import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import "./style.scss";
// components
import Nav from "components/settings/nav";
import Content from "components/settings/content";
import { connect } from "react-redux";
// pages
import AccountsPage from "../accounts";
import EtiquetadoPage from "../etiquetas";
import AccessManagement from "../accessManagement";
import rolesAssignment from "../rolesAssignment";
import EmailsAssgnment from "../ConfigEmails";
import IntegrationOdoo from "components/IntegrationOdoo";
import * as APILA from "helpers/apiLA";
import { Auth } from "aws-amplify";
import BudgetLogs from "components/budgetLogs";
import ConfigurationPriority from "components/configPriority";
import ConfiguracionWeek from "components/configWeek";
import { setPermisos } from "redux/actions/schedule.actions";
import _ from "lodash";
import { initialPermits } from "config/columns";
import Cuentas from "components/informacionCuentas";

const SettingsPage = (props) => {
  const [auth, setAuth] = useState(false);
  const [accountsView, setAccountsView] = useState(true);
  const [logsBudget, setLogsBudget] = useState(true);
  const [permitsConfig, setPermitsConfig] = useState({
    schedule: true,
    settings: true,
  });

  useEffect(() => {
    getAuth();
    permisos();
    permisosOdoo();
  }, []);

  const getAuth = () => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setAuth(true);
      })
      .catch(() => {
        setAuth(false);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(props.permits)) {
      let findPermisosSetting = props.permits?.find(
        (ele) => ele.name === "Configuración prioridades de campañas"
      );
      let findPermisosSchedule = props.permits?.find(
        (ele) => ele.name === "Horarios de atención"
      );
      setPermitsConfig((prev) => ({
        ...prev,
        schedule: findPermisosSchedule?.view,
        settings: findPermisosSetting?.view,
      }));
    }
  }, [props.permits]);

  const permisosOdoo = async () => {
    try {
      const userdata = await Auth.currentAuthenticatedUser();
      let response = await APILA.getPermitsByUser({
        user: userdata.username,
      });
      let cuenta = response.data.permits.find(
        (ele) => ele.account === props.currentAccount
      );
      let permisosOdoo = await APILA.getRoleNameById({ id: cuenta.idRol });
      let permits = permisosOdoo.data.rol[0].permissions;
      props._setPermisos(permits);
      let findPermisosLogsBudget = permits.find(
        (ele) => ele.name === "Movimientos de presupuestos"
      );
      setLogsBudget(findPermisosLogsBudget?.view);
    } catch (error) {
      props._setPermisos(initialPermits);
    }
  };

  const permisos = async () => {
    try {
      let userdata = await Auth.currentAuthenticatedUser();
      let response = await APILA.getPermitsByUser({
        user: userdata.username,
      });
      let cuenta = response.data.permits.find(
        (ele) => ele.account === props.currentAccount
      );
      if (cuenta.idRol === "Super Administrador") setAccountsView(true);
      else setAccountsView(false);
    } catch (error) {
      setAccountsView(true);
    }
  };

  if (props.isIpv4) {
    return (
      <>
        <div className="accountsPage">
          <Nav />
          <Content>
            <Switch>
              <Route
                path={`${props.match.path}/accounts`}
                component={AccountsPage}
              />
              <Route
                path={`${props.match.path}/etiquetas`}
                component={EtiquetadoPage}
              />
              <Route path="*">
                <Redirect to={`${props.match.path}/etiquetas`} />
              </Route>
            </Switch>
          </Content>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="accountsPage">
        <Nav />
        {auth && (
          <Content>
            <Switch>
              {/* <Route path={`${props.match.path}/budgets`} component={BudgetsPage} exact /> */}
              {accountsView && (
                <Route
                  path={`${props.match.path}/accounts/*`}
                  component={AccountsPage}
                />
              )}
              <Route
                path={`${props.match.path}/etiquetas/*`}
                component={EtiquetadoPage}
              />
              <Route
                path={`${props.match.path}/roles/*`}
                component={AccessManagement}
              />
              <Route
                path={`${props.match.path}/asignacion-roles/*`}
                component={rolesAssignment}
              />
              <Route
                path={`${props.match.path}/emails/*`}
                component={EmailsAssgnment}
              />
              <Route
                path={`${props.match.path}/integrationOdoo/*`}
                component={IntegrationOdoo}
              />
              {permitsConfig.settings && (
                <Route
                  path={`${props.match.path}/configuracionPriority/*`}
                  component={ConfigurationPriority}
                />
              )}
              {permitsConfig.schedule && (
                <Route
                  path={`${props.match.path}/configuracionSemanal/*`}
                  component={ConfiguracionWeek}
                />
              )}
              {logsBudget && (
                <Route
                  path={`${props.match.path}/logs-presupuestos`}
                  component={BudgetLogs}
                />
              )}
              <Route
                path={`${props.match.path}/informacionCuenta`}
                component={Cuentas}
              />
              {/* <Route path={`${props.match.path}/analytics`} component={ReportPage} exact /> */}
              <Route path="*">
                <Redirect to={`${props.match.path}/accounts`} />
                <Redirect to={`${props.match.path}/etiquetas`} />
                {/* <Redirect to={`${props.match.path}/roles-permisos`} /> */}
              </Route>
            </Switch>
          </Content>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  permits: state.schedule.permisos,
  isIpv4: state.app.isIPV4,
});

const mapDispatchToProps = (dispatch) => ({
  _setPermisos: (permisos) => dispatch(setPermisos(permisos)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SettingsPage));
