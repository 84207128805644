import React, { useState } from "react";
import { connect } from "react-redux";
import DataPicker from "components/dataPicker";
import { saveDates } from "redux/actions/fechasCamp.actions";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { saveNames } from "redux/actions/nameCamp.actions";
import { withUtils } from "utils";
import moment from "moment";
import { clearState, copyNextStep } from "redux/actions/campana.actions";
import "moment/locale/es";
import "moment/min/locales";
import "./style.scss";
moment.locale("es");

const FechaCamp = (props) => {
    const [state, setState] = useState({
        fechaIni: props.campaign.iniDate || "",
        fechafin: props.campaign.endDate || "",
    });
    const [namePauta, setNamePauta] = useState(props.campaign.name || "");
    const [erros, setErros] = useState({
        namePauta: false,
        fechaIni: false,
        fechafin: false,
    });

    const goback = (e) => {
        e.preventDefault();
        if (props.campaign.stepLogoutCopy == 16) {
            let response = window.confirm("Tienes cambios sin guardar, ¿Deseas continuar?")
            if (!response) return;
        }
        props._clearStateSteps();
        props._nextStepLogoutUserCopy(14);
        props._nextStepLogoutUser(14);
    };

    const handleChangeNameCap = (e) => {
        const value = e?.target?.value;
        if (value.length > 0) setErros((prev) => ({ ...prev, namePauta: false }));
        setNamePauta(value);
    };

    const validateInfo = () => {
        let validateInfo = false;
        if (!namePauta) {
            setErros((prev) => ({
                ...prev,
                namePauta: true,
            }));
            validateInfo = true;
        }
        if (!state.fechaIni || !state.fechafin) {
            setErros((prev) => ({
                ...prev,
                fechaIni: true,
                fechafin: true,
            }));
            validateInfo = true;
        }
        return validateInfo;
    };

    const go = (e) => {
        e.preventDefault();
        let response = validateInfo();
        if (response) return;
        props._nextStepLogoutUserCopy(16);
        props._saveNames(namePauta);
        props._nextStepLogoutUser(16);
    };

    const handleDateChange = (startDate, endDate) => {
        if (startDate && endDate) {
            setErros((prev) => ({ ...prev, fechaIni: false, fechafin: false }));
            setState((prev) => ({
                ...prev,
                fechaIni: startDate,
                fechafin: endDate,
            }));
        } else {
            setState((prev) => ({
                ...prev,
                fechaIni: "",
                fechafin: "",
            }));
        }
    };

    return (
        <div>
            <h1 className="title">
                Elige el rango de fecha y el nombre de tu pauta{" "}
            </h1>
            <div>
                <div className="oneColBody">
                    <FormGroup>
                        <Label
                            style={{
                                color: "#05252d",
                                fontWeight: 500,
                                marginBottom: "5px",
                            }}
                        >
                            Nombre de tu pauta
                        </Label>
                        <Input
                            type="text"
                            className="input"
                            id="createNamePauta"
                            autoComplete="off"
                            value={
                                props?.campaign?.name !== "" ? props?.campaign?.name : namePauta
                            }
                            onChange={handleChangeNameCap}
                            placeholder="P. ejemplo campaña de día de la madre"
                            invalid={erros.namePauta}
                        />
                        <FormFeedback>Ingresa un nombre</FormFeedback>
                    </FormGroup>
                </div>
                <div className="twoColBody" style={{ marginTop: "1rem" }}>
                    <Label className="texto_corregido"></Label>
                    <div className="date_picker_camp_logout">
                        <DataPicker
                            onChangeDate={handleDateChange}
                            fechaIni={
                                props?.campaign?.iniDate !== ""
                                    ? props?.campaign?.iniDate
                                    : state.fechaIni
                            }
                            fechafin={
                                props?.campaign?.endDate !== ""
                                    ? props?.campaign?.endDate
                                    : state.fechafin
                            }
                            invalid={erros.fechaIni || erros.fechafin}
                        />
                    </div>
                </div>
                {(erros.fechaIni || erros.fechafin) && (
                    <span style={{ fontSize: "10px", color: "red" }}>
                        Ingresa un rango de fechas
                    </span>
                )}
            </div>
            <div className="space-btns">
                <a className="btn-camp gray" onClick={goback} href="#">
                    volver
                </a>
                <a className="btn-camp" onClick={go} href="#" id="nextMedio">
                    siguiente
                </a>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
});

const mapDispatchToProps = (dispatch) => ({
    _clearStateSteps: () => dispatch(clearState()),
    _nextStepLogoutUser: (step) => dispatch(nextStepLogoutUser(step)),
    _nextStepLogoutUserCopy: (step) => dispatch(copyNextStep(step)),
    _saveNames: (name) => dispatch(saveNames(name)),
    _saveDates: (iniDate, endDate) => dispatch(saveDates(iniDate, endDate)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withUtils(FechaCamp));
