import React from "react";
import SimpleText2 from "../editBudget/index";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { TOTALES } from "../constants";

const CustomCellBudgetName = ({ row, editValueBudgetName }) => {
  const propsEdit = useSelector((state) => state.templates.edit);
  const budgets = useSelector((state) => {
    return state.templates.filteredBudgets;
  });
  const editCell = !row?.budget_name && row.name !== "Totales" ? true : false;
  const dataUser = useSelector((state) => state.templates.userInfo);

  if (row?.name === TOTALES) return <></>;

  return (
    <Tooltip
      title={`${row?.budget_name}` || ""}
      color="#002448"
      placement="top"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {editCell && (
          <i
            className="fa fa-exclamation-circle text-danger"
            aria-hidden="true"
            style={{ marginRight: "8px" }}
          ></i>
        )}
        <SimpleText2
          value={
            row?.budget_name && row?.budget_name.length > 30
              ? row?.budget_name.substr(0, 25) + "..."
              : row?.budget_name || ""
          }
          type={"select"}
          onEditValue={(value) =>
            editValueBudgetName(value, propsEdit, budgets, dataUser)
          }
          editable={true}
          id={{ id: row.id, campo: "budget_name" }}
          nameColumn={row}
          editCell={editCell}
          displayValue={row.idBudget}
          dataSelect={budgets}
        />
      </div>
    </Tooltip>
  );
};

export default CustomCellBudgetName;
