import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'

import MultiSelect from 'libs/multiselect'

import { arrayStatus as globalStatus } from 'config/status'
import { upsertStatus } from 'redux/actions/status.actions'

const UpsertStatus = (props) => {
  const [options, setOptions] = useState([])
  const [errors, setErrors] = useState({
    name: false,
    color: false,
    assoc: false,
  })
  const [state, setState] = useState({
    id: '',
    name: '',
    color: '',
    assoc: [],
    edit: false,
  })

  useEffect(() => {
    let eAdvertisingStatus = []

    globalStatus.forEach((status) => {
      eAdvertisingStatus = [
        ...eAdvertisingStatus,
        {
          value: status.id,
          name: status.label,
        },
      ]
    })

    setOptions(eAdvertisingStatus)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      let newState = {
        edit: true,
        id: props.edit.id,
        name: props.edit.name,
        color: props.edit.color,
        assoc: props.edit.associations,
      }
        
      setState(prev => ({
        ...prev,
        ...newState,
      }))
    } else {
      setState(prev => ({
        ...prev,
        id: '',
        name: '',
        color: '',
        assoc: [],
        edit: false,
      }))
    }
  }, [props.edit])

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setState(prev => ({
      ...prev,
      [name]: value
    }))

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }))
  }

  const handleAssociationsChange = (value) => {
    setState((prev) => ({
      ...prev,
      assoc: value,
    }))
    setErrors((prev) => ({
      ...prev,
      assoc: _.isEmpty(value),
    }))
  }

  const closeModal = (e) => {
    if (e) e.preventDefault()
    setState({
      id: '',
      name: '',
      color: '',
      assoc: [],
      edit: false,
    })
    window.$.fancybox.close()
  }

  const areValidInputs = () => {
    const mErrors = {
      name: state.name === '',
      color: state.color === '',
      assoc: _.isEmpty(state.assoc),
    }

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }))

    return !_.some(mErrors, (e) => e)
  }

  const handleUpsert = (e) => {
    e.preventDefault()

    if (!areValidInputs()) return

    let statusId = state.id

    if (statusId === '') {
      statusId = v4()
    }

    props._upsertStatus(
      statusId,
      state.name,
      state.color,
      state.assoc,
      state.edit
    )

    closeModal()
  }

  return (
    <div
      id='upsert-status-modal'
      style={{ display: 'none' }}
      className={classnames('upsert-modal')}
    >
      <div className='foot-mnc'>
        <div className='formOneStep'>
          <div className='titleOneStep'>
            <p className='h3'>
              {state.edit
                ? 'Actualizemos tu estado'
                : 'Creemos un nuevo estado'}
            </p>
          </div>

          <div className='bodyOneStep'>
            <div className='oneColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Ponle un nombre a tu estado
                </Label>
                <Input
                  type='text'
                  name='name'
                  className='input'
                  value={state.name}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo Corriendo'
                  invalid={errors.name}
                />
                <FormFeedback>Ingresa un nombre</FormFeedback>
              </FormGroup>
            </div>

            <div className='oneColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Asocia un color
                </Label>
                <Input
                  type='text'
                  name='color'
                  className='input'
                  value={state.color}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo #ffffff'
                  invalid={errors.color}
                />
                <FormFeedback>Ingresa un color</FormFeedback>
              </FormGroup>
            </div>

            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Asocia estados de eAdvertising
                </Label>

                {props.show && (
                  <MultiSelect
                    labelKey='name'
                    options={options}
                    value={state.assoc}
                    onChange={handleAssociationsChange}
                    className={classnames({
                      'is-invalid': errors.assoc,
                    })}
                  />
                )}
                <FormFeedback>Ingresa un estado</FormFeedback>
              </FormGroup>
            </div>
          </div>

          <div className='footOneStep mt-3'>
            <a href='#' className='btnCancelOS' onClick={closeModal}>
              Cancelar
            </a>

            <a href='#' className='btnConfirmOS' onClick={handleUpsert}>
              Confirmar
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  edit: state.status.edit,
  show: state.status.show_modal,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
})

const mapDispatchToProps = (dispatch) => ({
  _upsertStatus: (id, name, color, associations, edit) =>
    dispatch(upsertStatus(id, name, color, associations, edit)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpsertStatus)