import * as Actions from '../actions/modalImage.actions'
import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
const initialState = []

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case Actions.CLEAN_URLS:
//       return []
//     case Actions.ADD_URL:
//        return [...state, action.imagenObj]    
//     default:
//       return state
//   }
// }

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.MODAL_ADD_ID_URL:
      return action.id
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return  state           
  }
}