import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import DropZone from "libs/dropzone";
import classnames from "classnames";
import { Tooltip } from "antd";
import { v4 } from "uuid";
import _ from "lodash";
import * as STORAGE from "helpers/storage";
import { withRouter } from "react-router-dom";
import { withUtils } from "utils";
import imgPdf from "../../CampOneStep/assets/img/imgPdf.png";
import imgWord from "../../CampOneStep/assets/img/imgWord.png";
import imgExcel from "../../CampOneStep/assets/img/imgExcel.png";
import imgPp from "../../CampOneStep/assets/img/imgPp.png";
import imgTxt from "../../CampOneStep/assets/img/imgTxt.png";
import imgRar from "../../CampOneStep/assets/img/imgRar.png";
import imgZip from "../../CampOneStep/assets/img/imgZip.png";
import imgMp4 from "../../CampOneStep/assets/img/imgMp4.png";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader.actions";
import { editCountry, editAttachment } from "redux/actions/templates.actions";

const ModalAttachment = (props) => {
  const { setShowModalAttachment, info } = props;
  const [state, setState] = useState({
    copy: "",
    observation: "",
    creativity: [],
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(props.info)) {
      setState({
        copy: info.copy,
        observation: info.observation,
        creativity: info.creativity,
      });
      if (info.creativity && info.creativity.length > 0) {
        setImages(info.creativity);
      }
    }
  }, [props.info]);

  const saveChangesOnCancel = async () => {
    if (state.creativity.length === 0) {
      setShowModalAttachment(false);
      return
    }
    await props._editAttachment(
      state,
      info,
      props.edit,
      props.userInfo
    );
    setShowModalAttachment(false);

  }

  const saveChagnes = async () => {
    const validata = await props._editAttachment(
      state,
      info,
      props.edit,
      props.userInfo
    );
    Swal.fire({
      title: "Confirmación",
      text: "Cambios guardados con éxito",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    });
    setShowModalAttachment(false);
  };

  const handleCopyChange = (e, editor) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const remove = (file) => {
    const newArr = images.filter((img) => img.name !== file.name);
    setImages(newArr);
    setState((prev) => ({
      ...prev,
      creativity: newArr,
    }));
  };

  const handleObservationChange = (e, editor) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpload = async (files) => {
    try {
      props._showLoader(true);
      let imagesArr = state.creativity ? state.creativity : [];
      let arrayAux = [];
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const result = await new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
        });
        const uploadedFile = await STORAGE.uploadFile(
          file.name,
          // result,
          file.type
        );
        const { presignedUrl } = uploadedFile;
        await fetch(presignedUrl, {
          method: "PUT",
          body: file,
        });
        const url = await STORAGE.listFiles(uploadedFile.key);
        let taip;
        if (
          uploadedFile.key.includes("pdf") ||
          uploadedFile.key.includes("xls") ||
          uploadedFile.key.includes("docx") ||
          uploadedFile.key.includes("csv")
        ) {
          taip = "Document";
        } else {
          taip = "Image";
        }
        const newImage = {
          id: v4(),
          name: uploadedFile.key,
          link: url,
          account: props.account,
          type: taip,
        };
        imagesArr.push(newImage);
        /* let response= await APILA.associateFile({
                id: newImage.id,
                name: newImage.name,
                link: newImage.link,
              }) */
        setState((prev) => ({
          ...prev,
          creativity: imagesArr,
        }));
        if (state.edit) {
          props._validateCampg(
            props.campaignupd,
            { ...state, creativity: imagesArr },
            props.accounts,
            false
          );
        }
        /* getImg(imagesArr) */
        setImages(imagesArr);
        Swal.fire({
          title: "Confirmación",
          text: "Archivo subido con éxito",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log("ERROROROR", error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleFileChange = (files) => { };

  const docType = (file) => {
    let fileExt = file.name.split(".");
    let getExtension = fileExt[1].toLowerCase();
    if (file.type == "Image") {
      let imgExt = ["jpg", "jpeg", "png", "gif"];
      if (imgExt.includes(getExtension)) {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/${file.name}`;
      } else if (getExtension == "rar") {
        var img = imgRar;
      } else if (getExtension == "zip") {
        var img = imgZip;
      } else if (getExtension == "mp4") {
        var img = imgMp4;
      } else if (getExtension == "txt") {
        var img = imgTxt;
      } else if (getExtension == "pptx" || getExtension == "ppt") {
        var img = imgPp;
      } else {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/sin+imagen.jpg`;
      }
    } else {
      if (getExtension == "pdf") {
        var img = imgPdf;
      } else if (getExtension == "docx" || getExtension == "doc") {
        var img = imgWord;
      } else if (getExtension == "xlsx" || getExtension == "xls") {
        var img = imgExcel;
      } else {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/sin+imagen.jpg`;
      }
    }
    console.log('Validando la imagen que retorna', { img, file })
    return img;
  };

  return (
    <>
      <div
        id="new-pauta-modal"
        style={{ display: "none" }}
        className={classnames("upsert-modal w-85 h-85 ModalPauta")}
      ></div>
      <div className="titleOneStep w-100 bodyModalPauta">
        <p>Editemos esta pauta</p>
      </div>

      <div className="bodyOneStep w-100 bodyModalPauta">
        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Observaciones
            </Label>
            <Input
              type="textarea"
              rows={7}
              onChange={handleObservationChange}
              value={state.observation}
              name="observation"
            />
          </FormGroup>
        </div>
        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Copy
            </Label>
            <Input
              type="textarea"
              rows={7}
              value={state.copy}
              onChange={handleCopyChange}
              name="copy"
            />
          </FormGroup>
        </div>

        <div className="twoColBody">
          <div>
            <div className="img-content-creativy">
              {images.map((file) => (
                <div className="imagenCreati mb-5">
                  <i
                    onClick={() => {
                      remove(file);
                    }}
                    className="fas fa-times"
                  ></i>
                  <>
                    <img
                      style={{ display: "flex", margin: "auto" }}
                      className="img-fluid h-100"
                      src={docType(file)}
                    />{" "}
                    <p>{file.name}</p>
                  </>
                </div>
              ))}
            </div>
            <DropZone
              //   disabled={disabled.dropzone}
              onUpload={handleUpload}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>

      <div className="footOneStep w-100 footOneStepModal">
        <Tooltip title="Descartar cambios" color="#002448" placement="top">
          <a
            href="#"
            className="btnCancel firstBtn"
            onClick={saveChangesOnCancel}
          >
            Cancelar
          </a>
        </Tooltip>
        <Tooltip title="Guardar cambios" color="#002448" placement="top">
          <a
            className="btnConfirm secondBtn"
            onClick={() => {
              saveChagnes();
            }}
          >
            Confirmar
          </a>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  isIpv4: state.app.isIPV4,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  templates: state.templates.list,
  edit: state.templates.edit,
  userInfo: state.templates.userInfo,
  pwaAccounts: state.pwa.accounts.list,
  editActivateTemplate: state.templates.editActivate,
  users: state.users,
  currentAccount: state.accounts.default,
  mediumBanner: state.templates.listMediumBanner,
  goalsTemplate: state.templates.listGoals,
  categories: state.categories.list,
  formatTemplate: state.templates.listFormat,
  audienceErrors: state.templates.errorsAudience,
  budgetsErrors: state.templates.errorsBudget,
  productErrors: state.templates.errorsProduct,
  implementErrors: state.templates.errorsImplement,
  budgets: state.templates.refreshBudgets,
  currentPageParams: state.templateDL.currentPage,
  currentTemplateId: state.templateDL.currentTemplate,
  categories: state.categories.list,
  implementButton: state.templates.buttonImplement,
});

const mapDispatchToProps = (dispatch) => ({
  _editCountry: (edit, data, dataUser) =>
    dispatch(editCountry(edit, data, dataUser)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _editAttachment: (edit, prev, data, dataUser) =>
    dispatch(editAttachment(edit, prev, data, dataUser)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(ModalAttachment)));
