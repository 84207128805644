import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { saveCopy, saveObservation } from "redux/actions/medioCamp.actions";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";
import "./style.scss";

const ObservationCamp = (props) => {
    const [observation, setObservation] = useState(
        props?.campaign?.observations || "",
    );
    const [copy, setCopy] = useState(props.campaign?.copy || "");

    const backName = (e) => {
        e.preventDefault();
        props._saveSteps(18);
    };

    const handleCopyChange = (e) => {
        setCopy(e.value);
    };

    const handleObservationChange = (e) => {
        setObservation(e.value);
    };

    const saveMetodo = (e) => {
        e.preventDefault();
        const inputcopy = document.getElementById("txtcopy");
        const inputobs = document.getElementById("txtobs");

        if (inputcopy.value !== "" && inputobs.value !== "") {
            props._saveCopy(inputcopy.value);
            props._saveObservation(inputobs.value);
            props._saveSteps(19);
        } else {
            if (!inputcopy.value) {
                inputcopy.style.borderBottom = "1px solid red";
                inputcopy.style.color = "red";
            }
            if (!inputobs.value) {
                inputobs.style.borderBottom = "1px solid red";
                inputobs.style.color = "red";
            }
        }
    };

    return (
        <div className={props.clase}>
            <h1 className="title"> Escribe tus campos de observaciones y copy </h1>
            <br></br>
            <div className="oneColBody">
                <FormGroup>
                    <Label
                        style={{
                            color: "#05252d",
                            fontWeight: 500,
                            marginBottom: "5px",
                        }}
                    >
                        Observaciones
                    </Label>
                    <Input
                        id="txtobs"
                        type="textarea"
                        placeholder="Ingrese alguna observación"
                        rows={3}
                        onChange={handleObservationChange}
                        value={observation}
                        name="observation"
                    />
                </FormGroup>
            </div>
            <div className="oneColBody">
                <FormGroup>
                    <Label
                        style={{
                            color: "#05252d",
                            fontWeight: 500,
                            marginBottom: "5px",
                        }}
                    >
                        Copy
                    </Label>
                    <Input
                        id="txtcopy"
                        type="textarea"
                        rows={3}
                        placeholder="Ingrese un texto para copy"
                        onChange={handleCopyChange}
                        value={copy}
                        name="copy"
                    />
                </FormGroup>
            </div>
            <br></br>
            <div className="space-btns">
                <a className="btn-camp gray" onClick={backName} href="#">
                    volver
                </a>
                <a className="btn-camp" onClick={saveMetodo} href="#" id="nextMedio">
                    siguiente
                </a>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
    campaignupd: state.campaignupd,
    mediumbannerstype: state.mediumbannerstype,
    countries: state.countryReducer,
    mediumbanners: state.mediumbanners,
});

const mapDispatchToProps = (dispatch) => ({
    _saveSteps: (step) => dispatch(nextStepLogoutUser(step)),
    _saveObservation: (obs) => dispatch(saveObservation(obs)),
    _saveCopy: (copy) => dispatch(saveCopy(copy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObservationCamp);
