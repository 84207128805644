import React, { useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { addDays, subDays } from "date-fns";
import esLocale from "date-fns/locale/es";
import "./assets/style.css";

export const DateRangeFilter = (props) => {
  const [state, setState] = useState([
    {
      startDate: props?.initDate || subDays(new Date(), 7),
      endDate: props?.finishDate || addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    props.onChange(selection);
    setState([selection]);
  };

  return (
    <>
      {props.onActivate && (
        <div className="calendar-dropdown">
          {/* <div className="dropdown-header">
            <h6 className="filter-heading">
              {" "}
              <i className="fa fa-calendar" aria-hidden="true"></i> Filtro por
              fechas
            </h6>
            <small>Seleccione la fecha o campo personalizado</small>
          </div> */}
          <form autoComplete="off">
            <DateRangePicker
              editableDateInputs={true}
              rangeColors={["#e4e4e4"]}
              monthDisplayFormat="dd/MM/yyyy"
              locale={esLocale}
              dateDisplayFormat="dd/MM/yyyy"
              months={1}
              onChange={handleOnChange}
              direction="vertical"
              moveRangeOnFirstSelection={false}
              ranges={state}
              staticRanges={[
                {
                  ...defaultStaticRanges[0],
                  label: "Hoy",
                },
                {
                  ...defaultStaticRanges[1],
                  label: "Ayer",
                },
                {
                  ...defaultStaticRanges[2],
                  label: "Esta semana",
                },
                {
                  ...defaultStaticRanges[3],
                  label: "Última semana",
                },
                {
                  ...defaultStaticRanges[4],
                  label: "Este mes",
                },
                {
                  ...defaultStaticRanges[5],
                  label: "Último mes",
                },
                {
                  label: "Todo el periodo",
                  range: () => ({
                    startDate: new Date(960000000000), // Establece una fecha muy antigua
                    endDate: new Date(2082758400000), // Establece una fecha muy futura
                  }),
                  isSelected() {
                    return false;
                  },
                },
              ]}
              inputRanges={[
                {
                  ...defaultInputRanges[0],
                  label: "Días hasta hoy",
                },
                {
                  ...defaultInputRanges[1],
                  label: "Días a partir hoy",
                },
              ]}
            />
            {props?.visibleButtonApply && (
              <div className="calendar-footer">
                <button
                  type="button"
                  id="id_btn_apply"
                  className="btn btn-secondary"
                  onClick={() => {
                    props?.getFilterData();
                  }}
                >
                  Aplicar
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};
