import { useState } from "react";
import "./styles.scss";
import PageLoader from "components/page_loader";
import { withUtils } from "utils";
import { connect } from "react-redux";
import ConectionOdoo from "./ConectionOdoo";
import AsociationIds from "./AsociationIds";
const IntegrationOdoo = (props) => {
  const [value, setValue] = useState();
  console.log("valor3", value);
  return (
    <>
      {value === undefined && <ConectionOdoo value={value} setValue={setValue} />}
      {value === "1" && <ConectionOdoo value={value} setValue={setValue} />}
      {value === "2" && <AsociationIds value={value} setValue={setValue} />}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
export default connect(mapStateToProps)(withUtils(IntegrationOdoo));
