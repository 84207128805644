/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { v4 } from 'uuid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  msgsuccess,
  getCampaigns,
  createCampaign,
  updateCampaign,
  clearCampaignupd,
} from 'redux/actions/dashboard.actions'
import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { cleanSteps } from 'redux/actions/nameCamp.actions'
import { cleanCampaign } from 'redux/actions/campana.actions'

class FinalCamp extends Component {
  closeModal = (modal) => {
    if (modal) {
      let shadow = document.getElementById('shadow')
      let modal = document.getElementById('modalNewCamp')
      shadow.classList.toggle('hide')
      modal.classList.toggle('hide')
    } else {
      this.props.history.push('/dashboard')
    }
  }

  showError = (showAlertModal) => {
    this.props._showLoader(false)
    showAlertModal(
      'Error!',
      'Ocurrió un error al crear la campaña',
      Modal.MODAL_TYPE_ERROR
    )
  }

  clean = (pmodal) => {
    this.closeModal(pmodal)
    this.props._cleanSteps()
    this.props._clearCampaignupd()
    this.props._cleanCampaign()
    this.props._msgsuccess()
    this.props._getCampaign()
  }

  sendCampaign = (modal, crearCamp, showAlertModal, estado) => {
    const campaign = this.props.campaign
    const campaignupd = this.props.campaignupd[0]
    const campaingnBudg = this.props.campaignsbudget[0]
    this.props._showLoader(true)

    if (this.props.campaignupd.length > 0) {
      this.props
        ._updateCampaign(
          campaignupd.id,
          campaign.name,
          campaign.redSocial,
          campaign.iniDate,
          campaign.endDate,
          campaign.objetivo,
          campaign.budget,
          campaign.category,
          campaign.tags,
          campaignupd.idMixDetaill,
          campaignupd.idMixChange,
          campaignupd.idMix,
          campaignupd.idCampaign,
          campaign.id_medium,
          campaign.medium,
          campaign.id_product,
          campaign.redSocial,
          estado
        )
        .then((result) => {
          this.clean(modal)
        })
    } else {
      let account_param = '0'
      if (
        this.props.account.default != undefined &&
        this.props.account.default !== ''
      ) {
        account_param = this.props.account.default
      } else {
        account_param = campaign.account
      }

      const id = v4()
      crearCamp(
        id,
        campaign.name,
        campaign.redSocial,
        campaign.iniDate,
        campaign.endDate,
        campaign.objetivo,
        campaign.budget,
        campaign.category,
        campaign.tags,
        campaign.id_medium,
        campaign.medium,
        campaign.id_product,
        campaign.redSocial,
        estado,
        campaingnBudg.id_budget,
        account_param
      )
      this.clean(modal)
    }
  }

  render() {
    return (
      <div className={this.props.clase}>
        <h1 className='title'>¡Bienvenido!</h1>
        <p className='subTitle'>¿Deseas correr ahora mismo tu nueva campañaaa?</p>
        <div className='space-btns'>
          <a
            className='btn-end gray'
            href='#'
            onClick={(e) =>
              this.sendCampaign(
                this.props.modal,
                this.props._createCampaign,
                this.props._ShowAlertModal,
                0
              )
            }
          >
            Quizá después
          </a>
          <a
            className='btn-end'
            href='#'
            onClick={(e) =>
              this.sendCampaign(
                this.props.modal,
                this.props._createCampaign,
                this.props._ShowAlertModal,
                1
              )
            }
          >
            Si deseo correrla
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
  campaignsbudget: state.campaignsbudget,
  account: state.accounts,
})

const mapDispatchToProps = (dispatch) => {
  return {
    _ShowAlertModal: (titulo, texto, tipo) =>
      dispatch(Modal.showAlertModal(titulo, texto, tipo)),
    _cleanSteps: (step) => dispatch(cleanSteps(step)),
    _clearCampaignupd: () => dispatch(clearCampaignupd()),
    _cleanCampaign: () => dispatch(cleanCampaign()),
    _createCampaign: (
      id,
      name,
      medio,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      category,
      publico,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      estado,
      id_budget,
      account
    ) =>
      dispatch(
        createCampaign(
          id,
          name,
          medio,
          fechaIni,
          fechafin,
          objetivo,
          budget,
          category,
          publico,
          id_medium,
          medioo,
          id_ad_product,
          banner,
          estado,
          id_budget,
          account
        )
      ),
    _updateCampaign: (
      id,
      name,
      medio,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      category,
      publico,
      idMixDetaill,
      idMixChange,
      idMix,
      idCampaign,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      estado
    ) =>
      dispatch(
        updateCampaign(
          id,
          name,
          medio,
          fechaIni,
          fechafin,
          objetivo,
          budget,
          category,
          publico,
          idMixDetaill,
          idMixChange,
          idMix,
          idCampaign,
          id_medium,
          medioo,
          id_ad_product,
          banner,
          estado
        )
      ),
    _getCampaign: () => dispatch(getCampaigns()),
    _msgsuccess: () => dispatch(msgsuccess()),
    _showLoader: (value) => dispatch(showLoader(value)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FinalCamp))
