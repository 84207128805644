import React, { useState, useEffect } from "react";
import ReactDataTable, { NumberFormatEdit } from "@easygosanet/react-datatable";
import $ from "jquery";
import { columnsLovs } from "config/columns";
import * as APILA from "helpers/apiLA";
import Level1 from "./levels/level1";
import { connect } from "react-redux";
import { withUtils } from "utils";

import { showLoader } from "redux/actions/loader.actions";
import { getTotalsData } from "redux/actions/tableLevels.actions";

const customStyles = {
  headCells: {
    style: {
      textAlign: "center",
      "&:nth-of-type(2n)": {
        backgroundColor: "#eff0f2",
        borderRadius: "12px 12xp 0 0",
      },
    },
  },
  cells: {
    style: {
      "&:nth-of-type(2n)": {
        backgroundColor: "#eff0f2",
      },
    },
  },
};

const customStyles2 = {
  cells: {
    style: {
      color: "#ff642e",
    },
  },
};

const ViewTable = (props) => {
  const [totalRows, setTotalRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [groupedBy, setGroupedBy] = useState("ninguno");
  const [columnsSelect, setColumnsSelect] = useState([]);
  const [customStyles, setCustomStyles] = useState({});
  const applyPermissionsToData = (data, permissions) => {
    if (permissions !== undefined) {
      return data.map((ele) => ({ ...ele, permisos: permissions }));
    } else {
      return data;
    }
  };

  const filterDataByActiveStatus = (data, filterText) => {
    return data;
  };

  useEffect(() => {
    const filteredDataResponse = props.data || [];

    const dataWithPermissions = applyPermissionsToData(
      filteredDataResponse,
      props?.permissions
    );
    setFilteredData(dataWithPermissions);
    setTotalRows(calculateTotal(dataWithPermissions));
  }, [props.filterText, props.data]);
  useEffect(() => {
    setColumnsSelect(props.columns);
    setCustomStyles(props.customStyles);
  }, [props.columns, props.customStyles]);

  const calculateTotal = (rows) => {
    return [props._getTotalsData(props.columns, rows)];
  };

  const viewExpanded = (row) => {
    return row.defaultExpanded;
  };

  $(document).ready(function () {
    $(".CampaTabla1").on("scroll", function () {
      $(".CampaTabla2").scrollLeft($(this).scrollLeft());
    });
    $(".CampaTabla2").on("scroll", function () {
      $(".CampaTabla1").scrollLeft($(this).scrollLeft());
    });
  });

  return (
    <>
      <ReactDataTable
        key="CampaTabla122"
        className="CampaTabla1"
        groupedBy={groupedBy}
        customStyles={customStyles}
        paginationRowsPerPageOptions={[50, 100, 150, 200]}
        paginationPerPage={50}
        columns={columnsSelect}
        data={filteredData}
        persistTableHead
        onChangePage={props.onChangePage}
        paginationDefaultPage={props.paginationDefaultPage}
        pagination
        noHeader
        expandableRows
        expandableRowExpanded={(row) => viewExpanded(row)}
        expandableRowsComponent={Level1}
        expandableRowsHideExpander={true}
      />
      <ReactDataTable
        key="CampaTabla222"
        className="CampaTabla2"
        columns={columnsSelect}
        customStyles={customStyles}
        groupedBy={""}
        data={totalRows}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  templates: state.templates.list,
  edit: state.templates.edit,
  editVisualizer: state.templates.editVisualizer,
  users: state.users,
  currentAccount: state.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _getTotalsData: (columns, data) => dispatch(getTotalsData(columns, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ViewTable));
