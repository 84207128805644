import React from 'react'
// components
import Content from 'components/RolesAssignment'
import MetaTags from 'components/metatags'

const PermissRoles = () => (
  <>
    <div className='contAccount'>
      <MetaTags title='Roles y permisos' description='Página de roles' />
      <Content/>
    </div>
  </>
)

export default PermissRoles
