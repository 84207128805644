import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactDataTable from "@easygosanet/react-datatable";
import * as Modal from "redux/actions/modal.actions";
import {
  columnsActivosDigitales,
  orderDigitlAssets as orderDefault,
} from "config/columns";
import DigitalAssetModal from "../Modals/DigitalFiles";
import DetailColEdit from "components/DetailCol";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "../layout";
import _ from "lodash";
import {
  setCurrentOption,
  getAssetsByClient,
  deleteDigitalAsset,
  reactivateDigitalAsset,
} from "redux/actions/digitalAssets.actions";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "redux/actions/loader.actions";
import * as APILA from "helpers/apiLA";
import { v4 } from "uuid";
import { Auth } from "aws-amplify";
import "./style.scss";
const CURRENT_MODULE = "activosDigitales";

const ActivosDigitales = (props) => {
  /* Hooks para la navegacion y push al pathname */
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const listOptions = useSelector((state) => state.digitalAssets.options);
  const listAssets = useSelector((state) => state.digitalAssets.assets);

  /* Instancias para buscar parametros en la url */
  const currentUrl = new URLSearchParams(location.pathname);
  const currentSearch = new URLSearchParams(location.search);

  /* Obtencion de los valores */
  const accountParams = location.pathname
    ?.split("/configuracion/informacionCuenta/account=")[1]
    ?.split("&")[0];
  const viewSelectorParams = Number(currentUrl.get("view_selector"));
  const pageParams = Number(currentUrl.get("page"));
  const searchParams = currentSearch.get("search_query");

  /* States */
  const [search, setSearch] = useState(searchParams || "");
  const [currentPage, setCurrentPage] = useState(pageParams || 1);
  const [editData, setEditData] = useState({});
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [views, setViews] = useState([]);
  const [nameView, setNameView] = useState("");
  const [dataBackup, setDataBackup] = useState([]);

  const changeUrl = ({ view = viewSelectorParams, page = pageParams }) => {
    const validateAccount =
      accountParams === null ||
      accountParams === undefined ||
      accountParams.length === 0
        ? props.currentAccount
        : accountParams;
    history.push({
      pathname: `/configuracion/informacionCuenta/account=${validateAccount}&view_selector=${view}&page=${page}`,
    });
    if (location.search.length > 0) {
      history.push({
        search: location.search,
      });
    }
  };

  const editInfo = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  useEffect(() => {
    setData(listAssets.filter((item) => item.id_ad_status !== 3));
    setDataBackup(listAssets);
  }, [listAssets]);

  const info = async () => {
    try {
      setLoadingData(true);
      const response = await props._getAssetsByClient({
        account: props.currentAccount,
      });
      if (searchParams && searchParams.length > 0) {
        history.push({
          search: `search_query=${searchParams}`,
        });
        const lowerCaseValue = searchParams.toLowerCase();
        let filters = response.filter((accountInfo) => {
          return ["name", "reference", "url", "medio"].some((key) =>
            accountInfo[key]?.toLowerCase()?.includes(lowerCaseValue)
          );
        });
        setData(filters);
      }
    } catch (error) {
      console.log("Error APILA.getAssetsByClient", error);
    } finally {
      setLoadingData(false);
    }
  };

  const reactiveDigitalAsset = async (row) => {
    console.log("validando reac", { row });
    let title = `¿Está seguro que desea reactivar el activo digital?`;
    props._showConfirmationModal(
      "¿Reactivar activo digital?",
      title,
      async () => {
        try {
          setIsLoading(true);
          await props._deleteDigitalAsset({ id: row.id, status: 1 });
        } catch (error) {
          console.log("Error APILA.deleteAccountInfo", error);
        } finally {
          setIsLoading(false);
          info();
        }
      }
    );
  };

  const eliminarInfo = async (row) => {
    console.log("validando ", { row });
    let title = `¿Está seguro que desea eliminar el activo digital?`;
    props._showConfirmationModal(
      "¿Eliminar activo digital?",
      title,
      async () => {
        try {
          setIsLoading(true);
          await props._deleteDigitalAsset({ id: row.id, status: 3 });
        } catch (error) {
          // setIsLoading(false)
          console.log("Error APILA.deleteAccountInfo", error);
        } finally {
          setIsLoading(false);
          info();
        }
      }
    );
  };

  useEffect(() => {
    if (!props.currentAccount) return;
    actualizarViews(false);
    info();
  }, [props.currentAccount]);

  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        fontSize: "0.9rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "160px",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  const columnsMapping = {
    name: {
      cell: (row) => {
        return (
          <DetailColEdit
            key={row.id}
            text={row.name}
            id={"name"}
            reActivar={true}
            activate={row.id_ad_status === 1}
            nameColumn={row}
            activeData={() => reactiveDigitalAsset(row)}
            editInfo={() => editInfo(row)}
            edit={true}
            deleteData={() => eliminarInfo(row)}
            // activate={true}
            permissions={props.permissions}
          />
        );
      },
    },
  };

  const columnsMap = columnsActivosDigitales.map((element) => {
    const mappingElementColumn = columnsMapping[element.id];
    if (mappingElementColumn) {
      return {
        ...element,
        ...mappingElementColumn,
      };
    }
    return element;
  });

  const [columns, setColumns] = useState([...columnsMap]);
  const [columnsSelect, setColumnsSelect] = useState(columns || []);

  const handleChangePage = (page) => {
    setCurrentPage(page);
    changeUrl({
      page: page,
    });
  };

  const handleChangeSelector = (option) => {
    let map = {
      1: listOptions[0],
      2: listOptions[1],
    };
    props.setValue(option);
    dispatch(setCurrentOption(map[option]));
    changeUrl({
      page: pageParams,
      view: option,
    });
    history.push({
      search: "",
    });
  };

  const handleFilterText = _.debounce((value) => {
    if (value.length > 0) {
      history.push({
        search: `search_query=${value}`,
      });
      if (value.toLowerCase() === "eliminado") {
        let filters = dataBackup.filter((accountInfo) => {
          return accountInfo.id_ad_status === 3;
        });
        setData(filters);
        return;
      }
      const lowerCaseValue = value.toLowerCase();
      let filterActive = dataBackup.filter((accountInfo) => {
        return accountInfo.id_ad_status === 1;
      });
      let filters = filterActive.filter((accountInfo) => {
        return ["name", "reference", "url", "medio"].some((key) =>
          accountInfo[key]?.toLowerCase()?.includes(lowerCaseValue)
        );
      });

      setData(filters);
    } else {
      setData(listAssets.filter((item) => item.id_ad_status !== 3));
      history.push({
        search: "",
      });
      setSearch("");
    }
  }, 400);

  const exportDataToExcel = () => {
    let headers = ["Nombre", "Referencia", "Url", "Medio"];
    let selectors = ["name", "reference", "url", "medio"];
    let dataExcel = [];

    dataExcel.push(headers);
    if (data && data.length > 0) {
      data.forEach((ele) => {
        const excelData = selectors.map((selector) =>
          selector === "name"
            ? ele[selector]
            : selector === "reference"
            ? ele[selector]
            : selector === "url"
            ? ele[selector]
            : selector === "medio"
            ? ele[selector]
            : ""
        );
        dataExcel.push(excelData);
      });
    }
    return dataExcel;
  };

  const handleShowColumnChange = (column, show) => {
    let index = columnsActivosDigitales.findIndex(
      (col) => col.selector === column
    );
    let aux = columnsActivosDigitales.map((col) => col);
    if (index === -1) {
      // * columnas de las categorias
      let index = columnsActivosDigitales.findIndex((col) => col.id === column);
      let aux = columnsActivosDigitales.map((col) => col);
      aux[index].omit = show;
      return;
    }
    // if (index === -1) return;
    aux[index].omit = show;
    setColumnsSelect(aux);
  };

  const handleColumnSortChange = (positions) => {
    setColumnsSelect((data) => {
      positions.forEach((columnId, position) => {
        let idx = data.findIndex((col) => col.id === columnId);
        if (idx != -1) {
          data[idx].position = position;
        }
      });
      data = _.orderBy(data, ["position"]);
      return data;
    });
  };

  const actualizarEstado = async (id, estado) => {
    try {
      props._showLoader(true);
      let body = {
        id: id,
        active: estado,
      };
      await APILA.updateViewActive(body);
      actualizarViews(false);
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector || ele.id, ele.omit);
      } else {
        handleShowColumnChange(ele.selector || ele.id, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const handleAddView = async (name) => {
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    let columns = columnsSelect.map((item) => item);
    try {
      let body = {
        id: idVIew,
        name,
        component: CURRENT_MODULE,
        columns: columns,
        account: props.currentAccount,
        user: props.currentUser,
        active: true,
      };
      await APILA.insertView(body);
      actualizarViews(true);
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleDeleteView = async (name) => {
    try {
      //   props._showLoader(true);
      let id = views.find((ele) => ele.active);
      if (id) {
        if (id.id === name) {
          setNameView("lista");
          columnsSelect.forEach((ele) => {
            handleShowColumnChange(ele.selector, true);
          });
          handleColumnSortChange(orderDefault);
        }
      }
      await APILA.deleteView({ id: name });
      actualizarViews();
    } catch (error) {
      throw new Error(error);
    } finally {
      //   props._showLoader(false);
    }
  };

  const actualizarViews = async (tipo) => {
    try {
      props._showLoader(true);
      if (Auth.user) {
        let sub = Auth.user.signInUserSession.idToken.payload.sub;
        let viewsData = await APILA.getViewsByUser({
          account: props.currentAccount,
          user: sub,
          component: CURRENT_MODULE,
        });
        setViews(viewsData.data.views);
        if (viewsData.data.views.length === 0) {
          setNameView("lista");
        } else {
          let vistaActiva = viewsData.data.views.find((ele) => ele.active);
          if (vistaActiva) {
            setNameView(vistaActiva.name);
            if (tipo) {
              let nombres = [];
              vistaActiva.columns.forEach((ele) => {
                nombres.push(ele.id);
                handleShowColumnChange(ele.selector, ele.omit);
              });
              handleColumnSortChange(nombres);
            }
          } else {
            columnsSelect.forEach((ele) => {
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(orderDefault);
            setNameView("lista");
          }
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  return (
    <>
      {props.permissions.view ? (
        <>
          {" "}
          <Layout
            isLoading={isLoading}
            handleChangeSelector={handleChangeSelector}
            search={search}
            handleFilterText={handleFilterText}
            setEditData={setEditData}
            setShowModal={setShowModal}
            columns={columnsSelect}
            exportDataToExcel={() => exportDataToExcel()}
            handleShowColumnChange={handleShowColumnChange}
            handleFilterPerColumn={() => {}}
            handleColumnSortChange={handleColumnSortChange}
            handleDeleteView={handleDeleteView}
            handleAddView={handleAddView}
            nameView={nameView}
            views={views}
            handleShowView={handleShowView}
            textTooltip="Crear activo digital"
            create={true}
            permissions={props.permissions}
          >
            <section className="main_emails_sectionTable">
              <ReactDataTable
                progressPending={loadingData}
                noDataComponent={"No hay datos para mostrar"}
                columns={columnsSelect}
                data={data}
                persistTableHead
                customStyles={customStyles}
                paginationComponentOptions={{
                  rowsPerPageText: "Elementos por página",
                  rangeSeparatorText: "de",
                }}
                onChangePage={handleChangePage}
                // paginationPerPage={1}
                paginationDefaultPage={currentPage || 1}
                pagination
                noHeader
              />
            </section>
          </Layout>
          {showModal && (
            <DigitalAssetModal
              showModal={showModal}
              editData={editData}
              setShowModal={setShowModal}
              info={info}
            />
          )}
        </>
      ) : (
        <h1>No tienes permisos.</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _getAssetsByClient: ({ account }) => dispatch(getAssetsByClient({ account })),
  _deleteDigitalAsset: ({ id, status }) =>
    dispatch(deleteDigitalAsset({ id, status })),
  _reactivateDigitalAsset: ({ id, status }) =>
    dispatch(reactivateDigitalAsset({ id, status })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivosDigitales);
