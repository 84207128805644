import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { savetags } from "redux/actions/tags.actions";
import { savePublicName } from "redux/actions/campana.actions";
import { createNewAudience } from "redux/actions/audiences.actions";
import { clearAccounts, getAccounts } from "redux/actions/accounts.actions";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import _ from "lodash";
import Select from "libs/select";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import PageLoader from "components/page_loader";
import { v4 } from "uuid";

const Publico = (props) => {
    const [errors, setErrors] = useState({
        name: false,
        tags: false,
        tagsE: false,
    });
    const initialState = {
        id: "",
        name: "",
        newTag: "",
        newTagE: "",
        edit: false,
        createAt: 0,
        tags: { data: [] },
        tagsE: { data: [] },
    };
    const [state, setState] = useState(props?.campaign?.tags || initialState);
    const [audiencesOptions, setAudiencesOptions] = useState([]);
    const [audiences, setAudiences] = useState([]);
    const [errorTag, setErrorTag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [valueAudience, setValueAudience] = useState({
        id: props?.campaign?.tags?.id || "",
        label: props?.campaign?.tags?.name || "",
    });

    const agregarTag = () => {
        let cont = document.getElementsByClassName("tagCont");
        let tags = document.getElementsByClassName("tag");

        if (cont.length < 8) {
            tags[cont.length + 1].classList.add("color");
        }
    };

    const handleInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: _.isEmpty(value),
        }));
    };

    const removeTag = (tag) => {
        let tags = state.tags;
        let data = tags.data.filter((s) => s !== tag);
        tags.data = data;
        setState((prev) => ({
            ...prev,
            tags: tags,
        }));
    };

    const backObjetivo = (e) => {
        e.preventDefault();
        props._saveSteps(17);
    };

    const budgetTransactionLets = async (
        prevBudget,
        budget,
        idBudget,
        prevIdBudget,
        idCampaign,
        user,
        account,
    ) => {
        let errorOccurred = false;
        try {
            const budgetsTransact = [];
            if (idBudget !== prevIdBudget) {
                budgetsTransact.push(
                    {
                        id: prevIdBudget,
                        quantity: prevBudget,
                        sum: true,
                        idCampaign,
                        user,
                    },
                    { id: idBudget, quantity: budget, sum: false, idCampaign, user },
                );
            } else {
                //! Mantiene el mismo presupuesto pero pudo cambiar la cantidad
                if (budget !== prevBudget) {
                    const difference = budget - prevBudget;
                    if (difference > 0) {
                        budgetsTransact.push({
                            id: idBudget,
                            account,
                            quantity: difference,
                            sum: false,
                            idCampaign,
                            user,
                        });
                    } else {
                        budgetsTransact.push({
                            id: idBudget,
                            account,
                            quantity: Math.abs(difference),
                            sum: true,
                            idCampaign,
                            user,
                        });
                    }
                }
            }
            if (budgetsTransact.length > 0) {
                await APILA.budgetTransaction({ budgets: budgetsTransact });
            }
        } catch (error) {
            errorOccurred = true;
        }

        return errorOccurred;
    };

    const savePublico = async (e) => {
        e.preventDefault();
        if (audiencesOptions.length > 0 && !state.id) {
            setErrors((prev) => ({ ...prev, name: true }));
            return;
        }
        setErrors((prev) => ({ ...prev, name: false }));
        try {
            //setIsLoading(true);
            e.preventDefault();
            const campaign = props.campaign;
            let idCampaign = v4();
            const bodyCampaign = {
                id: idCampaign,
                name: campaign.name.trim(),
                medio: campaign.medium,
                objetivo: "",
                status: 1,
                fechaIni: campaign.iniDate,
                fechafin: campaign.endDate,
                budget: campaign.budget,
                publico: state.tags.data,
                id_medium: campaign.id_medium,
                medioo: campaign.medium,
                id_ad_product: campaign.id_product.id_ad_product,
                banner: campaign.id_product.banner,
                idBudget: props?.budgetInfo?.id,
                account: props.currentAccount,
                observation: campaign.observations || "",
                copy: campaign.copy || "",
                id_region: campaign.country.id_region,
                name_region: campaign.country.name,
                version: "",
                categoriesSelected: [],
                creativity: [],
                id_sale: "123456",
                idAudience: state.id,
                UserOutside: true,
            };
            //const errorBudget = await budgetTransactionLets(
            //0,
            //props.campaign?.budget,
            //bodyCampaign?.idBudget,
            //bodyCampaign?.idBudget,
            //idCampaign,
            //"logout",
            //);

            //if (errorBudget) {
            //return Swal.fire({
            // icon: "error",
            //title: "Ocurrió un error al crear la campaña",
            //  showConfirmButton: true,
            // });
            //}
            //await APILA.createCampaign(bodyCampaign);
            props._savetags(state);
            props._saveSteps(20);
            //window.location.reload(true);
        } catch (error) {
            console.log("Error create pauta", error);
        } finally {
            setIsLoading(false);
        }
    };

    const submitTag = async (e) => {
        if (e.key == "Enter") {
            let tags = state.tags;
            let tagFound = [];
            tagFound = state.tags?.data.filter((el) => el == state.newTag);
            if (tagFound == undefined) {
                tagFound = [];
            }
            if (tags == undefined) {
                tags = { data: [] };
            }
            if (tagFound.length === 0) {
                if (state.newTag !== "") {
                    tags.data.push(state.newTag);
                }
                setState((prev) => ({
                    ...prev,
                    tags: tags,
                    newTag: "",
                }));
            } else {
                setErrorTag(true);
            }
        }
    };
    const submitTagExcluyente = (e) => {
        if (e.key == "Enter") {
            let tagsE = state.tagsE;

            let tagFound = [];
            tagFound = state.tagsE?.data.filter((el) => el == state.newTagE);
            if (tagFound == undefined) {
                tagFound = [];
            }
            if (tagsE == undefined) {
                tagsE = { data: [] };
            }
            if (tagFound.length === 0) {
                if (state.newTagE !== "") {
                    tagsE.data.push(state.newTagE);
                }
                setState((prev) => ({
                    ...prev,
                    tagsE: tagsE,
                    newTagE: "",
                }));
            } else {
                setErrorTag(true);
            }
        }
    };

    //Excluyentes
    const removeTagE = (tagE) => {
        let tagsE = state.tagsE;
        let data = tagsE.data.filter((s) => s !== tagE);
        tagsE.data = data;
        setState((prev) => ({
            ...prev,
            tagsE: tagsE,
        }));
    };

    useEffect(() => {
        if (!props.currentAccount) return;
        getAudiences();
    }, [props.currentAccount]);

    const getAudiences = async () => {
        try {
            setIsLoading(true);
            const setAudiencesOp = new Set();
            const response = await API.getAudiences(props.currentAccount);
            const { audiences } = response.data;
            setAudiences(audiences);
            audiences
                ?.filter((aud) => aud.active)
                .forEach((audience) => {
                    setAudiencesOp.add({
                        id: audience?.id,
                        label: audience?.name,
                    });
                });
            const audiencesOptions = Array.from(setAudiencesOp);
            setAudiencesOptions(audiencesOptions);
        } catch (error) {
            console.log("Error en las audiencias logout", { error });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        }
    };

    const handleChangeSelectAudiences = (values) => {
        if (values) {
            let findAudience = audiences.find((aud) => aud.id === values.id);
            setState((prev) => ({
                ...prev,
                id: findAudience.id,
                name: findAudience.name,
                tags: findAudience.tags,
                tagsE: findAudience.tagsE,
            }));
            setValueAudience({
                id: findAudience.id,
                label: findAudience.name,
            });
            setErrors((prev) => ({ ...prev, name: false }));
        }
    };

    return (
        <>
            <h1 className="title">
                ¡Casi terminamos! <br /> Ahora define tus públicos
            </h1>
            {isLoading ? (
                <PageLoader />
            ) : audiencesOptions.length > 0 ? (
                <div className={props.clase}>
                    <>
                        <Select
                            type="select"
                            options={audiencesOptions || []}
                            value={valueAudience}
                            onChange={handleChangeSelectAudiences}
                            placeholder="Publicos de ingenieros"
                            invalid={errors.name}
                        />
                        {errors.name && (
                            <span style={{ fontSize: "10px", color: "red" }}>
                                Selecciona un publico
                            </span>
                        )}
                    </>
                </div>
            ) : (
                <>
                    <div className="bodyOneStep bodyOneStepPublico ">
                        <div className="twoColBody">
                            <FormGroup>
                                <Label
                                    style={{
                                        color: "#05252d",
                                        fontWeight: 500,
                                        marginBottom: "5px",
                                    }}
                                >
                                    Ponle un nombre a tu p&uacute;blico
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    name="name"
                                    className="input"
                                    value={state.name}
                                    onChange={handleInputChange}
                                    placeholder="P. ejemplo madres solteras"
                                    invalid={errors.name}
                                />
                                <FormFeedback>Ingresa un nombre</FormFeedback>
                            </FormGroup>
                        </div>

                        <div className="twoColBody">
                            <FormGroup>
                                <Label
                                    style={{
                                        color: "#05252d",
                                        fontWeight: 500,
                                        marginBottom: "5px",
                                    }}
                                >
                                    Describe tu p&uacute;blico
                                </Label>
                                <Input
                                    required={true}
                                    type="text"
                                    name="newTag"
                                    className="input"
                                    value={state.newTag}
                                    onKeyDown={submitTag}
                                    onChange={handleInputChange}
                                    placeholder="P. ejemplo mujeres de 18 a 25 años con hijos"
                                />
                                <div className="tagsContent tagsCont1" id="tagsContent">
                                    {state.tags &&
                                        state.tags.data &&
                                        state.tags.data.map((tag) => (
                                            <div key={tag} className="tagCont">
                                                <p>{tag}</p>
                                                <i
                                                    onClick={() => removeTag(tag)}
                                                    className="fas fa-times"
                                                />
                                            </div>
                                        ))}
                                </div>
                            </FormGroup>
                        </div>
                        <div className="twoColBody">
                            <FormGroup>
                                <Label
                                    style={{
                                        color: "#05252d",
                                        fontWeight: 500,
                                        marginBottom: "5px",
                                    }}
                                >
                                    Describe tu p&uacute;blico Excluido
                                </Label>
                                <Input
                                    type="text"
                                    name="newTagE"
                                    className="input"
                                    value={state.newTagE}
                                    onKeyDown={submitTagExcluyente}
                                    onChange={handleInputChange}
                                    placeholder="P. ejemplo padres de 18 a 25 años con hijos"
                                />
                                <div className="tagsContent tagsCont1" id="tagsContent">
                                    {state.tagsE &&
                                        state.tagsE.data &&
                                        state.tagsE.data.map((tagE) => (
                                            <div key={tagE} className="tagCont">
                                                <p>{tagE}</p>
                                                <i
                                                    onClick={() => removeTagE(tagE)}
                                                    className="fas fa-times"
                                                />
                                            </div>
                                        ))}
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </>
            )}
            <div className="space-btns" style={{ marginTop: "2rem" }}>
                <a className="btn-camp gray" onClick={backObjetivo} href="#">
                    volver
                </a>
                <a className="btn-camp" onClick={savePublico} href="#">
                    siguiente
                </a>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    audiences: state.audiences.list, // wizard
    currentAccount: state.accounts.default, // wizard
    campaign: state.campaign,
    accounts: state.accounts.list,
    campaignupd: state.campaignupd,
    budgetInfo: state.budgets.budgetInfo,
});

const mapDispatchToProps = (dispatch) => ({
    _savetags: (tags) => dispatch(savetags(tags)),
    _saveSteps: (step) => dispatch(nextStepLogoutUser(step)),
    _clearAccounts: () => dispatch(clearAccounts()),
    _getAccounts: (client) => dispatch(getAccounts(client)),
    _savePublicName: (publicName) => dispatch(savePublicName(publicName)),
    _createAudience: (id, name, account, tags) =>
        dispatch(createNewAudience(id, name, account, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Publico);
