import { useState } from "react";
import { Tooltip } from "antd";
import PageLoader from "components/page_loader";

const ModalAsociationIds = ({ showModal, setShowModal }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="blur-div">
      <div className="content-modal">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <div className="header-modal">
              <h3 className="titleModal">Asociación IDS</h3>
            </div>

            <div className="body-modal">
              <section className="body-part">
                <label className="label-container">
                  Cuenta
                  <select name="erp" label={"hola"}>
                    <option value={"Odoo"}>cuenta1</option>
                  </select>
                </label>
                <label className="label-container">
                  Asociar IDS
                  <input type="text" />
                </label>
              </section>
              <section className="body-part">
                <p>Previsualización</p>
                <div className="containerBoxes">
                  <div className="boxLabel">
                    <label> 175 </label>
                    <label> Success</label>
                  </div>
                  <div className="boxLabel">
                    <label> 178 </label>
                    <label> Success</label>
                  </div>
                  <div className="boxLabel">
                    <label> 230 </label>
                    <label> Success</label>
                  </div>
                  <div className="boxLabel">
                    <label> 233 </label>
                    <label> Success</label>
                  </div>
                  <div className="boxLabel">
                    <label> 232 </label>
                    <label> Success</label>
                  </div>
                </div>
              </section>
            </div>

            <div className="footer-Modal">
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button
                  className="btn btn_cancel btnCancelOS"
                  onClick={(e) => setShowModal(false)}
                >
                  Cancelar
                </button>
              </Tooltip>
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button className="btn btn_save btnConfirmOS">Guardar</button>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalAsociationIds;
