import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/profile.actions'

const initialState = {
  name: '',
  email: '',
  phone: '',
  country: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.PROFILE_ADD_INFO:
      return {
        ...state,
        name: action.name,
        email: action.email,
        phone: action.phone,
        country: action.country,
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
