import React from 'react'

// components
import MetaTags from '../../components/metatags'
import ConfirmNewPasswordForm from '../../components/auth/confirm_new_password'

const ConfirmNewPassword = props => (
  <>
    <MetaTags title='Confirmar Nueva Contraseña' description='Página de confirmación de nueva contraseña' />

    <ConfirmNewPasswordForm {...props} />
  </>
)

export default ConfirmNewPassword
