import DetailColEdit from "components/DetailCol";
import { IdComponent } from "components/budgetOld/pages/idComponent";
import {
  addDays,
  isWithinInterval,
  parseISO,
  subDays,
  startOfMonth,
  endOfMonth,
  format,
} from "date-fns";
import status from "config/status";
import { Progress } from "antd";
import { es } from "date-fns/locale";
import { Tooltip, Select } from "antd";
import ReactDataTable, {
  Status,
  DateRangeEdit,
  SimpleText,
  NumberFormat,
  NumberFormatEdit,
  DateRange,
  OptionsSelectedEdit,
  EditAnt,
  DateRangeEditFormat,
} from "@easygosanet/react-datatable";
//Agregar nameTotal en valor true para la col que tendra el titulo de totales

export const initialPermits = [
  {
    name: "Configuración prioridades de campañas",
    export: true,
    view: true,
    edit: true,
    create: true,
    delete: true,
    manageRoles: true,
    approve: true,
  },
  {
    name: "Horarios de atención",
    export: true,
    view: true,
    edit: true,
    create: true,
    delete: true,
    manageRoles: true,
    approve: true,
  },
];

export const columnsLovs = [
  {
    name: "Orden de venta",
    selector: (row) => row.orderNumber,
    id: "orderNumber",
    center: true,
    omit: false,
    nameTotal: true,
    visible: true,
    type: "text",
    show: true,
    sortable: true,
    position: 1,
    minWidth: "220px",
  },
  {
    name: "Línea de OV",
    id: "order_line_id_v6",
    selector: (row) => row.order_line_id_v6,
    center: true,
    visible: true,
    type: "text",
    omit: false,
    show: true,
    sortable: true,
    position: 2,
    minWidth: "220px",
  },
  {
    name: "Venta",
    position: 3,
    center: true,
    minWidth: "220px",
    omit: false,
    visible: true,
    type: "selectOp",
    sortable: true,
    show: true,
    id: "product_uom_qty",
    selector: (row) => row.product_uom_qty,
    cell: (row) => {
      let valor = `$${numberWithCommas(row.product_uom_qty)}`;

      return (
        <NumberFormatEdit
          prefix={"$"}
          thousandSeparator={true}
          value={valor}
          id={"budget"}
          displayType={"text"}
          tipo={"text"}
          editable={false}
          nameColumn={row}
          onEditValue={() => {}}
        />
      );
    },
  },
  {
    name: "Presupuesto implementado",
    id: "reserve",
    selector: (row) => row.reserve,
    position: 4,
    center: true,
    visible: true,
    sortable: true,
    type: "selectOp",
    show: true,
    minWidth: "220px",
    omit: false,
    cell: (row) => {
      let valor = `$${numberWithCommas(row.reserve)}`;
      return (
        <NumberFormatEdit
          prefix={"$"}
          thousandSeparator={true}
          value={valor}
          id={"budget"}
          displayType={"text"}
          tipo={"text"}
          editable={false}
          nameColumn={row}
          onEditValue={() => {}}
        />
      );
    },
  },
  {
    name: "Saldo disponible",
    selector: (row) => row.budgetAvailable,
    id: "budgetAvailable",
    center: true,
    omit: false,
    sortable: true,
    position: 5,
    minWidth: "220px",
    visible: true,
    type: "selectOp",
    show: true,
    cell: (row) => {
      let valor = `$${numberWithCommas(row.budgetAvailable)}`;

      return (
        <NumberFormatEdit
          prefix={"$"}
          thousandSeparator={true}
          value={valor}
          id={"budget"}
          displayType={"text"}
          tipo={"text"}
          editable={false}
          nameColumn={row}
          onEditValue={() => {}}
        />
      );
    },
  },
  {
    name: "ID de presupuesto",
    center: true,
    position: 6,
    selector: (row) => row.idsBudget,
    id: "idsBudget",
    omit: false,
    minWidth: "220px",
    cell: (row) => {
      const combinedIds = (row.idsBudget ?? []).reduce((acc, ele, index) => {
        acc += ele;
        if (index !== row.idsBudget.length - 1) {
          acc += ", ";
        }
        return acc;
      }, "");

      return (
        <div>
          <span>
            <IdComponent text={combinedIds} />
          </span>
        </div>
      );
    },
  },

  {
    name: "ID de campaña",
    omit: false,
    center: true,
    position: 7,
    id: "idsCampaign",
    selector: (row) => row.idsCampaign,
    minWidth: "220px",
    cell: (row) =>
      row.idsCampaign &&
      row.idsCampaign.length > 0 &&
      row.idsCampaign.reduce((acc, ele, index) => {
        acc += ele;
        if (index !== row.idsCampaign.length - 1) {
          acc += ", ";
        }
        return acc;
      }, ""),
  },
  {
    omit: false,
    name: "Fecha de creación",
    id: "create_date",
    tag: "createAt",
    selector: (row) => row.create_date,
    sortable: true,
    minWidth: "220px",
    center: true,
    cell: (row) => formatDate(row.create_date),
  },
];

export const columnsTemplates = [
  {
    name: "ID",
    selector: (row) => row.id,
    id: "id",
    center: true,
    omit: false,
    nameTotal: true,
    visible: true,
    type: "text",
    show: true,
    sortable: true,
    position: 1,
    minWidth: "220px",
  },
  {
    name: "nombre",
    selector: (row) => row.name,
    id: "name",
    center: true,
    omit: false,
    nameTotal: true,
    visible: true,
    type: "text",
    show: true,
    sortable: true,
    position: 1,
    minWidth: "220px",
  },
];

export const columnsBudgets2 = [
  {
    omit: false,
    name: "Usuario",
    selector: (row) => row.user,
    sortable: true,
    type: "text",
    id: "user",
    minWidth: "180px",
    // visible: false,
    tag: "title",
    center: true,
    show: false,
  },
  {
    name: "ID de presupuesto",
    omit: false,
    selector: (row) => row.id,
    sortable: true,
    id: "idBudget",
    tag: "idBudget",
    center: true,
    show: true,
    cell: (row) => {
      return <IdComponent text={row.idBudget} />;
    },
  },
  {
    omit: false,
    name: "Presupuesto total",
    selector: (row) => row.amount,
    id: "amount",
    tag: "amount",
    type: "text",
    type: "text",
    sortable: true,
    show: false,
    center: true,
  },
  {
    omit: false,
    name: "Accion realizada",
    selector: (row) => row.action,
    id: "action",
    tag: "action",
    sortable: true,
    type: "text",
    show: false, // filterPerColumn
    center: true,
    minWidth: "220px",
  },
  {
    omit: false,
    name: "Fecha",
    selector: (row) => row.date,
    id: "date",
    tag: "date",
    sortable: true,
    center: true,
    type: "text",
    show: false,
  },
  {
    omit: false,
    name: "Id de campaña",
    selector: (row) => row.idCampaign,
    id: "idCampaign",
    tag: "idCampaign",
    sortable: true,
    type: "text",
    show: true,
    center: true,
  },
  {
    omit: false,
    name: "Id de Ov",
    selector: (row) => row.idOv,
    id: "idOv",
    tag: "idOv",
    sortable: true,
    center: true,
    show: true,
  },
];

export const columnsLovsConclict = [
  ...columnsLovs,
  {
    name: "Tipo de conflicto",
    center: true,
    minWidth: "220px",
    visible: true,
    show: false,
    position: 6,
    sortable: true,
    omit: false,
    id: "flagErrorMessage",
    selector: (row) => row.flagErrorMessage,
    cell: (row) => (
      <Tooltip color="#002448" placement="top" title={row.flagErrorMessage}>
        {row.flagErrorMessage && row.flagErrorMessage.length > 10
          ? `${row.flagErrorMessage.slice(0, 20)}...`
          : row.flagErrorMessage}
      </Tooltip>
    ),
  },
  {
    omit: false,
    name: "Fecha de conflicto",
    id: "createConflict",
    tag: "createConflict",
    selector: "createConflict",
    sortable: true,
    minWidth: "170px",
    center: true,
    cell: (row) => formatDateConflict(row.createConflict || 0),
  },
];

export const columnsDashboard = [
  {
    id: "ninguno",
    name: "Ninguno",
    selector: "ninguno",
    tag: "ninguno",
    nameCol: "Ninguno",
    group: true,
    visible: false,
    omit: true,
    position: 0,
    pinned: 0,
  },
  {
    id: "name",
    name: (
      <Tooltip title="Nombre de la campaña." color="#002448" placement="top">
        Nombre
      </Tooltip>
    ),
    selector: "name",
    sortable: false,
    visible: false,
    tag: "name",
    type: "text",
    nameCol: "Nombre",
    center: true,
    minWidth: "250px",
    maxWidth: "250px",
    omit: false,
    style: {
      position: "sticky !important",
      left: 0,
      zIndex: "3 !important",
      textAlign: "center",
    },
    position: 1,
    pinned: 1,
  },
  {
    id: "fechaIni",
    tag: "fechaIni",
    visible: false,
    nameCol: "fecha de inicio",
    omit: true,
    excel: true,
    selector: "fechaIni",
    position: 2,
  },
  {
    visible: false,
    id: "fechaFin",
    tag: "fechaFin",
    excel: true,
    selector: "fechaFin",
    position: 3,
    nameCol: "fecha de fin",
    omit: true,
  },
  {
    id: "year",
    name: "year",
    selector: "year",
    sortable: true,
    nameCol: "Rango de fecha",
    tag: "fechaIni",
    minWidth: "250px",
    maxWidth: "250px",
    center: true,
    omit: false,
    position: 4,
    cell: (row) => (
      <DateRange
        start={new Date(getData(row.fechaIni))}
        end={new Date(getData(row.fechafin))}
        format="dd/mm/yyyy"
        justifyContent={"center"}
        center={"true"}
        className={"budgetRange"}
      />
    ),
  },
  {
    id: "id",
    name: "ID",
    tag: "id",
    // selector: (row) => row.id,
    selector: "id",
    type: "text",
    nameCol: "ID",
    show: true,
    sortable: true,
    value: 1,
    center: true,
    minWidth: "180px",
    maxWidth: "180px",
    omit: false,
    position: 5,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.idMySql}
        displayType={"text"}
        thousandSeparator={false}
        center={"center"}
      />
    ),
  },
  {
    id: "id_sale",
    name: "Orden de Venta",
    selector: "id_sale",
    tag: "id_sale",
    minWidth: "190px",
    maxWidth: "190px",
    nameTotal: true,
    nameCol: "Orden de Venta",
    sortable: true,
    center: true,
    omit: false,
    show: true,
    type: "text",
    position: 6,
    cell: (row) => (
      <NumberFormatEdit
        prefix={""}
        value={row.id_sale ? row.id_sale : "0"}
        thousandSeparator={false}
        editable={false}
        // onEditValue={editData}
        id={"id_sale"}
        displayType={"text"}
        tipo={"text"}
        nameColumn={row}
      />
    ),
  },
  {
    id: "status",
    name: "Estado",
    selector: "status",
    tag: "searchable_status",
    group: true,
    sortable: true,
    minWidth: "190px",
    maxWidth: "190px",
    center: true,
    nameCol: "Estado",
    omit: false,
    position: 7,
    cell: (row) => (
      <Status
        value={row.status}
        label={getStatusLabel(row.status)}
        className={getStatusClassName(row.status)}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "country",
    name: "Pais",
    selector: "country",
    nameCol: "País",
    tag: "country",
    minWidth: "170px",
    maxWidth: "170px",
    group: true,
    sortable: true,
    type: "text",
    center: true,
    textAlign: "center",
    omit: false,
    position: 8,
    cell: (row) => (
      <SimpleText
        value={row.name_region ? row.name_region : row.country}
        id={row.id}
        type={"select"}
        editable={false}
        // dataSelect={props.namesCountries}
        nameColumn={row}
        // onEditValue={handleChangeCountry}
      />
    ),
  },
  {
    id: "medio",
    name: "Medio",
    selector: "medio",
    tag: "medio",
    sortable: true,
    type: "text",
    nameCol: "Medio",
    minWidth: "170px",
    maxWidth: "170px",
    group: true,
    center: true,
    omit: false,
    position: 9,
    cell: (row) => (
      <OptionsSelectedEdit
        id={row.id}
        editable={false}
        data={row.options ? row.options : []}
        value={row.medio}
        nameColumn={row}
        // onEditValue={handleChangeMedium}
        editCell={row.bodyUpdateProduct ? row.bodyUpdateProduct.medio : false}
        textSelect={
          row.options
            ? row.options.length !== 0
              ? "Selecciona una opción"
              : "Selecciona un pais"
            : "Selecciona un pais"
        }
      />
    ),
  },
  {
    id: "banner",
    name: "Objetivo",
    selector: "banner",
    nameCol: "Objetivo",
    group: true,
    tag: "banner",
    sortable: true,
    type: "text",
    center: true,
    minWidth: "200px",
    maxWidth: "200px",
    omit: false,
    position: 10,
    cell: (row) => (
      // row.banner,
      <OptionsSelectedEdit
        id={row.id}
        editable={false}
        data={row.optionsBanner ? row.optionsBanner : []}
        value={row.banner}
        nameColumn={row}
        editCell={
          row.bodyUpdateProduct ? row.bodyUpdateProduct.objetivo : false
        }
        // onEditValue={bannerChange}
        textSelect={
          row.optionsBanner
            ? row.optionsBanner.length !== 0
              ? "Selecciona una opción"
              : "Selecciona un medio"
            : "Selecciona un medio"
        }
      />
    ),
  },
  {
    id: "digital_asset",
    name: "Activo Digital",
    selector: "meta",
    tag: "meta",
    minWidth: "190px",
    maxWidth: "190px",
    sortable: true,
    center: true,
    omit: false,
    cell: (row) => row.name_digital_asset || "",
  },
  {
    id: "meta",
    name: "Meta definida",
    selector: "meta",
    tag: "meta",
    minWidth: "190px",
    maxWidth: "190px",
    sortable: true,
    nameCol: "Meta definida",
    center: true,
    omit: false,
    cell: (row) =>
      row.metaTotales > 0
        ? numberWithCommasTwoo(row.metaTotales)
        : row.meta != null
        ? numberWithCommasTwoo(row.meta)
        : "NA",
  },
  {
    id: "columnaCompare",
    name: "Meta alcanzada",
    selector: "columnaCompare",
    tag: "columnaCompare",
    minWidth: "190px",
    maxWidth: "190px",
    sortable: true,
    nameCol: "Meta alcanzada",
    center: true,
    omit: false,
    cell: (row) => (
      <PorcentBar
        row={row.totalPercentage ? getPercent(row) : getPercent(row)}
      />
    ),
  },
  {
    id: "formato",
    name: "Formato",
    selector: "formato",
    nameCol: "formato",
    group: true,
    tag: "formato",
    sortable: true,
    type: "text",
    center: true,
    minWidth: "200px",
    maxWidth: "200px",
    omit: false,
    position: 11,
    cell: (row) =>
      row.formato && row.formato.art ? row.formato.art : row.formato,
    // <OptionsSelectedEdit
    //   id={row.id}
    //   editable={false}
    //   editCell={row.bodyUpdateProduct ? row.bodyUpdateProduct.format : false}
    //   data={row.formatosOptions ? row.formatosOptions : []}
    //   value={row.formato}
    //   nameColumn={row}
    //   // onEditValue={handleChangeFormat}
    //   textSelect={"Selecciona un objetivo"}
    // />
  },
  {
    id: "budgetName",
    name: "Nombre del presupuesto",
    selector: "budgetName",
    tag: "budgetName",
    nameCol: "Nombre del presupuesto",
    group: true,
    sortable: true,
    type: "text",
    center: true,
    omit: false,
    position: 12,
    minWidth: "160px",
    maxWidth: "160px",
    cell: (row) => (
      <OptionsSelectedEdit
        id={row.id}
        editable={false}
        data={[]}
        value={
          <Tooltip title={row.budgetName} color="#002448" placement="top">
            {row.budgetName ? row.budgetName.substr(0, 10) + "..." : ""}
          </Tooltip>
        }
        nameColumn={row}
        editCell={false}
        onEditValue={(e) => console.log("La e del nombre")}
        textSelect={
          row.optionsBanner
            ? row.optionsBanner.length !== 0
              ? "Selecciona una opción"
              : "Selecciona un medio"
            : "Selecciona un medio"
        }
      />
    ),
  },
  {
    id: "purchase_unit",
    name: "Tipo de Compra",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "purchase_unit",
    tag: "purchase_unit",
    group: true,
    sortable: true,
    nameCol: "Tipo de compra",
    type: "text",
    center: true,
    omit: false,
    position: 13,
    cell: (row) => row.purchase_unit,
  },
  {
    id: "budgetI",
    name: "Presupuesto Invertido",
    selector: "budgetI",
    tag: "budgetI",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "Presupuesto Invertido",
    center: true,
    omit: false,
    position: 14,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={
          row.budget
            ? parseFloat(row.budget).toFixed(2) || 0.0
            : parseFloat(row.budgetI).toFixed(2) || 0.0
        }
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "budget",
    name: "Presupuesto Neto",
    selector: "budget",
    tag: "budgetNeto",
    nameCol: "Presupuesto Neto",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    center: true,
    omit: false,
    position: 15,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.budgetNeto ? row.budgetNeto : row.net_budget}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "budgetConsumido",
    name: "Presupuesto Consumido",
    selector: "budgetConsumido",
    tag: "budgetConsumido",
    nameCol: "Presupuesto Consumido",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    center: true,
    omit: false,
    position: 16,
    cell: (row) => "$" + numberWithCommas(row.budgetConsumido || 0),
  },
  {
    id: "swc",
    omit: false,
    name: "Presupuesto Consumido con Comisión",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "swc",
    nameCol: "Presupuesto Consumido con Comisión",
    tag: "swc",
    sortable: true,
    center: true,
    // visible: false,
    omit: false,
    position: 17,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={Number(row.swc).toFixed(2) || 0}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "sp_perc",
    name: "Porcentaje de Presupuesto Consumido",
    minWidth: "160px",
    maxWidth: "160px",
    tag: "spent_percent",
    selector: "sp_perc",
    nameCol: "Porcentaje de Presupuesto Consumido",
    sortable: true,
    center: true,
    omit: false,
    position: 18,
    cell: (row) => (row.spent_percent ? row.spent_percent + "%" : "0%"),
  },
  {
    id: "dias_campaña",
    name: "Días de Campaña",
    selector: "dias_campaña",
    tag: "dias_campaña",
    minWidth: "160px",
    maxWidth: "160px",
    sortable: true,
    nameCol: "Días de campaña",
    center: true,
    omit: false,
    position: 19,
    cell: (row) => row.dias_campaña,
  },
  {
    id: "segmentation",
    name: "Públicos",
    selector: "segmentation",
    tag: "segmentation",
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "Públicos",
    textAlign: "center",
    group: true,
    sortable: true,
    style: {
      textAlign: "center",
    },
    type: "text",
    center: true,
    omit: false,
    position: 20,
    // cell: (row) => audienceComponent(row.idAudience),
  },
  {
    id: "comentarios",
    name: "Comentarios",
    selector: "comentarios",
    tag: "comentarios",
    nameCol: "Comentarios",
    minWidth: "160px",
    maxWidth: "160px",
    maxWidth: "auto",
    sortable: true,
    center: true,
    omit: false,
    textAlign: "center",
    position: 21,
    cell: (row) => (
      <Tooltip title={row.comentarios} color="#002448" placement="top">
        {row.comentarios ? row.comentarios.substr(0, 10) + "..." : ""}
      </Tooltip>
    ),
  },
  {
    id: "ctr",
    name: "CTR",
    selector: "ctr",
    tag: "ctr",
    sortable: true,
    center: true,
    omit: false,
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "CTR",
    position: 22,
    cell: (row) => row.ctr || 0 + "%",
  },
  {
    id: "photo",
    name: "Visualizaciones de imagen",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "photo",
    tag: "photo",
    sortable: true,
    center: true,
    omit: false,
    position: 23,
    nameCol: "Visualizaciones de imagen",
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.photo}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "leads",
    omit: false,
    name: "Leads",
    selector: "leads",
    tag: "leads",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "Leads",
    center: true,
    position: 24,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.leads}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "event_responses",
    name: "Respuestas a Eventos",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "event_responses",
    tag: "event_responses",
    sortable: true,
    center: true,
    omit: false,
    position: 25,
    nameCol: "Respuesta a Eventos",
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.event_responses}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "installs",
    name: "Instalación de aplicaciones",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "installs",
    tag: "installs",
    nameCol: "Instalación de aplicaciones",
    sortable: true,
    center: true,
    omit: false,
    position: 26,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.installs}
        displayType={"text"}
        thousandSeparator={false}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "estimated_ad_recallers",
    name: "Reconocimiento de marca (personas)",
    selector: "estimated_ad_recallers",
    tag: "estimated_ad_recallers",
    sortable: true,
    center: true,
    nameCol: "Reconocimiento de marca (personas)",
    omit: false,
    position: 27,
    minWidth: "160px",
    maxWidth: "160px",
    cell: (row) => numberWithCommasTwoo(row.estimated_ad_recallers || 0),
  },
  {
    id: "frequency",
    name: "Frecuencia",
    selector: "frequency",
    tag: "frequency",
    sortable: true,
    nameCol: "Frecuencia",
    center: true,
    omit: false,
    position: 28,
    minWidth: "160px",
    maxWidth: "160px",
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.frequency}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "calificacion",
    name: "Calificación del anuncio",
    selector: "calificacion",
    tag: "calificacion",
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "Calificación del anuncio",
    sortable: true,
    center: true,
    omit: false,
    position: 29,
    cell: (row) => (row.calificacion ? row.calificacion : 0),
  },
  {
    id: "conversaciones_iniciadas",
    omit: false,
    name: "Conversaciones iniciadas",
    selector: "conversaciones_iniciadas",
    tag: "conversaciones_iniciadas",
    sortable: true,
    center: true,
    nameCol: "conversaciones_iniciadas",
    minWidth: "160px",
    maxWidth: "160px",
    position: 32,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.conversaciones_iniciadas}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpl",
    name: "CPL",
    selector: "cpl",
    tag: "cpl",
    sortable: true,
    center: true,
    omit: false,
    minWidth: "160px",
    maxWidth: "160px",
    position: 30,
    nameCol: "CPL",
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpl}
        displayType={"text"}
        thousandSeparator={false}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpi",
    name: "CPI",
    selector: "cpi",
    tag: "cpi",
    sortable: true,
    center: true,
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "CPI",
    omit: false,
    position: 31,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpi}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpma",
    omit: false,
    name: "CPMA",
    selector: "cpma",
    tag: "cpma",
    sortable: true,
    center: true,
    nameCol: "cpma",
    minWidth: "160px",
    maxWidth: "160px",
    position: 32,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpma}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpci",
    omit: false,
    name: "CPCI",
    selector: "cpci",
    tag: "cpci",
    sortable: true,
    center: true,
    nameCol: "cpci",
    minWidth: "160px",
    maxWidth: "160px",
    position: 32,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpci}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpre",
    omit: false,
    name: "CPRE",
    selector: "cpre",
    tag: "cpr",
    sortable: true,
    center: true,
    nameCol: "CPRE",
    minWidth: "160px",
    maxWidth: "160px",
    position: 32,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpr}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpa",
    name: "CPInt",
    selector: "cpa",
    tag: "cpa",
    nameCol: "CPInt",
    minWidth: "160px",
    maxWidth: "160px",
    sortable: true,
    center: true,
    omit: false,
    position: 33,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpa}
        displayType={"text"}
        thousandSeparator={false}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpc",
    name: "CPC",
    selector: "cpc",
    tag: "cpc",
    sortable: true,
    center: true,
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "CPC",
    omit: false,
    position: 34,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpc}
        displayType={"text"}
        thousandSeparator={false}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpv",
    name: "CPV",
    selector: "cpv",
    tag: "cpv",
    sortable: true,
    nameCol: "CPV",
    minWidth: "160px",
    maxWidth: "160px",
    center: true,
    omit: false,
    position: 35,
    cell: (row) => (
      <NumberFormat
        decimalScale={3}
        prefix={"$"}
        value={row.cpv}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpm",
    name: "CPM",
    selector: "cpm",
    tag: "cpm",
    sortable: true,
    center: true,
    omit: false,
    minWidth: "160px",
    maxWidth: "160px",
    position: 36,
    nameCol: "CPM",
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpm}
        displayType={"text"}
        thousandSeparator={false}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cpf",
    name: "CPF",
    selector: "cpf",
    tag: "cpf",
    sortable: true,
    center: true,
    omit: false,
    nameCol: "CPF",
    minWidth: "160px",
    maxWidth: "160px",
    position: 37,
    cell: (row) => (
      <NumberFormat
        prefix={"$"}
        value={row.cpf}
        displayType={"text"}
        thousandSeparator={false}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "cprm",
    omit: false,
    name: "CPRM",
    selector: "cprm",
    tag: "cprm",
    sortable: true,
    center: true,
    position: 38,
    nameCol: "CPRM",
    minWidth: "160px",
    maxWidth: "160px",
    cell: (row) => "$" + numberWithCommasThree(row.cprm || 0),
  },
  {
    id: "prints",
    name: "Impresiones",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "prints",
    tag: "prints",
    sortable: true,
    nameCol: "Impresiones",
    center: true,
    omit: false,
    position: 39,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.prints}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "clicks",
    name: "Clics",
    selector: "clicks",
    tag: "clicks",
    nameCol: "Clicks",
    minWidth: "160px",
    maxWidth: "160px",
    sortable: true,
    center: true,
    omit: false,
    position: 40,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.clicks}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "fans",
    name: "Fans",
    selector: "fans",
    tag: "fans",
    sortable: true,
    omit: false,
    minWidth: "160px",
    maxWidth: "160px",
    nameCol: "Fans",
    justifyContent: "center",
    center: true,
    position: 41,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.fans}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "reach",
    name: "Alcance",
    selector: "reach",
    tag: "reach",
    nameCol: "Alcance",
    minWidth: "160px",
    maxWidth: "160px",
    sortable: true,
    center: true,
    omit: false,
    position: 42,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.reach}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "views",
    name: "Reproducciones de video",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "views",
    tag: "views",
    sortable: true,
    nameCol: "Reproducciones de video",
    center: true,
    omit: false,
    position: 43,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.views}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "video_playbacks_25",
    name: "Reproducciones de video 25%",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "video_playbacks_25",
    tag: "video_playbacks_25",
    sortable: true,
    nameCol: "Reproducciones de video 25%",
    center: true,
    omit: false,
    position: 44,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.video_playbacks_25}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "video_playbacks_50",
    name: "Reproducciones de video 50%",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "video_playbacks_50",
    tag: "video_playbacks_50",
    sortable: true,
    nameCol: "Reproducciones de video 50%",
    omit: false,
    center: true,
    position: 45,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.video_playbacks_50}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "video_playbacks_75",
    name: "Reproducciones de video 75%",
    tag: "video_playbacks_75",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "video_playbacks_75",
    nameCol: "Reproducciones de video 75%",
    sortable: true,
    omit: false,
    center: true,
    position: 46,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.video_playbacks_75}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "video_playbacks_95",
    name: "Reproducciones de video 95%",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "video_playbacks_95",
    nameCol: "Reproducciones de video 95%",
    tag: "video_playbacks_95",
    sortable: true,
    omit: false,
    center: true,
    position: 47,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.video_playbacks_95}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "video_playbacks_100",
    name: "Reproducciones de video 100%",
    minWidth: "160px",
    maxWidth: "160px",
    tag: "video_playbacks_100",
    selector: "video_playbacks_100",
    omit: false,
    nameCol: "Reproducciones de video 100%",
    sortable: true,
    center: true,
    position: 48,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.video_playbacks_100}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "adv",
    name: "Tiempo promedio de reproducción del video",
    selector: "adv",
    tag: "adv",
    minWidth: "160px",
    maxWidth: "160px",
    sortable: true,
    nameCol: "Tiempo promedio de reproducción del video",
    center: true,
    omit: false,
    position: 49,
    cell: (row) => row.adv,
  },
  {
    id: "postShares",
    omit: false,
    name: "Veces que se compartió la publicación",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "postShares",
    nameCol: "Veces que se compartió la publicación",
    tag: "postShares",
    sortable: true,
    center: true,
    position: 50,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.postShares}
        displayType={"text"}
        thousandSeparator={true}
        center={"center"}
      />
    ),
  },
  {
    id: "postComments",
    name: "Comentarios en la publicación",
    selector: "postComments",
    tag: "postComments",
    minWidth: "160px",
    maxWidth: "160px",
    group: true,
    nameCol: "Comentarios en la publicacion",
    sortable: true,
    type: "text",
    center: true,
    omit: false,
    position: 51,
    Header: () => (
      <div
        style={{
          textAlign: "center",
        }}
      ></div>
    ),
    cell: (row) => row.postComments,
  },
  {
    id: "postLikes",
    omit: false,
    name: "Reaciones de la publicación",
    minWidth: "160px",
    maxWidth: "160px",
    selector: "postLikes",
    tag: "post_likes",
    nameCol: "Reacciones de la publicación",
    sortable: true,
    justifyContent: "center",
    center: true,
    position: 52,
    cell: (row) => (
      <NumberFormat
        prefix={""}
        value={row.post_likes}
        displayType={"text"}
        thousandSeparator={true}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  //comentar desde aqui

  /* {
        id:'presupuesto',
        name: 'Rango de presupuesto',
        selector: 'presupuesto',
        sortable: true,
        minWidth: '250px',
        center: true,  
      center: true,  
        center: true,  
      center: true,  
        center: true,  
        omit: true,
        cell: (row) => (
          <NumberRange
            start={getTotalspent(row.idMixDetaill).toString()}
            // end={row.budget?row.budget.toFixed(2):0}
            end={row.budget}
            className=''
            barClass=''
            format='$'
            justifyContent={'center'}
            center = {'true'}
        />
        ),  
      ),  
        ),  
      ),  
        ),  
      }, */
  /* {
          id:'budgetSpend2',
          name: 'Presupuesto Gastado',
          selector: 'budgetSpend2',
          sortable: true,
          minWidth: '250px',
          center: true, 
        center: true, 
          center: true, 
        center: true, 
          center: true, 
          omit: true, 
        omit: true, 
          omit: true, 
        omit: true, 
          omit: true, 
          cell: (row) => (
            <Graph
              type='area'
              width={100}
              height={40}
              series={getGraph(row.idMixDetaill,props.budget_spent)}
              options={graphOptions}
              justifyContent={'center'}
              center = {'true'}
            />
          ),
      }, */
  // {
  //   id:'realPrice',
  //   omit: false,
  //   name: 'Real price',
  //   selector: 'realPrice',
  //   sortable: true,
  //   cell: (row) => (
  //     <NumberFormat
  //       prefix={'$'}
  //       value={row.real_price}
  //       displayType={'text'}
  //       thousandSeparator={true}
  //       />
  //     // ,<DetailCol row={row}/>
  //   ),
  // },

  // {
  //   id:'version',
  //   name: 'Version',
  //   selector: 'version',
  //   group: true,
  //   sortable: true,
  //   type: 'text',
  //   cell: (row) => row.version
  // },

  // {
  //   id:'fr',
  //   omit: false,
  //   name: 'FR',
  //   selector: 'fr',
  //   sortable: true,
  //   cell: (row) => (
  //     <NumberFormat
  //       prefix={''}
  //       value={row.fr}
  //       displayType={'text'}
  //       thousandSeparator={true}
  // />
  //     // ,<DetailCol row={row}/>
  //   ),
  // },
];

export const columnsBudgets = [
  {
    omit: false,
    name: "Nombre de presupuesto",
    selector: (row) => row.title,
    sortable: true,
    type: "text",
    id: "title",
    minWidth: "180px",
    tag: "title",
    visible: false,
    center: true,
    show: true,
  },
  {
    name: "ID de presupuesto",
    omit: false,
    selector: (row) => row.id,
    sortable: true,
    id: "id",
    tag: "title",
    center: true,
    show: true,
    cell: (row) => {
      return <IdComponent text={row.id} />;
    },
  },
  {
    omit: false,
    name: "Línea de OV",
    selector: (row) => row.idLovs,
    id: "idLovs",
    tag: "idLovs",
    type: "text",
    sortable: true,
    show: true,
    center: true,
    cell: (row) => (
      <div>
        {row.idLovs && row.idLovs.length > 0
          ? row.idLovs.reduce((acc, ele, index) => {
              acc += ele;
              if (index !== row.idLovs.length - 1) {
                acc += ", ";
              }
              return acc;
            }, "")
          : ""}
      </div>
    ),
  },
  {
    omit: false,
    name: "Presupuesto total",
    selector: (row) => row.amount,
    id: "amount",
    tag: "amount",
    sortable: true,
    type: "selectOp",
    show: true,
    center: true,
    minWidth: "220px",
    cell: (row) => {
      let valor = `$${numberWithCommas(row.amount)}`;
      return (
        <NumberFormatEdit
          prefix={"$"}
          thousandSeparator={true}
          value={valor}
          id={"budget"}
          displayType={"text"}
          tipo={"text"}
          editable={false}
          nameColumn={row}
          // onEditValue={updateBudget}
        />
      );
    },
  },
  {
    omit: false,
    name: "Presupuesto implementado",
    selector: (row) => row.availableC,
    id: "availableC",
    tag: "availableC",
    sortable: true,
    center: true,
    type: "selectOp",
    show: true,
    cell: (row) => `$${numberWithCommas(row.availableC)}`,
  },
  {
    omit: false,
    name: "Presupuesto disponible",
    selector: (row) => row.available,
    id: "available",
    tag: "available",
    sortable: true,
    type: "selectOp",
    show: true,
    center: true,
    cell: (row) => `$${numberWithCommas(row.available)}`,
  },
  {
    omit: false,
    name: "Presupuesto Consumido con Comisión",
    selector: (row) => row.consumed,
    id: "consumed",
    tag: "consumed",
    sortable: true,
    center: true,
    type: "selectOp",
    show: true,
    cell: (row) => `$${numberWithCommas(row.consumed || 0)}`,
  },
  {
    omit: false,
    name: "Estado",
    id: "status",
    tag: "status",
    sortable: true,
    center: true,
    show: true,
  },
  {
    omit: false,
    name: "Fecha de creación",
    id: "createAt",
    tag: "createAt",
    sortable: true,
    center: true,
    cell: (row) => formatDateBudget(row.createAt),
  },
  {
    omit: false,
    name: "Última Actualización",
    id: "updateAt",
    tag: "updateAt",
    selector: (row) => row.updateAt,
    sortable: true,
    center: true,
    cell: (row) => {
      return formatDateBudget(row.updateAt);
    },
  },
];
const formatDateBudget = (millis) => {
  let date = new Date(millis);
  const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  return `${date.getDate()} - ${
    months[date.getMonth()]
  } - ${date.getFullYear()}`;
};

const getData = (fecha) => {
  const regex = /^\d{2}\.\d{2}\.\d{4}$/;
  if (fecha && regex.test(fecha) && fecha !== "00.00.0000") {
    const fini = fecha.split(".");
    return `${fini[1]}/${fini[0]}/${fini[2]}`;
  } else {
    return new Date();
  }
};

function numberWithCommasTwoo(x) {
  let auxNumber = new Number(x).toString();
  return auxNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithCommas(x) {
  let auxNumber = new Number(x).toFixed(2);
  return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const formatDate = (dateSelect) => {
  if (!dateSelect) return;
  const parsedDate = parseISO(dateSelect);
  const formatDate = format(parsedDate, "dd - MMM - yyyy", { locale: es });
  return formatDate;
};

const formatDateConflict = (millis) => {
  let date = new Date(millis);
  const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  return `${date.getDate()} - ${
    months[date.getMonth()]
  } - ${date.getFullYear()}`;
};

export const getStatusLabel = (mStatus) => valueStatus(mStatus, "label");
export const getStatusClassName = (mStatus) => valueStatus(mStatus, "class");

export const valueStatus = (mStatus, type) => {
  const statusNames = {
    1: status.CREADO[type],
    2: status.INACTIVO[type],
    3: status.ELIMINADO[type],
    5: status.MIX_APROBADO[type],
    6: status.EN_REVISION[type],
    7: status.APROBADO[type],
    8: status.MODIFICADO[type],
    9: status.EN_IMPLEMENTACION[type],
    10: status.ACTIVO[type],
    11: status.PENDIENTE[type],
    12: status.FINALIZADO[type],
    13: status.FINALIZADO_SIN_META[type],
    14: status.PAUSADO[type],
    15: status.RECHAZADO[type],
    16: status.NO_IMPLEMENTADO[type],
    17: status.FINALIZADO_SIN_REPORTE[type],
    18: status.PAUSADO2[type],
  };
  if (type === "class") {
    return statusNames[mStatus] || status.EN_IMPLEMENTACION.class;
  } else {
    return statusNames[mStatus] || "Totales";
  }
};
export const getPercent = (row) => {
  if (!row.meta || row.meta == 0) return "0%";

  let valueToGet = getValueTotal(row);

  if (valueToGet === 0) return "0%";
  if (!valueToGet) return "NA";
  let final = valueToGet / row.meta;
  return row.meta * (final / 100) + "/" + final.toFixed(0) + "%" + row.meta;
};

export const getValueTotal = (row) => {
  let valueToGet;
  if (row?.columnaCompare === "post_likes") {
    valueToGet = 100 * new Number(row.post_likes);
  } else if (row?.columnaCompare === "page_comments") {
    valueToGet = 100 * new Number(row.postComments);
  } else if (row?.columnaCompare === "page_shares") {
    valueToGet = 100 * new Number(row.postShares);
  } else if (row?.columnaCompare === "video_avg_time") {
    valueToGet = 100 * new Number(row.adv);
  } else if (row?.columnaCompare === "video_playbacks_100") {
    valueToGet = 100 * new Number(row.video_playbacks_100);
  } else if (row?.columnaCompare === "video_playbacks_95") {
    valueToGet = 100 * new Number(row.video_playbacks_95);
  } else if (row?.columnaCompare === "video_playbacks_75") {
    valueToGet = 100 * new Number(row.video_playbacks_75);
  } else if (row?.columnaCompare === "video_playbacks_50") {
    valueToGet = 100 * new Number(row.video_playbacks_50);
  } else if (row?.columnaCompare === "video_playbacks_25") {
    valueToGet = 100 * new Number(row.video_playbacks_25);
  } else if (row?.columnaCompare === "views") {
    valueToGet = 100 * new Number(row.views);
  } else if (row?.columnaCompare === "reach") {
    valueToGet = 100 * new Number(row.reach);
  } else if (row?.columnaCompare === "fans") {
    valueToGet = 100 * new Number(row.fans);
  } else if (row?.columnaCompare === "clicks") {
    valueToGet = 100 * new Number(row.clicks);
  } else if (row?.columnaCompare === "prints") {
    valueToGet = 100 * new Number(row.prints);
  } else if (row?.columnaCompare === "frequency") {
    valueToGet = 100 * new Number(row.frequency);
  } else if (row?.columnaCompare === "estimated_ad_recallers") {
    valueToGet = 100 * new Number(row.estimated_ad_recallers);
  } else if (row?.columnaCompare === "mobile_app_install") {
    valueToGet = 100 * new Number(row.installs);
  } else if (row?.columnaCompare === "event_responses") {
    valueToGet = 100 * new Number(row.event_responses);
  } else if (row?.columnaCompare === "leads") {
    valueToGet = 100 * new Number(row.leads);
  } else if (row?.columnaCompare === "photo_view") {
    valueToGet = 100 * new Number(row.photo);
  } else {
    valueToGet = 0;
  }
  return valueToGet;
};
export const PorcentBar = (row) => {
  let data = Object.values(row);
  let data2 = JSON.stringify(data);
  let data3 = data2.split(/\W/);
  let filtrado = data3.filter((element) => element !== "");
  let porcentaje;
  if (filtrado.length === 3) {
    porcentaje = filtrado[1];
  } else if (filtrado.length === 1 && filtrado[0] !== "NA") {
    porcentaje = filtrado[0];
  } else {
    porcentaje = 0;
  }

  function formatNumber(number) {
    return new Intl.NumberFormat("es-MX").format(number);
  }
  let cantidad = formatNumber(filtrado[0]);

  return (
    <div className="boxProgress">
      {filtrado.length === 3 && (
        <>
          <span className="text1">{cantidad}</span>
          <span className="text2">{filtrado[1]}%</span>
        </>
      )}
      {filtrado.length === 1 && filtrado[0] !== "NA" && (
        <span className="text3">{filtrado[0]}%</span>
      )}
      {filtrado.length === 1 && <span className="text3">{filtrado[0]}</span>}

      <Progress percent={porcentaje} strokeColor={"#00ca72"} />
    </div>
  );
};
function numberWithCommasThree(x) {
  let auxNumber = new Number(x).toFixed(3);
  return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const audienceComponent = (idAudience) => {
  // let audience = props.audiences?.filter((el) => el.id === idAudience);
  let audience = [];
  let audienceToShow = "";
  if (audience.length > 0) {
    audienceToShow = audience[0].name;
  }
  return <>{audienceToShow}</>;
};

export const orderDefault = columnsDashboard.map((ele) => ele.selector);

//Opciones que se mostraran en niveles inferiores de campa;as
const optionsShowDashboard = [
  "budgetI",
  "budget",
  "budgetConsumido",
  "swc",
  "budgetName",
  "id_sale",
];

//Opciones que se mostraran en niveles inferiores de lovs
const optionsShowLovs = [
  "orderNumber",
  "reserve",
  "budgetAvailable",
  "order_line_id_v6",
  "orderNumber",
  "idsCampaign",
];

//Opciones que se muestran en nivel de orden de Venta
const optionsShowOv = [
  "reserve",
  "budgetAvailable",
  "order_line_id_v6",
  "createAt",
  "product_uom_qty",
];

const optionsVisualizer = [
  "country",
  "year",
  "medio",
  // "id_sale",
  "banner",
  "formato",
  "budgetI",
  "segmentation",
  // "budget",
  // "sp_perc",
  // "swc",
  "name",
  "budgetName",
];

//Agreggar ids de columnas que no mostraran valores en totales
export const validateTotals = ["order_line_id_v6"];
export const columnsVisualizer = columnsDashboard
  .filter((ele) => optionsVisualizer.includes(ele.id))
  .map((column) => {
    if (column.id === "name") {
      column.show = true;
    }
    return column;
  });
export const orderDefaultTemplates = columnsVisualizer.map(
  (ele) => ele.selector
);
export const columnsOptions = {
  idsBudget: {
    columns: columnsBudgets,
    name: "Presupuestos",
    firstLevel: "idLovs",
  },
  idLovs: {
    columns: columnsLovs.filter((ele) => optionsShowLovs.includes(ele.id)),
    name: "Lovs",
    firstLevel: "idsCampaign",
    secondLevel: "orderNumber",
  },
  idsCampaign: {
    columns: columnsDashboard.filter((ele) =>
      optionsShowDashboard.includes(ele.id)
    ),
    name: "Campañas",
    firstLevel: "validation",
  },
  orderNumber: {
    columns: columnsLovs.filter((ele) => optionsShowOv.includes(ele.id)),
    name: "Orden de venta",
    firstLevel: "idLovs",
  },
};

/* Columns activos digateles */
export const columnsActivosDigitales = [
  {
    id: "name",
    omit: false,
    name: "Nombre",
    selector: (row) => row.name,
    visible: false,
    position: 1,
    type: "text",
    show: true,
    sortable: true,
    center: true,
    tag: "name",
  },
  {
    id: "status",
    name: "Estado",
    selector: (row) => row.id_ad_status,
    show: true,
    minWidth: "190px",
    maxWidth: "190px",
    center: true,
    omit: false,
    cell: (row) => (
      <Status
        value={row.id_ad_status}
        label={getStatusLabel(row.id_ad_status === 1 ? 10 : row.id_ad_status)}
        className={getStatusClassName(
          row.id_ad_status === 1 ? 10 : row.id_ad_status
        )}
        justifyContent={"center"}
        center={"true"}
      />
    ),
  },
  {
    id: "reference",
    omit: false,
    name: "Referencia",
    selector: (row) => row.reference,
    sortable: true,
    type: "text",
    minWidth: "180px",
    tag: "id",
    visible: true,
    position: 2,
    center: true,
    show: true,
    cell: (row) => row.reference,
  },
  {
    id: "url",
    position: 3,
    omit: false,
    name: "Activo digital",
    selector: (row) => row.url,
    visible: true,
    sortable: true,
    center: true,
    tag: "medio",
    cell: (row) => row.url,
  },
  {
    id: "medio",
    omit: false,
    name: "Medio",
    selector: (row) => row.medio,
    visible: true,
    sortable: true,
    center: true,
    position: 4,
    tag: "medio",
    cell: (row) =>
      row?.medio[0]?.toUpperCase() + row.medio.slice(1).toLowerCase() || "",
  },
];

/* Columns Account Information */
export const columnsInformacionCuentas = [
  {
    id: "name",
    omit: false,
    name: "Nombre",
    selector: (row) => row.name,
    visible: true,
    sortable: true,
    center: true,
    tag: "nombre",
    cell: (row) => row.name,
  },
  {
    id: "eMail",
    omit: false,
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    type: "text",
    minWidth: "180px",
    tag: "id",
    visible: true,
    center: true,
    show: true,
    cell: (row) => row.email,
  },
  {
    id: "address",
    omit: false,
    name: "Dirección",
    selector: (row) => row.address,
    visible: true,
    sortable: true,
    center: true,
    tag: "direccion",
    cell: (row) => row.address,
  },
  {
    id: "phone",
    omit: false,
    name: "Número de teléfono",
    selector: (row) => row.phone,
    visible: true,
    sortable: true,
    center: true,
    tag: "numeroDeTelefono",
    cell: (row) => row.phone,
  },
];

export const orderDigitlAssets = columnsActivosDigitales.map(
  (ele) => ele.selector
);

export const digitalMedium = [
  {
    value: 1,
    label: "Facebook",
  },
];
