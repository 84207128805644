import { useState, useEffect, useRef } from "react";
import * as APILA from "helpers/apiLA";
import makeAnimated from "react-select/animated";
import MySelect from "components/CampOneStep/MySelect";
import Editor from "components/EditorText";
import CreatableSelect from "libs/select/creatable";
import "../style.scss";
import { withUtils } from "utils";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import _ from "lodash";
import { components } from "react-select";
import { Input, FormFeedback } from "reactstrap";
import { updateEmailValidation } from "redux/actions/email.actions";
import PageLoader from "components/page_loader";

const Row = ({ dataRow, position, onRemove, onChange, opciones }) => {
  const fechas = ["createAt", "fechaIni", "updateAt", "lastModified"];
  let campo = dataRow.campo !== "" ? dataRow.campo : "DEFAULT";
  let operador = dataRow.operador !== "" ? dataRow.operador : "";
  let contraValidacion =
    dataRow.contraValidacion !== "" ? dataRow.contraValidacion : "";
  let condicional = dataRow.condicional !== "" ? dataRow.condicional : "";
  let positionD = dataRow.position;

  const [data, setData] = useState({
    campo: dataRow.campo,
    operador: dataRow.operador,
    contraValidacion: dataRow.contraValidacion,
    condicional: dataRow.condicional,
    position: dataRow.position,
  });
  useEffect(() => {
    if (
      data.contraValidacion !== dataRow.contraValidacion ||
      data.campo !== dataRow.campo ||
      data.condicional !== dataRow.condicional
    ) {
      onChange(data);
    }
  }, [data]);
  useEffect(() => {
    let datos = {
      campo: campo,
      operador: operador,
      contraValidacion: contraValidacion,
      condicional: condicional,
      position: positionD,
    };
    setData(datos);
  }, [dataRow]);

  const hanleChangeOptions = (e) => {
    e.persist();
    let { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <label className="area1">
        Campo
        <select
          name="campo"
          onChange={(e) => hanleChangeOptions(e)}
          value={data.campo}
        >
          {opciones.length > 0 ? (
            <>
              <option value={"DEFAULT"} disabled>
                selecciona una opción:
              </option>

              {opciones.map((ele, idx) => {
                if (ele.value !== "userName" && ele.value !== "accountName") {
                  return (
                    <option value={ele.value} key={idx}>
                      {ele.label}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </>
          ) : (
            <option>No hay opciones</option>
          )}
        </select>
      </label>
      <label className="operador area2">
        Operador
        <select
          value={data.operador}
          name="operador"
          onChange={(e) => hanleChangeOptions(e)}
          placeholder="selecciona una opción"
        >
          <option value={"igual a"}>Igual a</option>
          <option value={"diferente"}>!=</option>
          <option value={"Dentro"}>Dentro</option>
          <option value={"mayor que"}>mayor que</option>
          <option value={"menor que"}>menor que</option>
        </select>
      </label>
      <label className="area3">
        Campo contra validación
        <Input
          type={fechas.includes(data.campo) ? "date" : "text"}
          name="contraValidacion"
          className="inputEmails"
          bsSize="sm"
          value={data.contraValidacion}
          onChange={(e) => hanleChangeOptions(e)}
          placeholder="Ingresa la validación"
          invalid={false}
        />
        <FormFeedback>Ingresa un cuerpo de mensaje</FormFeedback>
      </label>
      <label className="area4">
        <select
          name="condicional"
          value={data.condicional}
          onChange={(e) => hanleChangeOptions(e)}
        >
          <option value={"y"}>y</option>
          <option value={"o"}>o</option>
        </select>
      </label>

      <button className="btns area5" onClick={() => onRemove(position)}>
        <i className="fas fa-times-circle"></i>
      </button>

      <button className="area6"></button>
    </>
  );
};

const ModalEditData = (props) => {
  const modules = [
    "Campañas",
    "Categorías",
    "Publicos",
    "Presupuestos",
    "Reportes",
    "Roles",
    "Cuentas",
    "Permisos",
  ];
  const modulesByRol = [];
  const [error, setError] = useState({
    module: false,
    channel: false,
    action: false,
    restriction: false,
    userByRol: false,
    sender: false,
    recipients: false,
    affair: false,
    messajeBody: false,
    campo: false,
    operador: false,
    contraValidacion: false,
    condicional: false,
    position: false,
  });
  const [disabledRoles, setDisabledRoles] = useState(true);
  const [bodyMensaje, setBodyMensaje] = useState({
    texto: "",
    html: "",
    content: "",
    change: false,
  });
  const account = props.currentAccount;
  const permissionActions = {
    Campañas: "Campañas",
    Categorías: "Categorías",
    Publicos: "Publicos",
    Presupuestos: "Presupuestos",
    Reportes: "Reportes",
    Roles: "Roles",
    Cuentas: "Cuentas",
    Permisos: "Permisos",
  };
  props.permissions.forEach((item) => {
    if (item.view && permissionActions[item.name]) {
      modulesByRol.push(permissionActions[item.name]);
    }
  });

  const [roles, setRoles] = useState([]);
  const [rolSelected, setRolSelected] = useState(props.data.userByRol);
  const [dataEdit, setDataEdit] = useState(props.data);
  const [opciones, setOpciones] = useState([]);
  const [rows, setRows] = useState(props.data.validations);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bodyOptions, setBodyOptions] = useState({
    position: 0,
    value: "",
    refresh: false,
    delete: false,
  });
  const optionsValidations = [
    {
      id: "",
      name: "Campañas",
      options: [
        {
          label: "Nombre",
          value: "name",
          check: false,
        },
        {
          label: "Usuario de creación",
          value: "userCreate",
          check: false,
        },
        {
          label: "Usuario de aprobación",
          value: "approvalUser",
          check: false,
        },
        {
          label: "Usuario de edición",
          value: "userEdit",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Fecha de inicio",
          value: "startDate",
          check: false,
        },
        {
          label: "Archivos",
          value: "files",
          check: false,
        },
        {
          label: "Fecha de fin",
          value: "endDate",
          check: false,
        },
        {
          label: "Id",
          value: "id",
          check: false,
        },
        {
          label: "Orden de venta",
          value: "id_sale",
          check: false,
        },
        {
          label: "País",
          value: "country",
          check: false,
        },
        {
          label: "Elementos editados",
          value: "editedElements",
          check: false,
        },
        {
          label: "Medio",
          value: "medium",
          check: false,
        },
        {
          label: "Objetivo",
          value: "banner",
          check: false,
        },
        {
          label: "Formato",
          value: "format",
          check: false,
        },
        {
          label: "Público",
          value: "public",
          check: false,
        },
        {
          label: "Presupuesto invertido",
          value: "budget",
          check: false,
        },
        {
          label: "Etiqueta",
          value: "tag",
          check: false,
        },
        {
          label: "Categorías",
          value: "categories",
          check: false,
        },
        {
          label: "Copy",
          value: "copy",
          check: false,
        },
        {
          label: "Observaciones",
          value: "observation",
          check: false,
        },
        {
          label: "Activo digital",
          value: "digitalAsset",
          check: false,
        },
      ],
    },
    {
      id: "",
      name: "Categorías",
      options: [
        {
          label: "Nombre",
          value: "name",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
        {
          label: "Fecha de creación",
          value: "createAt",
          check: false,
        },
        {
          label: "Última Actualización",
          value: "updateAt",
          check: false,
        },
        {
          label: "Status",
          value: "active",
          check: false,
        },
      ],
    },
    {
      id: "",
      name: "Publicos",
      options: [
        {
          label: "Nombre",
          value: "name",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
        {
          label: "Fecha de creación",
          value: "createAt",
          check: false,
        },
        {
          label: "Última Actualización",
          value: "updateAt",
          check: false,
        },
        {
          label: "Estado",
          value: "active",
          check: false,
        },
      ],
    },
    {
      id: "",
      name: "Creatividad",
      options: [
        {
          label: "Nombre de Archivo",
          value: "name",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
        {
          label: "Última Actualización",
          value: "lastModified",
          check: false,
        },
        {
          label: "Formato",
          value: "format",
          check: false,
        },
        {
          label: "Tamaño",
          value: "size",
          check: false,
        },
      ],
    },
    {
      id: "",
      name: "Presupuestos",
      options: [
        {
          label: "Nombre",
          value: "title",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
        {
          label: "Fecha de creación",
          value: "createAt",
          check: false,
        },
        {
          label: "Última actualización",
          value: "updateAt",
          check: false,
        },
        {
          label: "Presupuesto total",
          value: "amount",
          check: false,
        },
        {
          label: "Presupuesto implementado",
          value: "availableC",
          check: false,
        },
        {
          label: "Presupuesto disponible",
          value: "available",
          check: false,
        },
        {
          label: "Estado",
          value: "active",
          check: false,
        },
      ],
    },
    {
      id: "",
      name: "Reportes",
      options: [
        {
          label: "Enlace",
          value: "link",
        },
        {
          label: "Nombre",
          value: "name",
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
      ],
    },
    // {
    //   id: "",
    //   name: "Usuarios",
    //   options: [
    //     {
    //       label: "Nombre",
    //       value: "name",
    //       check: false,
    //     },
    //     {
    //       label: "Email",
    //       value: "email",
    //       check: false,
    //     },
    //     {
    //       label: "Cuenta",
    //       value: "accountName",
    //       checked: false
    //     },
    //     {
    //       label: "Usuario",
    //       value: 'userName',
    //       checked: false
    //     },
    //   ],
    // },
    {
      id: "",
      name: "Roles",
      options: [
        {
          label: "Usuario",
          value: "userName",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Tipo de rol",
          value: "type",
          checked: false,
        },
      ],
    },
    {
      id: "",
      name: "Cuentas",
      options: [
        {
          label: "Nombre",
          value: "name",
          check: false,
        },
        {
          label: "Fanpage",
          value: "fanpage",
          check: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
        {
          label: "Fecha de creación",
          value: "createAt",
          check: false,
        },
        {
          label: "Crédito",
          value: "credit",
          check: false,
        },
      ],
    },
    {
      id: "",
      name: "Permisos",
      options: [
        {
          label: "Usuario asignado",
          value: "name",
          check: false,
        },
        {
          label: "Rol",
          value: "role",
          check: false,
        },
        {
          label: "Cuenta asignada",
          value: "account",
          checked: false,
        },
        {
          label: "Cuenta",
          value: "accountName",
          checked: false,
        },
        {
          label: "Usuario",
          value: "userName",
          checked: false,
        },
        // {
        //   label: "Tipo de rol",
        //   values: "type",
        //   check: false,
        // },
      ],
    },
  ];

  useEffect(() => {
    getRolesNames();
    let opcionesSeleccionadas =
      dataEdit.messajeBody.texto.match(/\$\{([a-zA-Z_]+)\}/gi);

    let dataOp = optionsValidations.find((ele) => ele.name === dataEdit.module);
    dataOp.options.forEach((ele) => {
      let name = "${" + ele.value + "}";
      if (opcionesSeleccionadas) {
        if (opcionesSeleccionadas.includes(name)) {
          ele.check = true;
        }
      }
    });

    setBodyMensaje({
      texto: dataEdit.messajeBody.texto,
      content: dataEdit.messajeBody.content,
      html: dataEdit.messajeBody.html,
      change: true,
    });

    let filteredOptions = dataOp.options;
    if (dataEdit.isDefault && dataEdit.edit) {
      //No elimina ninguna opcion
      filteredOptions = filteredOptions.filter(
        (ele) => ele.value !== "userCreate"
      );
    } else if (dataEdit.isDefault && !dataEdit.edit) {
      filteredOptions = filteredOptions.filter(
        (ele) => ele.value !== "editedElements" && ele.value !== "userEdit"
      );
    } else {
      filteredOptions = filteredOptions.filter(
        (ele) => ele.value !== "editedElements" && ele.value !== "userCreate"
      );
    }

    setOpciones(filteredOptions);
    if (dataEdit.restriction === "Usuarios con acceso") {
      setDisabledRoles(false);
    }
    setDisabled(dataEdit.isDefault);
  }, [dataEdit.module]);

  const getRolesNames = async () => {
    let responseRoleNames = await APILA.getRoleNames({
      account: props.currentAccount,
    });
    let data = responseRoleNames.data.roleNames;
    let options = [];
    data.forEach((ele) => {
      let body = {
        id: ele.id,
        value: ele.id,
        label: ele.name,
      };
      options.push(body);
    });
    setRoles(options);
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const animatedComponents = makeAnimated();

  const handleChangeConfigLets = () => {};

  const areValidateImputs = () => {
    const mErrors = {
      module: dataEdit.module === "",
      channel: dataEdit.channel === "",
      action: dataEdit.action === "",
      userByRol:
        dataEdit.restriction === "Usuarios con acceso"
          ? dataEdit.userByRol.length === 0
          : false,
      sender: dataEdit.sender === "",
      recipients: dataEdit.recipients?.length === 0,
      affair: dataEdit.affair === "",
      messajeBody: dataEdit.messajeBody === "",
    };
    console.log("Los errores actuales de edicion", { mErrors });

    setError((prev) => ({
      ...prev,
      ...mErrors,
    }));

    return !_.some(mErrors, (e) => e);
  };

  const onSave = async () => {
    if (!areValidateImputs()) return;
    setIsLoading(true);
    // dataConfigLets
    let bodyConfig = {
      id: dataEdit.idConfigLetsEmail,
      module: dataEdit.module,
      channel: dataEdit.channel,
      action: dataEdit.action,
      restriction: dataEdit.restriction,
      account: account,
      userByRol: dataEdit.userByRol,
    };
    //dataEmail
    let bodyConfigEmail = {
      id: dataEdit.idConfigEmail,
      sender: dataEdit.sender,
      recipients: dataEdit.recipients,
      affair: dataEdit.affair,
      account: account,
      messajeBody: bodyMensaje,
    };
    //bodyValidations
    let bodyValidations = {
      id: dataEdit.idValidations,
      validations: rows,
      account: account,
    };
    //objeto global
    let bodyFinal = {
      id: dataEdit.id,
      idConfigLets: bodyConfig.id,
      idConfigEmail: bodyConfigEmail.id,
      idValidations: bodyValidations.id,
      account: account,
      default: disabled,
      date: new Date().toISOString().substring(0, 10),
    };
    props
      ._updateEmailValidation(
        bodyConfig,
        bodyConfigEmail,
        bodyValidations,
        bodyFinal,
        props.currentAccount
      )
      .then((res) => {
        props.refresh(true);
        props.showModal(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const addEmailrecipients = (value, actionMeta) => {
    const { action, removedValue } = actionMeta;
    const init = [
      {
        value: "eadvertising@lets-advertise.com",
        label: "eadvertising@lets-advertise.com",
      },
    ];
    const name = "recipients";
    if (action === "remove-value" && removedValue && dataEdit.isDefault) {
      const removedEmail = removedValue.label;
      if (removedEmail === "eadvertising@lets-advertise.com") {
        return;
      }
    } else if (action === "clear" && dataEdit.isDefault) {
      setDataEdit((prev) => ({ ...prev, [name]: init }));
      return;
    }
    let idx = value ? value.length - 1 : 0;
    let dataRep = value ? value : [];
    if (value) {
      if (value.length !== 0) {
        setError((prev) => ({ ...prev, recipients: false }));
      }
    }
    let verificar =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    let emailValidation = true;

    try {
      emailValidation = verificar.test(value[idx].value);
    } catch (err) {}

    if (!emailValidation) {
      Swal.fire({
        title: "Ups!",
        text: "Ingresa un correo válido",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    } else if (emailValidation) {
      setDataEdit((prev) => ({ ...prev, [name]: dataRep }));
    } else {
      setDataEdit((prev) => ({ ...prev, [name]: dataRep }));
    }
  };

  const handleSelectRol = (selected, id) => {
    let name = "userByRol";
    let arrAux = [];
    let data = roles;
    try {
      if (selected != null) {
        if (selected.length > 0) {
          setError((prev) => ({ ...prev, userByRol: false }));
          selected.forEach((ele) => {
            let dataSel = data.filter((el) => el.id === ele.id);
            arrAux = [...arrAux, ...dataSel];
          });
        }
        setRolSelected(arrAux);
        setDataEdit((prev) => ({ ...prev, [name]: arrAux }));
      } else {
        setRolSelected([]);
        setDataEdit((prev) => ({ ...prev, [name]: [] }));
      }
    } catch {}
  };

  const changeData = (e) => {
    e.persist();
    let { value, name } = e.target;
    if (value === "Usuarios con acceso") {
      setDisabledRoles(false);
    } else {
      setDisabledRoles(true);
    }
    if (value !== "") {
      setError((prev) => ({ ...prev, [name]: false }));
      setDataEdit((prev) => ({ ...prev, [name]: value }));
    }
  };

  const changeOptionBody = (value) => {
    let active = false;

    opciones.forEach((ele) => {
      if (ele.value === value) {
        active = !ele.check;
        ele.check = !ele.check;
      }
    });

    updateBodyOptions(value, active);
  };

  const updateBodyOptions = (value, active) => {
    if (active) {
      setBodyOptions((state) => {
        return {
          value: "${" + value + "}",
          refresh: true,
          delete: false,
        };
      });
    } else {
      setBodyOptions((state) => {
        return {
          value: "${" + value + "}",
          refresh: false,
          delete: true,
        };
      });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleAddValidation = (data) => {
    setRows((state) => {
      const upsertData = [...state];

      let idx = upsertData.findIndex((ele) => ele.position === data.position);

      upsertData[idx] = data;
      return upsertData;
    });
  };

  const handleOnRemove = (index) => {
    let cols = rows.filter((ele) => ele.position !== index);
    cols.forEach((ele, position) => {
      ele.position = position;
    });
    setRows(cols);
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...rows];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setRows(copyListItems);
  };

  const addValidation = () => {
    let idx = rows.length - 1;
    let ultimaPosition = 0;
    if (rows.length !== 0) {
      idx = rows.length - 1;
      ultimaPosition = rows[idx].position;
    }

    let data = {
      campo: "",
      operador: "igual a",
      contraValidacion: "",
      condicional: "y",
      position: ultimaPosition + 1,
    };

    setRows([...rows, data]);
  };

  const changeBody = (texto, html, content, idx) => {
    let value = {
      texto: texto,
      html: html,
      content: content,
      refresh: false,
    };
    setBodyMensaje(value);
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="blur-div">
          <div className="content-modal">
            <div className="header-modal">
              <h3 className="titleModal">Configuración Alertas</h3>
            </div>

            <div className="body-modal">
              <section className="body-part">
                <label>
                  Canal
                  <select
                    value={dataEdit.channel}
                    disabled={disabled}
                    placeholder="selecciona una opción"
                  >
                    <option>Email</option>
                  </select>
                  {error.channel && (
                    <span style={{ color: "red" }}>Agrega un canal</span>
                  )}
                </label>
                <label>
                  Módulos
                  <select
                    defaultValue={dataEdit.module}
                    name="module"
                    onChange={(e) => changeData(e)}
                    disabled={disabled}
                  >
                    <option value={"DEFAULT"} disabled>
                      selecciona un módulo:
                    </option>
                    {(props.isSuperAdmin ? modules : modulesByRol).map(
                      (ele, idx) => (
                        <option value={ele} key={idx}>
                          {ele}
                        </option>
                      )
                    )}
                  </select>
                  {error.module && (
                    <span style={{ color: "red" }}>Agrega un modulo</span>
                  )}
                </label>
                <label>
                  Acción
                  <select
                    defaultValue={dataEdit.action}
                    name="action"
                    disabled={disabled}
                    onChange={(e) => changeData(e)}
                    placeholder="selecciona una opción"
                  >
                    <option value={"DISABLED"} disabled>
                      selecciona una acción:
                    </option>
                    <option value={"Creación"}>Creación</option>
                    <option value={"Lectura"}>Lectura</option>
                    <option value={"Edición"}>Edición</option>
                    <option value={"Eliminación"}>Eliminación</option>
                  </select>
                  {error.action && (
                    <span style={{ color: "red" }}>Agrega una acción</span>
                  )}
                </label>
                <p className="labelAction">Condición</p>
                <div className="containerRows">
                  {rows.map((row, index) => (
                    <div className="containerLabels" key={index}>
                      <Row
                        opciones={opciones}
                        position={row.position}
                        onChange={(data) => handleAddValidation(data)}
                        onRemove={handleOnRemove}
                        key={index}
                        dataRow={row}
                      />
                      <button
                        className="area6"
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        draggable
                      >
                        <i className="fas fa-grip-vertical"></i>
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  className="addButton"
                  onClick={addValidation}
                  disabled={disabled}
                >
                  {" "}
                  Agregar
                </button>
              </section>
              <section className="body-part">
                <label>
                  Remitente
                  <select
                    name="restriction"
                    disabled={disabled}
                    onChange={(e) => {
                      handleChangeConfigLets(e);
                    }}
                    defaultValue={"DEFAULT"}
                    placeholder="selecciona una opción"
                  >
                    <option value={"DEFAULT"}>info@lets-advertise.com</option>
                  </select>
                  {error.sender && (
                    <span style={{ color: "red" }}>Agrega un remitente</span>
                  )}
                </label>
                <label>
                  Destinatarios específicos
                  <CreatableSelect
                    value={dataEdit.recipients}
                    name={"recipients"}
                    options={[]}
                    disabled={disabled}
                    onChange={addEmailrecipients}
                    placeholder="Ingresa tus destinatarios"
                  />
                  {error.recipients && (
                    <span style={{ color: "red" }}>Agrega un destinatario</span>
                  )}
                </label>
                <label>
                  Usuarios de la plataforma
                  <select
                    value={dataEdit.restriction}
                    name="restriction"
                    onChange={(e) => {
                      changeData(e);
                    }}
                    placeholder="selecciona una opción"
                  >
                    {props.isSuperAdmin && (
                      <option value={"Todos los usuarios"}>
                        Todos los usuarios
                      </option>
                    )}

                    <option value={"Usuarios con acceso"}>
                      Usuarios con acceso
                    </option>
                    <option value={"Ningun usuario"}>Ningún usuario</option>
                  </select>
                  {error.restriction && (
                    <span style={{ color: "red" }}>Agrega la restricción</span>
                  )}
                </label>
                {!disabledRoles && (
                  <label>
                    Usuarios por rol
                    <MySelect
                      placeholder="Selecciona un rol"
                      options={roles}
                      isMulti={true}
                      isDisabled={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option, MultiValue, animatedComponents }}
                      onChange={(e) => {
                        handleSelectRol(e, e);
                      }}
                      allowSelectAll={true}
                      value={rolSelected}
                    />
                    {error.userByRol && (
                      <span style={{ color: "red" }}>Agrega un rol</span>
                    )}
                  </label>
                )}

                <label>
                  Asunto
                  <input
                    type="text"
                    name="affair"
                    value={dataEdit.affair}
                    disabled={disabled}
                    onChange={(e) => changeData(e)}
                    placeholder="Asunto"
                    className="inputEmails"
                  />
                  {error.affair && (
                    <span style={{ color: "red" }}>Agrega un asunto</span>
                  )}
                </label>
                <label>
                  Cuerpo del mensaje
                  {error.messajeBody && (
                    <span style={{ color: "red" }}>
                      Agrega un cuerpo para el mensaje
                    </span>
                  )}
                </label>
                <div className="optionsBody">
                  {opciones.map((ele, idx) => (
                    <p key={idx}>
                      {ele.label}
                      <input
                        name="messajeBody"
                        type="checkbox"
                        // disabled={disabled}
                        value={ele.value}
                        checked={ele.check}
                        onChange={(e) => {
                          changeOptionBody(e.target.value);
                        }}
                      />
                    </p>
                  ))}
                </div>
                <Editor
                  setBody={setBodyMensaje}
                  setBodyOption={setBodyOptions}
                  // readOnly={disabled}
                  bodyPosition={bodyOptions}
                  deletePosition={bodyOptions.delete}
                  newPosition={bodyOptions.refresh}
                  value={bodyMensaje.content}
                  disabled={disabled}
                  refresh={bodyMensaje.change}
                  change={changeBody}
                />
              </section>
            </div>

            <div className="footer-Modal">
              <button
                className="btn btn_cancel"
                onClick={(e) => {
                  props.showModal(false);
                }}
              >
                Cancelar
              </button>
              <button className="btn btn_save" onClick={onSave}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _updateEmailValidation: (
    configLetsEmail,
    configEmail,
    validations,
    objEmails,
    account
  ) =>
    dispatch(
      updateEmailValidation(
        configLetsEmail,
        configEmail,
        validations,
        objEmails,
        account
      )
    ),
});

// export default ConfigEmails;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ModalEditData));
