import * as Actions from "../actions/templates.actions";

const initialState = {
  edit: {},
  list: [],
  filter_text: "",
  editVisualizer: {},
  editActivate: false,
  listMediumBanner: [],
  listGoals: [],
  listFormat: [],
  errorsAudience: [],
  errorsBudget: [],
  errorsProduct: [],
  errorsImplement: [],
  refreshBudgets: [],
  filteredBudgets: [],
  currentIdPautaEdit: "",
  buttonImplement: false,
  listAudiences: [],
  listRegions: [],
  userInfo: {},
  stepsDoubleClick: 0,
  name: "",
  editField: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.TEMPLATES_SET_EDIT:
      return {
        ...state,
        edit: action.edit,
      };
    case Actions.TEMPLATES_CLEAR_EDIT:
      return {
        ...state,
        edit: {},
      };
    case Actions.TEMPLATES_CLEAR:
      return {
        ...state,
        list: [],
      };
    case Actions.TEMPLATES_ADD_TOTAL:
      return {
        ...state,
        list: action.templates,
      };
    case Actions.TEMPLATES_SET_EDIT_VISUALIZER:
      return {
        ...state,
        editVisualizer: action.visualizer,
      };
    case Actions.TEMPLATES_UPDATE_ONE_VISUALIZER:
      const listItems = state.list;
      const idx = listItems.findIndex(
        (ele) => ele.id === action.updateVisualizer.id
      );
      listItems[idx] = { ...action.updateVisualizer };
      return {
        ...state,
        list: listItems,
      };

    case Actions.TEMPLATES_CLEAR_EDIT_VISUALIZER:
      return {
        ...state,
        editVisualizer: {},
      };
    case Actions.TEMPLATES_SET_EDIT_ACTIVE:
      return {
        ...state,
        editActivate: action.editActivate,
      };
    case Actions.TEMPLATES_SET_LIST_MEDIUMBANNER:
      return {
        ...state,
        listMediumBanner: action.listMediumBanner,
      };
    case Actions.TEMPLATES_SET_LIST_GOALS:
      return {
        ...state,
        listGoals: action.listGoals,
      };
    case Actions.TEMPLATES_SET_LIST_FORMAT:
      return {
        ...state,
        listFormat: action.listFormat,
      };
    case Actions.TEMPLATES_SET_LIST_ERRORS_AUDIENCE:
      return {
        ...state,
        errorsAudience: action.errorsAudience,
      };
    case Actions.TEMPLATES_SET_LIST_ERRORS_BUDGET:
      return {
        ...state,
        errorsBudget: action.errorsBudget,
      };
    case Actions.TEMPLATES_SET_LIST_ERRORS_PRODUCT:
      return {
        ...state,
        errorsProduct: action.errorsProduct,
      };
    case Actions.TEMPLATES_SET_LIST_ERRORS_IMPLEMENT:
      return {
        ...state,
        errorsImplement: action.errorImplement,
      };
    case Actions.TEMPLATES_SET_LIST_REFRESH_BUDGETS:
      return {
        ...state,
        refreshBudgets: action.refreshBudgets,
      };
    case Actions.TEMPLATES_SET_FILTERED_BUDGETS:
      return {
        ...state,
        filteredBudgets: action.filteredBudgets,
      };
    case Actions.TEMPLATES_SET_CURRENT_ID_PAUTA:
      return {
        ...state,
        currentIdPautaEdit: action.currentIdPautaEdit,
      };
    case Actions.TEMPLATES_SET_IMPLEMENT:
      return {
        ...state,
        buttonImplement: action.implement,
      };
    case Actions.TEMPLATES_SET_LIST_AUDIECNES:
      return {
        ...state,
        listAudiences: action.audiences,
      };
    case Actions.TEMPLATES_SET_LIST_REGIONS:
      return {
        ...state,
        listRegions: action.regions,
      };
    case Actions.TEMPLATES_SET_LIST_USER_INFO:
      return {
        ...state,
        userInfo: action.user,
      };
    case Actions.TEMPLATES_SET_LIST_STEP:
      return {
        ...state,
        stepsDoubleClick: action.step,
      };
    case Actions.TEMPLATES_SET_EDIT_FIELD:
      return {
        ...state,
        editField: action.editField,
      };
    case Actions.TEMPLATES_SET_NAME_FILED:
      return {
        ...state,
        name: action.name,
      };
    default:
      return state;
  }
};
