import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { useOutsideAlerter } from "components/dashboardPwa/outsideCLick";
import { connect } from "react-redux";

const DetailColEdit = (
  props
  // { row, text, dataSelect, deleteV, id, nameColumn,
  // onEditValue, editInfo, editable, type, reActivar, deleteData, activate, activeData, permissions, edit }
) => {
  const [valueE, setvalueE] = useState(props.text);
  const [data, setData] = useState([]);
  const { visible, setVisible, ref } = useOutsideAlerter(false);
  const [dataOptions, setDataOptions] = useState([]);
  const [permitEditBudgetOld, setPermitEditBudgetOld] = useState(false);
  useEffect(() => {
    let find = props.accounts?.find(({ id }) => id === props.currentAccount);
    if (find && "permitEditBudgetOld" in find) setPermitEditBudgetOld(true);
    else setPermitEditBudgetOld(false);
    // let existPermitEditBudgetOld = "permitEditBudgetOld" in find // verifico si existe la propiedad
    // if (existPermitEditBudgetOld) setPermitEditBudgetOld(true)
    // else setPermitEditBudgetOld(false)
    if (data.length > 0) {
      props.onEditValue(data).then(() => {
        setvalueE(props.text);
      });
      setData([]);
    }
  }, [visible]);
  useEffect(() => {
    if (props.dataSelect) {
      setDataOptions(props.dataSelect);
    }
  }, [props.dataSelect]);

  const editData = (valor) => {
    setvalueE(valor);
    let dataAdd = {
      id: props.id,
      column: props.nameColumn,
      value: valor,
    };
    setData([dataAdd]);
  };
  const optionsView = () => {
    if (props.editable) {
      setVisible(true);
    }
  };

  const item = () => {
    if (props.type == "select") {
      let result = [];
      if (props.dataSelect) {
        result = dataOptions.filter((item, index) => {
          return dataOptions.indexOf(item) === index;
        });
      }
      return (
        <>
          <select
            className="option-select"
            defaultValue={"DEFAULT"}
            onChange={(e) => editData(e.target.value)}
          >
            <option className="option-options" value="DEFAULT" disabled>
              Selecciona una opcion:
            </option>
            {result.map((data2, idx) => (
              <option className="option-options" key={idx} value={data2}>
                {data2}
              </option>
            ))}
          </select>
        </>
      );
    }
    if (props.type == "text") {
      return (
        <>
          <input
            className="name-fc"
            type="text"
            onChange={(e) => editData(e.target.value)}
            value={valueE}
          />
        </>
      );
    }
  };

  return (
    <>
      {visible ? (
        <div ref={ref}>{item()}</div>
      ) : (
        <div
          className="content-firstColumn"
          onDoubleClick={() => optionsView()}
        >
          <div className="iconBand">
            {props.edit && props.permissions.edit && props.activate && (
              <Tooltip title="Editar" placement="top" color="#002448">
                <i onClick={props.editInfo} className="fas fa-edit" />
              </Tooltip>
            )}
            {props.permissions.delete && (
              <Tooltip
                title={props.activate ? "Eliminar" : "Reactivar"}
                placement="top"
                color="#002448"
              >
                <i
                  className={
                    props.activate ? "fas fa-trash" : "fas fa-trash-restore-alt"
                  }
                  onClick={props.activate ? props.deleteData : props.activeData}
                />
              </Tooltip>
            )}
            {props.flag && (
              <Tooltip title="Establecer meta" placement="top" color="#002448">
                <i
                  className="fas fa-flag"
                  onClick={(e) => props.flagAction()}
                />
              </Tooltip>
            )}
            {props.check && props.permissions.approve && (
              <Tooltip
                title="Enviar a implementar"
                placement="top"
                color="#002448"
              >
                <i
                  onClick={props.approveConfirmation}
                  className="fas fa-check"
                />
              </Tooltip>
            )}
            {props.prints && (
              <Tooltip title="Ver prints" placement="top" color="#002448">
                <i onClick={props.getPrints} className="fas fa-images" />
              </Tooltip>
            )}
            {props.duplicate && props.permissions.create && (
              <Tooltip title="Duplicar" placement="top" color="#002448">
                <i
                  onClick={props.sendDuplicate}
                  className="fa fa-files-o"
                  aria-hidden="true"
                />
              </Tooltip>
            )}
            {props.editAttachment && props.permissions.edit && (
              <Tooltip title="Editar adjunto" placement="top" color="#002448">
                <i
                  onClick={props.editAttachmentAction}
                  className="fa fa-paperclip"
                  aria-hidden="true"
                />
              </Tooltip>
            )}
          </div>
          <p className="name-fc22">{props.text}</p>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentAccount: state.accounts.default,
});

export default connect(mapStateToProps, null)(DetailColEdit);
