import { v4 } from 'uuid'
import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'
import { showLoader } from './loader.actions'

export const ADD_BUDGET_SPENT = 'BUDGET_SPENT:ADD'
export const CLEAN_BUDGET_SPENT = 'BUDGET_SPENT:CLEAN'
export const BUDGET_CHANGE_TOTAL = 'BUDGET_CHANGE_TOTAL'

const TAG = 'BUDGET_SPENT:Actions'



export const cleanBudgetSpent = () => (dispatch) => {
  dispatch({
    type: CLEAN_BUDGET_SPENT,
  })
}

export const getBudgetSpent = (body) => dispatch => {
  
    dispatch(cleanBudgetSpent());

    console.log("getBudgetSpent action body.data",body);  

    let result = body.map(({ idMixDetaill }) => idMixDetaill);
    var filtered_array = [];

        result.forEach(function (element, index, array) {
            if (element>0) {
                filtered_array.push(element);    
            }
        });
    console.log("getBudgetSpent action ", filtered_array);
     APILA.getBudgetByidmix(filtered_array)
      .then(response => {
        Logger.log(TAG, 'getBudgetSpent response', response)
        let { budget_spent } = response.data
         dispatch(addTotal(response.data.spent_budget.length))
         dispatch(addBudget(response.data.spent_budget)) 
      })
      .catch(err => {
        Logger.log(TAG, 'error getBudgetSpent', err)
      })
      .then(() => {
        // dispatch()
        

      })
  }


  const addTotal = total => {
    
    return dispatch => {
      dispatch({
        type: BUDGET_CHANGE_TOTAL,
        total
      })
    }
  }
  
  const addBudget = budget_spent => {
    
    return dispatch => {
      dispatch({
        type: ADD_BUDGET_SPENT,
        budget_spent
      })
    }
  }
  