import { withRouter } from "react-router-dom";
import { withUtils } from "utils";
import { useState, useEffect } from "react";
import ReactDataTable from "@easygosanet/react-datatable";
import { v4 } from "uuid";
import "./style.scss";
import _, { isEmpty } from "lodash";
import Swal from "sweetalert2";
import { orderBy } from "lodash-es";
import * as APILA from "helpers/api";
import * as API from "helpers/apiLA";
import PageLoader from "components/page_loader";
import { connect } from "react-redux";
import analytics from "../../helpers/analytics";
import { Auth } from "aws-amplify";
import * as Modal from "redux/actions/modal.actions";
import { useLocation, useHistory } from "react-router-dom";

const Content = (props) => {

  //Hooks para el push al pathname
  const location = useLocation()
  const history = useHistory()

  // Instancias para poder buscar en la url
  const currentUrl = new URLSearchParams(location.pathname)
  const currentPageParams = Number(currentUrl.get('page'))
  const accountParams = currentUrl.get('account')
  const [currentPage, setCurrentPage] = useState(currentPageParams || 1);

  const [nameToShow, setNameToShow] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: false,
  });

  const changeUrl = ({ pagination = currentPage }) => {
    const validateAccount = accountParams === undefined || accountParams === null || accountParams.length == 0 ? props.currentAccount : accountParams
    history.push({
      pathname: `/configuracion/etiquetas/account=${validateAccount}&page=${pagination}`
    })
  }

  const [checkeds, setCheckeds] = useState({
    nameShown: "",
    state: 0,
    columnsSelected: [],
    account: "",
    id: "",
  });

  const [columnsList, setColumnsList] = useState([
    {
      nameShown: "ID",
      columns: "id",
      checked: false,
    },
    {
      nameShown: "Nombre",
      columns: "name",
      checked: false,
    },
    {
      nameShown: "FechaInicio",
      columns: "fechaIni",
      checked: false,
    },
    {
      nameShown: "FechaFin",
      columns: "fechafin",
      checked: false,
    },
    {
      nameShown: "Orden de Venta",
      columns: "id_sale",
      checked: false,
    },
    {
      nameShown: "Estado",
      columns: "status",
      checked: false,
    },
    {
      nameShown: "País",
      columns: "country",
      checked: false,
    },
    {
      nameShown: "Medio",
      columns: "medio",
      checked: false,
    },
    {
      nameShown: "Cliente",
      columns: "client",
      checked: false,
    },
    {
      nameShown: "Banner",
      columns: "banner",
      checked: false,
    },
    /*  {
      nameShown: 'Tipo de compra',
      columns: 'purchase_unit',
      checked: false
    }, */
    {
      nameShown: "Presupuesto Neto",
      columns: "budget",
      checked: false,
    },
    {
      nameShown: "Presupuesto Invertido",
      columns: "swc",
      checked: false,
    },
    /*  {
      nameShown: 'Presupuesto Consumido',
      columns: 'budgetConsumido',
      checked: false
    },
    {
      nameShown: 'Presupuesto consumido con comisión',
      columns: 'swc',
      checked: false
    },
    {
      nameShown: 'Presupuesto sin consumir',
      columns: 'budgetSinConsumir',
      checked: false
    }, */
    {
      nameShown: "Públicos",
      columns: "publico",
      checked: false,
    } /* ,
    {
      nameShown: 'PresupuestoNeto',
      columns: 'budgetNeto',
      checked: false
    } */,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page)
    changeUrl({
      pagination: page
    })
  }

  const [data, setData] = useState([]);
  const onChange = (item) => {
    let auxCol = columnsList;
    let auxSelect;
    auxCol.forEach((el) => {
      if (el.nameShown === item.nameShown) {
        if (!el.checked === true) {
          auxSelect = checkeds;
          auxSelect.columnsSelected.push(item.nameShown);
        } else {
          auxSelect = checkeds;
          let found = auxSelect.columnsSelected.filter(
            (ele) => ele != item.nameShown
          );
          auxSelect.columnsSelected = found;
        }
        el.checked = !el.checked;
      }
    });
    setCheckeds({
      ...auxSelect,
    });

    setColumnsList([...auxCol]);
  };

  useEffect(() => {
    (async () => {
      if (props.currentAccount != "") {
        let account = props.currentAccount;
        let categories = await APILA.getCategories(account);
        let cats = categories.data.categories.filter(
          (el) =>
            el.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" || el.padre == ""
        );
        let catsHijas = cats.filter((el) => el.active === true);
        let catsSorted = _.orderBy(catsHijas, ["name"], ["asc", "desc"]);
        let auxCols = columnsList;
        catsSorted.forEach((el) => {
          auxCols.push({
            nameShown: el.name,
            columns: `categorie:${el.name}`,
            checked: false,
          });
        });
        setColumnsList([...auxCols]);

        getData();
      }
    })();
    permisos();
    if (props.currentAccount == "") return
    if (props.isIpv4) return
    changeUrl({
      pagination: currentPageParams
    })
  }, [props.currentAccount]);

  const permisos = async () => {
    try {
      setIsLoading(true);
      const userData = await Auth.currentAuthenticatedUser();
      let sub = userData.username;
      let permisoAcc = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: sub,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await API.getRoleNameById({ id: idRol });
      let permissAud = permisosRes.data.rol[0].permissions;
      let permissFn = permissAud.filter((ele) => ele.name == "Etiquetados");
      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
      setIsLoading(false);
    } catch (err) {
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
      console.log("err permits", err);
      setIsLoading(false);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    let responseDefault = await APILA.getDefaultEtiqueta();
    let etiquetasAccount = await APILA.getEtiquetasByAccount({
      account: props.currentAccount,
    });
    let defaultCategorie = responseDefault.data.defaultEtiqueta[0];
    let etiquetas = etiquetasAccount.data.etiquetas;
    let allFalse = true;
    etiquetas.forEach((el) => {
      if (el.state === 1) {
        allFalse = false;
      }
    });

    let finalEtiquetas = [];

    if (allFalse === false) {
      defaultCategorie.state = 0;
      finalEtiquetas = [defaultCategorie, ...etiquetas];
    } else {
      finalEtiquetas = [defaultCategorie, ...etiquetas];
    }
    setData([...finalEtiquetas]);
    setIsLoading(false);
  };

  useEffect(() => {
    let auxObject = checkeds;
    let string = "";
    auxObject.columnsSelected.forEach((el) => {
      if (el != undefined) {
        string = string + el + "_";
      }
    });
    string = string.substring(0, string.length - 1);
    setNameToShow(string);
  }, [checkeds]);
  /* const columnsList =[
    "Id",
    "Cliente",
    "País",
    "Medio",
    "Banner",
    "SubCampaña1",
    "SubCampaña2",
    "Nombre",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
    "FechaInicio",
  ] */
  const onSave = async () => {
    setIsLoading(true)

    let auxColumns = columnsList.filter((el) => el.checked === true);
    //comentar para prod
    analytics.guardar({
      ubicacion: "Etiquetados",
    });
    if (auxColumns.length > 0) {
      let arrayColumnsSelected = [];
      checkeds.columnsSelected.forEach((el) => {
        let found = auxColumns.filter((ele) => ele.nameShown === el);
        if (found.length > 0) {
          arrayColumnsSelected.push(found[0].columns);
        }
      });
      let body = {
        id: v4(),
        account: props.currentAccount,
        columnsSelected: arrayColumnsSelected,
        nameShown: nameToShow,
        state: 0,
      }
      let response = await APILA.insertEtiqueta(body);
      let bodyBitacora = {
        id: v4(),
        date: new Date(),
        account: props.currentAccount,
        action: 'se ha creado una etiqueta',
        component: 'etiquetas',
        body: body
      }
      await API.insertBitacora(bodyBitacora)
      setIsLoading(false)
      if (response.data.success) {
        await Swal.fire({
          title: "Creación de etiqueta",
          text: "Etiqueta creada satisfactoriamente",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        getData();
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "Ups!",
          text: "Algo salió mal, por favor inténtalo de nuevo.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setIsLoading(false);
      Swal.fire({
        title: "Advertencia",
        text: "Por favor selecciona una columna de la lista",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };
  const handleClickCheck = async (row) => {
    let responseDefault = await APILA.getDefaultEtiqueta();
    let etiquetasAccount = await APILA.getEtiquetasByAccount({
      account: props.currentAccount,
    });
    let defaultCategorie = responseDefault.data.defaultEtiqueta[0];
    let etiquetas = etiquetasAccount.data.etiquetas;
    //comentar para prod
    analytics.accionesTabla({
      accion: "Seleccionar etiqueta",
      ubicacion: "Etiquetados",
    });
    let allFalse = true;
    etiquetas.forEach((el) => {
      if (el.state === 1) {
        allFalse = false;
      }
    });

    let finalEtiquetas = [];

    if (allFalse === false) {
      defaultCategorie.state = 0;
      finalEtiquetas = [defaultCategorie, ...etiquetas];
    } else {
      finalEtiquetas = [defaultCategorie, ...etiquetas];
    }
    let auxData = [...finalEtiquetas];
    let contChecked = 0;
    auxData.forEach((el) => {
      if (el.state === 1) {
        contChecked++;
      }
    });
    if (contChecked === 1) {
      if (row.state === 0) {
        setIsLoading(true);
        let auxRow;
        for (let i = 0; i < auxData.length; i++) {
          if (auxData[i].id === row.id) {
            auxData[i].state = auxData[i].state === 1 ? 0 : 1;
            auxRow = auxData[i];
          }
        }
        let finalData;

        auxData.forEach((el) => {
          if (el.id != row.id) {
            el.state = 0;
          }
        });

        for (let i = 0; i < auxData.length; i++) {
          if (auxData[i].account != "Default") {
            let response = await APILA.insertEtiqueta(auxData[i]);
          }
        }

        setData([...auxData]);
        setIsLoading(false);
      } else {
        Swal.fire({
          title: "Advertencia",
          text: "Tiene que haber una etiqueta seleccionada",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  const removeEtiqueta = (row) => {
    props._showAlertModal(
      "Eliminar Etiqueta",
      `Esta seguro que desea eliminar esta etiqueta?`,
      Modal.MODAL_TYPE_CONFIRMATION,
      () => {
        //comentar para prod
        analytics.accionesTabla({
          accion: "Eliminar",
          ubicacion: "Etiquetados",
        });
        APILA.deleteEtiqueta({ id: row.id })
          .then((res) => {
            getData();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    );
  };

  const [columns, setColumns] = useState([
    {
      name: "Etiqueta",
      selector: "",
      sortable: true,
      cell: (row) => (
        <>
          {row.account === "Default" ? (
            <></>
          ) : (
            <div className="boxIcons">
              <i
                class="fas fa-trash-alt"
                onClick={() => removeEtiqueta(row)}
              ></i>
            </div>
          )}

          {row.nameShown}
        </>
      ),
    },
    {
      name: "Actual",
      selector: "",
      sortable: true,
      center: true,
      cell: (row) => (
        <input
          type="checkbox"
          id="checkboxState"
          onClick={() => handleClickCheck(row)}
          checked={row.state === 1 ? true : false}
        />
      ),
    },
  ]);

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        fontSize: "1rem",
        "&:last-of-type": {
          backgroundColor: "#eff0f2",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2)": {
          backgroundColor: "#eff0f2",
          textAlign: "center",
        },
      },
    },
  };
  const isAuthenticated = () => {
    let currentUser = props.currentUser;
    let ok = false;
    if (_.isEmpty(props.users) == false) {
      let found = props.users.find(
        (element) => element.id == props.currentUser
      );
      if (found) {
        ok = true;
      }
    }
    return ok;
  };

  if (props.isIpv4) {
    return (
      <>
        {
          isLoading ?
            <PageLoader />
            :
            <>
              <div className="container-etiquetas">
                <div className="first-section">
                  <section className="section-column-list">
                    <h3>Lista columnas a seleccionar</h3>
                    <div className="columns">
                      {columnsList.map((item) => (
                        <div className="box">
                          <p>{item.nameShown}</p>
                          <input
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => {
                              onChange(item);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </section>
                  <section className="section-column-selected">
                    <h3>Columnas seleccionadas</h3>
                    <div className="box">{nameToShow}</div>
                    <button className="btnSave" onClick={onSave}>
                      Guardar
                    </button>
                  </section>
                </div>
                <div className="second-section">
                  <ReactDataTable
                    className="Table"
                    customStyles={customStyles}
                    columns={columns}
                    data={data}
                    persistTableHead
                    pagination
                  />
                </div>
              </div>
            </>
        }
      </>
    )
  }

  return isLoading ? (
    <PageLoader />
  ) : permissions.view ? (
    <>
      <div className="container-etiquetas">
        <div className="first-section">
          <section className="section-column-list">
            <h3>Lista columnas a seleccionar</h3>
            <div className="columns">
              {columnsList.map((item) => (
                <div className="box">
                  <p>{item.nameShown}</p>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => {
                      onChange(item);
                    }}
                  />
                </div>
              ))}
            </div>
          </section>
          <section className="section-column-selected">
            <h3>Columnas seleccionadas</h3>
            <div className="box">{nameToShow}</div>
            {permissions.create ? (
              <button className="btnSave" onClick={onSave}>
                Guardar
              </button>
            ) : (
              <h3>No tienes permisos para guardar</h3>
            )}
          </section>
        </div>
        <div className="second-section">
          <ReactDataTable
            className="Table"
            customStyles={customStyles}
            columns={columns}
            data={data}
            onChangePage={handlePageChange}
            paginationDefaultPage={currentPage || 1}
            persistTableHead
            pagination
          />
        </div>
      </div>
    </>
  ) : (
    <h1>No tienes permisos</h1>
  );
};

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.default,
  currentUser: state.app.current_user,
  users: state.users,
  isIpv4: state.app.isIPV4,
});
const mapDispatchToProps = (dispatch) => ({
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Content));
