import React from 'react'
// components
import Content from 'components/accounts'
import Etiquetados from 'components/etiquetas'
import MetaTags from 'components/metatags'
import Header from 'components/accounts/header'
import UpsertAccount from 'components/accounts/modal/upsert_account'

const AccountListPage = () => (
  <>
    {/* <Header /> */}
    <div className='contAccount'>
      <MetaTags title='Mis Cuentas' description='Página de cuentas' />
      <Content/>
      <UpsertAccount />
    </div>
  </>
)

export default AccountListPage
