import React, { useState } from "react";
import { connect } from "react-redux";
import DataPicker from "../dataPicker";
import { saveDates } from "redux/actions/fechasCamp.actions";
import { saveSteps } from "redux/actions/nameCamp.actions";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import { withUtils } from "utils";
import moment from "moment";
import "moment/locale/es";
import "moment/min/locales";
moment.locale("es");

const FechaCamp = (props) => {
  const [state, setState] = useState({
    fechaIni: props.campaign.iniDate || "",
    fechafin: props.campaign.endDate || "",
  });

  const goback = (e) => {
    e.preventDefault();
    props._saveSteps(2);
  };
  const go = (e) => {
    e.preventDefault();
    props._saveSteps(5);
  };
  const handleDateChange = (startDate, endDate) => {
    setState((prev) => ({
      ...prev,
      fechaIni: (startDate),
      fechafin: (endDate),
    }));
  };

  return (
    <div>
      <h1 className="title">Elige el rango de fecha</h1>
      <div>
        <div className="twoColBody">
          <FormGroup>
            <Label
              className="texto_corregido"
            ></Label>
            <DataPicker
              onChangeDate={handleDateChange}
              fechaIni={
                props?.campaign?.iniDate !== ""
                  ? props?.campaign?.iniDate
                  : state.fechaIni
              }
              fechafin={
                props?.campaign?.endDate !== ""
                  ? props?.campaign?.endDate
                  : state.fechafin
              }
            />
            <FormFeedback>Ingresa un rango de fechas</FormFeedback>
          </FormGroup>
        </div>
      </div>
      <div className="space-btns">
        <a className="btn-camp gray" onClick={goback} href="#">
          Volver
        </a>
        <a className="btn-camp" onClick={go} href="#" id="nextMedio">
          Siguiente
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
});

const mapDispatchToProps = (dispatch) => ({
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _saveDates: (iniDate, endDate) => dispatch(saveDates(iniDate, endDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(FechaCamp));
