import { APP_CLEAR_CURRENT_USER } from "../actions/app.actions";
import * as Actions from "../actions/dashboard.actions";

const initialState = {
  total: 0,
  loading: false,
  filter: {
    text: "",
    page: 1,
    paginationPerPage: 10,
    sortDirection: "",
    column: "",
  },
  campaigns: {
    approve: false,
    id: "",
  },
  list: [],
  filteredCampaigns: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CAMPAIGNS_CLEAR:
      return {
        ...state,
        list: [],
        total: 0,
      };
    case Actions.CAMPAIGNS_ADD_ONE:
      return {
        ...state,
        list: [
          ...state.list.filter(
            (campaing) => campaing.id !== action.campaign.id
          ),
          action.campaign,
        ],
      };
    case Actions.CAMPAIGNS_UPDATE_STATUS:
      return {
        ...state,
        list: [
          ...state.list.map((campaing) =>
            campaing.id === action.id
              ? {
                  ...campaing,
                  status: action.status,
                }
              : campaing
          ),
        ],
      };
    /*ESTO ENVÍA EL ACTION CREATOR DE getCampaigns*/
    case Actions.CAMPAIGNS_CHANGE_TOTAL:
      return {
        ...state,
        total: action.total,
      };
    /*ESTO ENVÍA EL ACTION CREATOR DE getCampaigns*/
    case Actions.CAMPAIGNS_CHANGE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case Actions.CAMPAIGNS_CHANGE_FILTER_SORT:
      return {
        ...state,
        filter: {
          ...state.filter,
          sortDirection: action.sortDirection,
          column: action.column,
        },
      };
    case Actions.CAMPAIGNS_CHANGE_FILTER_PAGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.page,
        },
      };
    case Actions.CAMPAIGNS_CHANGE_FILTER_TEXT:
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.text,
        },
      };
    case Actions.CAMPAIGNS_ADD_TOTAL:
      return {
        ...state,
        total: action.total,
      };
    case Actions.CAMPAIGNS_CHANGE_PAGINATION_PER_PAGE:
    case Actions.CAMPAIGNS_CHANGE_FILTER_PAGINATION:
      return {
        ...state,
        filter: {
          ...state.filter,
          paginationPerPage: action.paginationPerPage,
        },
      };
    case Actions.ADD_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.campaigns,
      };
    case Actions.ADD_CAMPAIGNS_FILTERED:
      return {
        ...state,
        campaignsFilter: action.campaigns,
      };
    case Actions.CAMPAIGNS_LIST_ARRAY_CAMPAIGNS:
      return {
        ...state,
        filteredCampaigns: action.filteredCampaigns,
      };
    case APP_CLEAR_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
};

export default dashboardReducer;
