import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { v4 } from 'uuid'

import './assets/css/styles.css'

import grupo from './assets/img/grupo.png'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { addBudget } from 'redux/actions/dashboard.actions'
import { createBudget_logout } from 'redux/actions/budgets.actions'
import { createAccount_logout } from 'redux/actions/accounts.actions'
import { createCategory_logout } from 'redux/actions/categories.actions'
import { saveCategory, saveAccount } from 'redux/actions/campana.actions'
import * as APILA from "helpers/apiLA";

import { withUtils } from 'utils'

const TAG = 'ConfirmSignUpForm'

const ConfirmSignUpForm = props => {
  let code = useRef(null)
  console.log('Las props', props);
  const confirmSignUp = async e => {
    e.preventDefault()
    props._showLoader(true)
    let error = ''

    try {
      let password
      props.utils.log(TAG, 'code', code.current.value)
      console.log("usuario ",props.authData.username)
      console.log("pass " , props.authData.password)
      if(props.authData.password){
        password=props.authData.password;
      }
      await Auth.confirmSignUp(props.authData.username, code.current.value).then(() => {
        let accountId = v4()

        console.log("creacion de usuariooooooooooooo")
  

        props._createAccount_logout(accountId, "General", props.authData.sub, 0, null, null, null
        ).then(async result => {

          props._saveAccount(accountId)

          let budgetId = v4()

          props._createBudget_logout(budgetId, "general", accountId, props.campaign.budget, null, null).then(result => {
            props._addBudget(budgetId)
          }).then()

          let categoryId = v4()
          props._createCategory_logout(categoryId, "General", accountId, null, null).then(result => {
            props._saveCategory(categoryId)

          })
          APILA.getRoleNameById({ id: 'Administrador' }).then((permisos)=> {
            let body = {
              id: v4(),
              account: accountId,
              user: props.authData.sub,
              role: permisos.data.rol[0].name,
              idRol: "Administrador",
            };
            APILA.insertPermisos(body).then(()=> {
              Auth.signIn(props.authData.username, props.authData.password)
            })
          })
        })

      })

      setTimeout(() => {

      },2000)




      
      localStorage.setItem("passAWS", "");
      props.onStateChange('signedIn', {})
    } catch (err) {
      let { code } = err
      props.utils.log(TAG, 'error confirm up', err)
      console.log('Error de activacion', err);
      switch (code) {
        case 'CodeMismatchException':
          error = 'El código que ingreso es incorrecto'
          break
        default:
          error = 'Ocurrió un error inesperado'
      }
    }

    props._showLoader(false)

    if (error!=='') {
      props._showAlertModal('Error!', error, Modal.MODAL_TYPE_ERROR)
    }else{
      props._showLoader(false)
    }
  }

  // const goToSignIn = e => {
  //   e.preventDefault()
  //   props.onStateChange('signIn', {})
  // }

  return(
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">¡Confirmar de Registro!</h1>
          <p className="textos">Correo electr&oacute;nico</p>
          <input type="text" value={props.authData.username} className="formInput" placeholder="Ingresa tu correo electr&oacute;nico" readOnly />
          <p className="textos">C&oacute;digo de Confirmaci&oacute;n</p>
          <input ref={code} className="formInput" type="text" placeholder="Ingresa tu c&oacute;digo" />
          
          <a onClick={confirmSignUp} className="btn-plus-form" href="#">
            CONFIRMAR
          </a>
        </div>
        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  campaign: state.campaign,
})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
  _createAccount_logout: (id, name, username, credit, edit, createAt, members) => dispatch(createAccount_logout(id, name, username, credit, edit, createAt, members)),
  _createBudget_logout: (id, title, account, amount, edit, createAt) => dispatch(createBudget_logout(id, title, account, amount, edit, createAt)),
  _addBudget: (id) => dispatch(addBudget(id)),
  _createCategory_logout: (id, name, account, edit, createAt) => dispatch(createCategory_logout(id, name, account, edit, createAt)),
  _saveCategory: (category) => dispatch(saveCategory(category)),
  _saveAccount: (account) => dispatch(saveAccount(account)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(ConfirmSignUpForm))
