import React, { useState, useEffect, useRef } from 'react';

const SimpleText = ({ value, id, onEditValue, editable, nameColumn, type, dataSelect, editCell, dataSelectId }) => {
    const [valueE, setvalueE] = useState(value);
    const [data, setData] = useState([]);
    const [dataOptions, setDataOptions] = useState([]);
    const ref = useRef(null);

    const useOutsideAlerter = (initialState) => {
        const [visible, setVisible] = useState(initialState);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);

        return { visible, setVisible, ref };
    };

    const { visible, setVisible } = useOutsideAlerter(false);

    useEffect(() => {
        if (data.length > 0) {
            onEditValue(data).then(() => {
                setvalueE(value);
            });
            setData([]);
        }
    }, [visible]);

    useEffect(() => {
        if (dataSelect) {
            setDataOptions(dataSelect);
        }
    }, [dataSelect]);

    const editData = (valor) => {
        setvalueE(valor);
        let dataAdd = {
            id: id,
            column: nameColumn,
            value: valor
        };
        setData([dataAdd]);
    };

    const item = () => {
        if (type === 'select') {
            let result = [];
            if (dataSelect) {
                result = dataOptions.filter((item, index) => {
                    return dataOptions.indexOf(item) === index;
                });
            }
            return (
                <select className='option-select' defaultValue={"DEFAULT"} onChange={(e) => editData(e.target.value)}>
                    <option className='option-options' value="DEFAULT" disabled >Selecciona una opcion:</option>
                    {result.map((data2, idx) =>
                    (
                        <option className='option-options' key={idx} value={data2.value}>{data2.label}</option>
                    )
                    )}
                </select>
            );
        }
    };

    const optionsView = () => {
        if (editable) {
            setVisible(true);
        }
    };

    const onPressEnter = (e) => {
        if (e.key === 'Enter') {
            setVisible(false);
        }
    }

    return (
        <div onKeyDown={(e) => onPressEnter(e)} onDoubleClick={() => optionsView()}>
            {visible ?
                <div ref={ref}>
                    {item()}
                </div>
                :
                <div className={editCell ? "alerta texto" : "texto"} style={{ width: '100px' }} >
                    <p className="name-text">
                        {value}
                    </p>
                </div>}
        </div>
    );
};

export default SimpleText;
