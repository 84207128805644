import React from "react";

// components
import Content from "components/report";
import MetaTags from "components/metatags";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
const AccountListPage = () => (
  <>
    <MetaTags title="Let's Advertise Ads - Reportes" description="Exporta informes automatizados en segundos. Aumenta el tiempo de calidad tomando decisiones, Let's Advertise Ads hace los reportes por ti. Ingresa ahora." />
    {/* <MetaTags title="Mis Reportes" description="Página de reportes" /> */}

    <div className="content-report">
      <Content />
    </div>
  </>
);

export default withRouter(AccountListPage);
