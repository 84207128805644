import React, { useEffect, useState } from "react";
import "../assets/style.scss";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import { v4 } from "uuid";
import Swal from "sweetalert2";
import { withUtils } from "utils";
import { connect } from "react-redux";
import { newEmailValidation } from "redux/actions/email.actions";

const ModalAddRol = (props) => {
  const { setShowModal, refresh, setRefresh } = props;
  const [rolesNames, setRolesNames] = useState([]);
  const [rolPadre, setRolPadre] = useState("");
  const [nameRol, setNameRol] = useState("");
  // useSta
  const [component, setComponent] = useState([
    {
      name: "Campañas",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Categorías",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Públicos",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Presupuestos",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Reportes",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Etiquetados",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Gestión de accesos",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Cuentas",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Creatividad",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Roles de sistema",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Configuración alertas",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Movimientos de presupuestos",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Configuración prioridades de campañas",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      name: "Horarios de atención",
      export: false,
      view: false,
      edit: false,
      create: false,
      delete: false,
      manageRoles: false,
      approve: false,
    },
    {
      approve: false,
      create: false,
      delete: false,
      edit: false,
      export: true,
      manageRoles: false,
      name: "Planificación",
      view: true,
    },
    {
      approve: false,
      create: false,
      delete: false,
      edit: false,
      export: true,
      manageRoles: false,
      name: "Configuración de la cuenta",
      view: true,
    },
  ]);
  useEffect(() => {
    setRolesNames([...props.roleNames]);
  }, [props.roleNames]);

  const editPermiss = (name, option, value) => {
    let searchComponent = component;
    searchComponent.forEach((ele) => {
      if (ele.name == name) {
        ele[option] = value;
      }
    });
    setComponent([...searchComponent]);
  };
  const addRol = async () => {
    let alerta = false;
    let titulo = "";
    let mensaje = "";
    let icon = "";
    let nombresRoles = rolesNames.map((ele) => ele.name);
    if (nombresRoles.includes(nameRol)) {
      alerta = true;
      mensaje = "Hubo un error, el nombre ya existe.";
      icon = "error";
      titulo = "Ups!";
    } else {
      if (rolPadre.length != 0) {
        if (nameRol != "") {
          try {
            let body = {
              id: v4(),
              name: nameRol,
              type: "Personalizado",
              rolPadre: rolPadre,
              account: props.currentAccount,
              permissions: component,
            };
            setShowModal(false);
            setRefresh(true);
            let insertRoles = await APILA.insertRoles(body);
            props._newEmailValidation(
              props.currentAccount,
              "Roles",
              body,
              props.currentUser,
              props.accounts,
              "Creación"
            );
            let bodyBitacora = {
              id: v4(),
              date: new Date(),
              account: props.currentAccount,
              action: "se ha agregado un rol",
              component: "roles",
              data: body,
            };
            await APILA.insertBitacora(bodyBitacora);
            console.log("El rol ingresado", insertRoles);
            alerta = true;
            mensaje = "Rol agregado correctamente.";
            icon = "success";
            titulo = "Rol agregado!";
            setRefresh(true);
          } catch (err) {
            alerta = true;
            mensaje = "Hubo un error, por favor inténtalo de nuevo.";
            icon = "error";
            titulo = "Ups!";
          }
        } else {
          alerta = true;
          mensaje = "Agrega un nombre al rol, por favor inténtalo de nuevo.";
          icon = "error";
          titulo = "Ups!";
        }
      } else {
        alerta = true;
        icon = "error";
        titulo = "Ups!";
        mensaje = "Selecciona un rol padre.";
      }
    }

    if (alerta) {
      Swal.fire({
        title: titulo,
        text: mensaje,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };
  const changeOptions = (id) => {
    // console.log('El id que estoy recibiendo', id);
    setRolPadre(id);
    let option = rolesNames.filter((rol) => rol.id == id);
    console.log("La opcion encontrada", option[0].permissions);
    setComponent([...option[0].permissions]);
  };
  return (
    <div className="blur-div">
      <div className="content-modal modalCreateRol">
        <div>
          <h3 className="titleModal titleModalRol">Crear rol</h3>
        </div>

        <div className="body-modal modalAccount">
          <div className="rol-options">
            <h3>Rol padre</h3>
            <select
              className="option-select"
              defaultValue={"DEFAULT"}
              onChange={(e) => changeOptions(e.target.value)}
            >
              <option className="option-options" value="DEFAULT" disabled>
                Selecciona una opcion:
              </option>
              {rolesNames.map((roles) => (
                <option value={roles.id}>{roles.name}</option>
              ))}
            </select>
            <h3>Nombre rol hijo</h3>
            <input type={"text"} onChange={(e) => setNameRol(e.target.value)} />
          </div>

          <div className="container-permiss">
            {component.map((component, idx) => (
              <div className="container-acc" key={idx}>
                <div className="names">{component.name}</div>
                <div className="container-options">
                  <div>
                    <input
                      type="checkbox"
                      checked={component.export}
                      onChange={() =>
                        editPermiss(component.name, "export", !component.export)
                      }
                    />
                    <span>Exportar</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.view}
                      onChange={() =>
                        editPermiss(component.name, "view", !component.view)
                      }
                    />
                    <span>Ver</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.edit}
                      onChange={() =>
                        editPermiss(component.name, "edit", !component.edit)
                      }
                    />
                    <span>Editar</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.create}
                      onChange={() =>
                        editPermiss(component.name, "create", !component.create)
                      }
                    />
                    <span>Crear</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.delete}
                      onChange={() =>
                        editPermiss(component.name, "delete", !component.delete)
                      }
                    />
                    <span>Eliminar</span>
                  </div>
                  {/* <div>
                    <input
                      type="checkbox"
                      checked={component.manageRoles}
                      onChange={() =>
                        editPermiss(
                          component.name,
                          "manageRoles",
                          !component.manageRoles
                        )
                      }
                    />
                    <span>Gestionar roles</span>
                  </div> */}
                  {component.name === "Campañas" && (
                    <div>
                      <input
                        type="checkbox"
                        checked={component.approve}
                        onChange={() =>
                          editPermiss(
                            component.name,
                            "approve",
                            !component.approve
                          )
                        }
                      />
                      <span>Aprobar</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="footer-Modal">
          <div className="buttons-Modal-content">
            <button
              className="btnModal btnCancelarModalRol btnCancelOS"
              onClick={(e) => setShowModal(false)}
            >
              Cancelar
            </button>
            <button
              className="btnModal btnGuardarModalRol btnConfirmOS"
              onClick={() => addRol()}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ModalAddRol));
