import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'

import './assets/css/styles.css'

import grupo from './assets/img/grupo.png'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'

import { withUtils } from 'utils'

const TAG = 'PasswordRecoveryForm'

const PasswordRecoveryForm = props => {
  let username = useRef(null)

  const sendResetCode = async e => {
    e.preventDefault()
    props._showLoader(true)
    let error = ''

    try {
      props.utils.log(TAG, 'username', username.current.value)
      let data = await Auth.forgotPassword(username.current.value)
      props.utils.log(TAG, 'data', data)
      props.onStateChange('requireNewPassword', {username: username.current.value})
    } catch (err) {
      let { code } = err
      props.utils.log(TAG, 'error signing in', err)

      switch (code) {
        case 'UserNotConfirmedException':
          await Auth.resendSignUp(username)
          props.onStateChange('confirmSignUp', {})
          break
        case 'NotAuthorizedException':
          error = 'La contraseña que ingreso es incorrecta'
          break
        case 'UserNotFoundException':
          error = 'El usuario que ingreso no existe'
          break
        default:
          error = 'Ocurrió un error inesperado'
      }
    }

    props._showLoader(false)

    if (error!=='') {
      props._showAlertModal('Error!', error, Modal.MODAL_TYPE_ERROR)
    }
  }

  const goToSignIn = e => {
    e.preventDefault()
    props.onStateChange('signUp', {})
  }

  return(
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">¡Recupera tu contrase&ntilde;a!</h1>
          <p className="textos">Correo electr&oacute;nico</p>
          <input ref={username} className="formInput" type="text" placeholder="Ingresa tu correo electr&oacute;nico" />
          <div className="passForget">
            <a onClick={goToSignIn} href='#'>
              Ir a inicio de sesi&oacute;n
            </a>
          </div>
          <a onClick={sendResetCode} className="btn-plus-form" href="#">
            RECUPERAR
          </a>
        </div>
        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(PasswordRecoveryForm))
