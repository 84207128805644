import React from 'react'
import { Authenticator } from 'aws-amplify-react'

import Auth from 'pages/auth'

const withAuthentication = Component => {
  const WithAuthentication = ({ authState, onStateChange, awsConfig, ...rest }) => (
    <Authenticator hideDefault={true} amplifyConfig={awsConfig}>
      <Auth authState={authState} onStateChange={onStateChange} />

      <Component authState={authState} onStateChange={onStateChange} {...rest} />
    </Authenticator>
  )

  return WithAuthentication
}

export { withAuthentication }
