import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import { newEmailValidation } from "redux/actions/email.actions";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { withUtils } from 'utils'
import * as Modal from 'redux/actions/modal.actions'
import { createAudience, createNewAudience, updateAudience } from 'redux/actions/audiences.actions'
import { pwaUpsertAudience } from 'redux/actions/pwa.actions'
import analytics from '../../../helpers/analytics';
import './style.scss'
import * as APILA from "helpers/api";
import { Auth } from "aws-amplify";
const TAG = 'UpsertAudience'

const UpsertAudience = (props) => {
  const [errors, setErrors] = useState({ name: false, tags: false, tagsE: false })
  const [state, setState] = useState({
    id: '',
    name: '',
    newTag: '',
    newTagE: '',
    edit: false,
    createAt: 0,
    tags: { data: [] },
    tagsE: { data: [] },
  })
  const [errorTag, setErrorTag] = useState(false)
  useEffect(() => {
    setState({})
    if (!_.isEmpty(props.edit)) {
      console.log("useEffect xxxxEditamos XXXXXX", props.edit);
      let newState = {
        edit: true,
        id: props.edit.id,
        name: props.edit.name,
        createAt: props.edit.createAt,
      }

      if (props.edit.tags && props.edit.tags.data) {
        console.log("####Props tagsE: ", props.edit.tagsE);
        if (props.edit.tagsE && props.edit.tagsE.data) {
          newState = {
            ...newState,
            tags: props.edit.tags,
            tagsE: props.edit?.tagsE,
          }
        } else {
          newState = {
            ...newState,
            tags: props.edit.tags,
            tagsE: { data: [] },
          }
        }

      }

      setState(prev => ({
        ...prev,
        ...newState,
      }))
    } else {
      setState({
        id: '',
        name: '',
        newTag: '',
        newTagE: '',
        edit: false,
        createAt: 0,
        tags: { data: [] },
        tagsE: { data: [] },
      })
    }
  }, [props.edit])

  const submitTag = async e => {
    if (e.key == 'Enter') {
      let tags = state.tags
      let tagFound = []
      tagFound = state.tags?.data.filter(el => el == state.newTag)
      if (tagFound == undefined) {
        tagFound = []
      }
      console.log('Tagf', tags)
      if (tags == undefined) {
        tags = { data: [] }
      }
      if (tagFound.length === 0) {
        if (state.newTag !== '') {
          tags.data.push(state.newTag)
        }

        setState(prev => ({
          ...prev,
          tags: tags,
          newTag: '',
        }))
      } else {
        setErrorTag(true)
      }

    }
  }

  const submitTagExcluyente = e => {
    if (e.key == 'Enter') {
      let tagsE = state.tagsE

      let tagFound = []
      tagFound = state.tagsE?.data.filter(el => el == state.newTagE)
      if (tagFound == undefined) {
        tagFound = []
      }
      if (tagsE == undefined) {
        tagsE = { data: [] }
      }
      if (tagFound.length === 0) {

        if (state.newTagE !== '') {
          tagsE.data.push(state.newTagE)
        }

        setState(prev => ({
          ...prev,
          tagsE: tagsE,
          newTagE: '',
        }))
      } else {
        setErrorTag(true)
      }

    }
  }

  const removeTag = tag => {
    let tags = state.tags
    let data = tags.data.filter(s => s !== tag)
    tags.data = data


    setState(prev => ({
      ...prev,
      tags: tags,
    }))
  }

  //Excluyentes
  const removeTagE = tagE => {
    let tagsE = state.tagsE
    let data = tagsE.data.filter(s => s !== tagE)
    tagsE.data = data


    setState(prev => ({
      ...prev,
      tagsE: tagsE,
    }))
  }
  const handleInputChange = (e) => {

    let name = e.target.name
    let value = e.target.value

    setState(prev => ({
      ...prev,
      [name]: value
    }))

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }))
  }

  const closeModal = (e) => {
    console.log("CloseModal ;jkdkj;dlfkjd")
    if (e) e.preventDefault()
    analytics.modalesAcciones({
      accion: 'Cancelar',
      ubicacion: 'Publicos'
    })
    props.utils.log(TAG, 'clearState')
    window.$.fancybox.close()
    setState({
      id: '',
      name: '',
      newTag: '',
      newTagE: '',
      edit: false,
      createAt: 0,
      tags: { data: [] },
      tagsE: { data: [] },
    })
  }

  const handleUpsertEdit = (e) => {
    e.preventDefault()
    console.log("***********Edicion ***********")
    analytics.modalesAcciones({
      accion: 'Confirmar',
      ubicacion: 'Publicos'
    })
    if (state.name !== '' && state.tags.data.length > 0) {

      console.log("Se verifica que los campos no esten vacios ******************");

      const audiences =
        props.currentUser !== '' ? props.audiences : props.pwaAudiences

      let existingPreviousAudience = _.find(audiences, {
        name: state.name.trim(),
      })
      console.log("***********Previa Audiencia************", existingPreviousAudience);

      if (!state.edit && existingPreviousAudience) {
        console.log("*****Publico Existente******")
        props._showAlertModal(
          'Público Existente',
          'Ya existe un público con este nombre',
          Modal.MODAL_TYPE_ERROR,
          () => { }
        )

        return
      }

      let audienceId = state.id

      if (audienceId === '') {
        audienceId = v4()
      }
      if (props.currentUser !== '') {
        console.log("***Edicion de audencia ****")
        Auth.currentAuthenticatedUser().then((userdata) => {
          props._updateAudience(
            audienceId,
            state.name,
            props.currentAccount,
            state.edit,
            state.tags,
            state.tagsE,
            Date.now(),
            state.createAt,
            true,
            props.currentUser,
            props.accounts,
            userdata.attributes.email
          )
        }).catch(async error => {
          if (error === 'The user is not authenticated') {
            await props._updateAudience(
              audienceId,
              state.name,
              props.currentAccount,
              state.edit,
              state.tags,
              state.tagsE,
              Date.now(),
              state.createAt,
              true,
              props.currentUser,
              props.accounts,
              'logout'
            )
          }
        })
      }
      closeModal()
    } else {
      props._showAlertModal(
        'Campos Vacíos',
        'Por favor ingresa un nombre y una descripción',
        Modal.MODAL_TYPE_ERROR,
        () => { }
      )
    }
  }
  useEffect(() => {
    if (errorTag == true) {
      Swal.fire(
        {
          title: 'Tag Existente',
          text: "Por favor ingresa un nuevo tag",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          setErrorTag(false)
        })
    }
  }, [errorTag])
  const handleUpsert = async (e) => {
    e.preventDefault()
    analytics.modalesAcciones({
      accion: 'Confirmar',
      ubicacion: 'Publicos'
    })
    console.log('CurrentUser', props.currentUser)
    console.log('PwaAcctoun', props.currentPWAAccount)
    console.log("*******CREACION*******")
    if (state.name !== '' && state.tags.data.length > 0) {

      console.log("Se verifica que los campos no esten vacios ******************");

      let audiences =
        props.currentUser !== '' ? props.audiences : props.pwaAudiences

      if (props.currentUser !== '') {

      } else {
        let audiencess = await APILA.getAudiences(props.currentPWAAccount);
        console.log("AllAudiences", audiencess.data.audiences);
        audiences = audiencess.data.audiences
      }

      let existingPreviousAudience = _.find(audiences, {
        name: state.name.trim(),
      })
      console.log("***********Previa Audiencia************", existingPreviousAudience);

      if (!state.edit && existingPreviousAudience) {
        console.log("*****Publico Existente******")
        props._showAlertModal(
          'Público Existente',
          'Ya existe un público con este nombre',
          Modal.MODAL_TYPE_ERROR,
          () => { }
        )

        return
      }

      let audienceId = state.id

      if (audienceId === '') {
        audienceId = v4()
      }
      /* if(state.edit){
         console.log("******Edicion de audiencia*****")
         props._createAudience(
           audienceId,
           state.name,
           props.currentAccount,
           state.tags,
           state.tagsE,
           state.edit,
           state.createAt
         )
       }*/
      if (props.currentUser !== '') {
        console.log("***Creacion de audencia****", state.createAt, state)
        Auth.currentAuthenticatedUser().then((userdata) => {
          props._createNewAudience(
            audienceId,
            state.name,
            props.currentAccount,
            state.tags,
            state.tagsE,
            props.currentUser,
            props.accounts,
            userdata.attributes.email
          )
        }).catch(error => {
          if (error === 'The user is not authenticated') {
            props._createNewAudience(
              audienceId,
              state.name,
              props.currentAccount,
              state.tags,
              state.tagsE,
              props.currentUser,
              props.accounts,
              "logout"
            )
          }
        })

      } else {
        Auth.currentAuthenticatedUser().then(async (userdata) => {
          let bodyAudience = {
            id: audienceId,
            name: state.name,
            account: props.currentPWAAccount,
            tags: state.tags,
            tasgE: state.tagsE,
            userEmail: userdata.attributes.email
          }
          await APILA.createAudience(bodyAudience)
        }).then(async error => {
          console.log('No hay currentUser', { error })
          let bodyAudience = {
            id: audienceId,
            name: state.name,
            account: props.currentPWAAccount,
            tags: state.tags,
            tasgE: state.tagsE,
            userEmail: "logout"
          }
          await APILA.createAudience(bodyAudience)
        })

        /* props._pwaUpsertAudience(
          audienceId,
          state.name,
          props.currentPWAAccount,
          state.tags,
          state.tagsE,
          state.edit
        ) */

      }

      closeModal()
    } else {
      props._showAlertModal(
        'Campos Vacíos',
        'Por favor ingresa un nombre y una descripción',
        Modal.MODAL_TYPE_ERROR,
        () => { }
      )
    }
    console.log("Campos vacios*****");

  }

  return (
    <div
      id='new-audience-modal'
      style={{ display: 'none' }}
      className={classnames('upsert-modal')}
    >
      <div className='foot-mnc'>
        <div className='formOneStep'>
          <div className='titleOneStep'>
            <p className='h3'>
              {
                state.edit
                  ? 'Actualizemos tu público'
                  : 'Creemos un nuevo público'
              }
            </p>
          </div>

          <div className='bodyOneStep bodyOneStepPublico '>
            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Ponle un nombre a tu p&uacute;blico
                </Label>
                <Input
                  required
                  type='text'
                  name='name'
                  className='input'
                  value={state.name}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo madres solteras'
                  invalid={errors.name}
                />
                <FormFeedback>Ingresa un nombre</FormFeedback>
              </FormGroup>
            </div>

            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Describe tu p&uacute;blico
                </Label>
                <Input
                  required={true}
                  type='text'
                  name='newTag'
                  className='input'
                  value={state.newTag}
                  onKeyDown={submitTag}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo mujeres de 18 a 25 años con hijos'
                />
                <div className="tagsContent tagsCont1" id="tagsContent">
                  {
                    state.tags && state.tags.data && state.tags.data.map(tag => (
                      <div key={tag} className="tagCont">
                        <p>{tag}</p>
                        <i
                          onClick={() => removeTag(tag)}
                          className="fas fa-times"
                        />
                      </div>
                    ))
                  }
                </div>
              </FormGroup>
            </div>
            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Describe tu p&uacute;blico Excluido
                </Label>
                <Input
                  type='text'
                  name='newTagE'
                  className='input'
                  value={state.newTagE}
                  onKeyDown={submitTagExcluyente}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo padres de 18 a 25 años con hijos'
                />
                <div className="tagsContent tagsCont1" id="tagsContent">
                  {
                    state.tagsE && state.tagsE.data && state.tagsE.data.map(tagE => (
                      <div key={tagE} className="tagCont">
                        <p>{tagE}</p>
                        <i
                          onClick={() => removeTagE(tagE)}
                          className="fas fa-times"
                        />
                      </div>
                    ))
                  }
                </div>
              </FormGroup>
            </div>
          </div>

          <div className='footOneStep w-100 footOneStepModal3'>
            <a href='#' className='btnCancelOS firstBtn' onClick={closeModal}>
              Cancelar
            </a>
            {
              state.edit
                ? <a href='#' className='btnConfirmOS secondBtn' onClick={handleUpsertEdit}>Confirmar</a>
                : <a href='#' className='btnConfirmOS secondBtn' onClick={handleUpsert}>Confirmar</a>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  edit: state.audiences.edit,
  accounts: state.accounts.list,
  audiences: state.audiences.list,
  currentUser: state.app.current_user,
  pwaAudiences: state.pwa.audiences.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
})

const mapDispatchToProps = (dispatch) => ({
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  _updateAudience: (id, name, account, edit, tags, tagsE, updateAt, createAt, active, usuario, accounts, userEmail) =>
    dispatch(updateAudience(id, name, account, edit, tags, tagsE, updateAt, createAt, active, usuario, accounts, userEmail)),
  _createAudience: (id, name, account, tags, tagsE, edit, createAt) =>
    dispatch(createAudience(id, name, account, tags, tagsE, edit, createAt)),
  _createNewAudience: (id, name, account, tags, tagsE, usuario, accounts, userEmail) => //New Audience Creation
    dispatch(createNewAudience(id, name, account, tags, tagsE, usuario, accounts, userEmail)),
  _pwaUpsertAudience: (id, name, account, tags, tagsE, edit) =>
    dispatch(pwaUpsertAudience(id, name, account, tags, tagsE, edit)),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(
        idAccount,
        modulo,
        data,
        usuario,
        accounts,
        action
      )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(UpsertAudience))