import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { v4 } from "uuid";
import { Select } from "antd";
import { withRouter } from "react-router-dom";
import { withUtils } from "utils";
import TableView from "./pages/tableView";
import { DateRangeFilter } from "libs/dateRange";
import { Auth } from "aws-amplify";
import DataTableUtils, {
  Share,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";
import { showLoader } from "redux/actions/loader.actions";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import { columnsLovs, columnsLovsConclict } from "config/columns";
import { Tooltip } from "antd";
import {
  parseISO,
  endOfMonth,
  isWithinInterval,
  startOfMonth,
  isToday,
  isYesterday,
  parse,
  format,
  isValid,
} from "date-fns";
import { sincPartialOdoo } from "redux/actions/odooOvs.actions";
import iconoSinc from "./assets/img/icono.png";
import iconoSincHover from "./assets/img/iconohover.png";

const { Option } = Select;

const Lovs = (props) => {
  const orderDefault = [
    "order_line_id_v6",
    "orderNumber",
    "product_uom_qty",
    "idsCampaign",
    "idsBudget",
    "flagErrorMessage",
  ];
  const [nameList, setNameList] = useState("");
  const [filterInfo, setFilterInfo] = useState([]);
  const [data, setData] = useState([]);
  const [views, setViews] = useState([]);
  const [dataBack, setDataBack] = useState([]);
  const [activate, setActivate] = useState(false);
  const [datesToShow, setDatesToShow] = useState({
    fechaIni: startOfMonth(new Date()),
    fechaFin: endOfMonth(new Date()),
  });
  const [filterTextValue, setFilterTextValue] = useState("");
  const [columns, setColumns] = useState(columnsLovs);
  const [columnsConflict, setColumnsConflict] = useState(columnsLovsConclict);
  const [textSinc, setTextSinc] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (props.state === 2) {
      getView(true);
      getAllLovsByFlag();
    } else if (props.state === 3) {
      getAllLovs();
      getView(true);
    }
  }, [props.state]);

  // useEffect(() => {
  //   assignedLovsAgo();
  // }, [dataBack, datesToShow]);

  const assignedLovsAgo = () => {
    const lovsAvailable = dataBack.filter((ele) => {
      const createDate = parseISO(ele.create_date);
      return isWithinInterval(createDate, {
        start: datesToShow.fechaIni,
        end: datesToShow.fechaFin,
      });
    });
    setData(lovsAvailable);
    return lovsAvailable;
  };

  const getAllLovsByFlag = async () => {
    props._showLoader(true);
    const response = await APILA.getFlagsByAccount({
      account: props.currentAccount,
    });
    let lovs = response.data.data;
    setDataBack(lovs);
    props._showLoader(false);
  };
  const getAllLovs = async () => {
    props._showLoader(true);
    const response = await API.getAllLovs({
      account: props.currentAccount,
    });
    let lovs = response.data.data;
    setDataBack(lovs);
    props._showLoader(false);
  };

  const handleColumnSortChange = (positions) => {
    let cols = props.state === 2 ? columnsConflict : columns;
    let aux = cols.map((col) => col);
    positions.forEach((columnId, position) => {
      let index = cols.findIndex((col) => col.id === columnId);
      if (index !== -1) {
        aux[index].position = position;
      }
    });

    aux = _.orderBy(aux, ["position"]);

    props.state === 2 ? setColumnsConflict(aux) : setColumns(aux);
  };

  const actualizarViewActive = async (id, estado) => {
    props._showLoader(true);
    let body = {
      id: id,
      active: estado,
    };
    await APILA.updateViewActive(body);
    getView();
    props._showLoader(false);
  };

  const handleAddView = async (name) => {
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    let viewMatch = views.find((ele) => ele.name === name);
    let idView = "";
    if (viewMatch) idView = viewMatch.id;
    else idView = v4();
    if (id) actualizarViewActive(id.id, false);
    let body = {
      id: idView,
      name: name,
      component: props.state === 2 ? "lovsConflict" : "lovs",
      columns: props.state === 2 ? columnsConflict : columns,
      account: props.currentAccount,
      user: props.currentUser,
      active: true,
    };
    await APILA.insertView(body);
    getView();
    props._showLoader(false);
  };

  const getView = async (initialValue) => {
    if (Auth.user) {
      let cols = props.state === 2 ? columnsConflict : columns;
      props._showLoader(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await APILA.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: props.state === 2 ? "lovsConflict" : "lovs",
      });
      setViews(viewsData.data.views);
      if (viewsData.data.views.length === 0) setNameList("lista");
      else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          setNameList(vistaActiva.name);
          if (initialValue) {
            let nombres = [];
            vistaActiva.columns.forEach((ele) => {
              nombres.push(ele.id);
              handleShowColumnChange(ele.id, ele.omit);
            });
            handleColumnSortChange(nombres);
          }
        } else {
          cols.forEach((ele) => {
            handleShowColumnChange(ele.id, ele.omit);
          });
          handleColumnSortChange(
            props.state === 2 ? orderDefault : orderDefault
          );
          setNameList("lista");
        }
      }
      props._showLoader(false);
    }
  };

  const exportDataToExcel = () => {
    const cols = props.state === 2 ? columnsConflict : columns;
    const filteredColumns = cols.filter((col) => !col.omit);

    const headers = filteredColumns.map((col) => col.name);
    const selectors = filteredColumns.map((col) => col.id);

    const dataExcel = [headers];

    data.forEach((element) => {
      const excelData = selectors.map((selector) => {
        const value = element[selector];
        return selector === "idsCampaign" || selector === "idsBudget"
          ? value.toString()
          : value;
      });
      dataExcel.push(excelData);
    });

    return dataExcel;
  };

  useEffect(() => {
    if (!filterTextValue || !datesToShow) return;
    setTimeout(() => {
      handleFilterTextChange(filterTextValue);
    }, 700);
  }, [datesToShow]);

  const handleFilterTextChange = (texto) => {
    if (texto !== "") {
      setFilterTextValue(texto);
      const filteredRecords = assignedLovsAgo().filter((record) => {
        const values = [
          record.order_line_id_v6,
          record.orderNumber,
          record.orderNumber,
          record.product_uom_qty,
          record.budgetAvailable,
          record.reserve,
        ];
        return values.some((value) =>
          String(value).toLowerCase().includes(texto.toLowerCase())
        );
      });
      setData(filteredRecords);
    } else {
      setFilterTextValue("");
      assignedLovsAgo();
    }
  };

  function obtenerHoraFormateada(fechaStr) {
    return fechaStr?.split(",")[1] || "";
  }

  function esFechaValida(fecha) {
    return !isNaN(fecha.getTime());
  }

  function obtenerFechaFormateada(fechaStr) {
    const formatoEsperado = /\d{4}-\d{2}-\d{2}/;
    if (!formatoEsperado.test(fechaStr?.split(",")[0])) {
      return format(new Date(), "dd/MM/yyyy HH:mm:ss");
    }
    const fechaCorto = parse(fechaStr?.split(",")[0], "yyyy-MM-dd", new Date());
    if (isValid(fechaCorto)) {
      return format(fechaCorto, "dd/MM/yyyy");
    } else {
      return format(new Date(), "dd/MM/yyyy HH:mm:ss");
    }
  }

  function determinarDia(fechaStr) {
    const formatoCorto = "yyyy-MM-dd";
    const formatoLargo = "dd/MM/yyyy, HH:mm:ss";

    // Intentar parsear la fecha en formato largo
    let fechaFormatoLargo = parse(fechaStr, formatoLargo, new Date());
    let validate = esFechaValida(fechaFormatoLargo);

    if (!validate) {
      // Si no se pudo parsear en formato largo, intentar en formato corto
      fechaFormatoLargo = parse(fechaStr, formatoCorto, new Date());
      validate = esFechaValida(fechaFormatoLargo);
    }

    if (isToday(fechaFormatoLargo)) {
      return "hoy " + obtenerHoraFormateada(fechaStr, formatoLargo);
    } else if (isYesterday(fechaFormatoLargo)) {
      return "ayer " + obtenerHoraFormateada(fechaStr, formatoLargo);
    } else {
      return obtenerFechaFormateada(
        fechaStr,
        validate ? formatoCorto : formatoLargo
      );
    }
  }

  const handleShowColumnChange = (column, show) => {
    const targetColumns = props.state === 2 ? columnsConflict : columns;
    const updatedColumns = [...targetColumns];

    const columnIndex = updatedColumns.findIndex((col) => col.id === column);
    if (updatedColumns[columnIndex]) {
      updatedColumns[columnIndex].omit = show;
    }
    if (props.state === 2) {
      setColumnsConflict(updatedColumns);
    } else {
      setColumns(updatedColumns);
    }
  };

  const handleDeleteView = async (name) => {
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        let cols = props.state === 2 ? columnsConflict : columns;
        setNameList("lista");
        cols.forEach((ele) => {
          handleShowColumnChange(ele.id, false);
        });
        handleColumnSortChange(props.state === 2 ? orderDefault : orderDefault);
      }
    }
    await APILA.deleteView({ id: name });
    getView();
    props._showLoader(false);
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarViewActive(id.id, false);
    actualizarViewActive(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.id, ele.omit);
      } else {
        handleShowColumnChange(ele.id, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(props.state === 2 ? orderDefault : orderDefault);
    }
  };

  const handleFilterPerColumn = (value) => {
    const dataToFilter = assignedLovsAgo();
    setFilterInfo(value);
    let dataFinal = [];

    if (value.length === 0) return setData(dataToFilter);

    const filterMap = {
      Venta: "product_uom_qty",
      "Presupuesto implementado": "reserve",
      "Saldo disponible": "budgetAvailable",
      "Orden de venta": "orderNumber",
      "Línea de OV": "order_line_id_v6",
    };

    value.forEach((ele) => {
      const field = filterMap[ele.column];
      if (!field) return;

      const filterConditions = {
        Menor: (value, eleValue) => value < Number(eleValue),
        Igual: (value, eleValue) => value === Number(eleValue),
        Mayor: (value, eleValue) => value > Number(eleValue),
      };

      const filterFunction = filterConditions[ele.type];
      console.log("Value", ele, field);
      const dataFilter = dataToFilter.filter((element) =>
        field === "orderNumber" || field === "order_line_id_v6"
          ? element[field].toString().includes(ele.value)
          : filterFunction(element[field], ele.value)
      );

      if (dataFilter.length > 0) {
        dataFinal.push(...dataFilter);
      }
    });

    setData(dataFinal);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.closest(".boxCalendar")
      ) {
        setActivate(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const changeState = async (value) => {
    // props.setIsLoading(true);
    // let name = props.accounts.find((ele) => ele.id === props.currentAccount);
    // await APILA.sincPartial({
    //   account: props.currentAccount,
    //   name: name.name,
    // });
    props.setState(value);
    localStorage.setItem("viewBudget", value);
    // props.setIsLoading(false);
  };

  const handleCalendarFilter = (value) => {
    setDatesToShow({
      fechaFin: value.endDate,
      fechaIni: value.startDate,
    });
  };

  return (
    <>
      <div className="barraSuperior barraSuperiorBudget">
        <Select
          id="viewSelect"
          defaultValue={props.state}
          showSearch={false}
          onChange={(e) => changeState(e)}
          className="select-dashboard"
        >
          <Option value={1} className="fa textSelectBudget">
            <Tooltip
              title="Vista de presupuestos"
              placement="rightTop"
              color="#002448"
            >
              <div>
                <i className="fa fa-table"></i> Vista de presupuestos{" "}
              </div>
            </Tooltip>
          </Option>
          <Option value={4} className="fa textSelectBudget">
            <Tooltip
              title="Presupuestos en conflictos"
              placement="rightTop"
              color="#002448"
            >
              <div>
                <i className="fa fa-table"></i> Presupuestos en conflictos{" "}
              </div>
            </Tooltip>
          </Option>
          <Option value={3} className="fa textSelectBudget">
            <Tooltip
              title="Vista de líneas de OV"
              placement="rightTop"
              color="#002448"
            >
              <div>
                <i className="fa fa-table"></i> Vista de líneas de OV{" "}
              </div>
            </Tooltip>
          </Option>
          <Option value={2} className="fa textSelectBudget">
            <Tooltip
              title="Líneas de OV en conflicto"
              placement="rightTop"
              color="#002448"
            >
              <div>
                <i className="fa fa-table"></i> Líneas de OV en conflicto{" "}
              </div>
            </Tooltip>
          </Option>
        </Select>

        <DataTableUtils
          data={data}
          valueFilter={filterTextValue}
          onChangeFilterText={(value) => handleFilterTextChange(value)}
        >
          <Tooltip color="#002448" placement="top" title="Exportar información">
            <div className="ExportBtn">
              <Share
                data={exportDataToExcel()}
                columns={columns}
                isArray={true}
                exportXLSX={true}
                exportTXT={true}
                exportDropbox={true}
                exportDrive={true}
                exportCSV={true}
                printable={true}
                exportPNG={true}
                idImg={"root"}
                appKey="2u78gno172idwbz"
                exportLink={true}
              />
            </div>
          </Tooltip>
          <Tooltip
            color="#002448"
            placement="top"
            title="Personalizar columnas"
          >
            <div id="shareBtn">
              <ShowColumn2
                columns={props.state === 2 ? columnsConflict : columns}
                showColumn={(data, data1) => {
                  handleShowColumnChange(data, data1);
                }}
                showView={(vista, show) => {
                  handleShowView(vista, show);
                }}
                onSortChange={(positions) => {
                  handleColumnSortChange(positions);
                }}
                vistas={views}
                setVista={(nombre) => {
                  handleAddView(nombre);
                }}
                deleteView={(name) => {
                  handleDeleteView(name);
                }}
                nameAc={nameList}
              />
            </div>
          </Tooltip>
          <Tooltip color="#002448" placement="top" title="Filtrar y agrupar">
            <div className="Filtro2 filtro3 ">
              {props.state === 2 && (
                <FilterColumn2
                  columns={_.orderBy(
                    columnsConflict,
                    ["name"],
                    ["asc", "desc"]
                  )}
                  options={[]}
                  data={[
                    {
                      nombrePadre: "Conflictos",
                      name: "Disponibles negativos",
                    },
                    {
                      nombrePadre: "Conflictos",
                      name: "Lovs borradas",
                    },
                  ]}
                  changeGrouped={() => {}}
                  onFilterColumn={handleFilterPerColumn}
                  filterInfoCheck={filterInfo}
                  categories={[["Conflictos"]]}
                />
              )}
              {props.state === 3 && (
                <FilterColumn2
                  columns={_.orderBy(columns, ["name"], ["asc", "desc"])}
                  options={[]}
                  data={data}
                  chalngeGrouped={() => {}}
                  onFilterColumn={handleFilterPerColumn}
                  filterInfoCheck={filterInfo}
                  categories={[]}
                />
              )}
            </div>
          </Tooltip>
          <div
            className="boxCalendar"
            ref={modalRef}
            onClick={() => setActivate(!activate)}
          >
            <div className="boxStartEndDate">
              <p className="startDate">
                Del {new Date(datesToShow.fechaIni).toLocaleDateString("es-GT")}
              </p>
              <p
                className="endDate"
                onClick={() => setActivate((prev) => !prev)}
              >
                {" "}
                Hasta{" "}
                {new Date(datesToShow.fechaFin).toLocaleDateString("es-GT")}
              </p>
            </div>
            <div className="container-icon-calendar">
              <i
                className="fa fa-calendar"
                aria-hidden="true"
                style={{ color: "black" }}
              ></i>
            </div>
            {activate && (
              <div onClick={(e) => e.stopPropagation()}>
                <DateRangeFilter
                  onActivate={activate}
                  onChange={handleCalendarFilter}
                  initDate={datesToShow.fechaIni}
                  finishDate={datesToShow.fechaFin}
                  visibleButtonApply={true}
                  getFilterData={assignedLovsAgo}
                />
              </div>
            )}
          </div>
        </DataTableUtils>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <a
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              color: textSinc ? "#A689F9" : "#686868",
            }}
          >
            {" "}
            {textSinc ? "¡ERP actualizado! " : "Última sincronización "}{" "}
          </a>
          <a
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              marginRight: "-15px",
              marginLeft: "10px",
              fontWeight: 600,
            }}
          >
            {determinarDia(props.lastSinc)}
          </a>
          <a
            href="#"
            onClick={async () => {
              await props._sincPartialOdoo(
                props.currentAccount,
                props.accounts
              );
              setTextSinc(true);
              setTimeout(() => {
                setTextSinc(false);
              }, 30000);
            }}
            style={{
              marginLeft: "2%",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
            className="hover-container"
          >
            <img src={iconoSinc} alt="Icono Sinc" className="imagen-normal" />
            <img
              src={iconoSincHover}
              alt="Icono Sinc"
              className="imagen-hover"
            />
          </a>
        </div>
        <a href="" />
        <TableView
          columns={props.state === 2 ? columnsConflict : columns}
          lovs={true}
          filterText={""}
          data={_.orderBy(data, ["create_date"], ["desc", "asc"])}
          setData={setData}
          permissions={{}}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  lastSinc: state.odoo.lastSinc,
  clients: state.clients,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  account: state.accounts.default,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  allCampaigns: state.allCampaigns,
});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _sincPartialOdoo: (account, accounts) =>
    dispatch(sincPartialOdoo(account, accounts)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Lovs)));
