import React from "react";

// components
import MetaTags from "components/metatags";
import Content from "components/categories";
import UpsertCategoryModal from "components/categories/modal/upsert_category";

const CategoryListPage = () => (
  <>
    <MetaTags title="Let's Advertise Ads - Categorías y subcategorías" description="En Let's Advertise Ads puedes asignar tags para filtrar, agrupar, analizar y conocer predicciones acerca de tus campañas de publicidad. Conocer más aquí."/>
    {/* <MetaTags title="Categorías" description="Página de categorias" /> */}

    <div className="content-category">
      <div id="shadow" className="shadow hide"></div>
      <Content />
    </div>

    <UpsertCategoryModal />
  </>
);

export default CategoryListPage;
