import React from 'react'

// components
import Info from 'components/profile/info'
import MetaTags from 'components/metatags'
import MyProfile from 'components/profile'
import './style.scss'

const ProfilePage = () => {
  return(
    <>
      <MetaTags title="Let's Advertise Ads - Mi perfil" description="Gestiona los datos de tu cuenta de Let's Advertise Ads en pasos sencillos. Actualiza tu perfil para estar siempre al día de tus campañas de publicidad."/>
      {/* <MetaTags title='Perfil' description='Página de perfil' /> */}

      <div className="form formContainer">
        <div className="containerFlex">
          <MyProfile/>
          <Info/>
        </div>
      </div>
    </>
  )
}

export default ProfilePage
