import Layout from "../layout";
import { useDispatch } from "react-redux";
import { setCurrentOption } from "redux/actions/digitalAssets.actions";
import { columnsInformacionCuentas } from "config/columns";
import { useSelector } from "react-redux";
import DetailColEdit from "components/DetailCol";
import ModalAccountInformation from "../Modals/InformacionCuenta";
import { getInformationCurrentAccount } from "redux/actions/accountInformation.actions";
import "../ActivosDigitales/style.scss";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Modal from "redux/actions/modal.actions";
import { withUtils } from "utils";
import { newEmailValidation } from "redux/actions/email.actions";
import ReactDataTable from "@easygosanet/react-datatable";
import { useLocation, useHistory } from "react-router-dom";

const InformacionCuenta = (props) => {
  /* Hooks para la navegacion y push al pathname */
  const location = useLocation();
  const history = useHistory();

  /* Instancias para buscar parametros en la url */
  const currentUrl = new URLSearchParams(location.pathname);

  /* Obtencion de los valores */
  const accountParams = location.pathname
    ?.split("/configuracion/informacionCuenta/account=")[1]
    ?.split("&")[0];
  const viewSelectorParams = Number(currentUrl.get("view_selector"));
  const pageParams = Number(currentUrl.get("page"));

  const dispatch = useDispatch();
  const listOptions = useSelector((state) => state.digitalAssets.options);
  const currentInformationAccount = useSelector(
    (state) => state.accountInformation.list
  );
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [dataBackup, setDataBackup] = useState([]);

  const changeUrl = ({ view = viewSelectorParams, page = pageParams }) => {
    const validateAccount =
      accountParams === null ||
      accountParams === undefined ||
      accountParams.length === 0
        ? props.currentAccount
        : accountParams;
    history.push({
      pathname: `/configuracion/informacionCuenta/account=${validateAccount}&view_selector=${view}&page=${page}`,
    });
    if (location.search.length > 0) {
      history.push({
        search: location.search,
      });
    }
  };

  let columnsMapping = {
    name: {
      cell: (row) => {
        return (
          <DetailColEdit
            key={row.id}
            text={row.name}
            id={"name"}
            nameColumn={row}
            editInfo={() => editInfo(row)}
            edit={true}
            activate={true}
            permissions={{ ...props.permissions, delete: false }}
          />
        );
      },
    },
  };

  const handleChangeSelector = (option) => {
    let map = {
      1: listOptions[0],
      2: listOptions[1],
    };
    props.setValue(option);
    dispatch(setCurrentOption(map[option]));
    changeUrl({
      page: pageParams,
      view: option,
    });
    history.push({
      search: "",
    });
  };

  const handleFilterText = (filterValue) => {
    if (!filterValue) {
      history.push({
        search: "",
      });
      setData(currentInformationAccount);
      return;
    }
    // history.push({
    //   search: `search_query=${filterValue}`,
    // });
    const lowerCaseValue = filterValue.toLowerCase();
    let filters = dataBackup.filter((accountInfo) => {
      return ["name", "email", "address", "phone"].some((key) =>
        accountInfo[key]?.toLowerCase()?.includes(lowerCaseValue)
      );
    });
    setData(filters);
  };

  useEffect(() => {
    setData(currentInformationAccount);
    setDataBackup(currentInformationAccount);
  }, [currentInformationAccount]);

  const getCurentClient = async () => {
    try {
      await dispatch(
        getInformationCurrentAccount({ account: props.currentAccount })
      );
    } catch (error) {
      console.log("Error getCurrentClient", { error });
    }
  };

  const editInfo = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  let columnsMap = columnsInformacionCuentas.map((element) => {
    let mappingElementColumn = columnsMapping[element.id];
    if (mappingElementColumn) {
      return {
        ...element,
        ...mappingElementColumn,
      };
    }
    return element;
  });

  const [columns] = useState([...columnsMap]);

  useEffect(() => {
    if (!props.currentAccount) return;
    getCurentClient();
  }, [props.currentAccount]);

  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        fontSize: "0.9rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "160px",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  return (
    <>
      {props.permissions.view ? (
        <>
          {" "}
          <Layout
            isLoading={false}
            handleChangeSelector={handleChangeSelector}
            search={""}
            handleFilterText={handleFilterText}
            setEditData={() => {}}
            columns={columns}
            textTooltip="Crear información de cuenta"
            exportDataToExcel={() => {}}
            handleShowColumnChange={() => {}}
            handleFilterPerColumn={() => {}}
            handleAddView={() => {}}
            handleDeleteView={() => {}}
            handleColumnSortChange={() => {}}
            create={false}
            permissions={props.permissions}
          >
            <section className="main_emails_sectionTable">
              <ReactDataTable
                progressPending={isLoading}
                noDataComponent={"No hay datos para mostrar"}
                columns={columns}
                data={data}
                create={false}
                persistTableHead
                customStyles={customStyles}
                paginationComponentOptions={{
                  rowsPerPageText: "Elementos por página",
                  rangeSeparatorText: "de",
                }}
                pagination
                noHeader
              />
            </section>
          </Layout>
          {showModal && (
            <ModalAccountInformation
              showModal={showModal}
              editData={editData}
              setShowModal={setShowModal}
              info={getCurentClient}
            />
          )}
        </>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(InformacionCuenta));
