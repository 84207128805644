/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'

import {
  getApps,
  getPages,
  setProp,
  savePage,
} from 'redux/actions/fanpageAsociar.actions'
import { withUtils } from 'utils'

const Asociar = (props) => {
  const login = () => {
    window.FB.getLoginStatus(function (response) {
      console.log('FBresponse', response)
      if (response.status === 'connected') {
        props.getPages(response.authResponse.accessToken)
      }
    })
  }

  useEffect(() => {
    if (!document.getElementById('facebook-jssdk')) {
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/es_LA/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }

    window.fblogin = login
    props.getApps()
  }, [])

  useEffect(() => {
    if (props.appId) {
      window.FB.init({
        appId: props.appId,
        cookie: true,
        xfbml: true,
        version: 'v7.0',
      })
    }
  }, [props.appId])

  const changeApp = (option) => {
    props.setProp({ name: 'appId', value: option.value })
  }

  const asociar = (e) => {
    e.preventDefault()
    props.savePage(props.selectedPages)
  }

  return (
    <>
      <div>
        <Link to='/scan'>Regresar</Link>
      </div>
      <div>
        <label>Elegir app:</label>
        <Select
          options={props.apps.map((app) => ({
            value: app.id,
            label: app.name,
          }))}
          onChange={changeApp}
        />
      </div>
      <div
        className='fb-login-button'
        data-size='large'
        data-button-type='continue_with'
        data-layout='default'
        data-auto-logout-link='false'
        data-use-continue-as='false'
        data-width=''
        data-scope='public_profile,email,pages_show_list,pages_read_engagement,read_insights,pages_read_user_content,pages_manage_metadata,pages_messaging'
        data-onlogin='fblogin()'
      />
      <div id='div-asociar' hidden={!props.pages.length}>
        <label>Elegir fanpage:</label>
        <Select
          options={props.pages.map((page) => ({
            value: page.id,
            label: page.name,
            token: page.access_token,
          }))}
          isMultiple
          onChange={(values) =>
            props.setProp({ name: 'selectedPages', value: values })
          }
        />
        <button type='button' id='btn-asociar' onClick={asociar}>
          Asociar
        </button>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  apps: state.fanpageAsociar.apps,
  appId: state.fanpageAsociar.appId,
  pages: state.fanpageAsociar.pages,
  selectedPages: state.fanpageAsociar.selectedPages,
})

const mapDispatchToProps = {
  getApps,
  setProp,
  getPages,
  savePage,
}

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(Asociar)))
