//CLASE COMO TABLA
import React, { useEffect, useState } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";

//ESTO SE CONSUME DE UN SERVICIO Y LOS DATOS DEVUELTOS POR ESE SERVICIO SE ENVÍAN A LA TABLA
//SERVICIO: SERVICIO PARA OBTENER PAUTAS
//(A TRAVÉS DE PROPS)

import ReactDataTable, {
  Graph,
  Status,
  DateRange,
  // DetailCol,
  NumberFormat,
} from "@easygosanet/react-datatable";
import LinearProgress from "@material-ui/core/LinearProgress";

import google from "./assets/img/google.png";
import youtube from "./assets/img/youtube.png";
import facebook from "./assets/img/facebook.png";
import linkedin from "./assets/img/linkedin.png";
import instagram from "./assets/img/instagram.png";

import status from "config/status";
import * as Modal from "redux/actions/modal.actions";
import {
  aproveCampaign,
  changeSortFilter,
  changePageFilter,
  changePaginationFilter,
} from "redux/actions/dashboard.actions";
import { addIdMixDetaill } from "redux/actions/modalDetail.actions";

import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

const LinearIndeterminate = () => (
  <div className="progress-table-component">
    <LinearProgress />
  </div>
);

const Medium = ({ id, label }) => {
  switch (id) {
    case 99:
    case 304:
    case 439:
    case 444:
    case 454:
      return <img src={facebook} width="25px" />;
    case 130:
    case 133:
      return <img src={google} width="25px" />;
    case 328:
      return <img src={youtube} width="25px" />;
    case 340:
      return <img src={linkedin} width="25px" />;
    case 397:
    case 440:
    case 453:
      return <img src={instagram} width="25px" />;
    default:
      return label;
  }
};

const Tabla = (props) => {
  const [dataTotal, setDataTotal] = useState([]);
  const { setRowsPerPage, customStyles } = props;

  const CustomMaterialPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
  }) => {
    console.log("rowsPerPage", rowsPerPage);
    setRowsPerPage(rowsPerPage);

    return (
      <TablePagination
        component="nav"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onChangePage={onChangePage}
        onChangeRowsPerPage={({ target }) =>
          onChangeRowsPerPage(Number(target.value))
        }
        ActionsComponent={TablePaginationActions}
      />
    );
  };

  const DetailCol = ({ row }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        className={classnames("catEditFind", {
          "icons-2": row.status !== status.CREADO.id,
          "icons-3": row.status === status.CREADO.id,
        })}
      >
        <i onClick={(e) => intermediaria(row)} className="fas fa-edit" />
        <i
          onClick={(e) => {
            props._addIdMixDetaill(row.idMixDetaill);
            window.$.fancybox.open({
              src: "#detail-pauta-modal",
              //type: 'modal',
              opts: {
                modal: true,
                afterClose: () => {
                  props._addIdMixDetaill(0);
                },
              },
            });
          }}
          className="fas fa-search"
        />
        {row.status === status.CREADO.id && (
          <i
            onClick={(e) => showApproveConfirmation(row.idMixChange)}
            className="fas fa-check"
          />
        )}
      </div>
      <p className="name-fc">{row.name}</p>
    </div>
  );

  const getData = (fecha) => {
    if (!_.isEmpty(fecha)) {
      const fini = fecha.split(".");
      return `${fini[1]}/${fini[0]}/${fini[2]}`;
    } else {
      // return new Date()
      return null;
    }
  };

  const getStatusLabel = (mStatus) => {
    switch (mStatus) {
      // Corriendo
      case status.ACTIVO.id:
        return status.ACTIVO.label;
      // En cola
      case status.CREADO.id:
        return status.CREADO.label;
      // En implementacion
      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.label;
      // Finalizado
      case status.FINALIZADO.id:
      case status.FINALIZADO_SIN_META.id:
      case status.FINALIZADO_SIN_REPORTE.id:
        return status.FINALIZADO.label;
      // Aprobado
      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.label;
      // En revision
      case status.PENDIENTE.id:
      case status.RECHAZADO.id:
      case status.EN_REVISION.id:
        return status.EN_REVISION.label;
      // Cancelado
      case status.ELIMINADO.id:
      case status.NO_IMPLEMENTADO.id:
        return status.ELIMINADO.label;
      default:
        return status.PAUSADO.label;
    }
  };

  const getStatusClassName = (mStatus) => {
    switch (mStatus) {
      // Corriendo
      case status.ACTIVO.id:
        return status.ACTIVO.class;
      // En cola
      case status.CREADO.id:
        return status.CREADO.class;
      // En implementacion
      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.class;
      // Finalizado
      case status.FINALIZADO.id:
      case status.FINALIZADO_SIN_META.id:
      case status.FINALIZADO_SIN_REPORTE.id:
        return status.FINALIZADO.class;
      // Aprobado
      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.class;
      // En revision
      case status.PENDIENTE.id:
      case status.RECHAZADO.id:
      case status.EN_REVISION.id:
        return status.EN_REVISION.class;
      // Cancelado
      case status.ELIMINADO.id:
      case status.NO_IMPLEMENTADO.id:
        return status.ELIMINADO.class;
      default:
        return status.PAUSADO.class;
    }
  };

  const getCategoryName = (categories) => {
    let result = [];

    if (categories) {
      categories.forEach((id) => {
        const category = _.find(props.categories, { id });

        if (category) {
          result.push(category.name);
        }
      });
    }

    return !_.isEmpty(result) ? result.join(", ") : "";
  };

  const intermediaria = (campaignup) => {
    let campaignupd = campaignup;
    console.log("edit_edit_Table", campaignupd);

    //3,6,12,13,16,17 estados no permitidos para actualizar
    if (
      campaignupd.status != 3 &&
      campaignupd.status != 6 &&
      campaignupd.status != 12 &&
      campaignupd.status != 13 &&
      campaignupd.status != 16 &&
      campaignupd.status != 17
    ) {
      props.funcampaignupd(campaignupd);

      window.$.fancybox.open({
        src: "#new-pauta-modal",
        opts: {
          modal: true,
        },
      });
    }
  };

  const showApproveConfirmation = (idMixChange) => {
    props._showConfirmationModal(
      "Aprobar Pauta?",
      `Esta seguro que desea aprobar esta pauta`,
      () => {
        props._aproveCampaign(idMixChange, props.account, props.filter);
      }
    );
  };

  const handleSort = (column, sortDirection) => {
    props._changeSortFilter(sortDirection, column.selector);
    // let catsSorted = _.orderBy(activeCategories, ['name'], ['asc', 'desc']);
    let orderData = [];
    if (sortDirection == "asc") {
      orderData = _.orderBy(
        props.campaigns,
        [column.selector],
        ["asc", "desc"]
      );
    } else {
      orderData = _.orderBy(
        props.campaigns,
        [column.selector],
        ["desc", "asc"]
      );
    }
    console.log("LA DATA SUPONGO", orderData);
    props.setFilteredData(orderData);
  };

  const handlePageChange = (page) => {
    props._changePageFilter(page);
  };

  const handlePerRowsChange = (perPage, page) => {
    props._changePaginationFilter(perPage);
  };

  // TODO: obtener presupuesto gastado de la pauta para mostrar en la grafica
  const graphSeries = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
    },
  ];

  const graphOptions = {
    chart: {
      type: "area",
      width: 100,
      height: 35,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: (seriesName) => "",
        },
      },
      marker: {
        show: false,
      },
    },
  };

  const paginationComponentOptions = {
    paginationRowsPerPageOptions: [50, 100, 150, 200],
  };

  const groups = props.columns.filter((column) => column.group);
  const currentGroup =
    props.groupedBy !== ""
      ? _.find(groups, { selector: props.groupedBy })
      : groups[0];
  /* console.log("groups",groups) */

  /* console.log("props",props) */
  let groupedData = [];
  if (currentGroup?.selector !== "ninguno") {
    let groupValues = [];

    if (currentGroup?.selector === "status") {
      groupValues = [
        {
          id: 1,
          label: "Creado",
        },
        {
          id: 6,
          label: "Detenido para revision",
        },
        {
          id: 10,
          label: "Corriendo",
        },
        {
          id: 12,
          label: "Completados",
        },
        {
          id: 14,
          label: "Pausado",
        },
      ];
    } else if (currentGroup?.selector === "medio") {
      props.mediumbanners.map((medium) => {
        groupValues = [
          ...groupValues,
          {
            id: medium.id_medium,
            label: medium.site,
          },
        ];
      });
    } else if (currentGroup?.selector === "category") {
      props.categories.map((category) => {
        groupValues = [
          ...groupValues,
          {
            id: category.id,
            label: category.name,
          },
        ];
      });

      groupValues = [
        ...groupValues,
        {
          id: "-1",
          label: "Todas",
        },
      ];
    }

    return groupValues.map((group, idx) => {
      console.log("group", group);

      const columns = [
        {
          id: "title",
          name: group.label,
          selector: "title",
          sortable: true,
          cell: (row) => <DetailCol row={row} />,
        },
        {
          id: "idMixDetaill",
          name: "MixDetail",
          selector: "idMixDetaill",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.idMixDetaill}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "category",
          name: "Categoría",
          selector: "category",
          sortable: true,
          omit: currentGroup?.selector === "category",
          format: ({ category }) => getCategoryName(category),
        },
        {
          id: "status",
          name: "Estado",
          selector: "status",
          sortable: true,
          omit: currentGroup?.selector === "status",
          cell: (row) => (
            <Status
              value={row.status}
              label={getStatusLabel(row.status)}
              className={getStatusClassName(row.status)}
            />
          ),
        },
        {
          id: "medio",
          name: "Medio",
          selector: "medio",
          sortable: true,
          maxWidth: "50px",
          omit: currentGroup?.selector === "medio",
          cell: ({ id_medium, medio }) => (
            <Medium id={id_medium} label={medio} />
          ),
        },
        {
          id: "year",
          name: "Rango de Fecha",
          selector: "year",
          sortable: true,
          minWidth: "250px",
          backgroundColor: "#3c3",
          color: "#3c3",
          cell: (row) => (
            <DateRange
              start={new Date(getData(row.fechaIni))}
              end={new Date(getData(row.fechafin))}
              format="dd/mm/yyyy"
            />
          ),
        },
        {
          name: "Presupuesto",
          selector: "budget",
          sortable: true,
          minWidth: "250px",
          cell: (row) => (
            <NumberFormat
              prefix={"$"}
              value={row.budget}
              displayType={"text"}
              thousandSeparator={true}
            />
          ),
        },
        {
          id: "budgetSpend",
          name: "Presupuesto Gastado",
          selector: "budgetSpend",
          sortable: true,
          minWidth: "450px",
          cell: () => (
            <Graph
              type="area"
              width={500}
              height={40}
              series={graphSeries}
              options={graphOptions}
            />
          ),
        },
        {
          id: "cpf",
          omit: false,
          name: "CPF",
          selector: "cpf",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpf}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpr",
          omit: false,
          name: "CPR",
          selector: "cpr",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpr}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpi",
          omit: false,
          name: "CPI",
          selector: "cpi",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpi}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpv",
          omit: false,
          name: "CPV",
          selector: "cpv",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpv}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "adv",
          omit: false,
          name: "ADV",
          selector: "adv",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.adv}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "fr",
          omit: false,
          name: "FR",
          selector: "fr",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.fr}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "banner",
          name: "Banner",
          selector: "banner",
          group: true,
          sortable: true,
          type: "text",
          cell: (row) => row.banner,
        },
        {
          id: "vv25",
          omit: false,
          name: "VV25",
          selector: "vv25",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.video_playbacks_25}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "vv50",
          omit: false,
          name: "VV50",
          selector: "vv50",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.video_playbacks_50}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "vv75",
          omit: false,
          name: "VV75",
          selector: "vv75",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.video_playbacks_75}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "vv95",
          omit: false,
          name: "VV95",
          selector: "vv95",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.video_playbacks_95}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "vv100",
          omit: false,
          name: "VV100",
          selector: "vv100",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.video_playbacks_100}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpa",
          omit: false,
          name: "CPA",
          selector: "cpa",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpa}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpc",
          omit: false,
          name: "CPC",
          selector: "cpc",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpc}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpm",
          omit: false,
          name: "CPM",
          selector: "cpm",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpm}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cpl",
          omit: false,
          name: "CPL",
          selector: "cpl",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cpl}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "swc",
          omit: false,
          name: "SWC",
          selector: "swc",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.swc}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "ctr",
          omit: false,
          name: "CTR",
          selector: "ctr",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.ctr}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "cnr",
          omit: false,
          name: "CNR",
          selector: "cnr",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.cnr}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "fans",
          omit: false,
          name: "Fans",
          selector: "fans",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.fans}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "price",
          omit: false,
          name: "Price",
          selector: "price",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={"$"}
              value={row.price}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "postLikes",
          omit: false,
          name: "Post likes",
          selector: "postLikes",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.post_likes}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "clicks",
          omit: false,
          name: "Clicks",
          selector: "clicks",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.clicks}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "views",
          omit: false,
          name: "Views",
          selector: "views",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.views}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "dailyBudget",
          omit: false,
          name: "Daily budget",
          selector: "dailyBudget",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={"$"}
              value={row.daily_budget}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "frequency",
          omit: false,
          name: "Frequency",
          selector: "frequency",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.frequency}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "prints",
          omit: false,
          name: "Prints",
          selector: "prints",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.prints}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "reach",
          omit: false,
          name: "Reach",
          selector: "reach",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.reach}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "photo",
          omit: false,
          name: "Photo",
          selector: "photo",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.photo}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "event_responses",
          omit: false,
          name: "Event Responses",
          selector: "event_responses",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.event_responses}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "sp_perc",
          omit: false,
          name: "Spent bud",
          selector: "sp_perc",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={"%"}
              value={row.spent_oercent}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "installs",
          omit: false,
          name: "Installs",
          selector: "installs",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.dayly_budget}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "age",
          omit: false,
          name: "Age",
          selector: "age",
          sortable: true,
          group: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.age}
              displayType={"text"}
              thousandSeparator={false}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "segmentation",
          name: "Segmentation",
          selector: "segmentation",
          group: true,
          sortable: true,
          minWidth: "900px",
          type: "text",
          cell: (row) => row.segmentation,
        },
        {
          id: "purchaseUnit",
          name: "Purchase unit",
          selector: "purchaseUnit",
          group: true,
          sortable: true,
          type: "text",
          cell: (row) => row.purchase_unit,
        },
        {
          id: "realPrice",
          omit: false,
          name: "Real price",
          selector: "realPrice",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={"$"}
              value={row.real_price}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "country",
          name: "Country",
          selector: "country",
          group: true,
          sortable: true,
          type: "text",
          cell: (row) => row.country,
        },
        {
          id: "version",
          name: "Version",
          selector: "version",
          group: true,
          sortable: true,
          type: "text",
          cell: (row) => row.version,
        },
        {
          id: "client",
          name: "Client",
          selector: "client",
          group: true,
          sortable: true,
          type: "text",
          cell: (row) => row.client,
        },
        {
          id: "leads",
          omit: false,
          name: "Leads",
          selector: "leads",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.leads}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
        {
          id: "postShares",
          omit: false,
          name: "Post Shares",
          selector: "postShares",
          sortable: true,
          cell: (row) => (
            <NumberFormat
              prefix={""}
              value={row.postShares}
              displayType={"text"}
              thousandSeparator={true}
            />
            // ,<DetailCol row={row}/>
          ),
        },
      ];

      console.log("group", group);
      console.log("groupedData", groupedData);
      if (currentGroup.selector === "medio") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "country") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "segmentation") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "banner") {
        console.log("banner", group);
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "status") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.id
        );
      }
      if (currentGroup.selector === "purchase_unit") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "version") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "client") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "purchase_unit") {
        groupedData = props.campaigns.filter(
          (d) => d[currentGroup.selector] === group.label
        );
      }
      if (currentGroup.selector === "category") {
        if (group.id === "-1") {
          groupedData = props.campaigns.filter(
            (d) => !d[currentGroup.selector]
          );
        } else {
          groupedData = props.campaigns.filter(
            // (d) => d[currentGroup.selector] === group.id
            (d) => _.includes(d[currentGroup.selector], group.label)
          );
        }
      }

      console.log("groupedData", groupedData);
      if (!_.isEmpty(groupedData)) {
        return (
          <div className="scroll_agrupaciones" key={idx}>
            {props.expandable ? (
              <ReactDataTable
                onSort={handleSort}
                data={groupedData}
                columns={columns}
                persistTableHead
                expandable
              />
            ) : (
              <ReactDataTable
                onSort={handleSort}
                data={groupedData}
                columns={columns}
                persistTableHead
              />
            )}
          </div>
        );
      } else {
        return null;
      }
    });
  } else {
    const columns = props.columns;
    return (
      <>
        {props.expandable ? (
          <ReactDataTable
            className="CampaTabla1"
            data={props.campaigns}
            customStyles={customStyles}
            onSort={handleSort}
            columns={columns}
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[50, 100, 150, 200]}
            pagination
            expandable
          />
        ) : (
          <ReactDataTable
            className="CampaTabla1"
            data={props.campaigns}
            customStyles={customStyles}
            onSort={handleSort}
            columns={columns}
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[50, 100, 150, 200]}
            pagination
          />
        )}
      </>
    );
  }
};

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const getNumberOfPages = (rowCount, rowsPerPage) => {
    Math.ceil(rowCount / rowsPerPage);
  };
  const handleFirstPageButtonClick = () => {
    onChangePage(1);
  };

  // RDT uses page index starting at 1, MUI starts at 0
  // i.e. page prop will be off by one here
  const handleBackButtonClick = () => {
    onChangePage(page);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 2);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(getNumberOfPages(count, rowsPerPage));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

const mapStateToProps = (state) => ({
  filter: state.campaigns.filter,
  account: state.accounts.default,
  loading: state.campaigns.loading,
  categories: state.categories.list,
  mediumbanners: state.mediumbanners,
  totalCampaigns: state.campaigns.total,
  paginationPerPage: state.campaigns.filter.paginationPerPage,
  allCampaigns: state.allCampaings,
});

const mapDispatchToProps = (dispatch) => ({
  _addIdMixDetaill: (id) => dispatch(addIdMixDetaill(id)),
  _changePageFilter: (page) => dispatch(changePageFilter(page)),
  _aproveCampaign: (idMixChange, account, filter) =>
    dispatch(aproveCampaign(idMixChange, account, filter)),
  _changeSortFilter: (sortDirection, column) =>
    dispatch(changeSortFilter(sortDirection, column)),
  _changePaginationFilter: (paginationPerPage) =>
    dispatch(changePaginationFilter(paginationPerPage)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabla);
