import React, { useEffect, useState } from "react";
import _, { filter, isEmpty, set } from "lodash";
import { connect } from "react-redux";
import {
  withRouter,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import classnames from "classnames";
import { Tooltip } from "antd";
// organization components
import ReactDataTable, { Status } from "@easygosanet/react-datatable";
import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";

import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { getClients } from "redux/actions/clients.actions";
import { pwaDeleteAudience } from "redux/actions/pwa.actions";
import { updateAccountMembers } from "redux/actions/accounts.actions";
import { updateAudience } from "redux/actions/audiences.actions";
import DetailColEdit from "components/DetailCol";
import {
  setEdit,
  clearEdit,
  getAudiences,
  clearAudiences,
  deleteAudience,
} from "redux/actions/audiences.actions";
import analytics from "../../helpers/analytics";
import * as API from "helpers/apiLA";
import { Analytics, Auth } from "aws-amplify";
import { withUtils } from "utils";
import * as APILA from "helpers/api";
import "./assets/css/styles.scss";
import { fabClasses, setRef } from "@mui/material";
import { v4 } from "uuid";
import PageLoader from "components/page_loader";
import status from "config/status";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import { nextStep } from "redux/actions/userLogout.action";
import { QUOTA_EXCEEDED_ERR } from "node-localstorage";
import Axios from "axios";
import Swal from "sweetalert2";
// const DetailCol = ({ text, className, onClick, permisos}) => (
//   <div className="firstColl w-100">
//     <div onClick={() => {onClick(); }} className={className}>
//       {permisos.edit && (
//         <i className="fas fa-edit"></i>
//       )
//       }
//     </div>
//     <p className="name-fc">
//       {text}
//     </p>
//   </div>
// )

const Actions = ({ onClick }) => (
  <div onClick={() => onClick()} className="secondColl orange cursor-pointer">
    Eliminar
  </div>
);

const ActionsActivate = ({ onClick }) => (
  <div onClick={() => onClick()} className="secondColl green cursor-pointer">
    Activar
  </div>
);

const Content = (props) => {
  const history = useHistory();
  const location = useLocation();
  const orderDefault = ["name", "account", "createAt", "updateAt", "estado"];
  const [nameView, setNameView] = useState("");
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });
  const [account, setAccount] = useState(props.currentAccount);
  const [filters, setFilters] = useState({});

  const url = window.location.href;
  const urlObject = new URL(url);
  const nombreParamans = urlObject.searchParams.get("Nombre") || "";

  const urlActual = new URLSearchParams(window.location.search);
  const [filterText, setFilterText] = useState(urlActual.get("search") || ""); // Obtenemos el valor del search de la url
  const [aud, setAud] = useState([]);
  const [audiencesInit, setAudiencesInit] = useState([]);
  const urlSplit = location.pathname.split("/account=");
  const pathSegmetns = urlSplit[1]?.split("&"); // Arreglo donde separamos los valores que necesitamos en la url
  // const viewValue = "list"
  const viewValue =
    pathSegmetns?.length > 1 ? pathSegmetns[1]?.split("=")[1] : undefined;
  const valueGroup =
    pathSegmetns?.length > 2 ? pathSegmetns[2]?.split("=")[1] : undefined;
  const valuePage =
    pathSegmetns?.length > 3 ? pathSegmetns[3]?.split("=")[1] : undefined;

  const [currentPage, setCurrentPage] = useState(parseInt(valuePage) || 1);
  const [idActiveView, setIdActiveView] = useState("list");
  const [grouped, setGrouped] = useState(valueGroup || "default");

  const [colAct, setColAct] = useState([]);
  const [groupedName, setGroupedName] = useState("");
  const [views, setViews] = useState([]);
  const initValue = [
    {
      column: "Nombre",
      value: nombreParamans || "",
    },
  ];
  const [filterInfo, setFilterInfo] = useState(initValue || []);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [searchFilterData, setSearchFilterData] = useState([]);

  const [columns, setColumns] = useState([
    {
      omit: false,
      id: "name",
      tag: "name",
      name: "Nombre",
      selector: "name",
      sortable: true,
      center: true,
      visible: false,
      type: "text",
      show: true,
      cell: (row) => (
        // <DetailCol
        //   row={row}
        //   text={row.name}
        //   permisos={row.permisos ? row.permisos : ''}
        //   onClick={() =>
        //     goToEditPage(row.id, row.name, row.createAt, row.tags, row.tagsE)
        //   }
        //   center={"true"}
        //   className="edit-fc"
        // />
        //   <DetailColEdit
        //   text={row.name}
        //   id={'name'}
        //   deleteData={() => deleteAudience(row.id, row.name, row)}
        //   deleteV={true}
        //   nameColumn={row}
        //   reActivar={row.permisos.delete ? true : false}
        //     activeData={() => activateAudience(
        //       row.id
        //       , row.name
        //       , row.tags
        //       , row.tagsE
        //       , row.account
        //       , row.active
        //       , row.edit
        //       , row.createAt
        //     )
        //     }
        //   activate={row.active}
        //   editInfo={() => goToEditPage(row.id, row.name, row.createAt, row.tags, row.tagsE)}
        //   edit={true}
        //   onEditValue={editData}
        //   editable={row.permisos ? row.permisos.edit : false}
        //   type={'text'}
        //   permissions={row.permisos ? row.permisos : ''}
        // />
        <DetailColEdit
          text={row.name}
          id={"name"}
          deleteData={() => deleteAudience(row.id, row.name, row)}
          deleteV={true}
          accounts={[]}
          budgetOld={false}
          viewBudget={false}
          nameColumn={row}
          reActivar={row.permisos.delete ? true : false}
          activeData={() =>
            activateAudience(
              row.id,
              row.name,
              row.tags,
              row.tagsE,
              row.account,
              row.active,
              row.edit,
              row.createAt
            )
          }
          activate={row.active}
          editInfo={() =>
            goToEditPage(row.id, row.name, row.createAt, row.tags, row.tagsE)
          }
          edit={true}
          onEditValue={editData}
          // editable={row.permisos ? row.permisos.edit : false}
          editable={false}
          type={"text"}
          permissions={row.permisos ? row.permisos : ""}
        />
      ),
    },
    // {
    //   omit: true,
    //   id: "account",
    //   tag: "account",
    //   name: "Cuenta",
    //   selector: "account",
    //   visible: false,
    //   sortable: true,
    //   center: true,
    //   cell: (row) => getAccountName(row.account),
    // },
    {
      omit: false,
      id: "createAt",
      tag: "createAt",
      name: "Fecha de Creación",
      selector: "createAt",
      sortable: true,
      center: true,
      cell: (row) => formatDate(row.createAt),
    },
    {
      omit: false,
      id: "updateAt",
      tag: "updateAt",
      name: "Última Actualización",
      selector: "updateAt",
      sortable: true,
      center: true,
      cell: (row) => formatDate(row.updateAt),
    },
    {
      type: "text",
      omit: false,
      id: "estado",
      tag: "estado",
      name: "Estado",
      selector: "estado",
      sortable: true,
      center: true,
      cell: (row) => (
        <Status
          value={row.estado}
          label={getStatusLabel(row.estado)}
          className={getStatusClassName(row.estado)}
          justifyContent={"center"}
          center={"true"}
        />
      ),
    },
    /*  {
      omit: false,
      name: "Acción",
      id: "botones",
      tag: "botones",
      selector: "botones",
      minWidth: "200px",
      sortable: false,
      button: true,
      center: true,
      /* cell: (row) => <Actions onClick={() => deleteAudience(row.id, row.name)} />, /
      cell: (row) =>
        row.active == true ? (
          <Actions onClick={() => deleteAudience(row.id, row.name)} />
        ) : (
          <ActionsActivate
            onClick={() =>
              activateAudience(
                row.id,
                row.name,
                row.tags,
                row.tagsE,
                row.account,
                row.active,
                row.edit,
                row.createAt
              )
            }
          />
        ),
    }, */
  ]);
  const pathSegments = location.pathname.split("/");
  const changeUrl = ({
    idView = idActiveView,
    group = grouped,
    page = currentPage,
  }) => {
    const currentSearch = location.search;
    const valorurl = pathSegments[2];
    const valorUlrsplit = valorurl?.split("=")[1]?.split("&")[0]; // Obtenemos el valor que viene de la url para la cuenta
    const validateAccountParams =
      valorUlrsplit === "" || valorUlrsplit === undefined
        ? props.currentAccount
        : valorUlrsplit;
    const newUrl = `/publicos/account=${validateAccountParams}&view=${idView}&group=${group}&page=${page}`;
    history.replace(newUrl);
    if (nombreParamans !== "" || urlActual.get("search") !== "") {
      history.push({
        search: currentSearch,
      });
    }
  };

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
    changeUrl({
      idView: idActiveView,
      group: grouped,
      page: currentPage,
    });
  };

  const getStatusLabel = (mStatus) => {
    switch (mStatus) {
      case "Activo":
        return status.ACTIVO.label;
      default:
        return status.ELIMINADO.label;
    }
  };

  const getStatusClassName = (mStatus) => {
    switch (mStatus) {
      case "Activo":
        return status.ACTIVO.class;
      default:
        return status.ELIMINADO.class;
    }
  };

  const editData = (value) => {
    let title = "Está seguro que desea editar el público?";
    props._showConfirmationModal("Editar público", title, async () => {
      let valor = value[0].value;
      let col = value[0].column;
      let name = value[0].id;
      analytics.accionesTabla({
        accion: "Editar con doble clic",
        ubicacion: "Publicos",
      });
      const updateAt = new Date();
      updateAt.getTime();

      let state = {
        audienciaId: col.id,
        name: valor,
        currentAccount: col.account,
        edit: true,
        tags: col.tags,
        tagsE: col.tagsE,
        createAt: col.createAt,
        updateAt: updateAt,
        active: col.active,
      };
      props
        ._updateAudience(
          state.audienciaId,
          state.name,
          state.currentAccount,
          state.edit,
          state.tags,
          state.tagsE,
          updateAt,
          // state.createAt,
          state.active
        )
        .then(() => {
          getAudiences();
        });
    });
  };

  useEffect(() => {
    setAccount(props.currentAccount);
    actualizarViews(true);
  }, [props.currentAccount]);

  let options1 = [
    "Nombre",
    // 'Fecha de creación',
    //  'Última actualización',
    //  'Estado'
  ];

  const permisos = async () => {
    try {
      setIsLoading(true);
      let userLog = await Auth.currentAuthenticatedUser();
      let permisoAcc = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await API.getRoleNameById({ id: idRol });

      let permissAud = permisosRes.data.rol[0].permissions;
      let permissFn = permissAud.filter((ele) => ele.name == "Públicos");

      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
      /* setIsLoading(false); */
    } catch (err) {
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
      /* setIsLoading(false); */
    } finally {
      setIsLoading(false);
    }
  };

  options1.sort();
  const [options, setOptions] = useState(options1);
  const viewsChangesParams = async () => {
    if (viewValue !== "list") {
      setIdActiveView(viewValue);
      // changeUrl({ idView: viewValue, group: grouped, page: currentPage })
      // let viewData = await API.getViewsByUser
      let response = await Axios.post("http://localhost:8000/getViewById", {
        id: viewValue,
      });
      let nombres = [];
      response.data.data.columns.forEach((ele) => {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      });
      handleColumnSortChange(nombres);
    } else {
    }
  };
  const actualizarViews = async (tipo) => {
    if (Auth.user) {
      setIsLoading(true);
      if (grouped === "name") {
        setGroupedName("name");
        changeUrl({ idView: idActiveView, group: "name", page: currentPage });
        setGrouped("Nombre");
        let op = ["Nombre"];
        setOptions(op);
      } else {
        changeUrl({
          idView: idActiveView,
          group: "default",
          page: currentPage,
        });
      }
      let userData = await Auth.currentAuthenticatedUser()
      let viewsData = await API.getViewsByUser({
        account: props.currentAccount,
        user: userData.username,
        component: "audiences",
      });
      if (viewValue && viewValue !== "list") {
        let viewUser = viewsData.data.views.find(({ id }) => id === viewValue);
        if (!viewUser) return history.replace({ pathname: "/campañas" });
      }
      setViews(viewsData.data.views);
      if (viewsData.data.views.length === 0) {
        setNameView("lista");
        setIdActiveView("list");
        changeUrl({ group: grouped, page: currentPage });
      } else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          setIdActiveView(vistaActiva.id);
          changeUrl({
            idView: vistaActiva.id,
            group: grouped,
            page: currentPage,
          });
          setNameView(vistaActiva.name);
          if (tipo) {
            let nombres = [];
            vistaActiva.columns.forEach((ele) => {
              nombres.push(ele.id);
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(nombres);
          }
        } else {
          setIdActiveView("list");
          if (viewValue !== "list")
            changeUrl({ idView: "list", group: grouped, page: currentPage });
          else changeUrl({ idView: "list", group: grouped, page: currentPage });
          columns.forEach((ele) => {
            handleShowColumnChange(ele.selector, ele.omit);
          });
          handleColumnSortChange(orderDefault);
          setNameView("lista");
        }
      }
      setIsLoading(false);
    }
  };

  const name = (name) => {
    setNameView(name);
  };

  useEffect(() => {
    if (props.currentUser !== "") {
      /* props._getClients();
      account !== "" && props._getAudiences(account); */
      getAudiences();
    } else {
      /* permisos() */
    }
  }, [account, props.currentUser]);

  const DetailCol = ({ text, row, onClick, permisos }) => (
    <div className="content-firstColumn">
      <div className={classnames("iconBand")}>
        {permisos.edit && (
          <Tooltip placement="top" title="Editar" color="#002448">
            <i
              onClick={(e) =>
                goToEditPage(
                  row.id,
                  row.name,
                  row.createAt,
                  row.tags,
                  row.tagsE
                )
              }
              className="fas fa-edit fa-edit1"
            />
          </Tooltip>
        )}
        {permisos.delete && row.active ? (
          <Tooltip placement="top" title="Eliminar" color="#002448">
            <i
              onClick={(e) => deleteAudience(row.id, row.name)}
              className="fas fa-trash"
            />
          </Tooltip>
        ) : (
          <div className="eliminar">
            <i
              className="fas fa-trash-restore-alt"
              onClick={() =>
                activateAudience(
                  row.id,
                  row.name,
                  row.tags,
                  row.tagsE,
                  row.account,
                  row.active,
                  row.updateAt,
                  row.createAt
                )
              }
            />
            <span className="etiqueta">Reactivar</span>
          </div>
        )}
      </div>
      <p className="name-fc22">{text}</p>
    </div>
  );

  const getAudiences = async () => {
    setIsLoadingData(true);
    let audi = await APILA.getAudiences(account);
    await permisos();
    let audiencias = audi.data.audiences;
    if (audiencias.length !== 0) {
      audiencias.forEach((ele) => {
        ele.permisos = permissions;
      });
    }
    setAud(audiencias);
    setAudiencesInit([...audi.data.audiences]);
    setIsLoading(false);
    setIsLoadingData(false);
  };

  let audiences = [];

  if (props.currentUser !== "") {
    audiences = _.sortBy(aud, ["createAt"]);
  } else {
    audiences = props.pwaAudiences;
  }

  const getAccountName = (account) => {
    let acc = _.find(props.accounts, { id: account });
    return acc ? acc.name || "" : "";
  };

  const goToEditPage = (id, name, createAt, tags, tagsE) => {
    let edit = {
      id,
      name,
      createAt,
      tags,
      tagsE,
    };

    props._setEdit(edit);
    analytics.accionesTabla({
      accion: "Editar",
      ubicacion: "Publicos",
    });
    window.$.fancybox.open({
      src: "#new-audience-modal",
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => {
          props._clearEdit();
          setTimeout(() => {
            getAudiences();
          }, 1000)
        },
      },
    });
  };

  const deleteAudience = async (id, name, row) => {
    try {
      setIsLoading(true);
      const count = await APILA.getCountAudiences({ idAudience: id });
      let countCam = count.data.count;
      props._showConfirmationModal(
        "¿Eliminar público?",
        `El público "${name}" que desea eliminar esta asociado a ${countCam} campañas.`,
        () => {
          analytics.accionesTabla({
            accion: "Eliminar",
            ubicacion: "Publicos",
          });
          props._deleteAudience(
            id,
            props.currentUser,
            props.accounts,
            props.currentAccount,
            row
          );
          /* if (props.currentUser !== '') {
          props._deleteAudience(id)
        } else {
          props._pwaDeleteAudience(id)
        } */
          setTimeout(() => {
            setAud([]);
            getAudiences();
          }, 2000);
        }
      );
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  };

  const activateAudience = (
    id,
    name,
    tags,
    tagsE,
    account,
    active,
    edit,
    createAt
  ) => {
    let title = "";
    const updateAt = new Date();
    updateAt.getTime();

    if (active == false) {
      analytics.accionesTabla({
        accion: "Activar",
        ubicacion: "Publicos",
      });
      title = `Esta seguro que desea activar el público "${name}"?`;
      props._showConfirmationModal("Activar Público?", title, () => {
        active = true;
        props._updateAudience(
          id,
          name,
          account,
          edit,
          tags,
          tagsE,
          updateAt,
          createAt,
          active
        );
        setTimeout(() => {
          setAud([]);
          getAudiences();
        }, 2000);
      });
    }
  };

  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return `${date.getDate()} - ${months[date.getMonth()]
      } - ${date.getFullYear()}`;
  };

  const handleFilterTextChange = (value) => {
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Públicos",
        busqueda: filterText,
      });
      setFilterText(value);
      history.push({
        search: `?search=${value}`,
      });
    } else {
      setFilterText("");
      history.push({
        search: ``,
      });
    }
    setVacio(false);
    setFilterInfo([]);
    setSearchFilterData([]);
  };

  const handleShowColumnChange = (column, show) => {
    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
      analytics.ShowHide({
        ubicacion: "Publicos",
      });
    }
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      } else {
        handleShowColumnChange(ele.selector, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    let body = {
      id: idVIew,
      name: name,
      component: "audiences",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await API.insertView(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const actualizarEstado = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const handleDeleteView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        setNameView("lista");
        columns.forEach((ele) => {
          handleShowColumnChange(ele.selector, false);
        });
        handleColumnSortChange(orderDefault);
      }
    }
    await API.deleteView({ id: name });
    actualizarViews();
    setIsLoading(false);
  };

  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];

    let filteredColumns = columns.filter(function (col) {
      return col.omit === false;
    });

    filteredColumns.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });
    //Este for es para data
    //   "exportDataToExcel !_.isEmpty(filteredData) ",
    //   !_.isEmpty(filteredData)
    // );
    // if (!_.isEmpty(filteredData)) {

    // }

    // return dataExcel;
    if (!_.isEmpty(filteredData)) {
      excelData = [];
      if (groupedName !== "") {
        let uniqueData = [];
        filteredData?.forEach((element) => {
          if (!uniqueData.includes(element[groupedName])) {
            uniqueData.push(element[groupedName]);
            dataExcel = [...dataExcel, [], [element[groupedName]]];
            dataExcel.push(headers);
            let groupedData = filteredData.filter((item) => {
              if (element[groupedName] === item[groupedName]) {
                return item;
              }
            });

            if (groupedData.length > 0) {
              groupedData.forEach((grupDta) => {
                let array = [];
                selectors.forEach((sele) => {
                  let valor = "";
                  if (sele === "updateAt" || sele === "createAt") {
                    valor = formatDate(grupDta[sele]);
                  } else {
                    valor = grupDta[sele];
                  }
                  array.push(valor);
                });
                dataExcel.push(array);
              });
            }
          }
        });
      } else {
        dataExcel.push(headers);
        filteredData?.forEach((element) => {
          excelData = [];
          for (let k = 0; k < selectors.length; k++) {
            excelData.push(
              selectors[k] == "name"
                ? element.name
                : selectors[k] == "account"
                  ? getAccountName(element.account)
                  : selectors[k] == "updateAt"
                    ? formatDate(element.updateAt)
                    : selectors[k] == "createAt"
                      ? formatDate(element.createAt)
                      : // selectors[k]=="updateAt" ? element.updateAt.replace(/\./g,'/')+" "+element.updateAt.replace(/\./g,'/'):
                      //  selectors[k]=="updateAt" ? '$ ' + element.updateAt.replace(/\./g,'/'):
                      //  selectors[k]=="budgetSinConsumir" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="spent_percent" ? element[selectors[k]]+ ' %':
                      //  selectors[k]=="ctr" ?  element[selectors[k]]+ ' %':
                      //  selectors[k]=="cpl" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpi" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpr" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpa" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpc" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpv" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpm" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="cpf" ? '$ ' + element[selectors[k]]:
                      //  selectors[k]=="fechaIni" ? element["fechaIni"].replace(/\./g,'/')+" "+element["fechafin"].replace(/\./g,'/'):
                      element[selectors[k]]
            );
          }
          dataExcel.push(excelData);
        });
      }
    }
    return dataExcel;
  };

  const handleColumnSortChange = (positions) => {
    //default
    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.id === columnId);
      if (index != -1) {
        aux[index].position = position;
      }
    });

    aux = _.orderBy(aux, ["position"]);

    setColumns(aux);
  };

  const [vacio, setVacio] = useState(false);
  const handleFilterPerColumn = (value) => {
    let dataToFilterr = [];
    if (value.length > 0) {
      dataToFilterr = filteredData;
      analytics.filtro({
        ubicacion: "Publicos",
      });
      if (dataToFilterr.length > 0) {
        let dataToFilter = dataToFilterr;
        let dataFinal = [];
        value.forEach((el) => {
          if (el.value != "") {
            history.push({
              search: `?search=&${el.column}=${el.value}`,
            });
            setFilterInfo([...value]);
            setFilterText("");
          } else {
            history.push({
              search: ``,
            });
          }
          if (el.column == "Nombre") {
            let dataAlt = filteredSearchData.filter((ele) =>
              ele.name.toLowerCase().includes(el.value.toLowerCase())
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            } else {
              setVacio(true);
            }
          }
          if (el.column == "status") {
            let dataAlt = filteredSearchData.filter(
              (ele) => ele.estado == el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
        });
        //  if (column == 'estado') {
        //   let dataAlt = filteredSearchData.filter(ele => ele.estado.toLowerCase().includes(value.toLowerCase()))
        //   if (dataAlt.length > 0) {
        //     dataFinal = [...dataFinal, ...dataAlt]
        //   }
        // }
        // if (column == 'name') {
        //   let dataAlt = filteredSearchData.filter(ele => ele.name.toLowerCase().includes(value.toLowerCase()))
        //   if (dataAlt.length > 0) {
        //     dataFinal = [...dataFinal, ...dataAlt]
        //   }
        // }
        let dataActiva = dataFinal.filter((ele) => ele.active != false);
        let set = new Set(dataActiva.map(JSON.stringify));
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        setSearchFilterData([...arrSinDuplicaciones]);
      }
    } else {
      setFilterInfo([]);
      setSearchFilterData([]);
      setVacio(false);
    }
  };

  const handleShareContact = (contact) => {
    const currentAccountInfo = _.find(props.accounts, { id: account });
    let members = currentAccountInfo.members || [];

    members = members.filter((member) => member !== contact.id);
    props._updateAccountMembers(account, members);
  };

  let filteredData = audiences.filter((item) => {
    if (item.active) {
      item.estado = "Activo";

      return (
        item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.estado.toString().toLowerCase().includes(filterText.toLowerCase())
      );
    }
    // item.name.toLowerCase().includes(filterText.toLowerCase())
  });

  let filteredSearchData = audiences.filter((item) => {
    item.estado = item.active ? "Activo" : "Eliminado";

    return (
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.estado.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  if (!isEmpty(filters)) {
    if (filters.name.value == "" && filters.estado.value == "") {
      filteredData = audiences.filter((item) => {
        if (item.active)
          // item.name.toLowerCase().includes(filterText.toLowerCase())
          return item.name.toLowerCase();
      });
    } else {
      filteredData = filteredSearchData.filter((item) => {
        let result = true;

        for (const filterKey in filters) {
          let response;
          let filter = filters[filterKey];
          let dataValue = item[filterKey];

          switch (filter.type) {
            case "EQUALS":
              response =
                `${filter.value}`.toLowerCase() ===
                `${dataValue}`.toLowerCase();
              break;
            case "INLIST":
              response = filter.value[`${dataValue}`];
              break;
            case "GREATERTHAN":
              response = filter.value === -1 || dataValue > filter.value;
              break;
            case "LESSTHAN":
              response = filter.value === -1 || dataValue < filter.value;
              break;
            case "EQUALTHAN":
              response = filter.value === -1 || filter.value === dataValue;
              break;
            default:
              response = `${dataValue}`
                .toLowerCase()
                .includes(`${filter.value}`.toLowerCase());
              break;
          }

          result = result && response;
        }

        return result;
      });
    }
  }

  let myContacts = [];

  props.clients.map((client) => {
    if (
      client.id !== props.currentUser &&
      client.name !== "" &&
      account !== ""
    ) {
      const currentAccountInfo = _.find(props.accounts, { id: account });
      //props.utils.log('currentAccountInfo', currentAccountInfo)

      if (currentAccountInfo) {
        let members = currentAccountInfo.members || [];
        //props.utils.log('members', members)

        if (!members.includes(client.id)) {
          myContacts = [
            ...myContacts,
            {
              id: client.id,
              name: `${client.name} (${client.email})`,
            },
          ];
        }
      }
    }
  });
  let filterTimeout;

  const changeGroupedOptions = (value) => {
    analytics.agrupar({
      ubicacion: "Publicos",
    });
    // setIsLoading(true);
    switch (value) {
      case "Estado":
        setGroupedName("estado");
        break;
      case "Nombre":
        setGroupedName("name");
        changeUrl({ idView: idActiveView, group: "name", page: currentPage });
        setGrouped("name");
        let op = ["Nombre"];
        setOptions(op);
        break;
      case "Fecha de creación":
        setGroupedName("createAt");
        break;
      case "Última actualización":
        setGroupedName("updateAt");
        break;
      default:
        setGroupedName("");
        changeUrl({
          idView: idActiveView,
          group: "default",
          page: currentPage,
        });
        setGrouped("");
        let opDefault = ["Nombre"];
        setOptions(opDefault);
        break;
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
  };

  const filterLibraryParams = async () => {
    let audi = await APILA.getAudiences(account);
    await permisos();
    let audienciass = audi.data.audiences;
    if (audienciass.length !== 0) {
      audienciass.forEach((ele) => {
        ele.permisos = permissions;
      });
    }
    let audiSort = _.sortBy(audienciass, ["createAt"]);
    if (location.search !== "") {
      if (nombreParamans !== "") {
        let dataFilter = audiSort.filter((item) => {
          if (item.active) {
            item.estado = "Activo";

            return (
              item.name.toLowerCase().includes(nombreParamans.toLowerCase()) ||
              item.estado
                .toString()
                .toLowerCase()
                .includes(nombreParamans.toLowerCase())
            );
          }
        });
        // setSearchFilterData([...dataFilter]);

        if (dataFilter.length === 0)
          return setSearchFilterData((prev) => [...prev]);
        setSearchFilterData((prev) => {
          return [...prev, ...dataFilter];
        });
      } else if (urlActual.get("search") !== "") {
        let data = audiSort.filter((item) => {
          item.estado = item.active ? "Activo" : "Eliminado";

          return (
            item.name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.estado
              .toString()
              .toLowerCase()
              .includes(filterText.toLowerCase())
          );
        });
        setSearchFilterData([...data]);
      }
    }
  };

  useEffect(() => {
    filterLibraryParams();
  }, []);

  const vistaPorDefecto = () => {
    // searchFilterData trae datos filtrados por la libreria
    // filterText es el texto que se busca en el input
    // filteredSearchData filtrado de las audiencias

    let dataPorDefecto =
      searchFilterData.length > 0
        ? searchFilterData
        : filterText != ""
          ? filteredSearchData
          : vacio
            ? []
            : filteredData;
    if (dataPorDefecto.length !== 0) {
      dataPorDefecto.forEach((ele) => {
        ele.permisos = permissions;
      });
    }

    return (
      <ReactDataTable
        className="publicTable"
        columns={columns}
        customStyles={customStyles}
        // data={searchFilterData.length > 0 ? searchFilterData : filterText == '' ? filteredData : filteredSearchData}
        data={dataPorDefecto}
        paginationPerPage={10}
        paginationDefaultPage={currentPage}
        onChangePage={handlePageChange}
        persistTableHead
        pagination
        noHeader
      />
    );
  };

  const vistaAgrupada = (datos) => {
    let data = [];
    filteredData.forEach((ele) => {
      if (groupedName != "createAt" && groupedName != "updateAt") {
        if (ele[groupedName] == datos) {
          data.push(ele);
        }
      } else {
        if (formatDate(ele[groupedName]) == formatDate(datos)) {
          data.push(ele);
        }
      }
    });
    let dataPorDefecto =
      searchFilterData.length > 0
        ? searchFilterData
        : filterText != ""
          ? filteredSearchData
          : vacio
            ? []
            : data;
    if (dataPorDefecto.length !== 0) {
      dataPorDefecto.forEach((ele) => {
        ele.permisos = permissions;
      });
    }
    return (
      <>
        {groupedName == "createAt" || groupedName == "updateAt" ? (
          <h1>{formatDate(datos)}</h1>
        ) : (
          <h1>{datos}</h1>
        )}
        <ReactDataTable
          className="publicTable"
          columns={columns}
          // data={searchFilterData.length > 0 ? searchFilterData : filterText == '' ? filteredData : filteredSearchData}
          data={dataPorDefecto}
          persistTableHead
          customStyles={customStyles}
          expandable={false}
          pagination
          noHeader
        />
      </>
    );
  };

  let nombres = [];
  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  if (props.isIpv4) {
    return (
      <>
        {
          isLoading
            ?
            <PageLoader />
            :
            <>
              {audiencesInit.length > 0 && audiencesInit.filter(item => item.active).length > 0 && (
                <div className="barraSuperior">
                  <DataTableUtils
                    data={audiences}
                    action={() => {
                      if (permissions.create) {
                        const mCurrentAccount =
                          props.currentUser !== ""
                            ? props.currentAccount
                            : props.currentPWAAccount;

                        if (mCurrentAccount === "") {
                          Swal.fire({
                            title: "Cuenta Requerida",
                            text: "Para utilizar esta sección, debes crear una cuenta.",
                            icon: "warning",
                          }).then((response) => {
                            if (!response.isConfirmed) return
                            if (props?.accounts.length > 0) return
                            props._nexStep(1)
                          })
                          return;
                        }
                        analytics.Add({
                          ubicacion: "Publicos",
                        });
                        window.$.fancybox.open({
                          src: "#new-audience-modal",
                          data: "new-audience",
                          //type: 'modal',
                          opts: {
                            modal: true,
                            afterClose: () => {
                              getAudiences();
                            },
                          },
                        });
                      }
                    }
                    }
                    actionIcon={
                      permissions.create && (
                        <Tooltip
                          placement="top"
                          title="Crear público"
                          color="#002448"
                        >
                          <i className="fas fa-plus" />
                        </Tooltip>
                      )
                    }
                    onChangeFilterText={(value) => {
                      clearTimeout(filterTimeout);
                      filterTimeout = setTimeout(() => {
                        handleFilterTextChange(value);
                      }, 1000);
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title="Exportar información"
                      color="#002448"
                    >
                      <div
                        onClick={() => {
                          analytics.Compartir({
                            ubicacion: "Publicos",
                          });
                        }}
                        className="ExportBtn"
                      >
                        <Share
                          data={exportDataToExcel()}
                          columns={columns}
                          isArray={true}
                          exportXLSX={true}
                          exportTXT={true}
                          exportDropbox={true}
                          exportDrive={true}
                          exportCSV={true}
                          printable={true}
                          exportPNG={true}
                          exportLink={true}
                          idImg={"root"}
                          appKey="2u78gno172idwbz"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title="Personalizar columnas"
                      color="#002448"
                    >
                      <div id="shareBtn">
                        <ShowColumn2
                          columns={columns}
                          showColumn={(data, data1) => {
                            analytics.ShowHide({
                              ubicacion: "Públicos",
                            });
                            handleShowColumnChange(data, data1);
                          }}
                          showView={handleShowView}
                          onSortChange={(positions) => {
                            analytics.Columnas({
                              ubicacion: "Públicos",
                            });
                            handleColumnSortChange(positions);
                          }}
                          vistas={views}
                          setVista={(nombre) => {
                            analytics.accionesVista({
                              ubicacion: "Públicos",
                              accion: "Agregar",
                            });
                            handleAddView(nombre);
                          }}
                          deleteView={(name) => {
                            analytics.accionesVista({
                              ubicacion: "Públicos",
                              accion: "ELiminar",
                            });
                            handleDeleteView(name);
                          }}
                          nameAc={nameView}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title="Filtrar y agrupar"
                      color="#002448"
                    >
                      <div className="Filtro2 filtro3">
                        <FilterColumn2
                          columns={columns}
                          data={audiences}
                          onFilterColumn={handleFilterPerColumn}
                          filterInfoCheck={filterInfo}
                          categories={[]}
                          options={options}
                          changeGrouped={changeGroupedOptions}
                        />
                      </div>
                    </Tooltip>
                  </DataTableUtils>
                </div>
              )}

              {audiencesInit.length > 0 && audiencesInit.filter(item => item.active).length > 0
                ?
                (
                  <>
                    <div className="Kanbang-content">
                      <div className="List-category">
                        {groupedName == ""
                          ? vistaPorDefecto()
                          : filteredData.map((ele) => {
                            if (
                              groupedName != "createAt" &&
                              groupedName != "updateAt"
                            ) {
                              if (!nombres.includes(ele[groupedName])) {
                                nombres.push(ele[groupedName]);
                                return <>{vistaAgrupada(ele[groupedName])}</>;
                              }
                            } else {
                              if (!nombres.includes(formatDate(ele[groupedName]))) {
                                nombres.push(formatDate(ele[groupedName]));
                                return <>{vistaAgrupada(ele[groupedName])}</>;
                              }
                            }
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="form homeSinLogin">
                    <div>
                      <p className="subTitle">Bienvenido a Let's Advertise</p>
                      <h1 className="title">Crea tu primer público</h1>
                      <a
                        href="#"
                        className="btn-plus"
                        onClick={() => {
                          const mCurrentAccount =
                            props.currentUser !== ""
                              ? props.currentAccount
                              : props.currentPWAAccount;

                          if (mCurrentAccount === "") {
                            Swal.fire({
                              title: "Cuenta Requerida",
                              text: "Para utilizar esta sección, debes crear una cuenta.",
                              icon: "warning",
                            }).then((response) => {
                              if (!response.isConfirmed) return
                              if (props?.accounts.length > 0) return
                              props._nexStep(1)
                            })
                            return;
                          }
                          analytics.Add({
                            ubicacion: "Publicos",
                          });
                          window.$.fancybox.open({
                            src: "#new-audience-modal",
                            data: "new-audience",
                            //type: 'modal',
                            opts: {
                              modal: true,
                              afterClose: () => {
                                getAudiences();
                              },
                            },
                          });
                        }}
                      >
                        <i className="fas fa-plus" />
                      </a>
                    </div>
                    <div></div>
                  </div>
                )}
              {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
            </>
        }
      </>
    )
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : isLoadingData ? (
        <PageLoader />
      ) : permissions.view ? (
        <>
          {audiencesInit.length > 0 && (
            <div className="barraSuperior">
              <DataTableUtils
                data={audiences}
                action={
                  permissions.create
                    ? () => {
                      if (permissions.create) {
                        const mCurrentAccount =
                          props.currentUser !== ""
                            ? props.currentAccount
                            : props.currentPWAAccount;

                        if (mCurrentAccount === "") {
                          props._showSelectAccountModal();
                          return;
                        }
                        analytics.Add({
                          ubicacion: "Publicos",
                        });
                        window.$.fancybox.open({
                          src: "#new-audience-modal",
                          data: "new-audience",
                          //type: 'modal',
                          opts: {
                            modal: true,
                            afterClose: () => {
                              getAudiences();
                            },
                          },
                        });
                      }
                    }
                    : null
                }
                actionIcon={
                  permissions.create && (
                    <Tooltip
                      placement="top"
                      title="Crear público"
                      color="#002448"
                    >
                      <i className="fas fa-plus" />
                    </Tooltip>
                  )
                }
                onChangeFilterText={(value) => {
                  clearTimeout(filterTimeout);
                  filterTimeout = setTimeout(() => {
                    handleFilterTextChange(value);
                  }, 1000);
                }}
                valueFilter={filterText}
              >
                <Tooltip
                  placement="top"
                  title="Exportar información"
                  color="#002448"
                >
                  <div
                    onClick={() => {
                      analytics.Compartir({
                        ubicacion: "Publicos",
                      });
                    }}
                    className="ExportBtn"
                  >
                    {permissions.export && (
                      <Share
                        // onShareContact={handleShareContact}
                        // contacts={myContacts}
                        data={exportDataToExcel()}
                        columns={columns}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    )}
                  </div>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="Personalizar columnas"
                  color="#002448"
                >
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columns}
                      showColumn={(data, data1) => {
                        analytics.ShowHide({
                          ubicacion: "Públicos",
                        });
                        handleShowColumnChange(data, data1);
                      }}
                      showView={handleShowView}
                      onSortChange={(positions) => {
                        analytics.Columnas({
                          ubicacion: "Públicos",
                        });
                        handleColumnSortChange(positions);
                      }}
                      vistas={views}
                      setVista={(nombre) => {
                        analytics.accionesVista({
                          ubicacion: "Públicos",
                          accion: "Agregar",
                        });
                        handleAddView(nombre);
                      }}
                      deleteView={(name) => {
                        analytics.accionesVista({
                          ubicacion: "Públicos",
                          accion: "ELiminar",
                        });
                        handleDeleteView(name);
                      }}
                      nameAc={nameView}
                    />
                  </div>
                </Tooltip>
                {/* <FilterColumn
              columns={columns}
              onFilterColumn={handleFilterPerColumn}
            /> */}
                <Tooltip
                  placement="top"
                  title="Filtrar y agrupar"
                  color="#002448"
                >
                  <div className="Filtro2 filtro3">
                    <FilterColumn2
                      columns={columns}
                      data={audiences}
                      onFilterColumn={handleFilterPerColumn}
                      filterInfoCheck={filterInfo}
                      groupedDefault={grouped}
                      // categories={[]}
                      options={options}
                      changeGrouped={changeGroupedOptions}
                    />
                  </div>
                </Tooltip>
              </DataTableUtils>
            </div>
          )}

          {audiencesInit.length > 0 ? (
            <>
              <div className="Kanbang-content">
                <div className="List-category">
                  {groupedName == ""
                    ? vistaPorDefecto()
                    : filteredData.map((ele) => {
                      if (
                        groupedName != "createAt" &&
                        groupedName != "updateAt"
                      ) {
                        if (!nombres.includes(ele[groupedName])) {
                          nombres.push(ele[groupedName]);
                          return <>{vistaAgrupada(ele[groupedName])}</>;
                        }
                      } else {
                        if (!nombres.includes(formatDate(ele[groupedName]))) {
                          nombres.push(formatDate(ele[groupedName]));
                          return <>{vistaAgrupada(ele[groupedName])}</>;
                        }
                      }
                    })}
                </div>
              </div>
            </>
          ) : (
            <div className="form homeSinLogin">
              {/* <div className='form-margin'> */}
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea tu primer público</h1>
                {permissions.create && (
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        props._showSelectAccountModal();
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Publicos",
                      });
                      window.$.fancybox.open({
                        src: "#new-audience-modal",
                        data: "new-audience",
                        //type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => {
                            getAudiences();
                          },
                        },
                      });
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                )}
              </div>
              <div></div>
              {/* </div> */}
            </div>
          )}
        </>
      ) : (
        <h1>No tienes Permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  step: state.userLogout.nextStepCategory,
  isIpv4: state.app.isIPV4,
  clients: state.clients,
  accounts: state.accounts.list,
  audiences: state.audiences.list,
  currentUser: state.app.current_user,
  pwaAudiences: state.pwa.audiences.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _nexStep: (step) => dispatch(nextStep(step)),
  _clearEdit: () => dispatch(clearEdit()),
  _getClients: () => dispatch(getClients()),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _clearAudiences: () => dispatch(clearAudiences()),
  _deleteAudience: (id, usuario, accounts, idAccount, body) =>
    dispatch(deleteAudience(id, usuario, accounts, idAccount, body)),
  _getAudiences: (account) => dispatch(getAudiences(account)),
  _updateAudience: (id, name, account, edit, tags, tagsE, updateAt, active) =>
    dispatch(
      updateAudience(id, name, account, edit, tags, tagsE, updateAt, active)
    ),
  _pwaDeleteAudience: (id) => dispatch(pwaDeleteAudience(id)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => { }
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Content)));
