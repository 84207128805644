import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/campana.actions'

const initialState = {
  step: 1,
  iniDate: "",
  endDate: "",
  country: {},
  saveChanges: false,
  stepLogout: 14,
  stepLogoutCopy: 14,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SAVE_PUBLIC_NAME:
      return {
        ...state,
        public_name: action.public,
      }
    case Actions.SAVE_NAME:
      return {
        ...state,
        name: action.name,
      }
    case Actions.CLEAN_CAMPAIGN2:
      return {}
    case Actions.SAVE_SOCIAL:
      return {
        ...state,
        redSocial: action.redSocial,
      }
    case Actions.SAVE_DATES:
      return {
        ...state,
        iniDate: action.iniDate,
        endDate: action.endDate,
      }
    case Actions.SAVE_OBJETIVE:
      return {
        ...state,
        objetivo: action.objetivo,
      }
    case Actions.SAVE_ACCOUNT_NAME:
      return {
        ...state,
        accountName: action.nameA,
      }
    case Actions.SAVE_TAGS:
      return {
        ...state,
        tags: action.tags,
      }
    case Actions.SAVE_STEP:
      return {
        ...state,
        step: action.step,
      }
    case Actions.SAVE_BUDGET:
      return {
        ...state,
        budget: action.budget,
      }
    case Actions.SAVE_AWSUSER:
      return {
        ...state,
        user: action.user,
      }
    case Actions.SAVE_CATEGORY:
      return {
        ...state,
        category: action.category,
      }
    case Actions.SAVE_COUNTRY:
      return {
        ...state,
        country: action.country,
      }
    case Actions.SAVE_OBSERVATIONS:
      return {
        ...state,
        observations: action.obs,
      }
    case Actions.SAVE_COPIES:
      return {
        ...state,
        copy: action.copy,
      }
    case Actions.CLEAN_CAMPAIGN:
      return {
        step: 1,
      }
    case Actions.SAVE_PRODUCT:
      return {
        ...state,
        id_product: action.product,
      }
    case Actions.SAVE_ID_MEDIUM:
      return {
        ...state,
        id_medium: action.idmedium,
      }
    case Actions.SAVE_MEDIUM:
      return {
        ...state,
        medium: action.medium,
      }
    case Actions.SAVE_ACCOUNT:
      return {
        ...state,
        account: action.account,
      }
    case Actions.SAVE_EMAILNAME:
      return {
        ...state,
        emailName: action.correo,
      }
    case Actions.SAVE_EMAIL:
      return {
        ...state,
        email: action.correo,
      }
    case Actions.NEXT_STEP_LOGOUT:
      return {
        ...state,
        stepLogout: action.stepCamLogOut,
      };
    case Actions.NEXT_STEP_LOGOUT_COPY:
      return {
        ...state,
        stepLogoutCopy: action.stepCamLogOutCopy,
      };
    case Actions.NEXT_STEP_LOGOUT_WITHOUT_ACC:
      return {
        ...state,
        saveChanges: action.saveChanges,
      };
    case Actions.CLEAR_INITIALSTATE:
      return initialState;
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}