import React from "react";

// components
import MetaTags from "components/metatags";
import Content from "components/admin/report";
import UpsertReportModal from "components/admin/report/modal/upsert_report";

const AudienceListPage = () => (
  <>
    {/* <MetaTags title="Reportes" description="Página de reportes" /> */}
    <MetaTags title="Let's Advertise Ads - Reportes" description="Exporta informes automatizados en segundos. Aumenta el tiempo de calidad tomando decisiones, Let's Advertise Ads hace los reportes por ti. Ingresa ahora." />


    <div className="content-all">
      <div id="shadow" className="shadow hide"></div>
      <div className="content-creative ContentReport">
        <Content />
      </div>
    </div>

    <UpsertReportModal />
  </>
);

export default AudienceListPage;
