import React, { useEffect, useState } from "react";
import classnames from "classnames";
import _ from "lodash";
import { newEmailValidation } from "redux/actions/email.actions";
import { v4 } from "uuid";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import status, { arrayPriority } from "config/status";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { getCountries } from "redux/actions/medioCamp.actions";
import CurrencyInput from "react-currency-input-field";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import Select from "libs/select";
import Swal from "sweetalert2";
import DropZone from "libs/dropzone";
import { components } from "react-select";
import DataPicker from "../dataPicker";
import analytics from "../../helpers/analytics";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { saveObjets } from "redux/actions/objetivoCamp.actions";
import { saveBudgets } from "redux/actions/budgetCamp.actions";
import { getBudgets } from "redux/actions/budgets.actions";
import { saveCategory, cleanCampaign } from "redux/actions/campana.actions";
import {
  saveNames,
  saveSteps,
  cleanSteps,
} from "redux/actions/nameCamp.actions";
import { getCategories } from "redux/actions/categories.actions";
import {
  saveSocials,
  saveproduct,
  saveMediumm,
  saveIdmediumm,
  getMediumBanner,
} from "redux/actions/medioCamp.actions";
import {
  msgsuccess,
  getCampaigns,
  createCampaign,
  updateCampaign,
  clearCampaignupd,
  funcampaignupAdd,
  exportCampaignAWS,
  updateCampaignFinalized,
  updateCampaignModal,
  validateCampg,
  validateHoursAvailable,
  getConfigScheduleGlobal,
} from "redux/actions/dashboard.actions";

import { find } from "lodash";
import { withUtils } from "utils";
import { Auth } from "aws-amplify";
import makeAnimated from "react-select/animated";
import MySelect from "../CampOneStep/MySelect";

import * as STORAGE from "helpers/storage";
import { Tooltip } from "antd";

import imgPdf from "../CampOneStep/assets/img/imgPdf.png";
import imgWord from "../CampOneStep/assets/img/imgWord.png";
import imgExcel from "../CampOneStep/assets/img/imgExcel.png";
import imgPp from "../CampOneStep/assets/img/imgPp.png";
import imgTxt from "../CampOneStep/assets/img/imgTxt.png";
import imgRar from "../CampOneStep/assets/img/imgRar.png";
import imgZip from "../CampOneStep/assets/img/imgZip.png";
import imgMp4 from "../CampOneStep/assets/img/imgMp4.png";
import "./assets/css/styles.scss";

const ModalPauta = (props) => {
  var newBudget = 0;

  const [validadorValue, setValidadorValue] = useState("");
  const [showOptions, setShowOptions] = useState({
    showCritical: false,
    showUrgent: false,
  });
  const [messageErrors, setMessageErrors] = useState({
    dates: "",
  });
  const [isActive, setIsActive] = useState(false);
  const [username, setUsername] = useState("");
  const [inputValue, setInputValue] = useState("Normal");
  const [nameForEmail, setNameForEmail] = useState("");
  const [emailForEmail, setEmailForEmail] = useState("");
  const [optionSelected, setOptionSelected] = useState("");
  const [generalBudget, setGeneralBudget] = useState([]);
  const [images, setImages] = useState([]);
  const [account, setAccount] = useState(props.dataEnviada.account);
  const [countries, setCountries] = useState([]);
  const [categoriesSorted, setCategoriesSorted] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [aud, setAud] = useState([]);
  const [digitalAssets, setDigitalAssets] = useState([]);
  const { refresh, setRefresh } = props;
  const [disabled, setDisabled] = useState({
    campos: false,
    name: false,
    dateRange: false,
    categorias: false,
    pais: false,
    medio: false,
    objetivo: false,
    formato: false,
    budgetSelect: false,
    investment: false,
    ov: false,
    observaciones: false,
    copy: false,
    audience: false,
    dropzone: false,
    budget: false,
    priority: false,
    digitalAsset: false,
  });
  const [campaign, setCampaign] = useState([]);
  const [selected, setSelected] = useState(false);
  const [activeSend, setActiveSend] = useState(true);
  const [formato, setFormato] = useState([]);
  const [categories, setCategories] = useState();
  const [accounts, setAccounts] = useState([]);
  const [formats, setFormats] = useState([]);
  const [mediumBanner, setMediumBanner] = useState([]);
  const [errors, setErrors] = useState({
    name: false,
    idMix: false,
    medio: false,
    id_ad_product: false,
    status: false,
    budget: false,
    publico: false,
    publicoE: false,
    priority: false,
    fechaIni: false,
    objetivo: false,
    idBudget: false,
    fechafin: false,
    idCampaign: false,
    formato: false,
    idMixChange: false,
    idMixDetaill: false,
    product: false,
    creativity: false,
    id_sale: false,
    observation: false,
    errorBudgetMessage: "",
    version: false,
    npost: false,
    budgets: false,
    campaigns: false,
    ov: false,
    amount: false,
    digitalAssets: false,
  });

  const [budget, setBudget] = useState([]);
  const [state, setState] = useState({
    editable: false,
    id: "",
    name: "",
    idMix: 0,
    status: 0,
    medio: { id_medium: "", site: "" },
    country: { id_region: "", name: "" },
    budget: 0,
    edit: false,
    publico: [],
    publicoE: [],
    fechaIni: "",
    objetivo: "",
    idBudget: "",
    fechafin: "",
    idCampaign: 0,
    idMixChange: 0,
    idMixDetaill: 0,
    id_sale: "123456",
    copy: "",
    observation: "",
    product: { id_ad_product: 0, banner: "" },
    defaultPublic: [],
    creativity: [],
    account: "",
    version: "default version",
    npost: "default npost",
    budgets: [],
    campaigns: [],
    id_ad_product: 0,
    banner: "",
    idAudience: 0,
    formato: { id_format: "", art: "" },
    priority: 3,
    digitalAsset: { id: "", name: "" },
  });

  let budgets = [];
  let publicos = [];
  const remove = (file) => {
    const newArr = images.filter((img) => img.name !== file.name);
    setImages(newArr);
    setState((prev) => ({
      ...prev,
      creativity: newArr,
    }));
  };

  const handleUpload = async (files) => {
    props._showLoader(true);
    try {
      let imagesArr = state.creativity ? state.creativity : [];
      let arrayAux = [];
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const result = await new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
        });
        const uploadedFile = await STORAGE.uploadFile(
          file.name,
          // result,
          file.type
        );
        const { presignedUrl } = uploadedFile;
        await fetch(presignedUrl, {
          method: "PUT",
          body: file,
        });
        const url = await STORAGE.listFiles(uploadedFile.key);
        let taip;
        if (
          uploadedFile.key.includes("pdf") ||
          uploadedFile.key.includes("xls") ||
          uploadedFile.key.includes("docx") ||
          uploadedFile.key.includes("csv")
        ) {
          taip = "Document";
        } else {
          taip = "Image";
        }
        const newImage = {
          id: v4(),
          name: uploadedFile.key,
          link: url,
          account: account,
          type: taip,
        };
        imagesArr.push(newImage);
        /* let response= await APILA.associateFile({
                id: newImage.id,
                name: newImage.name,
                link: newImage.link,
              }) */
        setState((prev) => ({
          ...prev,
          creativity: imagesArr,
        }));
        if (state.edit) {
          props._validateCampg(
            props.campaignupd,
            { ...state, creativity: imagesArr },
            props.accounts,
            false
          );
        }
        /* getImg(imagesArr) */
        setImages(imagesArr);
        Swal.fire({
          title: "Confirmación",
          text: "Archivo subido con éxito",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log("ERROROROR", error);
    }
    //STORAGE.uploadFile(files)

    props._showLoader(false);
  };
  const handleFileChange = (files) => {
    console.log(files);
  };

  aud?.map((pub) => {
    if (pub.active == true) {
      publicos = [
        ...publicos,
        {
          active: pub.active,
          value: pub.id,
          label: pub.name,
        },
      ];
    }
  });
  let lastOption = {
    value: 0,
    label: "Agregar nuevo publico",
  };
  publicos.push(lastOption);
  const handleAudienceChange = (value) => {
    if (value == null) {
      setState((prev) => ({
        ...prev,
        defaultPublic: [],
        idAudience: 0,
        publico: [],
        publicoE: [],
      }));
    } else {
      if (value.value == 0) {
        window.$.fancybox.open({
          src: "#new-audience-modal",
          opts: {
            modal: true,
            afterClose: function (instance, current) {
              console.info("Regresando a OneStep!");
              getAudiences(account);
            },
          },
        });
      } else {
        const found = aud.find((element) => element.id == value.value);
        setState((prev) => ({
          ...prev,
          defaultPublic: found.tags.data,
          idAudience: found.id,
          publico: found.tags.data,
          publicoE: found.tagsE?.data,
        }));
      }
    }

    setErrors((prev) => ({
      ...prev,
      publico: _.isEmpty(value),
    }));
  };

  const onChangeDigitalAssets = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      digitalAsset: {
        id: value.id || "",
        name: value.name || "",
      },
    }));
  };

  let currentBudget = budget.filter(
    (budget) => budget.value === state.idBudget
  );
  const closeModal = (e) => {
    if (e) e.preventDefault();
    Swal.close();
    props._clearCampaignupd();
    setImages([]);
    setState({
      digitalAsset: { id: "", name: "" },
      id: "",
      name: "",
      priority: 3,
      idMix: 0,
      status: 0,
      medio: { id_medium: "", site: "" },
      budget: "",
      edit: false,
      publico: [],
      publicoE: [],
      defaultPublic: [],
      fechaIni: "",
      objetivo: "",
      idBudget: "",
      fechafin: "",
      copy: "",
      observation: "",
      idCampaign: 0,
      idMixChange: 0,
      idMixDetaill: 0,
      product: { id_ad_product: 0, banner: "" },
      creativity: [],
      version: "default versoin2",
      npost: "default npost2",
      budgets: [],
      idAudience: 0,
      formato: { art: "", id_format: "" },
    });
  };
  useEffect(() => {
    getAudiences(props.currentAccount);
    getData();
  }, [props.currentAccount]);

  function myRound(num, dec) {
    var exp = Math.pow(10, dec || 2);
    return parseInt(num * exp, 10) / exp;
  }

  const validateDisabled = async (id, status) => {
    const response = await API.getBitacoraByidActionAndMix({
      idMixDetail: id,
      action: "estado activo de la pauta",
    });
    const bitacoras = response.data.bitacoras;
    const totalDisabledOptions = _.mapValues(disabled, () => true);
    const productOptions = {
      pais: true,
      formato: true,
      medio: true,
      objetivo: true,
    };

    const statusConfigurations = {
      12: totalDisabledOptions,
      13: {
        ...totalDisabledOptions,
        investment: false,
      },
      9: {
        ...totalDisabledOptions,
        investment: false,
      },
      14: bitacoras.length > 0 ? productOptions : disabled,
      16: bitacoras.length > 0 ? productOptions : disabled,
      15: bitacoras.length > 0 ? productOptions : disabled,
      10: productOptions,
      17: totalDisabledOptions,
      11: bitacoras.length > 0 ? productOptions : disabled,
      18: bitacoras.length > 0 ? productOptions : disabled,
    };

    if (props.isSupAdmin) return false;
    if (statusConfigurations.hasOwnProperty(status)) {
      setDisabled((prev) => ({ ...prev, ...statusConfigurations[status] }));
      if (status === 12 || status === 9 || status === 17) {
        setActiveSend(false);
      }
    }
    return bitacoras.length > 0;
  };

  //Valida los casos por cada estado
  const checkStatusDisabled = async (id, satus) => {};

  const handleObservationChange = (e, editor) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (state.edit) {
      props._validateCampg(
        props.campaignupd,
        { ...state, [name]: value },
        props.accounts,
        false
      );
    }
  };
  const handleCopyChange = (e, editor) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (state.edit) {
      props._validateCampg(
        props.campaignupd,
        { ...state, [name]: value },
        props.accounts,
        false
      );
    }
  };

  const areValidInputs = () => {
    let budgetParsed = {};
    budgetParsed = generalBudget.map((item) => {
      return {
        ...budgetParsed,
        account: item.account,
        active: item.active,
        amount: item.amount,
        available: parseFloat(item.available),
        createAt: item.createAt,
        id: item.id,
        status: item.status,
        tags: item.tags,
        title: item.title,
        updateAt: item.updateAt,
      };
    });

    let errorBudgetMessage = "";
    let errorBudget = false;
    const value = state.budget;
    if (state.idBudget !== "") {
      setState((prev) => ({
        ...prev,
        budget: value,
      }));

      let availableAmount = 0;

      let aux;

      if (props.campaignupd.budget > 0) {
        if (props.campaignupd.idBudget === state.idBudget) {
          availableAmount =
            Number(
              budgetParsed.find((ele) => ele.id === state.idBudget).available
            ) + Number(props.campaignupd.budget);
        } else {
          availableAmount = Number(
            budgetParsed.find((ele) => ele.id === state.idBudget).available
          );
        }
        let nuevoBudgetOp = availableAmount - Number(value);
        aux = value;
        newBudget = nuevoBudgetOp;
      } else {
        //aca creo
        availableAmount = Number(
          budgetParsed.find((ele) => ele.id === state.idBudget).available
        );
        let operacion = availableAmount - Number(value);

        if (operacion >= 0) {
          newBudget = operacion;
        } else {
          aux = value;
        }
      }

      setValidadorValue(value);
      //fin de tarea de edición de presupuesto

      if (aux > availableAmount /* || value > availableAmount*/) {
        errorBudget = true;
        errorBudgetMessage =
          "Monto incorrecto, el presupuesto ingresado es mayor al disponible. ";
      }
      if (
        new Number(aux) <
        new Number(props.campaignupd.total_gastado_con_comision)
      ) {
        errorBudget = true;
        errorBudgetMessage =
          " Monto incorrecto, el presupuesto ingresado es menor al consumido. ";
      }
      if (aux < 0) {
        errorBudget = true;
        errorBudgetMessage = "Presupuesto no válido ";
      }

      if (state.status === 13) {
        if (Number(aux) > props.campaignupd.total_gastado_con_comision) {
          errorBudget = true;
          errorBudgetMessage =
            "Monto incorrecto, el presupuesto ingresado es mayor al presupuesto consumido con comisión";
        }
        if (Number(aux) < props.campaignupd.total_gastado_con_comision) {
          errorBudget = true;
          errorBudgetMessage =
            "Monto incorrecto, el presupuesto ingresado es menor al presupuesto consumido con comisión";
        }
      }
    }

    const dateInvalid = validateDates();

    if (state.budget === "" || !state.budget) {
      errorBudget = true;
      errorBudgetMessage = "Debe ingresar un monto";
    }
    props.utils.log("areValidInputs aux errorBudget", errorBudget);
    let mErrors = {
      budget: errorBudget,
      name: state.name === "",
      site: state.medio.site === "",
      idBudget: state.idBudget === "",
      // fechaIni: state.fechaIni === "",
      // fechafin: state.fechafin === "",
      fechaIni: dateInvalid.fechaIni,
      datesError: dateInvalid.datesError,
      fechafin: dateInvalid.fechafin,
      id_ad_product: state.product.id_ad_product === "",
      medio: state.medio.id_medium === "",
      // product: _.isEmpty(state.product.id_ad_product),
      id_ad_product: state.product.banner === "",
      publico:
        currentAudience.find((ele) => ele.value === state.idAudience)?.label ===
        "Agregar nuevo publico",
      errorBudgetMessage,
      ov: state.id_sale === "",
      formato: state.formato.art === "" ? true : false,
      amount: state.budget == "",
    };
    props.utils.log("mErrors", mErrors);
    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));

    return !_.some(mErrors, (e) => e);
  };

  const validateDates = () => {
    let dataError = {
      fechaIni:
        state.fechaIni === "" ||
        !isDateGreaterThanToday(convertirFecha(state.fechaIni)),
      datesError: !isDateGreaterThanToday(convertirFecha(state.fechaIni)),
      fechafin: state.fechafin === "",
    };
    if (state.edit) {
      dataError = {
        fechaIni: state.fechaIni === "",
        datesError: false,
        fechafin: state.fechafin === "",
      };
    } else {
      return dataError;
    }
    return dataError;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function numberWithCommas1(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const getStatusLabel = (mStatus) => {
    switch (mStatus) {
      case status.CREADO.id:
        return status.CREADO.label;

      case status.INACTIVO.id:
        return status.INACTIVO.label;

      case status.ELIMINADO.id:
        return status.ELIMINADO.label;

      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.label;

      case status.EN_REVISION.id:
        return status.EN_REVISION.label;

      case status.APROBADO.id:
        return status.APROBADO.label;

      case status.MODIFICADO.id:
        return status.MODIFICADO.label;

      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.label;
      case status.ACTIVO.id:
        return status.ACTIVO.label;

      case status.PENDIENTE.id:
        return status.PENDIENTE.label;

      case status.FINALIZADO.id:
        return status.FINALIZADO.label;

      case status.FINALIZADO_SIN_META.id:
        return status.FINALIZADO_SIN_META.label;

      case status.PAUSADO.id:
        return status.PAUSADO.label;

      case status.RECHAZADO.id:
        return status.RECHAZADO.label;

      case status.NO_IMPLEMENTADO.id:
        return status.NO_IMPLEMENTADO.label;

      case status.FINALIZADO_SIN_REPORTE.id:
        return status.FINALIZADO_SIN_REPORTE.label;
      default:
        return "Totales";
    }
  };

  const clean = (element, action) => {
    setState({
      digitalAsset: { id: "", name: "" },
      editable: false,
      id: "",
      name: "",
      idMix: 0,
      status: 0,
      medio: { id_medium: "", site: "" },
      country: { id_region: "", name: "" },
      budget: 0,
      edit: false,
      publico: [],
      publicoE: [],
      fechaIni: "",
      objetivo: "",
      idBudget: "",
      fechafin: "",
      idCampaign: 0,
      idMixChange: 0,
      idMixDetaill: 0,
      id_sale: "",
      copy: "",
      observation: "",
      product: { id_ad_product: 0, banner: "" },
      defaultPublic: [],
      creativity: [],
      account: "",
      version: "default version",
      npost: "default npost",
      budgets: [],
      campaigns: [],
      id_ad_product: 0,
      banner: "",
      idAudience: 0,
      formato: { art: "", id_format: "" },
      priority: 3,
    });
    /* setIsLoading(false) */
    /*  props._msgsuccess() */
    /*  setTimeout(() => {
           setRefresh(!refresh)
         }, 3000); */
    setTimeout(() => {
      props._cleanSteps();
      setRefresh();
      closeModal();
      props.setShowModal(false);
    }, 1000);
  };

  const getAudiences = async (acc) => {
    /* props._showLoader(true) */
    let audiences = await APILA.getAudiences(acc);
    setAud(audiences.data.audiences);
    /* props._showLoader(false) */
  };
  const onClickTest = () => {
    getAudiences(account);
  };
  let currentAudience = publicos.filter(
    (pub) => pub.value === state.idAudience
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleMediumTypeChange = async (event, value) => {
    if (value) {
      let formats = await API.getFormatData({
        id_medium: state.medio.id_medium,
        id_banner: value.id_banner,
        id_region: state.country.id_region,
      });
      setFormats(formats.data.data);
      setState((prev) => ({
        ...prev,
        product: { id_ad_product: value.id_ad_product, banner: value.banner },
      }));
      if (state.edit) {
        props._validateCampg(
          props.campaignupd,
          {
            ...state,
            product: {
              id_ad_product: value.id_ad_product,
              banner: value.banner,
            },
          },
          props.accounts,
          false
        );
      }
    }
  };

  const getFormatBudgets = async () => {
    const getBudgets = await APILA.getBudgets(props.currentAccount);
    let datosBudgets = getBudgets.data.budgets;
    setGeneralBudget(datosBudgets);
    let budgetsFormat = [];
    datosBudgets?.forEach((el) => {
      let avaAux = myRound(Number(el.available));
      let amountAux = Number(el.amount).toFixed(2);
      avaAux = numberWithCommas(avaAux);
      amountAux = numberWithCommas(amountAux);
      if (el.active) {
        budgetsFormat.push({
          value: el.id,
          label: `${el.title}: $${amountAux} (Disponible: $${avaAux})`,
          isDisabled: el.budgetOld ? true : false,
          date: new Date(el.createAt),
        });
      }
    });

    budgetsFormat = _.orderBy(budgetsFormat, ["isDisabled"], ["asc"]).sort(
      (a, b) => b.date - a.date
    );
    setBudget(budgetsFormat);
    return budgetsFormat;
  };

  const getData = async () => {
    props._showLoader(true);
    try {
      const querys = [
        APILA.getAccounts(props.currentUser),
        APILA.getCategories(props.currentAccount),
        getFormatBudgets(),
        API.getContryes(),
        Auth.currentAuthenticatedUser(),
        API.getAssetsByClient({
          account: props.currentAccount,
        }),
      ];
      const [
        accounts,
        getCategories,
        getBudgets,
        countries,
        responseAuth,
        digitalAssetsInfo,
      ] = await Promise.all(querys);
      const filterDigitalAsset = digitalAssetsInfo?.data?.assets?.assets.filter(
        (item) => item.id_ad_status === 1
      );
      setDigitalAssets(filterDigitalAsset || []);
      let datosAccounts = accounts.data.accounts;
      setAccounts(datosAccounts);
      setCountries(countries.data.pautas);
      let datos = getCategories.data.categories;
      let datosFilter = datos?.filter((item) => item.active);
      let account = datosAccounts?.find(
        (ele) => ele.id === props.currentAccount
      ); // Current account
      let countCritical = account.priorityRecord.countCritical;
      let countUrgent = account.priorityRecord.countUrgent;
      let availableCritical = account.priorityRecord.availableCritical;
      let availableUrgent = account.priorityRecord.availableUrgent;
      if (countCritical == availableCritical) {
        // Estado que muestra la opcion de crear pauta critica
        setShowOptions((prev) => ({
          ...prev,
          showCritical: true,
        }));
      }
      if (countUrgent == availableUrgent) {
        // Estado que muestra la opcion de crear pauta urgente
        setShowOptions((prev) => ({
          ...prev,
          showUrgent: true,
        }));
      }
      setCategories(datosFilter);
      let activeCategories = datos?.filter((item) => item.active == true);
      let activeCategoriesPadre = activeCategories?.filter(
        (item) => item.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      );
      let catsSorted = _.orderBy(
        activeCategoriesPadre,
        ["name"],
        ["asc", "desc"]
      );

      setCategoriesSorted(catsSorted);
      let token = responseAuth.signInUserSession.accessToken.jwtToken;
      let userNameResponse = responseAuth.username;
      setUsername(userNameResponse);
      const responseProfileInfo = await APILA.getProfileInfo(token);
      const attributes = responseProfileInfo.data.data.UserAttributes;
      let nameFind = find(attributes, { Name: "name" });
      let emailFind = find(attributes, { Name: "email" });
      if (nameFind) setNameForEmail(nameFind.Value);
      if (emailFind) setEmailForEmail(emailFind.Value);
    } catch (error) {
      console.log("Error al obtener datos", error);
    } finally {
      props._showLoader(false);
    }
  };

  const obtenerCategorias = async () => {
    props._showLoader(true);

    let username = await Auth.currentAuthenticatedUser();

    let accounts = await APILA.getAccounts(username.username);
    let datosAccounts = accounts.data.accounts;
    setAccounts(datosAccounts);
    let getCategories = await APILA.getCategories(props.dataEnviada.account);
    let datos = getCategories.data.categories;
    let filterDatos = datos?.filter((item) => item.active);
    setCategories(filterDatos);
    let activeCategories = datos?.filter((item) => item.active === true);
    let activeCategoriesPadre = activeCategories?.filter(
      (item) => item.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341"
    );
    let catsSorted = _.orderBy(
      activeCategoriesPadre,
      ["name"],
      ["asc", "desc"]
    );
    setCategoriesSorted(catsSorted);
    props._showLoader(false);
  };

  useEffect(() => {
    obtenerCategorias();
    if (!_.isEmpty(props.campaignupd)) {
      let response = [
        API.getBitacoraByidActionAndMix({
          idMixDetail: props.campaignupd.id,
          action: "estado activo de la pauta",
        }),
      ];
      Promise.all(response).then((dataResponse) => {
        let disabledOptionsGeneral = false;
        if (dataResponse[0].data.bitacoras.length > 0) {
          disabledOptionsGeneral = true;
          setIsActive(true);
        }
        if (!props.isSupAdmin) {
          if (props.campaignupd.status === 12) {
            //finalizado
            // setActiveSend(false);
            setDisabled((prev) => ({
              ...prev,
              campos: true,
              budget: true,
              audience: true,
              budgetSelect: true,
              // categorias: true,
              copy: true,
              dateRange: true,
              dropzone: true,
              formato: true,
              investment: true,
              medio: true,
              name: true,
              objetivo: true,
              observaciones: true,
              ov: true,
              pais: true,
              priority: true,
              digitalAsset: true,
            }));
          }
          if (props.campaignupd.status === 13) {
            //finalizado sin meta
            setDisabled((prev) => ({
              ...prev,
              campos: true,
              budget: true,
              audience: true,
              budgetSelect: true,
              // categorias: true,
              copy: true,
              dateRange: true,
              dropzone: true,
              formato: true,
              investment: false,
              medio: true,
              name: true,
              objetivo: true,
              observaciones: true,
              ov: true,
              pais: true,
              priority: true,
              digitalAsset: true,
            }));
          }
          if (props.campaignupd.status === 9) {
            //en implementacion
            setActiveSend(false);
            setDisabled((prev) => ({
              ...prev,
              campos: true,
              budget: true,
              audience: true,
              budgetSelect: true,
              categorias: true,
              copy: true,
              dateRange: true,
              dropzone: true,
              formato: true,
              investment: false,
              medio: true,
              name: true,
              objetivo: true,
              observaciones: true,
              ov: true,
              pais: true,
              priority: true,
              digitalAsset: true,
            }));
            setTimeout(() => {
              Swal.fire({
                title: "En implementación!",
                text: "Tu campaña se esta implementando por lo que no puedes modificarla.",
                icon: "info",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              });
            }, 1000);
          }
          if (props.campaignupd.status === 14) {
            //pausado por lets
            if (disabledOptionsGeneral) {
              setDisabled((prev) => ({
                ...prev,
                pais: true,
                formato: true,
                medio: true,
                objetivo: true,
              }));
            }
          }
          if (props.campaignupd.status === 16) {
            //no implementado
            if (disabledOptionsGeneral) {
              setDisabled((prev) => ({
                ...prev,
                pais: true,
                formato: true,
                medio: true,
                objetivo: true,
              }));
            }
          }
          if (props.campaignupd.status === 15) {
            //rechazado
            if (disabledOptionsGeneral) {
              setDisabled((prev) => ({
                ...prev,
                pais: true,
                formato: true,
                medio: true,
                objetivo: true,
              }));
            }
          }

          if (props.campaignupd.status === 10) {
            setDisabled((prev) => ({
              ...prev,
              pais: true,
              medio: true,
              objetivo: true,
              formato: true,
            }));
          }
          if (props.campaignupd.status === 17) {
            //finalizado sin reporte
            setDisabled((prev) => {
              let valores = prev;
              let obtActualizado = _.mapValues(valores, function (valor) {
                return true;
              });
              return obtActualizado;
            });
            setActiveSend(false);
          }
          if (props.campaignupd.status === 11) {
            //pendiente
            if (disabledOptionsGeneral) {
              setDisabled((prev) => ({
                ...prev,
                pais: true,
                formato: true,
                medio: true,
                objetivo: true,
              }));
            }
          }
          if (props.campaignupd.status === 18) {
            if (disabledOptionsGeneral) {
              setDisabled((prev) => ({
                ...prev,
                pais: true,
                formato: true,
                medio: true,
                objetivo: true,
              }));
            }
          }
        }
      });

      let countrytemp = { id_region: 3, name: "Guatemala" };
      if (
        props.campaignupd.id_region != "" &&
        props.campaignupd.id_region != undefined &&
        props.campaignupd.id_region > 0
      ) {
        countrytemp = {
          id_region: props.campaignupd.id_region,
          name: props.campaignupd.name_region,
        };
      }
      let seg = [];
      if (props.campaignupd.segmentation) {
        seg = props.campaignupd.segmentation.split(",");
      }
      let newState = {
        edit: props.campaignupd.edit == false ? false : true,
        id: props.campaignupd.id,
        name: props.campaignupd.name,
        idMix: props.campaignupd.idMix,
        status: props.campaignupd.status,
        medio: {
          id_medium: props.campaignupd.id_medium || 0,
          site: props.campaignupd.medioo
            ? props.campaignupd.medioo
            : props.campaignupd.medio || 0,
        },
        editable: props.campaignupd.editable
          ? props.campaignupd.editable
          : true,
        country: countrytemp,

        budget: props.campaignupd.budget,
        publico:
          props.campaignupd.segmentation != ""
            ? seg
            : props.campaignupd.publico == null ||
              props.campaignupd.publico == undefined ||
              props.campaignupd.publico == "null"
            ? []
            : props.campaignupd.publico != ""
            ? props.campaignupd.publico
            : [],
        publicoE:
          props.campaignupd.publicoE == null ||
          props.campaignupd.publicoE == undefined ||
          props.campaignupd.publicoE == "null"
            ? []
            : props.campaignupd.publicoE,
        defaultPublic: props.campaignupd.segmentation
          ? seg
          : props.campaignupd.publico == null ||
            props.campaignupd.publico == undefined ||
            props.campaignupd.publico == "null"
          ? []
          : props.campaignupd.publico,
        fechaIni: props.campaignupd.fechaIni,
        objetivo: props.campaignupd.objetivo,
        idBudget: props.campaignupd.idBudget,
        fechafin: props.campaignupd.fechafin,
        idCampaign: props.campaignupd.idCampaign,
        idMixChange: props.campaignupd.idMixChange,
        idMixDetaill: props.campaignupd.idMixDetaill,
        id_sale: props.campaignupd.orden_venta
          ? props.campaignupd.orden_venta
          : props.campaignupd.id_sale,
        copy: props.campaignupd.copy || "",
        observation: props.campaignupd.observation || "",
        product: {
          id_ad_product: props.campaignupd.id_ad_product,
          banner: props.campaignupd.banner,
        },
        creativity: props.campaignupd.creativity,

        account: props.campaignupd.account,
        //nameForEmail: nameForEmail,
        version: "",
        npost: "",
        budgets: props.campaignupd.budgets,
        campaigns: props.campaignupd.campaigns,
        formato: props.campaignupd.formato
          ? {
              art: props.campaignupd.formato.art
                ? props.campaignupd.formato.art
                : props.campaignupd.formato,
              id_format: props.campaignupd.formato.id_format
                ? props.campaignupd.formato.id_format
                : "",
            }
          : "",
        priority: props.campaignupd.priority,
        digitalAsset: {
          id: props.campaignupd?.id_digital_asset || "",
          name: props.campaignupd?.name_digital_asset || "",
        },
      };

      if (props.campaignupd.creativity) {
        setImages(props.campaignupd.creativity);
      }
      if (props.campaignupd.idAudience) {
        const found = props.audiences.find(
          (element) => element.id == props.campaignupd.idAudience
        );

        if (found) {
          newState = {
            ...newState,
            publico: found.tags.data,
            publicoE: found.tagsE.data,
            idAudience: props.campaignupd.idAudience,
          };
        } else {
          newState = {
            ...newState,
            publico:
              props.campaignupd.publico == null ||
              props.campaignupd.publico == undefined ||
              props.campaignupd.publico == "null"
                ? []
                : props.campaignupd.publico,
            publicoE:
              props.campaignupd.publicoE == null ||
              props.campaignupd.publicoE == undefined ||
              props.campaignupd.publicoE == "null"
                ? []
                : props.campaignupd.publicoE,
            /* defaultPublic:props.campaignupd.publico==null||props.campaignupd.publico==undefined||props.campaignupd.publico=="null"?[]:props.campaignupd.publico, */
          };
        }
      }

      setValidadorValue(props.campaignupd.budget);
      setState((prev) => ({
        ...prev,
        ...newState,
      }));
      if (props.campaignupd.categoriesSelected) {
        setOptionSelected(props.campaignupd.categoriesSelected);
      } else {
        setOptionSelected("");
      }
    } else {
      setState({
        digitalAsset: { id: "", name: "" },
        id: "",
        name: "",
        idMix: 0,
        status: 0,
        amount: 0,
        medio: { id_medium: "", site: "" },
        country: { id_region: "", name: "" },
        budget: 0,
        edit: false,
        publico: [],
        publicoE: [],
        defaultPublic: [],
        fechaIni: "",
        objetivo: "",
        idBudget: "",
        fechafin: "",
        idCampaign: 0,
        idMixChange: 0,
        idMixDetaill: 0,
        id_sale: "123456",
        copy: "",
        observation: "",
        product: { id_ad_product: 0, banner: "" },
        creativity: [],
        account: "",
        version: "",
        npost: "",
        budgets: [],
        campaigns: [],
        idAudience: 0,
        formato: { art: "", id_format: "" },
        priority: 3,
      });
      setOptionSelected("");
    }
    setErrors({
      name: false,
      idMix: false,
      medio: false,
      status: false,
      budget: false,
      publico: false,
      publicoE: false,
      fechaIni: false,
      objetivo: false,
      idBudget: false,
      fechafin: false,
      idCampaign: false,
      idMixChange: false,
      formato: false,
      idMixDetaill: false,
      product: false,
      creativity: false,
      id_sale: false,
      observation: false,
      errorBudgetMessage: "",
      version: false,
      npost: false,
      budgets: false,
      campaigns: false,
      digitalAssets: false,
    });
  }, [props.campaignupd]);

  const optionsToShow = arrayPriority.filter((item) => item.show);
  const defaultState = arrayPriority.find((item) => item.id === state.priority);
  const valuePriority = arrayPriority.find((item) => {
    if (
      Object.keys(props.campaignupd).length > 0 &&
      props.campaignupd.status === 5 &&
      state.priority === 0
    ) {
      return item.id === 0;
    } else {
      return item.id === state.priority;
    }
  });
  const defaultOption = arrayPriority.find((item) => item.id === 0);
  const changePriority = arrayPriority.find(
    (item) => item.id === state.priority
  );

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const handleSelect = async (e) => {
    let value = e.target.value;
    setSelected(true);
    // setInputValue()
    if (state.edit === false || state.status === 1) {
      let dataResponse = await props._validateHoursAvailable(
        props.currentAccount,
        props.accounts,
        state.fechaIni,
        Number(value),
        true
      );
      setErrors((prev) => ({
        ...prev,
        priority: dataResponse,
      }));
    } else {
      props._validateCampg(
        props.campaignupd,
        { ...state, priority: Number(value) },
        props.accounts,
        false
      );
    }

    setState((prev) => ({
      ...prev,
      priority: Number(value),
    }));
  };

  const handleMediumChange = (event, value) => {
    if (value) {
      let bod = {
        id_medium: value.id_medium,
        id_region: state.country.id_region,
      };
      setState((prev) => ({
        ...prev,
        medio: {
          id_medium: value.id_medium,
          site: value.site,
        },
      }));
      if (state.edit) {
        props._validateCampg(
          props.campaignupd,
          { ...state, medio: { id_medium: value.id_medium, site: value.site } },
          props.accounts,
          false
        );
      }
      medio(bod, "formato");
    }
  };
  const handleBudgetSelectChange = (option) => {
    setState((prev) => ({
      ...prev,
      idBudget: option.value,
    }));

    if (state.edit) {
      props._validateCampg(
        props.campaignupd,
        { ...state, idBudget: option.value },
        props.accounts,
        false
      );
    }

    let found = generalBudget.filter((el) => el.id === option.value);
    if (found.length > 0) {
      if (found[0].available < state.budget) {
        let errorBudget = true;
        let errorBudgetMessage =
          "Monto no disponible, el presupuesto seleccionado es menor al invertido ";
        setErrors((prev) => ({
          ...prev,
          idBudget: _.isEmpty(option.value),
          budget: errorBudget,
          errorBudgetMessage,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          idBudget: _.isEmpty(option.value),
          budget: false,
          errorBudgetMessage: "",
        }));
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        idBudget: _.isEmpty(option.value),
        budget: false,
        errorBudgetMessage: "",
      }));
    }
  };

  const handleChange = (selected, id, padre) => {
    try {
      _.isEmpty(selected);
      if (_.isEmpty(optionSelected)) {
        let selectedAux = [];
        if (selected.length > 0) {
          selected.forEach((el) => {
            selectedAux.push({
              label: el.label,
              padre: el.padre,
              nombrePadre: padre.name,
              value: el.value,
            });
          });
        } else {
          selectedAux = [
            {
              label:
                selected[selected.length != 0 ? selected.length - 1 : 0].label,
              padre:
                selected[selected.length != 0 ? selected.length - 1 : 0].padre,
              nombrePadre: padre.name,
              value:
                selected[selected.length != 0 ? selected.length - 1 : 0].value,
            },
          ];
        }

        setOptionSelected(selectedAux);
      } else {
        let testArray;
        if (selected == null) {
          let auxArray = optionSelected.filter((el) => {
            return el.padre != id;
          });
          testArray = auxArray;
        } else {
          let selectedAux = [];
          if (selected.length > 0) {
            selected.forEach((el) => {
              selectedAux.push({
                label: el.label,
                padre: el.padre,
                nombrePadre: padre.name,
                value: el.value,
              });
            });
          } else {
            selectedAux = [
              {
                label:
                  selected[selected.length != 0 ? selected.length - 1 : 0]
                    .label,
                padre:
                  selected[selected.length != 0 ? selected.length - 1 : 0]
                    .padre,
                nombrePadre: padre.name,
                value:
                  selected[selected.length != 0 ? selected.length - 1 : 0]
                    .value,
              },
            ];
          }

          let auxArray = optionSelected.filter((el) => {
            return el.padre != id;
          });
          auxArray.forEach((el) => {
            selectedAux.push(el);
          });
          testArray = selectedAux;
        }
        setOptionSelected(testArray);
      }
    } catch {}
  };

  //Dame una function la cual detecte si una fecha es igual o mayor a la actual, unicamente valida el dia
  function isDateGreaterThanToday(fecha) {
    const fechaActual = new Date();
    const fechaComparar = new Date(fecha);

    // Establecer las horas, minutos, segundos y milisegundos a cero
    fechaActual.setHours(0, 0, 0, 0);
    fechaComparar.setHours(0, 0, 0, 0);

    return fechaComparar >= fechaActual;
  }

  //Funcion que convierte la fecha seleccionada a un formato de fecha aceptado por la API
  function convertirFecha(fechaString) {
    const partes = fechaString.split(".");
    return new Date(partes[2], partes[1] - 1, partes[0]);
  }
  const handleDateChange = async (startDate, endDate) => {
    if (!state.edit) {
      //valida que startDate sea mayor o igual a la fecha actual de lo contrario retorna un log
      if (!isDateGreaterThanToday(convertirFecha(startDate))) {
        setErrors((prev) => ({
          ...prev,
          fechaIni: true,
        }));
        setMessageErrors((prev) => ({
          ...prev,
          dates: "La fecha de inicio no puede ser menor a la fecha actual",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          fechaIni: false,
        }));
        setMessageErrors((prev) => ({
          ...prev,
          dates: "",
        }));
      }
    }
    if (state.edit === false || state.status === 1) {
      const dataResponse = await props._validateHoursAvailable(
        props.currentAccount,
        props.accounts,
        startDate,
        state.priority,
        true
      );
      setErrors((prev) => ({
        ...prev,
        priority: dataResponse,
      }));
    } else {
      props._validateCampg(
        props.campaignupd,
        { ...state, fechaIni: startDate, fechafin: endDate },
        props.accounts,
        false
      );
    }
    // await calculateHoursAvailable(startDate);
    setState((prev) => ({
      ...prev,
      fechaIni: startDate,
      fechafin: endDate,
    }));
    return;
  };

  const getCurrentListCategory = (id) => {
    let currentListOption = [];

    categories.forEach((element) => {
      if (element.padre == id) {
        let item = {
          value: element.id,
          label: element.name,
          padre: id,
        };
        currentListOption.push(item);
      }
    });

    return currentListOption;
  };
  const handleCountryChange = (event, value) => {
    if (value) {
      let bod = {
        id_region: value.id_region,
        bandera: 1,
      };
      setState((prev) => ({
        ...prev,
        country: {
          id_region: value.id_region,
          name: value.name,
        },
      }));

      setState((prev) => ({
        ...prev,
        medio: {
          id_medium: "",
          site: "",
        },
      }));

      setState((prev) => ({
        ...prev,
        product: { id_ad_product: "", banner: "" },
      }));
      setState((prev) => ({
        ...prev,
        formato: {
          art: "",
          id_format: "",
        },
      }));
      if (state.edit) {
        props._validateCampg(
          props.campaignupd,
          {
            ...state,
            country: { id_region: value.id_region, name: value.name },
            medio: { id_medium: "", site: "" },
            product: { id_ad_product: "", banner: "" },
            formato: { art: "", id_format: "" },
          },
          props.accounts,
          false
        );
      }
      medio(bod, "medioPau");
    }
  };
  const medio = async (bod, caso) => {
    let medio = await API.getMediumBanner(bod);

    switch (caso) {
      case "medioPau":
        setMediumBanner(medio.data.data);
        break;

      case "formato":
        setFormato(medio.data.data);
        break;
    }
  };
  const getCategoryOptionSelected = (id) => {
    let currentListOption = [];
    if (optionSelected) {
      optionSelected.forEach((element) => {
        if (element.padre == id) {
          currentListOption.push(element);
        }
      });
    }

    return currentListOption;
  };
  const handleChangeFormat = (evemt, value) => {
    if (value) {
      setState((prev) => ({
        ...prev,
        formato: { id_format: value.id_format, art: value.art },
        product: {
          id_ad_product: value.id_ad_product,
          banner: state.product.banner,
        },
      }));
      if (state.edit) {
        props._validateCampg(
          props.campaignupd,
          {
            ...state,
            formato: { id_format: value.id_format, art: value.art },
            product: {
              id_ad_product: value.id_ad_product,
              banner: state.product.banner,
            },
          },
          props.accounts,
          false
        );
      }
    }
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const animatedComponents = makeAnimated();

  const sendCampaign = async () => {
    setIsLoading(true);
    setSelected(false);

    if (state.name === "") {
      var cname = document.getElementById("cname");
      cname.scrollIntoView();
    } else if (state.fechaIni === "") {
      var cfecha = document.getElementById("cfecha");
      cfecha.scrollIntoView();
    } else if (state.country.name === "") {
      var cpais = document.getElementById("cpais");
      cpais.scrollIntoView();
    } else if (state.medio.site === "") {
      var cmedio = document.getElementById("cmedio");
      cmedio.scrollIntoView();
    } else if (state.product.id_ad_product === "") {
      var cformato = document.getElementById("cformato");
      cformato.scrollIntoView();
    } else if (state.idBudget === "") {
      var cpresupuesto = document.getElementById("cpresupuesto");
      cpresupuesto.scrollIntoView();
    } else if (state.budget === 0) {
      var cinversion = document.getElementById("cinversion");
      cinversion.scrollIntoView();
    }

    console.log("areValidInputs()", areValidInputs());
    if (!areValidInputs()) {
      setIsLoading(false);
      return;
    }
    props._showLoader(true);

    const querys = [
      APILA.getDefaultEtiqueta(),
      APILA.getEtiquetasByAccount({
        account: props.currentAccount,
      }),
      API.getBudgetsById({ id: state.idBudget }),
    ];

    const [responseDefault, etiquetasAccount, responseBudget] =
      await Promise.all(querys);

    if (props.campaignupd.idBudget === state.idBudget) {
      if (responseBudget.data.data && responseBudget.data.data.length > 0) {
        setIsLoading(false);
        return Swal.fire({
          title: "Ups!",
          text: "Tu campaña cuenta con un presupuesto antiguo, debes cuadrarlo antes.",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    }

    // setIsLoading(false);
    let vnpost = "";
    let vversion = "";

    props._showLoader(true);

    const mAccount = _.find(accounts, { id: account });

    let defaultCategorie = responseDefault.data.defaultEtiqueta[0];
    let etiquetas = etiquetasAccount.data.etiquetas;
    let chosenTag;
    let etiquetaFound = false;

    etiquetas.forEach((el) => {
      if (el.state === 1) {
        chosenTag = el;
        etiquetaFound = true;
      }
    });

    if (etiquetaFound === false) {
      chosenTag = defaultCategorie;
    }

    let finalEtiqueta = "";
    chosenTag.columnsSelected.forEach((el) => {
      if (el.includes("categorie:")) {
        let value = el.split(":");
        let found = categories.filter((eleCat) => eleCat.name === value[1]);
        if (found.length > 0) {
          if (optionSelected.length > 0) {
            let foundOptionSel = optionSelected.filter(
              (eleOption) => eleOption.padre === found[0].id
            );
            if (foundOptionSel.length > 0) {
              foundOptionSel.forEach((eleCat) => {
                finalEtiqueta = finalEtiqueta + eleCat.label + "_";
              });
            }
          }
        }
      } else if (el === "client") {
        finalEtiqueta = finalEtiqueta + mAccount.name + "_";
      } else if (el === "publico") {
        if (state.idAudience != undefined || state.idAudience != "") {
          let audienceFound = aud.filter(
            (eleAud) => eleAud.id === state.idAudience
          );
          if (audienceFound.length > 0) {
            finalEtiqueta = finalEtiqueta + audienceFound[0].name + "_";
          }
        }
      } else if (el === "medio") {
        finalEtiqueta = finalEtiqueta + state.medio.site + "_";
      } else if (el === "banner") {
        if (state.product.banner) {
          finalEtiqueta = finalEtiqueta + state.product.banner + "_";
        } else {
          finalEtiqueta = finalEtiqueta + state.product + "_";
        }
      } else if (el === "fechaIni") {
        let fechaBien = state.fechaIni.split(".");
        finalEtiqueta =
          finalEtiqueta +
          fechaBien[0] +
          "/" +
          fechaBien[1] +
          "/" +
          fechaBien[2] +
          "_";
      } else if (el === "fechafin") {
        let fechaBien = state.fechafin.split(".");
        finalEtiqueta =
          finalEtiqueta +
          fechaBien[0] +
          "/" +
          fechaBien[1] +
          "/" +
          fechaBien[2] +
          "_";
      } else if (el === "country") {
        if (state.country.name) {
          finalEtiqueta = finalEtiqueta + state.country.name + "_";
        } else {
          finalEtiqueta = finalEtiqueta + state.country + "_";
        }
      } else if (el === "status") {
        finalEtiqueta = finalEtiqueta + getStatusLabel(state.status) + "_";
      } else if (el === "budget") {
        finalEtiqueta =
          finalEtiqueta + "$" + numberWithCommas1(state.budget) + "_";
      } else if (el === "swc") {
        finalEtiqueta =
          finalEtiqueta + "$" + numberWithCommas1(props.campaignupd.swc) + "_";
      } else {
        if (state[el]) {
          finalEtiqueta = finalEtiqueta + state[el] + "_";
        }
      }
    });
    finalEtiqueta = finalEtiqueta.substring(0, finalEtiqueta.length - 1);

    if (state.edit) {
      if (state.editable == true) {
        // let valuePriority;
        if (state.status === 1) {
          const dataResponse = await props._validateHoursAvailable(
            props.currentAccount,
            props.accounts,
            state.fechaIni,
            state.priority,
            true
          );
          console.log("resposneee", dataResponse);
          setErrors((prev) => ({
            ...prev,
            priority: dataResponse,
          }));
          setIsLoading(false);
          if (dataResponse) {
            return;
          }
        } else {
          // props._validateCampg(props.campaignupd, state, props.accounts, false);
        }
        let value = {
          emailToSend: "nombre",
          nameToSend: "correo",
          id: state.id,
          name: state.name,
          medio: state.medio.site,
          fechaIni: state.fechaIni,
          fechafin: state.fechafin,
          objetivo: state.objetivo,
          budget: state.budget,
          publico: state.publico,
          idMixDetaill: state.idMixDetaill,
          idMixChange: state.idMixChange,
          idMix: state.idMix,
          idCampaign: state.idCampaign,
          id_medium: state.medio.id_medium,
          medioo: state.medio.site,
          id_ad_product: state.product.id_ad_product,
          banner: state.product.banner,
          state: state.status === 12 ? 12 : state.status === 13 ? 13 : 14,
          idBudget: state.idBudget,
          account: mAccount,
          creativity: state.creativity,
          observation: state.observation,
          copy: state.copy,
          id_sale: state.id_sale,
          id_region: state.country.id_region,
          name_region: state.country.name,
          nameForEmail,
          emailForEmail,
          urls: [],
          availableAmount: _.find(generalBudget, { id: state.idBudget })
            .available,
          newBudget,
          vversion, //state.version
          vnpost, //state.npost
          budgets: generalBudget,
          campaigns: campaign,
          categories: [],
          idAudience: state.idAudience,
          optionSelected,
          finalEtiqueta,
          formato: state.formato,
          priority: state.priority,
          id_digital_asset: state.digitalAsset.id,
          name_digital_asset: state.digitalAsset.name,
        };
        if (state.status !== 12 && state.status !== 13) {
          props
            ._updateCampaignModal(
              "nombre", //NameToSend
              "correo", //emailToSeng
              state.id, //id
              state.name, //name
              state.medio.site, //medio
              state.fechaIni, //fechaIni
              state.fechafin, // fechafin
              state.objetivo, //objetivo
              state.budget, //budget
              state.publico, //publico
              state.idMixDetaill, //idMixDetail
              state.idMixChange, //idMIxChange
              state.idMix, //idMix
              state.idCampaign, //idCampaign
              state.medio.id_medium, //ifMedium
              state.product.id_ad_product, //id_ad_product
              state.product.banner, //banner
              14, //estado
              state.idBudget, //idBUdget
              mAccount, //Account
              state.creativity, //Creativity
              state.observation, //Observation
              state.copy, //Copy
              state.id_sale, //id_sale
              state.country.id_region, //id_region
              state.country.name, //name_region
              nameForEmail, //nameForEmail
              emailForEmail, //EmailForEmail
              [], //Urls
              vversion, //version
              vnpost, //state.npost
              generalBudget, //Budgets
              state.idAudience, //idAudience
              optionSelected, //CategoriesSelected
              finalEtiqueta, //TagToEmail
              state.formato, //Formato
              props.campaignupd, //CampAc
              state.priority, //priority,
              state.digitalAsset.id,
              state.digitalAsset.name
            )
            .then((response) => {
              setIsLoading(false);
              clean(value, "update");
              props.setShowModal(false);
            });
        } else {
          props
            ._updateCampaignFinalized(
              "nombre",
              "correo",
              state.id,
              state.name,
              state.medio.site,
              state.fechaIni,
              state.fechafin,
              state.objetivo,
              state.budget,
              state.publico,
              state.idMixDetaill,
              state.idMixChange,
              state.idMix,
              state.idCampaign,
              state.medio.id_medium,
              state.medio.site,
              state.product.id_ad_product,
              state.product.banner,
              state.status === 12 ? 12 : state.status === 13 ? 12 : 14,
              state.idBudget,
              mAccount,
              state.creativity,
              state.observation,
              state.copy,
              state.id_sale,
              state.country.id_region,
              state.country.name,
              nameForEmail,
              emailForEmail,
              [],
              _.find(generalBudget, { id: state.idBudget }).available,
              newBudget,
              vversion, //state.version
              vnpost, //state.npost
              generalBudget,
              campaign,
              [],
              state.idAudience,
              optionSelected,
              chosenTag,
              finalEtiqueta,
              state.formato
            )
            .then(() => {
              clean(value, "update");
              props.setShowModal(false);
            });
        }
      }
      if (state.editable == false) {
        const idAWS = v4();
        let value = {
          id: idAWS,
          name: state.name,
          medio: state.medio.site,
          fechaIni: state.fechaIni,
          fechafin: state.fechafin,
          objetivo: state.objetivo,
          budget: state.budget,
          publico: state.publico,
          idMixDetaill: state.idMixDetaill,
          idMixChange: state.idMixChange,
          idMix: state.idMix,
          idCampaign: state.idCampaign,
          id_medium: state.medio.id_medium,
          medioo: state.medio.site,
          id_ad_product: state.product.id_ad_product,
          banner: state.product.banner,
          state: 14,
          idBudget: state.idBudget,
          account: mAccount,
          creativity: state.creativity,
          observation: state.observation,
          copy: state.copy,
          id_sale: state.id_sale,
          id_region: state.country.id_region,
          name_region: state.country.name,
          nameForEmail,
          emailForEmail,
          urls: props.campaignupd.urls,
          availableAmount: _.find(generalBudget, { id: state.idBudget })
            .available,
          newBudget,
          vversion, //state.version
          vnpost, //state.npost
          budgets: generalBudget,
          campaigns: campaign,
          categories: props.categories.list,
          idAudience: state.idAudience,
          optionSelected,
          formato: state.formato,
          priority: state.priority,
        };
        props
          ._exportCampaignAWS(
            idAWS,
            state.name,
            state.medio.site,
            state.fechaIni,
            state.fechafin,
            state.objetivo,
            state.budget,
            state.publico,
            state.idMixDetaill,
            state.idMixChange,
            state.idMix,
            state.idCampaign,
            state.medio.id_medium,
            state.medio.site,
            state.product.id_ad_product,
            state.product.banner,
            14,
            state.idBudget,
            // { id: state.account },
            mAccount,
            state.creativity,
            state.observation,
            state.copy,
            state.id_sale,
            state.country.id_region,
            state.country.name,
            nameForEmail,
            emailForEmail,
            //state.urls,
            props.campaignupd.urls,
            _.find(generalBudget, { id: state.idBudget }).available,
            newBudget,
            vversion, //state.version
            vnpost, //state.npost
            generalBudget,
            campaign,
            props.categories.list,
            state.idAudience,
            optionSelected,
            state.priority
          )
          .then(() => {
            clean(value, "update");
            props.setShowModal(false);
          });
      }
    } else {
      //Creacion de pauta
      let urls = "";
      const dataResponse = await props._validateHoursAvailable(
        props.currentAccount,
        props.accounts,
        state.fechaIni,
        state.priority,
        true
      );
      console.log("resposneee", dataResponse);
      setErrors((prev) => ({
        ...prev,
        priority: dataResponse,
      }));

      if (
        props.filesPauta.files !== undefined &&
        props.filesPauta.files !== null &&
        props.filesPauta.files !== ""
      ) {
        var contador = 1;
        for (var i = 0; i < props.filesPauta.files.length; i++) {
          const results = props.files.filter(
            (file) => file.name == props.filesPauta.files[i].path
          );

          if (results.length > 0) {
            if (urls == "") {
              urls = results[0].urlPublic;
            } else {
              urls = urls + "division" + results[0].urlPublic;
            }
            contador++;
          } else {
          }
        }
      }

      const id = v4();
      let mandar = {
        id: id,
        name: state.name,
        medio: state.medio.site,
        fechaIni: state.fechaIni,
        fechaFin: state.fechafin,
        objetivo: state.objetivo,
        budget: state.budget,
        publico: state.publico,
        id_medium: state.medio.id_medium,
        medioo: state.medio.site,
        id_ad_product: state.product.id_ad_product,
        banner: state.product.banner,
        estado: 1,
        idBudget: state.idBudget,
        // { id: props.currentAccount },
        account: mAccount,
        creativity: state.creativity,
        id_sale: state.id_sale,
        observation: state.observation,
        copy: state.copy,
        urls: urls,
        availableAmount: _.find(generalBudget, { id: state.idBudget })
          .available,
        id_region: state.country.id_region,
        name_region: state.country.name,
        nameForEmail: nameForEmail,
        emailForEmail: emailForEmail,
        version: vversion, //state.version
        npost: vnpost, //state.npost
        budgets: generalBudget,
        campaigns: campaign,
        idAudience: state.idAudience,
        categoriesSelectred: optionSelected,
        formato: state.formato,
        priority: state.priority,
      };

      props
        ._createCampaign(
          id, //id
          state.name, //name
          state.medio.site, //medio
          state.fechaIni, //fechaIni
          state.fechafin, //fechafin
          state.objetivo, //objetivo // ! validar (no se usa)
          state.budget, //budget
          state.publico, //publico
          state.medio.id_medium, //id_medium
          state.medio.site, // medioo
          state.product.id_ad_product, //id_ad_product
          state.product.banner, //bammer
          1, //estado
          state.idBudget, //budgetId
          mAccount, //account
          state.creativity, //creativity
          state.id_sale, //id_sale
          state.observation, //observation
          state.copy, //copy
          urls, //urls
          state.country.id_region, // id_region
          state.country.name, //name_region
          nameForEmail, //nameForEmail
          emailForEmail, //Emailforemail
          "", //version
          "", //npost
          generalBudget, //budgets
          state.idAudience, //idAudience
          optionSelected, //optionSelected
          state.formato, // formato
          state.priority, //priority
          finalEtiqueta,
          state.digitalAsset.id,
          state.digitalAsset.name
        )
        .then(() => {
          console.log("IDREPOSNE 222");
          setIsLoading(false);
          let fechaAcIni = _.split(state.fechaIni, ".", 10);
          let fechaAcFin = _.split(state.fechafin, ".", 10);
          let newOrderFin =
            fechaAcFin[2] + "/" + fechaAcFin[1] + "/" + fechaAcFin[0];
          let newOrderIni =
            fechaAcIni[2] + "/" + fechaAcIni[1] + "/" + fechaAcIni[0];
          let bodyEmail = {
            name: state.name,
            fechaIni: newOrderIni,
            fechaFin: newOrderFin,
            id: state.id,
            id_sale: state.id_sale,
            country: state.country.name,
            medio: state.medio.site,
            banner: state.product.banner,
            budget: Number(state.budget),
            copy: state.copy,
            observation: state.observation,
          };
          // ! Descomentar
          props._newEmailValidation(
            props.currentAccount,
            "Campañas",
            bodyEmail,
            props.currentUser,
            props.accounts,
            "Creación"
          );
          props._showLoader(false);

          clean(mandar, "create");
          props.setShowModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          getFormatBudgets();
        });
    }
    // fin del else

    // clean()
  };
  const handleBudgetInputChange = (e) => {
    let budgetParsed = {};
    budgetParsed = generalBudget.map((item) => {
      return {
        ...budgetParsed,
        account: item.account,
        active: item.active,
        amount: item.amount,
        available: parseFloat(item.available),
        createAt: item.createAt,
        id: item.id,
        nombrePadre: item.nombrePadre,
        padre: item.padre,
        status: item.status,
        tags: item.tags,
        title: item.title,
        updateAt: item.updateAt,
      };
    });
    const value = e;
    let errorBudget = false;
    let errorBudgetMessage = "";

    if (state.idBudget !== "") {
      setState((prev) => ({
        ...prev,
        budget: value,
      }));
      errorBudget = _.isEmpty(value);

      let availableAmount = 0;

      let aux;
      if (props.campaignupd.budget > 0) {
        if (props.campaignupd.idBudget === state.idBudget) {
          availableAmount =
            Number(
              budgetParsed.find((ele) => ele.id === state.idBudget).available
            ) + Number(props.campaignupd.budget);
        } else {
          availableAmount = Number(
            budgetParsed.find((ele) => ele.id === state.idBudget).available
          );
        }
        let nuevoBudgetOp = availableAmount - Number(value);
        aux = value;
        newBudget = nuevoBudgetOp;

        props.utils.log("presupuesto disponible 1", {
          availableAmount,
          newBudget,
        });
      } else {
        //aca creo
        availableAmount = Number(
          budgetParsed.find((ele) => ele.id === state.idBudget).available
        );
        let operacion = availableAmount - Number(value);

        if (operacion >= 0) {
          newBudget = operacion;
        } else {
          aux = value;
        }
      }

      setValidadorValue(value);
      //fin de tarea de edición de presupuesto

      if (aux > availableAmount /* || value > availableAmount*/) {
        errorBudget = true;
        errorBudgetMessage =
          "Monto incorrecto, el presupuesto ingresado es mayor al disponible. ";
      }
      if (
        new Number(aux) <
        new Number(props.campaignupd.total_gastado_con_comision)
      ) {
        errorBudget = true;
        errorBudgetMessage =
          " Monto incorrecto, el presupuesto ingresado es menor al consumido. ";
      }
      if (aux < 0) {
        errorBudget = true;
        errorBudgetMessage = "Presupuesto no válido ";
      }

      if (state.status === 13) {
        if (Number(aux) > props.campaignupd.total_gastado_con_comision) {
          errorBudget = true;
          errorBudgetMessage =
            "Monto incorrecto, el presupuesto ingresado es mayor al presupuesto consumido con comisión";
        }
        if (Number(aux) < props.campaignupd.total_gastado_con_comision) {
          errorBudget = true;
          errorBudgetMessage =
            "Monto incorrecto, el presupuesto ingresado es menor al presupuesto consumido con comisión";
        }
      }

      setErrors((prev) => ({
        ...prev,
        budget: errorBudget,
        errorBudgetMessage,
      }));
    } else {
      errorBudget = true;
      errorBudgetMessage = "Selecciona un presupuesto ";
    }

    setErrors((prev) => ({
      ...prev,
      budget: errorBudget,
      errorBudgetMessage,
    }));
  };
  const docType = (file) => {
    let fileExt = file.name.split(".");
    let getExtension = fileExt[1].toLowerCase();
    if (file.type == "Image") {
      let imgExt = ["jpg", "jpeg", "png", "gif"];
      if (imgExt.includes(getExtension)) {
        var img = `https://marketing-suite-files-bucket90024-production.s3.amazonaws.com/public/${file.name}`;
      } else if (getExtension == "rar") {
        var img = imgRar;
      } else if (getExtension == "zip") {
        var img = imgZip;
      } else if (getExtension == "mp4") {
        var img = imgMp4;
      } else if (getExtension == "txt") {
        var img = imgTxt;
      } else if (getExtension == "pptx" || getExtension == "ppt") {
        var img = imgPp;
      } else {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/sin+imagen.jpg`;
      }
    } else {
      if (getExtension == "pdf") {
        var img = imgPdf;
      } else if (getExtension == "docx" || getExtension == "doc") {
        var img = imgWord;
      } else if (getExtension == "xlsx" || getExtension == "xls") {
        var img = imgExcel;
      } else {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/sin+imagen.jpg`;
      }
    }
    return img;
  };
  return (
    <>
      <div
        id="new-pauta-modal"
        style={{ display: "none" }}
        className={classnames("upsert-modal w-85 h-85 ModalPauta")}
      ></div>
      <div className="titleOneStep w-100 bodyModalPauta">
        <p>{state.edit ? "Editemos esta pauta" : "Creemos una nueva pauta"}</p>
      </div>
      <div className="bodyOneStep w-100 bodyModalPauta">
        <div className="oneColBody" id="cname">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Ponle un nombre a tu campa&ntilde;a
            </Label>
            <Input
              type="text"
              name="name"
              className="input"
              value={state.name}
              onChange={handleInputChange}
              disabled={disabled.name}
              placeholder="P. ejemplo campaña de día de la madre"
              invalid={errors.name}
            />
            <FormFeedback>Ingresa un nombre</FormFeedback>
          </FormGroup>
        </div>
        {/* <div className="oneColBody" id="cfecha">
          <FormGroup>
            <Label
              className="texto_corregido"
              // style={{
              //   color: '#05252d',
              //   fontWeight: 500,
              //   marginBottom: '5px',
              // }}
            >
              Elige un rango de fechas
            </Label>
            <DataPicker
              disabled={disabled.dateRange}
              onChangeDate={handleDateChange}
              fechaIni={state.fechaIni}
              fechafin={state.fechafin}
              className={classnames({
                "is-invalid": errors.fechaIni || errors.fechafin,
              })}
            />
            {/* <FormFeedback>Ingresa un rango de fechas</FormFeedback>
            <FormFeedback>{messageErrors.dates}</FormFeedback>
             
            <FormFeedback>{messageErrors.dates}</FormFeedback>
          </FormGroup>
        </div> */}
        <div
          className="oneColBody"
          id="cfecha"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormGroup>
            <Label className="texto_corregido">Elige un rango de fechas</Label>
            <DataPicker
              disabled={disabled.dateRange}
              onChangeDate={handleDateChange}
              fechaIni={state.fechaIni}
              isActive={isActive}
              fechafin={state.fechafin}
              className={classnames({
                "is-invalid": errors.fechaIni || errors.fechafin,
              })}
            />
            <FormFeedback>{messageErrors.dates}</FormFeedback>
          </FormGroup>
          <FormGroup className="priorityResponsive">
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Prioridad de campa&ntilde;a
            </Label>
            <Autocomplete
              options={[...optionsToShow]}
              getOptionLabel={(option) => option.name}
              value={defaultState}
              onChange={(event, newValue) => {
                const selectedValue = newValue ? newValue.id : state.priority;
                handleSelect({
                  target: { name: "priority", value: selectedValue },
                });
              }}
              className={classnames({
                "is-invalid": errors.priority,
              })}
              disabled={disabled.priority}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  label=""
                  variant="outlined"
                  name="Normal"
                  autoComplete="off"
                />
              )}
            />
            <FormFeedback>Seleccione una opción valida</FormFeedback>
          </FormGroup>
        </div>
        {categoriesSorted.length > 0 &&
          categoriesSorted?.map((element, idx) => {
            if (
              element.id &&
              element.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" &&
              element.active == true
            ) {
              return (
                <div id={element.id} key={idx} className="contSelect">
                  <div className="oneColBody">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#05252d",
                          fontWeight: 500,
                          marginBottom: "5px",
                        }}
                      >
                        {element.name}
                      </Label>
                      <MySelect
                        isDisabled={disabled.categorias}
                        placeholder="Selecciona una categoria de producto"
                        options={getCurrentListCategory(element.id)}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          MultiValue,
                          animatedComponents,
                        }}
                        onChange={(e) => handleChange(e, element.id, element)}
                        allowSelectAll={true}
                        value={getCategoryOptionSelected(element.id)}
                      />
                    </FormGroup>
                  </div>
                </div>
              );
            }
          })}

        <div className="oneColBody" id="cpais">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un pa&iacute;s donde pautar
            </Label>
            <Autocomplete
              value={state.country.id_region === "" ? null : state.country}
              disabled={disabled.pais}
              options={countries.length > 0 ? countries : []}
              onChange={handleCountryChange}
              getOptionLabel={(option) => option.name}
              style={{ height: 35 }}
              getOptionSelected={(option, value) =>
                option.id_region === value?.id_region
              }
              className={classnames({
                "is-invalid": errors.medio,
              })}
              renderInput={(params) => (
                <TextField {...params} label="País" variant="outlined" />
              )}
            />
            <FormFeedback>Elige un pais</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cmedio">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un medio donde pautar
            </Label>
            <Autocomplete
              value={state.medio}
              options={mediumBanner}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleMediumChange}
              disabled={disabled.medio}
              getOptionLabel={(option) => option.site}
              style={{ height: 35 }}
              className={classnames({
                "is-invalid": errors.medio,
              })}
              renderInput={(params) => (
                <TextField {...params} label="Medio" variant="outlined" />
              )}
            />
            <FormFeedback>Ingresa un medio donde pautar</FormFeedback>
          </FormGroup>
        </div>

        <div className="oneColBody" id="cformato">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un objetivo para pautar
            </Label>
            <Autocomplete
              value={state.product}
              options={formato}
              disabled={disabled.objetivo}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleMediumTypeChange}
              getOptionLabel={(option) => option.banner}
              style={{ height: 35 }}
              className={classnames({
                "is-invalid": errors.id_ad_product,
              })}
              renderInput={(params) => (
                <TextField {...params} label="Objetivo" variant="outlined" />
              )}
            />
            <FormFeedback>Ingresa un objetivo para pautar</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cname">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un formato para pautar
            </Label>
            <Autocomplete
              getOptionSelected={(option, value) => option.id === value.id}
              value={state.formato}
              options={formats}
              disabled={disabled.formato}
              onChange={handleChangeFormat}
              getOptionLabel={(option) => option.art}
              style={{ height: 35 }}
              className={classnames({
                "is-invalid": errors.formato,
              })}
              renderInput={(params) => (
                <TextField {...params} label="Formato" variant="outlined" />
              )}
            />
            <FormFeedback>Ingresa un formato para pautar</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cpresupuesto">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Selecciona un presupuesto para la campa&ntilde;a
            </Label>
            <Select
              options={budget}
              value={currentBudget}
              isDisabled={disabled.budget}
              placeholder="Mi presupuesto"
              onChange={handleBudgetSelectChange}
              className={classnames({
                "is-invalid": errors.idBudget,
              })}
            />
            <FormFeedback>Selecciona un presupuesto</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cinversion">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Ingresa la inversión
            </Label>
            <div
              className={classnames("d-flex align-items-center", {
                "is-invalid": errors.errorBudgetMessage,
              })}
            >
              <CurrencyInput
                // min="0"
                intlConfig={{ locale: "es-GT", currency: "USD" }}
                prefix="$"
                // type='number'
                name="amount"
                //pattern='[0-9]*'
                disabled={disabled.investment}
                className="input form-control"
                style={{
                  color: "#05252d",
                }}
                value={state.budget}
                onValueChange={(e) => handleBudgetInputChange(e)}
                placeholder="P. ejemplo 1000.25"
                invalid={errors.errorBudget}
              />
            </div>
            <FormFeedback>{errors.errorBudgetMessage}</FormFeedback>
          </FormGroup>
        </div>
        {/* <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              N&uacute;mero de OV
            </Label>
            <Input
              type="text"
              name="id_sale"
              className="input"
              invalid={errors.ov}
              disabled={disabled.ov}
              value={state.id_sale}
              onChange={handleInputChange}
              placeholder="Por ejemplo: 1234567"
            />
            <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
          </FormGroup>
        </div> */}
        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Prioridad de campa&ntilde;a
            </Label>
            <Autocomplete
              options={[...optionsToShow]}
              getOptionLabel={(option) => option.name}
              value={defaultState}
              onChange={(event, newValue) => {
                const selectedValue = newValue ? newValue.id : state.priority;
                handleSelect({
                  target: { name: "priority", value: selectedValue },
                });
              }}
              disabled={disabled.priority}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  label="Prioridad"
                  variant="outlined"
                  name="Normal"
                />
              )}
            />
            <FormFeedback>Eliga una opci&oacute;n</FormFeedback>
          </FormGroup>
        </div>
        {categoriesSorted?.length % 2 !== 0 ? (
          // <div className="oneColBody"></div>
          <></>
        ) : (
          <></>
        )}
        {/* <div className="oneColBody"></div> */}
        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Observaciones
            </Label>
            <Input
              disabled={disabled.observaciones}
              type="textarea"
              rows={7}
              onChange={handleObservationChange}
              value={state.observation}
              name="observation"
            />
          </FormGroup>
        </div>

        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Copy
            </Label>
            <Input
              type="textarea"
              rows={7}
              disabled={disabled.copy}
              onChange={handleCopyChange}
              value={state.copy}
              name="copy"
            />
          </FormGroup>
        </div>

        <div className="twoColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un público
            </Label>
            <div onClick={onClickTest}>
              <Select
                options={publicos}
                value={currentAudience}
                isDisabled={disabled.audience}
                // getOptionLabel={(option) => option.id}
                placeholder="Selector de publicos"
                onChange={handleAudienceChange}
                className={classnames({
                  "is-invalid": errors.publico || "",
                })}
              />
              <FormFeedback>
                Debe definir o elegir un nombre de público y este debe tener
                hashtags asociados
              </FormFeedback>
            </div>
          </FormGroup>
        </div>

        <div className="twoColBody" id="digitalAsset">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un Activo Digital
            </Label>
            <Autocomplete
              disableClearable={true}
              value={state.digitalAsset}
              disabled={disabled.digitalAsset}
              options={digitalAssets.length > 0 ? digitalAssets : []}
              onChange={(e, value) => {
                onChangeDigitalAssets({
                  target: { name: "digitalAssets", value },
                });
              }}
              getOptionLabel={(option) => option.name}
              style={{ height: 35 }}
              getOptionSelected={(option, value) => option.id === value?.id}
              className={classnames({
                "is-invalid": errors.digitalAssets,
              })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Activo Digital"
                  variant="outlined"
                />
              )}
            />
            <FormFeedback>Elige un Activo Digital</FormFeedback>
          </FormGroup>
        </div>
        <div className="twoColBody">
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Público incluido
          </Label>
          <div className="tagsContent tagsCont2" id="tagsContent">
            {state.publico != null
              ? state.publico?.map((ta, idx) => (
                  <div key={idx} className="tagCont">
                    <p>{ta}</p>
                  </div>
                ))
              : ""}
          </div>
        </div>
        <div className="twoColBody">
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Público excluido
          </Label>
          <div className="tagsContent tagsCont2" id="tagsContent">
            {state.publicoE != null
              ? state.publicoE?.map((ta, idx) => (
                  <div key={idx} className="tagCont">
                    <p>{ta}</p>
                  </div>
                ))
              : ""}
          </div>
        </div>
        <div className="twoColBody">
          <div>
            <div className="img-content-creativy">
              {images.map((file) => (
                <div className="imagenCreati mb-5">
                  {!disabled.dropzone && (
                    <i
                      onClick={() => {
                        remove(file);
                      }}
                      className="fas fa-times"
                    ></i>
                  )}
                  {
                    <>
                      <img
                        style={{ display: "flex", margin: "auto" }}
                        className="img-fluid h-100"
                        src={docType(file)}
                      />{" "}
                      <p>{file.name}</p>
                    </>
                  }
                </div>
              ))}
            </div>
            <DropZone
              disabled={disabled.dropzone}
              onUpload={handleUpload}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
      <div className="footOneStep w-100 footOneStepModal">
        <Tooltip title="Descartar cambios" color="#002448" placement="top">
          <a
            href="#"
            className="btnCancel firstBtn"
            onClick={() => {
              localStorage.setItem("modalState", false);
              closeModal();
              analytics.modalesAcciones({
                accion: "Cancelar",
                ubicacion: "Modal Dashboard",
              });
              props.setShowModal(false);
            }}
          >
            Cancelar
          </a>
        </Tooltip>
        {isLoading ? (
          <Tooltip title="Guardar cambios" color="#002448" placement="top">
            <a
              className="btnConfirm secondBtn"
              onClick={() => {
                console.log("Isloading");
              }}
            >
              Confirmar
            </a>
          </Tooltip>
        ) : (
          <Tooltip title="Guardar cambios" color="#002448" placement="top">
            {!activeSend ? (
              <a
                className="btnConfirm secondBtn"
                style={{ cursor: "not-allowed" }}
                onClick={() => {}}
              >
                Confirmar
              </a>
            ) : (
              <a
                className="btnConfirm secondBtn"
                onClick={() => {
                  localStorage.setItem("modalState", true);
                  analytics.modalesAcciones({
                    accion: "Confirmar",
                    ubicacion: "Modal Dashboard",
                  });
                  sendCampaign();
                }}
              >
                Confirmar
              </a>
            )}
          </Tooltip>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  accounts: state.accounts.list,
  campaignupd: state.campaignupd,
  campaignuAdd: state.campaignuAdd,
  filter: state.campaigns.filter,
  mediumbanners: state.mediumbanners,
  currentAccount: state.accounts.default,
  mediumbannerstype: state.mediumbannerstype,
  filesPauta: state.filesPautaReducer,
  files: state.creative.files,
  budget_spent: state.budget_spentReducer,
  currentUser: state.app.current_user,
});

const mapDispatchToProps = (dispatch) => ({
  _msgsuccess: () => dispatch(msgsuccess()),
  _getConfigScheduleGlobal: () => dispatch(getConfigScheduleGlobal()),
  _showLoader: (show) => dispatch(showLoader(show)),
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _saveNames: (name) => dispatch(saveNames(name)),
  _getCategories: (account) => dispatch(getCategories(account)),
  _cleanCampaign: () => dispatch(cleanCampaign()),
  _funcampaignupAdd: (camp) => dispatch(funcampaignupAdd(camp)),
  _cleanSteps: (step) => dispatch(cleanSteps(step)),
  _saveSocials: (name) => dispatch(saveSocials(name)),
  _clearCampaignupd: () => dispatch(clearCampaignupd()),
  _saveMediumm: (medium) => dispatch(saveMediumm(medium)),
  _getCountries: (account, user, idmod, mod) =>
    dispatch(getCountries(account, user, idmod, mod)),

  _saveBudgets: (budget) => dispatch(saveBudgets(budget)),
  _getMediumBanner: (bdy) => dispatch(getMediumBanner(bdy)),
  _saveObjets: (objetivo) => dispatch(saveObjets(objetivo)),
  _saveproduct: (product) => dispatch(saveproduct(product)),
  _saveCategory: (category) => dispatch(saveCategory(category)),
  _saveIdmediumm: (idmedium) => dispatch(saveIdmediumm(idmedium)),
  _getCampaign: (account, filter) => dispatch(getCampaigns(account, filter)),
  _getBudgets: (account, user, idmod, mod) =>
    dispatch(getBudgets(account, user, idmod, mod)),
  _ShowAlertModal: (titulo, texto, tipo) =>
    dispatch(Modal.showAlertModal(titulo, texto, tipo)),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
  _createCampaign: (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    budgetId,
    account,
    creativity,
    id_sale,
    observation,
    copy,
    urls,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    version,
    npost,
    budgets,
    idAudience,
    optionSelected,
    formato,
    priority,
    finalEtiqueta,
    id_digital_asset_,
    name_digital_asset
  ) =>
    dispatch(
      createCampaign(
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        budgetId,
        account,
        creativity,
        id_sale,
        observation,
        copy,
        urls,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        version,
        npost,
        budgets,
        idAudience,
        optionSelected,
        formato,
        priority,
        finalEtiqueta,
        id_digital_asset_,
        name_digital_asset
      )
    ),
  _updateCampaign: (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected,
    chosenTag,
    tagToEmail,
    formato,
    campAc,
    priority
  ) =>
    dispatch(
      updateCampaign(
        nameToSend,
        emailToSend,
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        urls,
        availableAmount,
        newBudget,
        version,
        npost,
        budgets,
        campaigns,
        categories,
        idAudience,
        optionSelected,
        chosenTag,
        tagToEmail,
        formato,
        campAc,
        priority
      )
    ),
  _exportCampaignAWS: (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected
  ) =>
    dispatch(
      exportCampaignAWS(
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        urls,
        availableAmount,
        newBudget,
        version,
        npost,
        budgets,
        campaigns,
        categories,
        idAudience,
        optionSelected
      )
    ),
  _updateCampaignFinalized: (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected,
    chosenTag,
    tagToEmail
  ) =>
    dispatch(
      updateCampaignFinalized(
        nameToSend,
        emailToSend,
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        urls,
        availableAmount,
        newBudget,
        version,
        npost,
        budgets,
        campaigns,
        categories,
        idAudience,
        optionSelected,
        chosenTag,
        tagToEmail
      )
    ),
  _updateCampaignModal: (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    version,
    npost,
    budgets,
    idAudience,
    optionSelected,
    tagToEmail,
    formato,
    campAc,
    priority,
    id_digital_asset,
    name_digital_asset
  ) =>
    dispatch(
      updateCampaignModal(
        nameToSend,
        emailToSend,
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        urls,
        version,
        npost,
        budgets,
        idAudience,
        optionSelected,
        tagToEmail,
        formato,
        campAc,
        priority,
        id_digital_asset,
        name_digital_asset
      )
    ),
  _validateHoursAvailable: (
    currentAccount,
    accounts,
    startDate,
    priority,
    edit
  ) =>
    dispatch(
      validateHoursAvailable(
        currentAccount,
        accounts,
        startDate,
        priority,
        edit
      )
    ),
  _validateCampg: (oldVersion, newVersion, accounts, approve) =>
    dispatch(validateCampg(oldVersion, newVersion, accounts, approve)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ModalPauta));
