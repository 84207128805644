import React from 'react'
import { Link } from "react-router-dom";

// components
import Asociar from 'components/fanpageAsociar'
import MetaTags from 'components/metatags'

const AsociarPage = () => (
    <>
        <MetaTags title='Asociar' description='Asociar páginas' />
        <Asociar />
    </>
)

export default AsociarPage
