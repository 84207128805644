import { find } from 'lodash'
import { v4 } from 'uuid'
import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'

export const ADD_USER = 'USERS:ADD'
export const CLEAN_USER = 'USERS:CLEAN'
const TAG = 'USERS:Actions'



export const cleanUsers = () => (dispatch) => {
  dispatch({
    type: CLEAN_USER,
  })
}

export const getUsersRole = (user) => async dispatch => {

  dispatch(cleanUsers());
  try {
    const response = await APILA.getCognitoUsers()

    // const { campaigns, count } = response.data.campaigns    
    // if (response.data.users) {
    //   // response.data.campaigns.filter((campaign) => {
    //   //   /*CAMPAIGNS_ADD_ONE */
    //   //   // dispatch(addCampaign(campaign))
    //   // })
    //   dispatch(addUsers(response.data.users))
    // }
    let { users } = response.data
    users.forEach(client_response => {
      let user = client_response.Username;      
      let nameAttr = find(client_response.Attributes, { 'Name': 'name' })
      let emailAttr = find(client_response.Attributes, { 'Name': 'email' })
      let sub = find(client_response.Attributes, { 'Name': 'sub' })
        

      let client = {
        id: sub ? sub.Value : '',
        name: nameAttr ? nameAttr.Value : '',
        email: emailAttr ? emailAttr.Value : '',
      }

      dispatch(addUsers(client))
    })

    
  } catch (err) {
    Logger.log(TAG, 'error getUsersRole', err)
  }
}


  const addUsers = users => {
    
    return dispatch => {
      dispatch({
        type: ADD_USER,
        users
      })
    }
  }
  