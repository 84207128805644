import React, { useEffect, useState } from "react";
import _, { unset } from "lodash";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { newEmailValidation } from "redux/actions/email.actions";
import { Tooltip } from "antd";
// organization components
import { createBudget } from "redux/actions/budgets.actions";
import ReactDataTable, {
  NumberFormat,
  SimpleText,
  NumberFormatEdit,
} from "@easygosanet/react-datatable";
import DetailColEdit from "components/DetailCol";
import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";
import Swal from "sweetalert2";

import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { getClients } from "redux/actions/clients.actions";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import { Auth } from "aws-amplify";
import { pwaDeleteBudget } from "redux/actions/pwa.actions";
import {
  getAccountTags,
  updateAccountMembers,
} from "redux/actions/accounts.actions";
import {
  setEdit,
  clearEdit,
  getBudgets,
  clearBudgets,
} from "redux/actions/budgets.actions";
import { getAllCampaigns } from "redux/actions/allCampaings.actions";
import { withUtils } from "utils";
import { v4 } from "uuid";
import { element } from "prop-types";
import { FlashAutoOutlined } from "@material-ui/icons";
import analytics from "../../helpers/analytics";
import PageLoader from "components/page_loader";
import ModalPresupuesto from "./modal/ModalPresupuestos";

//import "./assets/css/styles.scss";
const idmod = 3;
const mod = "PRESUPUESTOS";

// const DetailCol = ({ text, className, onClick }) => (
//   <div className="firstColl w-100">
//     <div onClick={() => onClick()} className={className}>
//       <i className="fas fa-edit"></i>
//     </div>
//     <p className="name-fc">{text}</p>
//   </div>
// );

const Status = ({ value }) => (
  <>
    {value === 1 && <div className="secondColl green">Aprobado</div>}
    {value === 2 && <div className="secondColl orange">Pendiente</div>}
    {value === 3 && <div className="secondColl red">Rechazado</div>}
  </>
);
const StatusName = (value) => {
  console.log("StatusName ", value);
  let name = "";
  if (value === 1) {
    name = "Aprobado";
  }
  if (value === 2) {
    name = "Pendiente";
  }
  if (value === 3) {
    name = "Rechazado";
  }

  return name;
};
const Content = (props) => {
  const orderDefault = [
    "title",
    "createAt",
    "updateAt",
    "amount",
    "availableC",
    "available",
    "status",
  ];
  const [nameView, setNameView] = useState("");
  const budgetUseSelector = useSelector((state) => state.budgets.list);
  const [account, setAccount] = useState(props.currentAccount);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [filters, setFilters] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterInfo, setFilterInfo] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [colAct, setColAct] = useState([]);
  const [views, setViews] = useState([]);
  const [vacio, setVacio] = useState(false);
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [elim, setElim] = useState(false);
  const [nameGrouped, setnameGrouped] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });
  console.log("Content props ", props);

  function numberWithCommas(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const getActiveLabel = (active) => {
    if (active) {
      return "Activo";
    } else {
      return "Eliminado";
    }
  };

  const getClassActive = (active) => {
    if (active) {
      return "status_mix_aprobado";
    } else {
      return "status_cancelado";
    }
  };

  const [columns, setColumns] = useState([
    {
      omit: false,
      name: "Nombre",
      selector: "title",
      sortable: true,
      type: "text",
      id: "title",
      tag: "title",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        let eliminar = row.availableC === 0 ? true : false;
        return (
          <DetailColEdit
            text={row.title}
            id={"name"}
            accounts={[]}
            viewBudget={false}
            deleteData={() => eliminarPresupuesto(row)}
            deleteV={eliminar}
            budgetOld={false}
            nameColumn={row}
            edit={row.active ? row.permisos.edit : false}
            onEditValue={updateBudget}
            editInfo={() => goToEditPage(row)}
            editable={false}
            type={"text"}
            permissions={row.permisos ? row.permisos : ""}
          />
        );
      },
    },
    {
      omit: false,
      name: "Fecha de Creación",
      id: "createAt",
      tag: "createAt",
      selector: "createAt",
      sortable: true,
      center: true,
      cell: (row) => formatDate(row.createAt),
    },
    {
      omit: false,
      name: "Última Actualización",
      id: "updateAt",
      tag: "updateAt",
      selector: "updateAt",
      sortable: true,
      center: true,
      cell: (row) => {
        //console.log("row.updateAt",row.updateAt);
        return formatDate(row.updateAt);
      },
    },
    // {
    //   omit: true,
    //   name: "Categoria",
    //   selector: "padre",
    //   id: "padre",
    //   tag: "padre",
    //   visible: false,
    //   sortable: true,
    //   center: true,
    //   // type: 'text',
    //   cell: (row) => {
    //     return mostrarPadre(row.padre, props.budgets);
    //   },
    // },
    // {
    //   name: 'Padre',
    //   selector: 'padre',
    //   sortable: true,
    //   //center: true,
    //   style: {
    //     fontWeight: 'bold',
    //   },
    //   type: 'text',
    //   cell: (row) => mostrarPadre2(row.padre),
    // },
    {
      omit: false,
      name: "Presupuesto Total",
      selector: "amount",
      id: "amount",
      tag: "amount",
      sortable: true,
      type: "selectOp",
      show: true,
      center: true,
      minWidth: "220px",
      cell: (row) => (
        // `$${numberWithCommas(row.amount)}`,
        <NumberFormatEdit
          prefix={"$"}
          thousandSeparator={true}
          value={row.amount}
          id={"budget"}
          displayType={"text"}
          tipo={"text"}
          editable={row.permisos ? row.permisos.edit : false}
          nameColumn={row}
          onEditValue={updateBudget}
        />
      ),
    },
    {
      omit: false,
      name: "Presupuesto Implementado",
      selector: "availableC",
      id: "availableC",
      tag: "availableC",
      sortable: true,
      center: true,
      type: "selectOp",
      show: true,
      cell: (row) => `$${numberWithCommas(row.availableC)}`,
    },
    {
      omit: false,
      name: "Presupuesto Disponible",
      selector: "available",
      id: "available",
      tag: "available",
      sortable: true,
      type: "selectOp",
      show: true,
      center: true,
      cell: (row) => `$${numberWithCommas(row.available)}`,
    },
    {
      omit: false,
      name: "Estado",
      id: "status",
      tag: "status",
      type: "status",
      show: false,
      selector: "status",
      sortable: true,
      center: true,
      cell: (row) => (
        <Status
          value={StatusName(row.status)}
          // label={getStatusLabel(row.status)}
          // className={getStatusClassName(row.status)}
          label={getActiveLabel(row.active)}
          className={getClassActive(row.active)}
          justifyContent={"center"}
          center={"true"}
        />
      ),
    },
  ]);
  const updateBudget = (row) => {
    let title = "Está seguro que desea editar el presupuesto?";
    props._showConfirmationModal("Editar prespuesto", title, async () => {
      let col = row[0].column;
      let value = row[0].value;
      let name = row[0].id;
      let save = true;
      analytics.accionesTabla({
        accion: "Editar con doble clic",
        ubicacion: "Presupuestos",
      });
      let amountA = name === "budget" ? value : col.amount;
      let availableAmount = 0;
      let prevBud = col;

      if (name === "budget") {
        if (Number(value) < col.availableC) {
          save = false;
        }
      }
      console.log("ROWW", col);

      if (prevBud) {
        if (prevBud.amount > amountA) {
          let dif = amountA - prevBud.amount;
          availableAmount = prevBud.available + dif;
        } else {
          let dif = prevBud.amount - amountA;
          availableAmount = prevBud.available - dif;
        }
      }
      if (save) {
        let state = {
          budgetId: col.id,
          name: name === "name" ? value : col.title,
          currentAccount: props.currentAccount,
          amount: name === "budget" ? value : col.amount,
          tags: col.tags,
          createAt: col.createAt,
          edit: true,
          padre: "",
          availableAmount: availableAmount,
          idBudget: "",
          nombrePadre: "",
          budgets: props.budgets,
          fatherAvailable: undefined,
          currentUser: props.currentUser,
          idMod: 3,
          mod: "PRESUPUESTOS",
        };

        console.log("El estado", state);
        props._createBudget(
          state.budgetId,
          state.name,
          state.currentAccount,
          state.amount,
          state.tags,
          1,
          state.createAt,
          state.edit,
          state.padre,
          //state.budget,
          //state.idBudget,
          state.availableAmount,
          state.idBudget,
          state.nombrePadre,
          state.fatherAvailable,
          state.budgets,
          state.currentUser,
          state.idMod,
          state.mod
        );
      } else {
        Swal.fire({
          title: "Ups!",
          text: "El monto maximo reducible es de " + col.availableC,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  const eliminarPresupuesto = async (row) => {
    console.log("la row que recibo", row);
    let title =
      "Esta seguro que desea eliminar el presupuesto de " + row.title + "?";
    props._showConfirmationModal("Eliminar presupuesto?", title, async () => {
      try {
        setIsLoading(true);
        // let elim = await APILA.deleteBudget({ id: row.id });
        let elim = await APILA.updateBudget({ id: row.id, active: false });
        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: account,
          action: "se ha eliminado un presupuesto",
          component: "budgets",
        };
        await API.insertBitacora(bodyBitacora);
        props._newEmailValidation(
          props.currentAccount,
          "Presupuestos",
          row,
          props.currentUser,
          props.accounts,
          "Eliminación"
        );
        Swal.fire({
          title: "Presupuesto eliminado!",
          text: "Presupuesto eliminado correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        props._clearBudgets();
        setTimeout(() => {
          props._getClients(props.currentUser, idmod, mod);
          props._getBudgets(account, props.currentUser, idmod, mod);
          props._getAccountTags(account, props.currentUser, idmod, mod);
          props._getAllCampaigns(account, props.currentUser, idmod, mod);
          setIsLoading(false);
        }, 500);
      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error al eliminar el presupuesto",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  const defaultView = () => {
    if (data.length !== 0) {
      data.forEach((ele) => {
        ele.permisos = permissions;
      });
    }
    return (
      <ReactDataTable
        className="budgetTable"
        customStyles={customStyles}
        columns={columns}
        // data={searchFilterData.length > 0 ? searchFilterData : filteredData}
        data={data}
        persistTableHead
        pagination
        noHeader
      />
    );
  };

  useEffect(() => {
    setAccount(props.currentAccount);
    actualizarViews(true);
    setElim(true);
    permisos();
  }, [props.currentAccount /*, props.clients*/]);
  useEffect(() => {
    actualizarViews(true);
  }, []);
  // useEffect(() => {
  //   let show = false;
  //   let dataFinal = [];
  //   let or = [];
  //   if (views.length > 0) {
  //     views.forEach((ele) => {
  //       console.log("Eleaa", ele);
  //       if (ele.active) {
  //         ele.columns.forEach((elementos) => {
  //           console.log("Las colu ele", elementos);
  //           show = true;
  //           dataFinal.push(elementos);
  //           name(ele.name);
  //           handleShowColumnChange(elementos.selector, elementos.omit);
  //         });
  //       }
  //     });
  //   }
  //   if (dataFinal.length == 0) setNameView("Lista");
  //   if (show) {
  //     dataFinal.sort((a, b) => {
  //       return a.position - b.position;
  //     });
  //   }
  //   dataFinal.forEach((element) => {
  //     or.push(element.id);
  //   });
  //   if (dataFinal.length == 0) {
  //     name("Lista");
  //   }
  //   if (dataFinal.length > 0) {
  //     handleColumnSortChange(or);
  //   }
  // }, [colAct]);
  let options1 = ["Nombre"];
  const permisos = async () => {
    try {
      setIsLoading(true);
      let userLog = await Auth.currentAuthenticatedUser();
      let permisoAcc = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await API.getRoleNameById({ id: idRol });
      console.log("ACA");
      let permissAud = permisosRes.data.rol[0].permissions;
      console.log("Permisos aud", permissAud);
      let permissFn = permissAud.filter((ele) => ele.name == "Presupuestos");

      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
    }
  };

  const actualizarViews = async (tipo) => {
    setElim(true);
    if (Auth.user) {
      setIsLoading(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await API.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "budgets",
      });
      setViews(viewsData.data.views);
      console.log("Data view", viewsData.data.views);
      if (viewsData.data.views.length === 0) {
        setNameView("lista");
      } else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          setNameView(vistaActiva.name);
          if (tipo) {
            let nombres = [];
            vistaActiva.columns.forEach((ele) => {
              nombres.push(ele.id);
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(nombres);
          }
        } else {
          columns.forEach((ele) => {
            handleShowColumnChange(ele.selector, ele.omit);
          });
          handleColumnSortChange(orderDefault);
          setNameView("lista");
        }
      }
      setIsLoading(false);
    }
    if (tipo) {
      await permisos();
    }
  };
  const name = (name) => {
    setNameView(name);
  };
  useEffect(() => {
    setIsLoadingData(true);
    if (props.currentUser !== "") {
      props._getClients(props.currentUser, idmod, mod);
      account !== "" &&
        props._getBudgets(account, props.currentUser, idmod, mod);
      account !== "" &&
        props._getAccountTags(account, props.currentUser, idmod, mod);
      account !== "" &&
        props._getAllCampaigns(account, props.currentUser, idmod, mod);
    }
    setTimeout(() => {
      setIsLoadingData(false);
    }, [4000]);
  }, [account, props.currentUser /*, props.account*/]);

  /*
 clients: state.clients,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,

  */

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          minWidth: "200px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(1)": {
          minWidth: "200px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  const getAccountName = () => {
    let acc = _.find(props.accounts, { id: account });
    return acc ? acc.name || "" : "";
  };

  const goToEditPage = (edit) => {
    props._setEdit(edit);
    analytics.accionesTabla({
      accion: "Editar",
      ubicacion: "Presupuestos",
    });
    window.$.fancybox.open({
      src: "#new-budget-modal",
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => props._clearEdit(),
      },
    });
  };

  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return `${date.getDate()} - ${
      months[date.getMonth()]
    } - ${date.getFullYear()}`;
  };

  // console.log("props.budgets antes de...",props.budgets);

  // const [arre, setArre] = useState([])
  // console.log("arre antes de...",arre);
  // useEffect(() => {
  //   setArre(props.budgets);
  // })
  // console.log("arre despues de...",arre);

  const mostrarPadre = (padre, bud) => {
    // console.log("mostrarPadre padre",padre);
    // console.log("mostrarPadre bud",bud);
    let found = bud.find((element) => element.id == padre);
    if (!found) {
      return "";
    } else {
      return found.title;
    }
  };

  // const mostrarPadre2 = (padre) => {
  //   let acc = _.find(props.budgets, {'id': padre})
  //   console.log("acc",acc);
  //   return acc ? (acc.title || '') : ''
  // }

  let budgets = [];

  if (props.currentUser !== "") {
    budgets = _.sortBy(props.budgets, ["createAt"]);
  } else {
    budgets = props.pwaBudgets;
  }

  const handleFilterTextChange = (value) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("value", value);
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Presupuestos",
        busqueda: filterText,
      });
    }
    setFilterText(value);
  };

  const handleShowColumnChange = (column, show) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("column", column);
    props.utils.log("show", show);
    console.log("Column: ", column, "show: ", show);

    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
    }
  };
  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      } else {
        handleShowColumnChange(ele.selector, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };
  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    console.log("id", id);
    if (id) actualizarEstado(id.id, false);
    let body = {
      id: idVIew,
      name: name,
      component: "budgets",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await API.insertView(body);
    actualizarViews(false);
    setIsLoading(false);
  };
  const actualizarEstado = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    setIsLoading(false);
  };
  const handleDeleteView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        setNameView("lista");
        columns.forEach((ele) => {
          handleShowColumnChange(ele.selector, false);
        });
        handleColumnSortChange(orderDefault);
      }
    }
    await API.deleteView({ id: name });
    actualizarViews(false);
    setIsLoading(false);
  };
  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];

    let filteredColumns = columns.filter(function (col) {
      return col.omit === false;
    });

    console.log("columns ", columns);
    console.log("filteredData ", filteredData);
    filteredColumns.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });
    console.log("filteredColumns ", filteredColumns);
    console.log("headers ", headers);
    console.log("selectors ", selectors);
    // dataExcel.push(headers);
    //Este for es para data
    if (!_.isEmpty(filteredData)) {
      if (nameGrouped !== "") {
        let uniqueData = [];
        filteredData?.forEach((element) => {
          if (!uniqueData.includes(element[nameGrouped])) {
            uniqueData.push(element[nameGrouped]);
            dataExcel = [...dataExcel, [], [element[nameGrouped]]];
            dataExcel.push(headers);

            let grouedData = filteredData.filter((item) => {
              if (element[nameGrouped] === item[nameGrouped]) {
                return item;
              }
            });
            console.log("GROUPEDDATA", grouedData);
            if (grouedData.length > 0) {
              grouedData.forEach((grupData) => {
                let dataGrouped = [];
                selectors.forEach((sele) => {
                  let valor = "";
                  if (sele === "createAt" || sele === "updateAt") {
                    valor = formatDate(grupData[sele]);
                  } else if (sele === "status") {
                    valor = StatusName(grupData[sele]);
                  } else {
                    valor = grupData[sele];
                  }
                  console.log("Valor valor", valor);
                  dataGrouped.push(valor);
                });
                dataExcel.push(dataGrouped);
              });
            }
          }
        });
      } else {
        dataExcel.push(headers);
        filteredData?.forEach((element) => {
          excelData = [];
          console.log("element ", element);
          for (let k = 0; k < selectors.length; k++) {
            //  console.log("k " ,k)
            //  console.log("selectors " ,selectors[k])
            let montoPorConsumir = element.amount - element.available;
            excelData.push(
              selectors[k] == "title"
                ? element.title
                : selectors[k] == "createAt"
                ? formatDate(element.createAt)
                : selectors[k] == "updateAt"
                ? formatDate(element.updateAt)
                : selectors[k] == "availableC"
                ? element.availableC
                : selectors[k] == "amount"
                ? element.amount
                : selectors[k] == "available"
                ? element.available
                : selectors[k] == "status"
                ? StatusName(element.status)
                : ""
            );
          }
          dataExcel.push(excelData);
        });
      }
    }

    return dataExcel;
  };
  let pr = [];
  useEffect(() => {
    filteredData.forEach((dat) => {
      dat.availableC = dat.amount - dat.available;
      pr.push(dat);
    });
    setSearchFilterData([]);
  }, []);
  const [first, setfirst] = useState(false);

  const handleColumnSortChange = (positions) => {
    props.utils.log("handleColumnSortChange");
    props.utils.log("positions", positions);
    // console.log('Las positions', positions);
    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      if (index != -1) {
        props.utils.log("Index", index);
        aux[index].position = position;
      }
    });

    aux = _.orderBy(aux, ["position"]);

    props.utils.log("new columns", aux);

    setColumns(aux);
  };
  useEffect(() => {
    setfirst(true);
  }, [first]);
  const [tpe, setTpe] = useState("");
  const handleFilterPerColumn = (value) => {
    let dataToFilterr = [];
    // let sinVacios = value.filter(el => typeof el.value != 'boolean'
    //  && el.value != 'Igual'
    //  && el.value != 'Mayor'
    //   && el.value != 'Menor'
    //   )
    let sinBooleanos = value.filter((el) => typeof el.value != "boolean");
    let sinIgual = sinBooleanos.filter((el) => el.value != "Igual");
    let sinMayor = sinIgual.filter((el) => el.value != "Mayor");
    let sinMenor = sinMayor.filter((el) => el.value != "Menor");
    let sinVacios = sinMenor;
    console.log("Lo que hay en set filters", sinVacios);

    if (sinVacios.length > 0) {
      analytics.filtro({
        ubicacion: "Presupuestos",
      });
      let hola = [];
      //  value.forEach((el)=> {if(el.type == true){
      //   el.type = 'Menor'
      //   hola.push(el)
      //  }})
      // console.log('Lo que hay en set filters', hola);
      setFilterInfo([...sinVacios]);
      dataToFilterr = filteredData;

      if (dataToFilterr.length > 0) {
        let dataToFilter = dataToFilterr;
        let dataFinal = [];
        sinVacios.forEach((el) => {
          if (el.column == "Nombre") {
            let dataAlt = dataToFilterr.filter((ele) =>
              ele.title.toLowerCase().includes(el.value.toLowerCase())
            );
            let para = dataToFilter.filter((ele) =>
              console.log("Para guiarme", ele)
            );
            console.log("La dataAlt", dataAlt);
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "status") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.status === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Presupuesto Total") {
            let type = el.type;
            if (type == "Menor") {
              var dataAlt = dataToFilterr.filter(
                (ele) => Number(ele.amount) < el.value
              );
            }
            if (type == "Igual") {
              var dataAlt = dataToFilterr.filter(
                (ele) => ele.amount === el.value
              );
            }
            if (type == "Mayor") {
              var dataAlt = dataToFilterr.filter(
                (ele) => Number(ele.amount) > el.value
              );
            }

            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Presupuesto Implementado") {
            let type = el.type;
            if (type == "Menor") {
              var dataAlt = dataToFilterr.filter(
                (ele) => Number(ele.availableC) < el.value
              );
            }
            if (type == "Igual") {
              var dataAlt = dataToFilterr.filter(
                (ele) => ele.availableC == Number(el.value)
              );
            }
            if (type == "Mayor") {
              var dataAlt = dataToFilterr.filter(
                (ele) => Number(ele.availableC) > el.value
              );
            }
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Presupuesto Disponible") {
            let type = el.type;
            if (type == "Menor") {
              var dataAlt = dataToFilterr.filter(
                (ele) => ele.available < el.value
              );
            }
            if (type == "Igual") {
              var dataAlt = dataToFilterr.filter(
                (ele) => ele.available == el.value
              );
            }
            if (type == "Mayor") {
              var dataAlt = dataToFilterr.filter(
                (ele) => ele.available > el.value
              );
            }

            // let dataAlt = dataToFilterr.filter(ele => ele.available == el.value)
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          let dataActiva = dataFinal.filter((ele) => ele.active != false);
          console.log("dataToFilter", dataActiva);
          let set = new Set(dataActiva.map(JSON.stringify));
          let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
          setSearchFilterData([...arrSinDuplicaciones]);
          if (arrSinDuplicaciones.length == 0) {
            setVacio(true);
          }
        });
      }
    } else {
      setFilterInfo([]);
      setSearchFilterData([]);
      setVacio(false);
    }
  };

  const handleShareContact = (contact) => {
    props.utils.log("handleShareContact");
    props.utils.log("contact", contact);

    const currentAccountInfo = _.find(props.accounts, { id: account });
    let members = currentAccountInfo.members || [];

    members = members.filter((member) => member !== contact.id);
    props._updateAccountMembers(account, members);
  };
  console.log("budgets ", budgets);
  let filteredData = budgets.filter((item) => {
    item.availableC = item.amount - item.available;
    if (item.title.toLowerCase().includes(filterText.toLowerCase())) {
      return item.title.toLowerCase().includes(filterText.toLowerCase());
    } else if (item.padre.toLowerCase().includes(filterText.toLowerCase())) {
      return item.padre.toLowerCase().includes(filterText.toLowerCase());
    }
  });
  let data =
    searchFilterData.length > 0 ? searchFilterData : vacio ? [] : filteredData;

  if (!_.isEmpty(filters)) {
    filteredData = filteredData.filter((item) => {
      let result = true;

      for (const filterKey in filters) {
        let response;
        let filter = filters[filterKey];
        let dataValue = item[filterKey];

        props.utils.log("key", filterKey);
        props.utils.log("filter", filter);
        props.utils.log("data value", dataValue);

        switch (filter.type) {
          case "EQUALS":
            response =
              `${filter.value}`.toLowerCase() === `${dataValue}`.toLowerCase();
            break;
          case "INLIST":
            response = filter.value[`${dataValue}`];
            break;
          case "GREATERTHAN":
            response = filter.value === -1 || dataValue > filter.value;
            break;
          case "LESSTHAN":
            response = filter.value === -1 || dataValue < filter.value;
            break;
          case "EQUALTHAN":
            response = filter.value === -1 || filter.value === dataValue;
            break;
          default:
            response = `${dataValue}`
              .toLowerCase()
              .includes(`${filter.value}`.toLowerCase());
            break;
        }

        result = result && response;
      }

      return result;
    });
  }

  let myContacts = [];

  props.clients.map((client) => {
    if (
      client.id !== props.currentUser &&
      client.name !== "" &&
      account !== ""
    ) {
      const currentAccountInfo = _.find(props.accounts, { id: account });
      //props.utils.log('currentAccountInfo', currentAccountInfo)

      if (currentAccountInfo) {
        let members = currentAccountInfo.members || [];
        //props.utils.log('members', members)

        if (!members.includes(client.id)) {
          myContacts = [
            ...myContacts,
            {
              id: client.id,
              name: `${client.name} (${client.email})`,
            },
          ];
        }
      }
    }
  });
  const DetailCol = ({ text, className, onClick, permisos, row }) => (
    <div className="content-firstColumn">
      <div className="iconBand">
        {permisos.edit && (
          <Tooltip title="Editar" color="#002448" placement="top">
            <i className="fas fa-edit" onClick={() => onClick(row)}></i>
          </Tooltip>
        )}
        {console.log("Los pemrisos", permisos)}

        {row.availableC === 0 && permisos.delete && (
          <Tooltip color="#002448" placement="top" title="Eliminar">
            <i
              className="fas fa-trash"
              onClick={() => eliminarPresupuesto(row)}
            ></i>
          </Tooltip>
        )}
      </div>
      <p className="name-fc">{text}</p>
    </div>
  );

  // let hola = columns
  // let cols = hola.sort((a,b) => a.name.localeCompare(b.name))
  // console.log('HOLAAA', cols);
  let cols = _.orderBy(columns, ["name"], ["asc", "desc"]);
  let filterTimeout;

  const agrupacionChange = (datos, titulo, idx) => {
    let groupedData = [];

    data.map((ele) => {
      if (nameGrouped == "status") {
        if (StatusName(ele[nameGrouped]) == titulo) {
          groupedData.push(ele);
        }
      }
      if (nameGrouped == "title") {
        if (ele.title == titulo) {
          groupedData.push(ele);
        }
      }
      if (nameGrouped == "createAt" || nameGrouped == "updateAt") {
        if (formatDate(ele[nameGrouped]) == titulo) {
          groupedData.push(ele);
        }
      }
      if (
        nameGrouped == "amount" ||
        nameGrouped == "availableC" ||
        nameGrouped == "available"
      ) {
        if (numberWithCommas(ele[nameGrouped]) == titulo) {
          groupedData.push(ele);
        }
      }
    });
    if (groupedData.length !== 0) {
      groupedData.forEach((ele) => {
        ele.permisos = permissions;
      });
    }
    return (
      <>
        <h1>{titulo}</h1>
        <button onClick={() => setShowModal(!showModal)}>abrir modal</button>
        <ReactDataTable
          className="budgetTable"
          customStyles={customStyles}
          columns={columns}
          data={groupedData}
          persistTableHead
          expandable
          pagination
          noHeader
        />
      </>
    );
  };
  let option1 = [
    "Nombre",
    // 'Fecha de creación',
    // 'Última actualización',
    "Presupuesto total",
    "Presupuesto implementado",
    "Presupuesto disponible",
    // 'Estado'
  ];
  option1.sort();
  option1.splice(0, 0, "Por defecto");
  const [options, setOptions] = useState(option1);

  let names = [];

  const handleGroupedChange = (value) => {
    setIsLoading(true);
    analytics.agrupar({
      ubicacion: "Presupuestos",
    });
    switch (value) {
      case "Nombre":
        setnameGrouped("title");
        option1.splice(0, 0, "Nombre");
        break;
      case "Fecha de creación":
        setnameGrouped("createAt");
        option1.splice(0, 0, "Fecha de creación");
        break;
      case "Última actualización":
        setnameGrouped("updateAt");
        option1.splice(0, 0, "Última actualización");

        break;
      case "Presupuesto total":
        setnameGrouped("amount");
        option1.splice(0, 0, "Presupuesto total");

        break;
      case "Presupuesto implementado":
        setnameGrouped("availableC");
        option1.splice(0, 0, "Presupuesto implementado");

        break;
      case "Presupuesto disponible":
        setnameGrouped("available");
        option1.splice(0, 0, "Presupuesto disponible");

        break;
      case "Estado":
        setnameGrouped("status");
        option1.splice(0, 0, "Estado");

        break;
      default:
        option1.splice(0, 0, "Por defecto");
        setnameGrouped("");
        break;
    }
    let result = option1.filter((item, index) => {
      return option1.indexOf(item) === index;
    });
    setOptions(result);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : isLoadingData ? (
        <PageLoader />
      ) : permissions.view ? (
        <>
          {budgets.length > 0 && (
            <div title="parrafo principal" className="barraSuperior">
              <DataTableUtils
                data={budgets}
                action={
                  permissions.create
                    ? () => {
                        const mCurrentAccount =
                          props.currentUser !== ""
                            ? props.currentAccount
                            : props.currentPWAAccount;

                        if (mCurrentAccount === "") {
                          //props._showSelectAccountModal();
                          // setShowModal(true);
                          return;
                        }
                        analytics.Add({
                          ubicacion: "Presupuestos",
                        });
                        /*window.$.fancybox.open({
                        src: "#new-budget-modal",
                        type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => {},
                        },
                      });*/
                        //setShowModal(true);
                      }
                    : null
                }
                actionIcon={
                  permissions.create && (
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Crear presupuesto 444"
                    >
                      <i className="fas fa-plus" />
                    </Tooltip>
                  )
                }
                onChangeFilterText={(value) => {
                  value.preventDefault();
                  clearTimeout(filterTimeout);
                  filterTimeout = setTimeout(() => {
                    handleFilterTextChange(value);
                  }, 1000);
                }}
              >
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Exportar información"
                >
                  <div
                    onClick={() => {
                      analytics.Compartir({
                        ubicacion: "Presupuestos",
                      });
                    }}
                    className="ExportBtn"
                  >
                    {permissions.export && (
                      // <Share
                      //   data={exportDataToExcel()}
                      //   contacts={myContacts}
                      //   onShareContact={handleShareContact}
                      // />
                      <Share
                        // onShareContact={handleShareContact}
                        // contacts={myContacts}
                        data={exportDataToExcel()}
                        columns={columns}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    )}
                  </div>
                </Tooltip>
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Personalizar columnas"
                >
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columns}
                      showColumn={(data, data1) => {
                        analytics.ShowHide({
                          ubicacion: "Presupuestos",
                        });
                        handleShowColumnChange(data, data1);
                      }}
                      showView={handleShowView}
                      onSortChange={(positions) => {
                        analytics.Columnas({
                          ubicacion: "Presupuestos",
                        });
                        handleColumnSortChange(positions);
                      }}
                      vistas={views}
                      // setVista={handleAddView}
                      // deleteView={handleDeleteView}
                      setVista={(nombre) => {
                        analytics.accionesVista({
                          ubicacion: "Presupuestos",
                          accion: "Agregar",
                        });
                        handleAddView(nombre);
                      }}
                      deleteView={(name) => {
                        analytics.accionesVista({
                          ubicacion: "Presupuestos",
                          accion: "ELiminar",
                        });
                        handleDeleteView(name);
                      }}
                      nameAc={nameView}
                    />
                  </div>
                </Tooltip>
                {/* <FilterColumn
              columns={columns}
              onFilterColumn={handleFilterPerColumn}
            /> */}
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Filtrar y agrupar"
                >
                  <div className="Filtro2 filtro3 ">
                    <FilterColumn2
                      columns={cols}
                      options={options}
                      data={
                        searchFilterData.length > 0
                          ? searchFilterData
                          : vacio
                          ? []
                          : filteredData
                      }
                      changeGrouped={handleGroupedChange}
                      onFilterColumn={handleFilterPerColumn}
                      filterInfoCheck={filterInfo}
                      limpiarData={elim}
                      setLimpiarData={setElim}
                      categories={[]}
                    />
                  </div>
                </Tooltip>
              </DataTableUtils>
            </div>
          )}

          {budgets.length > 0 ? (
            <>
              <div className="Kanbang-content">
                <div className="List-category">
                  {nameGrouped != ""
                    ? data.map((ele, idx) => {
                        if (nameGrouped == "status") {
                          let nombre = StatusName(ele[nameGrouped]);
                          if (!names.includes(nombre)) {
                            names.push(nombre);
                            return agrupacionChange(
                              ele[nameGrouped],
                              nombre,
                              idx
                            );
                          }
                        }
                        if (nameGrouped == "title") {
                          if (!names.includes(ele[nameGrouped])) {
                            names.push(ele[nameGrouped]);
                            return agrupacionChange(
                              ele[nameGrouped],
                              ele[nameGrouped],
                              idx
                            );
                          }
                        }
                        if (
                          nameGrouped == "createAt" ||
                          nameGrouped == "updateAt"
                        ) {
                          let nombre = formatDate(ele[nameGrouped]);
                          if (!names.includes(nombre)) {
                            names.push(nombre);
                            return agrupacionChange(
                              ele[nameGrouped],
                              nombre,
                              idx
                            );
                          }
                        }
                        if (
                          nameGrouped == "amount" ||
                          nameGrouped == "availableC" ||
                          nameGrouped == "available"
                        ) {
                          let nombre = numberWithCommas(ele[nameGrouped]);
                          if (!names.includes(nombre)) {
                            names.push(nombre);
                            return agrupacionChange(
                              ele[nameGrouped],
                              nombre,
                              idx
                            );
                          }
                        }
                        // if(nameGrouped == 'availableC'){

                        // }
                        // if(nameGrouped == 'available'){

                        // }
                      })
                    : defaultView()}
                </div>
              </div>
            </>
          ) : (
            <div className="form homeSinLogin">
              {/* <div className='form-margin'> */}
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea tu primer Presupuesto</h1>
                {permissions.create && (
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        props._showSelectAccountModal();
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Presupuestos",
                      });
                      window.$.fancybox.open({
                        src: "#new-budget-modal",
                        //type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => {},
                        },
                      });
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                )}
              </div>
              <div></div>
              {/* </div> */}
            </div>
          )}
          {showModal && (
            <ModalPresupuesto
              setShowModal={setShowModal}
              showModal={showModal}
            />
          )}
        </>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  account: state.accounts.default,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  allCampaigns: state.allCampaigns,
});

const mapDispatchToProps = (dispatch) => ({
  _clearEdit: () => dispatch(clearEdit()),
  _getClients: (user, idmod, mod) => dispatch(getClients(user, idmod, mod)),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _clearBudgets: () => dispatch(clearBudgets()),
  _clearBudgets: () => dispatch(clearBudgets()),
  _showLoader: (show) => dispatch(showLoader(show)),
  _getBudgets: (account, user, idmod, mod) =>
    dispatch(getBudgets(account, user, idmod, mod)),
  _pwaDeleteBudget: (id) => dispatch(pwaDeleteBudget(id)),
  _getAllCampaigns: (account, user, idmod, mod) =>
    dispatch(getAllCampaigns(account, user, idmod, mod)),
  _getAccountTags: (account, user, idmod, mod) =>
    dispatch(getAccountTags(account, user, idmod, mod)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => {}
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _createBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    createAt,
    edit,
    padre,
    availableAmount,
    idBudget,
    nombrePadre,
    fatherAvailable,
    budgets,
    user,
    idmod,
    mod
  ) =>
    dispatch(
      createBudget(
        id,
        title,
        account,
        amount,
        tags,
        status,
        createAt,
        edit,
        padre,
        availableAmount,
        idBudget,
        nombrePadre,
        fatherAvailable,
        budgets,
        user,
        idmod,
        mod
      )
    ),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Content)));
