import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { v4 } from 'uuid'
import { saveAttributes } from "redux/actions/profile.actions";

import './assets/css/styles.css'

import grupo from './assets/img/grupo.png'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { addBudget } from 'redux/actions/dashboard.actions'
import { createBudget_logout } from 'redux/actions/budgets.actions'
import { createAccount_logout } from 'redux/actions/accounts.actions'
import { createCategory_logout } from 'redux/actions/categories.actions'
import { saveCategory, saveAccount } from 'redux/actions/campana.actions'
import * as API from "helpers/api";
import Swal from "sweetalert2";

import { withUtils } from 'utils'

const TAG = 'ConfirmUserService'

const ConfirmUserService = props => {
  let code = useRef(null)

  const confirmSignUp = async e => {
    e.preventDefault()
    // props._showLoader(true)
    console.log('HOLA', props);
    let atributos = {};
    try{ 
      let body = {
        id: props.authData.username,
        password: props.authData.password
      }
      console.log('EL usuario a guardar', code);
      Auth.forgotPasswordSubmit(props.authData.username, code.current.value, props.authData.pass).then(() => {
        let userSave = API.insertUsersLets(body)
        Auth.signIn(props.authData.username, props.authData.pass).then(() => {
          atributos = {
            'custom:origin': 'true',
            'custom:country': props.authData.countryValue,
            'custom:phone': props.authData.phone,
            'name': props.authData.name,
            'custom:dialCode': props.authData.dialCode
          }
          props._saveAttributes('', atributos);
        })
      })
      

    }catch(err){
      Swal.fire({
        title: "Codigo incorrecto!",
        // text: "Presupuesto eliminado correctamente.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
 
  }



  return(
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">¡Confirma tu Registro!</h1>
          <p className="textos">Correo electr&oacute;nico</p>
          <input type="text" value={props.authData.username} className="formInput" placeholder="Ingresa tu correo electr&oacute;nico" readOnly />
          <p className="textos">C&oacute;digo de Confirmaci&oacute;n</p>
          <input ref={code} className="formInput" type="text" placeholder="Ingresa tu c&oacute;digo" />
          
          <a onClick={confirmSignUp} className="btn-plus-form" href="#">
            CONFIRMAR
          </a>
        </div>
        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  campaign: state.campaign,
})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
  _createAccount_logout: (id, name, username, credit, edit, createAt, members) => dispatch(createAccount_logout(id, name, username, credit, edit, createAt, members)),
  _createBudget_logout: (id, title, account, amount, edit, createAt) => dispatch(createBudget_logout(id, title, account, amount, edit, createAt)),
  _addBudget: (id) => dispatch(addBudget(id)),
  _createCategory_logout: (id, name, account, edit, createAt) => dispatch(createCategory_logout(id, name, account, edit, createAt)),
  _saveCategory: (category) => dispatch(saveCategory(category)),
  _saveAttributes: (username, attributes) => dispatch(saveAttributes(username, attributes)),
  _saveAccount: (account) => dispatch(saveAccount(account)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(ConfirmUserService))
