import React from "react";
import { useSelector } from "react-redux";
import { SimpleText } from "./simpleText";
import { TOTALES } from "../constants";

const CustomCellCountry = ({ row, editCountry }) => {
    const propsEdit = useSelector((state) => state.templates.edit);
    const nameRegionList = useSelector((state) => state.templates.listRegions);
    const dataUser = useSelector((state) => state.templates.userInfo);
    let editCell = !row?.name_region && row.name !== TOTALES ? true : false;

    if (row?.name === TOTALES) return <></>;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {editCell && (
                <i
                    className="fa fa-exclamation-circle text-danger"
                    aria-hidden="true"
                    style={{ marginRight: "8px" }}
                ></i>
            )}
            <SimpleText
                value={row.name_region}
                id={{ id: row.id, campo: "country" }}
                type={"select"}
                editable={true}
                dataSelect={nameRegionList.map(country => country.name)}
                nameColumn={row}
                onEditValue={(value) => editCountry(value, propsEdit, dataUser)}
                editCell={editCell}
            />
        </div>
    );
}

export default CustomCellCountry;