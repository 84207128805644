import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/modal.actions'

const initialState = {
  isShowing: false,
  type: '',
  title: '',
  message: '',
  callback: () => {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SHOW_MODAL:
      return {
        ...state,
        isShowing: true,
        type: action.modal_type,
        title: action.title,
        message: action.message,
        callback: action.callback,
      }
    case Actions.HIDE_MODAL:
      return {
        ...state,
        isShowing: false,
        type: '',
        title: '',
        message: '',
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
