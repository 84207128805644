import { showLoader } from "./loader.actions";
import * as APILA from "helpers/apiLA";
export const ACCOUNT_INFO = "DIGITAL_INFO:ADD:ONE";

export const getInformationCurrentAccount =
  ({ account }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await APILA.getCurrentClient({ account });
      dispatch(listInformationAccount(response.data.client.client));
    } catch (error) {
      console.log("ERROR OBETENER INFORMACION DE LA CUENTA", { error });
    } finally {
      dispatch(showLoader(false));
    }
  };

export const updateInformationCurrentAccount =
  ({ name, phone, address, email, account }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const bodyDynamo = {
        name,
        phone,
        address,
        email,
        id: account,
      };
      const response = await APILA.getCurrentIdEadvertising({ account });
      console.log("response", { response, bodyDynamo });
      const bodyEadvertising = {
        name,
        phone,
        address,
        email,
        id_client: response.data.eAdvertising,
      };
      await Promise.all([
        APILA.updateInformationAccount(bodyDynamo),
        APILA.updateInformationAccountEadvertising(bodyEadvertising),
      ]);
    } catch (error) {
      console.log("ERROR ACTUALIZAR INFORMACION DE LA CUENTA", { error });
    } finally {
      dispatch(showLoader(false));
    }
  };

export const listInformationAccount = (list) => ({
  type: ACCOUNT_INFO,
  list,
});
