export const SAVE_NAME = 'CAMPAIGN:NAME'
export const SAVE_SOCIAL = 'CAMPAIGN:SOCIAL'
export const SAVE_DATES = 'CAMPAIGN:DATES'
export const SAVE_OBJETIVE = 'CAMPAIGN:OBJETIVE'
export const SAVE_TAGS = 'CAMPAIGN:TAGS'
export const SAVE_STEP = 'CAMPAIGN:STEP'
export const SAVE_BUDGET = 'CAMPAIGN:BUDGET'
export const SAVE_CATEGORY = 'CAMPAIGN:CATEGORY'
export const SAVE_PRODUCT = 'CAMPAIGN:PRODUCT'
export const SAVE_ID_MEDIUM = 'CAMPAIGN:IDMEDIUM'
export const SAVE_MEDIUM = 'CAMPAIGN:MEDIUMDESCRIP'
export const CLEAN_CAMPAIGN = 'CAMPAIGN:CLEAN'
export const CLEAN_CAMPAIGN2 = 'CAMPAIGN:CLEAN2'
export const SAVE_ACCOUNT = 'CAMPAIGN:ACCOUNT'
export const SAVE_PUBLIC_NAME = 'CAMPAIGN:SAVE_PUBLICNAME'
export const SAVE_COUNTRY = 'CAMPAIGN:SAVE_COUNTRY'
export const SAVE_OBSERVATIONS = 'CAMPAIGN:SAVE_OBSERVATIONS'
export const SAVE_COPIES = 'CAMPAIGN:SAVE_COPIES'
export const SAVE_AWSUSER = 'CAMPAIGN:SAVE_AWSUSER'
export const SAVE_EMAIL = 'CAMPAIGN:SAVE_EMAIL'
export const SAVE_EMAILNAME = 'CAMPAIGN:SAVE_EMAILNAME'
export const SAVE_ACCOUNT_NAME = 'CAMPAIGN:SAVE_ACCOUNT_NAME'
export const CLEAR_INITIALSTATE = "CAMPAIGN:CLEAR_INITIALSTATE";
export const NEXT_STEP_LOGOUT = "CAMPAIGN:NEXT_STEP_LOGOUT"; // wizard
export const NEXT_STEP_LOGOUT_COPY = "CAMPAIGN:NEXT_STEP_LOGOUT_COPY"; // VALIDA SI SE GUARDAN DATOS
export const NEXT_STEP_LOGOUT_WITHOUT_ACC = "CAMPAIGN:NEXT_STEP_LOGOUT_WITHOUT_acc"; // VALIDA SI SE GUARDAN DATOS


export const saveCorreo = (correo) => (dispatch) => {
  dispatch({
    type: SAVE_EMAIL,
    correo,
  })
}
export const saveNameCorreo = (correo) => (dispatch) => {
  dispatch({
    type: SAVE_EMAILNAME,
    correo,
  })
}
export const saveMedium = (medium) => (dispatch) => {
  dispatch({
    type: SAVE_MEDIUM,
    medium,
  })
}

export const saveIdmedium = (idmedium) => (dispatch) => {
  dispatch({
    type: SAVE_ID_MEDIUM,
    idmedium,
  })
}

export const saveProduct = (product) => (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT,
    product,
  })
}

export const saveName = (name) => (dispatch) => {
  dispatch({
    type: SAVE_NAME,
    name,
  })
}

export const saveSocial = (redSocial) => (dispatch) => {
  dispatch({
    type: SAVE_SOCIAL,
    redSocial,
  })
}

export const saveObservations = (obs) => (dispatch) => {
  dispatch({
    type: SAVE_OBSERVATIONS,
    obs,
  })
}

export const saveCopies = (copy) => (dispatch) => {
  dispatch({
    type: SAVE_COPIES,
    copy,
  })
}
export const saveNameA = (nameA) => (dispatch) => {
  dispatch({
    type: SAVE_ACCOUNT_NAME,
    nameA,
  })
}


export const saveCountry = (country) => (dispatch) => {
  dispatch({
    type: SAVE_COUNTRY,
    country,
  })
}

export const saveUser = (user) => (dispatch) => {
  dispatch({
    type: SAVE_AWSUSER,
    user,
  })
}

export const saveDate = (iniDate, endDate) => (dispatch) => {
  dispatch({
    type: SAVE_DATES,
    iniDate,
    endDate,
  })
}

export const saveObjet = (objetivo) => (dispatch) => {
  dispatch({
    type: SAVE_OBJETIVE,
    objetivo,
  })
}

export const saveTag = (tags) => (dispatch) => {
  dispatch({
    type: SAVE_TAGS,
    tags,
  })
}

export const saveStep = (step) => (dispatch) => {
  dispatch({
    type: SAVE_STEP,
    step,
  })
}

export const saveBudget = (budget) => (dispatch) => {
  dispatch({
    type: SAVE_BUDGET,
    budget,
  })
}

export const saveCategory = (category) => (dispatch) => {
  dispatch({
    type: SAVE_CATEGORY,
    category: category,
  })
}

export const cleanCampaign = () => (dispatch) => {
  dispatch({
    type: CLEAN_CAMPAIGN,
  })
}

export const cleanCampaign2 = () => (dispatch) => {
  dispatch({
    type: CLEAN_CAMPAIGN2,
  })
}

export const saveAccount = (account) => (dispatch) => {
  dispatch({
    type: SAVE_ACCOUNT,
    account,
  })
}

export const savePublicName = (publicName) => (dispatch) => {
  dispatch({
    type: SAVE_PUBLIC_NAME,
    public: publicName,
  })
}

export const copyNextStep = (stepCamLogOutCopy) => ({
  type: NEXT_STEP_LOGOUT_COPY,
  stepCamLogOutCopy,
})

export const clearState = () => ({
  type: CLEAR_INITIALSTATE,
});

export const nextStepLogout = (stepCamLogOut) => ({
  type: NEXT_STEP_LOGOUT,
  stepCamLogOut,
})
