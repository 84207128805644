import React, { useEffect } from "react";
import { connect } from "react-redux";
import FinCamp from "../flowLogOutUser/finCamp";
import PageHome from "../flowLogOutUser/pageHome";
import TagsCamp from "../flowLogOutUser/tags";
import FechasCamp from "../flowLogOutUser/fechas";
import BudgetCamp from "../flowLogOutUser/budgets";
import MedioCamp from "./medio";
import Dashboard from "../dashboard";
import InfoCamp from "../infoCamp";
import ObservationCamp from "../flowLogOutUser/observation";
import PagoCamp from "../flowLogOutUser/card";
import { clearState } from "redux/actions/campana.actions";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";

const returnStep = (step) => {
    if (step) {
        switch (step) {
            case 14:
                return <PageHome clase="component" />;
            case 15:
                return <FechasCamp clase="component" />;
            case 16:
                return <MedioCamp clase="component" />;
            case 17:
                return <BudgetCamp />;
            case 18:
                return <TagsCamp clase="component" />;
            case 19:
                return <FinCamp clase="component" />;
            case 20:
                return <ObservationCamp />;
            case 22:
                return <PagoCamp />;
            case 23:
                return <Dashboard clase="component" />;
            default:
                return <PageHome clase="component" />;
        }
    } else {
        return <PageHome clase="component" />;
    }
};

const NewFlowCampaign = (props) => {
    useEffect(() => {
        if (!window.location.pathname.includes("/campañas")) {
            props._nextStepLogoutUser(14);
            props._clearStateSteps();
        }
    }, [window.location.pathname]);
    return (
        <div
            div
            className={
                props.campaign.stepLogout === 22
                    ? "contenedorCrearCampSinReg__"
                    : "contenedorCrearCampSinReg"
            }
        >
            <div className="form-margin campaign__logout_steps">
                <div className="left-form">{returnStep(props.campaign.stepLogout)}</div>
                {props.campaign.stepLogout !== 14 && <InfoCamp />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
});

const mapDispatchToProps = (dispatch) => ({
    _clearStateSteps: () => dispatch(clearState()),
    _nextStepLogoutUser: (step) => dispatch(nextStepLogoutUser(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewFlowCampaign);
