import React from "react";
import { SimpleText } from "./simpleText";
import { useSelector } from "react-redux";
import { TOTALES } from "../constants";

const CustomCellMedio = ({ row, editMediumData }) => {
    const propsEdit = useSelector((state) => state.templates.edit);
    const mediumBanner = useSelector((state) => state.templates.listMediumBanner);
    let optionsBanner = mediumBanner.length > 0 ? mediumBanner : row?.optionsBanner;
    const dataUser = useSelector((state) => state.templates.userInfo);
    const validateError = !row?.medio && row.name !== TOTALES ? true : false

    if (row?.name === TOTALES) return <></>;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {validateError && (
                <i
                    className="fa fa-exclamation-circle text-danger"
                    aria-hidden="true" s
                    style={{ marginRight: "8px" }}
                ></i>
            )}
            <SimpleText
                value={row.medio || ""}
                id={{ id: row.id, campo: "medio" }}
                type={"select"}
                editable={true}
                editCell={validateError}
                dataSelect={
                    row?.optionsBanner?.map((medium) => medium.site)
                    || []
                }
                nameColumn={row}
                onEditValue={(value) => editMediumData(value, propsEdit, optionsBanner, dataUser)}
            />
        </div>
    );
}

export default CustomCellMedio;