import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import InfoCamp from '../infoCamp'

import { saveName, updatePauta } from 'redux/actions/objectCampain.actions'

const ObjectCampain = (props) => {
  let key = () => {
    let input = document.getElementById('btnNameCamp')
    input.style.borderBottom = '1px solid gray'
    input.classList.remove('require')
    input.placeholder = 'P. ejemplo campaña de día de la madre'
  }

  let closeModal = () => {
    let shadow = document.getElementById('shadow')
    let modal = document.getElementById('modalNewCamp')
    shadow.classList.toggle('hide')
    modal.classList.toggle('hide')
  }

  let saveName = () => {
    let name = document.getElementById('btnNameCamp')
    let conTitle = document.getElementById('Contitulo')
    let tituloTxt = document.getElementById('titulo')

    let content = document.getElementById('ConMedio')

    if (name.value.trim().length > 0) {
      tituloTxt.textContent = name.value
      tituloTxt.classList.add('insert')
      conTitle.classList.remove('ene-view')
      content.classList.add('ene-view')
      if (props.pauta != null && props.pauta.length > 0) {
        props._updatePauta(props.pauta[0].ide, name.value, 1)
      } else {
        props._saveName(name.value)
      }

      closeModal()
    } else {
      name.style.borderBottom = '1px solid red'
      name.placeholder = 'Este texto es requerido'
      name.classList.add('require')
    }
  }

  var nomb = ''

  if (props.pauta != null && props.pauta.length > 0) {
    nomb = props.pauta[0].namee
  }

  return (
    <div className='form'>
      <div className='form-margin'>
        <div className='left-form'>
          <div className='component'>
            <h1 className='title'>
              Ingresa el nombre del objetivo de la pauta
            </h1>
            <input
              className='input'
              id='btnNameCamp'
              type='text'
              onKeyDown={key}
              placeholder='P. ejemplo familia'
              defaultValue={nomb}
            ></input>
            <div className='rigth-btn'>
              <a className='btn-camp' onClick={saveName} href='#' id='nextName'>
                siguiente
              </a>
            </div>
          </div>
        </div>
        <InfoCamp />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  pauta: state.pauta,
})

const mapDispatchToProps = (dispatch) => ({
  _saveName: (name) => dispatch(saveName(name)),
  _updatePauta: (id, name, status) => dispatch(updatePauta(id, name, status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ObjectCampain))