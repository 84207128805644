import { v4 } from 'uuid'
import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'


export const ADD_LOGS = 'LOGS:ADD'
export const CLEAN_LOGS = 'LOGS:CLEAN'
export const TOTAL_LOGS = 'LOGS_TOTAL'

const TAG = 'LOGS:Actions'



export const cleanLogs = () => (dispatch) => {
  dispatch({
    type: CLEAN_LOGS,
  })
}

export const getLogs = (user) => dispatch => {
    dispatch(cleanLogs());
    
    console.log("getLogs action user",user);   
    const body={
      "user":user
    }
     APILA.getLogLine(body)
      .then(response => {
        Logger.log(TAG, 'getLogs response', response)
        let { logs,count } = response.data    
         dispatch(addTotalLogs(count))
         dispatch(addLog(logs)) 
      })
      .catch(err => {
        Logger.log(TAG, 'error getLogs', err)
      })
      // .then(() => {        
      //   Logger.log(TAG, 'error getBudgetSpent then')

      // })
  }


  const addTotalLogs = total => {
    
    return dispatch => {
      dispatch({
        type: TOTAL_LOGS,
        total
      })
    }
  }
  
  const addLog = log => {
    
    return dispatch => {
      dispatch({
        type: ADD_LOGS,
        log
      })
    }
  }
  