import * as Actions from '../actions/fanpageWebHook.actions'

const initialState = {
  info: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FB_PROP:
      return {
        ...state,
        [action.prop.name]: action.prop.value,
      }
    case Actions.FB_DATOS:
      return {
        ...state,
        info: action.info,
      }
    default:
      return state
  }
}