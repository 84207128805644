import React from 'react'
import grupo from "./assets/img/grupo.png";
import { connect } from 'react-redux'

import { withUtils } from 'utils'
import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { withRouter } from 'react-router';
import { useHistory } from "react-router-dom";

const TermsAndConditions = (props) => {
    let history = useHistory();

    const goToSignIn = (e) => {
        e.preventDefault()
        console.log('Las props terms', props);
        if(props.onStateChange){
            props.onStateChange('signUp', {})
        }else{ 
            history.push("/campañas");

        }
    }


    return (
        <div className="form-login">
            <div className="form-margin-login">
                <div className="lado">
                    <h1 className="title-form">¡Términos y condiciones!</h1>
                    <div className="w-80 d-flex flex-column align-items-center textos">
                        Cuando utilizas nuestra plataforma e inicias sesión con tu perfil de Facebook,
                        Google, o a través de usuario y contraseña, recolectamos información de tu
                        cuenta como el nombre, correo, número de teléfono, correo electrónico.
                    </div>


                    <div className="redes" style={{ marginTop: '10px' }}>
                        <a className="w-100 formatTerms" onClick={goToSignIn} href="#">
                            Regresar
                        </a>
                    </div>
                </div>

                <div className="lado">
                    <img src={grupo} className="people" alt="" />
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(TermsAndConditions)))

