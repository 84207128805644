import React, { useEffect, useState } from 'react';
import { descifrar } from "helpers/encrypt";
import { connect } from "react-redux";
import * as APILA from "helpers/apiLA";
import { find } from "lodash";

function Livechat(props) {
    const [user, setUser] = useState({})
    const getUser = async () => {
        const responseClients = await APILA.getAllAccountsAWS();
        const { clients } = responseClients.data;
        const clientsArr = clients.map((client_response) => {
            const nameAttr = find(client_response.Attributes, { Name: "name" });
            const emailAttr = find(client_response.Attributes, { Name: "email" });
            const phoneNumber = find(client_response.Attributes, { Name: "custom:phone" });

            const nameValue = nameAttr ? nameAttr.Value : "";
            const emailValue = emailAttr ? emailAttr.Value : "";
            const format = nameValue ? `${nameValue} (${emailValue})` : emailValue;
            const phoneNumberValue = phoneNumber ? phoneNumber.Value : "";

            return {
                id: client_response.Username,
                name: nameValue,
                phoneNumber: phoneNumberValue,
                email: emailValue,
                format: format,
            };
        });
        const usuario = clientsArr.find(ele => ele.id === props.currentUser);
        (function (d, t) {
            var BASE_URL = "https://chatme.culturaguatemala.com";
            var g = d.createElement(t);
            var s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);

            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: '3G3zAY9DK5tUwZK8rKvreQiM',
                    baseUrl: BASE_URL
                });

                window.addEventListener('chatwoot:ready', function () {
                    window.$chatwoot.setUser(usuario?.id, {
                        email: usuario?.email,
                        name: usuario?.name,
                        avatar_url: "",
                        phone_number: `+502${usuario?.phoneNumberValue}`,
                        company_name: props?.currentAccount
                    });
                })
            };
        })(document, "script");
    }
    useEffect(() => {
        if (props.currentUser !== '' && props.currentAccount !== '') {
            getUser();
        }
    }, [props.currentUser, props.currentAccount]);

    return null;
}
const mapStateToProps = (state) => ({
    clients: state.clients,
    accounts: state.accounts.list,
    currentUser: state.app.current_user,
    pwaAccounts: state.pwa.accounts.list,
    currentAccount: state.accounts.default,
    currentPWAAccount: state.pwa.accounts.default,
    permisos: state.permisos.current_permissions
});

const mapDispatchToProps = (dispatch) => ({
    _descrifrar: (texto, password) => dispatch(descifrar(texto, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Livechat)