import React from 'react'

// components
import MetaTags from '../../components/metatags'
import ConfirmSignUpForm from '../../components/auth/confirm_signup_form'

const ConfirmSignUp = props => (
  <>
    <MetaTags title='Confirmar Registro' description='Página de confirmación de registro' />

    <ConfirmSignUpForm {...props} />
  </>
)

export default ConfirmSignUp
