import * as Actions from "../actions/odooOvs.actions";

const initialState = {
  isLoading: false,
  currentLevel: 0,
  portal_name: "lets-advertise",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.APP_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
