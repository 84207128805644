import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import ReactDataTable from "@easygosanet/react-datatable";
import { getBudgets, setEdit } from "redux/actions/budgets.actions";
import DataTableUtils from "@easygosanet/react-datatable-utils";
import * as APILA from "helpers/api";
import * as API from "helpers/apiLA";
import Swal from "sweetalert2";
import analytics from "../../../helpers/analytics";
import {
  createBudget,
  updateBudget,
  createBudgetOvs,
} from "redux/actions/budgets.actions";
import { pwaUpsertBudget } from "redux/actions/pwa.actions";
import CurrencyInput from "react-currency-input-field";
import "./style.scss";
import { Auth } from "aws-amplify";
import PageLoader from "components/page_loader";
import { showLoader } from "redux/actions/loader.actions";
const idmod = 3;
const mod = "PRESUPUESTOS";

const UpsertBudget = (props) => {
  const [dataLovs, setDataLovs] = useState([]);
  const [update, setUpdate] = useState(false);
  const [suma, setSuma] = useState(0);
  const [budgetOld, setBudgetOld] = useState(props.edit?.budgetOld);
  const [filterText, setFilterText] = useState("");
  const [available, setAvailable] = useState(0);
  const [tablaTemporal, setTablaTemporal] = useState([]);
  const [tablaTemporal2, setTablaTemporal2] = useState([]);
  const [data2, setData2] = useState([]);
  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({
    nameBudget: false,
    amount: false,
    errorBudgetMessage: "",
  });
  const [test, setTest] = useState([]);
  const [selectAll, setSelectAll] = useState({
    table1: false,
    table2: false,
  });
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({});
  const initialState = {
    id: "",
    name: "",
    amount: 0,
    budgetOld: false,
    edit: false,
    createAt: 0,
    available: 0,
    reserveForLovs: [],
  };
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const [data, setData] = useState([]);
  const [dataTotalTable, setDataTotalTable] = useState([
    {
      presupuestoTotal: Number(0).toFixed(2),
      totalConsumido: Number(0).toFixed(2),
      totalReservado: Number(0).toFixed(2),
      totalDisponible: Number(0).toFixed(2),
    },
  ]);

  // ! Creacion

  useEffect(() => {
    if (_.isEmpty(props.edit)) {
      getAllLovs();
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(props.edit)) {
      handleSuma();
    }
  }, [input, suma]);

  useEffect(() => {
    if (_.isEmpty(props.edit)) {
      let amount = data2.reduce(
        (total, value) => total + Number(value.reserve),
        0
      );
      setState((prev) => ({
        ...prev,
        budgetOld: false,
        amount: Number(amount),
        available: Number(amount),
        // reserveForLovs: data2,
      }));
      setState((prev) => ({
        ...prev,
        reserveForLovs: data2.map((item) => ({
          order_line_id_v6: item.order_line_id_v6,
          idsBudget: item.idsBudget,
          idsCampaign: item.idsCampaign,
          reserve: item.reserve,
          id: item.id,
        })),
      }));
    }
  }, [data2]);

  // ? Sumatoria de reservado de las ovs
  const handleSuma = (e) => {
    let su = Object.values(input).reduce(
      (total, value) => total + Number(value),
      0
    );
    setSuma(su);
    let sumatoria = data2.reduce(
      (total, value) => total + Number(value.reserve),
      0
    );
    setDataTotalTable([
      {
        presupuestoTotal: Number(sumatoria).toFixed(2),
        totalConsumido: Number(0).toFixed(2),
        totalReservado: Number(0).toFixed(2),
        totalDisponible: Number(0).toFixed(2),
      },
    ]);
  };

  const getAllLovs = async () => {
    try {
      setLoading(true);
      const response = await APILA.getAllLovs({
        account: props.currentAccount,
      });
      setLoading(false);
      let lovs = response.data.data;
      let budgetAvailbaleCorrect = lovs.filter((item) => {
        return item.budgetAvailable > 0;
      });
      let filterOvsOld = budgetAvailbaleCorrect.filter((ov) => !ov?.ovOld);
      setData(filterOvsOld);
      setDataLovs(lovs);
    } catch (err) {
      console.log("Error en getAllLovs", err);
    } finally {
      setLoading(false);
    }
  };

  const areValidateInputs = () => {
    let error = false;
    const reservedForLovs = _.map(state.reserveForLovs, "reserve");

    const invalidoReserve = reservedForLovs.some(
      (num) => num === undefined || num === null || num === "0" || num === 0
    ); //Valida que ninguna ov seleccionada tenga valores invalidos seleccionadois

    const errorsModal = {
      invalidData2: {
        title: "Ups!",
        text: "No se puede crear un presupuesto sin líneas de orden de venta.",
      },
      invalid: {
        title: "Error",
        text: "Lo reservado de la línea de orden de venta no puede ser cero ó vacío.",
      },
      nameBudget: {
        title: "Error",
        text: "El nombre del presupuesto es requerido.",
      },
    };

    if (data2.length === 0 || invalidoReserve) {
      const errorType = data.length === 0 ? "invalidData2" : "invalid";
      error = true;
      showError(errorsModal[errorType]);
    } else if (state.name === "") {
      error = true;
      showError(errorsModal.nameBudget);
    }
    return error;
  };

  const onSaveModalCreate = async (e) => {
    try {
      e.preventDefault();

      if (areValidateInputs()) return;
      const userEmail = await Auth.currentAuthenticatedUser();

      const email = userEmail.attributes.email;

      await props._createBudgetOvs(
        props.currentAccount,
        state.name,
        state.amount,
        data2,
        email,
        dataLovs
      );
      closeModal();
    } catch (error) {}
  };

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      setUpdate(true);
      calculateTotals();
      let newAmount = data2.reduce(
        (total, value) => parseFloat(total) + parseFloat(value.reserve),
        0
      );
      // props.edit.budgetOld === false ---> el presupuesto no es antiguo
      if (!props.edit?.budgetOld) {
        let newState = {
          edit: true,
          id: props.edit.id,
          name: props.edit.title,
          budgetOld: props.edit.budgetOld,
          amount: parseFloat(newAmount),
          createAt: props.edit.createAt,
          padre: props.edit.padre,
          budgetOld: false,
          nombrePadre: props.edit.nombrePadre,
          fatherAvailable: props.fatherAvailable,
          available: parseFloat(newAmount) - parseFloat(props.edit.availableC),
          reserveForLovs: data2?.map((item) => ({
            order_line_id_v6: item.order_line_id_v6,
            budgetAvailable: item.budgetAvailable,
            idsBudget: item.idsBudget,
            idsCampaign: item.idsCampaign,
            orderNumber: item.orderNumber,
            reserve: item.reserve,
            id: item.id,
          })),
        };
        setState((prev) => ({
          ...prev,
          ...newState,
        }));
      } else {
        //Para cuadrar el presupuesto donde el reservado sea igual al amunt que ya tenia
        let newState = {
          edit: true,
          id: props.edit.id,
          name: props.edit.title,
          budgetOld: false,
          amount: parseFloat(props.edit.amount),
          createAt: props.edit.createAt,
          padre: props.edit.padre,
          nombrePadre: props.edit.nombrePadre,
          fatherAvailable: props.fatherAvailable,
          available: parseFloat(props.edit.available),
          reserveForLovs: data2?.map((item) => ({
            order_line_id_v6: item.order_line_id_v6,
            budgetAvailable: item.budgetAvailable,
            idsBudget: item.idsBudget,
            idsCampaign: item.idsCampaign,
            orderNumber: item.orderNumber,
            reserve: item.reserve,
            id: item.id,
          })),
        };
        setState((prev) => ({
          ...prev,
          ...newState,
        }));
      }
    }
  }, [
    inputValue,
    props.edit,
    props.edit.reserveForLovs,
    props.edit.availableC,
    props.edit.amount,
    data2,
  ]);

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      getAllAllovs();
      getLovsReal();
    }
    return () => {
      props._getBudgets(
        props.currentAccount,
        props.currentUser,
        3,
        "PRESUPUESTOS"
      );
      setState(initialState);
      props._setEdit({});
    };
  }, [props.edit]);

  // Trae el conusmido que viene de mysql -> Total consumido
  const budgetComsumed = async () => {
    let idBudget = props.edit.id;
    let sumatoria = 0;
    const account = props.accounts.find(
      (ele) => ele.id === props.currentAccount
    );
    let budget = await APILA.getCountBudgets({ idBudget: idBudget });
    for (let ele of budget.data.data) {
      const newMix = await API.findCampaignByMixDetail({
        idMixDetaill: ele.idMixDetaill,
        idclient: account.eAdvertising,
      });
      let mix = newMix.data.campaigns[0]?.gastado_con_comision;
      sumatoria += mix ?? 0;
    }
    return sumatoria;
  };

  // Las campa;as son lo que se invierte -> Total implementado
  const calculateTotals = () => {
    setDataTotalTable((prev) => [
      {
        ...prev[0],
        totalReservado: Number(props.edit.availableC).toFixed(2),
      },
    ]);
  };

  const getLovsReal = async () => {
    try {
      const response = await APILA.getAllLovs({
        account: props.currentAccount,
      });
      let lovs = response.data.data;
      let lovsConflic = response.data.lovsConflict;
      setTest([...lovs, ...lovsConflic]);
    } catch (err) {
      console.log("Error al traer las lovs", err);
    }
  };

  const getAllAllovs = async () => {
    try {
      setLoading(true);
      calculateTotals();
      let budgetComision = await budgetComsumed();
      const response = await APILA.getAllLovs({
        account: props.currentAccount,
      });

      setLoading(false);
      let lovs = response.data.data;
      let lovsConflic = response.data.lovsConflict;
      let mix = lovs.concat(lovsConflic);

      let actual = props.edit.reserveForLovs.map(
        (item) => item.order_line_id_v6
      );

      let actualAndUniques = lovs.filter(
        (lov) =>
          !actual.includes(lov.order_line_id_v6) && lov.budgetAvailable > 0
      );

      let uniqueLines = mix.filter(
        (item) => !actual.includes(item.order_line_id_v6)
      );
      let duplicate = mix.filter((item) =>
        actual.includes(item.order_line_id_v6)
      );

      let duplicateConflicts = lovsConflic.filter((item) =>
        actual.includes(item.order_line_id_v6)
      );

      let budgetAvailbaleCorrect = uniqueLines.filter((item) => {
        return item.budgetAvailable > 0 && item.flagError !== "active";
      });

      let filteredOvsOld = actualAndUniques.filter((ov) => !ov?.ovOld);
      setData(filteredOvsOld);

      duplicate.forEach((item) => {
        let found = props.edit.reserveForLovs.find(
          (ele) => ele.order_line_id_v6 === item.order_line_id_v6
        );
        if (found) {
          let originalReserve = item.reserve;
          item.reserve = found.reserve;
          // item.reserve = originalReserve;
        }
      });
      // setData2(duplicateConflicts);
      setData2(duplicate);
      // setData2(duplicateConflicts)
      let result = props.edit.reserveForLovs.reduce(
        (total, value) => total + Number(value.reserve),
        0
      );

      setDataTotalTable((prev) => [
        {
          ...prev[0],
          presupuestoTotal: Number(props.edit.amount).toFixed(2),
          totalConsumido: Number(budgetComision).toFixed(2),
        },
      ]);
    } catch (err) {
      try {
        setLoading(true);
        let budgetComision = await budgetComsumed();
        let response2 = await APILA.getAllLovs({
          account: props.currentAccount,
        });
        setDataTotalTable((prev) => [
          {
            ...prev[0],
            presupuestoTotal: Number(props.edit.amount).toFixed(2),
            totalConsumido: Number(budgetComision).toFixed(2),
          },
        ]);
        const lovs2 = response2.data.data;
        let lovsCorrect = lovs2.filter(
          (item) => Number(item.budgetAvailable).toFixed(2) > 0.0
        );
        setData(lovsCorrect);
      } catch (err) {
        console.log("Error en los servicios", err);
      } finally {
        setLoading(false);
        if (budgetOld) {
          Swal.fire({
            title: "Información",
            text: "Actualmente el total del presupuesto no pertenece a ninguna línea de orden de venta, por lo que deberás ajustar el presupuesto manualmente.",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        }
      }
    }
  };

  const handleOnChangeCheckedTable1 = (row, e) => {
    e.persist();
    let { checked } = e.target;
    if (row === "all") {
      let dataActive = [...data];
      setSelectAll((prev) => ({ ...prev, table1: checked }));
      dataActive.forEach((ele) => (ele.checked = checked));
      setData(dataActive);
      setTablaTemporal(dataActive);
    } else {
      let newSelected = {
        ...row,
        checked: checked,
      };
      setData((prev) => {
        let upsertData = [...prev];
        let idx = upsertData.findIndex(
          (ele) => ele.order_line_id_v6 === newSelected.order_line_id_v6
        );
        upsertData[idx] = { ...upsertData[idx], checked: checked };
        return upsertData;
      });

      setTablaTemporal((prev) => {
        let upsertData = [...prev];
        if (!checked) {
          upsertData = upsertData.filter(
            (ele) => ele.order_line_id_v6 !== row.order_line_id_v6
          );
        } else {
          upsertData = [...upsertData, newSelected];
        }
        return upsertData;
      });
    }
  };

  const handleOnChangeCheckedTable2 = (row, e) => {
    e.persist();
    let { checked } = e.target;
    if (row === "all") {
      let dataActive = [...data2];
      setSelectAll((prev) => ({ ...prev, table2: checked }));
      dataActive.forEach((ele) => {
        if (!ele?.ovOld) {
          ele.checked = checked;
        } else {
          ele.checked = false;
        }
      });
      setData2(dataActive);
      let filteredDataBlockedOvs = dataActive.filter((ov) => !ov?.ovOld);
      // la tabla temporal2 se usa para guardar los datos seleccionados
      setTablaTemporal2(filteredDataBlockedOvs);
    } else {
      let newSelected = {
        ...row,
        checked: checked,
      };
      setData2((prev) => {
        let upsertData = [...prev];
        let idx = upsertData.findIndex(
          (ele) => ele.order_line_id_v6 === newSelected.order_line_id_v6
        );
        upsertData[idx] = { ...upsertData[idx], checked: checked };
        return upsertData;
      });

      setTablaTemporal2((prev) => {
        let upsertData = [...prev];
        if (!checked) {
          upsertData = upsertData.filter(
            (ele) => ele.order_line_id_v6 !== row.order_line_id_v6
          );
        } else {
          upsertData = [...upsertData, newSelected];
        }
        return upsertData;
      });
    }
  };

  function numberWithCommas1(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columnsUno = [
    {
      omit: false,
      name: (
        <input
          type="checkbox"
          checked={selectAll.table1}
          onChange={(e) => handleOnChangeCheckedTable1("all", e)}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.checked}
          onChange={(e) => handleOnChangeCheckedTable1(row, e)}
        />
      ),
    },
    {
      omit: false,
      name: "No. Orden",
      selector: "orderNumber",
      sortable: true,
      type: "text",
      id: "orderNumber",
      tag: "orderNumber",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderNumber,
    },
    {
      omit: false,
      name: "LOV",
      selector: "lov",
      sortable: true,
      type: "text",
      id: "lov",
      tag: "lov",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.order_line_id_v6,
    },
    {
      omit: false,
      name: "Disponible",
      selector: "disponible",
      sortable: true,
      type: "text",
      id: "disponible",
      tag: "disponible",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${numberWithCommas1(row.budgetAvailable)}`,
    },
    {
      omit: false,
      name: "Cliente",
      selector: "cliente",
      sortable: true,
      type: "text",
      id: "cliente",
      tag: "cliente",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderReference,
    },
  ];

  const columns2 = [
    {
      omit: false,
      name: (
        <input
          type="checkbox"
          checked={selectAll.table2}
          onChange={(e) => handleOnChangeCheckedTable2("all", e)}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.checked}
          onChange={(e) => handleOnChangeCheckedTable2(row, e)}
        />
      ),
    },
    {
      omit: false,
      name: "No. Orden",
      selector: "orderNumber",
      sortable: true,
      type: "text",
      id: "orderNumber",
      tag: "orderNumber",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderNumber,
    },
    {
      omit: false,
      name: "LOV",
      selector: "lov",
      sortable: true,
      type: "text",
      id: "lov",
      tag: "lov",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.order_line_id_v6,
    },
    {
      omit: false,
      name: "Disponible",
      selector: "disponible",
      sortable: true,
      type: "text",
      id: "disponible",
      tag: "disponible",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${numberWithCommas1(row.budgetAvailable)}`,
    },
    {
      omit: false,
      name: "Cliente",
      selector: "cliente",
      sortable: true,
      type: "text",
      id: "cliente",
      tag: "cliente",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderReference,
    },
    {
      omit: false,
      name: "Reservar",
      selector: "reserva",
      sortable: true,
      type: "text",
      id: "reserva",
      tag: "reserva",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        const formattedValue = row.reserve; //!== null ? row.reserve.toFixed(2) : "";
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormGroup>
              <CurrencyInput
                intlConfig={{ locale: "es-GT", currency: "USD" }}
                prefix="$"
                key={row.id}
                name="amount"
                step="0.01"
                className="input form-control"
                style={{
                  color: "#05252d",
                }}
                value={!isNaN(formattedValue) ? formattedValue : null}
                onValueChange={(e) => handleChange(e, row)}
                placeholder="P. ejemplo 1000.25"
                invalid={isInvalid[row.id]}
              />
              {isInvalid[row.id] && (
                <div
                  className="text-danger"
                  style={{ position: "relative", bottom: "50%", left: "0" }}
                >
                  <span style={{ fontSize: "11px" }}>
                    El reservado no puede ser mayor al disponible.
                  </span>
                </div>
              )}
            </FormGroup>
          </div>
        );
      },
    },
  ];

  const columns3 = [
    {
      omit: false,
      name: "Presupuesto Total",
      selector: "totalBudget",
      sortable: true,
      type: "text",
      id: "totalBudget",
      tag: "totalBudget",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        let totalData2 = data2.reduce((total, value) => {
          const reserveValue = parseFloat(value.reserve);
          if (!isNaN(reserveValue)) {
            return total + reserveValue;
          } else {
            return total;
          }
        }, 0);

        if (props.edit.budgetOld) {
          return `$${Number(props.edit.amount).toFixed(2)}`;
        } else {
          return `$${Number(totalData2).toFixed(2)}`;
        }
      },
    },
    {
      omit: false,
      name: "Total implementado",
      selector: "totalReserved",
      sortable: true,
      type: "text",
      id: "totalReserved",
      tag: "totalReserved",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${row.totalReservado}`,
    },
    {
      omit: false,
      name: "Total consumido",
      selector: "totalConsumed",
      sortable: true,
      type: "text",
      id: "totalConsumed",
      tag: "totalConsumed",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${row.totalConsumido}`,
    },
    {
      omit: false,
      name: "Total disponible",
      selector: "totalAvailable",
      sortable: true,
      type: "text",
      id: "totalAvailable",
      tag: "totalAvailable",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        let totalData2 = data2.reduce((total, value) => {
          const reserveValue = parseFloat(value.reserve);
          if (!isNaN(reserveValue)) {
            return total + reserveValue;
          } else {
            return total;
          }
        }, 0);
        if (update) {
          if (props.edit?.budgetOld) {
            return `$${parseFloat(props.edit.available).toFixed(2)}`;
          } else {
            let available =
              parseFloat(totalData2).toFixed(2) -
              parseFloat(props.edit.availableC).toFixed(2);
            setAvailable(available);
            return `$${available.toFixed(2)}`;
          }
        } else {
          return `$${row.totalDisponible}`;
        }
      },
    },
  ];

  // Mueve las filas selecionadas de ka tabla1 a la tabla2
  const moveSelectedRowsTable1 = () => {
    const filasSeleccionadas = tablaTemporal.filter((fila) => {
      if (
        fila.checked &&
        !data2.some((fila2) => fila.order_line_id_v6 === fila2.order_line_id_v6)
      ) {
        fila.checked = false;
        fila.prevReserve = fila.reserve;
        fila.reserve = 0;
        return fila;
      }
    });
    const filasRestantes = tablaTemporal
      .filter(
        (fila1) =>
          !data.some(
            (fila2) => fila1.order_line_id_v6 === fila2.order_line_id_v6
          )
      )
      .concat(
        data.filter(
          (fila2) =>
            !tablaTemporal.some(
              (fila1) => fila2.order_line_id_v6 === fila1.order_line_id_v6
            )
        )
      );

    //ELIMINAR ELEMENTOS REPETIDOS TABLA ORIGEN
    let uniqueArr = filasRestantes.filter((item, index) => {
      return (
        index ===
        filasRestantes.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(item);
        })
      );
    });
    // Agregar las filas seleccionadas a la tabla de destino
    const tabla2Nueva = [...data2, ...filasSeleccionadas];
    setSelectAll((prev) => ({ ...prev, table1: false }));
    // Actualizar la tabla de origen
    setData(uniqueArr);

    // Actualizar la tabla de destino
    setData2(tabla2Nueva);

    // Reiniciar la tabla temporal
    setTablaTemporal([]);
  };

  // Mueve las filas seleccionadas de la tabla2 a la tabla1
  const moveSelectedRowsTable2 = async () => {
    const filasSeleccionadas = tablaTemporal2.filter((fila) => {
      if (
        fila.checked &&
        !data.some((fila2) => fila.order_line_id_v6 === fila2.order_line_id_v6)
      ) {
        fila.checked = false;
        fila.prevReserve = fila.reserve;
        fila.reserve = fila.prevReserve;
        return fila;
      }
    });

    if (update) {
      filasSeleccionadas.forEach((element) => {
        const found = test.find(
          (ele) => ele.order_line_id_v6 === element.order_line_id_v6
        );
        const foundEdit = props.edit?.reserveForLovs?.find(
          (ele) => ele.order_line_id_v6 === element.order_line_id_v6
        );
        if (found && foundEdit) {
          const newReserve =
            parseFloat(found.reserve) - parseFloat(foundEdit.reserve);
          const newBudgetAvailable =
            parseFloat(found.budgetAvailable) + parseFloat(foundEdit.reserve);

          if (element.reserve !== null) {
            element.budgetAvailable = newBudgetAvailable;
            element.reserve = newReserve;
          }
        } else {
          element.budgetAvailable = found.budgetAvailable;
          element.reserve = found.reserve;
        }
      });
    }

    const filasRestantes = tablaTemporal2
      .filter(
        (fila1) =>
          !data2.some(
            (fila2) => fila1.order_line_id_v6 === fila2.order_line_id_v6
          )
      )
      .concat(
        data2.filter(
          (fila2) =>
            !tablaTemporal2.some(
              (fila1) => fila2.order_line_id_v6 === fila1.order_line_id_v6
            )
        )
      );

    //ELIMINAR ELEMENTOS REPETIDOS TABLA ORIGEN
    let uniqueArr = filasRestantes.filter((item, index) => {
      return (
        index ===
        filasRestantes.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(item);
        })
      );
    });
    setSelectAll((prev) => ({ ...prev, table2: false }));
    // Agregar las filas seleccionadas a la tabla de destino
    const tabla2Nueva = [...data, ...filasSeleccionadas];
    //ELIMINAR ELEMENTOS DONDE SU BUDGETAVAILABLE SEA IGUAL O MENOR A CERO
    const tabla2Nueva2 = tabla2Nueva.filter((item) => item.budgetAvailable > 0);

    // Actualizar la tabla de origen
    setData2(uniqueArr);

    // Actualizar la tabla de destino
    // setData(tabla2Nueva);
    setData(tabla2Nueva2);

    // Reiniciar la tabla temporal
    setTablaTemporal2([]);
  };

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          minWidth: "100px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "transparent",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(1)": {
          minWidth: "100px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "transparent",
        },
      },
    },
  };

  // OnChange del input de lo reservado de cada ov
  const handleChange = async (value, row) => {
    setData2((prev) => {
      let datainput = [...prev];
      const idx = datainput.findIndex((item) => item.id === row.id);
      let newAvailable = datainput[idx].budgetAvailable;
      let newBudgetAvailable = 0;
      let reserveValue = parseFloat(datainput[idx].reserve) || 0;
      if (reserveValue < parseFloat(value)) {
        // Suma el disponible
        let found = parseFloat(newAvailable) + reserveValue;
        newBudgetAvailable = found - parseFloat(value);
      } else {
        if (parseFloat(value) >= 0) {
          let differenceValue = reserveValue - parseFloat(value);
          newBudgetAvailable = newAvailable + differenceValue;
        } else {
          newBudgetAvailable = newAvailable + reserveValue;
        }
      }
      if (newBudgetAvailable < 0) {
        setIsInvalid((prev) => ({
          ...prev,
          [row.id]: true,
        }));
      } else {
        let valorReserve = value >= 0 ? value : null;
        setIsInvalid((prev) => ({
          ...prev,
          [row.id]: false,
        }));
        datainput[idx] = {
          ...datainput[idx],
          reserve: valorReserve,
          budgetAvailable: newBudgetAvailable,
        };
      }
      return datainput;
    });
  };

  // OnChange del input del nombre del presupuesto
  const handleChangeInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      nameBudget: _.isEmpty(value),
    }));
  };

  const closeModal = (e) => {
    setState(initialState);
    if (e) e.preventDefault();
    analytics.modalesAcciones({
      accion: "Cancelar",
      ubicacion: "Presupuestos",
    });
    props.setOpenModal === undefined
      ? window.$.fancybox.close()
      : props.setOpenModal(false);
    props._setEdit({});
    setData2([]);
    setInputValue({});
    setDataTotalTable([
      {
        presupuestoTotal: Number(0).toFixed(2),
        totalConsumido: Number(0).toFixed(2),
        totalReservado: Number(0).toFixed(2),
        totalDisponible: Number(0).toFixed(2),
      },
    ]);
  };

  const areValidateLovs = () => {
    let error = false;
    const reservedForLovs = _.map(state.reserveForLovs, "reserve");
    const newAmount = _.sumBy(data2, "reserve");
    const newNegativo = Math.sign(Number(available));
    const invalidoReserve = reservedForLovs.some(
      (num) => num === undefined || num === null || num === "0" || num === 0
    ); //Valida que ninguna ov seleccionada tenga valores invalidos seleccionadois

    console.log("reservedForLovs", reservedForLovs);
    const errorsModal = {
      "-1": {
        title: "Error",
        text: "El presupuesto total no puede ser menor al total implementado.",
      },
      invalid: {
        title: "Error",
        text: "Lo reservado de la línea de orden de venta no puede ser cero ó vacío.",
      },
      nameBudget: {
        title: "Error",
        text: "El nombre del presupuesto es requerido.",
      },
    };

    if (newNegativo === -1 || invalidoReserve) {
      const errorType = newNegativo === -1 ? "-1" : "invalid";
      error = true;
      showError(errorsModal[errorType]);
    } else if (state.name === "") {
      error = true;
      showError(errorsModal.nameBudget);
    } else if (state.reserveForLovs.length === 0) {
      error = true;
      showError({
        title: "Error",
        text: "Agrega al menos una LOV al presupuesto.",
      });
    }
    return error;
  };

  const showError = ({ title, text }) => {
    return Swal.fire({
      title: title,
      text: text,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    });
  };

  const onSaveModalEdit = async (e) => {
    try {
      e.preventDefault();
      if (areValidateLovs()) return;
      setLoading(true);
      const autentidcacion = await Auth.currentAuthenticatedUser();
      props
        ._updateBudget({
          id: state.id,
          title: state.name,
          account: props.currentAccount,
          amount: state.amount,
          status: 1,
          createAt: state.createAt,
          availableAmount: 0,
          user: props.currentUser,
          accounts: props.accounts,
          userEmail: autentidcacion.attributes.email,
          budgetOld: state.budgetOld,
          prevBudget: props.edit,
          lovsSelected: data2,
          lovsModal: test,
        })
        .then(() => {
          closeModal();
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);

      console.log("Error", error);
    } finally {
      // props._showLoader(false);
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.checked,
      style: {
        backgroundColor: "#599ee87a", // Cambia aquí el color de resaltado
      },
    },
    {
      when: (row) => row.ovOld,
      style: {
        backgroundColor: "#999",
        color: "#fff",
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
  ];

  function filterData(data, filterText) {
    const lowerCaseFilterText = filterText.toLowerCase();
    return data.filter(
      (buscador) =>
        (buscador.orderReference &&
          buscador.orderReference
            .toLowerCase()
            .includes(lowerCaseFilterText)) ||
        (buscador.order_line_id_v6 &&
          buscador.order_line_id_v6
            .toString()
            .toLowerCase()
            .includes(lowerCaseFilterText)) ||
        (buscador.orderNumber &&
          buscador.orderNumber.toLowerCase().includes(lowerCaseFilterText)) ||
        (buscador.budgetAvailable &&
          buscador.budgetAvailable
            .toString()
            .toLowerCase()
            .includes(lowerCaseFilterText))
    );
  }

  return (
    <div className={props.openModal ? "blur-div" : ""}>
      <div
        id="new-budget-modal"
        style={{ display: props.openModal ? "block" : "none" }}
        className="content-modal content-modal__presupuestos"
      >
        {loading ? (
          <>
            <PageLoader />
          </>
        ) : (
          <>
            <div className="content-modal__child">
              <div>
                <div>
                  <div className="header-modal">
                    <h3 className="titleModal">
                      {" "}
                      {update
                        ? "Actualicemos tu presupuesto"
                        : "Creemos un presupuesto"}{" "}
                    </h3>
                  </div>
                </div>
                <div className="body-modal body-modal__row">
                  <section className="body-part">
                    <DataTableUtils
                      valueFilter={filterText || ""}
                      onChangeFilterText={(value) => {
                        setFilterText(value);
                      }}
                    ></DataTableUtils>
                    <div className="scroll-busqueda">
                      <ReactDataTable
                        className="budgetTable"
                        customStyles={customStyles}
                        conditionalRowStyles={conditionalRowStyles}
                        columns={columnsUno}
                        data={filterData(data, filterText)}
                        persistTableHead
                        pagination
                        noHeader
                      />
                    </div>
                  </section>
                  <section className="Buttons">
                    <button onClick={moveSelectedRowsTable1}>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                    <button onClick={moveSelectedRowsTable2}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </section>
                  <section className="body-part">
                    <FormGroup>
                      <Label className="nameBudget">
                        Nombre
                        <Input
                          type="text"
                          name="name"
                          value={state.name}
                          onChange={handleChangeInputChange}
                          invalid={errors.nameBudget}
                          placeholder="Presupuesto"
                        />
                        <FormFeedback>Ingresa un nombre</FormFeedback>
                      </Label>
                    </FormGroup>
                    <div className="scroll-busqueda">
                      <ReactDataTable
                        className="budgetTable"
                        customStyles={customStyles}
                        columns={columns2}
                        conditionalRowStyles={conditionalRowStyles}
                        data={data2}
                        persistTableHead
                        pagination
                        noHeader
                      />
                    </div>
                    <ReactDataTable
                      className="budgetTable"
                      customStyles={customStyles}
                      columns={columns3}
                      data={dataTotalTable}
                      persistTableHead
                      noHeader
                    />
                  </section>
                </div>
                <div className="footOneStep w-100 footOneStepModal4">
                  <a className="btnCancelOS firstBtn" onClick={closeModal}>
                    Cancelar
                  </a>
                  <a
                    className="btnConfirmOS secondBtn"
                    onClick={update ? onSaveModalEdit : onSaveModalCreate}
                  >
                    Confirmar
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.budgets.edit,
  accounts: state.accounts.list,
  campaign: state.campaign,
  campaigns: state.campaigns.list,
  accountTags: state.accounts.tags,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  budgets: state.budgets.list,
  campaignupd: state.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  _getBudgets: (account, user, idmod, mod) =>
    dispatch(getBudgets(account, user, idmod, mod)),
  _createBudgetOvs: (
    account,
    title,
    amount,
    lovsSelected,
    userEmail,
    dataLovs
  ) =>
    dispatch(
      createBudgetOvs(account, title, amount, lovsSelected, userEmail, dataLovs)
    ),
  _showLoader: (show) => dispatch(showLoader(show)),
  _createBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    createAt,
    edit,
    padre,
    availableAmount,
    idBudget,
    nombrePadre,
    fatherAvailable,
    budgets,
    user,
    idmod,
    mod,
    accounts,
    userEmail,
    budgetOld,
    reserveForLovs
  ) =>
    dispatch(
      createBudget(
        id,
        title,
        account,
        amount,
        tags,
        status,
        createAt,
        edit,
        padre,
        availableAmount,
        idBudget,
        nombrePadre,
        fatherAvailable,
        budgets,
        user,
        idmod,
        mod,
        accounts,
        userEmail,
        budgetOld,
        reserveForLovs
      )
    ),
  _updateBudget: (
    id,
    title,
    account,
    amount,
    status,
    createAt,
    availableAmount,
    user,
    accounts,
    userEmail,
    budgetOld,
    prevBudget,
    lovsSelected,
    lovsModal
  ) =>
    dispatch(
      updateBudget(
        id,
        title,
        account,
        amount,
        status,
        createAt,
        availableAmount,
        user,
        accounts,
        userEmail,
        budgetOld,
        prevBudget,
        lovsSelected,
        lovsModal
      )
    ),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _pwaUpsertBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    edit,
    userEmail,
    budgetOld,
    reserveForLovs
  ) =>
    dispatch(
      pwaUpsertBudget(
        id,
        title,
        account,
        amount,
        tags,
        status,
        edit,
        userEmail,
        budgetOld,
        reserveForLovs
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsertBudget);
