import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/categories.actions'

const initialState = {
  edit: {},
  list: [],
  filter_text: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CATEGORIES_SET_EDIT:
      return {
        ...state,
        edit: action.edit,
      }
    case Actions.CATEGORIES_CLEAR_EDIT:
      return {
        ...state,
        edit: {},
      }
    case Actions.CATEGORIES_CLEAR:
      return {
        ...state,
        list: [],
      }
    case Actions.CATEGORIES_ADD_ONE:
      return {
        ...state,
        list: action.category/*[  
          ...state.list.filter(
            (category) => category.id !== action.category.id
          ),
          action.category,
        ],*/
      }
    case Actions.CATEGORIES_REMOVE:
      return {
        ...state,
        list: [
          ...state.list.filter((category) => category.id !== action.category),
        ],
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
