/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import "./assets/css/styles.scss";

import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "./computed_styles";

import List from "./list";
import { withUtils } from "utils";

const TAG = "DropZone";

const DropZone = (props) => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (props.files) {
      setFiles(props.files);
    }
  }, [props.files]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback(
    (filesToUpload) => {
      let newFiles = [...files, ...filesToUpload];
      setFiles(newFiles);
      props.onChange(newFiles);
      if (props.files) {
        props.setFiles(newFiles);
      }

      props.onUpload(newFiles);
      setFiles([]);
    },
    [files, props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, disabled: props.disabled });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const uploadFiles = (e) => {
    e.preventDefault();
    props.onUpload(files);
    setFiles([]);
  };

  const removeFile = (name) => {
    const index = files.findIndex((f) => f.name === name);
    files.splice(index, 1);
    setFiles([...files]);
    if (props.files) {
      props.setFiles([...files]);
    }
  };

  return (
    <section className="container m-2 p-2 subirArchivos">
      {files.length !== 0 && (
        <a
          onClick={uploadFiles}
          className="btnOrange-dash noMargen py-1 px-2 mb-1"
          href="#"
        >
          Subir Archivos
        </a>
      )}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <i className="fas fa-download h1 my-4"></i>
        <p className="text-center h5">
          Arrastra tus archivos aqu&iacute;,
          <br />o haz click para seleccionarlos
        </p>
      </div>
      <aside>
        <List files={files} onRemove={removeFile} />
      </aside>
    </section>
  );
};

export default withUtils(DropZone);
