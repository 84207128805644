import * as Actions from '../actions/app.actions'

const initialState = {
  isLoading: false,
  current_user: '',
  portal_name: "Let's Advertise",
  isIPV4: false,
  isSuperAdmin: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.APP_LOADED:
      return {
        ...state,
        isLoading: false,
      }
    case Actions.APP_SET_CURRENT_USER:
      return {
        ...state,
        current_user: action.user,
      }
    case Actions.IS_IPV4:
      return {
        ...state,
        isIPV4: action.ipv4,
      }
    case Actions.APP_CLEAR_CURRENT_USER:
      return {
        ...state,
        current_user: '',
      }
    case Actions.IS_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: true
      }
    default:
      return state
  }
}
