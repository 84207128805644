import React from 'react'

// components
import MetaTags from '../../components/metatags'
import ConfirmUserService from '../../components/auth/confirm_userSerice'

const ConfirmUser = props => (
  <>
    <MetaTags title='Confirmar Registro en servicio' description='Página de confirmación de registro' />

    <ConfirmUserService {...props} />
  </>
)

export default ConfirmUser
