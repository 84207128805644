import { useState } from "react";
import { Tooltip } from "antd";
import PageLoader from "components/page_loader";
import ReactDataTable from "@easygosanet/react-datatable";

const ModalPresupuesto = ({ showModal, setShowModal }) => {
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      omit: false,
      name: "OV",
      selector: "title",
      sortable: true,
      type: "text",
      id: "title",
      tag: "title",
      visible: false,
      center: true,
      show: true,
    },
    {
      omit: false,
      name: "LOV",
      selector: "title",
      sortable: true,
      type: "text",
      id: "title",
      tag: "title",
      visible: false,
      center: true,
      show: true,
    },
    {
      omit: false,
      name: "Disponible",
      selector: "title",
      sortable: true,
      type: "text",
      id: "title",
      tag: "title",
      visible: false,
      center: true,
      show: true,
    },
    {
      omit: false,
      name: "Reservar",
      selector: "title",
      sortable: true,
      type: "text",
      id: "title",
      tag: "title",
      visible: false,
      center: true,
      show: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          minWidth: "200px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#fff",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(1)": {
          minWidth: "200px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#fff",
        },
      },
    },
  };

  return (
    <div className="blur-div">
      <div className="content-modal">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <div className="header-modal">
              <h3 className="titleModal">Presupuestos</h3>
            </div>

            <div className="body-modal">
              <section className="body-part">
                <button className="btnSincronizar">Sincronizar</button>
                <ReactDataTable
                  className="budgetTable"
                  customStyles={customStyles}
                  columns={columns}
                  // data={searchFilterData.length > 0 ? searchFilterData : filteredData}
                  //data={data}
                  persistTableHead
                  pagination
                  noHeader
                />
              </section>
              <section className="body-part">
                <label>
                  Nombre del presupuesto
                  <input type="text" />
                </label>
                <ReactDataTable
                  className="budgetTable"
                  customStyles={customStyles}
                  columns={columns}
                  // data={searchFilterData.length > 0 ? searchFilterData : filteredData}
                  //data={data}
                  persistTableHead
                  pagination
                  noHeader
                />
              </section>
            </div>

            <div className="footer-Modal">
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button
                  className="btn btn_cancel btnCancelOS"
                  onClick={(e) => setShowModal(false)}
                >
                  Cancelar
                </button>
              </Tooltip>
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button className="btn btn_save btnConfirmOS">Guardar</button>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalPresupuesto;
