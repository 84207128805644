/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'

import './assets/css/styles.css'

import grupo from './assets/img/grupo.png'

import { withUtils } from 'utils'
import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'

const TAG = 'ConfirmNewPassword'

const ConfirmNewPassword = props => {
  props.utils.log(TAG, 'props', props)

  let code = useRef(null)
  let password = useRef(null)

  const updatePassword = async e => {
    e.preventDefault()
    props._showLoader(true)
    let error = ''

    try {
      props.utils.log(TAG, 'code', code.current.value)
      props.utils.log(TAG, 'password', password.current.value)
      let data = await Auth.forgotPasswordSubmit(props.authData.username, code.current.value, password.current.value)
      props.utils.log(TAG, 'data', data)
      await Auth.signIn(props.authData.username, password.current.value)
      props.onStateChange('signedIn', {})
      props.history.push('/dashboard')
    } catch (err) {
      let { code } = err

      switch (code) {
        case 'InvalidParameterException':
          error = 'La contraseña debe tener al menos 6 carácteres.'
          break
          case 'CodeMismatchException':
          error = 'El código que ingreso es incorrecto.'
          break
      }

      props.utils.log(TAG, 'error signing in', err)
    }

    props._showLoader(false)

    if (error!=='') {
      props._showAlertModal('Error!', error, Modal.MODAL_TYPE_ERROR)
    }
  }

  const goToSignIn = e => {
    e.preventDefault()
    props.onStateChange('signIn', {})
  }

  return(
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">¡Recupera tu contrase&ntilde;a!</h1>
          <p className="textos">C&oacute;digo de Verificaci&oacute;n</p>
          <input ref={code} className="formInput" type="text" placeholder="Ingresa tu c&oacute;digo" />
          <p className="textos">Nueva Contrase&ntilde;a</p>
          <input ref={password} className="formInput" type="password" placeholder="Ingresa tu nueva contrase&ntilde;a" />
          <div className="passForget">
            <a onClick={goToSignIn} href='#'>
              Ir a inicio de sesi&oacute;n
            </a>
          </div>
          <a onClick={updatePassword} className="btn-plus-form" href="#">
            RECUPERAR
          </a>
        </div>
        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(ConfirmNewPassword))
