export const APP_LOADED = 'APP:LOADED'
export const APP_SET_CURRENT_USER = 'APP:SET:CURRENT:USER'
export const APP_CLEAR_CURRENT_USER = 'APP:CLEAR:CURRENT:USER'
export const IS_IPV4 = 'APP:IS:IPV4'
export const IS_SUPER_ADMIN = 'APP:IS:SUPER:ADMIN'

export const appLoaded = () => ({ type: APP_LOADED })

export const setCurrentUser = (user) => ({
  type: APP_SET_CURRENT_USER,
  user,
})

export const clearCurrentUser = () => ({
  type: APP_CLEAR_CURRENT_USER,
})

export const setIsIPV4 = (ipv4) => ({
  type: IS_IPV4,
  ipv4: ipv4,
})

export const setIsSuperAdmin = (isSuperAdmin) => ({
  type: IS_SUPER_ADMIN,
  isSuperAdmin: isSuperAdmin,
})

