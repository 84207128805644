import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ReactCalendar from '@easygosanet/react-calendar'

import status from 'config/status'

const Calendar = ({ campaigns }) => {
  const [events, setEvents] = useState([])

  useEffect(() => {
    let mCampaings = []

    campaigns.map((campaign) => {
      if (campaign.fechaIni && campaign.fechafin) {
        let fi = campaign.fechaIni.split('.')
        let ff = campaign.fechafin.split('.')

        mCampaings.push({
          id: campaign.id,
          title: campaign.name,
          start: new Date(`${fi[2]}-${fi[1]}-${fi[0]} 00:00:00`),
          end: new Date(`${ff[2]}-${ff[1]}-${ff[0]} 24:00:00`),
          color: getEventColor(campaign.status)
        })
      }
    })

    if (!_.isEmpty(mCampaings)) setEvents(mCampaings)
  }, [campaigns])

  const getEventColor = mStatus => {
    switch (mStatus) {
      // Corriendo
      case status.ACTIVO.id:
        return status.ACTIVO.color
      // En cola
      case status.CREADO.id:
        return status.CREADO.color
      // En implementacion
      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.color
      // Finalizado
      case status.FINALIZADO.id:
      case status.FINALIZADO_SIN_META.id:
      case status.FINALIZADO_SIN_REPORTE.id:
        return status.FINALIZADO.color
      // Aprobado
      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.color
      // En revision
      case status.PENDIENTE.id:
      case status.RECHAZADO.id:
      case status.EN_REVISION.id:
        return status.EN_REVISION.color
      // Cancelado
      case status.ELIMINADO.id:
      case status.NO_IMPLEMENTADO.id:
        return status.ELIMINADO.color
      default:
        return status.PAUSADO.color
    }
  }

  return (
    <div className='form-tar'>
      <ReactCalendar
        events={events}
        headerToolbar={{ start: 'prev', center: 'title', end: 'next' }}
      />
    </div>
  )
}

export default Calendar