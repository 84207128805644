import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MultiSelect from "libs/multiselect";
import { getAccounts } from "redux/actions/accounts.actions";
import { getClients } from "redux/actions/clients.actions";
import { createAccount } from "redux/actions/accounts.actions";
import { pwaUpsertAccount } from "redux/actions/pwa.actions";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import { getClient_Mysql } from "redux/actions/clients_mysql.actions";
import Swal from "sweetalert2";
import PageLoader from "components/page_loader";
const ModalAccounts = (props) => {
  const { setShowModalAccounts, showModalAccounts, refresh, setRefresh } =
    props;
  const [canRender, setCanRender] = useState(0);
  const [username, setUsername] = useState("");
  const [mysqlClients, SetMysqlClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    address: false,
  });
  const [state, setState] = useState({
    id: "",
    name: "",
    phone: "",
    email: "",
    credit: 0,
    client: "",
    address: "",
    fanpage: "",
    members: [],
    createAt: 0,
    edit: false,
    eAdvertising: 0,
    clienteddl: { id_cli: 0, name: "" },
  });
  useEffect(() => {
    // setIsLoading(props.)
  }, [props.isLoading]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((userData) => {
      let { username } = userData;
      setUsername(username);
      APILA.getAllClients().then((res) => {
        SetMysqlClients(res.data.clients);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const handleUsersChange = (value) => {
    console.log("handleUsersChange");
    console.log(value);
    if (value) {
      setState((prev) => ({
        ...prev,
        members: value,
      }));
    }
  };

  const areValidInputs = () => {
    console.log("areValidInputs state", state);
    console.log("areValidInputs props", props);
    let found = props.accounts.find((element) => element.name == state.name);
    let error = false;
    if (found && !state.edit) {
      error = true;
    }

    const mErrors = {
      name: error,
      email: state.email === "",
      phone: state.phone === "",
      address: state.address === "",
    };

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));

    console.log("errors", mErrors);

    return !_.some(mErrors, (e) => e);
  };

  const handleUpsert = (e) => {
    e.preventDefault();

    if (!areValidInputs()) return;

    let accountId = state.id;

    if (accountId === "") {
      accountId = v4();
    }
    if (state.name != "") {
      if (props.currentUser !== "") {
        // setIsLoading(true)
        setShowModalAccounts(false);
        props.setShowModal(false);
        props
          ._createAccount(
            accountId,
            state.name,
            username, // state.client,
            state.phone,
            state.email,
            state.address,
            state.credit,
            state.members,
            state.edit,
            state.eAdvertising,
            props.accounts
          )
          .then((response) => {
            // setRefresh(!refresh)
            // setIsLoading(false)
            props.setShowModal(true);
            setShowModalAccounts(false);
          });
      } else {
        props
          ._pwaUpsertAccount(
            accountId,
            state.name,
            state.phone,
            state.email,
            state.address,
            state.edit,
            state.eAdvertising
          )
          .then((response) => {
            // setRefresh(!refresh)
            // setIsLoading(false)
            props.setShowModal(true);
            setShowModalAccounts(false);
          });
      }
    }

    console.log("Editable", state);
  };

  const handleNameList = async (event, value) => {
    // props.utils.log('handleInputChange')
    console.log("value handleNameList", value);
    console.log("state after handleNameList", props);
    if (value) {
      let responseAcc = await API.getAccountByName({ name: value.name });
      console.log("ResAcc", responseAcc);

      if (responseAcc.data.account.length > 0) {
        Swal.fire({
          title: "Alerta",
          text: "Esta cuenta ya forma parte de Let's Advertise.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      } else {
        if (value) {
          setState((prev) => ({
            ...prev,

            // id: value.id_client,
            phone: value.phone,
            email: value.email,
            name: value.name,
            address: value.address,
            eAdvertising: value.id_client,
            clienteddl: {
              id_cli: value.id_client,
              name: value.name,
            },
          }));
        }
      }
    }
    if (value == null) {
      setState((prev) => ({
        ...prev,

        // id: value.id_client,
        phone: "",
        email: "",
        name: "",
        address: "",
        eAdvertising: "",
        clienteddl: {
          id_cli: "",
          name: "",
        },
      }));
    }

    console.log("state after handleNameList", props);
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="blur-div ">
          <div className="content-modal contentModalRolAccount">
            <div className="closeModal-content">
              <i
                className="fas fa-times-circle close"
                onClick={(e) => setShowModalAccounts(false)}
              ></i>
            </div>
            <div>
              <h3 className="titleModal titleModalRol"> Creación de Cuenta</h3>
            </div>
            <div className="body-modal modalAccount">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Ponle un nombre a tu cuenta
                </Label>
                <Autocomplete
                  disabled={state.edit}
                  value={state.clienteddl}
                  options={mysqlClients}
                  onChange={handleNameList}
                  getOptionLabel={(options) => options.name || ""}
                  style={{ width: 300, height: 35 }}
                  className={classnames({
                    "is-invalid": errors.name,
                  })}
                  renderInput={(params) => (
                    <TextField {...params} label="Cuenta" variant="outlined" />
                  )}
                />
                <FormFeedback>
                  La cuenta ya existe o el dato se encuentra vacio
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  N&uacute;mero de tel&eacute;fono
                </Label>
                <Input
                  type="text"
                  name="phone"
                  className="input"
                  value={state.phone}
                  onChange={handleInputChange}
                  placeholder="P. ejemplo 5555 5555"
                  invalid={errors.phone}
                />
                <FormFeedback>
                  Ingresa un n&uacute;mero de tel&eacute;fono
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Correo electr&oacute;nico
                </Label>
                <Input
                  type="text"
                  name="email"
                  className="input"
                  value={state.email}
                  onChange={handleInputChange}
                  placeholder="P. ejemplo cuenta@gmail.com"
                  invalid={errors.email}
                />
                <FormFeedback>
                  Ingresa un correo electr&oacute;nico
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Direcci&oacute;n
                </Label>
                <Input
                  type="text"
                  name="address"
                  className="input"
                  value={state.address}
                  onChange={handleInputChange}
                  placeholder="P. ejemplo 7 avenida 4-12"
                  invalid={errors.address}
                />
                <FormFeedback>Ingresa una direcci&oacute;n</FormFeedback>
              </FormGroup>
            </div>

            <div className="footer-Modal">
              <div className="buttons-Modal-content">
                <button
                  className="btnModal btnCancelarModalRol"
                  onClick={(e) => setShowModalAccounts(false)}
                >
                  Cancelar
                </button>
                <button
                  className="btnModal btnGuardarModalRol"
                  onClick={handleUpsert}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  edit: state.accounts.edit,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  clients_mysql: state.clients_mysql,
  accounts: state.accounts.list,
  selected: state.accounts.edit.selected,
});

const mapDispatchToProps = (dispatch) => ({
  _getAccounts: (client) => dispatch(getAccounts(client)),
  _getClients: () => dispatch(getClients()),
  _getClient_Mysql: () => dispatch(getClient_Mysql()),
  _pwaUpsertAccount: (id, name, phone, email, address, edit) =>
    dispatch(pwaUpsertAccount(id, name, phone, email, address, edit)),
  _createAccount: (
    id,
    name,
    username,
    phone,
    email,
    address,
    credit,
    members,
    edit,
    eAdvertising,
    accounts
  ) =>
    dispatch(
      createAccount(
        id,
        name,
        username,
        phone,
        email,
        address,
        credit,
        members,
        edit,
        eAdvertising,
        accounts
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAccounts);
