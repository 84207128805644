import React from 'react'

// components
import Content from 'components/status'
import MetaTags from 'components/metatags'
import UpsertStatusModal from 'components/status/modal/upsert_status'

const StatusPage = () => (
  <>
    <MetaTags title='Estados de Pauta' description='Página de estados de pauta' />

    <div className='content-all'>
      <div id='shadow' className='shadow hide'></div>
      <div className='form-dashboard'>
        <Content />
      </div>
    </div>

    <UpsertStatusModal />
  </>
)

export default StatusPage
