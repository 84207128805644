import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { pwaSetDefault } from "redux/actions/pwa.actions";
import { updateCurrentAccount } from "redux/actions/dashboard.actions";
import { setDefault } from "redux/actions/accounts.actions";
import { setCurrentPermissions } from "redux/actions/permissions.actions";
import * as APILA from "helpers/apiLA";

import pautaobj from "components/objectCampain";
import pautaobjlist from "components/objectCampainList";
import updateCampain from "components/updateCampain";

// Pages
import Home from "pages/home";
import Scan from "pages/scan";
import Status from "pages/status";
import Profile from "pages/profile";
import Creative from "pages/creative";
import Template from "pages/templates";
import Category from "pages/category";
import Audience from "pages/audience";
import Settings from "pages/settings";
import Dashboard from "pages/dashboard";
import AdminAnalytics from "pages/admin/report";
import oldBudget from "pages/oldBudget";
import UpdatePassword from "pages/auth/update_password";
// import Template from "pages/templates";
import Report from "pages/report";
import Budget from "pages/budget";
import RolesPermisos from "pages/rolesPermisos";
import TermsCondition from "pages/terminos";
import DashboardPwa from "pages/dashboardPWA";

import { getPayments } from "redux/actions/payments.actions";
import { getClients } from "redux/actions/clients.actions";
import { getAccounts } from "redux/actions/accounts.actions";
import { setCurrentUser, setIsIPV4 } from "redux/actions/app.actions";
import budget from "pages/budget";
import axios from "axios";

const Content = (props) => {
  const [username, setUsername] = useState("");
  const [signIn, setSignIn] = useState(false);
  const [reportRoute, setReportRoute] = useState(false);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    props._updateCurrentAccount(userId, props.currentAccount);
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        let { username } = userData;
        setUsername(username);
        props._setCurrentUser(username);
      })
      .catch(async () => {
        try {
          const apiUrl = 'https://api.ipify.org?format=json';
          const responseGeo = await fetch(apiUrl);
          const data = await responseGeo.json();
          const idUser = data?.ip
          setUserId(userId);
          setSignIn(true);
          props._setIsIPV4(true);
          props._setCurrentUser(idUser);
          props._pwaSetDefaultAccount(props.currentAccount);
          props._setDefaultAccount(props.currentAccount);
          props._updateCurrentAccount(idUser, props.currentAccount);
          setUsername(idUser);
        } catch (err) {
          console.log("Error en el usuario", err);
        }
      });
  }, []);

  useEffect(() => {
    // username !== '' && props._getClients()
    username !== "" && props._getAccounts(username).then((acc) => { });
    // username !== '' && props._getPayments(username)
  }, [username]);

  useEffect(() => {
    const beforeUnloadHandler = function (event) {
      event.preventDefault();
    };

    if (props.editActivateTemplate) {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [props.editActivateTemplate]);

  return (
    <Switch>
      <Route
        path="/privacy-policy"
        component={TermsCondition}
      // component={() => (<TermsCondition {...props}/>)}
      />
      {!signIn && <Route path="/campañas/:id" component={Dashboard} />}
      {!signIn && <Route path="/campañas" component={Dashboard} />}
      {signIn && <Route path="/campañas" component={DashboardPwa} />}
      <Route path="/status" component={Status} />
      <Route path="/templates" component={Template} />
      <Route path="/perfil" component={Profile} />
      {/* <Route path="/creatividad" component={Creative} exact /> */}
      <Route path="/pautaobj" component={pautaobj} />
      <Route path="/publicos" component={Audience} />
      <Route path="/categorias" component={Category} />
      <Route path="/pautaobjlist" component={pautaobjlist} />
      <Route path="/updateCampain" component={updateCampain} />
      <Route path="/update_password" component={UpdatePassword} />
      {/* <Route path='/analytics' component={AdminAnalytics} /> */}
      <Route path="/configuracion" component={Settings} />
      <Route path="/reportes" component={AdminAnalytics} />
      <Route path="/roles" component={RolesPermisos} />
      <Route path="/presupuestosOdoo" component={Budget} />
      <Route path="/presupuestos" component={oldBudget} />
      <Route path="/scan" component={Scan} />
      <Route path="*">
        <Redirect to="/campañas" />
      </Route>
    </Switch>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  editActivateTemplate: state.templates.editActivate,
  permisos: state.permisos.current_permissions,
});
const mapDispatchToProps = (dispatch) => ({
  // _getClients: () => dispatch(getClients()),
  _setIsIPV4: (ipv4) => dispatch(setIsIPV4(ipv4)),
  _getAccounts: (client) => dispatch(getAccounts(client, true)),
  _getPayments: (client) => dispatch(getPayments(client)),
  _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  _setDefaultAccount: (account) => dispatch(setDefault(account)),
  _pwaSetDefaultAccount: (account) => dispatch(pwaSetDefault(account)),
  _setCurrentPermissions: (permisos) =>
    dispatch(setCurrentPermissions(permisos)),
  _updateCurrentAccount: (user, account) =>
    dispatch(updateCurrentAccount(user, account)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
