/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";

export const CustomInput = ({
  placeholder,
  value,
  handleInputChange,
  name,
  hasError,
  messageError,
  label,
}) => {
  const currentTypeDigitalAsset = useSelector(
    (state) => state.digitalAssets.currentTypeDigitalAsset
  );
  return (
    <FormGroup>
      <h5
        style={{
          color: "#05252d",
          fontWeight: 500,
          marginBottom: "5px",
        }}
      >
        {!label ? currentTypeDigitalAsset.labelParams : label}
      </h5>
      <Input
        type="text"
        name={name}
        className="input"
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        invalid={hasError}
        required
      />
      <FormFeedback>{messageError}</FormFeedback>
    </FormGroup>
  );
};

export const ButtonsConfirmAndSave = ({
  handleEditInfo,
  handleCreateInfo,
  closeModal,
  isValidaFormState,
  state,
}) => {
  return (
    <>
      <a href="#" className="btnCancelOS firstBtn" onClick={closeModal}>
        Cancelar
      </a>
      <a
        href="#"
        className="btnConfirmOS secondBtn"
        style={{
          pointerEvents: !isValidaFormState ? "none" : "auto",
          opacity: !isValidaFormState ? 0.5 : 1,
        }}
        tabIndex="-1"
        onClick={(e) => (state.edit ? handleEditInfo(e) : handleCreateInfo(e))}
      >
        Confirmar
      </a>
    </>
  );
};

export const OptionSelect = ({
  handleInputChange,
  digitalMedium,
  defaultValueMedio,
}) => {
  return (
    <FormGroup>
      <h5
        style={{
          color: "#05252d",
          fontWeight: 500,
          marginBottom: "5px",
        }}
      >
        Tipo
      </h5>
      <Autocomplete
        disableClearable={true}
        options={digitalMedium}
        required
        getOptionLabel={(option) => option.label}
        className="input"
        name="medio"
        value={defaultValueMedio ? defaultValueMedio : digitalMedium[0]}
        onChange={(_, value) => {
          handleInputChange(value);
        }}
        placeholder="P. ejemplo: Instagram/Facebook"
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            label=""
            variant="outlined"
            name="Facebook"
            autoComplete="off"
          />
        )}
      />
      <FormFeedback>Ingresa una medio</FormFeedback>
    </FormGroup>
  );
};
