import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/objectCampain.actions'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.UPD_OBJ:
      return [...state, action.pauta]
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}