import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'

export const STATUS_CLEAR = 'STATUS:CLEAR'
export const STATUS_REMOVE = 'STATUS:REMOVE'
export const STATUS_ADD_ONE = 'STATUS:ADD:ONE'
export const STATUS_SET_EDIT = 'STATUS:SET:EDIT'
export const STATUS_CLEAR_EDIT = 'STATUS:CLEAR:EDIT'
export const STATUS_SHOW_MODAL = 'STATUS:SHOW:MODAL'
export const STATUS_SET_FILTER_TEXT = 'STATUS:SET:FILTER:TEXT'

const TAG = 'Status:Actions'

export const showModal = (show) => ({
  type: STATUS_SHOW_MODAL,
  show,
})

export const getStatusList = () => async (dispatch) => {
  dispatch(showLoader(true))
  dispatch(clearStatus())

  try {
    const response = await API.getStatusList()
    
    Logger.log(TAG, 'getStatusList', response)

    const { status } = response.data
  
    status.forEach((st) => {
      dispatch(addStatus(st))
    })
  } catch (err) {
    Logger.log(TAG, 'error getStatusList', err)
  }

  dispatch(showLoader(false))
}

export const upsertStatus = (id, name, color, associations, edit) => async (dispatch) => {
  Logger.log(TAG, 'upsertStatus')
  dispatch(showLoader(true))
  
  let body = {
    id,
  }

  if (name) {
    body = {
      ...body,
      name
    }
  }

  if (color) {
    body = {
      ...body,
      color
    }
  }

  if (associations) {
    body = {
      ...body,
      associations
    }
  }
  
  try {
    const response = edit
      ? await API.updateStatus(body)
      : await API.createStatus(body)
    
    Logger.log(TAG, 'upsertStatus', response)

    const title = edit ? 'Estado Editado' : 'Estado Creado'
    const message = edit
      ? 'El estado fue editado exitosamente.'
      : 'El estado fue creado exitosamente.'

    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getStatusList())
      })
    )
  } catch (err) {
    Logger.log(TAG, 'error upsertStatus', err)
    const message = edit
      ? 'Ocurrió un error al editar el estado'
      : 'Ocurrió un error al crear el estado'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }
  
  dispatch(showLoader(false))
}

export const deleteStatus = (id) => async (dispatch) => {
  dispatch(showLoader(true))

  try {
    const response = await API.deleteStatus(id)

    Logger.log(TAG, 'deleteStatus', response)
    
    dispatch(
      Modal.showAlertModal(
        'Estado Eliminado',
        'El estado fue eliminado exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        () => {
          dispatch(removeStatus(id))
        }
      )
    )
  } catch (err) {
    Logger.log(TAG, 'error deleteStatus', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al eliminar el estado',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }

  dispatch(showLoader(false))
}

export const setFilterText = (text) => ({
  type: STATUS_SET_FILTER_TEXT,
  text,
})

export const setEdit = (edit) => ({
  type: STATUS_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: STATUS_CLEAR_EDIT,
})

export const clearStatus = () => ({
  type: STATUS_CLEAR,
})

const addStatus = (status) => ({
  type: STATUS_ADD_ONE,
  status,
})

const removeStatus = (status) => ({
  type: STATUS_REMOVE,
  status,
})