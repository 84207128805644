import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

import CreatableSelect from "libs/select/creatable";
import analytics from "../../../helpers/analytics";
import { createBudget } from "redux/actions/budgets.actions";
import { pwaUpsertBudget } from "redux/actions/pwa.actions";
import Select from "libs/select";
import NumberFormat from "react-number-format";
import CurrencyInput from "react-currency-input-field";
import "../assets/css/styles.scss";
import { Auth } from "aws-amplify";
const idmod = 3;
const mod = "PRESUPUESTOS";

const UpsertBudget = (props) => {
  const [errors, setErrors] = useState({
    name: false,
    amount: false,
    errorBudgetMessage: "",
  });

  const [state, setState] = useState({
    id: "",
    name: "",
    tags: [],
    amount: 0,
    edit: false,
    createAt: 0,
    padre: "",
    //budget:'',
    idBudget: "",
    nombrePadre: "",
    fatherAvailable: 0,
    available: 0,
  });

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      let newState = {
        edit: true,
        id: props.edit.id,
        tags: props.edit.tags,
        name: props.edit.title,
        amount: props.edit.amount,
        createAt: props.edit.createAt,
        padre: props.edit.padre,
        nombrePadre: props.edit.nombrePadre,
        fatherAvailable: props.fatherAvailable,
        available: props.edit.available,
        //budget: props.campaignupd.budget,
        //idBudget: props.campaignupd.idBudget,
      };

      if (props.edit.tags) {
        newState = {
          ...newState,
          tags: props.edit.tags.map((tag) => ({
            value: tag,
            label: getTagName(tag),
          })),
        };
      }

      setState((prev) => ({
        ...prev,
        ...newState,
      }));
    } else {
      setState({
        id: "",
        name: "",
        tags: [],
        amount: 0,
        edit: false,
        createAt: 0,
        padre: "",
        //budget: '',
        idBudget: "",
        nombrePadre: "",
        fatherAvailable: 0,
        available: 0,
      });
    }
  }, [props.edit]);

  const getTagName = (id) => {
    const tag = _.find(props.accountTags, { id });
    return tag ? tag.name || "" : "";
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "amount" && e.target.validity.valid) {
      setState((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const handleBudgetInputChange = (e) => {
    console.log("handleBudgetInputChange state", state);
    const value = e;
    console.log("type of", value);
    console.log("e", e);
    console.log("value handleBudgetInputChange", typeof value);
    let errorBudget = false;
    let errorBudgetMessage = "";
    console.log("state.idBudget ", state.id);
    // if (state.id === '') {
    //   console.log("state.idBudget === ''")
    //   setState((prev) => ({
    //     ...prev,
    //     amount: Number(value),
    //   }))
    // }else if (state.id !== '') {
    //   console.log("entra a con state.idBudget !== ''")
    //   setState((prev) => ({
    //     ...prev,
    //     //budget: Number(value),
    //     amount: Number(value),
    //   }))

    if (state.padre !== "") {
      errorBudget = _.isEmpty(value);
      const availableAmount =
        _.find(props.budgets, { id: state.padre }).available || 0;
      console.log("availableAmount", availableAmount);
      console.log("value", value);

      //const newAvailable = availableAmount+props.campaignupd.budget
      let prevBud = props.budgets.find((element) => element.id == state.id);
      //if (value > newAvailable) {
      let ava = 0;
      if (prevBud) {
        ava = prevBud.available;
      }
      if (parseFloat(value) > availableAmount + ava) {
        console.log("Condicion value > availableAmount");
        errorBudget = true;
        errorBudgetMessage = "Monto no disponible en presupuesto padre";
      }
      if (parseFloat(value) < 0) {
        console.log("Condicion value < 0");
        errorBudget = true;
        errorBudgetMessage = "Presupuesto no válido";
      }
    }
    setState((prev) => ({
      ...prev,
      amount: value,
    }));

    // } else {
    //   console.log("Entra al else")
    //   errorBudget = true
    //   errorBudgetMessage = 'Selecciona un presupuesto'
    // }
    let errorM = "";

    console.log("_.isEmpty(e.value) ", _.isEmpty(value));
    if (_.isEmpty(value)) {
      errorM = "El presupuesto no puede estar vacio";
    }
    let mErrors = {
      //budget: errorBudget,
      errorBudgetMessage: errorM,
      errorBudget: _.isEmpty(value),
    };

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));
  };

  const handleTagChange = (value) => {
    console.log("handleTagChange");
    console.log("newValue", value);

    setState((prev) => ({
      ...prev,
      tags: value,
    }));
  };

  const closeModal = (e) => {
    if (e) e.preventDefault();
    analytics.modalesAcciones({
      accion: "Cancelar",
      ubicacion: "Presupuestos",
    });
    window.$.fancybox.close();
    setState({
      id: "",
      name: "",
      tags: [],
      amount: 0,
      edit: false,
      createAt: 0,
      padre: "",
      //budget: '',
      idBudget: "",
      nombrePadre: "",
      fatherAvailable: 0,
      available: 0,
    });
  };

  // const areValidInputs = () => {
  //   const mErrors = {
  //     name: state.name === '',
  //     amount: state.amount === 0,
  //   }

  //   setErrors((prev) => ({
  //     ...prev,
  //     ...mErrors,
  //   }))

  //   return !_.some(mErrors, (e) => e)
  // }
  const areValidInputs = () => {
    console.log("areValidInputs");
    console.log("state areValidInputs", state);
    let errorBudgetMessage = "";
    let errorBudget = state.amount === 0;

    // console.log("are valid funcion state.idBudget",state.idBudget)
    console.log("are valid funcion state.idBudget", state.id);
    if (state.amount < 0) {
      errorBudget = true;
      errorBudgetMessage = "Monto debe ser 0 o mayor";
    }
    if (!state.amount) {
      errorBudget = true;
      errorBudgetMessage = "El valor no puede venir vacio";
    }

    if (state.edit) {
      let prevBud = props.budgets.find((element) => element.id == state.id);
      let avaPrev = 0;
      if (prevBud) {
        avaPrev = prevBud.available;
      }
      let dif = Math.abs(prevBud.amount - prevBud.available);
      if (state.amount < dif) {
        errorBudget = true;
        errorBudgetMessage = "El monto maximo reducible es de " + dif;
      }
    }

    // if (state.padre !== '') {

    //   // const availableAmount =
    //   //   _.find(props.budgets, { id: state.idBudget }).available || 0

    //   // const newAvailable = availableAmount+props.campaignupd.budget
    //   const availableAmount = _.find(props.budgets, { 'id': state.padre }).available || 0
    //   console.log("availableAmount",availableAmount);
    //   //if (state.budget > newAvailable) {
    //     let prevBud = props.budgets.find(element => element.id == state.id);
    //     let avaPrev=0

    //     if(prevBud){
    //       avaPrev=prevBud.available
    //     }
    //     let dif=Math.abs(avaPrev.amount-avaPrev);
    //   if (state.amount > dif ){
    //     errorBudget = true
    //     errorBudgetMessage = 'Monto no disponible en presupuesto padre'
    //   }
    // }else{
    //   if (state.amount<state.available) {
    //     if(isFather(state.id)){
    //       errorBudget = true
    //       errorBudgetMessage = 'No cuenta con el disponible para reducirlo'
    //     }
    //   }
    //   console.log("areValidInputs else state.amount " , state.amount);
    //   console.log("areValidInputs else state available " , state.available);
    //   console.log("areValidInputs else state.padre !== ''")

    // }
    let mErrors = {
      //budget: errorBudget,
      name: state.name === "",
      errorBudgetMessage,
    };

    console.log("mErrors", mErrors);

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));

    return !_.some(mErrors, (e) => e);
  };

  const getAvailableAmountBYID = (id) => {
    let prevBud = props.budgets.find((element) => element.id == id);
    let actualAva = 0;

    props.budgets.map((budget) => {
      if (budget.id == prevBud.padre) {
        actualAva = actualAva + budget.available;
      }
    });
    let availableAmount = prevBud.amount - actualAva;
    console.log("handleUpsert actualAva", actualAva);
    console.log("handleUpsert availableAmount", availableAmount);
    return availableAmount;
  };
  const getAvaliableGeneralFather = (id) => {
    let actualAva = 0;
    let prevBud = props.budgets.find((element) => element.id == id);
    props.budgets.map((budget) => {
      if (budget.padre == id) {
        actualAva = actualAva + budget.available;
      }
    });
    let availableAmount = prevBud.amount - actualAva;
    console.log("handleUpsert actualAva", actualAva);
    console.log("handleUpsert availableAmount", availableAmount);
    return availableAmount;
  };
  const getAvailableAmountSon = (id) => {
    let prevBud = props.budgets.find((element) => element.id == id);
    let actualAva = 0;

    props.budgets.map((budget) => {
      if (budget.padre == id) {
        actualAva = actualAva + budget.available;
      }
    });
    let availableAmount = state.amount - actualAva;
    console.log("handleUpsert actualAva", actualAva);
    console.log("handleUpsert availableAmount", availableAmount);
    return availableAmount;
  };
  // const getAmount = () =>{
  //   // console.log("mostrarPadre padre",padre);
  //   // console.log("mostrarPadre bud",bud);
  //   let found = bud.find(element => element.id == padre);
  //   if(!found){
  //     return "";
  //   }
  //   else{
  //     return found.title;
  //   }

  // }
  const isFather = (id) => {
    let found = props.budgets.find((element) => element.padre == id);
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpsert = (e) => {
    console.log("estado final", state);
    e.preventDefault();
    console.log("areValidInputs()", areValidInputs());
    if (!areValidInputs()) return;

    let budgetId = state.id;
    console.log("state.edit ", state.edit);
    if (budgetId === "") {
      budgetId = v4();
    }
    analytics.modalesAcciones({
      accion: "Confirmar",
      ubicacion: "Presupuestos",
    });
    console.log("handleUpsert state ", state);
    console.log("handleUpsert props ", props);
    // console.log("handleUpsert props ",props.budgets);
    // let fatherAvailable=0;
    // let availableAmount=0;
    // if(state.padre && state.edit==false){
    //   let found = props.budgets.find(element => element.id == state.padre);
    //   console.log("handleUpsert found ", found);
    //   fatherAvailable=found.available-state.amount;
    //   console.log("handleUpsert fatherAvailable ", fatherAvailable);
    // }

    // if(state.padre && state.edit==true){
    //   let prevBud = props.budgets.find(element => element.id == state.id);
    //   console.log("handleUpsert prevBud", prevBud);
    //   let foundPadre = props.budgets.find(element => element.id == state.padre);
    //   console.log("handleUpsert foundPadre", foundPadre);

    //   if(prevBud.available>state.amount){
    //     let dif=state.amount-prevBud.available;
    //     console.log("handleUpsert if dif ",dif);
    //     fatherAvailable=foundPadre.available+Math.abs(dif);
    //     console.log("handleUpsert dif dif fatherAvailable",fatherAvailable);
    //   }else{

    //     let dif=state.amount-prevBud.available;
    //     console.log("handleUpsert else dif ",dif);
    //     // if(dif===0){
    //     //   dif=state.amount;
    //     // }
    //       fatherAvailable=getAvaliableGeneralFather(state.padre)-dif;
    //       console.log("handleUpsert else getAvailableAmountBYID(state.padre) ",getAvaliableGeneralFather(state.padre) );
    //       console.log("handleUpsert else fatherAvailable ", fatherAvailable);
    //     //   availableAmount=state.amount
    //     // }

    //     console.log("handleUpsert else dif fatherAvailable",fatherAvailable);
    //   }
    //   if(isFather(state.id)){
    //     availableAmount=getAvailableAmountSon(state.id);
    //   }else{
    //     availableAmount=state.amount
    //   }
    // }

    // if(!state.padre && state.edit==true){
    //   console.log("handleUpsert !state.padre && state.edit==true")
    //   let prevBud = props.budgets.find(element => element.id == state.id);
    //   let actualAva=0;
    //   props.budgets.map((budget) => {
    //     if(budget.padre==state.id){
    //       actualAva=actualAva+budget.available;
    //     }
    //   })
    //   availableAmount=state.amount-actualAva;
    //   console.log("handleUpsert actualAva",actualAva)
    //   console.log("handleUpsert availableAmount",availableAmount)

    // }

    let fatherAvailable = 0;
    let availableAmount = 0;

    let prevBud = props.budgets.find((element) => element.id == state.id);

    if (prevBud) {
      if (prevBud.amount > state.amount) {
        let dif = state.amount - prevBud.amount;
        availableAmount = prevBud.available + dif;
      } else {
        let dif = prevBud.amount - state.amount;
        availableAmount = prevBud.available - dif;
      }
    }

    if (props.currentUser !== "") {
      const accountInfo = _.find(props.accounts, { id: props.currentAccount });

      console.log("handleUpsert state antes de enviar ", state);

      console.log("Obteniendo info del usuario");
      Auth.currentAuthenticatedUser().then((userdata) => {
        console.log("UserData", userdata);
        props._createBudget(
          budgetId,
          state.name,
          props.currentAccount,
          state.amount,
          state.tags,
          1,
          state.createAt,
          state.edit,
          state.padre,
          //state.budget,
          //state.idBudget,
          availableAmount,
          state.idBudget,
          state.nombrePadre,
          fatherAvailable,
          props.budgets,
          props.currentUser,
          idmod,
          mod,
          props.accounts,
          userdata.attributes.email
        );
      });
    } else {
      Auth.currentAuthenticatedUser().then((userdata) => {
        console.log("UserData", userdata);
        props._pwaUpsertBudget(
          budgetId,
          state.name,
          props.currentPWAAccount,
          state.amount,
          state.tags,
          1,
          state.edit,
          state.padre,
          //state.budget,
          state.idBudget,
          state.nombrePadre,
          fatherAvailable,
          props.budgets,
          userdata.attributes.email
        );
      });
    }

    closeModal();
  };

  let accountTags = [];

  if (props.accountTags) {
    accountTags = props.accountTags.map((tag) => ({
      value: tag.id,
      label: tag.name,
    }));
  }

  let budgets = [];
  let filtroPresupuestoPadre = props.budgets.filter((item) => {
    return !item.padre;
  });

  //console.log("filtro,filtroPresupuestoPadre", filtroPresupuestoPadre);

  props.budgets.map((budget) => {
    console.log("filtroPresupuestoPadre", budget);
    budgets = [
      ...budgets,
      {
        value: budget.id,
        label: (
          <NumberFormat
            prefix={"$"}
            displayType={"text"}
            value={budget.amount}
            thousandSeparator={true}
            renderText={(value) => (
              <>
                {budget.title}: {value}{" "}
                <NumberFormat
                  prefix={"$"}
                  displayType={"text"}
                  value={budget.available || 0}
                  thousandSeparator={true}
                  renderText={(value) => `(Disponible: ${value})`}
                />
              </>
            )}
          />
        ),
        nombrePadre: budget.title,
      },
    ];
  });

  // const currentBudget = () =>budgets.filter(

  //   (budget) => budget.value === state.padre
  //   (budget) => budget.label === state.padre
  // )
  const currentBudget = (id) => {
    if (id != "") {
      console.log("currentBudget id", state.id);
      console.log("currentBudget state.padre", state.padre);
      console.log("currentBudget state.idbudget", state.id);
      const found = budgets.find((element) => element.value == state.padre);
      console.log("currentBudget found", found);
      // setState(prev => ({
      //   ...prev,
      //   idBudget: id,
      // }))
    } else {
    }
    return budgets.filter(
      (budget) => budget.value === state.padre
      //   (budget) => budget.label === state.padre
    );

    // setState((prev) => ({
    //   ...prev,
    //   idBudget: option.value,
    //   padre: option.value,
    //   nombrePadre: option.nombrePadre,
    // }))
  };

  const handleBudgetSelectChange = (option) => {
    console.log("option handleBudgetSelectChange", option);
    let found = props.budgets.find((element) => element.padre == option.value);
    setState((prev) => ({
      ...prev,
      // idBudget: option.value,
      padre: option.value,
      nombrePadre: option.nombrePadre,
      // available:found.available
    }));
  };

  return (
    <div
      id="new-budget-modal"
      style={{ display: "none" }}
      className={classnames("upsert-modal h-75")}
    >
      <div className="foot-mnc">
        <div className="formOneStep">
          <div className="titleOneStep">
            <p className="h3">
              {state.edit
                ? "Actualizemos tu presupuesto."
                : "Creemos un nuevo presupuesto."}
            </p>
          </div>

          <div className="bodyOneStep">
            <div className="oneColBody">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Ponle un nombre a tu presupuesto
                </Label>
                <Input
                  type="text"
                  name="name"
                  className="input"
                  value={state.name}
                  onChange={handleInputChange}
                  placeholder="P. ejemplo presupuesto para madres solteras"
                  invalid={errors.name}
                />
                <FormFeedback>Ingresa un nombre</FormFeedback>
              </FormGroup>
            </div>

            <div className="oneColBody">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Ingresa la cantidad que deseas solicitar
                </Label>
                <div
                  className={classnames("d-flex align-items-center", {
                    "is-invalid": errors.errorBudgetMessage,
                  })}
                >
                  {/* <span className='mr-2'>$</span> */}
                  <CurrencyInput
                    intlConfig={{ locale: "es-GT", currency: "USD" }}
                    // min="0"
                    prefix="$"
                    // type='number'
                    name="amount"
                    //pattern='[0-9]*'
                    className="input"
                    style={{
                      color: "#05252d",
                    }}
                    value={state.amount}
                    onValueChange={handleBudgetInputChange}
                    placeholder="P. ejemplo 1000"
                    invalid={errors.errorBudget}
                  />
                </div>
                <FormFeedback>{errors.errorBudgetMessage}</FormFeedback>
              </FormGroup>
            </div>

            <div className="oneColBody">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Ingresa los tags que deseas
                </Label>
                <CreatableSelect
                  value={state.tags}
                  options={accountTags}
                  onChange={handleTagChange}
                  placeholder="Selector de tags"
                />
              </FormGroup>
            </div>

            {/*  <div className='oneColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '10px',
                  }}
                >
                  Selecciona un presupuesto padre
                </Label>
                  <Select
                    options={budgets.filter(
                        (budget) => (budget.value !== state.id)
                     )}
                    value={currentBudget(state.id)}
                    placeholder='Ninguno'
                    onChange={(handleBudgetSelectChange)}
                    // className={classnames({
                    //   'is-invalid': errors.objetivo,
                    // })}
                  />
                <FormFeedback>Ingresa un presupuesto padre</FormFeedback>
              </FormGroup>
            </div> */}
          </div>

          <div className="footOneStep w-100 footOneStepModal4">
            <a href="#" className="btnCancelOS firstBtn" onClick={closeModal}>
              Cancelar
            </a>

            <a
              href="#"
              className="btnConfirmOS secondBtn"
              onClick={handleUpsert}
            >
              Confirmar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.budgets.edit,
  accounts: state.accounts.list,
  accountTags: state.accounts.tags,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  budgets: state.budgets.list,
});

const mapDispatchToProps = (dispatch) => ({
  _createBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    createAt,
    edit,
    padre,
    availableAmount,
    idBudget,
    nombrePadre,
    fatherAvailable,
    budgets,
    user,
    idmod,
    mod,
    accounts,
    userEmail
  ) =>
    dispatch(
      createBudget(
        id,
        title,
        account,
        amount,
        tags,
        status,
        createAt,
        edit,
        padre,
        availableAmount,
        idBudget,
        nombrePadre,
        fatherAvailable,
        budgets,
        user,
        idmod,
        mod,
        accounts,
        userEmail
      )
    ),
  _pwaUpsertBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    edit,
    userEmail
  ) =>
    dispatch(
      pwaUpsertBudget(id, title, account, amount, tags, status, edit, userEmail)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsertBudget);
