import React from "react";
import { connect } from "react-redux";

import "./assets/css/styles.css";

import FinCamp from "../finCamp";
import NameCamp from "../nameCamp";
import PagoCamp from "../pagoCamp";
import TagsCamp from "../tagsCamp";
import InfoCamp from "../infoCamp";
import MedioCamp from "../medioCamp";
import FinalCamp from "../finalCamp";
import FechasCamp from "../fechasCamp";
import BudgetCamp from "../budgetCamp";
import ObjetivoCamp from "../objetivoCamp";
import CategoryCamp from "../categoryCamp";
import ObservationCamp from "../observationCamp";
import RegistrerCamp from "../registerCamp";
import Dashboard from "../dashboard";

const returnStep = (step, modal) => {
  if (step) {
    switch (step) {
      case 2:
        return <MedioCamp clase="component" />;
      case 3:
        return <FechasCamp clase="component" />;
      case 4:
        return <ObjetivoCamp clase="component" />;
      case 5:
        return <BudgetCamp />;
      case 6:
        return <TagsCamp clase="component" />;
      case 7:
        return <FinCamp clase="component" />;
      case 8:
        return <PagoCamp clase="component" />;
      case 9:
        return <FinalCamp clase="component" modal={modal} />;
      case 11:
        return <CategoryCamp clase="component" />;
      case 12:
        return <ObservationCamp clase="component" />;
      case 13:
        return <RegistrerCamp clase="component" />;
      case 21:
        return <Dashboard clase="component" />;

      default:
        return <NameCamp clase="component" />;
    }
  } else {
    return <NameCamp clase="component" />;
  }
};

const Campana = (props) => (
  <div className="contenedorCrearCampSinReg">
    <div className="form-margin">
      <div className="left-form">
        {returnStep(props.campaign.step, props.modal)}
      </div>

      <InfoCamp />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  campaign: state.campaign,
});

export default connect(mapStateToProps)(Campana);
