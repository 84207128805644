import React from "react";
import { useSelector } from "react-redux";
import { SimpleText } from "./simpleText";
import DetailColEdit from "components/DetailCol";

const CustomCell = ({
  row,
  handleDeleteTemplate,
  handleEditVisualiser,
  sendDuplicateRow,
  editData,
  editAttachmentAction,
  permissions,
}) => {
  const propsEdit = useSelector((state) => state.templates.edit);
  const dataUser = useSelector((state) => state.templates.userInfo);
  const budgets = useSelector((state) => state.templates.refreshBudgets);

  if (row?.name === "Totales") return <></>;

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "-90px",
        }}
      >
        <DetailColEdit
          // text={row.name}
          flag={false}
          activate={true}
          id={"name"}
          flagAction={() => {}}
          deleteData={() => handleDeleteTemplate(row.id, propsEdit)}
          deleteV={true}
          nameColumn={row}
          check={false}
          prints={false}
          getPrints={() => {}}
          editInfo={() => handleEditVisualiser(row)}
          edit={false}
          editAttachment={true}
          onEditValue={() => {}}
          duplicate={true}
          editAttachmentAction={() => editAttachmentAction(row)}
          sendDuplicate={() => sendDuplicateRow(row, propsEdit)}
          approveConfirmation={() => {}}
          editable={false}
          type={"text"}
          permissions={permissions}
        />
        {row.name === "" && (
          <i
            className="fa fa-exclamation-circle text-danger"
            aria-hidden="true"
            style={{ marginRight: "8px" }}
          ></i>
        )}
        <SimpleText
          value={row.name}
          id={{ id: row.id, campo: "nameP" }}
          type={"text"}
          editable={true}
          nameColumn={row}
          editCell={row.name === ""}
          onEditValue={(value) => editData(value, propsEdit, budgets, dataUser)}
          className={"simpletext-name"}
        />
      </div>
    </>
  );
};

export default CustomCell;
