import React, { useEffect, useState } from "react";
import { clearCampaignupd, msgsuccess } from "redux/actions/dashboard.actions";
import { connect } from "react-redux";
import { withUtils } from "utils";
import classnames from "classnames";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
// import DataPicker from "../dataPicker";
import DataPicker from "../../dataPicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import status, { arrayPriority } from "config/status";
import {
  validateHoursAvailable,
  editCampaign,
} from "redux/actions/dashboard.actions";
import { Auth } from "aws-amplify";
import * as API from "helpers/api";
import _ from "lodash";

const ModalUpdatePriority = (props) => {
  const [state, setState] = useState({
    fechaIni: "",
    fechafin: "",
    priority: 3,
  });
  const [errors, setErrors] = useState({
    fechaIni: false,
    fechafin: false,
    priority: false,
  });
  const { setRefresh } = props;
  const optionsToShow = arrayPriority.filter((item) => item.show);
  const defaultState = arrayPriority.find((item) => item.id === state.priority);

  useEffect(() => {
    if (!_.isEmpty(props.campaignupd)) {
      setState({
        ...props.campaignupd,
        priority: props.campaignupd.priority,
        fechaIni: props.campaignupd.fechaIni,
        fechafin: props.campaignupd.fechafin,
        id: props.campaignupd.id,
      });
    }
  }, [props.campaignupd]);

  const handleClick = async () => {
    let username = await Auth.currentAuthenticatedUser();
    let accounts = await API.getAccounts(username.username);
    let findAccount = accounts.data.accounts.find(
      (item) => item.id === props.currentAccount
    );

    const responseModal = await props._validateHoursAvailable(
      props.currentAccount,
      accounts.data.accounts,
      state.fechaIni,
      state.priority,
      false
    );
    if (!responseModal) {
      props
        ._editCampaign(state.id, state.fechaIni, state.fechafin, state.priority)
        .then(() => {
          setRefresh();
          props._clearCampaignupd();
          props._msgsuccess();
          props.setShowModalPriority(false);
        });
    }
  };
  const handleSelect = async (e) => {
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      priority: Number(value),
    }));
    const dataResponse = await props._validateHoursAvailable(
      props.currentAccount,
      props.accounts,
      state.fechaIni,
      Number(value),
      false
    );
    setErrors((prev) => ({
      ...prev,
      priority: dataResponse,
    }));
  };

  const handleDateChange = async (startDate, endDate) => {
    const dataResponse = await props._validateHoursAvailable(
      props.currentAccount,
      props.accounts,
      startDate,
      state.priority,
      false
    );
    setErrors((prev) => ({
      ...prev,
      priority: dataResponse,
    }));

    // await calculateHoursAvailable(startDate);
    setState((prev) => ({
      ...prev,
      fechaIni: startDate,
      fechafin: endDate,
    }));
    return;
  };

  const closeModal = () => {
    Swal.close();
    props.setShowModalPriority(false);
  };

  return (
    <div className="blur-div">
      <div className="content-modal modalPriority">
        <div className="header-modal">
          <h3 className="titleModal">Actualiza tu prioridad</h3>
        </div>
        <div className="body-modal">
          <div
            className="oneColBody"
            id="cfecha"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              // flexWrap: "wrap",
            }}
          >
            <FormGroup className="fechaSelect">
              <Label
                className="texto_corregido"
              // style={{
              //   color: '#05252d',
              //   fontWeight: 500,
              //   marginBottom: '5px',
              // }}
              >
                Elige un rango de fechas
              </Label>
              <DataPicker
                disabled={false}
                onChangeDate={handleDateChange}
                fechaIni={state.fechaIni}
                fechafin={state.fechafin}
                className={classnames({
                  "is-invalid": errors.fechaIni || errors.fechafin,
                })}
              />
              <FormFeedback>sss</FormFeedback>
            </FormGroup>
            <FormGroup className="priority-col">
              <Label
                style={{
                  color: "#05252d",
                  fontWeight: 500,
                  marginBottom: "5px",
                }}
              >
                Prioridad de campa&ntilde;a
              </Label>
              <Autocomplete
                options={[...optionsToShow]}
                getOptionLabel={(option) => option.name}
                value={defaultState}
                onChange={(event, newValue) => {
                  const selectedValue = newValue ? newValue.id : state.priority;
                  handleSelect({
                    target: { name: "priority", value: selectedValue },
                  });
                }}
                className={classnames({
                  "is-invalid": errors.priority,
                })}
                disabled={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    label="Prioridad"
                    variant="outlined"
                    name="Normal"
                  />
                )}
              />
              <FormFeedback>Eliga una opci&oacute;n valida</FormFeedback>
            </FormGroup>
          </div>
        </div>
        <div className="footer-Modal">
          <button
            className="btn btn_cancel btnCancelOS"
            onClick={(e) => closeModal()}
          >
            Cancelar
          </button>
          <button className="btn btn_save btnConfirmOS" onClick={handleClick}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  accounts: state.accounts.list,
  campaignupd: state.campaignupd,
  campaignuAdd: state.campaignuAdd,
  filter: state.campaigns.filter,
  mediumbanners: state.mediumbanners,
  currentAccount: state.accounts.default,
  mediumbannerstype: state.mediumbannerstype,
  filesPauta: state.filesPautaReducer,
  files: state.creative.files,
  budget_spent: state.budget_spentReducer,
  currentUser: state.app.current_user,
});
const mapDispatchToProps = (dispatch) => ({
  _msgsuccess: () => dispatch(msgsuccess()),
  _editCampaign: (id, since, last, priority) =>
    dispatch(editCampaign(id, since, last, priority)),
  _clearCampaignupd: () => dispatch(clearCampaignupd()),
  _validateHoursAvailable: (
    currentAccount,
    accounts,
    startDate,
    priority,
    edit
  ) =>
    dispatch(
      validateHoursAvailable(
        currentAccount,
        accounts,
        startDate,
        priority,
        edit
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ModalUpdatePriority));
