import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PageLoader from '../page_loader'

const Loader = props => props.isLoading && <PageLoader />

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoading: state.loader.isLoading
})

export default connect(mapStateToProps)(Loader)