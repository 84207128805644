import React, { useEffect, useState } from "react";
import * as Modal from "redux/actions/modal.actions";
import { connect } from "react-redux";
import { withUtils } from "utils";
import analytics from "../../helpers/analytics";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import { Tooltip } from "antd";
import ReactDataTable, {
  NumberFormat,
  OptionsSelectedEdit,
} from "@easygosanet/react-datatable";
import { showLoader } from "redux/actions/loader.actions";
import { Auth } from "aws-amplify";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import _, { find } from "lodash";
import AddAssigment from "./addAssigment";
import Swal from "sweetalert2";

const DetailCol = ({ text, onClick, permisos }) => (
  <div className="content-firstColumn">
    <div className="iconBand">
      {permisos.delete && (
        <Tooltip color="#002448" placement="top" title="Eliminar">
          <i className="fas fa-trash" onClick={() => onClick()} />
        </Tooltip>
      )}
    </div>
    <p className="name-fc">{text}</p>
  </div>
);

const AccessManagement = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [view, setView] = useState(false);
  const [awsClients, setAwsClients] = useState([]);
  const [roleNames, setRoleNames] = useState([]);
  const [userList, setUserList] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [optionsForm, setOptionsForm] = useState([["Cuentas"], ["Usuarios"]]);
  const [assignedUsersList, setAssignedUsersList] = useState([]);
  const [categories, setCategories] = useState([["Cuentas"], ["Rol"]]);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: false,
  });
  const [columns, setColumns] = useState([
    {
      id: "user",
      name: "Cuenta",
      selector: "user",
      sortable: true,
      center: true,
      tag: "user",
      // show: view ? false : true,
      visible: false,
      type: "text",
      omit: false,
      cell: (row) => (
        <DetailCol permisos={permissions} text={row.user} onClick={() => {}} />
      ),
    },
    {
      id: "role",
      name: "Rol",
      tag: "role",
      selector: "role",
      sortable: true,
      center: true,
      omit: false,
      show: false,
      type: "text",
      cell: (row) => (
        <OptionsSelectedEdit
          id={row.role}
          data={roleNames.map((el) => el.name)}
          value={row.role}
          nameColumn={"rol"}
          editable={true}
          onEditValue={() => {}}
        />
      ),
    },
    {
      id: "date",
      name: "Fecha último ingreso",
      show: false,
      tag: "date",
      selector: "date",
      omit: false,
      sortable: true,
      center: true,
      type: "text",
      style: {
        fontWeight: "normal",
      },
    },
  ]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupAdmin, setIsSupAdmin] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [showModalUsers, setShowModalUsers] = useState(false);
  const [showModalAccounts, setShowModalAccounts] = useState(false);
  const [permisosModal, setPermisosModal] = useState({
    createUser: false,
    createAccount: false,
    deleteUser: false,
  });
  useEffect(() => {
    permisos();
    getData();
  }, [props.currentAccount, props.currentUser]);

  const getRoleNames = async (obt, userNotAdmin) => {
    let responseRoleNames = await APILA.getRoleNames({
      account: props.currentAccount,
    });

    if (responseRoleNames.data.count > 0) {
      let filteredRoleNames;
      if (userNotAdmin && userNotAdmin === "notAdmin") {
        filteredRoleNames = responseRoleNames.data.roleNames.filter(
          (el) => el.id !== "Super Administrador" && el.id !== "Administrador"
        );
      } else {
        filteredRoleNames = responseRoleNames.data.roleNames.filter((el) =>
          obt === false ? el.id !== "Super Administrador" : el
        );
      }

      const array = filteredRoleNames.map((el) => ({
        id: el.id,
        name: el.name,
        checked: false,
      }));
      setRoleNames([...array]);
    }
  };

  const permisos = async () => {
    try {
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let permisosUser = await APILA.getPermitsByUser({
        user: sub,
      });
      const superAdminPermission = permisosUser.data.permits?.find(
        (permission) => permission.idRol === "Super Administrador"
      );
      if (superAdminPermission) {
        const response = await APILA.getRoleNameById({
          id: superAdminPermission.idRol,
        });
        const superAdminPermissions = response.data.rol[0].permissions;
        const gestionAccesosPermission = superAdminPermissions.find(
          (permission) => permission.name === "Gestión de accesos"
        );
        const permmissionAccounts = response.data.rol[0].permissions.find(
          (permission) => permission.name === "Cuentas"
        );
        setPermissions((state) => ({
          view: gestionAccesosPermission.view,
          approve: gestionAccesosPermission.approve,
          create: gestionAccesosPermission.create,
          delete: gestionAccesosPermission.delete,
          edit: gestionAccesosPermission.edit,
          export: gestionAccesosPermission.export,
          manageRoles: gestionAccesosPermission.manageRoles,
        }));
        setPermisosModal((prev) => ({
          ...prev,
          deleteUser: true,
          createAccount: permmissionAccounts.create,
          createUser: true,
        }));
      } else {
        const accountPermission = permisosUser.data.permits?.find(
          (permission) => permission.account === props.currentAccount
        );
        const roleId = accountPermission.idRol;
        const response = await APILA.getRoleNameById({ id: roleId });
        console.log("Response 213", response);
        const createUser = response.data.rol[0].id === "Administrador";
        const gestionAccesosPermission = response.data.rol[0].permissions.find(
          (permission) => permission.name === "Gestión de accesos"
        );
        const permmissionAccounts = response.data.rol[0].permissions.find(
          (permission) => permission.name === "Cuentas"
        );
        setPermisosModal((prev) => ({
          ...prev,
          deleteUser: false,
          createAccount: permmissionAccounts.create,
          createUser: createUser,
        }));
        setPermissions((state) => ({
          view: gestionAccesosPermission.view,
          approve: gestionAccesosPermission.approve,
          create: gestionAccesosPermission.create,
          delete: gestionAccesosPermission.delete,
          edit: gestionAccesosPermission.edit,
          export: gestionAccesosPermission.export,
          manageRoles: gestionAccesosPermission.manageRoles,
        }));
      }
    } catch (err) {
      setPermissions((state) => {
        return {
          approve: false,
          create: false,
          delete: false,
          edit: false,
          export: false,
          manageRoles: false,
          view: false,
        };
      });
    }
  };
  const handleFilterTextChange = (text) => {
    analytics.busqueda({
      ubicacion: "Roles",
      busqueda: text,
    });
    // filterAccounts2(value);
    if (text === "") {
      setAccounts(accountsList);
      setAwsClients(userList);
      setAssignedUsers(assignedUsersList);
      return;
    }

    if (!view) {
      const dataFilter = accountsList.filter((record) => {
        const values = [record.name];
        return values.some((value) =>
          String(value).toLowerCase().includes(text.toLowerCase())
        );
      });
      setAccounts(dataFilter);

      if (dataFilter.length === 0) {
        const dataUserFilter = userList.filter((record) => {
          const values = [record.format, record.email, record.name];
          return values.some((value) =>
            String(value).toLowerCase().includes(text.toLowerCase())
          );
        });

        const accountsIncludesUsers = accountsList.filter((account) => {
          return account?.members?.some((member) =>
            dataUserFilter.some((user) => user.id === member)
          );
        });
        setAwsClients(dataUserFilter);
        setAccounts(accountsIncludesUsers);

        if (dataUserFilter.length === 0) {
          const assigmetFilterRoles = assignedUsersList.filter((record) => {
            const values = [record.role];
            return values.some((value) =>
              String(value).toLowerCase().includes(text.toLowerCase())
            );
          });
          const usersIncludesAssgmentes = userList.filter((user) => {
            return assigmetFilterRoles.some(
              (record) => record.user === user.id
            );
          });
          const accountsIncludesUsers = accountsList.filter((account) => {
            return account?.members?.some((member) =>
              usersIncludesAssgmentes.some((user) => user.id === member)
            );
          });
          setAssignedUsers(assigmetFilterRoles);
          setAccounts(accountsIncludesUsers);
          setAwsClients(usersIncludesAssgmentes);
        }
      }
    } else {
      const dataFilter = userList.filter((record) => {
        const values = [record.format, record.email, record.name];
        return values.some((value) =>
          String(value).toLowerCase().includes(text.toLowerCase())
        );
      });
      setAwsClients(dataFilter);
      if (dataFilter.length === 0) {
        const dataFilterAccounts = accountsList.filter((account) => {
          return account.name.toLowerCase().includes(text.toLowerCase());
        });
        const idsMembers = [
          ...new Set(dataFilterAccounts.flatMap((ele) => ele?.members || [])),
        ];
        const usersFilter = userList.filter((user) =>
          idsMembers.includes(user.id)
        );
        const idsAccounts = dataFilterAccounts.map((ele) => ele.id);
        const assignedUsersFilter = assignedUsersList.filter((user) =>
          idsAccounts.includes(user.account)
        );

        setAssignedUsers(assignedUsersFilter);
        setAwsClients(usersFilter);
        setAccounts(dataFilterAccounts);
      }
    }
  };

  const handleShowColumnChange = (column, show) => {
    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    aux[index].omit = show;
    setColumns(aux);
  };

  const formatClients = async () => {
    const responseClients = await APILA.getAllAccountsAWS();
    const { clients } = responseClients.data;

    const clientsArr = clients.map((client_response) => {
      const nameAttr = find(client_response.Attributes, { Name: "name" });
      const emailAttr = find(client_response.Attributes, { Name: "email" });

      const nameValue = nameAttr ? nameAttr.Value : "";
      const emailValue = emailAttr ? emailAttr.Value : "";
      const format = nameValue ? `${nameValue} (${emailValue})` : emailValue;

      return {
        id: client_response.Username,
        name: nameValue,
        email: emailValue,
        format: format,
        checked: false,
        enable: client_response.Enabled,
      };
    });
    return clientsArr;
  };

  const getData = async () => {
    try {
      props._showLoader(true);

      const sub = props.currentUser;
      const permitsResponse = await APILA.getPermitsAll();
      const users = permitsResponse.data.permisos;

      const dataRoles = [...new Set(users.map((ele) => ele.role))].map(
        (role) => ({
          nombrePadre: "Rol",
          name: role,
        })
      );

      const usuarioSession = users.filter((us) => us.user === sub);
      const isAdmin = _.some(
        usuarioSession,
        (ele) => ele.role === "Administrador"
      );
      const supAdmin = _.some(
        usuarioSession,
        (ele) => ele.role === "Super Administrador"
      );
      const admin = isAdmin && !supAdmin;

      const found = _.find(
        usuarioSession,
        (ele) => ele.account === props.currentAccount
      );
      const response = await APILA.getRoleNameById({ id: found.idRol });
      const permisosResponse = response.data.rol;

      const clientsFormat = await formatClients();
      const usuarios = clientsFormat.map((client) => ({
        nombrePadre: "Usuario",
        name: client.email,
      }));

      const mUsers = _.sortBy(clientsFormat, ["email"]);
      const dataCon = [...dataRoles, ...usuarios];
      if (admin) {
        setIsAdmin(admin);
        await handleAdminMode(
          mUsers,
          users,
          props.currentAccount,
          permisosResponse[0]
        );
      } else if (supAdmin) {
        await handleSupAdminMode(mUsers, users);
      }
      setDataFilter(dataCon);
    } catch (error) {
    } finally {
      props._showLoader(false);
    }
  };

  const handleAdminMode = async (mUsers, users, currentAccount, permiso) => {
    const upsertAdmin =
      permiso.id === "Administrador" || permiso.rolPadre === "Administrador";
    getRoleNames(false, upsertAdmin ? "isAdmin" : "notAdmin");
    const currentAccountData = _.get(
      await API.getAccountById({
        id: currentAccount,
      }),
      "data.account[0]",
      null
    );

    const filteredUserRoles = users.filter(
      (user) =>
        user.account === currentAccountData.id &&
        user.idRol !== "Super Administrador"
    );

    const filteredUserIds = filteredUserRoles.map((user) => user.user);
    const filteredUserAccounts = mUsers.filter((user) =>
      filteredUserIds.includes(user.id)
    );
    const userAccounts = [];
    if (currentAccountData.members?.length > 0) {
      currentAccountData.members.forEach((member) => {
        const foundUserAccount = filteredUserAccounts.find(
          (user) => user.id === member
        );
        if (foundUserAccount) {
          userAccounts.push(foundUserAccount);
        }
      });
    }

    const accountListArray = [currentAccountData].map((account) => ({
      id: account.id,
      name: account.name,
      members: account.members,
      checked: false,
      active: account.active,
    }));

    setAccounts([currentAccountData]);
    setAwsClients(userAccounts);
    setUserList(userAccounts);
    setAccountsList(accountListArray);
    setAssignedUsers(filteredUserRoles); // !mal
    setAssignedUsersList(filteredUserRoles); // !mal
  };

  const handleSupAdminMode = async (mUsers, users) => {
    const responseAcc = await API.getAllAcounts();
    getRoleNames(true);
    setIsSupAdmin(true);
    const mAccounts = _.sortBy(responseAcc.data.accounts, ["name"]);
    const accountListArray = mAccounts.map((account) => ({
      id: account.id,
      name: account.name,
      members: account.members,
      checked: false,
      active: account.active,
    }));
    setAccounts([...mAccounts]);
    setAccountsList(accountListArray);
    setAwsClients(mUsers);
    setUserList(mUsers);
    setAssignedUsers(users);
    setAssignedUsersList(users);
  };

  const userFormat = (userId, accountId) => {
    const userFound = awsClients.find((ele) => ele.id === userId);

    const roleUsuario = assignedUsers.find(
      (ele) => ele.user === userId && ele.account === accountId
    );

    if (roleUsuario && userFound) {
      const usuario = {
        idPermiss: roleUsuario.id,
        id: roleUsuario.user,
        email: userFound.email,
        name: userFound.name,
        account: roleUsuario.account,
        format: `${userFound.name} (${userFound.email})`,
        date: roleUsuario.date,
        rol: roleUsuario.role,
      };
      return usuario;
    }
    return null;
  };

  const deleteAssigment = async (row) => {
    // console.log('Los datos de la columna', row);
    let title =
      "Está seguro que desea eliminar el rol y la asignación de " +
      row.user +
      "?";
    props._showConfirmationModal(
      "Eliminar asignación de cuenta y rol",
      title,
      async () => {
        try {
          props._showLoader(true);
          const accountFound = accounts.find((ele) => ele.id === row.account);
          let body = {
            id: row.idPermiss,
          };
          await APILA.deleteRol(body);
          if (accountFound) {
            const members = accountFound?.members || [];
            const membersUpdated = members.filter(
              (member) => member !== row.id
            );
            await API.updateAccountMembers({
              id: row.account,
              members: membersUpdated,
            });
          }
          analytics.accionesTabla({
            accion: "Eliminar",
            ubicacion: "Roles",
          });

          setAccounts((prev) => {
            const index = prev.findIndex((ele) => ele.id === row.account);
            const aux = prev.map((ele) => ele);
            aux[index].members = aux[index].members.filter(
              (ele) => ele !== row.id
            );
            return aux;
          });

          Swal.fire({
            icon: "success",
            title: "Asignación eliminada",
            showConfirmButton: true,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se pudo eliminar la asignación",
            showConfirmButton: true,
          });
        } finally {
          props._showLoader(false);
        }
      }
    );
  };

  const editRoleAssigment = async (value, row) => {
    console.log("first", value, row);
    let title =
      "Está seguro que desea editar el rol de " + value[0].column + "?";
    const foundAccount = accounts.find((ele) => ele.id === row.account);
    if (!foundAccount.active) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se puede editar el rol de un usuario en una cuenta inactiva",
        showConfirmButton: true,
      });
    }
    props._showConfirmationModal("Editar rol", title, async () => {
      try {
        props._showLoader(true);
        const valueData = value[0];
        analytics.accionesTabla({
          accion: "Editar con doble clic",
          ubicacion: "Roles",
        });

        const idRol = roleNames.find((ele) => ele.name === valueData.value).id;
        const body = {
          id: row.idPermiss,
          account: row.account,
          user: row.id,
          role: valueData.value,
          idRol: idRol,
        };
        await APILA.insertPermisos(body);
        setAssignedUsers((prev) => {
          const index = prev.findIndex((ele) => ele.id === row.idPermiss);
          const aux = prev.map((ele) => ele);
          aux[index].role = valueData.value;
          aux[index].idRol = idRol;
          return aux;
        });
        // props._newEmailValidation(
        //   props.currentAccount,
        //   "Permisos",
        //   body,
        //   props.currentUser,
        //   props.accounts,
        //   "Edición"
        // );
        Swal.fire({
          icon: "success",
          title: "Rol editado",
          showConfirmButton: true,
        });
      } catch (error) {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo editar el rol",
          showConfirmButton: true,
        });
      } finally {
        props._showLoader(false);
      }
    });
  };

  const mapData = () => {
    const column1 = columns.find((value) => value.id === "user");
    const column2 = columns.find((value) => value.id === "role");
    const column3 = columns.find((value) => value.id === "date");

    const columns3 = [
      {
        id: "user",
        name: "Usuario",
        selector: "user",
        sortable: true,
        tag: "user",
        omit: column1.omit,
        position: column1.position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        type: "text",
        cell: (row) => (
          <DetailCol
            text={row.user}
            permisos={permissions}
            onClick={() => {
              deleteAssigment(row);
            }}
          />
        ),
      },
      {
        id: "role",
        name: "Rol",
        selector: "role",
        sortable: true,
        tag: "role",
        omit: column2.omit,
        position: column2.position,
        center: true,
        style: {
          fontWeight: "bold",
          backgroundColor: "#eff0f2",
        },
        type: "text",
        cell: (row) =>
          permissions.edit ? (
            <OptionsSelectedEdit
              id={row.id}
              data={roleNames.map((ele) => ele.name)}
              value={row.role}
              editable={permissions.edit}
              nameColumn={row.user}
              onEditValue={(data) => editRoleAssigment(data, row)}
            />
          ) : (
            row.role
          ),
      },
      {
        id: "date",
        omit: column3.omit,
        position: column3.position,
        name: "Fecha último ingreso",
        tag: "date",
        selector: "date",
        sortable: true,
        center: true,
        type: "text",
      },
    ];

    const customStyles1 = {
      headCells: {
        style: {
          backgroundColor: "#eff0f2",
          "&:nth-of-type(2n)": {
            backgroundColor: "#eff0f2",
          },
        },
      },
      cells: {
        style: {
          "&:nth-of-type(2n)": {
            backgroundColor: "#eff0f2",
          },
        },
      },
    };

    const elemetos = accounts.map((account, idx) => {
      const members = account?.members || [];
      const data = members.reduce((acc, member) => {
        const userData = userFormat(member, account.id);
        if (userData) {
          const date = userData.date;
          const dateFormat = date
            ? date.toString().match(/[0-9]+\/[0-9]+\/[0-9]+/gi)
            : "Sin asignar";
          acc.push({
            id: userData.id,
            idPermiss: userData.idPermiss,
            user: userData.format,
            account: userData.account,
            role: userData.rol || "Sin asignar",
            date: dateFormat,
          });
        }
        return acc;
      }, []);

      return (
        <div key={idx} className="scroll_agrupaciones">
          <h2>{account.name}</h2>
          <ReactDataTable
            columns={columns3}
            data={data}
            customStyles={customStyles1}
            persistTableHead
            expandable
            pagination
            noHeader
          />
        </div>
      );
    });

    return elemetos;
  };

  const mapDateUser = () => {
    const a0 = columns.filter((a) => a.id === "user");
    const a1 = columns.filter((a) => a.id === "role");
    const a2 = columns.filter((a) => a.id === "date");

    const columns1 = [
      {
        id: "account",
        name: "Cuenta",
        selector: "acconutName",
        sortable: true,
        omit: a0[0].omit,
        position: a0[0].position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        type: "text",
        cell: (row) => (
          <DetailCol
            permisos={permissions}
            text={row.acconutName}
            onClick={() => deleteAssigment(row)}
          />
        ),
      },
      {
        id: "role",
        name: "Rol",
        selector: "role",
        sortable: true,
        center: true,
        omit: a1[0].omit,
        position: a1[0].position,
        style: {
          fontWeight: "bold",
        },
        type: "text",
        cell: (row) =>
          permissions.edit ? (
            <OptionsSelectedEdit
              id={row.id}
              data={roleNames.map((el) => el.name)}
              value={row.role}
              editable={permissions.edit}
              nameColumn={row.user}
              onEditValue={(data) => editRoleAssigment(data, row)}
            />
          ) : (
            row.role
          ),
      },
      {
        id: "date",
        name: "Fecha último ingreso",
        selector: "date",
        sortable: true,
        omit: a2[0].omit,
        position: a2[0].position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        type: "text",
      },
    ];

    columns1.sort((a, b) => {
      return a.position - b.position;
    });

    const elementos = awsClients.map((user, idx) => {
      const arrayToShow = [];
      accounts.forEach((acc) => {
        const userFound = acc.members?.includes(user.id);
        const data = userFormat(user.id, acc.id);

        if (userFound && data) {
          const da = data.date;
          const c = da
            ? da.toString().match(/[0-9]+\/[0-9]+\/[0-9]+/gi)
            : "Sin asignar";
          arrayToShow.push({
            idUser: data.id,
            role: data.rol || "Sin asignar",
            date: c,
            id: data.id,
            idPermiss: data.idPermiss,
            user: data.format,
            account: data.account,
            acconutName: acc.name,
          });
        }
      });

      return (
        <div className="subTablas" key={idx}>
          <h2
            style={{ margin: "16px 0 45px 45px" }}
          >{`${user.name} (${user.email})`}</h2>
          <ReactDataTable
            columns={columns1}
            data={arrayToShow}
            key={awsClients.id}
            persistTableHead
            pagination
            noHeader
            expandable
          />
        </div>
      );
    });

    return elementos;
  };

  const groupedData = (value) => {
    analytics.agrupar({
      ubicacion: "Roles",
    });

    if (value === "Usuarios") {
      const data = [["Usuario"], ["Rol"]];
      setCategories(data);
      setView(true);
    } else {
      const data = [["Cuentas"], ["Rol"]];
      setCategories(data);
      setView(false);
    }
  };

  return (
    <>
      <div className="main-GestionRoles">
        <div className="barraSuperior">
          <DataTableUtils
            data={[]}
            action={
              permissions.create
                ? () => {
                    setShowModal(true);
                    analytics.Add({
                      ubicacion: "Roles",
                    });
                  }
                : null
            }
            actionIcon={
              permissions.create && (
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Crear asignación"
                >
                  <i className="fas fa-plus" />
                </Tooltip>
              )
            }
            onChangeFilterText={handleFilterTextChange}
          >
            <Tooltip
              color="#002448"
              placement="top"
              title="Exportar información"
            >
              <div
                onClick={() => {
                  analytics.Compartir({
                    ubicacion: "Roles",
                  });
                }}
              >
                {permissions.export && (
                  <Share
                    idImg={"root"}
                    appKey="2u78gno172idwbz"
                    contacts={[]}
                    data={[]}
                  />
                )}
              </div>
            </Tooltip>
            <Tooltip
              color="#002448"
              placement="top"
              title="Personalizar columnas"
            >
              <div id="shareBtn">
                <ShowColumn2
                  columns={columns}
                  showColumn={(data, data1) => {
                    analytics.ShowHide({
                      ubicacion: "Roles",
                    });
                    handleShowColumnChange(data, data1);
                  }}
                  showView={() => {}}
                  onSortChange={(positions) => {
                    analytics.Columnas({
                      ubicacion: "Roles",
                    });
                  }}
                  vistas={[]}
                  setVista={(nombre) => {
                    analytics.accionesVista({
                      ubicacion: "Roles",
                      accion: "Agregar",
                    });
                  }}
                  deleteView={(name) => {
                    analytics.accionesVista({
                      ubicacion: "Roles",
                      accion: "ELiminar",
                    });
                  }}
                  nameAc={""}
                />
              </div>
            </Tooltip>
            <Tooltip color="#002448" placement="top" title="Filtrar y agrupar">
              <div className="Filtro2 filtro3">
                <FilterColumn2
                  columns={columns}
                  data={dataFilter}
                  onFilterColumn={(data) => {}}
                  filterInfoCheck={[]}
                  options={optionsForm}
                  changeGrouped={(value) => groupedData(value)}
                  categories={categories}
                />
              </div>
            </Tooltip>
          </DataTableUtils>
        </div>

        <div className="contaner-tables">
          <div className="box-table">{!view ? mapData() : mapDateUser()}</div>
        </div>
      </div>
      {showModal && (
        <AddAssigment
          roleNames={roleNames}
          assignedUsersList={assignedUsersList}
          getData={getData}
          users={userList}
          permisos={permisosModal}
          accounts={accountsList}
          setShowModalUsers={setShowModalUsers}
          setShowModalAccounts={setShowModalAccounts}
          showModalAccounts={showModalAccounts}
          showModalUsers={showModalUsers}
          setShowModal={setShowModal}
          isAdmin={isAdmin}
          isSupAdmin={isSupAdmin}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(AccessManagement));
