import API from "@aws-amplify/api";

import { API_NAME } from "../config/api";
import { API_NAMELA } from "../config/api";

import * as Logger from "utils/logger";

const TAG = "API";

const globalOptions = {
  response: true,
};

/*
 * Clients
 */

export const getClients = () => {
  let options = {
    ...globalOptions,
    body: {},
  };

  Logger.log(TAG, "getClients", options);

  return API.post(API_NAME, "/getClients", options);
  // return API.post(API_NAME, '/getClients/allUsers', options)
};

/*
 * Accounts
 */

export const getAllAcounts = () => {
  let options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/accountsLets/allAccounts", options);
};

export const updateAccountMembers = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/accountsLets/updateAccountMembers", options);
};

export const getAccounts = (client) => {
  let options = {
    ...globalOptions,
    body: {
      client,
    },
  };

  return API.post(API_NAMELA, "/accountsLets/myAccounts", options);
};

export const createAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createAccount", options);

  return API.post(API_NAMELA, "/accountsLets/new", options);
};

export const updateAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateAccount", options);

  return API.post(API_NAMELA, "/accountsLets/update", options);
};

export const deleteAccount = (id) => {
  let options = {
    ...globalOptions,
  };

  Logger.log(TAG, "deleteAccount", options);

  return API.del(API_NAMELA, `/accountsLets/object/${id}`, options);
};

export const getAccountByName = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, `/accountsLets/getAccountByName`, options);
};
export const getAccountById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, `/accountsLets/getAccountById`, options);
};
/*
 * Bills
 */

export const getBillingByClient = (client) => {
  let options = {
    ...globalOptions,
    body: {
      client,
    },
  };

  Logger.log(TAG, "getBillingByClient", options);

  return API.post(API_NAME, "/getBillingByClient", options);
};

/*
 * Budgets
 */

export const getBudgets = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  return API.post(API_NAMELA, "/budgetLets/myBudgets", options);
};

export const myBudgetsModal = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  Logger.log(TAG, "myBudgetsModal", options);

  return API.post(API_NAMELA, "/budgetLets/myBudgetsModal", options);
};

export const updateAvailableLov = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  console.log("BODY update", body);
  return API.post(API_NAMELA, "/odooOvs/updateAvailableLov", options);
};

export const updateIdsCampaign = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/updateIdsCampaign", options);
};

export const getAllLovs = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getAllLovs", options);
};

export const deleteBudget = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/deleteBudget", options);
};

export const getCountBudgets = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/countBudget", options);
};

export const createBudget = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/new", options);
};

export const updateBudget = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateBudget", options);

  return API.post(API_NAMELA, "/budgetLets/update", options);
};

/*
 * Audiences
 */

export const getAudiences = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  return API.post(API_NAMELA, "/audiences/myAudiences", options);
};

export const createAudience = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createAudience", options);

  return API.post(API_NAMELA, "/audiences/new", options);
};

export const updateAudience = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateAudience", options);

  return API.post(API_NAMELA, "/audiences/update", options);
};

export const deleteAudience = (id) => {
  let options = {
    ...globalOptions,
  };

  Logger.log(TAG, "deleteAudience", options);

  return API.del(API_NAMELA, `/audiences/object/${id}`, options);
};

export const getCountAudiences = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  console.log("bodyyy", body);
  Logger.log(TAG, "getCountAudiences", options);

  return API.post(API_NAMELA, "/campaign/countAudiences", options);
};

/*
 * Categories
 */

export const getCategories = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  return API.post(API_NAMELA, "/categoriesLets/myCategoriesNew", options);
};

export const getCountCategories = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  console.log("bodyy", body);
  Logger.log(TAG, "getCountCategories", options);

  return API.post(API_NAMELA, "/campaign/countCategories", options);
};

export const createCategory = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createCategory", options);

  return API.post(API_NAMELA, "/categoriesLets/new", options);
};

export const updateCategory = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateCategory", options);

  return API.post(API_NAMELA, "/categoriesLets/update", options);
};

export const deleteCategory = (id) => {
  let options = {
    ...globalOptions,
  };

  Logger.log(TAG, "deleteCategory", options);

  return API.del(API_NAMELA, `/categoriesLets/object/${id}`, options);
};

/*
 * Profile
 */

export const getProfileInfo = (accessToken) => {
  let options = {
    ...globalOptions,
    body: {
      accessToken,
    },
  };

  Logger.log(TAG, "getProfileInfo", options);

  return API.post(API_NAME, "/profile", options);
};

export const updateProfileInfo = (username, attributes) => {
  let options = {
    ...globalOptions,
    body: {
      username,
      attributes,
    },
  };

  Logger.log(TAG, "updateProfileInfo", options);

  return API.post(API_NAME, "/updateUser", options);
};

/*
 * Creative
 */

export const getMyFiles = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  Logger.log(TAG, "getMyFiles", options);

  return API.post(API_NAME, "/creative/myFiles", options);
};

export const associateFile = (id, account) => {
  let options = {
    ...globalOptions,
    body: {
      id,
      account,
    },
  };

  Logger.log(TAG, "associateFile", options);

  return API.post(API_NAME, "/creative/associateFile", options);
};

export const disassociateFile = (id, account) => {
  let options = {
    ...globalOptions,
    body: {
      id,
      account,
    },
  };

  Logger.log(TAG, "disassociateFile", options);

  return API.post(API_NAME, "/creative/disassociateFile", options);
};

/*
 * Status
 */

export const getStatusList = () => {
  let options = {
    ...globalOptions,
  };

  Logger.log(TAG, "getStatusList", options);

  return API.get(API_NAME, "/status", options);
};

export const createStatus = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createStatus", options);

  return API.post(API_NAME, "/status/new", options);
};

export const updateStatus = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateStatus", options);

  return API.post(API_NAME, "/status/update", options);
};

export const deleteStatus = (id) => {
  let options = {
    ...globalOptions,
  };

  Logger.log(TAG, "deleteStatus", options);

  return API.del(API_NAME, `/status/object/${id}`, options);
};

/*
 * Tags
 */
export const getAccountTags = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  Logger.log(TAG, "getAccountTags", options);

  return API.post(API_NAME, "/tags/myTags", options);
};

export const createAccountTag = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createTag", options);

  return API.post(API_NAME, "/tags/new", options);
};

export const updateAccountTag = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateTag", options);

  return API.post(API_NAME, "/tags/update", options);
};

export const deleteAccountTag = (id) => {
  let options = {
    ...globalOptions,
  };

  Logger.log(TAG, "deleteTag", options);

  return API.del(API_NAME, `/tags/object/${id}`, options);
};

/*
 * Reports
 */

export const getReports = (account) => {
  let options = {
    ...globalOptions,
    body: {
      account,
    },
  };

  Logger.log(TAG, "getReports", options);

  return API.post(API_NAMELA, "/reports/myReports", options);
};

export const createReport = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createReport", options);

  return API.post(API_NAMELA, "/reports/new", options);
};

export const updateReport = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "updateReport", options);

  return API.post(API_NAMELA, "/reports/update", options);
};

export const deleteReport = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "deleteReport", options);

  return API.post(API_NAMELA, `/reports/updateReportStatus`, options);
};
export const activateReport = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "deleteReport", options);

  return API.post(API_NAMELA, `/reports/ActivateReport`, options);
};

/** Facebook **/

export const getApps = () => {
  let options = {
    ...globalOptions,
  };

  Logger.log("API", "getApps", options);

  return API.post(API_NAME, "/facebook/apps", options);
};

export const getFanpages = (appId, userToken) => {
  let options = {
    ...globalOptions,
    body: {
      appId,
      userToken,
    },
  };

  Logger.log("API", "getFanpages", options);

  return API.post(API_NAME, "/facebook/fanpages", options);
};

export const saveFanpage = (data) => {
  let options = {
    ...globalOptions,
    body: data,
  };

  Logger.log("API", "saveFanpage", options);

  return API.post(API_NAME, "/facebook/save-fanpage", options);
};

export const getPages = () => {
  let options = {
    ...globalOptions,
  };

  Logger.log("API", "getPages", options);

  return API.post(API_NAME, "/facebook/pages", options);
};

export const getDatosWH = (data) => {
  let options = {
    ...globalOptions,
    body: data,
  };

  Logger.log("API", "getDatosWH", options);

  return API.post(API_NAME, "/facebook/datawh", options);
};

export const getDefaultEtiqueta = () => {
  let options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/etiquetas/getDefaultEtiqueta", options);
};
export const getEtiquetasByAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/etiquetas/getEtiquetasByAccount", options);
};

export const insertEtiqueta = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/etiquetas", options);
};

export const confirmUser = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/confirmuser", options);
};

export const deleteEtiqueta = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/etiquetas/deleteEtiqueta", options);
};
export const insertUsersLets = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/usersLets", options);
};
export const getUserLetsById = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/usersLets/getUserLetsById", options);
};

export const createNewBudgetLogOut = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/budgetLets/createBudgetLogOut", options);
};

export const createCategoriesBatch = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  Logger.log(TAG, "createCategory", options);

  return API.post(API_NAMELA, "/categoriesLets/createCategoriesBatch", options);
};
