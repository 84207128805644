import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/creative.actions'

const initialState = {
  files: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATIVE_CLEAR:
      return {
        ...state,
        files: [],
      }
    case Actions.CREATIVE_ADD_FILE:
      return {
        ...state,
        files: [
          ...state.files.filter((file) => file.id !== action.file.id),
          action.file,
        ],
      }
    case Actions.CREATIVE_REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((file) => file.name !== action.file),
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
