import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

// pages
import TermsAndConditions from './list'

const TermsCondition = props => {
  console.log("aca hay algo", props)
  return(
    <Switch>
      <Route path={`${props.match.path}`} component={TermsAndConditions} exact />
      <Route path="*">
        <Redirect to={`${props.match.path}`} />
      </Route>
    </Switch>
  )
}

export default withRouter(TermsCondition)
