import API from "@aws-amplify/api";

import { API_NAMELA } from "../config/api";

const globalOptions = {
  response: true,
};

export const getObjectCampain = () => {
  let options = {
    ...globalOptions,
  };

  return API.get(API_NAMELA, "/pautaobj", options);
};

export const createObjectCampain = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/pautaobj", options);
};

export const initialSync = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/odooOvs/initialSync", options);
};

export const sincPartial = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/odooOvs/sincPartial", options);
};

export const getSharedAuthor = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/sharedViews/getSharedAuthor", options);
};

export const updateObjectCampain = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.put(API_NAMELA, "/pautaobj", options);
};

export const updateCampaign = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  console.log("Body de la actualizacion", body);
  return API.post(API_NAMELA, "/campaign", options);
};

export const updateCampaignStatus = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign/updateCampaign", options);
};

export const createCampaign = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign", options);
};

export const editPautaLe = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign", options);
};

export const getCampaigns = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/myCampaigns", options);
};

export const updatePriority = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  console.log("Body priority", body);
  return API.post(API_NAMELA, "/accountsLets/updatePriorityAccount", options);
};

export const updateCampaignPriority = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  console.log("Body priority campaign", body);
  return API.post(API_NAMELA, "/campaign/updateCampaignPriority", options);
};

export const getAllCampaigns = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/AllCampaigns", options);
};
export const AllCampaignsNewMerged = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/AllCampaignsNewMerged", options);
};
export const AllCampsByAccountOnlyDynamo = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/AllCampsByAccountOnlyDynamo", options);
};
export const getCampaignById = (idMixDetaill) => {
  let options = {
    ...globalOptions,
  };
  return API.post(
    API_NAMELA,
    `/campaign/getCampaignById?id=${idMixDetaill}`,
    options
  );
};

export const getCampaignByIdSale = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/getCampaignByIdSale", options);
};

/*LLAMAR EN ACTIONS*/
export const getCampaignsSearch = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/search", options);
};

/**
 * Backend
 */

export const review = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/review", options);
};

export const aprobe = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/aprobe", options);
};

export const disaprobe = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/disaprobe", options);
};

export const getpautas = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.get(API_NAMELA, "/backend/apirest/getpautas", options);
};

export const editpautalets = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/editpautalets", options);
};

export const updatePautasLets = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/backend/apirest/updatePautasLets", options);
};

export const getMediumBanner = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getMediumBanner", options);
};

export const getFormatData = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getFormatData", options);
};

export const newUser = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/newUser", options);
};

export const findUserByEmail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findUserByEmail",
    options
  );
};

export const findPauta = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/findPauta", options);
};

export const editpauta = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/editpauta", options);
};

export const createpauta = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/createpauta", options);
};

export const sendEmail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/backend/letsadvertise/newEMail", options);
};

export const getObjEmailsByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/objEmails/getObjEmailsByAccount", options);
};

export const editpautalet = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/editpautalet", options);
};

/* Referencia:
export const createCampaign = (body) => {
  let options = {
    ...globalOptions,
    body
  }

  return API.post(API_NAMELA, '/campaign', options)
}
*/

export const getContryes = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/backend/apirest/getRegionList", options);
};

// esto lo hice hoy 28/10/2021
export const sincPautas = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/sincPautas", options);
};
export const getAllCampaings = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getAllCampaings", options);
};
export const getAllCampaingsNew = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign/AllCampaignsNew", options);
};
export const getBudgetByidmix = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/apirest/getBudgetSpentByidmix",
    options
  );
};

export const getUrlByIdMixDetail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getUrlByIdMixDetail", options);
};

export const getAllClients = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getAllClients", options);
};
export const findCampaingsByFilters = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/apirest/findCampaingsByFilters",
    options
  );
};

export const getMyCampaings = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getMyCampaings", options);
};

export const allPeriodCampaigns = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign/allPeriodCampaigns", options);
};
export const findCampaignByMixDetail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findCampaignByMixDetail",
    options
  );
};
export const findCampaignByIdSale = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findCampaignByIdSale",
    options
  );
};

export const findImagesByMixDetail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findImagesByMixDetail",
    options
  );
};

export const getCognitoUsers = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getCognitoUsers", options);
};
export const updateCurrentAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/updateCurrentAccount", options);
};

export const getCurrentAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getCurrentAccount", options);
};

export const insertLogLine = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/insertLogLine", options);
};

export const getLogLine = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getLogLine", options);
};

export const getAllAccountsAWS = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getAllAccountsAWS", options);
};
export const deleteUserAws = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/deleteUserAws", options);
};

export const updateAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/updateAccount", options);
};

export const getPaymentInfo = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getPaymentInfo", options);
};
export const upsertPaymentInfo = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/upsertPaymentInfo", options);
};

export const getDefaultCategories = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getDefaultCategories", options);
};
export const updateCategoryStatus = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/updateCategoryStatus", options);
};

export const getTempCampaigns = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/getTempCampaigns", options);
};

export const upsertTempCampaigns = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  console.log("Estas son los changes", body);
  return API.post(API_NAMELA, "/backend/apirest/upsertTempCampaigns", options);
};

export const associateFile = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/creatividad", options);
};

export const getCreativitieByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/creatividad/getCreativitieByAccount", options);
};
export const insertBitacora = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/bitacora", options);
};
export const deleteCreativitie = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/creatividad/deleteCreativitieItem", options);
};

export const insertQueue = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/insertQueue", options);
};

export const getQueueById = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/getQueueById", options);
};

export const editQueue = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/editQueue", options);
};

export const deleteQueue = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/deleteQueue", options);
};
export const updateCampaingStatus = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/updateCampaingStatus", options);
};
export const deletePauta = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/backend/letsadvertise/deletePauta", options);
};
export const updateGoal = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/updateGoal", options);
};

export const insertView = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/views", options);
};

export const getViewsByUser = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/views/getViewsByUser", options);
};

export const updateViewActive = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/views/updateViewActive", options);
};
export const deleteView = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/views/deleteView", options);
};
export const getRoleNames = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/roleNames/getRoleNames", options);
};
export const getRoleNameById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/roleNames/getRoleNameById", options);
};

export const insertPermisos = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/permisos", options);
};
export const insertValidacion = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/validaciones", options);
};
export const deleteValidacion = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/validaciones/deleteValidations", options);
};
export const getValidationsById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/validaciones/getValidationsById", options);
};
export const getBatchValidations = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/validaciones/getBatchValidations", options);
};
export const getBatchConfigEmails = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/configEmail/getBatchConfigEmails", options);
};
export const getBatchConfigEmailsLets = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/configLetsEmail/getBatchConfigEmailsLets",
    options
  );
};
export const getValidations = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/validaciones/getValidations", options);
};

export const insertObjEmails = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/objEmails", options);
};
export const deleteObjEmails = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/objEmails/deleteObjEmails", options);
};
export const getObjEmailsById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/objEmails/getObjEmailsById", options);
};
export const getObjEmails = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/objEmails/getObjEmails", options);
};

export const getAllPermitsByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/permisos/getAllPermitsByAccount", options);
};

export const insertConfigLetsEmail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/configLetsEmail", options);
};
export const deleteConfigLetsEmail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/configLetsEmail/deleteConfigLetsEmail",
    options
  );
};
export const getConfigLetsEmailById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/configLetsEmail/getConfigLetsEmailById",
    options
  );
};
export const getConfigLetsEmail = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/configLetsEmail/getConfigLetsEmail", options);
};

export const insertConfigEmail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/configEmail", options);
};
export const deleteConfigEmail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/configEmail/deleteConfigEmail", options);
};
export const getConfigEmailById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/configEmail/getConfigEmailById", options);
};
export const getConfigEmail = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/configEmail/getConfigEmail", options);
};

export const getPermitsByAccountAndUser = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/permisos/getPermitsByAccountAndUser", options);
};
export const deleteRol = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/permisos/deleteRol", options);
};
export const getPermitsAll = () => {
  const options = {
    ...globalOptions,
  };

  return API.post(API_NAMELA, "/permisos/getAllPermits", options);
};
export const getPermitsByUser = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/permisos/getPermitsByUser", options);
};

export const updateLastAccess = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/permisos/updateLastAccess", options);
};

export const deleteRolName = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/roleNames/deleteRolName", options);
};
export const insertRoles = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/roleNames", options);
};

export const testToken = () => {
  const options = {
    ...globalOptions,
    headers: {
      authorizationToken: "allow",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return API.post(API_NAMELA, "/testToken", options);
};
export const connectionOdoo = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  console.log("Body de la conexion", body);
  return API.post(API_NAMELA, "/Odoo/connection", options);
};

export const getOdooConnectionsByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/Odoo/getConnectionsByAccount", options);
};
export const deleteOdooConnection = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/Odoo/deleteConnection", options);
};
export const editFinal = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/editFinal", options);
};
export const getBitacoraByidMixDetail = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/bitacora/getBitacoraByidMixDetail", options);
};

export const getBitacoraByidActionAndMix = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/bitacora/getBitacoraByidActionAndMix", options);
};

export const getBudgetsOvs = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getBudgets", options);
};

export const getBudgetByIdEditModal = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/getBudgetByIdEditModal", options);
};

export const getBudgetsByStatusAndAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/budgetLets/getBudgetsByStatusAndAccount",
    options
  );
};

export const getBudgetsById = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/getBudgetById", options);
};

export const insertSharedView = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/sharedViews", options);
};
export const getFlagsByAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getFlagsByAccount", options);
};

export const getConnectionsByAccount = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/Odoo/getByAccount", options);
};

export const getSharedView = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/sharedViews/getSharedView", options);
};
export const getLovById = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getLovById", options);
};
export const getLovsBatch = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getLovsBatch", options);
};
export const getLovByIdOrderNumber = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getLovByIdOrderNumber", options);
};

export const myTemplates = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/templates/myTemplates", options);
};

export const upsertTemplate = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/templates", options);
};

export const upsertFiles = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/files/new-uploadFiles", options);
};
export const getUrlFile = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/files/getUrlFile", options);
};

export const findBudgetByName = (body) => {
  let options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/budgetLets/findBudgetByName", options);
};

export const findMediaCounter = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findMediaCounter",
    options
  );
};
export const findBannerCounter = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findBannerCounter",
    options
  );
};
export const findRegionCounter = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findRegionCounter",
    options
  );
};
export const findFormatCounter = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findFormatCounter",
    options
  );
};

export const findProduct = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/letsadvertise/findProduct", options);
};

export const findProductPartial = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/findProductPartial",
    options
  );
};

export const budgetTransaction = (body) => {
  // ! lambda transaccional, solo modifica disponibles (available)
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/budgetTransaction", options);
};

export const updateBudgetLovs = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/updateBudgetLovs", options);
};

export const ovTransactionovTransaction = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/odooOvs/ovTransaction", options);
};

export const findAmountById = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/budgetLets/findAmountById", options);
};

export const findAudiencesByName = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/audiences/findAudiencesByName", options);
};

export const getLastSinc = () => {
  let options = {
    ...globalOptions,
  };

  return API.get(API_NAMELA, "/infoOdoo", options);
};

export const getLogsBudget = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/bitacorasBudgetsLets/getBitacoras", options);
};

export const eliminateBudget = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/budgetLets/eliminateBudget", options);
};

export const getConfigEmailByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/configEmail/getConfigEmailByAccount", options);
};

export const getConfigLetsEmailByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/configLetsEmail/getConfigLetsEmailByAccount",
    options
  );
};

export const getValidacionesByAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/validaciones/getValidacionesByAccount",
    options
  );
};

export const updateConfigLets = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/configLetsEmail/updateConfigLets", options);
};

export const updateIdsBudgets = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/updateIdsBudget", options);
};

export const deleteTemplate = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/templates/deleteTemplate", options);
};
export const getIdsCampaignOvs = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/odooOvs/getIdsCampLovs", options);
};

export const findScheduleById = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/scheduleConfiguration/findScheduleById",
    options
  );
};

export const updateSchedule = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/scheduleConfiguration/updateSchedule", options);
};

export const getConfigPriority = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/accountsLets/getConfigPriority", options);
};

export const updateConfigPriority = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/accountsLets/updateConfigPriority", options);
};

export const updateRecordPriority = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/accountsLets/updateRecordPriority", options);
};

export const updatePriorityAndDate = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/updatePriorityAndDate",
    options
  );
};

export const updatePriorityAndDateDynamo = (body) => {
  let options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign/updatePriorityAndDate", options);
};

export const updateBudgetLogOut = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/budgetLets/budgetUpdateLogOut", options);
};

export const deletePautaLogOut = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/campaign/deletePautaLogout", options);
};

export const getTemplateDefault = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/creatividad/getTemplateDefault", options);
};

export const deleteAccessAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/permisos/deleteAccessAccount", options);
};

export const activateAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/permisos/activateAccount", options);
};

export const disabledUser = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/disabledUser", options);
};
export const enabledUser = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/backend/apirest/enabledUser", options);
};

export const elimAccess = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/permisos/elimAccess", options);
};

export const convertTemplateDefault = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/templates/createTempletaDefault", options);
};

export const getPermissionsByModule = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/permisos/getPermissionsByModule", options);
};

export const createLogTemplate = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/templates/createLogTemplate", options);
};

export const getAccountByClient = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(API_NAMELA, "/accountsLets/getAccountByClient", options);
};
export const createPautaTransaction = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/backend/apirest/createPautaTransaction",
    options
  );
};

export const findCampaignAndMergeMysqlAndDynamo = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/campaign/searching", options);
};

export const getCurrentClient = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/accountsLets/getClient", options);
};

export const getAssetsByClient = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(API_NAMELA, "/accountsLets/getAssetsByClient", options);
};

export const insertDigitalAsset = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/insertDigitalAsset",
    options
  );
};

export const getCurrentIdEadvertising = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/accountsLets/getCurrentIdEadvertising",
    options
  );
};

export const deleteDigitalAsset = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/deleteDigitalAsset",
    options
  );
};

export const updateDigitalAsset = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/updateDigitalAsset",
    options
  );
};

export const updateInformationAccount = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/accountsLets/updateInformationCurrentAccount",
    options
  );
};

export const updateInformationAccountEadvertising = (body) => {
  const options = {
    ...globalOptions,
    body,
  };

  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/updateInformationAccount",
    options
  );
};

export const getTypesDigitalAssets = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/getTypesDigitalAssets",
    options
  );
};

export const getDigitalAssetById = (body) => {
  const options = {
    ...globalOptions,
    body,
  };
  return API.post(
    API_NAMELA,
    "/backend/letsadvertise/getDigitalAssetById",
    options
  );
};
