import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/fanpageAsociar.actions'

const initialState = {
  apps: [],
  pages: [],
  appId: null,
  selectedPages: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FB_APPS:
      return {
        ...state,
        apps: action.apps,
      }
    case Actions.FB_PROP:
      return {
        ...state,
        [action.prop.name]: action.prop.value,
      }
    case Actions.FB_PAGES:
      return {
        ...state,
        pages: action.pages,
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}