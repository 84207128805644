import { useReducer } from "react";
import { validateInput } from "./validateInput";

const InputActions = {
  CHANGE_VALUE: "CHANGE_VALUE",
  CLEAR_FORM: "CLEAR_FORM",
  SET_ERROR: "SET_ERROR",
  SET_FORM_INVALID: "SET_FORM_INVALID",
  SET_FORM_VALID: "SET_FORM_VALID",
  SET_FORM: "SET_FORM",
};

const formReducer = (state, action) => {
  const { data, type } = action;
  switch (type) {
    case InputActions.CHANGE_VALUE:
      return {
        ...state,
        [data.name]: {
          ...state[data.name],
          value: data.value,
          hasError: data.hasError,
          messageError: data.messageError,
          isFormInvalid: data.isFormInvalid,
        },
      };
    case InputActions.CLEAR_FORM:
      return {
        ...state,
        [data.name]: {
          ...state[data.name],
          value: data.value,
          hasError: data.hasError,
          messageError: data.messageError,
          isFormInvalid: data.isFormInvalid,
        },
      };
    default:
      return state;
  }
};

export const useForm = (initialState) => {
  // el useReducer recibe como primer parametro un reducer y como segundo parametro el estado inicial
  const [state, dispatch] = useReducer(formReducer, initialState);

  const onChange = ({ value, name, currentTypeDigitalAsset }) => {
    const { errorMessage, hasError } = validateInput({
      value,
      name,
      currentTypeDigitalAsset,
    });
    dispatch({
      type: InputActions.CHANGE_VALUE,
      data: {
        value,
        name,
        hasError,
        messageError: errorMessage,
        isFormInvalid: !hasError,
      },
    });
  };

  const isValidaForm = () => {
    let isValid = true;
    Object.keys(state).forEach((key) => {
      if (!state[key].isFormInvalid) {
        isValid = false;
      }
    });
    return isValid;
  };

  return {
    state,
    onChange,
    isValidaFormState: isValidaForm(),
  };
};
