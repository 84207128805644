import React from "react";
import { connect } from "react-redux";
import { saveSteps } from "redux/actions/nameCamp.actions";
import { saveNameAccount } from "redux/actions/medioCamp.actions";
import { nextStep, saveName, clearSteps } from "redux/actions/userLogout.action";
import { setDefault, setAccountsTotal } from "redux/actions/accounts.actions";
import Tooltip from "antd/es/tooltip";
import "./style.css";

const ModalConfirmUploadTempleta = (props) => {

    return (
        <>
            <div className={props?.showModal ? "blur-div" : ""}>
                <div className="form-margin__cat contenedorCrearCampSinReg__cat content-modal__accountLogout">
                    <div className='component'>
                        <Tooltip title="Cerrar" color="#002448" key="white" zIndex={99999999}>
                            <i
                                className="fa fa-times close-modal-btn"
                                onClick={props.closeModal}
                            ></i>
                        </Tooltip>
                        <h2 className="title__">
                            Elige un método para la creación de tu plantilla
                        </h2>
                        <div className="rigth-btn__templates">
                            <label className="btn-camp" htmlFor="fileUpload" id="nextName">
                                Importar plantilla
                            </label>
                            <input
                                type="file"
                                id="fileUpload"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    props.closeModal();
                                    props.handleFileChange(e)
                                }}
                            />
                            <a className="btn-camp-cancel" onClick={async () => {
                                props.setConfirmUploadExcel(false)
                                props.setShowModalPauta(true)
                                props.closeModal();
                            }} href="#" id="nextName">
                                Creación Manual
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
    campaignupd: state.campaignupd,
    step: state.userLogout.nextStepCategory,
    currentUser: state.app.current_user,
    nameAccount: state.userLogout.nameAccount
});

const mapDispatchToProps = (dispatch) => ({
    _setAccountsTotal: (accounts) => dispatch(setAccountsTotal(accounts)),
    _clearSteps: () => dispatch(clearSteps()),
    _setDefaultAccount: (account) => dispatch(setDefault(account)),
    _nexStep: (step) => dispatch(nextStep(step)),
    _saveName: (name) => dispatch(saveName(name)),
    _saveNameAccount: (nameA) => dispatch(saveNameAccount(nameA)),
    _saveSteps: (step) => dispatch(saveSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmUploadTempleta);
