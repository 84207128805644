import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

import CreatableSelect from "libs/select/creatable";
import analytics from "../../../helpers/analytics";
import { createBudget } from "redux/actions/budgets.actions";
import { pwaUpsertBudget } from "redux/actions/pwa.actions";
import NumberFormat from "react-number-format";
import CurrencyInput from "react-currency-input-field";
import PageLoader from "components/page_loader";
import "../assets/css/styles.scss";
import "./upsertModal.scss";
import { Auth } from "aws-amplify";
import { modifyBudget } from "redux/actions/budgets.actions";
import { getBudgets, createNewBudgetLogOut, updateBudgetLogOut } from "redux/actions/budgets.actions";
import Swal from "sweetalert2";

const UpsertBudget = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    amount: false,
    errorBudgetMessage: "",
  });

  const initialState = {
    id: "",
    name: "",
    tags: [],
    amount: 0,
    budgetOld: true,
    edit: false,
    createAt: 0,
    padre: "",
    //budget:'',
    idBudget: "",
    nombrePadre: "",
    fatherAvailable: 0,
    available: 0,
  };
  const [state, setState] = useState({
    id: "",
    name: "",
    tags: [],
    budgetOld: true,
    amount: 0,
    edit: false,
    createAt: 0,
    padre: "",
    idBudget: "",
    nombrePadre: "",
    fatherAvailable: 0,
    available: 0,
  });

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      setIsLoading(true);
      let newState = {
        edit: true,
        id: props.edit.id,
        tags: props.edit.tags,
        budgetOld: props.edit.budgetOld,
        name: props.edit.title,
        amount: props.edit.amount,
        createAt: props.edit.createAt,
        padre: props.edit.padre,
        nombrePadre: props.edit.nombrePadre,
        fatherAvailable: props.fatherAvailable,
        available: props.edit.available,
      };

      if (props.edit.tags) {
        newState = {
          ...newState,
          tags: props.edit.tags.map((tag) => ({
            value: tag,
            label: getTagName(tag),
          })),
        };
      }

      setState((prev) => ({
        ...prev,
        ...newState,
      }));
    } else {
      setState({
        id: "",
        name: "",
        tags: [],
        amount: 0,
        edit: false,
        budgetOld: true,
        createAt: 0,
        padre: "",
        //budget: '',
        idBudget: "",
        nombrePadre: "",
        fatherAvailable: 0,
        available: 0,
      });
    }
    setIsLoading(false);
  }, [props.edit]);

  const getTagName = (id) => {
    const tag = _.find(props.accountTags, { id });
    return tag ? tag.name || "" : "";
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "amount" && e.target.validity.valid) {
      setState((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const handleBudgetInputChange = (e) => {
    const value = e;
    let errorBudget = false;
    let errorBudgetMessage = "";

    if (state.padre !== "") {
      errorBudget = _.isEmpty(value);
      const availableAmount =
        _.find(props.budgets, { id: state.padre }).available || 0;

      //const newAvailable = availableAmount+props.campaignupd.budget
      let prevBud = props.budgets.find((element) => element.id == state.id);
      //if (value > newAvailable) {
      let ava = 0;
      if (prevBud) {
        ava = prevBud.available;
      }
      if (parseFloat(value) > availableAmount + ava) {
        errorBudget = true;
        errorBudgetMessage = "Monto no disponible en presupuesto padre";
      }
      if (parseFloat(value) < 0) {
        errorBudget = true;
        errorBudgetMessage = "Presupuesto no válido";
      }
    }
    setState((prev) => ({
      ...prev,
      amount: value,
    }));

    let errorM = "";

    console.log("_.isEmpty(e.value) ", _.isEmpty(value));
    if (_.isEmpty(value)) {
      errorM = "El presupuesto no puede estar vacio";
    }
    let mErrors = {
      //budget: errorBudget,
      errorBudgetMessage: errorM,
      errorBudget: _.isEmpty(value),
    };

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));
  };

  const handleTagChange = (value) => {
    setState((prev) => ({
      ...prev,
      tags: value,
    }));
  };

  useEffect(() => {
    if (props.create) {
      setState(initialState);
    }
  }, [props.create]);

  const closeModal = (e) => {
    if (e) e.preventDefault();
    analytics.modalesAcciones({
      accion: "Cancelar",
      ubicacion: "Presupuestos",
    });
    setState({
      id: "",
      name: "",
      tags: [],
      amount: 0,
      edit: false,
      budgetOld: true,
      createAt: 0,
      padre: "",
      //budget: '',
      idBudget: "",
      nombrePadre: "",
      fatherAvailable: 0,
      available: 0,
    });
    // props._getBudgets(
    //   props.currentAccount,
    //   props.currentUser,
    //   3,
    //   "PRESUPUESTOS"
    // );
    // setState(initialState)
    props.setModalUpsertOld(false);
  };

  const areValidInputs = () => {
    let errorBudgetMessage = "";
    let errorBudget = state.amount === 0;

    if (state.amount < 0) {
      errorBudget = true;
      errorBudgetMessage = "Monto debe ser 0 o mayor";
    }
    if (!state.amount) {
      errorBudget = true;
      errorBudgetMessage = "El valor no puede venir vacio";
    }

    if (state.edit) {
      let prevBud = props.budgets.find((element) => element.id == state.id);
      let avaPrev = 0;
      if (prevBud) {
        avaPrev = prevBud.available;
      }
      let dif = Math.abs(prevBud.amount - prevBud.available);
      if (state.amount < dif) {
        errorBudget = true;
        errorBudgetMessage = "El monto maximo reducible es de " + dif;
      }
    }

    let mErrors = {
      name: state.name === "",
      errorBudgetMessage,
    };

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));

    return !_.some(mErrors, (e) => e);
  };

  const handleUpsert = async (e) => {
    e.preventDefault();
    if (!areValidInputs()) return;

    analytics.modalesAcciones({
      accion: "Confirmar",
      ubicacion: "Presupuestos",
    });

    try {
      const userdata = await Auth.currentAuthenticatedUser();

      if (!state.edit) {
        props._createBudget(
          state.name,
          props.currentAccount,
          state.amount,
          state.tags,
          state.padre,
          props.currentUser,
          userdata.attributes.email
        );
      } else {
        props._modifyBudget(
          state.id,
          state.name,
          state.amount,
          state.tags,
          state.padre,
          props.currentUser,
          userdata.attributes.email,
          props.currentAccount
        );
      }
    } catch (error) {
      console.log("error creaciddood pres", error);
      if (error === 'The user is not authenticated') {
        if (!state.edit) {
          const body = {
            title: state.name,
            account: props.currentAccount,
            amount: parseFloat(state.amount).toFixed(2),
            tags: state.tags,
            padre: state.padre,
            user: props.currentUser,
            userEmail: "logout",
          };
          await props._createNewBudgetLogOut(body)
        } else {
          await props._updateBudgetLogOut(state, props.edit);
        }
        Swal.fire({
          title: state.edit ? "Presupuesto editado exitosamente" : "Presupuesto creado exitosamente",
          icon: "success"
        })
        props._getBudgets(
          props.currentAccount,
          props.currentUser,
          3,
          "PRESUPUESTOS"
        );
      }
    } finally {
      closeModal();
    }
  };

  let accountTags = [];

  if (props.accountTags) {
    accountTags = props.accountTags.map((tag) => ({
      value: tag.id,
      label: tag.name,
    }));
  }

  let budgets = [];

  props.budgets.map((budget) => {
    budgets = [
      ...budgets,
      {
        value: budget.id,
        label: (
          <NumberFormat
            prefix={"$"}
            displayType={"text"}
            value={budget.amount}
            thousandSeparator={true}
            renderText={(value) => (
              <>
                {budget.title}: {value}{" "}
                <NumberFormat
                  prefix={"$"}
                  displayType={"text"}
                  value={budget.available || 0}
                  thousandSeparator={true}
                  renderText={(value) => `(Disponible: ${value})`}
                />
              </>
            )}
          />
        ),
        nombrePadre: budget.title,
      },
    ];
  });

  return (
    <div className="blur-div">
      <div
        id="new-budget-modal"
        style={{ display: props.modalUpsertOld ? "block" : "none" }}
        className="modal-old"
      >
        {props.modalUpsertOld && isLoading ? (
          <>
            <PageLoader />
          </>
        ) : (
          <>
            <div className="foot-mnc">
              <div className="formOneStep">
                <div className="titleOneStep">
                  <p className="h3">
                    {state.edit
                      ? "Actualizemos tu presupuesto."
                      : "Creemos un nuevo presupuesto."}
                  </p>
                </div>

                <div className="bodyOneStep">
                  <div className="oneColBody">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#05252d",
                          fontWeight: 500,
                          marginBottom: "5px",
                        }}
                      >
                        Ponle un nombre a tu presupuesto
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        className="input"
                        value={state.name}
                        onChange={handleInputChange}
                        placeholder="P. ejemplo presupuesto para madres solteras"
                        invalid={errors.name}
                      />
                      <FormFeedback>Ingresa un nombre</FormFeedback>
                    </FormGroup>
                  </div>

                  <div className="oneColBody">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#05252d",
                          fontWeight: 500,
                          marginBottom: "5px",
                        }}
                      >
                        Ingresa la cantidad que deseas solicitar
                      </Label>
                      <div
                        className={classnames("d-flex align-items-center", {
                          "is-invalid": errors.errorBudgetMessage,
                        })}
                      >
                        {/* <span className='mr-2'>$</span> */}
                        <CurrencyInput
                          intlConfig={{ locale: "es-GT", currency: "USD" }}
                          // min="0"
                          prefix="$"
                          // type='number'
                          name="amount"
                          //pattern='[0-9]*'
                          className="input"
                          style={{
                            color: "#05252d",
                          }}
                          value={state.amount}
                          onValueChange={handleBudgetInputChange}
                          placeholder="P. ejemplo 1000"
                          invalid={errors.errorBudget}
                        />
                      </div>
                      <FormFeedback>{errors.errorBudgetMessage}</FormFeedback>
                    </FormGroup>
                  </div>

                  <div className="oneColBody">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#05252d",
                          fontWeight: 500,
                          marginBottom: "5px",
                        }}
                      >
                        Ingresa los tags que deseas
                      </Label>
                      <CreatableSelect
                        value={state.tags}
                        options={accountTags}
                        onChange={handleTagChange}
                        placeholder="Selector de tags"
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="footOneStep w-100 footOneStepModal4">
                  <a
                    href="#"
                    className="btnCancelOS firstBtn"
                    onClick={closeModal}
                  >
                    Cancelar
                  </a>

                  <a
                    href="#"
                    className="btnConfirmOS secondBtn"
                    onClick={handleUpsert}
                  >
                    Confirmar
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.budgets.edit,
  accounts: state.accounts.list,
  accountTags: state.accounts.tags,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  budgets: state.budgets.list,
});

const mapDispatchToProps = (dispatch) => ({
  _updateBudgetLogOut: (budget, sum) => dispatch(updateBudgetLogOut(budget, sum)),
  _createNewBudgetLogOut: (budget) => dispatch(createNewBudgetLogOut(budget)),
  _getBudgets: (account, user, idmod, mod) =>
    dispatch(getBudgets(account, user, idmod, mod)),
  _createBudget: (
    id,
    title,
    account,
    amount,
    rawTags,
    padre,
    user,
    userEmail
  ) =>
    dispatch(
      createBudget(id, title, account, amount, rawTags, padre, user, userEmail)
    ),
  _modifyBudget: (
    id,
    title,
    amount,
    rawTags,
    padre,
    user,
    userEmail,
    account
  ) =>
    dispatch(
      modifyBudget(id, title, amount, rawTags, padre, user, userEmail, account)
    ),
  _pwaUpsertBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    edit,
    userEmail,
    budgetOld
  ) =>
    dispatch(
      pwaUpsertBudget(id, title, account, amount, tags, status, edit, userEmail)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsertBudget);
