import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import { VerifyContact } from "aws-amplify-react";

//cache
import ReloadPrompt from "pwa/ReloadPrompt";

// Theme
import Theme from "theme";

// Components
import NavBar from "components/navBar";
import Loader from "components/loader";
import PageLoader from "components/page_loader";

import Content from "./content";
import analytics from "helpers/analytics";
import * as APILA from "helpers/apiLA";
// Utils
import { withAuthentication } from "config/session";
import { setIsIPV4 } from "redux/actions/app.actions";

let userAnalytics = true;
const App = ({ authState, isLoading, onStateChange, _setIsIPV4 }) => {
  const [user, setUser] = useState(null);
  const [isSupAdmin, setIsSupAdmin] = useState(false);
  const [accountsByUser, setAccountsByUser] = useState([]);
  const [refresh, setRefresh] = useState(false);
  async function checkUser() {
    const userD = await Auth.currentAuthenticatedUser();
  }

  const getUser = () => {
    checkUser();
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        console.log("Not signed in");
      });
  };

  useEffect(() => {
    if (authState === "signedIn") {
      Auth.currentAuthenticatedUser()
        .then((userData) => {
          APILA.updateLastAccess({ user: userData.username });
          analytics.userData(userData.username);
          userAnalytics = false;
        })
        .catch(() => {
          _setIsIPV4(true);
          console.log("Not signed in");
        });
    }
    if (Auth.user) {
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      APILA.getPermitsByUser({ user: sub }).then((data) => {
        const isSupAdmin = data.data.permits.some(
          (ele) => ele.idRol === "Super Administrador"
        );
        setIsSupAdmin(isSupAdmin);
        if (data.data.permits.length > 0) {
          setAccountsByUser(() => {
            return data.data.permits.map((ele) => ele.account);
          });
        }
      });
    } else {
    }
  }, [authState]);

  useEffect(() => {
    let version = localStorage.getItem("version");
    if (version !== "cache eliminada version nuevo flujo peps") {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  }, []);
  //descomentar para prod
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("cache eliminada calendario");
  };

  useEffect(() => {
    clearCacheData();
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn": {
          //Reload page
          window.location.reload(true);
          return;
        }
        case "cognitoHostedUI": {
          getUser().then((userData) => setUser(userData));
          break;
        }
        case "signOut":
          setUser(null);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  if (isLoading) {
    //este es el aguacate
    return <PageLoader />;
  }
  if (authState === "signedIn" || authState === "PWA") {
    return (
      <>
        <Theme>
          <NavBar
            authState={authState}
            onStateChange={onStateChange}
            accountsByUser={accountsByUser}
            isSupAdmin={isSupAdmin}
          />

          <Content onStateChange={onStateChange} />
        </Theme>
        {/* <ReloadPrompt refresh={refresh} /> */}
        <Loader />
      </>
    );
  }

  if (authState === "verifyContact") {
    return (
      <VerifyContact authData={user} user={user} authState="verifyContact" />
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  _setIsIPV4: (ipv4) => dispatch(setIsIPV4(ipv4)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(App));
