import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { withRouter } from "react-router-dom";
import { newEmailValidation } from "redux/actions/email.actions";
import { Tooltip } from "antd";
// organization components
import Lovs from "./lovs";
import { DateRangeFilter } from "libs/dateRange";
import { Select } from "antd";
import DetailColEdit from "components/DetailCol";
import DataTableUtils, {
  Share,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";
import Swal from "sweetalert2";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { getClients } from "redux/actions/clients.actions";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import { Auth } from "aws-amplify";
import { getAccountTags } from "redux/actions/accounts.actions";
import {
  setEdit,
  getBudgets,
  clearBudgets,
} from "redux/actions/budgets.actions";
import { getAllCampaigns } from "redux/actions/allCampaings.actions";
import { withUtils } from "utils";
import analytics from "../../helpers/analytics";
import PageLoader from "components/page_loader";
import ConflictingBudgets from "./ConflictingBudgets";
import ModalUpsertNew from "./modalPresupuestos";
import ModalUpsertOld from "./modal/ModalUpserstBudget";
import "./assets/css/styles.scss";
import {
  isWithinInterval,
  startOfMonth,
  endOfMonth,
  isToday,
  isYesterday,
  parse,
  format,
  isValid,
} from "date-fns";
import ViewTable from "components/viewTable";
import { columnsBudgets } from "config/columns";
import { columnsOptions } from "config/columns";
import { getLastSinc, sincPartialOdoo } from "redux/actions/odooOvs.actions";
import { descifrar } from "helpers/encrypt";
// stataus column
import status from "config/status";
import { Status } from "@easygosanet/react-datatable";
import iconoSinc from "./assets/img/icono.png";
import iconoSincHover from "./assets/img/iconohover.png";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import { nextStep } from "redux/actions/userLogout.action";

//
const idmod = 3;
const mod = "PRESUPUESTOS";
const { Option } = Select;

const StatusName = (value) => {
  let name = "";
  if (value === 1) {
    name = "Activo";
  }
  if (value === 2) {
    name = "Pendiente";
  }
  if (value === 3) {
    name = "Rechazado";
  }

  return name;
};
const Content = (props) => {
  const orderDefault = [
    "title",
    "createAt",
    "updateAt",
    "amount",
    "availableC",
    "available",
    "status",
  ];
  const groupingOptions = [
    "Nombre",
    "Presupuesto total",
    "Presupuesto implementado",
    "Presupuesto disponible",
    "Lovs",
  ];

  const [state, setState] = useState(1);
  const [options, setOptions] = useState(groupingOptions);
  const [budgets, setBudgets] = useState([]);
  const [create, setCreate] = useState(false); //Identifica si esta actualizando o creando
  const [modalUpsertOld, setModalUpsertOld] = useState(false);
  const [connection, setConnection] = useState([]);
  const [nameView, setNameView] = useState("");
  const [totalColumn, setTotalColumn] = useState([]);
  const [account, setAccount] = useState(props.currentAccount);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [filterInfo, setFilterInfo] = useState([]);
  const [budgetsFilter, setBudgetsFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [views, setViews] = useState([]);
  const [executeState, setExecuteState] = useState(false);
  const [nameGrouped, setnameGrouped] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activate, setActivate] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });
  const [datesToShow, setDatesToShow] = useState({
    fechaIni: startOfMonth(new Date()),
    fechaFin: endOfMonth(new Date()),
  });
  const modalRef = useRef();
  const [textSinc, setTextSinc] = useState(false);

  function numberWithCommas(x) {
    let auxNumber = Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columnsMapping = {
    title: {
      cell: (row) => {
        let eliminar = row.availableC === 0 ? true : false;
        let edit = true;
        if (row.userEmail !== "logout") {
          if (!props.blocBudget && row?.budgetOld === true) {
            edit = false;
          }
        }
        return (
          <DetailColEdit
            key={row.id}
            text={row.title}
            id={"name"}
            deleteData={() => eliminarPresupuesto(row)}
            deleteV={eliminar}
            nameColumn={row}
            reActivar={true}
            activate={row.active}
            viewBudget={true}
            activeData={() => activateData(row)}
            edit={row.active ? row.permisos?.edit : false}
            onEditValue={updateBudget}
            accounts={props.accounts}
            budgetOld={row.budgetOld}
            editable={false}
            editInfo={() => goToEditPage(row)}
            type={"text"}
            permissions={
              row.permisos
                ? {
                    ...row.permisos,
                    edit: row.permisos.edit && edit,
                    delete: row.permisos.delete && eliminar,
                  }
                : {}
            }
          />
        );
      },
    },
    idLovs: {
      cell: (row) => (
        <div onClick={() => showSecondLevelTable(row, "idsBudget")}>
          {Array.isArray(row?.idLovs) ? row.idLovs.join(", ") : ""}
        </div>
      ),
    },
    status: {
      cell: (row) => {
        return (
          <Status
            value={row.active == true ? "Activo" : "Eliminado"}
            label={getStatusLabel(row.active)}
            className={getStatusClassName(row.active)}
            justifyContent="center"
            center="true"
          />
        );
      },
    },
  };

  const getStatusLabel = (mStatus) => {
    switch (mStatus) {
      case false:
        return status.ELIMINADO.label;
      case true:
        return status.ACTIVO.label;
      default:
        return "Totales";
    }
  };

  const getStatusClassName = (mStatus) => {
    switch (mStatus) {
      case false:
        return status.ELIMINADO.class;

      case true:
        return status.ACTIVO.class;

      default:
        return status.EN_IMPLEMENTACION.class;
    }
  };

  let columsMapp = columnsBudgets.map((element) => {
    const mappingElementColumn = columnsMapping[element.id];
    if (mappingElementColumn) {
      return { ...element, ...mappingElementColumn };
    }
    return element;
  });
  const [columns, setColumns] = useState([...columsMapp]);

  const showSecondLevelTable = (row, type) => {
    // !descomentar para ver niveles
    // setBudgetsFilter((data) => {
    //   let updateData = [...data];
    //   const indexToUpdate = updateData.findIndex((item) => item.id === row.id);
    //   updateData[indexToUpdate].typeData =
    //     columnsOptions["idsBudget"].firstLevel; //"idLovs";
    //   updateData[indexToUpdate].defaultExpanded =
    //     !updateData[indexToUpdate].defaultExpanded;
    //   return updateData;
    // });
  };

  /**
   *
   * @deprecated
   * Modulo en desuso, ya no se utiliza por niveles y gestion de disponibles en ovs
   */
  const updateBudget = (row) => {
    console.warn("Metodo sin funcionalidad");
  };

  useEffect(() => {
    setInitialLoader(true);
  }, [props.currentAccount]);

  const eliminarPresupuesto = async (row) => {
    try {
      setIsLoading(true);
      let count = await APILA.getCountBudgets({ idBudget: row.id });
      const userdata = await Auth.currentAuthenticatedUser();
      let countB = count.data.count;
      if (countB > 0) {
        return Swal.fire({
          title: `Presupuesto asociado a ${countB} ${
            countB === 1 ? "campaña" : "campañas"
          }`,
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((response) => {
          if (response.isConfirmed) {
            setIsLoading(false);
            return;
          }
        });
      }
      let title = `¿Está seguro que desea eliminar el presupuesto <strong>${row.title}</strong>?, esta acción retornará lo reservado a cada línea de orden de venta relacionado a este presupuesto.`;

      props._showConfirmationModal(
        "¿Eliminar presupuesto?",
        title,
        async () => {
          try {
            setIsLoading(true);

            const updatePromise = APILA.updateBudget({
              id: row.id,
              active: false,
            });

            await Promise.all([updatePromise]);

            let bodyBitacora = {
              id: v4(),
              date: new Date(),
              account: account,
              action: "se ha eliminado un presupuesto",
              component: "budgets",
              data: row,
            };
            API.insertBitacora(bodyBitacora);

            props._newEmailValidation(
              props.currentAccount,
              "Presupuestos",
              row,
              props.currentUser,
              props.accounts,
              "Eliminación"
            );

            const lovs = row?.reserveForLovs ?? [];
            if (lovs.length > 0) {
              const lovsTrasaction = lovs.map((ele) => ({
                id: ele.id,
                quantity: parseFloat(ele.reserve),
                sum: true,
                idOv: ele.order_line_id_v6,
                user: userdata.attributes.email,
                account: props.currentAccount,
              }));
              await API.updateBudgetLovs({
                ovs: lovsTrasaction,
                budget: {},
              }).then(async () => {
                await API.eliminateBudget({
                  id: row.id,
                });
              });
            }
            props._getBudgets(
              props.currentAccount,
              props.currentUser,
              idmod,
              mod
            );
            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);

            Swal.fire({
              title: "Ups!",
              text: "Hubo un error al eliminar el presupuesto",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          }
        }
      );
    } catch (error) {
      console.error("Error eliminando presupuesto:", error);
      let count = await APILA.getCountBudgets({ idBudget: row.id });
      let countB = count.data.count;
      if (countB > 0) {
        return Swal.fire({
          title: `Presupuesto asociado a ${countB} ${
            countB === 1 ? "campaña" : "campañas"
          }`,
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((response) => {
          if (response.isConfirmed) {
            setIsLoading(false);
            return;
          }
        });
      }
      let title = `¿Está seguro que desea eliminar el presupuesto <strong>${row.title}</strong>?, esta acción retornará lo reservado a cada línea de orden de venta relacionado a este presupuesto.`;

      props._showConfirmationModal(
        "¿Eliminar presupuesto?",
        title,
        async () => {
          try {
            setIsLoading(true);
            const updatePromise = APILA.updateBudget({
              id: row.id,
              active: false,
            });
            await Promise.all([updatePromise]);
            const lovs = row?.reserveForLovs ?? [];
            if (lovs.length > 0) {
              const lovsTrasaction = lovs.map((ele) => ({
                id: ele.id,
                quantity: parseFloat(ele.reserve),
                sum: true,
                idOv: ele.order_line_id_v6,
                user: "logout",
                account: props.currentAccount,
              }));
              await API.updateBudgetLovs({
                ovs: lovsTrasaction,
                budget: {},
              }).then(async () => {
                await API.eliminateBudget({
                  id: row.id,
                });
              });
            }
            props._getBudgets(
              props.currentAccount,
              props.currentUser,
              idmod,
              mod
            );
            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
            Swal.fire({
              title: "Ups!",
              text: "Hubo un error al eliminar el presupuesto",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          }
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const numberBudgetView = Number(localStorage.getItem("viewBudget"));
    setAccount(props.currentAccount);
    actualizarViews(true);
    getConnection();
    setFilterInfo([]);
    getUserPermissions();
    props._getBudgets(props.currentAccount, props.currentUser, idmod, mod);
    props._getLastSinc(props.currentAccount);
    if (numberBudgetView > 1 && !executeState) {
      changeState(numberBudgetView);
      setExecuteState(true);
    }
  }, [props.currentAccount, state]);

  useEffect(() => {
    if (props.budgets.length) {
      setInitialLoader(false);
    }
    assignnedBudgets();
  }, [props.budgets, datesToShow, filterText]);

  const getFilterData = (value) => {
    if (value && value !== "") {
      setBudgetsFilter(filteredData(budgets, value));
    } else {
      const budgetsActives = budgets.filter((ele) => ele.active);
      setBudgetsFilter(budgetsActives);
    }
  };

  const assignnedBudgets = () => {
    const budgetsFiltered = props.budgets
      .map((element) => ({
        ...element,
        availableC: +element.amount - +element.available,
      }))
      .filter((ele) => {
        const createAt = new Date(ele.createAt).setHours(0, 0, 0, 0);
        return isWithinInterval(createAt, {
          start: datesToShow.fechaIni,
          end: datesToShow.fechaFin,
        });
      })
      .sort((a, b) => b.createAt - a.createAt);

    setBudgets(budgetsFiltered);
    if (initialLoader) {
      const budgetsActives = budgetsFiltered.filter((ele) => ele.active);
      setBudgetsFilter(budgetsActives);
    }
  };

  const getConnection = async () => {
    try {
      const response = await API.getConnectionsByAccount({
        account: props.currentAccount,
      });

      const connections = response.data.connections;
      setConnection(connections);
    } catch (error) {
      console.error("Error al obtener las conexiones:", error);
    }
  };

  const getUserPermissions = async () => {
    try {
      setIsLoading(true);

      const userLog = await Auth.currentAuthenticatedUser();
      const permisoAccResponse = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      const idRol = permisoAccResponse.data.permisos[0].idRol;
      const permisosResResponse = await API.getRoleNameById({ id: idRol });
      const permissAud = permisosResResponse.data.rol[0].permissions;

      const permissFn = permissAud.find((ele) => ele.name === "Presupuestos");

      if (!permissFn) {
        setDefaultPermissions();
        return;
      }

      setPermissions({
        approve: permissFn.approve,
        create: permissFn.create,
        delete: permissFn.delete,
        edit: permissFn.edit,
        export: permissFn.export,
        manageRoles: permissFn.manageRoles,
        view: permissFn.view,
      });
    } catch (error) {
      if (error === "The user is not authenticated") {
        setPermissions({
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: false,
          view: true,
        });
        return;
      }
      setDefaultPermissions();
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaultPermissions = () => {
    setPermissions({
      approve: false,
      create: false,
      delete: false,
      edit: false,
      export: false,
      manageRoles: false,
      view: false,
    });
  };

  const actualizarViews = async (tipo) => {
    if (!Auth.user) return;
    const sub = Auth.user.signInUserSession.idToken.payload.sub;
    try {
      const viewsData = await API.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "budgets",
      });

      const dataVie = viewsData.data.views;
      setViews(dataVie);

      if (tipo) setFilterInfo([]);

      if (dataVie.length === 0) return setNameView("Lista");

      const vistaActiva = dataVie.find((ele) => ele.active);
      if (!vistaActiva) return;
      setNameView(vistaActiva.name);
      if (tipo) {
        const nombres = vistaActiva.columns.map((ele) => {
          handleShowColumnChange(ele.id, ele.omit);
          return ele.id;
        });
        handleColumnSortChange(nombres);
      }
    } catch (error) {
      console.error("Error fetching views:", error);
    }
  };

  const goToEditPage = async (edit) => {
    setIsLoading(true);
    try {
      let findBudget = _.get(
        await API.getBudgetByIdEditModal({ id: edit.id }),
        "data.budget",
        "{}"
      );

      if (findBudget) {
        const newAmount = parseFloat(findBudget[0].amount);
        const available = parseFloat(findBudget[0].available);

        findBudget = {
          ...edit,
          availableC: newAmount - available,
          amount: newAmount,
          available: available,
        };
      }
      props._setEdit(findBudget);
      analytics.accionesTabla({
        accion: "Editar",
        ubicacion: "Presupuestos",
      });

      if (connection.length) {
        setOpenModal(true);
      } else {
        setOpenModal(true);
        setCreate(false);
        setModalUpsertOld(true);
      }
    } catch (err) {
      console.log("Error en los servicios", err);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return `${date.getDate()} - ${
      months[date.getMonth()]
    } - ${date.getFullYear()}`;
  };

  const handleCalendarFilter = (value) => {
    setDatesToShow({
      fechaFin: value.endDate,
      fechaIni: value.startDate,
    });
  };

  const handleFilterTextChange = (value) => {
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Presupuestos",
        busqueda: filterText,
      });
    }
    setFilterText(value);
    getFilterData(budgets, value);
  };

  const filteredData = (dateFilter) => {
    const searchValues = filterText.split(",").map((val) => val.trim());
    const idBudgetFilter = dateFilter.find(
      (budget) => budget.id === filterText
    );

    let filteredRecords;

    if (filterText?.toLowerCase() === "eliminado") {
      // Filtramos todos los eliminados
      filteredRecords = dateFilter.filter((budget) => !budget.active);
    } else if (idBudgetFilter) {
      // Buscamos por id y no importa si esta activo o no
      filteredRecords = [idBudgetFilter];
    } else {
      // Filtro normal
      filteredRecords = dateFilter.filter((record) => {
        const idLovs = (record.idLovs || []).map((ele) => ele.toString());
        const idLovsMatch = searchValues.some((searchValue) =>
          idLovs.includes(searchValue)
        );
        const otherParamsMatch = searchValues.some((searchValue) =>
          [
            record.title,
            record.id,
            record.available,
            record.amount,
            record.availableC,
          ].some((valueRes) =>
            String(valueRes).toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        return idLovsMatch || otherParamsMatch;
      });
      filteredRecords = filteredRecords.filter(
        (budgetActive) => budgetActive.active
      );
    }

    return filteredRecords;
  };

  const handleShowColumnChange = (column, show) => {
    analytics.ShowHide({
      ubicacion: "Presupuestos",
    });
    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
    }
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.id, ele.omit);
      } else {
        handleShowColumnChange(ele.id, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    if (id) actualizarEstado(id.id, false);
    let body = {
      id: idVIew,
      name: name,
      component: "budgets",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await API.insertView(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const actualizarEstado = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const handleDeleteView = async (name) => {
    try {
      setIsLoading(true);
      const activeView = views.find((view) => view.active);
      if (activeView && activeView.id === name) {
        setNameView("lista");
        columns.forEach((column) => {
          handleShowColumnChange(column.id, false);
        });
        handleColumnSortChange(orderDefault);
      }
      await API.deleteView({ id: name });
      actualizarViews(false);
    } catch (error) {
      console.error("Error deleting view:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatCellValue = (cellValue, id) => {
    switch (id) {
      case "createAt":
      case "updateAt":
        return formatDate(cellValue);
      case "status":
        return StatusName(cellValue);
      case "idLovs":
        return cellValue && cellValue.length > 0
          ? cellValue.map((ele) => ele).toString()
          : "";
      case "reserveForLovs":
        return "";
      default:
        return cellValue;
    }
  };

  const calculateTotal = (rows) => {
    let totalAmount = 0;
    let totalImplemented = 0;
    let totalAvailable = 0;
    rows.forEach((ele) => {
      if (Number(ele.amount) > 0) {
        totalAmount += Number(ele.amount);
      }
      if (Number(ele.availableC) > 0) {
        totalImplemented += Number(ele.availableC);
      }
      if (Number(ele.available) > 0) {
        totalAvailable += Number(ele.available);
      }
    });

    let totales = [
      {
        title: "Totales",
        id: "",
        amount: totalAmount,
        availableC: totalImplemented,
        available: totalAvailable,
        createAt: 0,
        updateAt: 0,
      },
    ];
    return totales;
  };

  const exportDataToExcel = () => {
    const headers = columns.filter((col) => !col.omit).map((col) => col.name);
    const selectors = columns.filter((col) => !col.omit).map((col) => col.tag);
    const dataExcel = [];

    if (!_.isEmpty(budgetsFilter)) {
      if (nameGrouped === "lovs") {
        let titlesUsed = new Set();

        budgetsFilter.forEach((ele) => {
          const idLovs = ele.idLovs;
          if (idLovs && idLovs.length > 0) {
            idLovs.forEach((idLov) => {
              if (!titlesUsed.has(idLov)) {
                titlesUsed.add(idLov);
                let dataGrouped = budgetsFilter.filter((element) =>
                  element.idLovs?.includes(idLov)
                );
                dataExcel.push([idLov]);
                dataExcel.push(headers);

                dataGrouped.forEach((item) => {
                  const excelData = selectors.map((selector) =>
                    formatCellValue(item[selector], selector)
                  );
                  dataExcel.push(excelData);
                });
                const totalData = calculateTotal(dataGrouped);
                if (totalData.length > 0) {
                  const excelTotalData = selectors.map((selector) =>
                    formatCellValue(totalData[0][selector], selector)
                  );
                  dataExcel.push(excelTotalData);
                }
              }
            });
          }
        });
      } else if (nameGrouped !== "") {
        let titlesUsed = [];
        budgetsFilter.map((elemento) => {
          let title =
            nameGrouped === "title"
              ? elemento[nameGrouped]
              : numberWithCommas(elemento[nameGrouped]);
          if (!titlesUsed.includes(title)) {
            dataExcel.push([], [title]);
            dataExcel.push(headers);
            let grouedData = budgetsFilter.filter((item) => {
              if (elemento[nameGrouped] === item[nameGrouped]) {
                return item;
              }
            });

            const excelData = selectors.map((selector) =>
              formatCellValue(elemento[selector], selector)
            );
            dataExcel.push(excelData);
            calculateTotal(grouedData).map((element) => {
              const excelData = selectors.map((selector) =>
                formatCellValue(element[selector], selector)
              );
              dataExcel.push(excelData);
            });
          }
        });
      } else {
        dataExcel.push(headers);
        budgetsFilter.forEach((element) => {
          const excelData = selectors.map((selector) =>
            formatCellValue(element[selector], selector)
          );
          dataExcel.push(excelData);
        });
        calculateTotal(budgetsFilter).map((element) => {
          const excelData = selectors.map((selector) =>
            formatCellValue(element[selector], selector)
          );
          dataExcel.push(excelData);
        });
      }
    }

    return dataExcel;
  };

  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.id === columnId);
      if (index != -1) aux[index].position = position;
    });

    aux = _.orderBy(aux, ["position"]);

    setColumns(aux);
  };

  const handleFilterPerColumn = (value) => {
    if (value.length === 0) {
      assignnedBudgets();
      return;
    }

    analytics.filtro({
      ubicacion: "Presupuestos",
    });

    setFilterInfo(value);
    const dataFinal = [];
    let dataToFilter = budgets;

    value.forEach((el) => {
      let dataAlt = [];
      switch (el.column) {
        case "Nombre de presupuesto":
          dataAlt = dataToFilter.filter((ele) =>
            ele.title.toLowerCase().includes(el.value.toLowerCase())
          );
          break;
        case "Línea de OV":
          dataAlt = dataToFilter.filter(
            (ele) => Array.isArray(ele.idLovs) && ele.idLovs.includes(+el.value)
          );
          break;
        case "status":
          dataAlt = dataToFilter.filter((ele) => ele.status === el.value);
          break;
        case "Presupuesto total":
          dataAlt = dataToFilter.filter((ele) => {
            const amount = Number(ele.amount);
            return el.type === "Menor"
              ? amount < +el.value
              : el.type === "Igual"
              ? amount === +el.value
              : el.type === "Mayor"
              ? amount > +el.value
              : false;
          });
          break;
        case "Presupuesto implementado":
          dataAlt = dataToFilter.filter((ele) => {
            const availableC = Number(ele.availableC);
            return el.type === "Menor"
              ? availableC < +el.value
              : el.type === "Igual"
              ? availableC === +el.value
              : el.type === "Mayor"
              ? availableC > +el.value
              : false;
          });
          break;
        case "Presupuesto disponible":
          dataAlt = dataToFilter.filter((ele) => {
            const available = Number(ele.available);
            return el.type === "Menor"
              ? available < +el.value
              : el.type === "Igual"
              ? available === +el.value
              : el.type === "Mayor"
              ? available > +el.value
              : false;
          });
          break;
        default:
          break;
      }

      if (dataAlt.length > 0) {
        dataFinal.push(...dataAlt);
      }
    });

    const dataActiva = dataFinal.filter((ele) => ele.active !== false);
    const set = new Set(dataActiva.map(JSON.stringify));
    const arrSinDuplicaciones = Array.from(set).map(JSON.parse);

    setBudgetsFilter(arrSinDuplicaciones);
  };

  let cols = _.orderBy(columns, ["name"], ["asc", "desc"]);

  const agrupacionChange = () => {
    let titlesUsed = [];
    const elements = budgetsFilter.map((ele, idx) => {
      let title =
        nameGrouped == "title"
          ? ele[nameGrouped]
          : numberWithCommas(ele[nameGrouped]);
      if (!titlesUsed.includes(title)) {
        titlesUsed.push(title);
        let dataGrouped = budgetsFilter.filter(
          (element) => element[nameGrouped] === ele[nameGrouped]
        );

        return (
          <>
            <h1>{title}</h1>
            <ViewTable
              key={idx}
              columns={columns}
              filterText={filterText}
              data={dataGrouped}
              setData={setBudgetsFilter}
              permissions={permissions}
            />
            ;
          </>
        );
      }
    });
    return elements;
  };

  const handleGroupedChange = async (value) => {
    try {
      analytics.agrupar({
        ubicacion: "Presupuestos",
      });

      let groupedBy = "";

      switch (value) {
        case "Nombre":
          groupedBy = "title";
          break;
        case "Fecha de creación":
          groupedBy = "createAt";
          break;
        case "Última actualización":
          groupedBy = "updateAt";
          break;
        case "Presupuesto total":
          groupedBy = "amount";
          break;
        case "Presupuesto implementado":
          groupedBy = "availableC";
          break;
        case "Presupuesto disponible":
          groupedBy = "available";
          break;
        case "Estado":
          groupedBy = "status";
          break;
        case "Lovs":
          groupedBy = "lovs";
          break;
        default:
          groupedBy = "";
          break;
      }
      setnameGrouped(groupedBy);
    } catch (error) {
      console.error("Error grouped change:", error);
    }
  };

  function obtenerHoraFormateada(fechaStr) {
    return fechaStr?.split(",")[1] || "";
  }

  function esFechaValida(fecha) {
    return !isNaN(fecha.getTime());
  }

  function obtenerFechaFormateada(fechaStr) {
    const formatoEsperado = /\d{4}-\d{2}-\d{2}/;
    if (!formatoEsperado.test(fechaStr?.split(",")[0])) {
      return format(new Date(), "dd/MM/yyyy HH:mm:ss");
    }
    const fechaCorto = parse(fechaStr?.split(",")[0], "yyyy-MM-dd", new Date());
    if (isValid(fechaCorto)) {
      return format(fechaCorto, "dd/MM/yyyy");
    } else {
      return format(new Date(), "dd/MM/yyyy HH:mm:ss");
    }
  }

  function determinarDia(fechaStr) {
    const formatoCorto = "yyyy-MM-dd";
    const formatoLargo = "dd/MM/yyyy, HH:mm:ss";

    // Intentar parsear la fecha en formato largo
    let fechaFormatoLargo = parse(fechaStr, formatoLargo, new Date());
    let validate = esFechaValida(fechaFormatoLargo);

    if (!validate) {
      // Si no se pudo parsear en formato largo, intentar en formato corto
      fechaFormatoLargo = parse(fechaStr, formatoCorto, new Date());
      validate = esFechaValida(fechaFormatoLargo);
    }

    if (isToday(fechaFormatoLargo)) {
      return "hoy " + obtenerHoraFormateada(fechaStr, formatoLargo);
    } else if (isYesterday(fechaFormatoLargo)) {
      return "ayer " + obtenerHoraFormateada(fechaStr, formatoLargo);
    } else {
      return obtenerFechaFormateada(
        fechaStr,
        validate ? formatoCorto : formatoLargo
      );
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.closest(".boxCalendar")
      ) {
        setActivate(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const activateData = async (edit) => {
    try {
      let findBudget = _.get(
        await API.getBudgetByIdEditModal({ id: edit.id }),
        "data.budget",
        "{}"
      );

      if (findBudget) {
        const newAmount = parseFloat(findBudget[0].amount);
        const available = parseFloat(findBudget[0].available);

        findBudget = {
          ...edit,
          availableC: newAmount - available,
          amount: newAmount,
          available: available,
        };
      }
      props._setEdit(findBudget);
      if (connection.length) {
        setOpenModal(true);
      } else {
        setOpenModal(true);
        setCreate(false);
        setModalUpsertOld(true);
      }
    } catch (error) {
      console.error("Error activate data:", error);
    } finally {
      setIsLoading(false);
    }
    // let title = "";
    // title = `¿Está  seguro que desea activar el presupuesto <strong>"${row.title}"</strong>?`;
    // props._showConfirmationModal("¿Activar presupuesto?", title, async () => {
    //   try {
    //     setIsLoading(true);
    //     if (row.reserveForLovs) {
    //       let response = await APILA.getAllLovs({
    //         account: props.currentAccount,
    //       });
    //       let data = response.data.data;
    //       for (let ele of data) {
    //         let found = row.reserveForLovs.find(
    //           (elemento) => elemento.id === ele.id
    //         );
    //         if (found !== undefined) {
    //           let newBudgetAvailable =
    //             parseFloat(ele.budgetAvailable) - parseFloat(found.reserve);
    //           let newReserve = Number(ele.reserve) + Number(found.reserve);
    //           await APILA.updateAvailableLov({
    //             id: found.id,
    //             budgetAvailable: newBudgetAvailable,
    //             reserve: newReserve,
    //             idsBudget: ele.idsBudget,
    //           });
    //         }
    //       }
    //     } else {
    //       console.log("No contiene reserveForLovs");
    //     }
    //     let activat = await APILA.updateBudget({ id: row.id, active: true });

    //     let bodyBitacora = {
    //       id: v4(),
    //       date: new Date(),
    //       account: account,
    //       action: "se ha activado un presupuesto",
    //       component: "budgets",
    //       data: row,
    //     };
    //     API.insertBitacora(bodyBitacora).then(() => {
    //       Swal.fire({
    //         title: "Presupuesto activado!",
    //         text: "Presupuesto activado correctamente.",
    //         icon: "success",
    //         showCancelButton: false,
    //         confirmButtonColor: "#3085d6",
    //         confirmButtonText: "Ok",
    //       });

    //       props._clearBudgets();
    //       setTimeout(() => {
    //         props._getClients(props.currentUser, idmod, mod);
    //         props._getBudgets(account, props.currentUser, idmod, mod);
    //         props._getAccountTags(account, props.currentUser, idmod, mod);
    //         props._getAllCampaigns(account, props.currentUser, idmod, mod);
    //         setFilterText("");
    //         setIsLoading(false);
    //       }, 500);
    //     });
    //     setIsLoading(false);
    //   } catch (error) {
    //     Swal.fire({
    //       title: "Ups!",
    //       text: "Hubo un error al activar el presupuesto",
    //       icon: "error",
    //       showCancelButton: false,
    //       confirmButtonColor: "#3085d6",
    //       confirmButtonText: "Ok",
    //     });
    //     setIsLoading(false);
    //   }
    // });
  };

  const agrupacionLovs = () => {
    if (nameGrouped === "lovs") {
      let titlesUsed = [];
      const elements = budgetsFilter.flatMap((ele) => {
        const idLovs = ele.idLovs;
        if (idLovs && idLovs.length > 0) {
          return idLovs.map((idLov) => {
            if (!titlesUsed.includes(idLov)) {
              titlesUsed.push(idLov);
              let dataGrouped = budgetsFilter.filter((element) =>
                element.idLovs?.includes(idLov)
              );
              return (
                <>
                  <h1>{idLov}</h1>
                  <ViewTable
                    key={idLov}
                    columns={columns}
                    setData={setBudgetsFilter}
                    filterText={filterText}
                    data={dataGrouped}
                    permissions={permissions}
                  />
                </>
              );
            }
            return null; // Retornar null para elementos repetidos
          });
        }
        return null; // Retornar null para objetos sin idLovs
      });
      return elements;
    }
  };

  const viewChanges = () => {
    switch (state) {
      case 2:
        return (
          <Lovs setIsLoading={setIsLoading} state={state} setState={setState} />
        );
      case 3:
        return (
          <Lovs setIsLoading={setIsLoading} state={state} setState={setState} />
        );
      case 4:
        return (
          <ConflictingBudgets
            setIsLoading={setIsLoading}
            setState={setState}
            state={state}
            showLoader={props._showLoader}
            currentAccount={props.currentAccount}
            formatDate={formatDate}
            numberWithCommas={numberWithCommas}
          />
        );
      default:
        if (nameGrouped === "lovs") return agrupacionLovs();
        else if (nameGrouped !== "") return agrupacionChange();
        else
          return (
            <>
              <ViewTable
                columns={columns}
                filterText={filterText}
                data={budgetsFilter}
                permissions={permissions}
                setTotalColumn={setTotalColumn}
              />
            </>
          );
    }
  };

  const changeState = async (value) => {
    setState(value);
    localStorage.setItem("viewBudget", value);
  };

  if (props.isIpv4) {
    return (
      <>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {props.budgets.length > 0 && (
              <>
                <div className="barraSuperior barraSuperiorBudget__logout">
                  <DataTableUtils
                    data={budgetsFilter}
                    valueFilter={filterText || ""}
                    action={async () => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        Swal.fire({
                          title: "Cuenta Requerida",
                          text: "Por favor selecciona una cuenta antes",
                          icon: "warning",
                        }).then((response) => {
                          if (!response.isConfirmed) return;
                          if (props?.accounts.length > 0) return;
                          props._nexStep(1);
                        });
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Presupuestos",
                      });
                      if (connection.length) {
                        setIsLoading(true);
                        setOpenModal(true);
                        setIsLoading(false);
                      } else {
                        setIsLoading(true);
                        setModalUpsertOld(!modalUpsertOld);
                        setCreate(true);
                        setIsLoading(false);
                      }
                    }}
                    actionIcon={
                      <Tooltip
                        color="#002448"
                        placement="top"
                        title="Crear presupuesto"
                      >
                        <i className="fas fa-plus" />
                      </Tooltip>
                    }
                    onChangeFilterText={(value) => {
                      handleFilterTextChange(value);
                    }}
                  >
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Exportar información"
                    >
                      <div
                        onClick={() => {
                          analytics.Compartir({
                            ubicacion: "Presupuestos",
                          });
                        }}
                        className="ExportBtn"
                      >
                        <Share
                          data={exportDataToExcel()}
                          columns={columns}
                          isArray={true}
                          exportXLSX={true}
                          exportTXT={true}
                          exportDropbox={true}
                          exportDrive={true}
                          exportCSV={true}
                          printable={true}
                          exportPNG={true}
                          idImg={"root"}
                          appKey="2u78gno172idwbz"
                          exportLink={true}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Personalizar columnas"
                    >
                      <div id="shareBtn">
                        <ShowColumn2
                          columns={columns}
                          showColumn={handleShowColumnChange}
                          showView={handleShowView}
                          onSortChange={(positions) => {
                            analytics.Columnas({
                              ubicacion: "Presupuestos",
                            });
                            handleColumnSortChange(positions);
                          }}
                          vistas={views}
                          setVista={(nombre) => {
                            analytics.accionesVista({
                              ubicacion: "Presupuestos",
                              accion: "Agregar",
                            });
                            handleAddView(nombre);
                          }}
                          deleteView={(name) => {
                            analytics.accionesVista({
                              ubicacion: "Presupuestos",
                              accion: "ELiminar",
                            });
                            handleDeleteView(name);
                          }}
                          nameAc={nameView}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Filtrar y agrupar"
                    >
                      <div className="Filtro2 filtro3 ">
                        <FilterColumn2
                          columns={cols}
                          options={options}
                          data={budgetsFilter}
                          changeGrouped={handleGroupedChange}
                          onFilterColumn={(value) =>
                            handleFilterPerColumn(value)
                          }
                          filterInfoCheck={filterInfo}
                          categories={[]}
                        />
                      </div>
                    </Tooltip>
                    <div
                      className="boxCalendar"
                      ref={modalRef}
                      onClick={() => setActivate((prev) => !prev)}
                    >
                      <div className="boxStartEndDate">
                        <p className="startDate">
                          Del{" "}
                          {new Date(datesToShow.fechaIni).toLocaleDateString(
                            "es-GT"
                          )}
                        </p>
                        <p className="endDate">
                          {" "}
                          Hasta{" "}
                          {new Date(datesToShow.fechaFin).toLocaleDateString(
                            "es-GT"
                          )}
                        </p>
                      </div>
                      <div className="container-icon-calendar">
                        <i
                          className="fa fa-calendar"
                          aria-hidden="true"
                          style={{ color: "black" }}
                        ></i>
                      </div>
                      {activate && (
                        <div onClick={(e) => e.stopPropagation()}>
                          <DateRangeFilter
                            onActivate={activate}
                            onChange={handleCalendarFilter}
                            initDate={datesToShow.fechaIni}
                            finishDate={datesToShow.fechaFin}
                          />
                        </div>
                      )}
                    </div>
                  </DataTableUtils>
                </div>
              </>
            )}
            {state === 1 ? (
              budgetsFilter.length > 0 ? (
                <>
                  <div className="Kanbang-content">{viewChanges()}</div>
                </>
              ) : (
                <div className="form homeSinLogin">
                  <div>
                    <p className="subTitle">Bienvenido a Let's Advertise</p>
                    <h1 className="title">Crea tu primer presupuesto</h1>
                    <a
                      href="#"
                      className="btn-plus"
                      onClick={async () => {
                        const mCurrentAccount =
                          props.currentUser !== ""
                            ? props.currentAccount
                            : props.currentPWAAccount;

                        if (mCurrentAccount === "") {
                          Swal.fire({
                            title: "Cuenta Requerida",
                            text: "Por favor selecciona una cuenta antes",
                            icon: "warning",
                          }).then((response) => {
                            if (!response.isConfirmed) return;
                            if (props?.accounts.length > 0) return;
                            props._nexStep(1);
                          });
                          return;
                        }
                        analytics.Add({
                          ubicacion: "Presupuestos",
                        });
                        if (connection.length) {
                          setIsLoading(true);
                          setShowModal(!showModal);
                          setIsLoading(false);
                        } else {
                          setIsLoading(true);
                          setModalUpsertOld(!modalUpsertOld);
                          setCreate(true);
                          setIsLoading(false);
                        }
                      }}
                    >
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                </div>
              )
            ) : (
              <>
                <div className="">{viewChanges()}</div>
              </>
            )}
            {!connection.length && modalUpsertOld && (
              <ModalUpsertOld
                modalUpsertOld={modalUpsertOld}
                setModalUpsertOld={setModalUpsertOld}
                create={create}
              />
            )}
            {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permissions.view ? (
        <>
          {state === 1 && (
            <>
              <div className="barraSuperior barraSuperiorBudget">
                <Select
                  id="viewSelect"
                  defaultValue={state}
                  showSearch={false}
                  onChange={(e) => changeState(e)}
                  className="select-dashboard"
                >
                  <Option value={1} className="fa textSelectBudget">
                    <Tooltip
                      title="Vista de presupuestos"
                      placement="rightTop"
                      color="#002448"
                    >
                      <div>
                        <i className="fa fa-table"></i> Vista de presupuestos{" "}
                      </div>
                    </Tooltip>
                  </Option>
                  <Option value={4} className="fa textSelectBudget">
                    <Tooltip
                      title="Presupuestos en conflictos"
                      placement="rightTop"
                      color="#002448"
                    >
                      <div>
                        <i className="fa fa-table"></i> Presupuestos en
                        conflictos{" "}
                      </div>
                    </Tooltip>
                  </Option>
                  <Option value={3} className="fa textSelectBudget">
                    <Tooltip
                      title="Vista de líneas de OV"
                      placement="rightTop"
                      color="#002448"
                    >
                      <div>
                        <i className="fa fa-table"></i> Vista de líneas de OV{" "}
                      </div>
                    </Tooltip>
                  </Option>
                  <Option value={2} className="fa textSelectBudget">
                    <Tooltip
                      title="Líneas de OV en conflicto"
                      placement="rightTop"
                      color="#002448"
                    >
                      <div>
                        <i className="fa fa-table"></i> Líneas de OV en
                        conflicto{" "}
                      </div>
                    </Tooltip>
                  </Option>
                </Select>
                <DataTableUtils
                  data={budgetsFilter}
                  valueFilter={filterText || ""}
                  action={
                    permissions.create
                      ? async () => {
                          const mCurrentAccount =
                            props.currentUser !== ""
                              ? props.currentAccount
                              : props.currentPWAAccount;

                          if (mCurrentAccount === "") {
                            props._showSelectAccountModal();
                            return;
                          }
                          analytics.Add({
                            ubicacion: "Presupuestos",
                          });
                          if (connection.length) {
                            setIsLoading(true);
                            // setShowModal(!showModal);
                            setOpenModal(true);
                            setIsLoading(false);
                          } else {
                            setIsLoading(true);
                            setModalUpsertOld(!modalUpsertOld);
                            setCreate(true);
                            setIsLoading(false);
                          }
                        }
                      : null
                  }
                  actionIcon={
                    permissions.create && (
                      <Tooltip
                        color="#002448"
                        placement="top"
                        title="Crear presupuesto"
                      >
                        <i className="fas fa-plus" />
                      </Tooltip>
                    )
                  }
                  onChangeFilterText={(value) => {
                    handleFilterTextChange(value);
                  }}
                >
                  <Tooltip
                    color="#002448"
                    placement="top"
                    title="Exportar información"
                  >
                    <div
                      onClick={() => {
                        analytics.Compartir({
                          ubicacion: "Presupuestos",
                        });
                      }}
                      className="ExportBtn"
                    >
                      {permissions.export && (
                        <Share
                          data={exportDataToExcel()}
                          columns={columns}
                          isArray={true}
                          exportXLSX={true}
                          exportTXT={true}
                          exportDropbox={true}
                          exportDrive={true}
                          exportCSV={true}
                          printable={true}
                          exportPNG={true}
                          idImg={"root"}
                          appKey="2u78gno172idwbz"
                          exportLink={true}
                        />
                      )}
                    </div>
                  </Tooltip>
                  <Tooltip
                    color="#002448"
                    placement="top"
                    title="Personalizar columnas"
                  >
                    <div id="shareBtn">
                      <ShowColumn2
                        columns={columns}
                        showColumn={handleShowColumnChange}
                        showView={handleShowView}
                        onSortChange={(positions) => {
                          analytics.Columnas({
                            ubicacion: "Presupuestos",
                          });
                          handleColumnSortChange(positions);
                        }}
                        vistas={views}
                        // setVista={handleAddView}
                        // deleteView={handleDeleteView}
                        setVista={(nombre) => {
                          analytics.accionesVista({
                            ubicacion: "Presupuestos",
                            accion: "Agregar",
                          });
                          handleAddView(nombre);
                        }}
                        deleteView={(name) => {
                          analytics.accionesVista({
                            ubicacion: "Presupuestos",
                            accion: "ELiminar",
                          });
                          handleDeleteView(name);
                        }}
                        nameAc={nameView}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    color="#002448"
                    placement="top"
                    title="Filtrar y agrupar"
                  >
                    <div className="Filtro2 filtro3 ">
                      <FilterColumn2
                        columns={cols}
                        options={options}
                        data={budgetsFilter}
                        changeGrouped={handleGroupedChange}
                        onFilterColumn={(value) => handleFilterPerColumn(value)}
                        filterInfoCheck={filterInfo}
                        categories={[]}
                      />
                    </div>
                  </Tooltip>
                  <div
                    className="boxCalendar"
                    ref={modalRef}
                    onClick={() => setActivate((prev) => !prev)}
                  >
                    <div className="boxStartEndDate">
                      <p className="startDate">
                        Del{" "}
                        {new Date(datesToShow.fechaIni).toLocaleDateString(
                          "es-GT"
                        )}
                      </p>
                      <p className="endDate">
                        {" "}
                        Hasta{" "}
                        {new Date(datesToShow.fechaFin).toLocaleDateString(
                          "es-GT"
                        )}
                      </p>
                    </div>
                    <div className="container-icon-calendar">
                      <i
                        className="fa fa-calendar"
                        aria-hidden="true"
                        style={{ color: "black" }}
                      ></i>
                    </div>
                    {activate && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <DateRangeFilter
                          onActivate={activate}
                          onChange={handleCalendarFilter}
                          initDate={datesToShow.fechaIni}
                          finishDate={datesToShow.fechaFin}
                          visibleButtonApply={true}
                          getFilterData={getFilterData}
                        />
                      </div>
                    )}
                  </div>
                </DataTableUtils>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <a
                  style={{
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    color: textSinc ? "#A689F9" : "#686868",
                  }}
                >
                  {" "}
                  {textSinc
                    ? "¡ERP actualizado! "
                    : "Última sincronización "}{" "}
                </a>
                <a
                  style={{
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    marginRight: "-15px",
                    marginLeft: "10px",
                    fontWeight: 600,
                  }}
                >
                  {determinarDia(props.lastSinc)}
                </a>
                <a
                  href="#"
                  onClick={async () => {
                    await props._sincPartialOdoo(
                      props.currentAccount,
                      props.accounts
                    );
                    setTextSinc(true);
                    setTimeout(() => {
                      setTextSinc(false);
                    }, 30000);
                  }}
                  style={{
                    marginLeft: "2%",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                  className="hover-container"
                >
                  <img
                    src={iconoSinc}
                    alt="Icono Sinc"
                    className="imagen-normal"
                  />
                  <img
                    src={iconoSincHover}
                    alt="Icono Sinc"
                    className="imagen-hover"
                  />
                </a>
              </div>
            </>
          )}
          {state === 1 ? (
            budgetsFilter.length > 0 ? (
              <>
                <div className="Kanbang-content">{viewChanges()}</div>
              </>
            ) : (
              <div className="form homeSinLogin">
                <div>
                  <p className="subTitle">Bienvenido a Let's Advertise</p>
                  <h1 className="title">Crea tu primer Presupuesto</h1>
                  {permissions.create && (
                    <a
                      href="#"
                      className="btn-plus"
                      onClick={async () => {
                        const mCurrentAccount =
                          props.currentUser !== ""
                            ? props.currentAccount
                            : props.currentPWAAccount;

                        if (mCurrentAccount === "") {
                          props._showSelectAccountModal();
                          return;
                        }
                        analytics.Add({
                          ubicacion: "Presupuestos",
                        });
                        if (connection.length) {
                          setIsLoading(true);
                          setShowModal(!showModal);
                          setIsLoading(false);
                        } else {
                          setIsLoading(true);
                          setModalUpsertOld(!modalUpsertOld);
                          setCreate(true);
                          setIsLoading(false);
                        }
                      }}
                    >
                      <i className="fas fa-plus" />
                    </a>
                  )}
                </div>
              </div>
            )
          ) : (
            <>
              <div className="">{viewChanges()}</div>
            </>
          )}
          {!connection.length && modalUpsertOld && (
            <ModalUpsertOld
              modalUpsertOld={modalUpsertOld}
              setModalUpsertOld={setModalUpsertOld}
              create={create}
            />
          )}
          {connection.length > 0 && openModal && (
            <ModalUpsertNew setOpenModal={setOpenModal} openModal={openModal} />
          )}
        </>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  isIpv4: state.app.isIPV4,
  step: state.userLogout.nextStepCategory,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  blocBudget: state.accounts.blocBudget,
  account: state.accounts.default,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  allCampaigns: state.allCampaigns,
  lastSinc: state.odoo.lastSinc,
});

const mapDispatchToProps = (dispatch) => ({
  _getClients: (user, idmod, mod) => dispatch(getClients(user, idmod, mod)),
  _nexStep: (step) => dispatch(nextStep(step)),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _clearBudgets: () => dispatch(clearBudgets()),
  _showLoader: (show) => dispatch(showLoader(show)),
  _getBudgets: (account, user, idmod, mod) =>
    dispatch(getBudgets(account, user, idmod, mod)),
  _getAllCampaigns: (account, user, idmod, mod) =>
    dispatch(getAllCampaigns(account, user, idmod, mod)),
  _getAccountTags: (account, user, idmod, mod) =>
    dispatch(getAccountTags(account, user, idmod, mod)),
  _getLastSinc: (account) => dispatch(getLastSinc(account)),
  _descrifrar: (texto, password) => dispatch(descifrar(texto, password)),
  _sincPartialOdoo: (account, accounts) =>
    dispatch(sincPartialOdoo(account, accounts)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => {}
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Content)));
