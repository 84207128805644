// import * as Modal from './modal.actions'
// import * as APILA from 'helpers/apiLA'
// import * as Logger from 'utils/logger'
// export const MODAL_ADD_ID_IMAGE = 'MODAL:ADD:ID_IMAGE'
import * as Modal from './modal.actions'
// export const ADD_URL = 'URL:ADD'
 export const CLEAN_URLS = 'URL:CLEAN'
// const TAG = 'ModalImage:Actions'



export const MODAL_ADD_ID_URL = 'MODAL:ADD_URL'

// export const cleanURL = () => (dispatch) => {
//   dispatch({
//     type: CLEAN_URLS,
//   })
// }

export const getUrlImages = (id) => (dispatch) => {
  // cleanURL(dispatch);
  console.log("getUrlImages actions id " , id)
  if (id || id === 0) {    
    dispatch({
      type: MODAL_ADD_ID_URL,
      id,
    })
  } else {
    dispatch(Modal.showAlertModal('Error!', 'La campaña no es valida', Modal.MODAL_TYPE_ERROR))
    console.log('No existe el id')
    console.log(id)
  }
}

// export const getUrlByIdMixDetail = (body) => dispatch => {
//     dispatch(cleanURL());
    
//     console.log("getUrlByIdMixDetail action body.data",body);      
//     let req={id_mix_detail:body}
//      APILA.getUrlByIdMixDetail(req)
//       .then(response => {
//         Logger.log(TAG, 'getUrlByIdMixDetail response', response)        
        
//         if(response.data.status!==100){
//           response.data.URLS.forEach(ima => {
//             dispatch(addImage(ima))
//        })
//         }
       
//       })
//       .catch(err => {
//         Logger.log(TAG, 'error getUrlByIdMixDetail', err)
//       })
//       .then(() => {
//         // dispatch()
//         Logger.log(TAG, 'getUrlByIdMixDetail then')

//       })
//   }


//   const addImage = imagenObj => {
    
//     return dispatch => {
//       dispatch({
//         type: ADD_URL,
//         imagenObj
//       })
//     }
//   }
  