import * as Actions from '../actions/files.actions'

const initialState = {
  files: [],
}
    //files: [],
 // }

  export default (state = initialState, action) => {
    switch (action.type) {
      case Actions.FILE_CLEAR:
        return {
            ...state,
            files:[],
        }

      case Actions.FILE_ADD_FILE:
        return {
          ...state,
          files:state.files.concat(action.file)
        }
      case Actions.FILE_REMOVE_FILE:
        return {
          ...state,
          files: state.files.filter((file) => file.name !== action.file),
        }
      
      default:
        return state
    }
  }