import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import "./assets/css/styles.scss";
import AccountSelector from "components/accounts/selector";
import imgIdea from "./assets/img/bombilla3.png";
import analytics from "../../helpers/analytics";
import { Nav } from "@easygosanet/react-navbar";
import { format, startOfMonth, endOfMonth } from "date-fns";
import logoLets from "./assets/img/nuevoLogo.png";

import { Tooltip } from "antd";
import { setEditVisualizer } from "redux/actions/templates.actions";
import { withUtils } from "utils";
import { Auth } from "aws-amplify";

import { alertEdit } from "redux/actions/templates.actions";

const NavBar = ({
  authState,
  location,
  onStateChange,
  currentUser,
  editActivateTemplate,
  setEditVisualizerEdit,
  accountsByUser,
  currentAccount,
  _alertEdit,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [toggle, setToggle] = useState(false);
  const [logout, setLogout] = useState(false);
  useEffect(() => {
    setActive(editActivateTemplate);
  }, [editActivateTemplate]);
  // Presupuestos
  const today = new Date();
  let pathname = window.location.pathname;

  const firstDayOfMonth = format(startOfMonth(today), "dd/MM/yyyy");
  const lastDayOfMonth = format(endOfMonth(today), "dd/MM/yyyy");

  // Campa;as
  const dateInitial = format(
    new Date().setDate(new Date().getDate() - 7),
    "dd/MM/yyyy"
  );
  const dateFinal = format(new Date(), "dd/MM/yyyy");

  const goToLogin = (e) => {
    e.preventDefault();
    onStateChange("signUp", {});
  };

  const goToHome = () => {
    if (authState !== "signedIn") {
      onStateChange("signIn", {});
    }
  };

  const showPWA = (nombre) => {
    if (authState !== "signedIn") {
      onStateChange("PWA", {});
    }
    analytics.Click_Navbar(nombre);
    if (active) {
      const resposne = _alertEdit(active);
      if (resposne) {
        setEditVisualizerEdit(false);
        setActive(false);
      } else {
        return false;
      }
    }
    return true;
  };

  const showPWA2 = (nombre) => {
    if (authState !== "signedIn") {
      onStateChange("PWA", {});
    }
    if (active) {
      const resposne = _alertEdit(active);
      if (resposne) {
        setEditVisualizerEdit(false);
        setActive(false);
      } else {
        return false;
      }
    }
    analytics.Click_Navbar_acciones(nombre);
    return true;
  };

  const validateUser = async () => {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (error) {
      if (error === "The user is not authenticated") {
        setLogout(true);
        if (authState === "signUp" || authState === "PWA") return;
        let mapRoutes = [
          "/categorias",
          "/publicos",
          "/presupuestos",
          "/reportes",
          "/templates",
          "/configurcion",
        ];
        if (pathname?.includes(mapRoutes[0])) {
          showPWA("categorias");
        } else if (pathname?.includes(mapRoutes[1])) {
          showPWA("publicos");
        } else if (pathname?.includes(mapRoutes[2])) {
          showPWA("presupuestos");
        } else if (pathname?.includes(mapRoutes[3])) {
          showPWA("reportes");
        } else if (pathname?.includes(mapRoutes[4])) {
          showPWA("Planificar");
        } else if (pathname?.includes(mapRoutes[5])) {
          showPWA("configurcion");
        }
      }
    }
  };

  useEffect(() => {
    validateUser();
  }, []);

  const ContentView = () => {
    let url = window.location.href;
    let newURL = url.split("/");
    let fragment = newURL[3];
    switch (fragment) {
      case "categories":
        return (
          <>
            <h3 className="TituloIframe">Sección de categorías</h3>
            <iframe
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/videoseries?list=PLyK0IhuYMtZeVesTgrxIrfZnlKscwyKS_"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </>
        );
      case "audiences":
        return (
          <>
            <h3 className="TituloIframe">Sección de públicos</h3>
            <iframe
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/videoseries?list=PLyK0IhuYMtZdK4AWN9ehF9nvnY0fKevu5"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </>
        );
      // case "creative":
      //   return (
      //     <>
      //       <h3 className="TituloIframe">Sección de creatividad</h3>
      //       <iframe
      //         width="100%"
      //         height="370"
      //         src="https://www.youtube.com/embed/videoseries?list=PLyK0IhuYMtZchIJHRvACZ6ImL_dBxWJLu"
      //         title="YouTube video player"
      //         frameborder="0"
      //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      //         allowfullscreen
      //       ></iframe>
      //     </>
      //   );
      case "budgets":
        return (
          <>
            <h3 className="TituloIframe">Sección de presupuestos</h3>
            <iframe
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/videoseries?list=PLyK0IhuYMtZfdQf-oLKaTR4O7v32FD2Rc"
              itle="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </>
        );
      case "analytics":
        return (
          <>
            <h3 className="TituloIframe">Sección de reportes</h3>
            <iframe
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/videoseries?list=PLyK0IhuYMtZffpLln5P56TSIbmES7iTFW"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </>
        );
      default:
        return (
          <>
            <h3 className="TituloIframe">Sección de campañas</h3>
            <iframe
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/videoseries?list=PLyK0IhuYMtZedIdM7q3MtayjNl6_ehRJM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </>
        );
    }
  };

  const MenuResponsive = () => {
    return (
      <ul ref={wrapperRef} className="menuResponsive">
        <AccountSelector accountsByUser={accountsByUser} />
        <Nav>
          <li>
            <NavLink
              to={`/campañas/account=${currentAccount}&view=1&group=default&viewColumn=default&page=1&initial_date=${dateInitial}&final_date=${dateFinal}`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/campañas/")) {
                  return true;
                }
              }}
            >
              Campañas
            </NavLink>
            {/* <NavLink to="/campañas">Campañas</NavLink> */}
          </li>
          <li>
            <NavLink
              to="/templates"
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/templates")) {
                  return true;
                }
              }}
            >
              Planificar
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/categorias/account=${currentAccount}&view=list&group=default`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/categorias/")) {
                  return true;
                }
              }}
            >
              Categorías
            </NavLink>
            {/* <NavLink to="/categorias">Categorías</NavLink> */}
          </li>
          <li>
            <NavLink
              to={`/publicos/account=${currentAccount}&view=list&group=default&page=1`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/publicos/")) {
                  return true;
                }
              }}
            >
              Públicos
            </NavLink>
            {/* <NavLink to="/publicos">Públicos</NavLink> */}
          </li>
          {/* <li>
            <NavLink to="/creatividad">Creatividad</NavLink>
          </li> */}
          {currentUser === "83caaa4a-0388-4f4a-a1a4-b23a8396d9c9" && (
            <li>
              <NavLink to="/scan" onClick={showPWA}>
                Escáner
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to={`/presupuestos/account=${currentAccount}&view_budget=1&view=list&group=default&page=1&initial_date=${firstDayOfMonth}&final_date=${lastDayOfMonth}`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/presupuestos/")) {
                  return true;
                }
              }}
            >
              Presupuestos
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reportes"
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/reportes/")) {
                  return true;
                }
              }}
            >
              Reportes
            </NavLink>
          </li>
        </Nav>
        <li className="itemButton">
          <button
            className="HelpBtn"
            onClick={(e) => {
              setShowModal(true);
              showPWA2("Help");
            }}
          >
            <img src={imgIdea} />
            Ayuda
          </button>
        </li>
        {authState === "signedIn" && (
          <li>
            <NavLink to="/perfil" onClick={() => showPWA2("Profile")}>
              <i className="far fa-user"></i>
              Perfil
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            to="/configuracion"
            activeStyle={{
              color: "#000000",
              borderBottom: "5px solid #000000",
            }}
            isActive={(_, location) => {
              if (location.pathname.includes("/campañas/")) {
                return true;
              }
            }}
            onClick={() => showPWA2("Configuration")}
          >
            <i className="fas fa-cog"></i>
            Configuraciones
          </NavLink>{" "}
        </li>
        {authState !== "signUp" &&
          authState !== "signedIn" &&
          authState !== "forgotPassword" &&
          authState !== "register" && (
            <li>
              <NavLink to="/login" className="Login" onClick={goToLogin}>
                Ingresar
              </NavLink>
            </li>
          )}
      </ul>
    );
  };

  const wrapperRef = useRef();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <nav className="Navbar">
        <Link to="/" className="logo">
          <Tooltip title="Let's Advertise Ads" placement="top" color="#002448">
            <img src={logoLets} className="logoimg" />
          </Tooltip>
        </Link>
        <ul className="menu">
          <AccountSelector accountsByUser={accountsByUser} />
          <li>
            <NavLink
              to={`/campañas/account=${currentAccount}&view=1&group=default&viewColumn=default&page=1&initial_date=${dateInitial}&final_date=${dateFinal}`}
              onClick={(e) => showPWA("campañas") || e.preventDefault()}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/campañas/")) {
                  return true;
                }
              }}
            >
              Campañas
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/templates"
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/templates")) {
                  return true;
                }
              }}
              onClick={(e) => showPWA("Planificar") || e.preventDefault()}
            >
              Planificar
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/categorias/account=${currentAccount}&view=list&group=default`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/categorias/")) {
                  return true;
                }
              }}
              onClick={(e) => showPWA("campañas") || e.preventDefault()}
            >
              Categorías
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/publicos/account=${currentAccount}&view=list&group=default&page=1`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/publicos/")) {
                  return true;
                }
              }}
              onClick={(e) => showPWA("publicos") || e.preventDefault()}
            >
              Públicos
            </NavLink>
          </li>
          <li>
            {/* <NavLink to="/creatividad" onClick={() => showPWA("creatividad")}>
              Creatividad
            </NavLink> */}
          </li>
          {currentUser === "83caaa4a-0388-4f4a-a1a4-b23a8396d9c9" && (
            <li>
              <NavLink to="/scan" onClick={showPWA}>
                Escáner
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to={`/presupuestos/account=${currentAccount}&view_budget=1&view=list&group=default&page=1&initial_date=${firstDayOfMonth}&final_date=${lastDayOfMonth}`}
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/presupuestos/")) {
                  return true;
                }
              }}
              onClick={(e) => showPWA("presupuestos") || e.preventDefault()}
            >
              Presupuestos
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reportes"
              activeStyle={{
                color: "#000000",
                borderBottom: "5px solid #000000",
              }}
              isActive={(_, location) => {
                if (location.pathname.includes("/reportes/")) {
                  return true;
                }
              }}
              onClick={(e) => showPWA("reportes") || e.preventDefault()}
            >
              Reportes
            </NavLink>
          </li>
          <li className="itemHelp">
            <Tooltip placement="top" title="Ayuda" color="#002448">
              <button
                className="HelpBtn"
                onClick={(e) => {
                  setShowModal(true);
                  showPWA2("Help");
                }}
              >
                <img src={imgIdea} />
              </button>
            </Tooltip>
          </li>
          {authState === "signedIn" && (
            <li className="itemProfile">
              <Tooltip placement="top" title="Mi perfil" color="#002448">
                <NavLink
                  to="/perfil"
                  onClick={(e) => showPWA2("Profile") || e.preventDefault()}
                >
                  <i className="far fa-user"></i>
                </NavLink>
              </Tooltip>
            </li>
          )}
          <li className="itemConfig">
            <Tooltip
              placement="top"
              title="Configuración de la cuenta "
              color="#002448"
            >
              <NavLink
                to={`/configuracion/etiquetas/account=${currentAccount}&page=1`}
                activeStyle={{
                  color: "#000000",
                  borderBottom: "5px solid #000000",
                }}
                isActive={(_, location) => {
                  if (location.pathname.includes("/configuracion/")) {
                    return true;
                  }
                }}
                onClick={(e) => showPWA2("Configuration") || e.preventDefault()}
              >
                <i className="fas fa-cog"></i>
              </NavLink>
            </Tooltip>
          </li>
          {authState !== "signUp" &&
            authState !== "signedIn" &&
            authState !== "forgotPassword" &&
            authState !== "register" && (
              <li>
                <NavLink to="/login" className="Login" onClick={goToLogin}>
                  Ingresar
                </NavLink>
              </li>
            )}
        </ul>
        <button className="burguerMenu" onClick={() => setToggle(!toggle)}>
          <i className="fas fa-bars"></i>
        </button>
        {toggle && <MenuResponsive />}
        {showModal && (
          <div className="blur-div">
            <div className="content-modal modalHelp">
              <div className="menuModal">
                <NavLink
                  to="#"
                  className={activeTab === "1" ? "activeTab" : ""}
                  onClick={() => {
                    setActiveTab("1"); //comentar en prod
                    analytics.modalAyudaVideotutoriales({
                      ubicacion: "Modal Videotutoriales",
                    });
                  }}
                >
                  Videotutoriales
                </NavLink>
                <NavLink
                  to="#"
                  className={activeTab === "2" ? "activeTab" : ""}
                  onClick={() => {
                    setActiveTab("2"); //comentar en prod
                    analytics.modalAyudaTicket({
                      ubicacion: "Modal Ticket",
                    });
                  }}
                >
                  Ticket
                </NavLink>
              </div>
              <div className="body-modal bodyPadre">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <ContentView />
                  </TabPane>
                  <TabPane tabId="2">
                    <h3 className="TituloIframe">Ticket</h3>
                    <iframe
                      className="IfremForm"
                      width="100%"
                      height="380px"
                      src="https://forms.office.com/Pages/ResponsePage.aspx?id=DtEb83YENUKvlBiTOgAnI98_B9P4AaFCt-Ax58854vpUMFBJWlVCSFBINURORTdLOEJXSTZLMFFLMy4u&embed=true"
                    ></iframe>
                  </TabPane>
                </TabContent>
              </div>
              <div className="footer-Modal modalFooter">
                <div className="buttons-Modal-content">
                  <button onClick={() => setShowModal(false)}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  accounts: state.accounts.list,
  editActivateTemplate: state.templates.editActivate,
  currentAccount: state.accounts.default,
  isIpv4: state.app.isIPV4,
});

const mapDispatchToProps = (dispatch) => ({
  _alertEdit: (edit) => dispatch(alertEdit(edit)),
  setEditVisualizerEdit: (edit) => dispatch(setEditVisualizer(edit)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(NavBar)));
