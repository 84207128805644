import * as Modal from './modal.actions'

export const MODAL_ADD_ID = 'MODAL:ADD:ID'

export const addIdMixDetaill = (id) => (dispatch) => {
  if (id || id === 0) {
    dispatch({
      type: MODAL_ADD_ID,
      id,
    })
  } else {
    dispatch(Modal.showAlertModal('Error!', 'Esta pauta no existe', Modal.MODAL_TYPE_ERROR))
    console.log('No existe el id')
    console.log(id)
  }
}