import React from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { saveSteps } from "redux/actions/nameCamp.actions";
import { saveNameAccount } from "redux/actions/medioCamp.actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { nextStep, saveName, clearSteps } from "redux/actions/userLogout.action";
import { setDefault, setAccountsTotal } from "redux/actions/accounts.actions";
import { v4 } from "uuid";
import * as API from 'helpers/api'
import "./style.css";
import Swal from "sweetalert2";

const NameCampLogOut = (props) => {
    const formik = useFormik({
        initialValues: {
            accountnamesave: ""
        },
        validateOnBlur: true,
        validationSchema: Yup.object({
            accountnamesave: Yup.string().required('Campo requerido')
        }),
        onSubmit: async (values) => {
            const { accountnamesave } = values;
            props._nexStep(0)
            props._saveNameAccount(accountnamesave)
            try {
                const bodyAccount = {
                    id: v4(),
                    name: accountnamesave,
                    phone: '',
                    email: '',
                    adress: '',
                    members: [
                        props.currentUser
                    ],
                    status: true,
                    client: '',
                    credit: 0,
                    priorityRecord: {
                        availableCritical: 5,
                        availableUrgent: 5,
                        countCritical: 0,
                        countUrgent: 0
                    },
                    configPriority: {
                        critical: {
                            maxHours: 2,
                            minHours: 0
                        },
                        normal: {
                            maxHours: 8,
                            minHours: 4
                        },
                        urgent: {
                            maxHours: 4,
                            minHours: 2
                        }
                    },
                }
                await API.createAccount(bodyAccount)
                await props._setAccountsTotal([bodyAccount])
                await props._setDefaultAccount(bodyAccount?.id)
                Swal.fire({
                    icon: 'success',
                    title: '¡Cuenta creada!',
                    text: 'Tu cuenta ha sido creada con éxito',
                })
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error al crear la cuenta',
                })
            }
        }
    })

    return (
        <>
            <div
                className={props.step == 1 ? "blur-div" : ""}
            >
                <div
                    className="form-margin__cat contenedorCrearCampSinReg__cat content-modal__accountLogout"
                >
                    <div className='component'>
                        <h1 className="title">
                            Crea tu primera cuenta
                        </h1>
                        <div className="oneColBody">
                            <FormGroup>
                                <Label
                                    style={{
                                        color: "#05252d",
                                        fontWeight: 500,
                                        marginBottom: "5px",
                                    }}
                                >
                                    Nombre de la cuenta
                                </Label>
                                <Input
                                    type="text"
                                    className="input"
                                    id="btnNameAccount__logout"
                                    autoComplete="off"
                                    value={props.nameAccount}
                                    onChange={(e) => {
                                        formik.setFieldValue('accountnamesave', e.target.value)
                                        props._saveName(e.target.value)
                                    }}
                                    invalid={formik.errors.accountnamesave}
                                    placeholder="P.j el nombre de tu marca, producto, servicio."
                                />
                                <FormFeedback>{formik.errors.accountnamesave}</FormFeedback>
                            </FormGroup>
                        </div>
                        <div className="rigth-btn__cat">
                            <a className="btn-camp-cancel" onClick={() => {
                                props._nexStep(0)
                                props._clearSteps()
                            }} href="#" id="nextName">
                                Cancelar
                            </a>
                            <a className="btn-camp" onClick={() => {
                                formik.handleSubmit()
                            }} href="#" id="nextName">
                                Siguiente
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
    campaignupd: state.campaignupd,
    step: state.userLogout.nextStepCategory,
    currentUser: state.app.current_user,
    nameAccount: state.userLogout.nameAccount
});

const mapDispatchToProps = (dispatch) => ({
    _setAccountsTotal: (accounts) => dispatch(setAccountsTotal(accounts)),
    _clearSteps: () => dispatch(clearSteps()),
    _setDefaultAccount: (account) => dispatch(setDefault(account)),
    _nexStep: (step) => dispatch(nextStep(step)),
    _saveName: (name) => dispatch(saveName(name)),
    _saveNameAccount: (nameA) => dispatch(saveNameAccount(nameA)),
    _saveSteps: (step) => dispatch(saveSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameCampLogOut);
