import React, { useState, useEffect } from "react";
import ReactDataTable, { NumberFormatEdit } from "@easygosanet/react-datatable";
import $ from "jquery";
import { connect } from "react-redux";
import { withUtils } from "utils";

const TableView = (props) => {
  const [pageSelected, setPageSelected] = useState(50);
  const [totalRows, setTotalRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyPermissionsToData = (data, permissions) => {
    const findAccount = props?.accounts?.find(
      (ele) => ele.id === props.currentAccount
    );
    return data.map((ele) => {
      const permitEditBudget = findAccount?.permitEditBudgetOld ?? true;
      if (permitEditBudget === undefined) {
        // permissions
      } else {
        permissions = {
          ...permissions,
          edit: permitEditBudget ? permissions.edit : !ele.budgetOld,
        };
      }
      console.log("Rice is the mo", { permissions, edot: permitEditBudget });

      return {
        ...ele,
        permisos: permissions,
      };
    });
  };

  const filterDataByActiveStatus = (data, filterText) => {
    // const isDeleted = filterText.toLowerCase().includes("eliminado");
    // if (props.lovs) return data;
    // return data.filter(ele => isDeleted ? !ele.active : ele.active);
    return data;
  };

  useEffect(() => {
    const filteredDataResponse = filterDataByActiveStatus(
      props.data,
      props.filterText
    );

    const dataWithPermissions = applyPermissionsToData(
      filteredDataResponse,
      props.permissions
    );
    setFilteredData(dataWithPermissions);
    setTotalRows(calculateTotal(dataWithPermissions, pageSelected));
  }, [props.filterText, props.data, pageSelected, props.accounts]);

  const calculateTotal = (rows, totalesRowPage) => {
    let totalAmount = 0;
    let totalImplemented = 0;
    let totalAvailable = 0;
    const dataRows = rows.slice(0, totalesRowPage);

    dataRows.forEach((ele) => {
      if (new Number(ele.amount) > 0) {
        totalAmount += new Number(ele.amount);
      }
      if (new Number(ele.availableC) > 0) {
        totalImplemented += new Number(ele.availableC);
      }
      if (new Number(ele.available) > 0) {
        totalAvailable += new Number(ele.available);
      }
      if (new Number(ele.product_uom_qty) > 0) {
        totalAmount += new Number(ele.product_uom_qty);
      }
      if (new Number(ele.reserve) > 0) {
        totalImplemented += new Number(ele.reserve);
      }
      if (new Number(ele.budgetAvailable) > 0) {
        totalAvailable += new Number(ele.budgetAvailable);
      }
    });
    let totales;

    if (props.lovs) {
      totales = [
        {
          orderNumber: "Totales",
          order_line_id_v6: "",
          idLovs: "",
          product_uom_qty: totalAmount,
          reserve: totalImplemented,
          budgetAvailable: totalAvailable,
          idsBudget: [],
          idsCampaign: [],
        },
      ];
    } else {
      totales = [
        {
          title: "Totales",
          id: "",
          idLovs: "",
          amount: totalAmount,
          availableC: totalImplemented,
          available: totalAvailable,
          createAt: 0,
          updateAt: 0,
          permisos: {
            delete: false,
            totalAmount: true,
          },
        },
      ];
    }

    return totales;
  };

  const onChangePage = (page) => {
    setPageSelected(page);
  };

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  $(document).ready(function () {
    $(".CampaTabla1").on("scroll", function () {
      $(".CampaTabla2").scrollLeft($(this).scrollLeft());
    });
    $(".CampaTabla2").on("scroll", function () {
      $(".CampaTabla1").scrollLeft($(this).scrollLeft());
    });
  });

  return (
    <>
      <ReactDataTable
        key="CampaTabla122"
        className="CampaTabla1"
        customStyles={customStyles}
        paginationRowsPerPageOptions={[50, 100, 150, 200]}
        paginationPerPage={50}
        columns={props.columns}
        data={filteredData}
        onChangePage={props.onChangePage}
        paginationDefaultPage={props.paginationDefaultPage || 1}
        onChangeRowsPerPage={onChangePage}
        persistTableHead
        pagination
        noHeader
      />
      <ReactDataTable
        key="CampaTabla222"
        className="CampaTabla2"
        columns={props.columns}
        customStyles={customStyles}
        groupedBy={""}
        data={totalRows}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(TableView));
