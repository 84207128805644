import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { saveAttributes } from "redux/actions/profile.actions";
import { save } from "redux/actions/profile.actions";
import { setCurrentUser } from "redux/actions/app.actions";
import * as API from "helpers/api";
import { descifrar, cifrar } from "helpers/encrypt";
import { createAccount_logout } from 'redux/actions/accounts.actions'
import { saveCategory, saveAccount } from 'redux/actions/campana.actions'
import { createBudget_logout } from 'redux/actions/budgets.actions'
import { addBudget } from 'redux/actions/dashboard.actions'
import { createCategory_logout } from 'redux/actions/categories.actions'

import "./assets/css/styles.css";
import { find } from "lodash";

import grupo from "./assets/img/grupo.png";
// import google from "./assets/img/google.png";

import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import FacebookLogin from "react-facebook-login";
import * as APILA from "helpers/apiLA";
import jwtDecode from "jwt-decode";
import { withUtils } from "utils";
import { v4 } from "uuid";
import analytics from "../../helpers/analytics";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const TAG = "LoginForm";

const SignInForm = (props) => {
  const history = useHistory();
  const [state, setState] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: false, password: false });
  useEffect(() => {
    /* global google */
    if (google.accounts && google.accounts.id && google.accounts.id.initialize) {
      console.log('biblioteca cargada');
      // La biblioteca está cargada y la función initialize está disponible
      google.accounts.id.initialize({
        client_id: '72775905960-fm6ljp1uc8r883ji1etpikk8ajpqlf02.apps.googleusercontent.com',
        callback: callback,
        scope: 'openid profile email',
      });
      google.accounts.id.renderButton(
        document.getElementById('signInDiv'),
        { theme: 'outline', sise: 'large' }
      )
    } else {
      // La biblioteca no está disponible, mostrar un error o volver a intentar más tarde
      console.error('No se pudo cargar la biblioteca de Google Sign-In.');
    }

  }, []);

  const callback = (response) => {
    console.log('response', response);
    let usuario = jwtDecode(response.credential)
    console.log('usuario', usuario);
    //creo el body del usuario de google
    try {
      let body = {
        id: usuario.email,
        email: usuario.email,
        password: usuario.email,
        identities: "Google",
        attributes: {
          name: usuario.name,
          email: usuario.email,
          "custom:country": "",
          "custom:phone": "",
        },
      };
      analytics.loginGoogle(usuario.email)
      //envio el body al metodo que inicia sesion
      signedInOptions(body);
    } catch (error) {
      console.log('error', error);
      props._showLoader(false)
    }
  }

  function data(response) { }

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const signInWithGoogle = async (e) => {
    e.preventDefault();

    try {
      await Auth.federatedSignIn(/*{ provider: 'Google' }*/);
    } catch (err) {
      console.log(err);
    }
  };

  const signInWithFacebook = async (e) => {
    e.preventDefault();

    try {
      await Auth.federatedSignIn(/*{ provider: 'Facebook' }*/);
    } catch (err) {
      console.log(err);
    }
  };

  const areValidInputs = () => {
    const mErrors = {
      email: state.email === "",
      password: state.password === "",
    };

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));

    return !_.some(mErrors, (e) => e);
  };

  const signIn = async (e) => {
    e.preventDefault();

    if (!areValidInputs()) return;
    history.push("/campañas");
    props._showLoader(true);

    let error = "";

    try {
      props.utils.log(TAG, "username", state.email);
      props.utils.log(TAG, "password", state.password);
      /* localStorage.setItem("passAWS", state.password); */
      let response = await Auth.signIn(state.email, state.password);
      let passwordSave = await props._cifrar(state.password, state.email);
      let body = {
        id: state.email,
        password: passwordSave,
      };
      let userSave = await API.insertUsersLets(body);
      console.log("Usuario guardado", userSave);
      console.log("Response response ", response);
      localStorage.setItem("tempAWSUser", "");
      localStorage.setItem("account", "");
      props.onStateChange("signedIn", {});

      // props.history.push('/dashboard')
    } catch (err) {
      console.log("Error", err);
      let { code } = err;
      props.utils.log(TAG, "error signing in", err);

      switch (code) {
        case "UserNotConfirmedException":
          await Auth.resendSignUp(state.email);
          props.onStateChange("confirmSignUp", { username: state.email, password: state.password });
          break;
        case "NotAuthorizedException":
          error = "La contraseña que ingreso es incorrecta";
          break;
        case "UserNotFoundException":
          error = "El usuario que ingreso no existe";
          break;
        default:
          error = "Ocurrió un error inesperado";
      }
    }

    props._showLoader(false);

    if (error !== "") {
      props._showAlertModal("Error!", error, Modal.MODAL_TYPE_ERROR);
    }
  };

  const goToPasswordRecovery = (e) => {
    e.preventDefault();
    props.onStateChange("forgotPassword", {});
  };

  const goToRegister = (e) => {
    e.preventDefault();
    props.onStateChange("register", {});
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      signIn(e);
    }
  };

  const goToTermAndCondition = (e) => {
    e.preventDefault()
    props.onStateChange("termsAndCondition", {})
  }

  const responseFacebook = (response) => {

    try {
      if (response.name && response.email) {
        let body = {
          id: response.id,
          email: response.email,
          password: "",
          identities: "Facebook",
          attributes: {
            name: response.name,
            email: response.email,
            "custom:country": "",
            "custom:phone": "",
            "custom:origin": "false",
            "custom:facebook": "true",
            "custom:google": "false",
          },
        };
        analytics.loginFacebook(response.email)
        signedInOptions(body);
      } else {
        Swal.fire({
          title: "Ups!",
          text: "Necesitamos el email y tu nombre.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }

    } catch (error) {
      props._showLoader(false)
    }
  };

  const signedInOptions = async (response) => {
    try {
      props._showLoader(true);
      console.log('response', response);
      //obtiene todas las cuentas para validar que no exista antes
      let usersResponse = await APILA.getAllAccountsAWS();
      let userAws = usersResponse.data.clients;
      let contrasena = "fee76e2c-7457-43f4-a557-c4aa929c386f" + response.email;
      let passwordSave = await props._cifrar(contrasena, response.email);
      let usuarios = [];
      let attributes = [];
      let atributos = {};
      userAws.forEach((element) => {
        let nameAttr = find(element.Attributes, { Name: "name" });
        let emailAttr = find(element.Attributes, { Name: "email" });

        let body = {
          id: element.Username,
          name: nameAttr ? nameAttr.Value : "",
          email: emailAttr ? emailAttr.Value : "",
          attributes: element.Attributes,
        };

        usuarios.push(body);
      });

      const usuarioEnc = usuarios.find((usuario) => usuario.email === response.email);
      let username = usuarioEnc ? usuarioEnc.id : "";

      // Obtengo los atributos del usuario de haber iniciado sesion con otra plataforma
      const userLets = usuarioEnc?.attributes.find((attr) => attr.Name === "custom:origin");
      const userFacebook = usuarioEnc?.attributes.find((attr) => attr.Name === "custom:facebook");
      const userGoogle = usuarioEnc?.attributes.find((attr) => attr.Name === "custom:google");

      if (usuarioEnc && response.identities === "Facebook") {
        //existe el usuario y se ingresara con facebook
        atributos = {
          // "custom:origin": userLets.length !== 0 ? userLets[0].Value : "true",
          "custom:origin": userLets ? userLets : "true",
          "custom:google": userGoogle ? userGoogle : 'false',
          "custom:facebook": "true",
        };
        console.log("Datos", username, attributes);
        let usuarioBack = await API.getUserLetsById({ id: response.email });
        let passwordDes = await props._descrifrar(
          usuarioBack.data.userLets[0].password,
          response.email
        );
        let inicio = await Auth.signIn(response.email, passwordDes);
        props._saveAttributes(username, atributos);
        props.onStateChange("signedIn", {});
        localStorage.setItem("tempAWSUser", "");
        localStorage.setItem("account", "");
        props._showLoader(false);
      } else if (usuarioEnc && response.identities === "Google") {
        //existe el usuario y se ingresara con google
        atributos = {
          "custom:origin": userLets ? userLets : "true",
          "custom:google": "true",
          "custom:facebook": userFacebook ? userFacebook : "false"
        };
        console.log("Datos", username, attributes);
        let usuarioBack = await API.getUserLetsById({ id: response.email });
        let passwordDes = await props._descrifrar(
          usuarioBack.data.userLets[0].password,
          response.email
        );
        let inicio = await Auth.signIn(response.email, passwordDes);
        props._saveAttributes(username, atributos);
        props.onStateChange("signedIn", {});
        localStorage.setItem("tempAWSUser", "");
        localStorage.setItem("account", "");
        props._showLoader(false);
      } else if (!usuarioEnc) {
        //no existe el usuario, hay que crearlo
        let newUser = {
          username: response.email,
          password: "fee76e2c-7457-43f4-a557-c4aa929c386f" + response.email,
          attributes: {
            name: response.attributes.name,
            email: response.email,
            "custom:facebook":
              response.identities === "Facebook" ? "true" : "false",
            "custom:google": response.identities === "Google" ? "true" : "false",
            "custom:origin": "false",
          },
        };
        let body = {
          id: response.email,
          password: passwordSave,
        };
        //tabla de contrasenas
        let userSave = await API.insertUsersLets(body);
        //creacion de usuario
        let createUser = await Auth.signUp(newUser);
        //confirmacion de usuarios
        let userConfirm = await API.confirmUser({ email: response.email });
        console.log('userConfirm', createUser);
        let accountId = v4()

        props._createAccount_logout(accountId, "General", createUser.userSub, 0, null, null, null
        ).then(async result => {

          props._saveAccount(accountId)

          let budgetId = v4()

          props._createBudget_logout(budgetId, "general", accountId, props.campaign.budget, null, null).then(result => {
            props._addBudget(budgetId)
          }).then()

          let categoryId = v4()
          props._createCategory_logout(categoryId, "General", accountId, null, null).then(result => {
            props._saveCategory(categoryId)

          })
          APILA.getRoleNameById({ id: 'Administrador' }).then((permisos) => {
            let body = {
              id: v4(),
              account: accountId,
              user: createUser.userSub,
              role: permisos.data.rol[0].name,
              idRol: "Administrador",
            };
            APILA.insertPermisos(body).then(async () => {
              //inicio de sesion
              let inicio = await Auth.signIn(response.email, contrasena);
              props.onStateChange("signedIn", {});
              localStorage.setItem("tempAWSUser", "");
              localStorage.setItem("account", "");
            })
          })
        })

      }
      props._showLoader(false);

    } catch (error) {
      props._showLoader(false);

    }

  };

  const responseGoogle = (response) => {
    try {
      let body = {
        id: response.profileObj.googleId,
        email: response.profileObj.email,
        password: response.profileObj.googleId,
        identities: "Google",
        attributes: {
          name: response.profileObj.name,
          email: response.profileObj.email,
          "custom:country": "",
          "custom:phone": "",
        },
      };
      analytics.loginGoogle(response.profileObj.email)
      signedInOptions(body);
    } catch (error) {
      props._showLoader(false)
    }
  };
  return (
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">¡Que alegre verte de nuevo!</h1>

          <FormGroup className="w-100 d-flex flex-column align-items-center">
            <Label className="textos">Correo electr&oacute;nico</Label>
            <Input
              type="text"
              name="email"
              value={state.email}
              invalid={errors.email}
              onChange={handleInputChange}
              placeholder="Ingresa tu correo electr&oacute;nico"
              className="formInput"
            />
            <FormFeedback>Ingresa un correo v&aacute;lido</FormFeedback>
          </FormGroup>

          <FormGroup className="w-100 d-flex flex-column align-items-center">
            <Label className="textos">Contrase&ntilde;a</Label>
            <Input
              type="password"
              name="password"
              value={state.password}
              invalid={errors.password}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
              placeholder="Ingresa tu contrase&ntilde;a"
              className="formInput"
            />
            <FormFeedback>Ingresa una contrase&ntilde;a</FormFeedback>
          </FormGroup>

          <div className="passForget">
            <a onClick={goToPasswordRecovery} href="#">
              Olvide mi contrase&ntilde;a
            </a>
          </div>

          <a onClick={signIn} className="btn-plus-form" href="#">
            INGRESAR
          </a>

          <h5 className="subTitle-form">Tambi&eacute;n puedes</h5>

          <div className="redes">
            <FacebookLogin
              cssClass={"facebook"}
              appId="1568057893655710"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              textButton={"Iniciar sesión con Facebook"}
              icon={"fa-facebook"}
            />
            <div id="signInDiv">

            </div>
          </div>

          <div className="d-flex w-100 text-center link formatTextRegister">
            <a onClick={goToRegister} className="w-100" href="#">
              No tienes una cuenta?
            </a>
          </div>
          <div className="d-flex w-100 text-center link my-1">
            <a onClick={goToTermAndCondition} className="w-100" href="#">
              Términos y condiciones
            </a>
          </div>
        </div>

        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  _cifrar: (texto, password) => dispatch(cifrar(texto, password)),
  _descrifrar: (texto, password) => dispatch(descifrar(texto, password)),
  _saveAttributes: (username, attributes) =>
    dispatch(saveAttributes(username, attributes)),
  _save: (username, attributes) => dispatch(save(username, attributes)),
  _createAccount_logout: (id, name, username, credit, edit, createAt, members) => dispatch(
    createAccount_logout(id, name, username, credit, edit, createAt, members)),
  _saveAccount: (account) => dispatch(saveAccount(account)),
  _createBudget_logout: (id, title, account, amount, edit, createAt) => dispatch(
    createBudget_logout(id, title, account, amount, edit, createAt)),
  _addBudget: (id) => dispatch(addBudget(id)),
  _createCategory_logout: (id, name, account, edit, createAt) => dispatch(
    createCategory_logout(id, name, account, edit, createAt)),
  _saveCategory: (category) => dispatch(saveCategory(category)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(SignInForm)));
