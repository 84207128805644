import React from 'react'
import Select, { components } from 'react-select'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <i className='fas fa-chevron-down' />
  </components.DropdownIndicator>
)

const MySelect = props => (
  <Select
    classNamePrefix='account-selector'
    components={{
      IndicatorSeparator: null,
      DropdownIndicator: DropdownIndicator,
    }}
    {...props}
  />
)

export default MySelect
