import * as APILA from 'helpers/apiLA'
import * as Logger from 'utils/logger'
import { v4 } from 'uuid'
import {
  saveSocial,
  saveMedium,
  saveProduct,
  saveIdmedium,
  saveCountry,
  saveCopies,
  saveObservations,
  saveUser,
  saveCorreo,
  saveNameCorreo,
  saveNameA

} from './campana.actions'
import { showLoader } from './loader.actions'

const TAG = 'Campaign:Actions'

export const LIST_MEDIUM = 'MEDIUMBANNER:ADD'
export const LIST2_MEDIUM = 'MEDIUMBANNER2:ADD'
export const CAMPAIGNS_CLEAR = 'MEDIUMBANNER:CLEAR'
export const CAMPAIGNS2_CLEAR = 'MEDIUMBANNER2:CLEAR'
export const COUNTRIES_CLEAR = 'COUNTRY:CLEAR'
export const ADD_COUNTRIES = 'COUNTRY:ADD'
export const LIST_BUDGET_SPENT = 'BUDGETSPENT:ADD'



export const saveMediumm = (medium) => (dispatch) => {
  dispatch(saveMedium(medium))
}

export const saveIdmediumm = (id_medium) => (dispatch) => {
  dispatch(saveIdmedium(id_medium))
}

export const saveproduct = (product) => (dispatch) => {
  dispatch(saveProduct(product))
}

export const saveSocials = (red) => (dispatch) => {
  dispatch(saveSocial(red))
}

export const saveAWSUser = (user) => (dispatch) => {
  dispatch(saveUser(user))
}

export const saveEmail= (user) => (dispatch) => {
  dispatch(saveCorreo(user))
}

export const saveEmailName= (user) => (dispatch) => {
  dispatch(saveNameCorreo(user))
}

export const saveCountries = (country) => (dispatch) => {
  dispatch(saveCountry(country))
}

export const saveNameAccount = (nameA) => (dispatch) => {
  dispatch(saveNameA(nameA))
}

export const saveObservation = (obs) => (dispatch) => {
  console.log("mediocamp actions obs ",obs)
  dispatch(saveObservations(obs))
}

export const saveCopy = (copy) => (dispatch) => {
  console.log("mediocamp actions copy ",copy)
  dispatch(saveCopies(copy))
}

export const getMediumBanner = (body,account,user,idmod,mod) => async (dispatch) => {
  Logger.log('medioCamp', 'getMediumBanner', body)

  console.log("region",body.id_region)
  console.log("bandera",body.bandera)


  if (body.bandera != undefined && body.bandera != '' && body.bandera == 1) {
    console.log("region","if")
    dispatch({ type: CAMPAIGNS_CLEAR })
    dispatch({ type: CAMPAIGNS2_CLEAR })
    
  } else {
    console.log("region","else")
    console.log("entro a cleanmedium");
    dispatch(cleanMediumtype());
    // dispatch({ type: CAMPAIGNS2_CLEAR })
    
  }

  dispatch(showLoader(true))

  try {
    const response = await APILA.getMediumBanner(body)
     await insertLog(account,"getMediumBanner",body,response.data,"user","Trae el medio de una campaña",idmod,mod)
    Logger.log('algo entro en service api *:*')
    Logger.log(response)

    if (response !== 777) {
      response.data.data.forEach((medium) => {
        if (body.bandera != undefined && body.bandera != '' && body.bandera == 1) {
          dispatch(addmedium(medium))
        } else {
          dispatch(addmediumtype(medium))
        }
      })
    }
  } catch (err) {
    Logger.log(TAG, 'error serviceapi *:*', err)
  }

  dispatch(showLoader(false))
}

export const getBudgetByidmix =  (body,account,user,idmod,mod)  => async (dispatch) => {
  Logger.log('medioCamp.actions getBudgetByidmix body' , body)

  dispatch({ type: CAMPAIGNS_CLEAR })
  dispatch({ type: CAMPAIGNS2_CLEAR })

  //dispatch(showLoader(true))

  try {
    const response = await APILA.getBudgetByidmix(body)
    // await insertLog(account,"getBudgetByidmix",body,response.data,user,"Trae presupuestos gastados de las campañas",idmod,mod)
    //Logger.log('getContryes','algo entro en service api *:*')
    Logger.log("getBudgetByidmix response ", response)
    

    //Logger.log('getContryes',response)


    if (response.data.status == 200) {
      // response.data.pautas.forEach((country) => {
      //   dispatch(getbudget(country))
      // })
     
    }
  } catch (err) {
    Logger.log('getCountries', 'error serviceapi *:*', err)
  }

  dispatch(showLoader(false))
}
const addmedium = (mediumbanners) => ({
  type: LIST_MEDIUM,
  mediumbanners,
})

const addmediumtype = (mediumbannerstype) => ({
  type: LIST2_MEDIUM,
  mediumbannerstype,
})

const getbudget = (budgetspent) => ({
  type: LIST_BUDGET_SPENT,
  budgetspent,
})

export const getCountries = (account,user,idmod,mod) => async (dispatch) => {
  dispatch(showLoader(true))
  Logger.log('country', 'getCountries')

  dispatch({ type: CAMPAIGNS_CLEAR })
  dispatch({ type: CAMPAIGNS2_CLEAR })
  dispatch(cleanCountry());
  //dispatch(showLoader(true))

  try {
    const response = await APILA.getContryes()
    await insertLog(account,"getCountries","",response.data,user,"Trae la lista de los paises /getRegionList",idmod,mod)
    
    //Logger.log('getContryes','algo entro en service api *:*')
    Logger.log(response)
    //Logger.log('getContryes',response)


    if (response.data.status == 200) {
      response.data.pautas.forEach((country) => {
        dispatch(addCountry(country))
      })
     
    }
  } catch (err) {
    Logger.log('getCountries', 'error serviceapi *:*', err)
  }

  dispatch(showLoader(false))
}

async function  insertLog (account,action,request,response,user,obs,idmod,mod){
  let idlog = v4()
  let now = Date.now()
  let logRequest={
    "id": idlog,
    "source":"Lets-Advertise",
    "timestamp":now,
    "action":action,
    "user": user,
    "role":account,
    "idmodulo":idmod,
    "modulo": mod,
    "request":request,
    "response": response,
    "observation": obs 
    
  }
  const responseLog = await APILA.insertLogLine(logRequest)

}
const addCountry = (countries) => ({
  type: ADD_COUNTRIES,
  countries,
})

const cleanCountry = () => (dispatch) => {
  dispatch({
    type: COUNTRIES_CLEAR,
  })
}
const cleanMediumtype = (dispatch) => ({
  type: CAMPAIGNS2_CLEAR,  
})
