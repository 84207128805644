import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import _, { find } from "lodash";
import { showLoader } from "./loader.actions";
import Swal from "sweetalert2";
import { v4 } from "uuid";

const fechas = ["createAt", "fechaIni", "updateAt", "lastModified"];

const clientsFormat = async () => {
  const getClients = _.get(
    await APILA.getAllAccountsAWS(),
    "data.clients",
    "[]"
  );

  const clients = getClients.map((client_response) => {
    const nameAttr = find(client_response.Attributes, { Name: "name" });
    const emailAttr = find(client_response.Attributes, { Name: "email" });

    const nameValue = nameAttr ? nameAttr.Value : "";
    const emailValue = emailAttr ? emailAttr.Value : "";

    const client = {
      id: client_response.Username,
      name: nameValue,
      email: emailValue,
      format: nameValue ? `${nameValue} (${emailValue})` : emailValue,
    };

    return client;
  });

  return clients;
};

const getObjetsEmail = async (account) => {
  const objEmail = _.get(
    await APILA.getObjEmailsByAccount({ account }),
    "data.objEmail",
    "[]"
  );
  let filterObjEmail = objEmail.filter((ele) => !ele?.default);
  return filterObjEmail;
};

const getValidacionesBatch = async (ids) => {
  const objEmail = _.get(
    await APILA.getBatchValidations({ ids }),
    "data.validations",
    "[]"
  );
  return objEmail;
};

const getBatchConfigEmailsIds = async (ids) => {
  const configEmai = _.get(
    await APILA.getBatchConfigEmails({ ids }),
    "data.configEmai",
    "[]"
  );
  return configEmai;
};
const getBatchConfigEmailsLets = async (ids) => {
  const configLetsEmail = _.get(
    await APILA.getBatchConfigEmailsLets({ ids }),
    "data.configLetsEmail",
    "[]"
  );
  return configLetsEmail;
};
const getPermits = async (account) => {
  const permisos = _.get(
    await APILA.getAllPermitsByAccount({ account }),
    "data.permisos",
    "[]"
  );
  return permisos;
};

// Función para obtener usuarios por roles desde letsEmail
function getUsersByRoles(roles, letsEmail) {
  const roleUsers = [];
  letsEmail?.userByRol.forEach((userData) => {
    const foundRole = roles
      .filter((rol) => rol.idRol === userData.id)
      .map((ele) => ({ ...ele, id: ele.user }));
    if (foundRole.length > 0) {
      roleUsers.push(...foundRole);
    }
  });
  return roleUsers;
}

// Función para reemplazar claves en el mensaje HTML
function replaceKeysInMessage(
  mensaje,
  keysValue,
  currentUser,
  accountFind,
  data
) {
  keysValue.forEach((key) => {
    if (mensaje.includes(key)) {
      let valor = key.replace("${", "").replace("}", "");
      // Realiza diferentes acciones según el valor de la clave
      switch (valor) {
        case "userName":
          mensaje = mensaje.replace(key, currentUser?.email);
          break;
        case "accountName":
          mensaje = mensaje.replace(key, accountFind.name);
          break;
        case "createAt":
          mensaje = mensaje.replace(
            key,
            data.createAt
              ? new Date(data.createAt).toLocaleString()
              : new Date().toLocaleString()
          );
          break;
        case "updateAt":
          mensaje = mensaje.replace(
            key,
            data.updateAt
              ? new Date(data.updateAt).toLocaleString()
              : new Date().toLocaleString()
          );
          break;
        case "lastModified":
          mensaje = mensaje.replace(key, new Date().toLocaleString());
          break;
        case "active":
          mensaje = mensaje.replace(key, data.active ? "Activo" : "Eliminado");
          break;
        default:
          mensaje = mensaje.replace(key, data[valor]);
          break;
      }
    }
  });
  return mensaje;
}

// Función para manejar restricciones en letsEmail
function handleRestrictions(clients, letsEmail, emailConfig, roles) {
  let users = [];
  if (letsEmail.restriction === "Todos los usuarios") {
    const clientsFormat = clients.map((client) => ({
      ["value"]: client.email,
    }));
    users = emailConfig.recipients.concat(clientsFormat);
  } else if (letsEmail.restriction === "Usuarios con acceso") {
    const roleUsers = getUsersByRoles(roles, letsEmail);
    const clientsWithAccess = _.intersectionBy(clients, roleUsers, "id");
    users = emailConfig.recipients.concat(
      clientsWithAccess.map((item) => ({
        value: item.email,
        label: item.email,
      }))
    );
    // console.log('Validando como viene los correos', { roleUsers, clientsWithAccess, clients, users })
  } else {
    users = emailConfig.recipients;
  }
  return users;
  // No se realiza ninguna acción en caso de restricción "Ningun usuario"
}

// Función para evaluar validaciones en validationsLets
function evaluateValidations(validationsLets, data, fechas) {
  return validationsLets.validations.every((ele) => {
    let campoInicial = ele.campo;
    let campInit, campContra;
    if (fechas.includes(campoInicial)) {
      campInit = new Date(data[campoInicial]).toLocaleDateString();
      campContra = ele.contraValidacion.replace("-", "/");
      campContra = ele.contraValidacion.replace("-", "/");
      campContra = new Date(campContra).toLocaleDateString();
    } else {
      campContra = ele.contraValidacion;
      campInit = data[campoInicial];
    }

    switch (ele.operador) {
      case "igual a":
        return campInit == campContra;
      case "diferente":
        return campInit != campContra;
      case "mayor que":
        return campInit > campContra;
      case "menor que":
        return campInit < campContra;
      case "Dentro":
        return campInit.includes(campContra);
      default:
        return false;
    }
  });
}

export const newEmailValidation =
  (idAccount, modulo, data, usuario, accounts, action) => async (dispatch) => {
    try {
      let columnDefault = ["userName", "accountName"];
      let keysData = Object.keys(data);

      const clients = await clientsFormat();
      const currentUser = clients.find((ele) => ele.id === usuario);
      const objEmail = await getObjetsEmail(idAccount);
      const roles = await getPermits(idAccount);

      const { idValidations, idConfigEmail, idConfigLets } = objEmail.reduce(
        (acc, ele) => {
          acc.idValidations.push(ele.idValidations);
          acc.idConfigEmail.push(ele.idConfigEmail);
          acc.idConfigLets.push(ele.idConfigLets);
          return acc;
        },
        { idValidations: [], idConfigEmail: [], idConfigLets: [] }
      );

      // Ahora, idsValidations, idsConfigEmails, idsConfigLets contienen las listas correspondientes.
      const [validaciones, configEmail, configLets] = await Promise.all([
        getValidacionesBatch(idValidations),
        getBatchConfigEmailsIds(idConfigEmail),
        getBatchConfigEmailsLets(idConfigLets),
      ]);
      const accountFind = accounts.find((ele) => ele.id === idAccount);

      const configModule = configLets.filter(
        (ele) => ele.module === modulo && ele.action === action
      );

      keysData = keysData.concat(columnDefault);
      let keysValue = keysData.map((ele) => "${" + ele + "}");
      // Itera sobre cada elemento en objEmail
      objEmail.forEach((ele) => {
        // Busca la configuración correspondiente en configModule usando el idConfigLets del elemento actual
        const letsEmail = configModule.find(
          (lets) => lets.id === ele.idConfigLets
        );

        // Busca la configuración de email correspondiente en configEmail usando el idConfigEmail del elemento actual
        const emailConfig = configEmail.find(
          (email) => email.id === ele.idConfigEmail
        );

        // Busca las validaciones correspondientes en el array validaciones usando el idValidations del elemento actual
        const validationsLets = validaciones.find(
          (val) => val.id === ele.idValidations
        );

        // Verifica si letsEmail es válido
        if (letsEmail) {
          // Aplica restricciones y actualiza destinatarios en emailConfig
          emailConfig.recipients = handleRestrictions(
            clients,
            letsEmail,
            emailConfig,
            roles
          );

          // Obtiene el contenido HTML del cuerpo del mensaje del objeto emailConfig
          let mensaje = emailConfig.messajeBody.html;

          // Reemplaza las claves en el mensaje con valores específicos
          mensaje = replaceKeysInMessage(
            mensaje,
            keysValue,
            currentUser,
            accountFind,
            data
          );

          // Evalúa las validaciones en validationsLets
          let validation = evaluateValidations(validationsLets, data, fechas);

          // Si todas las validaciones son verdaderas, muestra información del correo (para propósitos de depuración) y envía el correo
          if (validation) {
            console.log("Politica", {
              recipients: emailConfig.recipients,
              sender: emailConfig.sender,
              messajeBody: {
                html: mensaje,
              },
              affair: emailConfig.affair,
            });

            // Descomentar para enviar correos electrónicos
            APILA.sendEmail({
              politica: {
                recipients: emailConfig.recipients,
                sender: emailConfig.sender,
                messajeBody: {
                  html: mensaje,
                },
                affair: emailConfig.affair,
              },
            });
          }
        }
      });
    } catch (error) {
      console.log("Error al enviar correo", error);
    }
  };

export const updateEmailValidation =
  (configLetsEmail, configEmail, validations, objEmails, account) =>
  async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const bodyBitacora = {
        id: v4(),
        date: new Date(),
        account: account,
        action: "se ha editado una politica",
        component: "configEmails",
        data: objEmails,
      };
      if (objEmails.default) {
        await Promise.all([
          APILA.updateConfigLets({
            userByRol: configLetsEmail.userByRol,
            restriction: configLetsEmail.restriction,
            id: configLetsEmail.id,
          }),
          APILA.insertConfigEmail({
            ...configEmail,
            account,
          }),
          APILA.insertBitacora(bodyBitacora),
        ]);
      } else {
        await Promise.all([
          APILA.insertValidacion(validations),
          APILA.insertConfigLetsEmail(configLetsEmail),
          APILA.insertConfigEmail(configEmail),
          APILA.insertObjEmails(objEmails),
          APILA.insertBitacora(bodyBitacora),
        ]);
      }

      setTimeout(async () => {
        Swal.fire({
          title: "Política editada!",
          text: "Política editada correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        dispatch(showLoader(false));
      }, 500);
    } catch (error) {
      console.log("first error", error);
      Swal.fire({
        title: "Ups!",
        text: "Hubo un error al editar la politica",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          throw error;
        }
      });
    } finally {
      // dispatch(showLoader(false));
    }
  };
