import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import * as Logger from "utils/logger";
import * as Modal from "./modal.actions";
import { showLoader } from "./loader.actions";
import { v4 } from "uuid";
import { upsertCategory } from "./categories.actions";
import { newEmailValidation } from "./email.actions";
import _ from "lodash";

export const ACCOUNTS_CLEAR = "ACCOUNTS:CLEAR";
export const ACCOUNTS_REMOVE = "ACCOUNTS:REMOVE";
export const ACCOUNTS_ADD_TAG = "ACCOUNTS:ADD:TAG";
export const ACCOUNTS_ADD_ONE = "ACCOUNTS:ADD:ONE";
export const ACCOUNTS_ADD_LIST = "ACCOUNTS:ADD:LIST";
export const ACCOUNTS_SET_EDIT = "ACCOUNTS:SET:EDIT";
export const ACCOUNTS_TAGS_CLEAR = "ACCOUNTS:TAGS:CLEAR";
export const ACCOUNTS_CLEAR_EDIT = "ACCOUNTS:CLEAR:EDIT";
export const ACCOUNTS_SET_DEFAULT = "ACCOUNTS:SET:DEFAULT";
export const ACCOUNTS_SET_BLOCKBUDGET = "ACCOUNTS:SET:BLOCKBUDGET";
export const ACCOUNTS_ADD_TOTAL = "ACCOUNTS:ADD:TOTAL";

const TAG = "ACCOUNTS:ACTION";

export const getAccounts =
  (client, useDefault, user, idmod, mod) => async (dispatch) => {
    dispatch(showLoader(true));
    dispatch(clearAccounts());

    try {
      const responseCurrentAccount = _.get(
        await APILA.getAccountByClient({
          client: client,
          url: window.location.pathname,
        }),
        "data",
        null
      );

      if (responseCurrentAccount) {
        const { accounts, currentAccount, permitEditBudgetOld } =
          responseCurrentAccount;

        dispatch(setDefault(currentAccount));
        dispatch(setBlockBudget(permitEditBudgetOld));

        accounts.forEach((account) => {
          dispatch({
            type: ACCOUNTS_ADD_ONE,
            account: {
              ...account,
              owner: client === account.client,
            },
          });
        });
      }
      console.log("responseCurrentAccount bodyRequest", responseCurrentAccount);
    } catch (err) {
      Logger.log(TAG, "getAccounts", err);
      console.log("ERRIRIRI", err);
    } finally {
      dispatch(showLoader(false));
    }
  };

export const getAccountTags =
  (account, user, idmod, mod) => async (dispatch) => {
    dispatch(clearAccountTags());

    try {
      const response = await API.getAccountTags(account);
      insertLog(
        account,
        "getAccountTags",
        { account },
        response.data,
        user,
        "Listado tags, /myTags",
        idmod,
        mod
      );

      Logger.log(TAG, "getAccountTags", response);
      const { tags } = response.data;

      tags.forEach((tag) => {
        dispatch(addTag(tag));
      });
    } catch (err) {
      Logger.log(TAG, "getAccountTags", err);
    }

    dispatch(showLoader(false));
  };

export const createAccount_logout =
  (id, name, username, credit, edit, createAt, members) => (dispatch) => {
    dispatch(showLoader(true));
    let now = Date.now();

    let body = {
      id,
      name,
      members,
      status: true,
      updateAt: now,
      client: username,
      credit: credit || 0,
      createAt: edit ? createAt : now,
      step: 2,
      priorityRecord: {
        availableCritical: 5,
        availableUrgent: 5,
        countCritical: 0,
        countUrgent: 0,
      },
      configPriority: {
        critical: {
          maxHours: 2,
          minHours: 0,
        },
        normal: {
          maxHours: 8,
          minHours: 4,
        },
        urgent: {
          maxHours: 4,
          minHours: 2,
        },
      },
    };

    return API.createAccount(body);
  };

export const createAccount =
  (
    id,
    name,
    username,
    phone,
    email,
    address,
    credit,
    members,
    edit,
    eAdvertising,
    accounts
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    let idAccount = id;
    const today = new Date();
    const fechaHoy =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    const body = {
      id,
      name,
      phone,
      email,
      address,
      members,
      status: true,
      client: username,
      credit: edit ? credit : 0,
      eAdvertising,
    };
    console.log("body account ", body);
    console.log("body eAdvertising ", eAdvertising);
    const now = Date.now();
    let catFacher = [];
    try {
      let findAccount = _.get(
        await API.getAccountById({
          id: "730cf81f-99e8-45f4-b2a7-07ca1b27708f",
        }),
        "data.account[0]",
        "[]"
      );
      if (edit) {
        await API.updateAccount(body);
      } else {
        if (findAccount) {
          let { priorityRecord } = findAccount;
          await API.createAccount({
            ...body,
            priorityRecord,
          });
        } else {
          await API.createAccount(body);
        }
      }

      let bodyEmail = body;
      bodyEmail.createAt = Date.now();

      let bodyBitacora = {
        id: v4(),
        date: new Date(),
        account: id,
        action: edit ? "se ha editado una cuenta" : "se ha creado una cuenta",
        component: "accounts",
        data: body,
      };
      await APILA.insertBitacora(bodyBitacora);
      dispatch(
        newEmailValidation(
          body.id,
          "Cuentas",
          bodyEmail,
          username,
          [body],
          edit ? "Edición" : "Creación"
        )
      );

      let title = edit ? "Cuenta Editada" : "Cuenta Creada";
      let message = edit
        ? "La cuenta fue editada exitosamente."
        : "La cuenta fue creada exitosamente.";

      if (!edit) {
        let defaultReponse = await APILA.getDefaultCategories(body);
        console.log("default Response", defaultReponse);
        let defaultCategories = defaultReponse.data.categories;
        let categoriaPadre = defaultCategories.filter((item) => {
          return item.padre == 0;
        });

        for (let i = 0; i < categoriaPadre.length; i++) {
          let idcate = v4();
          let bodycat = {
            id: idcate,
            idAWS: categoriaPadre[i].id,
          };
          catFacher.push(bodycat);
          await dispatch(
            upsertCategory(
              idcate,
              categoriaPadre[i].name,
              idAccount,
              false,
              "97bc9ee5-36a0-455b-974c-fa7832c9c341"
            )
          );
        }

        console.log("catFacher ", catFacher);
        for (let y = 0; y < catFacher.length; y++) {
          let filtered = defaultCategories.filter((cat) => {
            return cat.padre == catFacher[y].idAWS;
          });
          for (let z = 0; z < filtered.length; z++) {
            let idcat = v4();
            let now = Date.now();
            const bodycat = {
              id: idcat,
              name: filtered[z].name,
              account: "prueba",
              padre: catFacher[y].id,
              tipo: "prueba",
              updateAt: now,
              createAt: now,
              active: true,
            };
            await dispatch(
              upsertCategory(
                idcat,
                filtered[z].name,
                idAccount,
                false,
                catFacher[y].id
              )
            );
          }
        }
      }

      let idbudget = v4();
      let bodyBudget = {
        id: idbudget,
        tags: [],
        title: "Presupuesto_" + name + "_" + fechaHoy,
        amount: "0",
        status: 1,
        account: id,
        available: "0",
        createAt: now,
        updateAt: now,
        padre: "",
        nombrePadre: "",
      };
      let bodyPauta = {
        id_client: eAdvertising,
        account: idAccount,
        idBudget: idbudget,
      };

      console.log("bodyPauta ", bodyPauta);

      dispatch(
        Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
          dispatch(getAccounts(username));
          dispatch(setDefault(id));
        })
      );
    } catch (err) {
      Logger.log(TAG, "error createAccount", err);
      let message = edit
        ? "Ocurrió un error al editar la cuenta"
        : "Ocurrió un error al crear la cuenta";
      dispatch(Modal.showAlertModal("Error!", message, Modal.MODAL_TYPE_ERROR));
    }

    dispatch(showLoader(false));
  };

export const upsertAccountTag =
  (id, name, account, edit) => async (dispatch) => {
    const body = {
      id,
      name,
      account,
    };

    try {
      const response = edit
        ? await API.updateAccountTag(body)
        : await API.createAccountTag(body);

      Logger.log(TAG, "upsertAccountTag", response);

      dispatch(getAccountTags(account));
    } catch (err) {
      Logger.log(TAG, "error upsertAccountTag", err);
    }
  };

export const updateAccountMembers = (id, members) => async (dispatch) => {
  dispatch(showLoader(true));

  const body = {
    id,
    members,
  };

  try {
    const response = await API.updateAccount(body);
    Logger.log(TAG, "updateAccount", response);

    dispatch(
      Modal.showAlertModal(
        "Cuenta Compartida",
        "La cuenta fue compartida exitosamente.",
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    );
  } catch (err) {
    Logger.log(TAG, "error updateAccount", err);
    dispatch(
      Modal.showAlertModal(
        "Error!",
        "Ocurrió un error al compartir la cuenta",
        Modal.MODAL_TYPE_ERROR
      )
    );
  }

  dispatch(showLoader(false));
};

export const deleteAccount = (id) => (dispatch) => {
  dispatch(showLoader(true));

  // API.deleteAccount(id)
  //   .then(response => {
  //     Logger.log(TAG, 'deleteAccount', response)
  //     dispatch(Modal.showAlertModal('Cuenta Eliminada', 'La cuenta fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
  //       dispatch(removeAccount(id))
  //     }))
  //   })
  //   .catch(err => {
  //     Logger.log(TAG, 'error deleteAccount', err)
  //     dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al eliminar la cuenta', Modal.MODAL_TYPE_ERROR))
  //   })
  //   .then(() => {
  //     dispatch(showLoader(false))
  //   })
};
async function insertLog(
  account,
  action,
  request,
  response,
  user,
  obs,
  idmod,
  mod
) {
  let idlog = v4();
  let now = Date.now();
  let logRequest = {
    id: idlog,
    source: "Lets-Advertise",
    timestamp: now,
    action: action,
    user: user,
    role: account,
    idmodulo: idmod,
    modulo: mod,
    request: request,
    response: response,
    observation: obs,
  };
  const responseLog = await APILA.insertLogLine(logRequest);
}

export const setAccountsTotal = (accounts) => ({
  type: ACCOUNTS_ADD_TOTAL,
  accounts,
});

export const clearAccounts = () => ({
  type: ACCOUNTS_CLEAR,
});

export const clearAccountTags = () => ({
  type: ACCOUNTS_TAGS_CLEAR,
});

export const setDefault = (account) => ({
  type: ACCOUNTS_SET_DEFAULT,
  account,
});

export const setBlockBudget = (blocBudget) => ({
  type: ACCOUNTS_SET_BLOCKBUDGET,
  blocBudget,
});

export const setEdit = (edit) => ({
  type: ACCOUNTS_SET_EDIT,
  edit,
});

export const clearEdit = () => ({
  type: ACCOUNTS_CLEAR_EDIT,
});

export const addAccount = (account) => ({
  type: ACCOUNTS_ADD_ONE,
  account,
});

const addTag = (tag) => ({
  type: ACCOUNTS_ADD_TAG,
  tag,
});

const removeAccount = (account) => ({
  type: ACCOUNTS_REMOVE,
  account,
});
