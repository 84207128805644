/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import {
    saveSocials,
    getMediumBanner,
    saveproduct,
    saveMediumm,
    saveIdmediumm,
    saveCountries,
} from "redux/actions/medioCamp.actions";
import { getCountries } from "redux/actions/medioCamp.actions";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";
import "./style.scss";

const MedioCamp = (props) => {
    const [country, setCountry] = useState(props?.campaign?.country || "");
    const [medium, setMedium] = useState({
        site: props?.campaign?.medium || "",
        id_medium: props?.campaign?.id_medium || "",
    });
    const [formato, setFormato] = useState(props?.campaign?.id_product || {});
    useEffect(() => {
        props._getCountries();
        if (props.campaignupd.length > 0) {
        }
    }, []);

    const handleCountryChange = (event, value) => {
        if (value) {
            let bod = {
                id_region: value.id_region,
                bandera: 1,
            };
            let countrybod = {
                id_region: value.id_region,
                name: value.name,
            };
            setCountry(countrybod);
            props._getMediumBanner(bod);
        }
    };

    const handleMediumChange = (event, value) => {
        if (value) {
            let bod = {
                id_medium: value.id_medium,
                id_region: country.id_region,
            };
            setMedium({
                site: value.site,
                id_medium: value.id_medium,
            });
            props._getMediumBanner(bod);
            props._saveIdmediumm(value.id_medium);
            props._saveMediumm(value.site);
        }
    };

    const backName = (e) => {
        e.preventDefault();
        props._saveSteps(15);
    };

    const handleMediumTypeChange = (event, value) => {
        if (value) {
            let bod = {
                banner: value.banner,
                id_ad_product: value.id_ad_product,
            };
            setFormato(bod);
            props._saveproduct(bod);
        }
    };

    const saveMetodo = (e) => {
        e.preventDefault();
        const inputMedium = document.getElementById("btnMedioCamp");
        const inputCountry = document.getElementById("btnCountry");

        if (inputMedium.value !== "" && inputCountry.value !== "") {
            props._saveSocials("");
            props._saveCountries(country);
            props._saveSteps(17);
        } else {
            if (!inputMedium.value) {
                inputMedium.style.borderBottom = "1px solid red";
                inputMedium.style.color = "red";
            }
            if (!inputCountry.value) {
                inputCountry.style.borderBottom = "1px solid red";
                inputCountry.style.color = "red";
            }
        }
    };

    if (props.campaign.redSocial === null && props.campaignupd.length > 0) {
        props.campaign.redSocial = props.campaignupd[0].medio;
    }

    if (props.campaignupd.length > 0) {
        var medio = "";
        if (
            props.campaignupd[0].medioo !== null &&
            props.campaignupd[0].medioo !== undefined &&
            props.campaignupd[0].medioo !== ""
        ) {
            medio = props.campaignupd[0].medioo;
        } else {
            medio = props.campaignupd[0].medio;
        }
    }

    return (
        <div className={props.clase}>
            <h1 className="title">
                Elige un país,un medio y un formato en dónde pautar{" "}
            </h1>
            <br></br>
            <div className="oneColBody">
                <FormGroup>
                    <Label
                        style={{
                            color: "#05252d",
                            fontWeight: 500,
                            marginBottom: "5px",
                        }}
                    >
                        Elige un pa&iacute;s donde pautar
                    </Label>
                    <Autocomplete
                        id="btnCountry"
                        value={country}
                        options={props.countries}
                        onChange={handleCountryChange}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="País" variant="outlined" />
                        )}
                    />
                    <FormFeedback>Elige un pais</FormFeedback>
                </FormGroup>
            </div>
            <div className="oneColBody">
                <FormGroup>
                    <Label
                        style={{
                            color: "#05252d",
                            fontWeight: 500,
                            marginBottom: "5px",
                        }}
                    >
                        Elige un medio donde pautar
                    </Label>
                    <Autocomplete
                        id="btnMedioCamp"
                        value={medium}
                        options={props.mediumbanners}
                        onChange={handleMediumChange}
                        getOptionLabel={(option) => option.site}
                        renderInput={(params) => (
                            <TextField {...params} label="Medio" variant="outlined" />
                        )}
                    />
                    <FormFeedback>Ingresa un medio donde pautar</FormFeedback>
                </FormGroup>
            </div>
            <div className="oneColBody">
                <FormGroup>
                    <Label
                        style={{
                            color: "#05252d",
                            fontWeight: 500,
                            marginBottom: "5px",
                        }}
                    >
                        Elige un formato donde pautar
                    </Label>
                    <Autocomplete
                        id="btnMedioCamp"
                        value={formato}
                        options={props.mediumbannerstype}
                        onChange={handleMediumTypeChange}
                        getOptionLabel={(option) => option.banner}
                        renderInput={(params) => (
                            <TextField {...params} label="Formato" variant="outlined" />
                        )}
                    />
                    <FormFeedback>Ingresa un medio donde pautar</FormFeedback>
                </FormGroup>
            </div>
            <br></br>

            <div className="space-btns">
                <a className="btn-camp gray" onClick={backName} href="#">
                    volver
                </a>
                <a className="btn-camp" onClick={saveMetodo} href="#" id="nextMedio">
                    siguiente
                </a>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
    campaignupd: state.campaignupd,
    mediumbanners: state.mediumbanners,
    mediumbannerstype: state.mediumbannerstype,
    countries: state.countryReducer,
});

const mapDispatchToProps = (dispatch) => ({
    _saveSteps: (step) => dispatch(nextStepLogoutUser(step)),
    _saveSocials: (name) => dispatch(saveSocials(name)),
    _saveCountries: (country) => dispatch(saveCountries(country)),

    _saveMediumm: (medium) => dispatch(saveMediumm(medium)),
    _saveproduct: (product) => dispatch(saveproduct(product)),
    _getMediumBanner: (bdy) => dispatch(getMediumBanner(bdy)),
    _saveIdmediumm: (idmedium) => dispatch(saveIdmediumm(idmedium)),
    _getCountries: () => dispatch(getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedioCamp);
