import { useQuill } from "react-quilljs";
import { useState, useEffect } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";

const Editor = (props) => {
  const { quill, quillRef } = useQuill({
    placeholder: "Ingresa tu plantilla...",
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],

        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],

        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["link"],
        [{ color: [] }, { background: [] }],

        ["clean"],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  });
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (quill) {
      quill.on("selection-change", () => {
        let idx = quill?.getSelection()?.index;
        if (idx >= 0) setIndex(idx);
      });
      quill.on("text-change", () => {
        let idx = quill?.getSelection()?.index;
        if (idx >= 0) setIndex(idx);
        console.log('El texto que se guardara', JSON.stringify(quill.getContents()));
        props.change(
          quill.getText(),
          quill.root.innerHTML,
          JSON.stringify(quill.getContents()),
          quill.getSelection()
        );
      });
    }
  }, [quill]);

  useEffect(() => {
    if (props.refresh) {
      console.log('El valor value', JSON.parse(props.value));
      if (quill) {
        quill.setContents(JSON.parse(props.value))
        if (props.setBody) {
          props.setBody((state) => {
            return {
              change: false,
              content: state.content,
              html: state.html,
              texto: state.texto,
            };
          });
        }
      }

    }
  }, [props.value, quill]);

  useEffect(() => {
    if (props.newPosition) {
      quill.insertText(index, props.bodyPosition.value);
      if (props.setBodyOption) {
        props.setBodyOption((state) => {
          return {
            value: "",
            refresh: false,
          };
        });
      }
    }
  }, [props.newPosition]);

  return (
    <form>
      <div ref={quillRef}></div>
    </form>
  );
};

export default Editor;
