import * as pako from "pako";

export const objectPermissions = {
  superAdmin: "Super Administrador",
  campaignModule: "Campañas",
};

export const allPeriodCampaignsDates = {
  fechaFin: "2035/12/31",
  fechaIni: "2000/06/02",
};

export const formatDate = (date) => {
  const formattedDate = date.toLocaleDateString("es-GT");
  const [day, month, year] = formattedDate.split("/");
  return `${year}/${month}/${day}`;
};

export const formatDateToCustomString = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Sumar 1 porque los meses van de 0 a 11
  const year = date.getFullYear().toString();

  return `${day}.${month}.${year}`;
};

export const unzip = (base64str) => {
  const strData = atob(base64str);

  // Convert binary string to character-number array
  const charData = strData.split("").map((x) => {
    return x.charCodeAt(0);
  });

  // Turn number array into byte-array
  const binData = new Uint8Array(charData);

  return JSON.parse(pako.inflate(binData, { to: "string" }));
};

export const getCurrentDateFormatted = () => {
  const date = new Date();
  return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}/${String(date.getDate()).padStart(2, "0")}`;
};

export const getDateMinus7DaysFormatted = () => {
  const date = new Date();
  // Restar 7 días a la fecha actual
  date.setDate(date.getDate() - 7);
  return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}/${String(date.getDate()).padStart(2, "0")}`;
};
