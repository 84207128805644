import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'

import './assets/css/styles.css'

import grupo from './assets/img/grupo.png'
import google from './assets/img/google.png'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'

import { withUtils } from 'utils'

const TAG = 'SignUpForm'

const SignUpForm = props => {
  let name = useRef(null)
  let phone = useRef(null)
  let country = useRef(null)
  let username = useRef(null)
  let password = useRef(null)

  const signInWithGoogle = e => {
    e.preventDefault()
    Auth.federatedSignIn({
      provider: 'Google'
    })
  }

  const signUp = async e => {
    e.preventDefault()
    props._showLoader(true)
    let error = ''

    try {
      props.utils.log(TAG, 'name', name.current.value)
      props.utils.log(TAG, 'phone', phone.current.value)
      props.utils.log(TAG, 'country', country.current.value)
      props.utils.log(TAG, 'username', username.current.value)
      props.utils.log(TAG, 'password', password.current.value)

      let user = await Auth.signUp({
        username: username.current.value,
        password: password.current.value,
        attributes: {
          email: username.current.value,
          // phone_number: phone.current.value,
          // other custom attributes
          // 'custom:country': country.current.value,
        }
      })

      props.utils.log(TAG, 'user', user)
      props.onStateChange('confirmSignUp', {username: username.current.value})
    } catch (err) {
      let { code } = err
      props.utils.log(TAG, 'error signing up', err)
    }

    props._showLoader(false)

    if (error!=='') {
      props._showAlertModal('Error!', error, Modal.MODAL_TYPE_ERROR)
    }
  }

  const goToSignIn = e => {
    e.preventDefault()
    props.onStateChange('signIn', {})
  }

  return(
    <div className="form-login my-3 h-100">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form text-left w-100">Ahora creamos <br /> nuestro perfil</h1>
          <p className="textos w-100 p-0">Nombre completo</p>
          <input ref={name} className="formInput w-100" type="text" placeholder="Ingresa tu nombre completo" />
          <p className="textos w-100 p-0">Correo electr&oacute;nico</p>
          <input ref={username} className="formInput w-100" type="text" placeholder="Ingresa tu correo electr&oacute;nico" />
          <p className="textos w-100 p-0">N&uacute;mero de tel&eacute;fono</p>
          <input ref={phone} className="formInput w-100" type="text" placeholder="Ingresa tu n&uacute;mero de tel&eacute;fono" />
          <p className="textos w-100 p-0">Pa&iacute;s</p>
          <input ref={country} className="formInput w-100" type="text" placeholder="Selecciona el pa&iacute;s en el que te encuentras o dedeas gestionar" />
          <p className="textos w-100 p-0">Contrase&ntilde;a</p>
          <input ref={password} className="formInput w-100" type="password" placeholder="Ingresa tu contrase&ntilde;a" />
          <a onClick={signUp} className="btn-plus-form" href="#">
            CREAR CUENTA
          </a>
          <h5 className="subTitle-form">Tambi&eacute;n puedes</h5>
          <div className="redes w-100 justify-content-between align-items-stretch">
            <a className="facebook" href="#">
              <i className="fab fa-facebook"></i>
              Iniciar sesi&oacute;n con Facebook
            </a>
            <a onClick={e => signInWithGoogle(e)} className="google" href="#">
              <img src={google} alt="G"/>
              Iniciar sesi&oacute;n con Google
            </a>
          </div>
          <div className="d-flex w-100 text-center link my-3">
            <a onClick={goToSignIn} className="w-100" href="#">
              Ya tienes cuenta?
            </a>
          </div>
        </div>
        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(SignUpForm))
