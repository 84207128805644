import React from "react";
import { Select } from "antd";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import PageLoader from "components/page_loader";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  const {
    isLoading,
    handleChangeSelector,
    search,
    handleFilterText,
    handleColumnSortChange,
    setEditData,
    setShowModal,
    columns,
    textTooltip,
    exportDataToExcel,
    handleShowColumnChange,
    handleFilterPerColumn,
    handleAddView,
    handleDeleteView,
    views,
    nameView,
    handleShowView,
    create,
    permissions,
  } = props;
  const options = useSelector((state) => state.digitalAssets.options);
  const currentOption = useSelector(
    (state) => state.digitalAssets.currentOption
  );
  const location = useLocation();
  const currentUrl = new URLSearchParams(location.pathname);
  const viewSelectorParams = Number(currentUrl.get("view_selector"));

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="main__cuentas">
        <div className="barraSuperior">
          <Tooltip color="#002448" placement="top" title="Vista">
            <Select
              className="select__vista__"
              defaultValue={1}
              value={viewSelectorParams || currentOption.value}
              onChange={handleChangeSelector}
              options={options}
              styles={{
                textAlign: "center",
                width: "50%",
                borderRadius: "10px",
              }}
            />
          </Tooltip>
          <div style={{ display: "contents" }}>
            <DataTableUtils
              action={
                create && permissions.create
                  ? () => {
                      setEditData({});
                      setShowModal(true);
                    }
                  : null
              }
              actionIcon={
                create &&
                permissions.create && (
                  <Tooltip color="#002448" placement="top" title={textTooltip}>
                    <i className="fas fa-plus" />
                  </Tooltip>
                )
              }
              onChangeFilterText={handleFilterText}
              valueFilter={search || ""}
            >
              <Tooltip
                color="#002448"
                placement="top"
                title="Exportar información"
              >
                {permissions.export && (
                  <Share
                    contacts={[]}
                    data={exportDataToExcel()}
                    columns={columns}
                    isArray={true}
                    exportXLSX={true}
                    exportTXT={true}
                    exportDropbox={true}
                    exportDrive={true}
                    exportCSV={true}
                    printable={true}
                    exportPNG={true}
                    exportLink={true}
                    idImg={"root"}
                    appKey="2u78gno172idwbz"
                  />
                )}
                <div className="ExportBtn"></div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Personalizar columnas"
              >
                <div id="shareBtn">
                  <ShowColumn2
                    columns={columns}
                    vistas={views || []}
                    setVista={(nombre) => {
                      handleAddView(nombre);
                    }} // Para crear una vista
                    deleteView={(name) => {
                      handleDeleteView(name);
                    }}
                    showColumn={handleShowColumnChange}
                    onSortChange={handleColumnSortChange}
                    nameAc={nameView}
                    showView={handleShowView}
                  />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Filtrar y agrupar"
              >
                <div className="Filtro2 filtro3">
                  <FilterColumn2
                    onFilterColumn={handleFilterPerColumn}
                    columns={[]}
                  />
                </div>
              </Tooltip>
            </DataTableUtils>
          </div>
        </div>
        {props.children}
      </div>
    </>
  );
};

export default Layout;
