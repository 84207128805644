import { Tooltip } from "antd";
import React, { useState } from 'react';

export const IdComponent = ({ text }) => {
    const [copiedMessageVisible, setCopiedMessageVisible] = useState(false);

    const handleCopyClipBoard = () => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopiedMessageVisible(true);
                setTimeout(() => {
                    setCopiedMessageVisible(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Error al copiar', error);
            });
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const textStyles = {
        flex: 1,
        marginRight: '10px',
        overflow: 'hidden',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <Tooltip title={!copiedMessageVisible ? "Copiar al portapapeles" : "Copiado con éxito"} placement="top" color="#002448" >
                <span style={textStyles} onClick={() => handleCopyClipBoard()} >{text ? text.substr(0, 4) : ''}</span>
            </Tooltip>
        </div>
    );
};
