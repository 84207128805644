import React, { useEffect, useState } from "react";
import _, { unescape } from "lodash";
import { connect } from "react-redux";
// organization components
import { newEmailValidation } from "redux/actions/email.actions";
import ReactDataTable from "@easygosanet/react-datatable";
import DetailColEdit from "components/DetailCol";
import { saveAs } from "file-saver";
import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";
import analytics from "../../helpers/analytics";
// custom styles
import "./assets/scss/styles.scss";
import DropZone from "libs/dropzone";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { pwaUploadFiles } from "redux/actions/pwa.actions";
import { getClients } from "redux/actions/clients.actions";
import * as API from "helpers/apiLA";
import { Auth } from "aws-amplify";
import { updateAccountMembers } from "redux/actions/accounts.actions";
import {
  getFiles,
  removeFile,
  uploadFiles,
} from "redux/actions/creative.actions";
import { Tooltip } from "antd";
import { withUtils } from "utils";
import * as Logger from "utils/logger";
import { getAllCampaigns } from "redux/actions/allCampaings.actions";
import * as STORAGE from "helpers/storage";
import * as APILA from "helpers/apiLA";
import { v4 } from "uuid";

//oneDrive
import { Login, FileList } from "@microsoft/mgt-react";
import { Providers, LoginType, ProviderState } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import iconGoogleDrive from "./assets/img/google-drive.png";
import ModalOneDrive from "./ModalOneDrive";

import useDrivePicker from "react-google-drive-picker";
import PageLoader from "components/page_loader";
const TAG = "Creative";

const Content = (props) => {
  const CLIENT_ID =
    "72775905960-fm6ljp1uc8r883ji1etpikk8ajpqlf02.apps.googleusercontent.com";
  const API_KEY = "AIzaSyBmi-dOJIYuCcDI4Ul_w59g8KIRrHoBgBA";
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const SCOPES = "https://www.googleapis.com/auth/drive.readonly";
  const [openPicker, data, authResponse] = useDrivePicker();
  const [selectedFile, setSelectedFile] = useState([]);
  const orderDefault = ["name", "url", "lastModified", "type", "size"];
  const [nameView, setNameView] = useState("");
  const [account, setAccount] = useState(props.currentAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [campaings, setCampaings] = useState(props.allCampaigns);
  const [filters, setFilters] = useState({});
  const [optionsFile, setOptionsFile] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [creativity, setCreativity] = useState([]);
  const [showDrop, setShowDrop] = useState(false);
  const [nameGrouped, setNameGrouped] = useState("");
  const [colAct, setColAct] = useState([]);
  const [views, setViews] = useState([]);
  const [isLoginOneDrive, setIsLoginOneDrive] = useState(false);
  const [oneDriveFiles, setOneDriveFiles] = useState([]);
  const [filterInfo, setFilterInfo] = useState([]);
  const [msalInstance, setMsalInstance] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [showModalOneDrive, setShowModalOneDrive] = useState(false);
  let gapiInited = false;
  let gisInited = false;
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });
  const [columns, setColumns] = useState([
    {
      omit: false,
      name: "Nombre de Archivo",
      selector: "name",
      id: "name",
      tag: "name",
      sortable: true,
      show: true,
      center: true,
      visible: false,
      type: "text",
      cell: (row) => (
        <DetailColEdit
          text={row.name}
          id={"name"}
          activate={true}
          accounts={[]}
          viewBudget={false}
          budgetOld={false}
          deleteData={() => removeFile(row)}
          deleteV={true}
          nameColumn={row}
          onEditValue={editName}
          reActivar={false}
          editable={false}
          type={"text"}
          permissions={{
            approve: true,
            create: true,
            delete: true,
            edit: true,
            export: true,
            manageRoles: true,
            view: true,
          }}
        />
      ),
    },
    {
      omit: false,
      name: "Miniatura",
      selector: "url",
      id: "url",
      tag: "url",
      sortable: false,
      minWidth: "15rem",
      center: true,
      grow: 0,
      cell: (row) => (
        <div
          className=""
          data-fancybox="images"
          href={`https://marketing-suite-files-bucket90024-production.s3.amazonaws.com/public/${row.name}`} //comentar en dev
          // href={`https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/${row.name}`} //comentar en prod
          /* href={`https://marketing-suite-files-bucket90024-production.s3.amazonaws.com/public/${row.name}`} */
          style={{ height: "50px" }}
        >
          <img
            className="img-fluid h-100"
            data-cke-saved-src={`https://marketing-suite-files-bucket90024-production.s3.amazonaws.com/public/${row.name}`} //comentar en dev
            // data-cke-saved-src={`https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/${row.name}`} //comentar en prod
            // src={`https://marketing-suite-files-bucket90024-production.s3.amazonaws.com/public/${row.name}`}//comentar en dev
            src={`https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/${row.name}`} //comentar en prod
          />
        </div>
      ),
    },
    {
      omit: false,
      name: "Última Actualización",
      selector: "lastModified",
      id: "lastModified",
      tag: "lastModified",
      sortable: true,
      minWidth: "15rem",
      center: true,
      cell: (row) => formatDate(row.link[0].lastModified),
    },
    {
      omit: false,
      name: "Formato",
      selector: "type",
      // type:'text',
      id: "type",
      tag: "type",
      sortable: true,
      center: true,
      minWidth: "15rem",
      grow: 0,
      format: (row) => formatType(row),
    },
    {
      omit: false,
      name: "Tamaño",
      selector: "size",
      id: "size",
      tag: "size",
      minWidth: "15rem",
      sortable: true,
      center: true,
      grow: 0,
      format: (row) => formatFileSize(row.link[0].size),
    },
  ]);
  const [uploadFIles, setUploadFIles] = useState([]);
  const [tokenClient, setTokenClient] = useState(null);

  useEffect(() => {
    actualizarViews(true);
    /*global gapi*/
    /*global google*/
    gapiLoaded();
    gisLoaded();
    const provider = new Msal2Provider({
      clientId: "a184da0c-1ac1-41c9-8b41-f1cc7cd2ae0e",
      loginType: LoginType.Popup,
    });
    console.log("aaa", provider);
    Providers.globalProvider = provider;
    console.log(
      "asda",
      Providers.globalProvider &&
        Providers.globalProvider.state === ProviderState.SignedIn
    );
    setIsLoginOneDrive(
      Providers.globalProvider &&
        Providers.globalProvider.state === ProviderState.SignedIn
    );
  }, []);

  function gapiLoaded() {
    /*global gapi*/
    /*global google*/
    gapi.load("client", initializeGapiClient);
  }
  async function initializeGapiClient() {
    /*global gapi*/
    /*global google*/
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;
  }

  const gisLoaded = () => {
    /*global gapi*/
    /*global google*/
    let googleAuth = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: "", // defined later
    });
    gisInited = true;
    setTokenClient(googleAuth);
    // maybeEnableButtons();
  };

  const editName = (value) => {
    console.log("El valor que estoy recibiendo", value);
  };

  const permisos = async () => {
    try {
      setIsLoading(true);
      let userLog = await Auth.currentAuthenticatedUser();
      let permisoAcc = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await API.getRoleNameById({ id: idRol });
      console.log("ACA");
      let permissAud = permisosRes.data.rol[0].permissions;
      console.log("Permisos aud", permissAud);
      let permissFn = permissAud.filter((ele) => ele.name == "Creatividad");

      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
      return permissFn[0];
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
    }
  };

  const dataFormat = (data) => {
    let files = [];
    if (data && data.length > 0) {
      const promises = data.map((ele) => {
        return gapi.client.drive.files
          .get({
            fileId: ele.id,
            alt: "media",
          })
          .then((res) => {
            console.log("res", res);
            const data = res.body;
            const len = data.length;
            const ar = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              ar[i] = data.charCodeAt(i);
            }
            const blob = new Blob([ar], { type: ele.mimeType });
            const file = new File([blob], ele.name, { type: blob.type });
            file.path = ele.name;
            files.push(file);
          });
      });

      Promise.allSettled(promises).then(() => {
        console.log("los files", files);
        // setUploadFIles([...uploadFIles, ...files]);
        handleUpload(files);
      });
    }
  };

  const filePickerDrive = () => {
    /*global gapi*/
    /*global google*/
    let access_token = gapi.auth.getToken().access_token;

    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS",
      showUploadView: false,
      token: access_token,
      showUploadFolders: true,
      supportDrives: true,
      callbackFunction: (data) => {
        dataFormat(data.docs);
      },
      locale: "es",
      multiselect: true,
    });
  };

  const getDriveFiles = async () => {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      filePickerDrive();
    };

    if (gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      tokenClient.requestAccessToken({ prompt: "" });
    }
  };

  useEffect(() => {
    setAccount(props.currentAccount);
    actualizarViews(true);
    permisos();
  }, [props.currentAccount]);

  let options1 = [
    "Nombre de archivo",
    // 'Última actualización',
    // 'Formato',
    // 'Tamaño'
  ];
  options1.sort();
  options1.splice(0, 0, "Por defecto");
  const [options, setOptions] = useState(options1);
  const actualizarViews = async (tipo) => {
    if (Auth.user) {
      // setIsLoading(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await API.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "creative",
      });
      setViews(viewsData.data.views);
      console.log("Data view", viewsData.data.views);
      if (viewsData.data.views.length === 0) {
        setNameView("lista");
      } else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          setNameView(vistaActiva.name);
          if (tipo) {
            let nombres = [];
            vistaActiva.columns.forEach((ele) => {
              nombres.push(ele.id);
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(nombres);
          }
        } else {
          columns.forEach((ele) => {
            handleShowColumnChange(ele.selector, ele.omit);
          });
          handleColumnSortChange(orderDefault);
          setNameView("lista");
        }
      }
      // setIsLoading(false);
    }
  };
  const handleFilterPerColumn2 = (value) => {
    if (value.length > 0) {
      //comentar en prod
      analytics.filtro({
        ubicacion: "Creatividad",
      });
      setFilterInfo([...value]);
      value.forEach((ele) => {
        setFilterText(ele.value);
      });
    } else {
      setFilterInfo([]);
      setFilterText("");
    }
  };
  const getCreativitie = async () => {
    setIsLoading(true);
    APILA.getCreativitieByAccount({ account: account }).then((response) => {
      console.log("responseCreat", response);
      let resCreat = response.data.creativitie;
      let arrayAux = [];
      /* for(let i=0;i<resCreat.length;i++){
        STORAGE.getUrlImage(resCreat[i].name).then(res => {
          console.log('resURL',res)
          arrayAux.push({
            name:resCreat[i].name,
            URL: res.toString(),
            id: resCreat[i].id,
            account: resCreat[i].account,
            link: resCreat[i].link,
          })
          setCreativity(arrayAux)
        })
      } */
      setCreativity(response.data.creativitie);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    console.log("Account", account);
    getCreativitie();
  }, [account, showDrop]);
  const handleUpload = async (files) => {
    props.utils.log(TAG, "handleUpload");
    props.utils.log(TAG, "files", files);
    const mAccount =
      props.currentUser !== "" ? account : props.currentPWAAccount;
    if (mAccount === "") {
      props._showAlertModal(
        "Cuenta Requerida",
        `Elige una cuenta para subir archivos`,
        Modal.MODAL_TYPE_WARNING,
        () => {}
      );
      return;
    }
    console.log("filtes", files, mAccount);
    let imagesArr = [];
    for (const file of files) {
      let options = {
        contentType: file.type,
        level: "public",
        acl: "public-read-write",
        progressCallback(progress) {
          Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`);
        },
      };
      console.log("files", files);
      const uploadedFile = await STORAGE.uploadFile(file.name, file, options);
      const url = await STORAGE.listFiles(uploadedFile.key);
      const newImage = {
        id: v4(),
        name: uploadedFile.key,
        link: url,
        account: mAccount,
      };
      console.log("NEWIMAGE::: ", newImage);
      imagesArr.push(newImage);
      Logger.log(TAG, newImage);
    }
    for (let i = 0; i < imagesArr.length; i++) {
      let response = await APILA.associateFile({
        id: imagesArr[i].id,
        name: imagesArr[i].name,
        link: imagesArr[i].link,
        account: imagesArr[i].account,
      });
      let body = {
        name: imagesArr[i].name,
        lastModified: Date.now(),
        format: formatType(imagesArr[i]),
        size: formatFileSize(imagesArr[i].link[0].size),
      };
      console.log("El body image", body);
      props._newEmailValidation(
        props.currentAccount,
        "Creatividad",
        body,
        props.currentUser,
        props.accounts,
        "Creación"
      );
      let bodyBitacora = {
        id: v4(),
        date: new Date(),
        account: props.currentAccount,
        action: "se ha creado un archivo",
        data: body,
        component: "creative",
      };
      APILA.insertBitacora(bodyBitacora);
      console.log("Imagen res", response);
      console.log("La data del link", {
        id: imagesArr[i].id,
        name: imagesArr[i].name,
        link: imagesArr[i].link,
        account: imagesArr[i].account,
      });
      setShowDrop(false);
    }
    setUploadFIles([]);
    setOptionsFile([]);
    setOneDriveFiles([]);
  };
  const handleFileChange = (files) => {
    props.utils.log(TAG, "handleFileChange");
    props.utils.log(TAG, "files", files);
  };
  const removeFile = (row) => {
    console.log("La data de la row", row);
    props._showAlertModal(
      "Eliminar Archivo?",
      `Esta seguro que desea eliminar el archivo ${row.name}`,
      Modal.MODAL_TYPE_CONFIRMATION,
      () => {
        APILA.deleteCreativitie({ id: row.id })
          .then((res) => {
            let body = {
              name: row.name,
              lastModified: Date.now(),
              format: formatType(row),
              size: formatFileSize(row.link[0].size),
            };
            let bodyBitacora = {
              id: v4(),
              date: new Date(),
              account: props.currentAccount,
              action: "se ha eliminado un archivo",
              data: body,
              component: "creative",
            };
            APILA.insertBitacora(bodyBitacora);
            props._newEmailValidation(
              props.currentAccount,
              "Creatividad",
              body,
              props.currentUser,
              props.accounts,
              "Eliminación"
            );
            console.log("res", res);
            // props._removeFile(row.name, account);
            setCreativity([]);
            getCreativitie();
            //comentar en prod
            analytics.accionesTabla({
              accion: "Eliminar",
              ubicacion: "Creatividad",
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    );
  };
  const formatDate = (datee) => {
    const months = [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ];
    let date = new Date(datee);
    if (props.currentUser !== "") {
      return `${date.getDate()}-${
        months[date.getMonth()]
      }-${date.getFullYear()}`;
    } else {
      if (date instanceof Date) {
        return `${date.getDate()}-${
          months[date.getMonth()]
        }-${date.getFullYear()}`;
      } else {
        const mDate = new Date(date);
        return `${mDate.getDate()}-${
          months[mDate.getMonth()]
        }-${mDate.getFullYear()}`;
      }
    }
  };
  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];
    let filteredColumns = columns.filter(function (col) {
      return col.omit === false;
    });
    filteredColumns.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });
    if (!_.isEmpty(filteredData)) {
      if (nameGrouped !== "") {
        //data agrupada
        const uniqueValues = [
          ...new Set(filteredData.map((item) => item[nameGrouped])),
        ];

        uniqueValues.forEach((value) => {
          dataExcel.push([value], headers);
          const groupedData = filteredData.filter(
            (item) => item[nameGrouped] === value
          );

          groupedData.forEach((data) => {
            console.log("DATAA", data);
            const row = selectors.map((sele) => {
              let valor = "";
              if (sele === "lastModified") {
                valor = formatDate(data.link[0].lastModified);
              } else if (sele === "type") {
                valor = formatType(data);
              } else if (sele === "size") {
                valor = formatFileSize(data.link[0].size);
              } else {
                valor = data[sele];
              }
              return valor;
            });
            dataExcel.push(row);
          });
        });
      } else {
        dataExcel.push(headers);
        filteredData?.forEach((element) => {
          const rowData = [];
          // Iteramos sobre los selectores y agregamos el valor correspondiente a rowData
          selectors.forEach((selector) => {
            let valor = "";

            if (selector === "lastModified") {
              valor = formatDate(element.link[0].lastModified);
            } else if (selector === "type") {
              valor = formatType(element);
            } else if (selector === "size") {
              valor = formatFileSize(element.link[0].size);
            } else {
              valor = element[selector];
            }

            rowData.push(valor);
          });

          // Agregamos la fila actual a la hoja de Excel
          dataExcel.push(rowData);
        });
      }
    }
    return dataExcel;
  };
  // const formatType = type => type.split('/')[1] || ''
  const formatType = (object) => {
    let splitArray = object.name.split(".");
    let format = "";
    if (splitArray.length > 2) {
      format = splitArray[splitArray.length - 1];
    } else {
      format = splitArray[1];
    }
    return format;
  };
  const getPautasName = (name) => {
    // let c = props.files.filter((fileF) => fileF.name === file.path)
    // console.log("getPautasName " , props.allCampaigns)
    // console.log("getPautasName " , name)
    let arrayName = [];
    // const filtered = asArray.filter(([key, value]) => key === '');
    // console.log(filtered);
    // console.log("getPautasName " , props._allCampaign)
    // console.log("campaings " , allCampaigns)
    // console.log("campaings " , getAllCampaigns)
    let pauta;
    console.log("getPautasName name", name);
    console.log("getPautasName props.allCampaigns", props.allCampaigns);
    console.log("getPautasName props", props);
    props.allCampaigns.forEach(function (element, index, array) {
      // console.log("aaa" , element)
      if (element.creativity) {
        element.creativity.forEach(function (obj, ind, arr) {
          if (obj.path == name) {
            console.log(name);
            console.log(element);
            arrayName.push(element.idMixDetaill);
            //  pauta=element.name;
          }
          // if(obj.path==name){
          //   console.log("Encontro match")
          // }
        });
      }
    });
    arrayName.forEach(function (ids, ind, arr) {
      if (!pauta) {
        pauta = ids;
      } else {
        pauta = pauta + "," + ids;
      }
    });
    console.log("array ", arrayName);
    return pauta;
  };
  const formatFileSize = (size, si = true) => {
    const thresh = si ? 1000 : 1024;
    const units = si
      ? ["B", "KB", "MB", "GB", "TB"]
      : ["B", "KiB", "MiB", "GiB", "TiB"];
    var i = Math.floor(Math.log(size) / Math.log(thresh));
    return (size / Math.pow(thresh, i)).toFixed(2) * 1 + " " + units[i];
  };
  let files = [];
  if (props.currentUser !== "") {
    files = _.sortBy(props.files, ["lastModified"]);
  } else {
    files = props.pwaFiles;
  }
  const handleFilterTextChange = (value) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("value", value);
    //comentar en prod
    analytics.busqueda({
      ubicacion: "Creatividad",
      busqueda: filterText,
    });
    setFilterText(value);
  };
  const handleShowColumnChange = (column, show) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("column", column);
    props.utils.log("show", show);
    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    aux[index].omit = show;
    setColumns(aux);
  };
  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      } else {
        handleShowColumnChange(ele.selector, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };
  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    console.log("id", id);
    if (id) actualizarEstado(id.id, false);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    let body = {
      id: idVIew,
      name: name,
      component: "creative",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await API.insertView(body);
    actualizarViews();
    setIsLoading(false);
  };
  const actualizarEstado = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    setIsLoading(false);
  };
  const handleDeleteView = async (name) => {
    setIsLoading(false);
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        setNameView("lista");
        columns.forEach((ele) => {
          handleShowColumnChange(ele.selector, false);
        });
        handleColumnSortChange(orderDefault);
      }
    }
    await API.deleteView({ id: name });
    actualizarViews(false);
    setIsLoading(false);
  };
  const handleColumnSortChange = (positions) => {
    props.utils.log("handleColumnSortChange");
    props.utils.log("positions", positions);
    console.log("Positions", positions);
    let aux = columns.map((col) => col);
    //comentar en prod
    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.id === columnId);
      props.utils.log("Index", index);
      aux[index].position = position;
    });
    aux = _.orderBy(aux, ["position"]);
    props.utils.log("new columns", aux);
    setColumns(aux);
  };
  const handleShareContact = (contact) => {
    props.utils.log("handleShareContact");
    props.utils.log("contact", contact);
    const currentAccountInfo = _.find(props.accounts, { id: account });
    let members = currentAccountInfo.members || [];
    members = members.filter((member) => member !== contact.id);
    props._updateAccountMembers(account, members);
  };
  let filteredData = creativity.filter(
    (item) => item.name.toLowerCase().includes(filterText.toLowerCase())
    // item.format.toLowerCase().includes(filterText.toLowerCase())
  );
  if (!_.isEmpty(filters)) {
    filteredData = filteredData.filter((item) => {
      let result = true;
      for (const filterKey in filters) {
        let response;
        let filter = filters[filterKey];
        let dataValue = item[filterKey];
        props.utils.log("key", filterKey);
        props.utils.log("filter", filter);
        props.utils.log("data value", dataValue);
        switch (filter.type) {
          case "EQUALS":
            response =
              `${filter.value}`.toLowerCase() === `${dataValue}`.toLowerCase();
            break;
          case "INLIST":
            response = filter.value[`${dataValue}`];
            break;
          case "GREATERTHAN":
            response = filter.value === -1 || dataValue > filter.value;
            break;
          case "LESSTHAN":
            response = filter.value === -1 || dataValue < filter.value;
            break;
          case "EQUALTHAN":
            response = filter.value === -1 || filter.value === dataValue;
            break;
          default:
            response = `${dataValue}`
              .toLowerCase()
              .includes(`${filter.value}`.toLowerCase());
            break;
        }
        result = result && response;
      }
      return result;
    });
  }
  let myContacts = [];
  props.clients.map((client) => {
    if (
      client.id !== props.currentUser &&
      client.name !== "" &&
      account !== ""
    ) {
      const currentAccountInfo = _.find(props.accounts, { id: account });
      // props.utils.log('currentAccountInfo', currentAccountInfo)
      if (currentAccountInfo) {
        let members = currentAccountInfo.members || [];
        //props.utils.log('members', members)
        if (!members.includes(client.id)) {
          myContacts = [
            ...myContacts,
            {
              id: client.id,
              name: `${client.name} (${client.email})`,
            },
          ];
        }
      }
    }
  });
  let filterTimeout;
  const changeGrouped = (value) => {
    analytics.agrupar({
      ubicacion: "Creatividad",
    });
    console.log("El valor", value);
    setIsLoading(true);
    switch (value) {
      case "Formato":
        setNameGrouped("key");
        break;
      case "Nombre de archivo":
        setNameGrouped("name");
        let optAc = ["Nombre de archivo", "Por defecto"];
        setOptions(optAc);
        break;
      case "Tamaño":
        setNameGrouped("size");
        break;
      case "Última actualización":
        setNameGrouped("lastModified");
        break;
      default:
        setNameGrouped("");
        let optDefault = ["Por defecto", "Nombre de archivo"];
        setOptions(optDefault);
        break;
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  const cambioDeAgrupacion = (datos, titulo, idx) => {
    let datosAgrupados = [];
    filteredData.forEach((ele) => {
      if (nameGrouped == "name") {
        if (ele[nameGrouped] == datos) {
          datosAgrupados.push(ele);
        }
      }
      if (nameGrouped == "key") {
        if (formatType(ele) == titulo) {
          datosAgrupados.push(ele);
        }
      }
      if (nameGrouped == "size") {
        if (formatFileSize(ele.link[0][nameGrouped]) == titulo) {
          datosAgrupados.push(ele);
        }
      }
      if (nameGrouped == "lastModified") {
        if (formatDate(ele.link[0][nameGrouped]) == titulo) {
          datosAgrupados.push(ele);
        }
      }
    });
    const customStyles = {
      headCells: {
        style: {
          textAlign: "center",
          "&:nth-of-type(2n)": {
            backgroundColor: "#eff0f2",
            borderRadius: "12px 12xp 0 0",
            minWidth: "8rem !important",
            maxWidth: "8rem !important",
          },
        },
      },
      cells: {
        style: {
          "&:nth-of-type(2n)": {
            backgroundColor: "#eff0f2",
            minWidth: "8rem !important",
            maxWidth: "8rem !important",
          },
        },
      },
    };
    return (
      <>
        <h1>{titulo}</h1>
        <ReactDataTable
          className="creativityTable"
          columns={columns}
          key={idx}
          data={datosAgrupados}
          customStyles={customStyles}
          expandable
          persistTableHead
          pagination
          noHeader
        />
      </>
    );
  };
  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };
  let nombres = [];

  const handleClick = (value) => {
    console.log("value", value);
    if (value === itemId) return;
    if (value === "home") {
      setOptionsFile([]);
      setItemId(null);
    } else {
      setItemId(value);
      let idx = optionsFile.findIndex((ele) => ele.value === value);
      let options = optionsFile.slice(0, idx + 1);
      setOptionsFile(options);
    }
  };

  const handleItemClick = async (e) => {
    console.log("ododod", e);
    const nav = document.getElementById("navFiles");

    if (e.detail && e.detail.folder) {
      const id = e.detail.id;
      const name = e.detail.name;
      setOptionsFile([...optionsFile, { name: name, value: id }]);
      setItemId(id);
    } else {
      let access_token = await Providers.globalProvider.getAccessToken();
      console.log("acces");
      const fileName = e.detail.name;

      const fileId = e.detail.id;
      fetch(
        `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
        .then(async (respones) => {
          console.log("response", respones);
          const blob = await respones.blob();
          const file = new File([blob], fileName, { type: blob.type });
          file.path = fileName;
          console.log("fileee", file);
          // setUploadFIles([...uploadFIles, file]);
          setOneDriveFiles([...oneDriveFiles, file]);
        })
        .catch((data) => {
          console.log("error", data);
        });
    }
  };

  const sendUpdateFiles = (e) => {
    console.log("e", e);
    setUploadFIles([...uploadFIles, ...e]);
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permissions.view ? (
        <>
          {creativity.length > 0 && (
            <div className="barraSuperior">
              <DataTableUtils
                data={files}
                action={
                  permissions.create
                    ? () => {
                        setShowDrop(!showDrop); //comentar en prod
                        analytics.Add({
                          ubicacion: "Creatividad",
                        });
                      }
                    : null
                }
                actionIcon={
                  <Tooltip
                    color="#002448"
                    placement="top"
                    title="Crear creatividad"
                  >
                    {" "}
                    <i className="fas fa-plus" />{" "}
                  </Tooltip>
                }
                onChangeFilterText={(value) => {
                  clearTimeout(filterTimeout);
                  filterTimeout = setTimeout(() => {
                    handleFilterTextChange(value);
                  }, 1000);
                }}
              >
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Exportar información"
                >
                  <div
                    onClick={() => {
                      //comentar en prod
                      analytics.Compartir({
                        ubicacion: "Creatividad",
                      });
                    }}
                    className="ExportBtn"
                  >
                    {permissions.export && (
                      <Share
                        // onShareContact={handleShareContact}
                        // contacts={myContacts}
                        data={exportDataToExcel()}
                        columns={columns}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    )}
                  </div>
                </Tooltip>
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Personalizar columnas"
                >
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columns}
                      showColumn={(data, data1) => {
                        analytics.ShowHide({
                          ubicacion: "Creatividad",
                        });
                        handleShowColumnChange(data, data1);
                      }}
                      showView={handleShowView}
                      onSortChange={(positions) => {
                        analytics.Columnas({
                          ubicacion: "Creatividad",
                        });
                        handleColumnSortChange(positions);
                      }}
                      vistas={views}
                      setVista={(nombre) => {
                        analytics.accionesVista({
                          ubicacion: "Creatividad",
                          accion: "Agregar",
                        });
                        handleAddView(nombre);
                      }}
                      deleteView={(name) => {
                        analytics.accionesVista({
                          ubicacion: "Creatividad",
                          accion: "ELiminar",
                        });
                        handleDeleteView(name);
                      }}
                      nameAc={nameView}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Filtrar y agrupar"
                >
                  <div className="Filtro2 filtro3 filtroCreative">
                    <FilterColumn2
                      columns={columns}
                      onFilterColumn={handleFilterPerColumn2}
                      data={[]}
                      options={options}
                      changeGrouped={changeGrouped}
                      filterInfoCheck={filterInfo}
                    />
                  </div>
                </Tooltip>
              </DataTableUtils>
            </div>
          )}
          {showDrop && (
            <>
              <DropZone
                className="dropzone"
                onUpload={handleUpload}
                onChange={handleFileChange}
                files={uploadFIles}
                setFiles={setUploadFIles}
              />
              <div className="boxBtnUpLoad">
                <button onClick={() => getDriveFiles()} className="btnGD">
                  <img src={iconGoogleDrive} className="IconGD" />
                  Cargar
                </button>
                {!isLoginOneDrive ? (
                  <Login
                    loginCompleted={() => {
                      setIsLoginOneDrive(true);
                    }}
                  />
                ) : (
                  <button
                    onClick={() => setShowModalOneDrive(true)}
                    className="btnGD"
                  >
                    Archivos OneDrive
                  </button>
                )}
              </div>
            </>
          )}
          {showModalOneDrive && (
            <ModalOneDrive
              setShowModal={setShowModalOneDrive}
              handleClick={handleClick}
              optionsFile={optionsFile}
              itemId={itemId}
              setIsLoginOneDrive={setIsLoginOneDrive}
              handleItemClick={handleItemClick}
              oneDriveFiles={oneDriveFiles}
              sendUpdateFiles={handleUpload}
            />
          )}
          {/* <ul class="breadcrumb" id="navFiles">
            <li>
              <a id="home" onClick={(e) => handleClick("home")}>
                Files
              </a>
            </li>
            {optionsFile.map((opt) => (
              <li>
                <a id="home" onClick={(e) => handleClick(opt.value)}>
                  {opt.name}
                </a>
              </li>
            ))}
          </ul>
          <FileList
            itemId={itemId}
            aria-aria-multiselectable={true}
            itemClick={handleItemClick}
          /> */}
          {creativity.length > 0 ? (
            <>
              <div className="Kanbang-content">
                <div className="List-category">
                  {nameGrouped == "" ? (
                    <ReactDataTable
                      className="creativityTable"
                      columns={columns}
                      data={filteredData}
                      persistTableHead
                      customStyles={customStyles}
                      pagination
                      noHeader
                    />
                  ) : (
                    filteredData.map((ele, idx) => {
                      if (nameGrouped == "name") {
                        if (!nombres.includes(ele[nameGrouped])) {
                          nombres.push(ele[nameGrouped]);
                          return cambioDeAgrupacion(
                            ele[nameGrouped],
                            ele[nameGrouped],
                            idx
                          );
                        }
                      }
                      if (nameGrouped == "key") {
                        // let b = (ele.link[0][nameGrouped]).match(/\.(png|jpg|jpeg|pdf)/g)
                        // let a = (b.toString()).split('.')
                        let name = formatType(ele);
                        if (!nombres.includes(name)) {
                          nombres.push(name);
                          return cambioDeAgrupacion(
                            ele.link[0][nameGrouped],
                            name,
                            idx
                          );
                        }
                      }
                      if (nameGrouped == "size") {
                        let name = formatFileSize(ele.link[0][nameGrouped]);
                        if (!nombres.includes(name)) {
                          nombres.push(name);
                          return cambioDeAgrupacion(
                            ele.link[0][nameGrouped],
                            name,
                            idx
                          );
                        }
                      }
                      if (nameGrouped == "lastModified") {
                        let name = formatDate(ele.link[0][nameGrouped]);
                        if (!nombres.includes(name)) {
                          nombres.push(name);
                          return cambioDeAgrupacion(
                            ele.link[0][nameGrouped],
                            name,
                            idx
                          );
                        }
                      }
                    })
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="form homeSinLogin">
              {/* <div className='form-margin'> */}
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Agrega tu primer imágen</h1>
                {permissions.create && (
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      setShowDrop(!showDrop);
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                )}
              </div>
              <div></div>
              {/* </div> */}
            </div>
          )}
        </>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  pwaFiles: state.pwa.creative.files,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _getAllCampaign: (account) => dispatch(getAllCampaigns(account)),
  _getClients: () => dispatch(getClients()),
  _showLoader: (show) => dispatch(showLoader(show)),
  _getFiles: (account) => dispatch(getFiles(account)),
  _removeFile: (name, account) => dispatch(removeFile(name, account)),
  _uploadFiles: (files, account) => dispatch(uploadFiles(files, account)),
  _pwaUploadFiles: (files, account) => dispatch(pwaUploadFiles(files, account)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(withUtils(Content));
