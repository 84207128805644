import { showLoader } from "./loader.actions";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";

import _ from "lodash";

export const APP_LOADED = "APP:LOADED";
export const APP_SET_CURRENT_ODOO = "APP:SET:CURRENT:ODOOOVS";
export const APP_CLEAR_CURRENT_ODOO = "APP:CLEAR:CURRENT:ODOOOVS";
export const APP_SET_LAST_SINC = "APP:SET:LAST:SINC";

export const appLoaded = () => ({ type: APP_LOADED });

export const setCurrentPermissions = (odoo) => ({
  type: APP_SET_CURRENT_ODOO,
  odoo,
});

export const setLastSinc = (lastSinc) => ({
  type: APP_SET_LAST_SINC,
  lastSinc,
});

export const clearCurrentPermissions = () => ({
  type: APP_CLEAR_CURRENT_ODOO,
});

export const getOdooOvsByOrderNumber = (orderNumber) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    const response = await APILA.getLovByIdOrderNumber({
      orderNumber: orderNumber,
    });
    return response.data.data;
  } catch (error) {
    console.log("Hubo un error", error);
    return [];
  } finally {
    dispatch(showLoader(false));
  }
};

export const getLastSinc = (account) => async (dispatch) => {
  try {
    let findSinc = _.get(
      await API.getAccountById({ id: account }),
      "data.account",
      "[]"
    );

    if (findSinc.length > 0) {
      const date =
        findSinc[0]?.lastSinc !== undefined
          ? findSinc[0]?.lastSinc
          : new Date().toISOString().substring(0, 10);
      dispatch(setLastSinc(date));
    } else {
      dispatch(setLastSinc(new Date().toISOString().substring(0, 10)));
    }
  } catch (error) {
    console.log("Hubo un error", error);
    dispatch(setLastSinc(new Date().toISOString().substring(0, 10)));
  }
};

export const sincPartialOdoo = (account, accounts) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    const findAccount = accounts.find((ele) => ele.id === account);
    await APILA.sincPartial({
      account: account,
      name: findAccount.name,
    });
    await dispatch(getLastSinc(account));
  } catch (error) {
    console.log("Hubo un error", error);
  } finally {
    dispatch(showLoader(false));
  }
};
