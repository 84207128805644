import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Campaign from "../objectCampain";
import Pauta from "../updateCampain";

import NavBar from "../navBar";

import { saveId, getObjectCampain } from "redux/actions/objectCampain.actions";

const ObjectCampainList = (props) => {
  useEffect(() => {
    props._getObjectCampain();
  }, []);

  return (
    <>
      <NavBar />
      <div className="content-all">
        <div id="shadow" className="shadow hide"></div>
        <div className="form-dashboard">
          <Bar objectcampain={props.objectcampain} updatep={props._saveId} />
        </div>
        <ModalnewCamp />
        <ModalUpdCamp />
      </div>
    </>
  );
};

class Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
    };
  }

  showModalCamp = () => {
    let shadow = document.getElementById("shadow");
    let modal = document.getElementById("modalNewCamp");
    shadow.classList.toggle("hide");
    modal.classList.toggle("hide");
  };

  labelWithIcon = (<span className="fas fa-stroopwafel" />);

  render() {
    return (
      <div className="Bar-content" onClick={this.showModalUpd}>
        <div className="content-menu">
          <select className="select-dashboard fa" id="viewSelect">
            <option className="fa" value="1">
              {" "}
              &#xf0ce; Vista de lista
            </option>
            <option className="fa" value="0">
              {" "}
              &#xf0db; Vista de Kanban{" "}
            </option>
            <option className="fa" value="2">
              {" "}
              &#xf073; Vista de calendario
            </option>
          </select>
          <div className="select-dashboard width">
            <input
              className="search-dashboard"
              id=""
              type="text"
              placeholder="Ingresa tu busqueda"
            ></input>
            <a>
              <i className="fas fa-microphone"></i>
            </a>
          </div>
          <div className="content-floatWindow">
            <a id="share-btn" className="btn-dashboard">
              <i className="fas fa-share-square"></i>
            </a>
            <a id="colum-btn" className="btn-dashboard">
              <i className="fas fa-columns"></i>
            </a>
            <a id="filter-btn" className="btn-dashboard">
              <i className="fas fa-filter"></i>
            </a>
          </div>
          <a onClick={this.showModalCamp} className="btnOrange-dash">
            <i className="fas fa-plus"></i>
          </a>
        </div>

        <div id="List-content" className="Kanbang-content">
          <div className="List-category">
            <a id="btn-showList">
              <i className="fas fa-caret-down"></i>
            </a>
            <table className="table-List">
              <thead>
                <tr>
                  <th>
                    <p>Tipo de pauta</p>
                  </th>
                  <th>Fecha creacion</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.props.objectcampain.map((campaign) => {
                  return (
                    <Fila
                      nombre={campaign.name}
                      fechaIni={campaign.datecreate}
                      estado="Activo"
                      id={campaign.id}
                      update2={this.props.updatep}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

class ModalnewCamp extends Component {
  render() {
    return (
      <div id="modalNewCamp" className="modal-newCamp hide">
        <div className="foot-mnc">
          <Campaign modal={true} />
        </div>
      </div>
    );
  }
}

class ModalUpdCamp extends Component {
  render() {
    return (
      <div id="ModalUpdCamp" className="modal-newCamp hide">
        <div className="foot-mnc">
          <Pauta modal={true} />
        </div>
      </div>
    );
  }
}

class Fila extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  showModalUpd = () => {
    let shadow = document.getElementById("shadow");
    let modal = document.getElementById("modalNewCamp");
    shadow.classList.toggle("hide");
    modal.classList.toggle("hide");
  };

  intermediaria = (ide, namee) => {
    console.log("el ide devuelto es");
    console.log(ide);
    this.props.update2(ide, namee);

    this.showModalUpd();
  };

  render() {
    return (
      <tr>
        <th>
          <div className="firstColl">
            <div
              className="edit-fc"
              onClick={(e) =>
                this.intermediaria(this.props.id, this.props.nombre)
              }
            >
              <i className="fas fa-edit"></i>
            </div>
            <p className="name-fc">{this.props.nombre}</p>
            <div className="msn-fc">
              <i className="fas fa-comment-alt"></i>
            </div>
          </div>
        </th>
        <th>
          <div>
            <p>{this.props.fechaIni}</p>
          </div>
        </th>
        <th>
          <div className="secondColl green">{this.props.estado}</div>
        </th>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  objectcampain: state.objcampain,
  pauta: state.pauta,
});

const mapDispatchToProps = (dispatch) => ({
  _getObjectCampain: () => dispatch(getObjectCampain()),
  _saveId: (id, name) => dispatch(saveId(id, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ObjectCampainList));
