import React from 'react'

// components
import MetaTags from 'components/metatags'
import TermsAndConditions from 'components/auth/termsAndCondition'

const TermsCondition = props => (
  <>
    <MetaTags
      title='Terminos y condiciones'
      description='Lectura de los terminos y condiciones de uso'
    />

    <TermsAndConditions {...props} />
  </>
)

export default TermsCondition
