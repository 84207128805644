
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import './assets/css/style.css'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import { getCountries } from 'redux/actions/medioCamp.actions'
import { createAccountModal } from 'redux/actions/dashboard.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { Auth } from 'aws-amplify'
const ModalImage = (props) => {
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [contryValue, setContryValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')

    useEffect(() => {      
      // props._getCountries();
    }, [])
  
  
    const checkdata = async e => {
      e.preventDefault()
      props._showLoader(true)
      //checkparametros
      register(e)
      props._showLoader(false)
    }
    const logInn = async e => {
      await Auth.signIn(emailValue, passwordValue)
      let user = await Auth.currentAuthenticatedUser()
      console.log("user ", user)
      
      // localStorage.setItem("tempAWSUser", "");
      // localStorage.setItem("account", "");
      props.onStateChange('confirmSignUp', {username: emailValue, password: passwordValue})
      closeModal(e)
      // props.history.push('/categories')

    }
    
    const register =async (e) => {  
      
      const mAccount = _.find(props.accounts, { id: props.account })
      console.log("current account ", mAccount)
      props._showLoader(false)

      const awsBody={
        name:nameValue,
        phone:phoneValue,
        country:contryValue,
        username:emailValue,
        password:passwordValue
      }
      localStorage.setItem("nameAWS", nameValue);
      localStorage.setItem("emailAWS", emailValue);
      await props._createAccountModal(awsBody,mAccount)
      
      await Auth.signIn(emailValue, passwordValue)      
      localStorage.setItem("tempAWSUser", "");      
      localStorage.setItem("account", "");
      // props.onStateChange('signedIn', {})
      
      closeModal(e)
    }
    
    
    const closeModal = (e) => 
    {      
        if (e) e.preventDefault()
        window.$.fancybox.close()
    }
    

  return (
    <div
      id='new-create-cuenta'
      style={{ display: 'none' }}
      className='upsert-modal w-75 h-75'
    >
        <div className='titleOneStep w-100'>
    <p>
      Creemos tu cuenta
    </p>
  </div>
  <div className='bodyOneStep w-100'>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Nombre completo
          </Label>
          <Input
            type='text'
            name='id_sale'
            className='input'
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            placeholder='Ingresa tu nombre completo'
          />
          <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Correo electrónico
          </Label>
          <Input
            type='text'
            name='id_sale'
            className='input'
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            placeholder='Ingresa tu correo electrónico'
          />
          <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Numero teléfonico
          </Label>
          <Input
            type='text'
            name='id_sale'
            className='input'
            value={phoneValue}
            onChange={(e) => setPhoneValue(e.target.value)}
            placeholder='Ingresa tu numero telefonico'
          />
          <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Elige un pa&iacute;s 
          </Label>
          <Autocomplete
            id='btnCountry'       
            //disableClearable     
            // autoComplete="off" 
            style={{ width: 300, height: 35 }}
            // value={state.country}
            options={props.countries}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setContryValue(newValue.name);
            }}
            
            
            renderInput={(params) => (
              <TextField {...params} label='País' variant='outlined' />
            )}
          />
          <FormFeedback>Elige un pais</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Ingresa tu nombre contraseña
          </Label>
          <Input              
            name='id_sale'
            className='input'
            type='password'
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            placeholder='Ingresa tu contrase&ntilde;a'
          />
          <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
        </FormGroup>
    </div>
  </div>
  <div className='footOneStep w-100'>
    <a href='#' className='btnCancelOS' onClick={closeModal}>
      Cancelar
    </a>

    <a className='btnConfirmOS' onClick={checkdata} >
      Confirmar
    </a>
    {/* <a className='btnConfirmOSa' onClick={logInn} >
      log in
    </a> */}
  </div>
        
      
    </div>
  )   

    
    
}

const mapStateToProps = (state) => ({        
    countries:state.countryReducer,
    currentUser: state.app.current_user,
    currentAccount: state.accounts.default,
    accounts: state.accounts.list,
    account: state.accounts.default,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    _getCountries: () => dispatch(getCountries()),
    _createAccountModal: (awsbody,account) => dispatch(createAccountModal(awsbody,account)),
    _showLoader: (show) => dispatch(showLoader(show)),

  })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalImage))








