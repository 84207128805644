import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sortBy } from "lodash";
import NumberFormat from "react-number-format";
import analytics from "../../helpers/analytics";
import { Tooltip } from "antd";
// organization components
import ReactDataTable from "@easygosanet/react-datatable";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import {
  setEdit,
  clearEdit,
  getAccounts,
} from "redux/actions/accounts.actions";
import { getUsersRole } from "redux/actions/users.actions";
import { withUtils } from "utils";
import * as APILA from "helpers/apiLA";
import PageLoader from "components/page_loader";
import { useHistory, useLocation } from "react-router-dom";
import "./assets/css/styles.scss";

const TAG = "Accounts";
const DetailCol = ({ text, className, onClick, permisos }) => (
  <div className="content-firstColumn">
    <div className="iconBand">
      {permisos.edit && (
        <Tooltip color="#002448" placement="top" title="Editar">
          <i className="fas fa-edit" onClick={() => onClick()}></i>
        </Tooltip>
      )}
    </div>
    <p className="name-fc">{text}</p>
  </div>
);
const Content = (props) => {
  /* Variables para cambiar la url */
  const history = useHistory()
  const location = useLocation()

  /* ULRSeachParams clase que  utilizamos  sus metodos y obtener los valores  */
  const currentUrlSearch = new URLSearchParams(location.search)
  const currentUrl = new URLSearchParams(location.pathname)
  /**
   * Valores por defecto si viene en la url
   */
  const currentPageParams = Number(currentUrl.get("page"))
  const textSearching = currentUrlSearch.get("search_query")

  //State
  const [currentPage, setCurrentPage] = useState(currentPageParams || 1);
  const [username, setUsername] = useState("");
  const [filters, setFilters] = useState({});
  const [filterText, setFilterText] = useState(textSearching || "");
  const [isLoading, setIsLoading] = useState(false);

  const changeUrl = ({ pagination = currentPage }) => {
    const pathSegments = location.pathname.split('/')
    const segmentForAccount = pathSegments[3]
    const valueAccount = segmentForAccount?.split('=')[1]?.split('&')[0]
    const validateValueAccount = valueAccount === undefined ? props.currentAccount : valueAccount
    const newUrl = `/configuracion/accounts/account=${validateValueAccount}&page=${pagination}`
    history.replace({
      pathname: newUrl
    })
    if (textSearching !== '' && textSearching !== null)
      history.push({
        search: location.search
      })
  }

  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: false,
  });

  const [columns, setColumns] = useState([
    {
      name: "Nombre",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <DetailCol
          text={getAccountName(row.id, row.client, row.owner, row.name)}
          onClick={() => goToEditPage(row)}
          permisos={permissions}
          className={classnames("edit-fc", {
            "not-propietary": !row.owner,
          })}
        />
      ),
    },
    {
      name: "Fanpage",
      selector: "fanpage",
      sortable: true,
      center: true,
    },
    {
      name: "Fecha de Creación",
      selector: "createAt",
      sortable: true,
      center: true,
      cell: (row) => formatDate(row.createAt),
    },
    {
      name: "Crédito",
      selector: "credit",
      sortable: true,
      center: true,
      cell: (row) => (
        <NumberFormat
          displayType={"text"}
          value={row.credit}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
    },
  ]);
  let accounts = [];
  const permisos = async () => {
    setIsLoading(true);
    try {
      let userdata = await Auth.currentAuthenticatedUser();
      let sub = userdata.username;
      let permisoAcc = await APILA.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: sub,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await APILA.getRoleNameById({ id: idRol });
      let permissAud = permisosRes.data.rol[0].permissions;
      let permissFn = permissAud.filter((ele) => ele.name == "Cuentas");
      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
    } catch (err) {
      console.log("err permits", err);
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page)
    changeUrl({
      pagination: Number(page)
    })
  }

  if (props.currentUser !== "") {
    accounts = sortBy(props.accounts, ["createAt"]);
  } else {
    accounts = props.pwaAccounts;
  }
  useEffect(() => {
    permisos();
  }, [props.currentAccount]);
  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser().then((userData) => {
        changeUrl({
          pagination: currentPageParams || 1
        })
        let { username } = userData;
        setUsername(username);
        props._getAccounts(username);
        props._getUsersRole(username);
      });
    } catch (err) {
      setUsername(props.currentUser);
      props._getAccounts(props.currentUser);
      props._getUsersRole(props.currentUser)
      changeUrl({
        pagination: currentPageParams || 1
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };
  const getAccountName = (accountId, clientId, owner, name) => {
    if (props.currentUser !== "") {
      const acc = _.find(props.accounts, { id: accountId });
      const client = _.find(props.clients, { id: clientId });
      // if (acc) {
      // return owner ? acc.name : `${name} - ${client ? client.name : ''}`
      // }
      return name;
    } else {
      return name;
    }
  };
  const goToEditPage = (edit) => {
    props._setEdit(edit);
    //comentar para prod
    analytics.accionesTabla({
      accion: "Editar",
      ubicacion: "Configuracion",
    });
    window.$.fancybox.open({
      src: "#new-account-modal",
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => props._clearEdit(),
      },
    });
  };
  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    return `${date.getDate()} - ${months[date.getMonth()]
      } - ${date.getFullYear()}`;
  };
  const handleFilterTextChange = (value) => {
    //comentar para prod
    analytics.busqueda({
      ubicacion: "Configuracion",
      busqueda: filterText,
    });
    if (value === "") {
      history.push({
        search: ""
      })
    } else {
      history.push({
        search: `search_query=${value}`
      })
    }
    setFilterText(value);
  };
  const handleShowColumnChange = (column, show) => {
    //comentar para prod
    analytics.ShowHide({
      ubicacion: "Configuracion",
    });
    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    aux[index].omit = show;
    setColumns(aux);
  };
  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);
    //comentar para prod
    analytics.Columnas({
      ubicacion: "Configuracion",
    });
    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      aux[index].position = position;
    });
    aux = _.orderBy(aux, ["position"]);
    setColumns(aux);
  };
  const handleFilterPerColumn = (column, type, value) => {
    setFilters((prev) => ({ ...prev, [column]: { type, value } }));
    //comentar para prod
    analytics.filtro({
      ubicacion: "Configuracion",
    });
  };
  let filteredData = accounts.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase())
  );
  if (!_.isEmpty(filters)) {
    filteredData = filteredData.filter((item) => {
      let result = true;
      for (const filterKey in filters) {
        let response;
        let filter = filters[filterKey];
        let dataValue = item[filterKey];
        switch (filter.type) {
          case "EQUALS":
            response =
              `${filter.value}`.toLowerCase() === `${dataValue}`.toLowerCase();
            break;
          case "INLIST":
            response = filter.value[`${dataValue}`];
            break;
          case "GREATERTHAN":
            response = filter.value === -1 || dataValue > filter.value;
            break;
          case "LESSTHAN":
            response = filter.value === -1 || dataValue < filter.value;
            break;
          case "EQUALTHAN":
            response = filter.value === -1 || filter.value === dataValue;
            break;
          default:
            response = `${dataValue}`
              .toLowerCase()
              .includes(`${filter.value}`.toLowerCase());
            break;
        }
        result = result && response;
      }
      return result;
    });
  }
  const isAuthenticated = () => {
    let currentUser = props.currentUser;
    let ok = false;
    if (_.isEmpty(props.users) == false) {
      let found = props.users.find(
        (element) => element.id == props.currentUser
      );
      if (found) {
        ok = true;
      }
    }
    return ok;
  };
  return isLoading ? (
    <PageLoader />
  ) : permissions.view ? (
    <>
      <div className="barraSuperior">
        <DataTableUtils
          data={accounts}
          action={
            !props.isIpv4 && permissions.create ?
              () => {
                props._clearEdit();
                //comentar para prod
                analytics.Add({
                  ubicacion: "Configuracion",
                });
                window.$.fancybox.open({
                  src: "#new-account-modal",
                  //type: 'modal',
                  opts: {
                    modal: true,
                    afterClose: () => props._clearEdit(),
                  },
                });
              }
              :
              null
          }
          actionIcon={
            permissions.create && (
              <Tooltip color="#002448" placement="top" title="Crear cuenta">
                <i className="fas fa-plus" />
              </Tooltip>
            )
          }
          onChangeFilterText={handleFilterTextChange}
          valueFilter={filterText}
        >
          <Tooltip color="#002448" placement="top" title="Exportar información">
            <div
              onClick={() => {
                //comentar para prod
                analytics.Compartir({
                  ubicacion: "Configuracion",
                });
              }}
              className="ExportBtn"
            >
              {permissions.export &&
                <Share
                  data={columns}
                  contacts={[]}
                  idImg={'root'}
                  appKey='2u78gno172idwbz'
                />
              }
            </div>
          </Tooltip>

          <Tooltip
            color="#002448"
            placement="top"
            title="Personalizar columnas"
          >
            <div id="shareBtn">
              <ShowColumn2
                columns={columns}
                showColumn={handleShowColumnChange}
                onSortChange={handleColumnSortChange}
                vistas={[]}
              />
            </div>
          </Tooltip>

          <Tooltip color="#002448" placement="top" title="Filtrar y agrupar">
            <div className="Filtro2">
              <FilterColumn2
                columns={columns}
                onFilterColumn={handleFilterPerColumn}
              />
            </div>
          </Tooltip>
        </DataTableUtils>
      </div>
      <div className="Kanbang-content tablaMyAccounts">
        <div className="List-category">
          <ReactDataTable
            customStyles={customStyles}
            columns={columns}
            data={filteredData}
            // paginationPerPage={2}
            currentPage={currentPage}
            paginationDefaultPage={currentPage}
            onChangePage={handlePageChange}
            persistTableHead
            pagination
            noHeader
          />
        </div>
      </div>
    </>
  ) : (
    <h1>No tienes Permisos</h1>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  isIpv4: state.app.isIPV4,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  users: state.users,
  currentAccount: state.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _getUsersRole: (user) => dispatch(getUsersRole(user)),
  _clearEdit: () => dispatch(clearEdit()),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _getAccounts: (client) => dispatch(getAccounts(client)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Content)));
