import { v4 } from 'uuid'
import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'

export const ADD_PAYMENT = 'PAYMENT:ADD'
export const CLEAN_PAYMENT = 'PAYMENT:CLEAN'
export const PAYMENT_CHANGE_TOTAL = 'PAYMENT:ADD_TOTAL'

const TAG = 'Payment:Actions'



export const cleanPayments = () => (dispatch) => {
  dispatch({
    type: CLEAN_PAYMENT,
  })
}

export const getPayments = (client) => async dispatch => {

  const body={
    client:client
  }
  Logger.log(TAG, 'getPayments parms', client)
  Logger.log(TAG, 'getPayments body', body)
  dispatch(cleanPayments());
  try {
    const response = await APILA.getPaymentInfo(body)
    Logger.log(TAG, 'getPayments', response)
    

 
    dispatch(addPayment(response.data.cards))
    dispatch(addTotal(response.data.cards.length)) 
     
    

    
  } catch (err) {
    Logger.log(TAG, 'error getAllCampaigns', err)
  }
}

// async function  insertLog (account,action,request,response,user,obs,idmod,mod){
//   let idlog = v4()
//   let now = Date.now()
//   let logRequest={
//     "id": idlog,
//     "source":"Lets-Advertise",
//     "timestamp":now,
//     "action":action,
//     "user": user,
//     "role":account,
//     "idmodulo":idmod,
//     "modulo": mod,
//     "request":request,
//     "response": response,
//     "observation": obs 
    
//   }
//   const responseLog = await APILA.insertLogLine(logRequest)

// }

const addTotal = total => {
    
  return dispatch => {
    dispatch({
      type: PAYMENT_CHANGE_TOTAL,
      total
    })
  }
}


  const addPayment = cards => {
    
    return dispatch => {
      dispatch({
        type: ADD_PAYMENT,
        cards
      })
    }
  }
  