import React from "react";
import { Tooltip } from "antd";
import "../style.scss";

const DEFAULT = "default";

const Buttons = ({
  updNameTemplate,
  deleteTemplate,
  convertTemplateDefault,
  templateInformation,
  permission,
}) => {
  return (
    <>
      {permission.edit && (
        <Tooltip
          title="Actualizar nombre de la plantilla actual"
          placement="top"
          color="#002448"
        >
          <button
            style={{
              border: "none",
              background: "none",
              width: "60px",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              color: "#02025b",
            }}
            onClick={updNameTemplate}
          >
            <i
              className="fas fa-edit text-blue "
              style={{ fontSize: "24px", padding: "2px 3px" }}
            ></i>
          </button>
        </Tooltip>
      )}

      {templateInformation?.account !== DEFAULT && permission.delete && (
        <Tooltip
          title="Eliminar plantilla actual"
          placement="top"
          color="#002448"
        >
          <button
            style={{
              border: "none",
              background: "none",
              width: "60px",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              color: "#02025b",
            }}
            onClick={deleteTemplate}
          >
            <i
              className="fas fa-trash-alt"
              style={{ fontSize: "24px", padding: "2px 3px" }}
            ></i>
          </button>
        </Tooltip>
      )}
      {!templateInformation?.isDefaultByAccount &&
        templateInformation?.account !== DEFAULT &&
        permission.edit && (
          <Tooltip
            title="Convertir en plantilla predeterminada"
            placement="top"
            color="#002448"
          >
            <button
              style={{
                border: "none",
                borderRadius: "5px",
                width: "60px",
                background: "none",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                color: "#02025b",
              }}
              onClick={convertTemplateDefault}
            >
              <i
                className="fa fa-table"
                style={{ fontSize: "24px", padding: "2px 3px" }}
              ></i>
            </button>
          </Tooltip>
        )}
    </>
  );
};

export default Buttons;
