import React from 'react'

// components
import MetaTags from 'components/metatags'
import Dashboard from 'components/dashboardPwa'

const DashboardPwa = () => (
  <>
    {/* <MetaTags title="Let's Advertise Ads - Campañas de publicidad" description="Administra tus campañas de publicidad en un mismo lugar. La sección de Campañas de Let's Advertise Ads te permite comprar publicidad en todos los medios." /> */}
    <MetaTags title='Dashboard' description='Página de Dashboard' />

    <Dashboard />
  </>
)

export default DashboardPwa
