import * as APILA from 'helpers/apiLA'
import _ from 'lodash'
import { Auth } from "aws-amplify";


export const SCHEDULE_SET_EDIT = 'SCHEDULE_SET_EDIT';
export const SCHEDULE_CLEAR_EDIT = 'SCHEDULE_CLEAR_EDIT';
export const SCHEDULE_ONSAVE = 'SCHEDULE_ONSAVE';
export const SCHEDULE_PERMISOS = 'SCHEDULE_PERMISOS';
const idAccountGeneral = 'a75a9dab-c8d2-45cc-aefc-0ee0679cc089'


export const setEdit = (edit) => ({
    type: SCHEDULE_SET_EDIT,
    edit
})

export const clearEdit = () => ({
    type: SCHEDULE_CLEAR_EDIT
})

export const onSave = (onSave) => ({
    type: SCHEDULE_ONSAVE,
    onSave
})

export const setPermisos = (permisos) => ({
    type: SCHEDULE_PERMISOS,
    permisos
})

export const getSchedules = () => async (dispatch) => {
    try {
        const response = await APILA.findScheduleById({ id: idAccountGeneral })
        const dataResponse = response?.data?.response?.workDays
        const newData = dataResponse.map((item, index) => {
            return { ...item, day: _.capitalize(item?.day), key: index + 1 }
        })
        dispatch(setEdit(response?.data?.response))
        dispatch(onSave(false))
        return { workDays: newData, freeDays: response?.data?.response?.freeDays }
    } catch (error) {
        console.log('Error in getSchedules', error)
    }
}

export const getPermitsByUser = (account) => async (dispatch) => {
    try {
        let response = await APILA.getPermitsByUser({
            user: Auth.user.signInUserSession.idToken.payload.sub,
        });
        response = response.data.permits;
        let cuenta = response.find(
            (ele) => ele.account === account
        );
        let permisosResponse = await APILA.getRoleNameById({
            id: cuenta.idRol,
        });
        permisosResponse = permisosResponse.data.rol[0].permissions;
        let permisosRoles = permisosResponse.find(
            (ele) => ele.name === "Configuración prioridades de campañas"
        );
        console.log('Validando lo que esta retornando', { response, permisosRoles, cuenta })
    } catch (error) {
        console.log('Error in getPermitsByUser', error)
    }
}

export const editData = (newEdit, propsEdit, freeDays) => async (dispatch) => {
    try {
        if (freeDays) {
            dispatch(onSave(true))
            let dataFreeDays = { ...propsEdit, freeDays: newEdit }
            dispatch(setEdit(dataFreeDays))
        } else {
            dispatch(onSave(true))
            let dataWorkDays = { ...propsEdit, workDays: newEdit }
            dispatch(setEdit(dataWorkDays))
        }
    } catch (error) {
        console.log('Error en la edicion', error)
    }
}

export const updateInDynamo = (data) => async (dispatch) => {
    try {
        let dataToSend = data?.workDays?.map(item => {
            const newObject = { ...item }
            delete newObject.key // eliminamos la key del objeto
            return newObject
        })
        let newObject = { ...data, workDays: dataToSend }
        let dataLoweCase = newObject.workDays.map(item =>
            ({ ...item, day: item.day.toLowerCase() })
        )
        let dataUpdate = { ...newObject, workDays: dataLoweCase }
        await APILA.updateSchedule(dataUpdate)
        dispatch(onSave(false))
    } catch (error) {
        console.log('Error en la actuializacion', error)
    }
}
