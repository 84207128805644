import { showLoader } from "./loader.actions";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import { getCampaignByIdMix } from "redux/actions/dashboard.actions";
import { getOdooOvsByOrderNumber } from "redux/actions/odooOvs.actions";
import { columnsOptions, validateTotals } from "config/columns";
import _ from "lodash";

export const APP_LOADED = "APP:LOADED";

export const appLoaded = () => ({ type: APP_LOADED });

export const getDataLevels =
  (accounts, props, typeData, currentAccount, father) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const findAccount = accounts.find((ele) => ele.id === currentAccount);
      const dataHandlers = {
        orderNumber: async () =>
          await handleOrderNumberData(props.data.orderNumber, dispatch),
        idsCampaign: async () =>
          await handleIdsCampaignData(findAccount, props, dispatch),
        idLovs: async () => await getLovsBatch(props.data.idLovs, father),
      };
      if (typeData in dataHandlers) {
        return await dataHandlers[typeData]();
      } else {
        return [];
      }
    } catch (error) {
      console.log("Hubo un error", error);
      return [];
    } finally {
      dispatch(showLoader(false));
    }
  };

const handleOrderNumberData = async (orderNumber, dispatch) => {
  const response = await dispatch(getOdooOvsByOrderNumber(orderNumber));
  return response;
};

const handleIdsCampaignData = async (findAccount, props, dispatch) => {
  if (props.data.idsCampaign && props.data.idsCampaign.length > 0) {
    const querys = await Promise.all(
      props.data.idsCampaign.map((ele) =>
        dispatch(
          getCampaignByIdMix(
            ele,
            findAccount.eAdvertising,
            props.budgets,
            props.id
          )
        )
      )
    );
    const getData = querys.filter(Boolean).flat(1);
    return getData;
  }
};

const getLovsBatch = async (lovs, father) => {
  const response = await APILA.getLovsBatch({
    idsLovs: lovs, // props.data.idLovs,
    idBudget: father,
  });
  return response.data.data;
};

export const getTotalsData = (columns, data) => (dispatch) => {
  const responseAssigned = {};
  const nombreCol = columns.find((ele) => ele.nameTotal);

  if (data.length > 0) {
    const keys = Object.keys(data[0]);
    keys.forEach((element) => {
      if (element !== "id_sale") {
        const valorSumado = _.sumBy(data, element);
        if (!isNaN(valorSumado) && !validateTotals.includes(element)) {
          responseAssigned[element] = valorSumado;
        }
      }
    });
  }
  responseAssigned["name"] = "Totales";
  // if (nombreCol) {
  //   responseAssigned[nombreCol.id] = "Totales";
  // }

  return responseAssigned;
};
