import { v4 } from "uuid";

import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import * as Modal from "./modal.actions";
import { showLoader } from "./loader.actions";
import _ from "lodash";
import {
  createCampaignTemplate,
  createCampaignTemplateLogout,
} from "./dashboard.actions";
import { generateLink } from "redux/actions/templateDeepLink.actions";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";
import { format } from "date-fns";

export const TEMPLATES_CLEAR = "TEMPLATES:CLEAR";
export const TEMPLATES_ADD_TOTAL = "TEMPLATES:ADD:TOTAL";
export const TEMPLATES_SET_EDIT = "TEMPLATES:SET:EDIT";
export const TEMPLATES_CLEAR_EDIT = "TEMPLATES:CLEAR:EDIT";
export const TEMPLATES_SET_EDIT_ACTIVE = "TEMPLATES:SET:EDIT:ACTIVE";
export const TEMPLATES_SET_EDIT_VISUALIZER = "TEMPLATES:SET:EDIT:VISUALIZER";
export const TEMPLATES_SET_LIST_ERRORS_AUDIENCE =
  "TEMPLATES:SET:LIST:ERRORSAUDIENCE";
export const TEMPLATES_SET_LIST_ERRORS_BUDGET =
  "TEMPLATES:SET:LIST:ERRORS_BUDGET";
export const TEMPLATES_SET_LIST_ERRORS_PRODUCT =
  "TEMPLATES:SET:LIST:ERRORS_PRODUCT";
export const TEMPLATES_SET_LIST_MEDIUMBANNER =
  "TEMPLATES:SET:LIST:MEDIUMBANNER";
export const TEMPLATES_SET_LIST_GOALS = "TEMPLATES:SET:LIST:GOALS";
export const TEMPLATES_SET_LIST_FORMAT = "TEMPLATES:SET:LIST:FORMAT";
export const TEMPLATES_SET_LIST_ERRORS_IMPLEMENT =
  "TEMPLATES:SET:LIST:ERRORS_IMPLEMENT";
export const TEMPLATES_CLEAR_EDIT_VISUALIZER =
  "TEMPLATES:CLEAR:EDIT:VISUALIZER";
export const TEMPLATES_UPDATE_ONE_VISUALIZER =
  "TEMPLATES:UPDATE:ONE:VISUALIZER";
export const TEMPLATES_SET_LIST_REFRESH_BUDGETS = "TEMPLATES:REMOVE:VISUALIZER";
export const TEMPLATES_SET_CURRENT_ID_PAUTA = "TEMPLATES:SET:CURRENT:ID:PAUTA";
export const TEMPLATES_SET_IMPLEMENT = "TEMPLATES:SET:IMPLEMENT";
export const TEMPLATES_SET_LIST_AUDIECNES = "TEMPLATES:SET:LIST:AUDIENCES";
export const TEMPLATES_SET_LIST_REGIONS = "TEMPLATES:SET:LIST:REGIONS";
export const TEMPLATES_SET_LIST_USER_INFO = "TEMPLATES:SET:LIST:USER:INFO";
export const TEMPLATES_SET_LIST_STEP = "TEMPLATES:SET:LIST:STEP";
export const TEMPLATES_SET_EDIT_FIELD = "TEMPLATES:SET:EDIT_FILED";
export const TEMPLATES_SET_NAME_FILED = "TEMPLATES:SET:LIST:NAME_FIELD";

export const TEMPLATES_SET_FILTERED_BUDGETS =
  "TEMPLATES:REMOVE:FILTERED:VISUALIZER";

/*****************************************
 * * Mensajes de error *
 *****************************************/

/*****************************************
 * * Opciones requeridas en headers *
 *****************************************/

const formatKeys = [
  "name",
  "medio",
  "fechaIni",
  "fechaFin",
  "banner",
  "budget",
  "id_medium",
  "id_region",
  "id_banner",
  "id_ad_product",
  "idBudget",
  "idAudience",
  "id_sale",
];

/*****************************************
 * * Implementacion de pautas *
 *****************************************/

export const sendDeployCampaignLogout =
  (visualizers, account, accounts, idTemplate) => async (dispatch) => {
    try {
      const mAccount = _.find(accounts, { id: account });
      const responseBudgets = await API.getBudgets(mAccount.id);
      const budgets = responseBudgets.data.budgets;
      let idsCreate = [];
      const newFormat = await dispatch(
        validateProduct(visualizers, budgets, account)
      );
      const requiredFiles = dispatch(validateFormatFile(visualizers));

      if (newFormat.exception || requiredFiles.validator) {
        return dispatch(
          Modal.showAlertModal(
            "¡Error!",
            newFormat.messageException || requiredFiles.message,
            Modal.MODAL_TYPE_ERROR,
            () => { }
          )
        );
      }
      const budgetsTransact = [];

      budgets.forEach((ele) => {
        const campaignsTotalBudget = newFormat.mappDataResponse
          .filter((budget) => budget.idBudget === ele.id)
          .reduce((total, item) => total + item.budget, 0);
        if (campaignsTotalBudget !== 0) {
          budgetsTransact.push({
            id: ele.id,
            quantity: campaignsTotalBudget,
            sum: false,
            idCampaign: 213,
            user: "logout",
          });
        }
      });
      if (budgetsTransact.length > 0) {
        try {
          await APILA.budgetTransaction({ budgets: budgetsTransact });
        } catch (errorTransaction) {
          dispatch(setListErrorImplements(budgetsTransact));
          dispatch(refreshBudgets(account));

          return dispatch(
            Modal.showAlertModal(
              "Ups!",
              "Hubo un error en la transacción,  ¡Revisa y ajusta los implementados!",
              Modal.MODAL_TYPE_ERROR,
              () => { }
            )
          );
        }
      }

      for (const ele of newFormat.mappDataResponse) {
        const idResponse = await dispatch(
          createCampaignTemplateLogout(
            ele.id, //Validaddp
            ele.name, //Validado
            ele.medio, //Va;odadp
            ele.fechaIni, //Validado
            ele.fechaFin, //Validado
            ele.banner, //Validado
            ele.budget, //Validadp
            [], //Validado
            ele.id_medium, //V;aodadp
            ele.medio, //Validado
            ele.id_ad_product, //Validado
            ele.banner, //Validado
            1, //Validado
            ele.idBudget, //Validado
            mAccount, //Validado
            ele.creativity, //Validado
            "123456", //Validado
            ele.observation, //Validado
            ele.copy, //Validado
            ele.urls, //Validado
            0, //0 verificar pq fue eliminada
            ele.id_region,
            ele.name_region,
            "", //name email
            "", //email
            "",
            "",
            budgets,
            [],
            ele.idAudience,
            ele.categoriesSelected,
            ele.formato,
            3
          )
        );
        idsCreate.push(+idResponse);
      }

      let urlInitial = window.location.origin + "/campañas/";
      let fechaFin = new Date();
      let fechaIn = new Date();
      fechaIn.setDate(fechaIn.getDate() - 7);
      let fechaIni = new Date(fechaIn);
      const initialDate = fechaIni.toISOString().substring(0, 10);
      const endDate = fechaFin.toISOString().substring(0, 10);
      const idsResponse = idsCreate.join(", ");

      let url = `${urlInitial}account=${account}&view=1&group=default&viewColumn=default&page=1&initial_date=${convertirFormato(
        initialDate
      )}&final_date=${convertirFormato(
        endDate
      )}?search_query=&results=${idsResponse}`;
      // APILA.deleteTemplate({ id: idTemplate });
      Swal.fire({
        title: "<strong>Creado con éxito</strong>",
        icon: "success",
        text: "Tu plantilla fue implementada correctamente. Ve a la sección de campañas para aprobarlas.",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ir a campañas",
        cancelButtonText: "Copiar link de campañas",
        customClass: {
          closeButton: "custom-close-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = url;
          return;
        }
        navigator.clipboard.writeText(url);
        Swal.fire({
          title: "¡Copiado al portapapeles!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      });
      const style = document.createElement("style");
      style.innerHTML = `
        .swal2-close {
          display: none; /* Ocultar el botón de cierre por defecto */
        }
        .swal2-container .custom-close-button-container {
          position: absolute;
          top: 5px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
          color: #000;
        }
        .swal2-container .custom-close-button-container i {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      `;
      document.head.appendChild(style);

      // Agregar el icono de cierre personalizado
      const closeButton = document.createElement("div");
      closeButton.classList.add("custom-close-button-container");
      closeButton.innerHTML = '<i class="fas fa-times"></i>'; // Icono de FontAwesome
      closeButton.addEventListener("click", () => Swal.close());
      document.querySelector(".swal2-popup").appendChild(closeButton);
      dispatch(getTemplates(account, ""));
    } catch (error) {
      console.log("Error", error);
    }
  };

export const sendDeployCampaigns =
  (
    visualizers,
    account,
    accounts,
    idTemplate,
    accountTemplate,
    isDefaultByAccount,
    edit
  ) =>
    async (dispatch) => {
      // * createCampaign, funcion de creacion de campaigns de manera masiva
      try {
        dispatch(showLoader(true));
        const userdata = await Auth.currentAuthenticatedUser();
        const mAccount = _.find(accounts, { id: account });
        const responseBudgets = await API.getBudgets(mAccount.id);
        const budgets = responseBudgets.data.budgets;

        let idsCreate = [];
        const newFormat = await dispatch(
          validateProduct(visualizers, budgets, account, edit)
        );
        const requiredFiles = dispatch(validateFormatFile(visualizers));

        if (newFormat.exception || requiredFiles.validator) {
          return dispatch(
            Modal.showAlertModal(
              "¡Error!",
              newFormat.messageException || requiredFiles.message,
              Modal.MODAL_TYPE_ERROR,
              () => { }
            )
          );
        }

        const newCampaigns = newFormat.mappDataResponse.map((ele) => {
          return {
            account: account,
            iniDate: validateDateStart(ele.fechaIni).replace(/\./g, "-"), //ele.fechaIni.replace(/\./g, "-"),
            endDate: ele.fechaFin.replace(/\./g, "-"),
            budget: parseFloat(ele.budget),
            appProduct: ele.id_ad_product,
            observation: ele.observation,
            idMedium: ele.id_medium,
            segmentation: ele.publico.join(", "),
            n_post: ele.name,
            campaign_name: "finalEtiqueta",
            id_priority: 3,
            medio: ele.medio,
            medioo: ele.medio,
            objetivo: "",
            publico: ele.publico,
            banner: ele.banner,
            idBudget: ele.idBudget,
            creativity: ele.creativity,
            copy: ele.copy,
            id_region: ele.id_region,
            name_region: ele.name_region,
            nameForEmail: ele.nameForEmail,
            emailForEmail: ele.emailForEmail,
            idAudience: ele.idAudience,
            categoriesSelected: ele.categoriesSelected,
            formato: ele.formato,
            id_digital_asset: "",
          };
        });

        const response = await dispatch(
          createCampaignTemplate(newCampaigns, account, userdata.attributes.email)
        );

        console.log("newCampaigns", response);

        let urlInitial = window.location.origin + "/campañas/";
        let fechaFin = new Date();
        let fechaIn = new Date();
        fechaIn.setDate(fechaIn.getDate() - 7);
        let fechaIni = new Date(fechaIn);
        const initialDate = fechaIni.toISOString().substring(0, 10);
        const endDate = fechaFin.toISOString().substring(0, 10);
        const idsResponse = idsCreate.join(", ");

        let url = `${urlInitial}account=${account}&view=1&group=default&viewColumn=default&page=1&initial_date=${convertirFormato(
          initialDate
        )}&final_date=${convertirFormato(
          endDate
        )}?search_query=&results=${response}`;
        dispatch(
          insertLogTemplate(
            "createCampaignTemplate response",
            3,
            "TEMPLATES",
            "Creacion masiva exitosa de campañas en planificacion de campañas",
            {},
            idsCreate,
            account,
            "Lets-Advertise",
            userdata.attributes.email
          )
        );
        // APILA.deleteTemplate({ id: idTemplate });
        Swal.fire({
          title: "<strong>Creado con éxito</strong>",
          icon: "success",
          text: "Tu plantilla fue implementada correctamente. Ve a la sección de campañas para aprobarlas.",
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ir a campañas",
          cancelButtonText: "Copiar link de campañas",
          customClass: {
            closeButton: "custom-close-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = url;
            return;
          }
          navigator.clipboard.writeText(url);
          Swal.fire({
            title: "¡Copiado al portapapeles!",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        });
        const style = document.createElement("style");
        style.innerHTML = `
        .swal2-close {
          display: none; /* Ocultar el botón de cierre por defecto */
        }
        .swal2-container .custom-close-button-container {
          position: absolute;
          top: 5px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
          color: #000;
        }
        .swal2-container .custom-close-button-container i {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      `;
        document.head.appendChild(style);

        // Agregar el icono de cierre personalizado
        const closeButton = document.createElement("div");
        closeButton.classList.add("custom-close-button-container");
        closeButton.innerHTML = '<i class="fas fa-times"></i>'; // Icono de FontAwesome
        closeButton.addEventListener("click", () => Swal.close());
        document.querySelector(".swal2-popup").appendChild(closeButton);
        if (accountTemplate !== "default" && !isDefaultByAccount) {
          await dispatch(deleteTemplate(idTemplate, account));
          dispatch(
            insertLogTemplate(
              "deleteTemplate",
              3,
              "TEMPLATES",
              "Eliminacion de plantilla",
              { idTemplate: idTemplate },
              {},
              account,
              "Lets-Advertise",
              userdata.attributes.email
            )
          );
        }
        dispatch(getTemplates(account, ""));
      } catch (error) {
        if (error === "The user is not authenticated") {
          dispatch(
            sendDeployCampaignLogout(visualizers, account, accounts, idTemplate)
          );
        }
      } finally {
        dispatch(showLoader(false));
      }
    };

/*****************************************
 * * Funciones de plantillas(crud) *
 *****************************************/

export const getTemplates = (account, id) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    dispatch(clearTemplates());
    dispatch(clearEdit());
    dispatch(setEditActivate(false));

    const getTemplatesByAccount = await APILA.myTemplates({ account: account });
    const data = getTemplatesByAccount.data.templates;
    const newData = await Promise.all(
      data.map(async (item) => {
        const previsualizer = item.previsualizer;
        const promises = previsualizer.map(async (ele) => {
          if (ele.id_region) {
            const response = await APILA.getMediumBanner({
              id_region: ele.id_region,
            });
            ele.optionsBanner = response.data.data;
          }
          if (ele.id_region && ele.id_medium) {
            const request = await APILA.getMediumBanner({
              id_medium: ele.id_medium,
              id_region: ele.id_region,
            });
            ele.optionsObject = request.data.data;
          }
          if (ele.id_medium && ele.id_banner && ele.id_region) {
            const request = await APILA.getFormatData({
              id_medium: ele.id_medium,
              id_region: ele.id_region,
              id_banner: ele.id_banner,
            });
            ele.optionsFormat = request.data.data;
          }
        });

        await Promise.all(promises); // Wait for all nested promises in the previsualizer
        return item;
      })
    );
    if (id && id !== "") {
      const findEdit = newData.find((ele) => ele.id === id);
      dispatch(setEdit(findEdit));
    }
    dispatch(
      insertLogTemplate(
        "getTemplates",
        3,
        "TEMPLATES",
        "Obtener plantillas",
        { account, id },
        newData,
        account,
        "Lets-Advertise",
        "userlets"
      )
    );
    dispatch(addTotalTemplate(newData));
  } catch (error) {
    console.log("Error", error);
  } finally {
    dispatch(showLoader(false));
  }
};

export const upsertTemplate =
  (body, account, name, setOption, id, history, newTemplateCopy) =>
    async (dispatch) => {
      try {
        dispatch(showLoader(true));
        const user = await getuser();
        const categories = await getCategories(account);
        const previsualizer = mappingDataFormat(body, account, user, categories);
        const newPrevisualizer = await matchData(account, previsualizer);

        const sendTemplate = async () => {
          let nameTemplate = name;
          if (!newTemplateCopy) {
            return Swal.fire({
              title: "¿Desea cambiar el nombre de la plantilla?",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              inputValue: nameTemplate,
              showCancelButton: true,
              confirmButtonText: "Confirmar",
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              bottonInvert: true,
              preConfirm: (nameInput) => {
                if (!nameInput) {
                  Swal.showValidationMessage("El nombre no puede ser vacio.");
                } else {
                  nameTemplate = nameInput;
                }
              },
            }).then(async (result) => {
              const bodyTemplate = {
                previsualizer: newPrevisualizer.responseData,
                account: account,
                id: id === "" ? v4() : id,
                name: nameTemplate,
                isDefaultByAccount: false,
              };
              await APILA.upsertTemplate(bodyTemplate);
              setOption(bodyTemplate.id);
              dispatch(getTemplates(account, bodyTemplate.id));
              dispatch(
                Modal.showAlertModal(
                  "!Plantilla cargada!",
                  "Plantilla cargada correctamente.",
                  Modal.MODAL_TYPE_SUCCESS,
                  () => { }
                )
              );
              dispatch(
                insertLogTemplate(
                  "create template",
                  3,
                  "TEMPLATES",
                  "Plantilla actualizada",
                  bodyTemplate,
                  {},
                  account,
                  "Lets-Advertise",
                  ""
                )
              );
              dispatch(generateLink(account, history, 1, bodyTemplate.id, ""));
            });
          } else {
            const bodyTemplate = {
              previsualizer: newPrevisualizer.responseData,
              account: account,
              id: id === "" ? v4() : id,
              name: nameTemplate,
              isDefaultByAccount: false,
            };
            await APILA.upsertTemplate(bodyTemplate);
            setOption(bodyTemplate.id);

            dispatch(getTemplates(account, bodyTemplate.id));
            dispatch(
              Modal.showAlertModal(
                "!Plantilla cargada!",
                "Plantilla cargada correctamente.",
                Modal.MODAL_TYPE_SUCCESS,
                () => { }
              )
            );
            dispatch(
              insertLogTemplate(
                "upsertTemplate copy",
                3,
                "TEMPLATES",
                "Plantilla actualizada",
                bodyTemplate,
                {},
                account,
                "Lets-Advertise",
                ""
              )
            );

            dispatch(generateLink(account, history, 1, bodyTemplate.id, ""));
          }
        };
        if (
          newPrevisualizer.responseData.length === 0 ||
          newPrevisualizer.exception
        ) {
          dispatch(
            Modal.showAlertModal(
              "¡Plantilla incompleta!",
              "A tu documento le hace falta información. ¿Deseas continuar así?",
              Modal.MODAL_TYPE_CONFIRMATION,
              () => sendTemplate()
            )
          );
        } else {
          sendTemplate();
        }
      } catch (error) {
        dispatch(
          insertLogTemplate(
            "upsertTemplate error",
            3,
            "TEMPLATES",
            "Plantilla actualizada",
            body,
            { error: error },
            account,
            "Lets-Advertise",
            ""
          )
        );
        console.log("Hubo un error", error);
      } finally {
        dispatch(showLoader(false));
      }
    };
export const updateTemplateVisualizer =
  (template, data) => async (dispatch) => {
    //Actualiza la plantilla completa
    try {
      dispatch(showLoader(true));
      const user = await getuser();
      const categories = await getCategories(template.account);

      const previsualizer = mappingDataFormat(
        data,
        template.account,
        user,
        categories
      );

      const newPrevisualizer = await matchData(template.account, previsualizer);

      const sendTemplate = async () => {
        const dataUpdate = {
          ...template,
          previsualizer: newPrevisualizer.responseData,
        };
        await APILA.upsertTemplate(dataUpdate);
        await dispatch(getTemplates(template.account, template.id));
        dispatch(
          insertLogTemplate(
            "updateTemplateVisualizer",
            3,
            "TEMPLATES",
            "Plantilla actualizada",
            dataUpdate,
            {},
            template.account,
            "Lets-Advertise",
            ""
          )
        );
        dispatch(
          Modal.showAlertModal(
            "Plantilla actualizada!",
            "Plantilla actualizada correctamente.",
            Modal.MODAL_TYPE_SUCCESS,
            () => { }
          )
        );
      };
      if (!newPrevisualizer.exception) {
        sendTemplate();
      } else {
        dispatch(
          Modal.showAlertModal(
            "Plantilla incompleta!",
            "A esta plantilla le falta informacion",
            Modal.MODAL_TYPE_CONFIRMATION,
            () => sendTemplate()
          )
        );
      }
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      dispatch(showLoader(false));
    }
  };
export const updateTemplateDynamo = (template, data) => async (dispatch) => {
  try {
    //Actualiza la plantilla completa y quita el boton de edicion
    dispatch(showLoader(true));
    const user = await getuser();
    const categories = await getCategories(template.account);
    const previsualizer = mappingDataFormat(
      data,
      template.account,
      user,
      categories
    );
    const newPrevisualizer = await matchData(template.account, previsualizer);

    const sendTemplate = async () => {
      const dataUpdate = {
        ...template,
        previsualizer: newPrevisualizer.responseData,
      };
      await APILA.upsertTemplate(dataUpdate);
      await dispatch(getTemplates(template.account, template.id));
      dispatch(
        Modal.showAlertModal(
          "¡Plantilla actualizada!",
          "Plantilla actualizada correctamente.",
          Modal.MODAL_TYPE_SUCCESS,
          () => { }
        )
      );
      dispatch(setEditActivate(false));
    };
    dispatch(
      insertLogTemplate(
        "create template",
        3,
        "TEMPLATES",
        "Plantilla actualizada",
        template,
        {},
        template.account,
        "Lets-Advertise",
        ""
      )
    );
    // if (!newPrevisualizer.exception) {
    sendTemplate();
    // } else {
    // dispatch(
    //   Modal.showAlertModal(
    //     "Plantilla incompleta!",
    //     "A esta plantilla le falta informacion",
    //     Modal.MODAL_TYPE_CONFIRMATION,
    //     () => sendTemplate()
    //   )
    // );
    // }
  } catch (error) {
    console.log("ERROR", error);
  } finally {
    dispatch(showLoader(false));
  }
};

export const newTemplateForAccount =
  (data, account, setOption, id, history, newTemplate) => async (dispatch) => {
    // ! Esta funcion crea copias de las plantillas
    try {
      dispatch(showLoader(true));
      const previsualizer = data.previsualizer;
      previsualizer.forEach((ele) => {
        ele.account = account;
      });
      dispatch(setEditActivate(false));

      await dispatch(
        upsertTemplate(
          previsualizer,
          account,
          data.name,
          setOption,
          id,
          history,
          newTemplate
        )
      );
      dispatch(
        insertLogTemplate(
          "newTemplateForAccount",
          3,
          "TEMPLATES",
          "Plantilla actualizada",
          data,
          {},
          account,
          "Lets-Advertise",
          ""
        )
      );
    } catch (error) {
    } finally {
      dispatch(showLoader(false));
    }
  };

/*****************************************
 * * Funciones de visualizers (campaigns de las plantillas) *
 *****************************************/

export const removeVIsualizer = (template, id) => async (dispatch) => {
  dispatch(clearEdit());
  let previsualizer = template.previsualizer;
  const visualizerFilter = previsualizer.filter((ele) => ele.id !== id);
  const newFormatTemplate = {
    ...template,
    previsualizer: visualizerFilter,
  };
  dispatch(updateTemplate(newFormatTemplate));
  dispatch(setEdit(newFormatTemplate));
  dispatch(setEditActivate(true));
};
export const upsertVisualizer = (templates, visualizer) => async (dispatch) => {
  console.log("Visualizer", visualizer);
  console.log("templates", templates);
  let previsualizer = templates.previsualizer;
  const idx = previsualizer.findIndex((ele) => ele.id === visualizer.id);
  previsualizer[idx] = { ...visualizer };
  const newFormatTemplate = {
    ...templates,
    previsualizer,
  };
  dispatch(updateTemplate(newFormatTemplate));
  dispatch(clearEditVisualizer());
  dispatch(setEditActivate(true));
  return newFormatTemplate;
};
export const addOneVisualizer = (template, visualizer) => async (dispatch) => {
  let previsualizer = template.previsualizer;
  previsualizer.push(visualizer);
  const newFormatTemplate = {
    ...template,
    previsualizer,
  };
  dispatch(setEditActivate(true));
  // dispatch(
  //   Modal.showAlertModal(
  //     "Agregada correctamente!",
  //     "Plantilla actualizada correctamente.",
  //     Modal.MODAL_TYPE_SUCCESS,
  //     () => {}
  //   )
  // );
  // dispatch(setEditActivate(true));
  return newFormatTemplate;
};
/*****************************************
 * * Funcion de insercion de logs *
 *****************************************/

async function insertLog(account, action, request, response, user, obs) {
  let idlog = v4();
  let now = Date.now();
  let logRequest = {
    id: idlog,
    source: "Lets-Advertise",
    timestamp: now,
    action: action,
    user: user,
    role: account,
    idmodulo: 3,
    modulo: "TEMPLATES",
    request: request,
    response: response,
    observation: obs,
  };
  await APILA.insertLogLine(logRequest);
}

export const insertLogTemplate = (
  action,
  idmodulo,
  modulo,
  observation,
  request,
  response,
  role,
  source,
  user
) => {
  return async (dispatch) => {
    const body = {
      id: v4(),
      action: action,
      idmodulo: idmodulo,
      modulo: modulo,
      observation: observation,
      request: request,
      response: response,
      role: role,
      source: source,
      timestamp: Date.now(),
      user: user,
    };
    await APILA.createLogTemplate({ log: body });
    dispatch({ type: "LOG_TEMPLATE_INSERTED", payload: body });
  };
};
/*****************************************
 * * Funciones de formato de datos *
 *****************************************/

export const validateFormatFile = (file) => (dispatch) => {
  let validator = false;
  let message = "";
  const valuesNumeric = ["budget", "id_sale"];
  const valuesDates = ["fechaIni", "fechaFin"];

  file.forEach((element) => {
    const keys = Object.keys(element);
    const allKeysExists = _.every(formatKeys, (key) => keys.includes(key));

    keys.forEach((ele) => {
      if (valuesNumeric.includes(ele)) {
        if (isNaN(element[ele])) {
          validator = true;
          if (message === "") {
            message = "Algunos valores no cumplen el formato correcto";
          }
        }
      }
      if (valuesDates.includes(ele)) {
        if (!validarFormatoFecha(element[ele])) {
          validator = true;
          if (message === "") {
            message = `El formato de las fechas no es correcto. Nombre de campaña: ${element.name}`;
          }
        }
      }
    });
    if (!allKeysExists) {
      validator = true;
    }
  });
  return { validator, message };
};
function convertirFormato(fecha) {
  const partes = fecha.split("-");
  const fechaConvertida = `${partes[2]}/${partes[1]}/${partes[0]}`;
  return fechaConvertida;
}
const mappingDataFormat = (body, account, user, categories) => {
  const categoriesActivePadre = categories.filter(
    (item) =>
      item.active &&
      (item.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
        item.padre === "")
  );

  const categoriesComplete = categories.filter((cate) => cate.active);

  const sortedActiveCategories = _.orderBy(
    categoriesActivePadre,
    ["name"],
    ["asc"]
  );

  const namesCategories = sortedActiveCategories.map((ele) => ele.name);

  const previsualizer = body.map((ele) => {
    let categoriesSelected = [];
    const keys = Object.keys(ele);

    keys.forEach((key) => {
      if (namesCategories.includes(key)) {
        const values = ele[key].split(",") || [];
        values.forEach((value) => {
          const foundCategorie = categoriesComplete.find(
            (cate) => cate.name === value
          );
          const foundCategoriePadre = sortedActiveCategories.find(
            (cate) => cate.name === key
          );
          if (foundCategorie && foundCategoriePadre) {
            const categoria = {
              label: foundCategorie?.name ?? "",
              nombrePadre: foundCategoriePadre?.name ?? "",
              padre: foundCategoriePadre?.id ?? "",
              value: foundCategorie?.id ?? "",
            };
            categoriesSelected.push(categoria);
          }
        });
      }
    });

    if (
      ele?.categoriesSelected !== undefined &&
      ele?.categoriesSelected?.length !== 0
    ) {
      categoriesSelected.push(...ele.categoriesSelected);
    }
    console.log("DATA EN FECHAS", ele);
    return {
      id: v4(),
      name: ele?.name ?? "",
      medio: ele?.medio ?? "",
      fechaIni: ele?.fechaIni ?? "",
      fechaFin: ele?.fechaFin ?? "",
      objetivo: ele?.objetivo ?? "",
      banner: ele?.objetivo ?? "",
      account: account,
      budget: ele?.budget ?? 0,
      id_format: ele?.id_format ?? 0,
      id_medium: ele?.id_medium ?? 0,
      id_ad_product: ele?.id_ad_product ?? 0,
      idBudget: ele?.idBudget ?? "",
      creativity: ele?.creativity ?? [],
      publico: ele?.publico ?? [],
      id_sale: ele?.id_sale ?? 0,
      observation: ele?.observation ?? "",
      copy: ele?.copy ?? "",
      urls: ele?.urls ?? "",
      id_region: ele?.id_region ?? 0,
      budget_name: ele?.budget_name ?? "",
      audience_name: ele?.audience_name ?? "",
      id_banner: ele?.id_banner ?? 0,
      version: ele?.version ?? "",
      priority: ele?.priority ?? 3,
      estado: 1,
      categoriesSelected: categoriesSelected ?? [],
      name_region: ele?.name_region ?? "",
      idAudience: ele?.idAudience ?? "",
      formato: ele?.formato ?? "",
      nameForEmail: ele?.nameForEmail ?? user?.name ?? "",
      emailForEmail: ele?.emailForEmail ?? user?.email ?? "",
    };
  });
  return previsualizer;
};
const matchData = async (account, data) => {
  let exception = false;

  const budgetsIds = data.filter((ele) => ele.budget_name);
  const uniqueBudgetNames = _.uniqBy(budgetsIds, "budget_name").map(
    (ele) => ele.budget_name
  );

  const audiencesId = data.filter((ele) => ele.audience_name);
  const uniqueAudiencesNames = _.uniqBy(audiencesId, "audience_name").map(
    (ele) => ele.audience_name
  );

  const medioNames = data.filter((ele) => ele.medio);
  const uniqueMedioNames = _.uniqBy(medioNames, "medio").map(
    (ele) => `'${ele.medio}'`
  );

  const regionNames = data.filter((ele) => ele.name_region);
  const uniqueRegionNames = _.uniqBy(regionNames, "name_region").map(
    (ele) => `'${ele.name_region}'`
  );

  const bannerNames = data.filter((ele) => ele.banner);
  const uniquebannerNames = _.uniqBy(bannerNames, "banner").map(
    (ele) => `'${ele.banner}'`
  );

  const formatNames = data.filter((ele) => ele.formato);
  const uniqueformatNames = _.uniqBy(formatNames, "formato").map(
    (ele) => `'${ele.formato}'`
  );

  const datesTotalFormat = data.reduce((dates, ele) => {
    if (ele.fechaIni) dates.push(ele.fechaIni);
    if (ele.fechaFin) dates.push(ele.fechaFin);
    return dates;
  }, []);

  const promises = [
    findBudgetByNames(account, uniqueBudgetNames),
    findMediosByNames(uniqueMedioNames),
    findBannerCounter(uniquebannerNames),
    findRegionCounter(uniqueRegionNames),
    findFormatCounter(uniqueformatNames),
    findAudiencesByIds(account, uniqueAudiencesNames),
  ];

  const [
    responseBudgets,
    responseMedios,
    responseBanner,
    responseRegion,
    responseFormat,
    responseAudiences,
  ] = await Promise.all(promises);

  const responseData = data.map((ele) => {
    const budgetFound = responseBudgets.find(
      (budget) => budget.title === ele.budget_name
    );
    const mediumFound = responseMedios.find(
      (medium) => medium.site === ele.medio
    );
    const regionFound = responseRegion.find(
      (region) => region.name === ele.name_region
    );
    const bannerFound = responseBanner.find(
      (banner) => banner.banner === ele.banner
    );
    const formatFound = responseFormat.find(
      (format) => format.art === ele.formato
    );

    const audienceFound = responseAudiences.find(
      (audience) => audience.name === ele.audience_name
    );

    const exceptionIni = validarFormatoFecha(ele.fechaIni);
    const exceptionFin = validarFormatoFecha(ele.fechaFin);
    if (
      !budgetFound ||
      !mediumFound ||
      !regionFound ||
      !bannerFound ||
      !formatFound ||
      !exceptionIni ||
      !exceptionFin ||
      !audienceFound
    ) {
      exception = true;
    }
    let productValidate = false;
    if (!mediumFound || !regionFound || !bannerFound || !formatFound) {
      productValidate = true;
      insertLogTemplate(
        "matchData",
        3,
        "TEMPLATES",
        "Producto incompleto",
        {
          budgetFound,
          mediumFound,
          regionFound,
          bannerFound,
          formatFound,
          exceptionIni,
          exceptionFin,
          audienceFound,
          data: ele,
        },
        {},
        account,
        "Lets-Advertise",
        ""
      );
    }
    console.log(
      // "budgetFound",
      // budgetFound,
      // "mediumFound",
      // mediumFound,
      // "regionFound",
      // regionFound,
      // "bannerFound",
      // bannerFound,
      "formatFound",
      { formatFound, responseFormat }
      // "exceptionIni",
      // exceptionIni,
      // "exceptionFin",
      // exceptionFin,
      // "audienceFound",
      // audienceFound,
      // "productValidate",
      // productValidate
    );
    return {
      ...ele,
      id_region: !productValidate ? regionFound?.id_region : 0,
      name_region: !productValidate ? regionFound?.name : "",
      id_medium: !productValidate ? mediumFound?.id_medium : 0,
      medio: !productValidate ? mediumFound?.site : "",
      id_banner: !productValidate ? bannerFound?.id_banner : 0,
      banner: !productValidate ? bannerFound?.banner : "",
      objetivo: !productValidate ? bannerFound?.banner : "",
      id_format: !productValidate ? formatFound?.id_format : 0,
      formato: !productValidate ? formatFound?.art : "",
      idAudience: !audienceFound ? "" : audienceFound?.id,
      audience_name: !audienceFound ? "" : audienceFound?.name,
      idBudget: !budgetFound ? "" : budgetFound?.id,
      budget_name: !budgetFound ? "" : budgetFound?.title,
      fechaIni: !exceptionIni ? "" : ele.fechaIni,
      fechaFin: !exceptionFin ? "" : ele.fechaFin,
    };
  });

  return { exception, responseData };
};

/**
 * Validates the product data and sets appropriate errors if any issues are found.
 *
 * @param {Array} data - The data to be validated.
 * @param {Array} budgets - List of available budgets.
 * @param {Object} account - Account information.
 * @param {Object} edit - Edit information to be updated.
 *
 * @returns {Object} Result containing the mapped data response, exception status, and message.
 */
const validateProduct = (data, budgets, account, edit) => async (dispatch) => {
  let exception = false;
  let messageException = "";

  // Listado de casos de error en el producto
  const casesErrorProduct = {
    caseComplete: "producto completo",
    case2: "producto incompleto (region, medio, banner)",
    case3: "producto incompleto (region, medio)",
    case4: "producto incompleto (region)",
  };

  try {
    console.log("Inicio de validateProduct", { data, budgets, account, edit });

    const audiences = _.get(
      await API.getAudiences(account),
      "data.audiences",
      []
    );
    console.log("Audiencias obtenidas", { audiences });

    const mappingData = data.map(
      ({ id, id_banner, id_format, id_medium, id_region }) => ({
        id,
        id_banner,
        id_format,
        id_medium,
        id_region,
      })
    );
    console.log("Datos mapeados", { mappingData });

    const uniqueCombinations = _.uniqWith(
      mappingData,
      (a, b) =>
        a.id_banner === b.id_banner &&
        a.id_format === b.id_format &&
        a.id_medium === b.id_medium &&
        a.id_region === b.id_region
    );
    console.log("Combinaciones únicas", { uniqueCombinations });

    const promises = uniqueCombinations.map(
      ({ id_region, id_medium, id_banner, id_format }) =>
        findProduct(id_region, id_medium, id_banner, id_format)
    );

    const response = (await Promise.all(promises)).flat();
    console.log("Respuesta de productos encontrados", { response });

    const mappDataResponse = data.map((ele) => {
      const foundProduct = response.find(
        (product) =>
          product.id_region === ele.id_region &&
          product.id_medium === ele.id_medium &&
          product.id_banner === ele.id_banner &&
          product.id_format === ele.id_format
      );

      const foundAudience = audiences.find((aud) => aud.id === ele?.idAudience);
      const foundBudget = budgets.find((budget) => budget.id === ele?.idBudget);

      const filterProductConflict = data.filter(
        (product) =>
          !response.some(
            (ids) =>
              product.id_region === ids.id_region &&
              product.id_medium === ids.id_medium &&
              product.id_banner === ids.id_banner &&
              product.id_format === ids.id_format
          )
      );
      dispatch(setErrorListTemplateProduct(filterProductConflict));
      console.log("Conflictos de producto filtrados", {
        filterProductConflict,
      });

      const filterConflictAudience = data.filter(
        (ele) => !audiences.some((audience) => audience.id === ele.idAudience)
      );
      dispatch(setErrorListTemplateAudience(filterConflictAudience));
      console.log("Conflictos de audiencia filtrados", {
        filterConflictAudience,
      });

      const filterConflictBudget = data.filter(
        (ele) => !budgets.some((budget) => budget.id === ele.idBudget)
      );
      dispatch(setErrorListTemplateBudget(filterConflictBudget));
      console.log("Conflictos de presupuesto filtrados", {
        filterConflictBudget,
      });

      let errorMessages = [];

      // Verifica los diferentes casos de error y acumula los mensajes
      if (!foundProduct) {
        errorMessages.push("productos");
      }
      if (!foundAudience) {
        errorMessages.push("públicos");
      }
      if (!foundBudget) {
        errorMessages.push("presupuestos");
      }

      // Si hay errores, establece la excepción y crea el mensaje de error acumulado
      if (errorMessages.length > 0) {
        exception = true;
        messageException = `Algunas combinaciones no coinciden con los ${errorMessages.join(
          ", "
        )} existentes. ¡Revisa y ajusta las combinaciones! Nombre de campaña: ${ele.name
          }`;
        dispatch(
          insertLogTemplate(
            "validateProduct",
            3,
            "TEMPLATES",
            "Producto no encontrado",
            {
              foundProduct,
              foundAudience,
              foundBudget,
              errorMessages,
              ele,
            },
            {},
            account,
            "Lets-Advertise",
            ""
          )
        );
      }

      return { ...ele, id_ad_product: foundProduct?.id_ad_product ?? 0 };
    });

    // Encuentra combinaciones no encontradas
    const notFoundCombinations = data.filter((ele) => {
      const foundProduct = response.find(
        (product) =>
          product.id_region === ele.id_region &&
          product.id_medium === ele.id_medium &&
          product.id_banner === ele.id_banner &&
          product.id_format === ele.id_format
      );
      return !foundProduct;
    });

    const dataFinalProducts = [];

    for (let ele of notFoundCombinations) {
      const findProduct = await findProductPartial(
        ele.id_region,
        ele.id_medium,
        ele.id_banner,
        ele.id_format
      );
      console.log("Producto encontrado parcial", { findProduct });
      if (findProduct.length !== 0) {
        const product = findProduct[0];
        if (product.match_type === "CaseComplete") {
          console.log("Producto completo", { product });
        } else if (product.match_type === "Case2") {
          console.log("Producto incompleto (región, medio, banner)", {
            product,
          });
          ele = {
            ...ele,
            id_format: null,
            formato: null,
          };
          exception = true;
          messageException = `El producto no es válido debido a un formato incorrecto. ¡Revisa y ajusta las combinaciones! Nombre de campaña: ${ele.name}`;
        } else if (product.match_type === "Case3") {
          console.log("Producto incompleto (region, medio)", { product });
          ele = {
            ...ele,
            banner: null,
            formato: null,
            id_banner: null,
            id_format: null,
          };
          exception = true;
          messageException = `El producto no es válido debido a un objetivo incorrecto. ¡Revisa y ajusta las combinaciones! Nombre de campaña: ${ele.name}`;
        } else if (product.match_type === "Case4") {
          console.log("Producto incompleto (region)", { product });
          ele = {
            ...ele,
            medio: null,
            formato: null,
            banner: null,
          };
          exception = true;
          messageException = `El producto no es válido debido a un medio incorrecto. ¡Revisa y ajusta las combinaciones! Nombre de campaña: ${ele.name}`;
        }
      }
      dataFinalProducts.push(ele);
    }
    const combinedData = {};
    mappDataResponse.forEach((item) => {
      combinedData[item.id] = item;
    });
    dataFinalProducts.forEach((item) => {
      combinedData[item.id] = item;
    });

    // Convertir el objeto de vuelta a un array
    const finalResponse = Object.values(combinedData);
    console.log("Respuesta final combinada", { finalResponse });
    dispatch(setEdit({ ...edit, previsualizer: finalResponse }));

    return { mappDataResponse: finalResponse, exception, messageException };
  } catch (error) {
    console.error("Error en validateProduct", { error });
    dispatch(
      insertLogTemplate(
        "validateProduct error",
        3,
        "TEMPLATES",
        "Error en la validación del producto",
        { error },
        {},
        account,
        "Lets-Advertise",
        ""
      )
    );
    throw error;
  } finally {
    console.log("Finalización de validateProduct");
  }
};

function validarFormatoFecha(fecha) {
  const patronFecha = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

  return patronFecha.test(fecha);
}
/**
 * Valida que la fecha de inicio no sea menor a la fecha actual y devuelve la fecha actual en formato "DD.MM.YYYY" si es menor.
 * @param {string} date - La fecha de inicio en formato de cadena con formato "DD.MM.YYYY".
 * @returns {string|null} - La fecha actual en formato "DD.MM.YYYY" si la fecha de inicio es menor que la fecha actual; de lo contrario, devuelve la fecha de inicio proporcionada.
 */
const validateDateStart = (date) => {
  // Divide la cadena de fecha en día, mes y año
  const [day, month, year] = date.split(".");

  // Crea un objeto Date para la fecha de inicio
  const dateStart = new Date(`${month}/${day}/${year}`);
  const dateNow = new Date();

  // Compara las fechas para verificar si la fecha de inicio es menor que la fecha actual
  if (dateStart < dateNow) {
    // Formatea la fecha actual en formato "DD.MM.YYYY"
    const dateFormat = `${dateNow.getDate()}.${dateNow.getMonth() + 1
      }.${dateNow.getFullYear()}`;
    return dateFormat;
  } else {
    // Devuelve la fecha de inicio proporcionada si es mayor o igual a la fecha actual
    return date;
  }
};

/*****************************************
 * * Funciones que consultan datos a eadvertising *
 *****************************************/

const findBudgetByNames = async (account, nameBudgets) => {
  const response = await APILA.findBudgetByName({
    account,
    titles: nameBudgets,
  });
  return response.data.budget;
};
const findAudiencesByIds = async (account, nameBudgets) => {
  const response = await APILA.findAudiencesByName({
    account,
    titles: nameBudgets,
  });
  return response.data.audiences;
};
const findMediosByNames = async (medioNames) => {
  const response = await APILA.findMediaCounter({
    medios: medioNames,
  });
  //Ordena la respuesta para que salgan primero los que tiene id_ad_status en valor 1
  const responseOrder = _.orderBy(
    response.data.response,
    ["id_ad_status"],
    ["asc"]
  );
  return responseOrder;
};
const findBannerCounter = async (banner) => {
  const response = await APILA.findBannerCounter({
    banner: banner,
  });
  const responseOrder = _.orderBy(
    response.data.response,
    ["id_ad_status"],
    ["asc"]
  );
  return responseOrder;
};
const findRegionCounter = async (medioNames) => {
  const response = await APILA.findRegionCounter({
    region: medioNames,
  });
  const responseOrder = _.orderBy(
    response.data.response,
    ["id_ad_status"],
    ["asc"]
  );
  return responseOrder;
};
const findFormatCounter = async (format) => {
  const response = await APILA.findFormatCounter({
    format: format,
  });
  const responseOrder = _.orderBy(
    response.data.response,
    ["id_ad_status"],
    ["asc"]
  );
  return responseOrder;
};
const findProduct = async (id_region, id_medium, id_banner, id_format) => {
  const response = await APILA.findProduct({
    id_region,
    id_medium,
    id_banner,
    id_format,
  });
  return response.data.response;
};

const findProductPartial = async (
  id_region,
  id_medium,
  id_banner,
  id_format
) => {
  const response = await APILA.findProductPartial({
    id_region,
    id_medium,
    id_banner,
    id_format,
  });
  return response.data.response;
};

const getuser = async () => {
  try {
    const userResponse = await Auth.currentAuthenticatedUser();
    const {
      accessToken: { jwtToken: token },
    } = userResponse.signInUserSession;
    const responseProfileInfo = await API.getProfileInfo(token);

    const attributes = responseProfileInfo?.data?.data?.UserAttributes || [];
    const nameAttribute = attributes.find((attr) => attr?.Name === "name");
    const emailAttribute = attributes.find((attr) => attr?.Name === "email");

    const user = {
      name: nameAttribute?.Value || "",
      email: emailAttribute?.Value || "",
    };

    return user;
  } catch (error) {
    if (error === "The user is not authenticated") {
      const user = {
        name: "logout",
        email: "logout",
      };
      return user;
    }
  }
};

const getCategories = async (account) => {
  try {
    const findCategoriesByAccount = _.get(
      await API.getCategories(account),
      "data.categories",
      []
    );

    return findCategoriesByAccount;
  } catch (error) {
    console.log("Ocurrio un error inesperado", error);
    return [];
  }
};
/******************************************
 * * Funciones para ediciones a doble clic *
 ******************************************/
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function myRound(num, dec) {
  var exp = Math.pow(10, dec || 2);
  return parseInt(num * exp, 10) / exp;
}

export const updateNameTemplate =
  (nameTemplate, propsEdit, dataUser) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      let newTemplate = { ...propsEdit, name: nameTemplate };
      dispatch(upsertVisualizer(newTemplate, newTemplate.previsualizer));
      dispatch(setEdit(newTemplate));
    } catch (error) {
      console.log("Error en la edicion del nombre de la plantilla", { error });
    } finally {
      dispatch(showLoader(false));
    }
  };

export const deleteTemplate =
  (idTemplate, currentAccount) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      await APILA.deleteTemplate({ id: idTemplate });
      dispatch(getTemplates(currentAccount, ""));
    } catch (error) {
      console.log("Error en la edicion del nombre de la plantilla", { error });
    } finally {
      dispatch(showLoader(false));
    }
  };

export const refreshBudgets = (currentAccount) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    const responseBudgets = await API.getBudgets(currentAccount);
    const budgetsData = responseBudgets.data.budgets;

    const filteredBudgets = budgetsData.map((budget) => {
      const available = numberWithCommas(myRound(parseFloat(budget.available)));
      const amount = numberWithCommas(parseFloat(budget.amount).toFixed(2));
      const label = `${budget.title}: $${amount} (Disponible: $${available})`;

      return {
        name: budget.title,
        value: budget.id,
        label,
        isDisabled: budget.budgetOld,
        available: budget.available,
        active: budget.active,
        status: budget.status,
      };
    });

    const newFilteredBudgets = filteredBudgets.filter(
      (item) =>
        item.isDisabled === false &&
        item.active &&
        item.status === 1 &&
        parseFloat(parseFloat(item.available).toFixed(2)) > 0.0
    );

    const newSortedBudgets = _.orderBy(
      newFilteredBudgets,
      ["isDisabled"],
      ["desc"]
    );

    dispatch(setFilteredBudgets(newSortedBudgets));

    const filterBudgets = budgetsData.filter(
      (budget) =>
        budget.active &&
        budget.status === 1 &&
        parseFloat(parseFloat(budget.available).toFixed(2)) > 0.0
    );
    const formattedBudgets = filterBudgets.map((budget) => {
      const available = numberWithCommas(myRound(parseFloat(budget.available)));
      const amount = numberWithCommas(parseFloat(budget.amount).toFixed(2));
      const label = `${budget.title}: $${amount} (Disponible: $${available})`;

      return {
        name: budget.title,
        value: budget.id,
        label,
        isDisabled: budget.budgetOld ? true : false,
      };
    });
    const sortedBudgets = _.orderBy(formattedBudgets, ["isDisabled"], ["desc"]);
    dispatch(setListRefreshBudgets(sortedBudgets));
  } catch (error) {
    console.log("Error en el refresh de los presupuestos", error);
  } finally {
    dispatch(showLoader(false));
  }
};

export const updateTemplateDoubleClic = (editTempalte) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    await APILA.upsertTemplate(editTempalte);
    await dispatch(getTemplates(editTempalte.account, editTempalte.id));
    dispatch(
      Modal.showAlertModal(
        "Pauta actualizada!",
        "Pauta actualizada correctamente.",
        Modal.MODAL_TYPE_SUCCESS,
        () => { }
      )
    );
  } catch (error) {
    console.log("Error en la edicion a doble clic", error);
    dispatch(
      Modal.showAlertModal(
        "Ups!",
        "Hubo un error en la actualizacion",
        Modal.MODAL_TYPE_ERROR,
        () => { }
      )
    );
  } finally {
    dispatch(showLoader(false));
  }
};

export const asyncGetNameRegion = () => async (dispatch) => {
  try {
    // dispatch(showLoader(true));
    const data = _.get(await APILA.getContryes(), "data.pautas", "[]");
    dispatch(setRegions(data));
    return data;
  } catch (error) {
    console.log("Error obteniendo las regiones", error);
  } finally {
    // dispatch(showLoader(false));
  }
};

export const editData =
  (valueForEdit, propsEdit, dataUser, budgets) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));
      let { id, column, value } = valueForEdit[0];
      if (id.campo === "budgetI") {
        let newValue = value.includes("$") ? value.split("$")[1] : value;
        // * validanos que venga la edicion de inversion y que sea un valor numerico
        if (!/^\d+(\.\d+)?$/.test(newValue)) {
          dispatch(setEditField(true));
          return Swal.fire({
            title: "Error en el valor",
            text: "Ingresa un valor numerico",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            reverseButtons: true,
          });
        } else {
          let valueInv = Number(newValue).toFixed(2) || 0;
          let typeValue = Number(valueInv);
          let editValuePrevisualizer = {
            ...column,
            budget: typeValue,
            nameForEmail: dataUser?.name,
            emailForEmail: dataUser?.email,
          };
          let replace = propsEdit?.previsualizer.map((template) => {
            if (template.id === editValuePrevisualizer.id) {
              return editValuePrevisualizer;
            } else {
              template.nameForEmail = dataUser?.name;
              template.emailForEmail = dataUser?.email;
              return template;
            }
          });
          let editCompleted = { ...propsEdit, previsualizer: replace };
          await dispatch(
            alertDoubleClic(
              editCompleted,
              0,
              {},
              propsEdit,
              editValuePrevisualizer
            )
          );
        }
      } else {
        let mapEditValue = {
          nameP: {
            campo: "name",
            value: value || "",
          },
          budget_name: {
            campo: "budget_name",
            value: value || "",
            campo2: "idBudget",
          },
        };
        let campoEdit = mapEditValue[id.campo];
        let editValuePrevisualizer;
        if (id.campo === "budget_name") {
          let findBudget = budgets.find((budget) => budget.value === value);
          editValuePrevisualizer = {
            ...column,
            [campoEdit.campo]: findBudget.name,
            [campoEdit.campo2]: findBudget.value,
            nameForEmail: dataUser?.name,
            emailForEmail: dataUser?.email,
          };
          let replace = propsEdit?.previsualizer.map((template) => {
            if (template.id === editValuePrevisualizer.id) {
              return editValuePrevisualizer;
            } else {
              template.nameForEmail = dataUser?.name;
              template.emailForEmail = dataUser?.email;
              return template;
            }
          });
          let editCompleted = { ...propsEdit, previsualizer: replace };
          await dispatch(
            alertDoubleClic(
              editCompleted,
              0,
              {},
              propsEdit,
              editValuePrevisualizer
            )
          );
        } else {
          // Edicion del nombre de la pauta de la plantilla
          if (value === "") {
            return Swal.fire({
              title: "El nombre no puede estar un valor vacio.",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Ok",
              reverseButtons: true,
            });
          }
          editValuePrevisualizer = {
            ...column,
            [campoEdit.campo]: value,
            nameForEmail: dataUser?.name,
            emailForEmail: dataUser?.email,
          };
          let replace = propsEdit?.previsualizer.map((template) => {
            if (template.id === editValuePrevisualizer.id) {
              return editValuePrevisualizer;
            } else {
              template.nameForEmail = dataUser?.name;
              template.emailForEmail = dataUser?.email;
              return template;
            }
          });
          dispatch(setEditField(true));
          dispatch(setEditNameField(value));
          let editCompleted = { ...propsEdit, previsualizer: replace };
          await dispatch(
            alertDoubleClic(
              editCompleted,
              0,
              {},
              propsEdit,
              editValuePrevisualizer
            )
          );
        }
      }
    } catch (error) {
      console.log("Error en la edicion {name, budget}", { error });
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const editDates =
  (valueForEdit, propsEdit, dataUser) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));
      const { column, end, start, id } = valueForEdit[0];
      const dates = {
        fechaIni:
          format(new Date(start), "dd/MM/yyyy").split("/").join(".") ||
          format(new Date(), "dd/MM/yyyy").split("/").join("."),
        fechaFin:
          format(new Date(end), "dd/MM/yyyy").split("/").join(".") ||
          format(new Date(), "dd/MM/yyyy").split("/").join("."),
      };
      let datesEdit = {
        ...column,
        ...dates,
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
      };

      const idx = propsEdit?.previsualizer?.findIndex(
        (ele) => ele.id === id.id
      );

      if (idx !== -1) {
        const updatedPrevisualizer = [...propsEdit.previsualizer];
        updatedPrevisualizer[idx] = datesEdit;
        propsEdit = { ...propsEdit, previsualizer: updatedPrevisualizer };
        await dispatch(alertDoubleClic(propsEdit, 0, {}, propsEdit, datesEdit));
      }
    } catch (error) {
      console.log("Error en la edicion {name, budget}", { error });
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const alertDoubleClic =
  (editCompleted, state, dataFetch, edit, visualizer) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));
      let mapFetch = {
        0: async () => { },
        1: async () => {
          const medio = await APILA.getMediumBanner({
            id_region: dataFetch.id_region,
            bandera: 1,
          });
          dispatch(setListMediumBanner(medio.data.data));
          let newPrevisualizer = editCompleted.previsualizer.map((ele) => {
            if (ele.id === visualizer.id) {
              ele.optionsBanner = medio.data.data;
            }
            return ele;
          });
          editCompleted = { ...editCompleted, previsualizer: newPrevisualizer };
        },
        2: async () => {
          const medio = await APILA.getMediumBanner({
            id_medium: dataFetch.id_medium,
            id_region: dataFetch.id_region,
          });
          dispatch(setListGoals(medio.data.data));
          console.log("goeals", medio.data.data);
          let newPrevisualizer = editCompleted.previsualizer.map((ele) => {
            if (ele.id === visualizer.id) {
              ele.optionsObject = medio.data.data;
            }
            return ele;
          });
          editCompleted = { ...editCompleted, previsualizer: newPrevisualizer };
        },
        3: async () => {
          const formats = await APILA.getFormatData({
            id_medium: dataFetch.id_medium,
            id_banner: dataFetch.id_banner,
            id_region: dataFetch.id_region,
          });
          dispatch(setListFormat(formats.data.data));
          let newPrevisualizer = editCompleted.previsualizer.map((ele) => {
            if (ele.id === visualizer.id) {
              ele.optionsFormat = formats.data.data;
            }
            return ele;
          });
          editCompleted = { ...editCompleted, previsualizer: newPrevisualizer };
        },
      };
      await mapFetch[state]();
      dispatch(upsertVisualizer(edit, visualizer));
      dispatch(setEdit(editCompleted));
      return true;
    } catch (error) {
      console.log("Error en la actualización doble clic", { error });
      return false;
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const editAttachment =
  (state, prev, propsEdit, dataUser) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      // const { column, value } = valueForEdit[0];
      let editData = {
        ...prev,
        copy: state.copy,
        observation: state.observation,
        creativity: state.creativity,
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
      };
      let replace = propsEdit.previsualizer.map((template) => {
        if (template.id === editData.id) {
          return editData;
        } else {
          template.nameForEmail = dataUser?.name;
          template.emailForEmail = dataUser?.email;
          return template;
        }
      });
      let editCompleted = { ...propsEdit, previsualizer: replace };
      const response = await dispatch(
        alertDoubleClic(editCompleted, 0, {}, propsEdit, editData)
      );
      return response;
    } catch (error) {
      console.log("Error en la edicion del attachment", { error });
    } finally {
      dispatch(showLoader(false));
    }
  };

export const editCountry =
  (valueForEdit, propsEdit, dataUser) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));
      let nameRegionList = await dispatch(asyncGetNameRegion());
      const { column, value } = valueForEdit[0];
      let findCountry = nameRegionList.find(
        (nameRegion) => nameRegion.name === value
      );
      let dataEdit = {
        ...column,
        name_region: value,
        id_region: findCountry.id_region,
        banner: "",
        id_medium: "",
        id_ad_product: "",
        id_banner: "",
        id_format: "",
        formato: "",
        medio: "",
        objetivo: "",
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
      };
      let replace = propsEdit.previsualizer.map((template) => {
        if (template.id === dataEdit.id) {
          return dataEdit;
        } else {
          template.nameForEmail = dataUser?.name;
          template.emailForEmail = dataUser?.email;
          return template;
        }
      });

      let editCompleted = { ...propsEdit, previsualizer: replace };
      const validate = await dispatch(
        alertDoubleClic(editCompleted, 1, findCountry, propsEdit, dataEdit)
      );
      console.log("Retono del validate", { validate });
      dispatch(setStepDoubleClick(1));
      return validate;
    } catch (error) {
      console.log("Error en la edicion del pais ", { error });
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const editMediumData =
  (valueForEdit, propsEdit, banner, dataUser) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));
      const { column, value } = valueForEdit[0];
      console.log("Validando las props", {
        valueForEdit,
        propsEdit,
        banner,
        dataUser,
      });
      let findMedium = banner.find((medium) => medium.site === value);
      let dataEdit = {
        ...column,
        id_medium: findMedium.id_medium,
        medio: findMedium.site,
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
        // objetivo
        id_ad_product: 0,
        id_banner: 0,
        banner: "",
        objetivo: "",
        //formato
        id_format: "",
        formato: "",
      };
      let replace = propsEdit?.previsualizer?.map((template) => {
        if (template.id === dataEdit.id) {
          return dataEdit;
        } else {
          template.nameForEmail = dataUser?.name;
          template.emailForEmail = dataUser?.email;
          return template;
        }
      });
      let datasFetch = {
        id_medium: findMedium.id_medium,
        id_region: column.id_region,
      };
      let editCompleted = { ...propsEdit, previsualizer: replace };
      const response = await dispatch(
        alertDoubleClic(editCompleted, 2, datasFetch, propsEdit, dataEdit)
      );
      dispatch(setStepDoubleClick(2));
      return response;
    } catch (error) {
      console.log("Error en la edicion del medio", { error });
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const editValueBanner =
  (valueForEdit, propsEdit, goals, dataUser) => async (dispatch) => {
    try {
      const { column, value } = valueForEdit[0];

      let findBanner = goals.find((goal) => goal.banner === value);
      if (!findBanner) {
        throw new Error("No se encontró el banner");
      }

      let editData = {
        ...column,
        optionsObjective: goals,
        id_ad_product: findBanner.id_ad_product,
        id_banner: findBanner.id_banner,
        banner: value,
        objetivo: value,
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
        // Formato
        id_format: 0,
        formato: "",
      };

      let replace = propsEdit.previsualizer.map((template) => {
        if (template.id === editData.id) {
          return editData;
        } else {
          template.nameForEmail = dataUser?.name;
          template.emailForEmail = dataUser?.email;
          return template;
        }
      });

      let datasFetch = {
        id_medium: column.id_medium,
        id_region: column.id_region,
        id_banner: findBanner.id_banner,
      };

      let editCompleted = { ...propsEdit, previsualizer: replace };

      const response = await dispatch(
        alertDoubleClic(editCompleted, 3, datasFetch, propsEdit, editData)
      );

      dispatch(setStepDoubleClick(3));

      return response;
    } catch (error) {
      console.error("Error en la edición del banner", { error });
    } finally {
      console.log("Finalización de editValueBanner");
      // dispatch(showLoader(false));
    }
  };

export const editValueFormat =
  (valueForEdit, propsEdit, formats, dataUser) => async (dispatch) => {
    try {
      console.log("Inicio de editValueFormat");
      console.log("Parámetros recibidos:", {
        valueForEdit,
        propsEdit,
        formats,
        dataUser,
      });

      // dispatch(showLoader(true));

      const { column, value } = valueForEdit[0];
      console.log("Extracción de column y value:", { column, value });

      let findFormat = formats.find((format) => format.art === value);
      console.log("Formato encontrado:", findFormat);

      if (!findFormat) {
        throw new Error(`Formato no encontrado para el valor: ${value}`);
      }

      let editData = {
        ...column,
        id_format: findFormat.id_format,
        formato: value,
        optionsFormat: formats,
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
      };
      console.log("Datos para editar:", editData);

      let replace = propsEdit.previsualizer.map((template) => {
        if (template.id === editData.id) {
          console.log(`Reemplazando template con id: ${editData.id}`);
          return editData;
        } else {
          template.nameForEmail = dataUser?.name;
          template.emailForEmail = dataUser?.email;
          return template;
        }
      });
      console.log("Templates actualizados:", replace);

      let editCompleted = { ...propsEdit, previsualizer: replace };
      console.log("Edición completada:", editCompleted);

      const response = await dispatch(
        alertDoubleClic(editCompleted, 0, {}, propsEdit, editData)
      );
      console.log("Respuesta del dispatch:", response);

      dispatch(setStepDoubleClick(4));
      console.log("Paso doble click seteado a 4");

      return response;
    } catch (error) {
      console.error("Error en la edición del formato:", error);
    } finally {
      // dispatch(showLoader(false));
      console.log("Finalizando editValueFormat");
    }
  };

export const editSegmentation =
  (valueForEdit, propsEdit, audiences, dataUser) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));
      const { column, value } = valueForEdit[0];
      const findIdAudience = audiences.find((ele) => ele.label === value);
      let editData = {
        ...column,
        idAudience: findIdAudience?.id,
        publico: findIdAudience?.tags?.data,
        audience_name: findIdAudience?.name,
        publicoE: findIdAudience?.tagsE?.data,
        nameForEmail: dataUser?.name,
        emailForEmail: dataUser?.email,
      };

      let replace = propsEdit.previsualizer.map((template) => {
        if (template.id === editData.id) {
          return editData;
        } else {
          template.nameForEmail = dataUser?.name;
          template.emailForEmail = dataUser?.email;
          return template;
        }
      });
      let editCompleted = { ...propsEdit, previsualizer: replace };
      await dispatch(
        alertDoubleClic(editCompleted, 0, {}, propsEdit, editData)
      );
    } catch (error) {
      console.log("Error en la edicion de la segmentacion", { error });
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const updateCategoriesSelected =
  (categories, propsEdit, id, column) => async (dispatch) => {
    try {
      // dispatch(showLoader(true));

      const idx = propsEdit?.previsualizer?.findIndex((ele) => ele.id === id);

      if (idx !== -1) {
        const updatedPrevisualizer = propsEdit.previsualizer;
        let dataEdit = {
          ...column,
          categoriesSelected: categories,
        };
        updatedPrevisualizer[idx] = dataEdit;
        propsEdit = { ...propsEdit, previsualizer: updatedPrevisualizer };
        await dispatch(alertDoubleClic(propsEdit, 0, {}, propsEdit, dataEdit));
      }
    } catch (error) {
      console.log("Error en la edicion de categorias", error);
    } finally {
      // dispatch(showLoader(false));
    }
  };

export const getAudiences = (account) => async (dispatch) => {
  try {
    const [audiencesResponse] = await Promise.all([API.getAudiences(account)]);
    const dataAudiences = audiencesResponse.data.audiences.map((ele) => {
      return { ...ele, value: ele.id, label: ele.name };
    });
    dispatch(setListAudiences(dataAudiences));
    return dataAudiences;
  } catch (error) {
    console.log("Error en la obtencion de audiencias", error);
  }
};

/*****************************************
 * * Alertas de ediciones *
 *****************************************/

export const alertEdit = (edit, account) => (dispatch) => {
  if (edit) {
    const resultado = window.confirm(
      "Tienes cambios sin guardar, ¿deseas salir?"
    );
    if (!resultado) return false;
    dispatch(setEditActivate(false));
    dispatch(clearEdit());
    dispatch(clearEditVisualizer());
    dispatch(getTemplates(account, ""));

    return true;
  }
  return false;
};

/*****************************************
 * * Funcion para cargar una plantilla vacia *
 *****************************************/

export const uploadTemplateEmpty =
  (account, name, setOption, history, isSelected, format) =>
    async (dispatch) => {
      try {
        dispatch(showLoader(true));
        const sendTemplate = async () => {
          let nameTemplate = name;
          if (!isSelected) {
            return Swal.fire({
              title: "¿Desea cambiar el nombre de la plantilla?",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              inputValue: nameTemplate,
              showCancelButton: true,
              confirmButtonText: "Confirmar",
              confirmButtonColor: "#3085d6",
              preConfirm: (nameInput) => {
                if (!nameInput) {
                  Swal.showValidationMessage("El nombre no puede ser vacio.");
                } else {
                  nameTemplate = nameInput;
                }
              },
            }).then(async (result) => {
              const bodyTemplate = {
                previsualizer: [format] || [],
                account: account,
                id: v4(),
                name: nameTemplate,
              };
              await APILA.upsertTemplate(bodyTemplate);
              setOption(bodyTemplate.id);
              dispatch(getTemplates(account, bodyTemplate.id));
              dispatch(
                Modal.showAlertModal(
                  "!Plantilla cargada!",
                  "Plantilla cargada correctamente.",
                  Modal.MODAL_TYPE_SUCCESS,
                  () => { }
                )
              );
              dispatch(generateLink(account, history, 1, bodyTemplate.id, ""));
            });
          } else {
            const bodyTemplate = {
              previsualizer: [format] || [],
              account: account,
              id: v4(),
              name: nameTemplate,
            };
            await APILA.upsertTemplate(bodyTemplate);
            setOption(bodyTemplate.id);

            dispatch(getTemplates(account, bodyTemplate.id));
            dispatch(
              Modal.showAlertModal(
                "!Plantilla cargada!",
                "Plantilla cargada correctamente.",
                Modal.MODAL_TYPE_SUCCESS,
                () => { }
              )
            );
            dispatch(generateLink(account, history, 1, bodyTemplate.id, ""));
          }
        };
        sendTemplate();
      } catch (error) {
        console.log("Error cargando plantilla vacia", { error });
      } finally {
        dispatch(showLoader(false));
      }
    };

/*****************************************
 * * Convertir una plantilla a una plantilla por defecto, para ser reutilizada *
 *****************************************/

export const convertTemplateDefault =
  (currentAccount, idTemplate) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      await APILA.convertTemplateDefault({ idTemplate });
      dispatch(getTemplates(currentAccount, idTemplate));
    } catch (error) {
      throw new Error("Error al convertir la plantilla a por defecto", error);
    } finally {
      dispatch(showLoader(false));
    }
  };

/*****************************************
 * * Funciones de reducers *
 *****************************************/

export const clearTemplates = () => ({
  type: TEMPLATES_CLEAR,
});

export const addTotalTemplate = (templates) => ({
  type: TEMPLATES_ADD_TOTAL,
  templates,
});

export const setEdit = (edit) => ({
  type: TEMPLATES_SET_EDIT,
  edit,
});

export const clearEdit = () => ({
  type: TEMPLATES_CLEAR_EDIT,
});

export const setEditVisualizer = (visualizer) => ({
  type: TEMPLATES_SET_EDIT_VISUALIZER,
  visualizer,
});

export const updateTemplate = (updateVisualizer) => ({
  type: TEMPLATES_UPDATE_ONE_VISUALIZER,
  updateVisualizer,
});

export const clearEditVisualizer = () => ({
  type: TEMPLATES_CLEAR_EDIT_VISUALIZER,
});

export const setEditActivate = (editActivate) => ({
  type: TEMPLATES_SET_EDIT_ACTIVE,
  editActivate,
});

export const setListMediumBanner = (listMediumBanner) => ({
  type: TEMPLATES_SET_LIST_MEDIUMBANNER,
  listMediumBanner,
});

export const setListGoals = (listGoals) => ({
  type: TEMPLATES_SET_LIST_GOALS,
  listGoals,
});

export const setListFormat = (listFormat) => ({
  type: TEMPLATES_SET_LIST_FORMAT,
  listFormat,
});

export const setErrorListTemplateAudience = (errorsAudience) => ({
  type: TEMPLATES_SET_LIST_ERRORS_AUDIENCE,
  errorsAudience,
});

export const setErrorListTemplateBudget = (errorsBudget) => ({
  type: TEMPLATES_SET_LIST_ERRORS_BUDGET,
  errorsBudget,
});

export const setErrorListTemplateProduct = (errorsProduct) => ({
  type: TEMPLATES_SET_LIST_ERRORS_PRODUCT,
  errorsProduct,
});

export const setListErrorImplements = (errorImplement) => ({
  type: TEMPLATES_SET_LIST_ERRORS_IMPLEMENT,
  errorImplement,
});

export const setListRefreshBudgets = (refreshBudgets) => ({
  type: TEMPLATES_SET_LIST_REFRESH_BUDGETS,
  refreshBudgets,
});

export const setFilteredBudgets = (filteredBudgets) => ({
  type: TEMPLATES_SET_FILTERED_BUDGETS,
  filteredBudgets,
});

export const setCurrentIdPautaTemplate = (currentIdPautaEdit) => ({
  type: TEMPLATES_SET_CURRENT_ID_PAUTA,
  currentIdPautaEdit,
});

export const setImplementButton = (implement) => ({
  type: TEMPLATES_SET_IMPLEMENT,
  implement,
});

export const setListAudiences = (audiences) => ({
  type: TEMPLATES_SET_LIST_AUDIECNES,
  audiences,
});

export const setRegions = (regions) => ({
  type: TEMPLATES_SET_LIST_REGIONS,
  regions,
});

export const setUserInfo = (user) => ({
  type: TEMPLATES_SET_LIST_USER_INFO,
  user,
});

export const setStepDoubleClick = (step) => ({
  type: TEMPLATES_SET_LIST_STEP,
  step,
});

export const setEditField = (editField) => ({
  type: TEMPLATES_SET_EDIT_FIELD,
  editField,
});

export const setEditNameField = (name) => ({
  type: TEMPLATES_SET_NAME_FILED,
  name,
});
