import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import "./styles.scss";
import analytics from "helpers/analytics";
import PageLoader from "components/page_loader";
import * as APILA from "helpers/apiLA";
import { connect } from "react-redux";
import { withUtils } from "utils";
import { setPermisos } from "redux/actions/schedule.actions";
import { nextStep } from "redux/actions/userLogout.action";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import Swal from "sweetalert2";

import { Auth } from "aws-amplify";
const NavItem = ({ title, icon, expanded }) =>
  expanded ? <> {title}</> : <i className={icon} />;

const NavSettings = (props) => {
  const [permitsState, setPermitsState] = useState({
    settings: false,
    schedule: false,
    accountInfo: false,
  });
  const [expanded, setExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [rolesView, setRolesView] = useState(false);
  const [alertsView, setAlertsView] = useState(false);
  const [accountView, setAccountView] = useState(false);
  const [logsBudget, setLogsBudget] = useState(false);

  const match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    getRolesNames();
  }, []);

  useEffect(() => {
    let permisosSetting = props?.permits?.find(
      (ele) => ele.name === "Configuración prioridades de campañas"
    );
    let permisosSchdule = props?.permits?.find(
      (ele) => ele.name === "Horarios de atención"
    );

    let accountInfoPermission = props?.permits?.find(
      (item) => item.name === "Configuración de la cuenta"
    );

    setPermitsState((prev) => ({
      ...prev,
      settings: permisosSetting?.view,
      schedule: permisosSchdule?.view,
      accountInfo: accountInfoPermission?.view,
    }));
  }, [props.permits]);

  const getRolesNames = async () => {
    setIsLoading(true);
    // let responseRoleNames = await APILA.getRoleNames({account: });
    try {
      let userData = await Auth.currentAuthenticatedUser();
      let response = await APILA.getPermitsByUser({
        user: userData.username,
      });
      response = response.data.permits;
      let found = response.find(
        ({ account }) => account === props.currentAccount
      );
      if (found.idRol === "Super Administrador") {
        setAccountView(true);
      } else {
        setAccountView(false);
      }
      if (response.some((ele) => ele.idRol === "Super Administrador")) {
        setIsAdmin(true);
        let permisosResponse = await APILA.getRoleNameById({
          id: "Super Administrador",
        });
        permisosResponse = permisosResponse.data.rol[0].permissions;
        let permisosRoles = permisosResponse.find(
          (ele) => ele.name === "Roles de sistema"
        );
        let permisosAlertas = permisosResponse.find(
          (ele) => ele.name === "Configuración alertas"
        );
        let permisosLogsPresupuestos = permisosResponse.find(
          (ele) => ele.name === "Movimientos de presupuestos"
        );
        if (permisosLogsPresupuestos && permisosLogsPresupuestos.view) {
          setLogsBudget(true);
        }
        if (permisosRoles && permisosRoles.view) {
          setRolesView(true);
        } else {
          setRolesView(false);
        }
        if (permisosAlertas && permisosAlertas.view) {
          setAlertsView(true);
        } else {
          setAlertsView(false);
        }
      } else {
        let cuenta = response.find(
          (ele) => ele.account === props.currentAccount
        );
        let permisosResponse = await APILA.getRoleNameById({
          id: cuenta.idRol,
        });
        permisosResponse = permisosResponse.data.rol[0].permissions;
        let permisosRoles = permisosResponse.find(
          (ele) => ele.name === "Roles de sistema"
        );
        let permisosAlertas = permisosResponse.find(
          (ele) => ele.name === "Configuración alertas"
        );
        let poermisosLogsPresupuestos = permisosResponse.find(
          (ele) => ele.name === "Movimientos de presupuestos"
        );
        if (poermisosLogsPresupuestos && poermisosLogsPresupuestos.view) {
          setLogsBudget(true);
        }
        if (permisosRoles && permisosRoles.view) {
          setRolesView(true);
        } else {
          setRolesView(false);
        }
        if (permisosAlertas && permisosAlertas.view) {
          setAlertsView(true);
        } else {
          setAlertsView(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error === "The user is not authenticated") {
      }
      console.log("error", error);
      setIsLoading(false);
    }
  };

  let validate = false;
  const mCurrentAccount =
    props.currentUser !== "" ? props.currentAccount : props.currentPWAAccount;

  if (mCurrentAccount === "") {
    validate = true;
  }

  if (props.isIpv4) {
    return (
      <>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            <div
              className={classnames("settings-nav desktop", {
                exp: expanded,
                con: !expanded,
              })}
            >
              <div
                className={classnames("w-100", {
                  titleOneStep: expanded,
                  titleOneStepClose: !expanded,
                })}
              >
                <i
                  onClick={() => setExpanded(!expanded)}
                  className="far fa-arrow-alt-circle-left"
                />
                {expanded && <p className="m-0">CONFIGURACI&Oacute;N</p>}
              </div>
              {/* Mis Cuentas */}
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname === "/configuracion/accounts",
                })}
                onClick={() => {
                  if (validate) {
                    Swal.fire({
                      title: "Cuenta Requerida",
                      text: "Para utilizar esta sección, debes crear una cuenta.",
                      icon: "warning",
                    }).then((response) => {
                      if (!response.isConfirmed) return;
                      if (props?.accounts?.length > 0) return;
                      props._nexStep(1);
                    });
                  }
                }}
              >
                <Link
                  to={validate ? match.path : `${match.path}/accounts`}
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Mis cuentas" })
                  }
                  className="settings-nav-link"
                >
                  <NavItem
                    expanded={expanded}
                    title="Mis Cuentas"
                    icon="fas fa-user-circle"
                  />
                </Link>
              </div>
              {/* pagina Etiquetados  */}
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname === "/configuracion/etiquetas",
                })}
                onClick={() => {
                  if (validate) {
                    Swal.fire({
                      title: "Cuenta Requerida",
                      text: "Para utilizar esta sección, debes crear una cuenta.",
                      icon: "warning",
                    }).then((response) => {
                      if (!response.isConfirmed) return;
                      if (props?.accounts?.length > 0) return;
                      props._nexStep(1);
                    });
                  }
                }}
              >
                <Link
                  to={validate ? match.path : `${match.path}/etiquetas`}
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Etiquetas" })
                  }
                  className="settings-nav-link"
                >
                  <NavItem
                    expanded={expanded}
                    title="Etiquetas de campaña"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            </div>
            <div className="settings-nav responsive ">
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname === "/configuracion/accounts",
                })}
                onClick={() => {
                  if (validate) {
                    Swal.fire({
                      title: "Cuenta Requerida",
                      text: "Para utilizar esta sección, debes crear una cuenta.",
                      icon: "warning",
                    }).then((response) => {
                      if (!response.isConfirmed) return;
                      if (props?.accounts?.length > 0) return;
                      props._nexStep(1);
                    });
                  }
                }}
              >
                <Link
                  to={validate ? `${match.path}` : `${match.path}/accounts`}
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Mis cuentas" })
                  }
                  className="settings-nav-link"
                >
                  <NavItem title="Mis Cuentas" icon="fas fa-user-circle" />
                </Link>
              </div>
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname === "/configuracion/etiquetas",
                })}
                onClick={() => {
                  if (validate) {
                    Swal.fire({
                      title: "Cuenta Requerida",
                      text: "Para utilizar esta sección, debes crear una cuenta.",
                      icon: "warning",
                    }).then((response) => {
                      if (!response.isConfirmed) return;
                      if (props?.accounts?.length > 0) return;
                      props._nexStep(1);
                    });
                  }
                }}
              >
                <Link
                  to={validate ? Math.path : `${match.path}/etiquetas`}
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Etiquetas" })
                  }
                  className="settings-nav-link"
                >
                  <NavItem title="Etiquetas de campaña" icon="fas fa-tags" />
                </Link>
              </div>
            </div>
          </>
        )}
        {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div
            className={classnames("settings-nav desktop", {
              exp: expanded,
              con: !expanded,
            })}
          >
            <div
              className={classnames("w-100", {
                titleOneStep: expanded,
                titleOneStepClose: !expanded,
              })}
            >
              <i
                onClick={() => setExpanded(!expanded)}
                className="far fa-arrow-alt-circle-left"
              />
              {expanded && <p className="m-0">CONFIGURACI&Oacute;N</p>}
            </div>
            {/* Mis Cuentas */}
            {accountView && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes("/configuracion/accounts"),
                })}
              >
                <Link
                  to={`${match.path}/accounts/account=${props.currentAccount}&page=1`}
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Mis cuentas" })
                  }
                  className="settings-nav-link"
                >
                  <NavItem
                    expanded={expanded}
                    title="Mis Cuentas"
                    icon="fas fa-user-circle"
                  />
                </Link>
              </div>
            )}
            {/* pagina Etiquetados  */}
            <div
              className={classnames({
                "settings-nav-item": expanded,
                "settings-nav-itemClose": !expanded,
                active: location.pathname.includes("/configuracion/etiquetas"),
              })}
            >
              <Link
                to={`${match.path}/etiquetas/account=${props.currentAccount}&page=1`}
                onClick={() =>
                  analytics.opciones_config({ ubicacion: "Etiquetas" })
                }
                className="settings-nav-link"
              >
                <NavItem
                  expanded={expanded}
                  title="Etiquetas de campaña"
                  icon="fas fa-link"
                />
              </Link>
            </div>
            {/**Gestión de accesos */}
            <div
              className={classnames({
                "settings-nav-item": expanded,
                "settings-nav-itemClose": !expanded,
                active: location.pathname.includes("/configuracion/roles"),
              })}
            >
              <Link
                to={`${match.path}/roles/account=${props.currentAccount}&view=list&group=default`}
                onClick={() =>
                  analytics.opciones_config({ ubicacion: "Gestión de accesos" })
                }
                className="settings-nav-link"
              >
                <NavItem
                  expanded={expanded}
                  title="Gestión de accesos"
                  icon="fas fa-link"
                />
              </Link>
            </div>
            {/**asignacion roles */}
            {rolesView && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/asignacion-roles"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/asignacion-roles/account=${props.currentAccount}&page=1`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Roles del sistema",
                    })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Roles del sistema"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            )}

            {/**emails */}
            {alertsView && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes("/configuracion/emails"),
                })}
              >
                <Link
                  to={`${match.path}/emails/account=${props.currentAccount}&page=1`}
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Configuración de alertas",
                    })
                  }
                  className="settings-nav-link"
                >
                  <NavItem
                    expanded={expanded}
                    title="Configuración alertas"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            )}

            {/**integracion odoo */}
            {isAdmin && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/integrationOdoo"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/integrationOdoo/account=${props.currentAccount}&page=1`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Integración ERP" })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Integración ERP"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            )}
            {permitsState.settings && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/configuracionPriority"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/configuracionPriority/account=${props.currentAccount}`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Disponibilidad de horarios",
                    })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Configuración de prioridades"
                    icon="fa fa-clock-o"
                  />
                </Link>
              </div>
            )}
            {permitsState.schedule && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/configuracionSemanal"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/configuracionSemanal/account=${props.currentAccount}`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Configuración semanal de horarios",
                    })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Configuración semanal de horarios"
                    icon="fa fa-calendar"
                  />
                </Link>
              </div>
            )}

            {permitsState.accountInfo && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/informacionCuenta"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/informacionCuenta/account=${props.currentAccount}&view_selector=1&page=1`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Información de la cuenta",
                    })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Información de la cuenta"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            )}
            {logsBudget && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/logs-presupuestos"
                  ),
                })}
              >
                <Link
                  // to={`${match.path}/informacionCuenta/account=${props.currentAccount}&view_selector=1&page=1`}
                  to={`${match.path}/logs-presupuestos`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Logs de presupuestos",
                    })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Movimientos de los presupuestos"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            )}

            {/*
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
          'active': location.pathname === '/settings/budgets',
        })}
      >
        <Link to={`${match.path}/budgets`} className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Hola'
            icon='fas fa-money-check-alt'
          />
        </Link>
      </div>
 
      <div
        className={classnames({
          'settings-nav-item': expanded,
          'settings-nav-itemClose': !expanded,
          'active': location.pathname === '/settings/analytics',
        })}
      >
        <Link to={`${match.path}/analytics`} className='settings-nav-link'>
          <NavItem
            expanded={expanded}
            title='Mis Reportes'
            icon='fas fa-chart-line'
          />
        </Link>
      </div> */}
          </div>

          <div className="settings-nav responsive ">
            {/* <div className="w-100 titleOneStepClose">
          <i
            onClick={() => setExpanded(!expanded)}
            className="far fa-arrow-alt-circle-left"
          />
        </div> */}

            {accountView && (
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname.includes("/configuracion/accounts"),
                })}
              >
                <Link
                  to={`${match.path}/accounts/account=${props.currentAccount}&page=1`}
                  onClick={() =>
                    analytics.opciones_config({ ubicacion: "Mis cuentas" })
                  }
                  className="settings-nav-link"
                >
                  <NavItem title="Mis Cuentas" icon="fas fa-user-circle" />
                </Link>
              </div>
            )}
            <div
              className={classnames("settings-nav-itemClose", {
                active: location.pathname.includes("/configuracion/etiquetas"),
              })}
            >
              <Link
                to={`${match.path}/etiquetas/account=${props.currentAccount}&page=1`}
                onClick={() =>
                  analytics.opciones_config({ ubicacion: "Etiquetas" })
                }
                className="settings-nav-link"
              >
                <NavItem title="Etiquetas de campaña" icon="fas fa-tags" />
              </Link>
            </div>
            <div
              className={classnames("settings-nav-itemClose", {
                active: location.pathname.includes("/configuracion/roles"),
              })}
            >
              <Link
                to={`${match.path}/roles/account=${props.currentAccount}&view=list&group=default`}
                onClick={() =>
                  analytics.opciones_config({ ubicacion: "Gestión de accesos" })
                }
                className="settings-nav-link"
              >
                <NavItem
                  title="Gestión de accesos"
                  icon="fas fa-universal-access"
                />
              </Link>
            </div>
            {rolesView && (
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname.includes(
                    "/configuracion/asignacion-roles"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/asignacion-roles/account=${props.currentAccount}&page=1`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Roles del sistema",
                    })
                  }
                >
                  <NavItem title="Roles del sistema" icon="fas fa-sitemap" />
                </Link>
              </div>
            )}
            {alertsView && (
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname.includes("/configuracion/emails"),
                })}
              >
                <Link
                  to={`${match.path}/emails/account=${props.currentAccount}&page=1`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Configuración de alertas",
                    })
                  }
                >
                  <NavItem
                    title="Configuración alertas"
                    icon="fas fa-envelope"
                  />
                </Link>
              </div>
            )}
            {isAdmin && (
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname.includes(
                    "/configuracion/integrationOdoo"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/integrationOdoo/account=${props.currentAccount}&page=1`}
                  className="settings-nav-link"
                >
                  <NavItem title="Odoo" icon="fas fa-database" />
                </Link>
              </div>
            )}
            {permitsState.settings && (
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname.includes(
                    "/configuracion/configuracionPriority"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/configuracionPriority/account=${props.currentAccount}`}
                  className="settings-nav-link"
                >
                  <NavItem
                    title="Configuración de prioridades"
                    icon="fa fa-clock-o"
                  />
                </Link>
              </div>
            )}
            {permitsState.schedule && (
              <div
                className={classnames("settings-nav-itemClose", {
                  active: location.pathname.includes(
                    "/configuracion/configuracionSemanal"
                  ),
                })}
              >
                <Link
                  to={`${match.path}/configuracionSemanal/account=${props.currentAccount}`}
                  className="settings-nav-link"
                >
                  <NavItem
                    title="Configuración semanal de horario"
                    icon="fa fa-calendar"
                  />
                </Link>
              </div>
            )}
            {logsBudget && (
              <div
                className={classnames({
                  "settings-nav-item": expanded,
                  "settings-nav-itemClose": !expanded,
                  active: location.pathname.includes(
                    "/configuracion/logs-presupuestos"
                  ),
                })}
              >
                <Link
                  // to={`${match.path}/informacionCuenta/account=${props.currentAccount}&view_selector=1&page=1`}
                  to={`${match.path}/logs-presupuestos`}
                  className="settings-nav-link"
                  onClick={() =>
                    analytics.opciones_config({
                      ubicacion: "Logs de presupuestos",
                    })
                  }
                >
                  <NavItem
                    expanded={expanded}
                    title="Movimientos de los presupuestos"
                    icon="fas fa-link"
                  />
                </Link>
              </div>
            )}
            <div
              className={classnames({
                "settings-nav-item": expanded,
                "settings-nav-itemClose": !expanded,
                active: location.pathname.includes(
                  "/configuracion/informacionCuenta"
                ),
              })}
            >
              <Link
                to={`${match.path}/informacionCuenta/account=${props.currentAccount}&view_selector=1&page=1`}
                className="settings-nav-link"
                onClick={() =>
                  analytics.opciones_config({
                    ubicacion: "Información de la cuenta",
                  })
                }
              >
                <NavItem
                  expanded={expanded}
                  title="Información de la cuenta"
                  icon="fas fa-link"
                />
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isIpv4: state.app.isIPV4,
  step: state.userLogout.nextStepCategory,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  permits: state.schedule.permisos,
  accounts: state.accounts.list,
});
const mapDispatchToProps = (dispatch) => ({
  _setPermisos: (permisos) => dispatch(setPermisos(permisos)),
  _nexStep: (step) => dispatch(nextStep(step)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(NavSettings));
