import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/dashboard.actions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CAMPAIGNS_UPADD:
      return {
        ...state,
        ...action.campaignuupAdd,
      }
    case Actions.CAMPAIGNS_UPADD_CLEAR:
      return {}
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}