import { useState, useEffect, useRef, isValidElement } from "react";
import PageLoader from "components/page_loader";
import "./style.scss";
import { Login, FileList } from "@microsoft/mgt-react";
import List from "../../../libs/dropzone/list";

const ModaOneDrive = ({
  setShowModal,
  handleClick,
  optionsFile,
  handleItemClick,
  itemId,
  oneDriveFiles,
  sendUpdateFiles,
  setIsLoginOneDrive,
}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(oneDriveFiles);
  }, [oneDriveFiles]);

  const removeFile = (name) => {
    const index = files.findIndex((f) => f.name === name);
    files.splice(index, 1);
    setFiles([...files]);
  };

  return (
    <div className="blur-div">
      {loading ? (
        <PageLoader />
      ) : (
        <div className="content-modal modalOneDrive">
          <div className="header-modal">
            <h3 className="titleModal">Archivos OneDrive</h3>
            <div className="boxLogOut">
              <Login
                logoutCompleted={() => {
                  setIsLoginOneDrive(false);
                  setShowModal(false);
                }}
              />
            </div>
          </div>
          <div className="body-modal bodyModalOneDrive">
            <div className="body-part">
              <ul class="breadcrumb" id="navFiles">
                <li>
                  <a id="home" onClick={(e) => handleClick("home")}>
                    Files
                  </a>
                </li>
                {optionsFile.map((opt) => (
                  <li>
                    <a id="home" onClick={(e) => handleClick(opt.value)}>
                      {opt.name}
                    </a>
                  </li>
                ))}
              </ul>
              <FileList
                itemId={itemId}
                aria-aria-multiselectable={true}
                itemClick={handleItemClick}
              />
            </div>
            <div className="body-part">
              <div className="listBox">
                <h4>Archivos Seleccionados</h4>
                <List files={files} onRemove={removeFile} />
              </div>
            </div>
          </div>
          <div className="footer-Modal">
            <button
              className="btn btn_cancel btnCancelOS"
              onClick={(e) => setShowModal(false)}
            >
              Cancelar
            </button>
            <button
              className="btn btn_save btnConfirmOS"
              onClick={() => {
                sendUpdateFiles(files);
                setShowModal(false);
                setTimeout(() => {
                  setFiles([]);
                }, 2000);
              }}
            >
              Agregar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModaOneDrive;
