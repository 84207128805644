import _ from 'lodash'

import { APP_SET_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/pwa.actions'

const initialState = {
  accounts: {
    edit: {},
    list: [],
    default: '',
  },
  audiences: {
    edit: {},
    list: [],
    filter_text: '',
  },
  budgets: {
    edit: {},
    list: [],
    filter_text: '',
  },
  categories: {
    edit: {},
    list: [],
    filter_text: '',
  },
  creative: {
    files: [],
  },
  profile: {
    name: '',
    email: '',
    phone: '',
    country: '',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    // accounts
    case Actions.PWA_ACCOUNTS_SET_DEFAULT:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          default: action.account,
        },
      }
    case Actions.PWA_ACCOUNTS_UPSERT:
      const mAccount =
        _.find(state.accounts.list, { id: action.account.id }) || {}

      return {
        ...state,
        accounts: {
          ...state.accounts,
          list: [
            ...state.accounts.list.filter(
              (acc) => acc.id !== action.account.id
            ),
            {
              ...mAccount,
              ...action.account,
            },
          ],
        },
      }
    // audiences
    case Actions.PWA_AUDIENCES_UPSERT:
      const mAudience =
        _.find(state.audiences.list, { id: action.audience.id }) || {}

      return {
        ...state,
        audiences: {
          ...state.audiences,
          list: [
            ...state.audiences.list.filter(
              (aud) => aud.id !== action.audience.id
            ),
            {
              ...mAudience,
              ...action.audience,
            },
          ],
        },
      }
    case Actions.PWA_AUDIENCES_REMOVE:
      return {
        ...state,
        audiences: {
          ...state.audiences,
          list: state.audiences.list.filter((aud) => aud.id !== action.id),
        },
      }
    // budgets
    case Actions.PWA_BUDGETS_UPSERT:
      const mBudget = _.find(state.budgets.list, { id: action.budget.id }) || {}

      return {
        ...state,
        budgets: {
          ...state.budgets,
          list: [
            ...state.budgets.list.filter((b) => b.id !== action.budget.id),
            {
              ...mBudget,
              ...action.budget,
            },
          ],
        },
      }
    case Actions.PWA_BUDGETS_REMOVE:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          list: state.budgets.list.filter((b) => b.id !== action.id),
        },
      }
    // categories
    case Actions.PWA_CATEGORIES_UPSERT:
      const mCategory =
        _.find(state.categories.list, { id: action.category.id }) || {}

      return {
        ...state,
        categories: {
          ...state.categories,
          list: [
            ...state.categories.list.filter(
              (cat) => cat.id !== action.category.id
            ),
            {
              ...mCategory,
              ...action.category,
            },
          ],
        },
      }
    case Actions.PWA_CATEGORIES_REMOVE:
      return {
        ...state,
        categories: {
          ...state.categories,
          list: state.categories.list.filter((cat) => cat.id !== action.id),
        },
      }
    // files
    case Actions.PWA_CREATIVE_UPLOAD_FILES:
      return {
        ...state,
        creative: {
          files: [
            ...state.creative.files,
            action.fileInfo,
          ],
        },
      }
    case Actions.PWA_CREATIVE_REMOVE:
      return state
    case APP_SET_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
