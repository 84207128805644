import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/payments.actions'

const initialState = {
  total: 0,  
  cards: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLEAN_PAYMENT:
      return {
        ...state,
        cards: [],
        total: 0,
      }
    case Actions.ADD_PAYMENT:
       return {...state, cards:action.cards}
    case Actions.PAYMENT_CHANGE_TOTAL:
    return {
      ...state,
      total: action.total,
    }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}