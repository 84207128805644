import React from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tooltip } from "antd";

import "./assets/css/styles.css";

import { showLoader } from "redux/actions/loader.actions";
import { clearCurrentUser } from "redux/actions/app.actions";
import { withUtils } from "utils";
import analytics from "../../../helpers/analytics";
const TAG = "Profile:Info";

const Info = (props) => {
  const signOut = async (e) => {
    e.preventDefault();
    props._showLoader(true);
    // comentar en prod
    analytics.logout({
      ubicacion: "Perfil",
    });
    try {
      await Auth.signOut();
      props._clearCurrentUser();
      window.$chatwoot.reset();
      props.history.push("/");
    } catch (error) {
      props.utils.log(TAG, "error signing out: ", error);
    }

    props._showLoader(false);
  };

  return (
    <div className="info">
      <Tooltip placement="top" title="Cerrar sesión" color="#002448">
        <div className="boton">
          <a onClick={signOut} className="btn-plus" href="#">
            <i className="fas fa-sign-out-alt"></i>
          </a>
        </div>
        <h3 className="logout-text">Cerrar Sesión </h3>
      </Tooltip>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(
  undefined,
  mapDispatchToProps
)(withUtils(withRouter(Info)));
