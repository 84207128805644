import { useState, useEffect } from "react";
import DataTableUtils, {
  Share,
  ShowColumn2,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import ReactDataTable, {
  OptionsSelectedEdit,
  OptionNewEdit,
  EditAnt,
  SimpleText,
} from "@easygosanet/react-datatable";
import classnames from "classnames";
import { Tooltip } from "antd";
import ModalConectOdoo from "./ModalConectOdoo";
import "./styles.scss";
import * as APILA from "helpers/apiLA";
import PageLoader from "components/page_loader";
import { getReports } from "helpers/api";
import { withUtils } from "utils";
import { connect } from "react-redux";
import AsociationIds from "./AsociationIds";
import { useLocation, useHistory } from "react-router-dom";

const ConectionOdoo = (props) => {

  /* Hooks para la navegacion y push al pathname */
  const location = useLocation()
  const history = useHistory()

  /* Instancias para obntener los datos */
  const currentUrl = new URLSearchParams(location.pathname)
  const currentSearch = new URLSearchParams(location.search)

  /* Obtencion de los valores */
  const accountParams = location.pathname?.split('/configuracion/integrationOdoo/account=')[1]?.split('&')[0]
  const pageParams = Number(currentUrl.get('page'))
  const searchParams = currentSearch.get('search_query')

  const [currentPage, setCurrentPage] = useState(pageParams || 1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [erpData, setErpData] = useState([]);
  const [editData, setEditData] = useState({});
  const [filterText, setFilterText] = useState(searchParams || "");
  const list = [
    {
      id: "erp",
      omit: false,
      name: "ERP",
      selector: "erp",
      visible: false,
      sortable: true,
      center: true,
      tag: "erp",
      cell: (row) => <DetailCol text={row.erp} row={row} />,
    },
    {
      id: "username",
      omit: false,
      name: "Usuario",
      selector: "username",
      sortable: true,
      center: true,
      tag: "username",
      cell: (row) => row.username,
    },
    {
      id: "name",
      omit: false,
      name: "Compañía",
      selector: "name",
      sortable: true,
      center: true,
      tag: "url",
      cell: (row) => row.name,
    },
    {
      id: "db",
      omit: false,
      name: "Base de Datos",
      selector: "db",
      sortable: true,
      center: true,
      tag: "db",
      cell: (row) => row.db,
    },
  ];

  const changeUrl = ({ pagination = pageParams }) => {
    const validateAccount = accountParams === undefined || accountParams === null || accountParams.length == 0 ? props.currentAccount : accountParams
    const validationPage = pagination || 1
    history.push({
      pathname: `/configuracion/integrationOdoo/account=${validateAccount}&page=${pagination}`
    })
    if (location.search.length > 0) {
      history.push({
        search: location.search
      })
    }
  }

  const getERP = async (account) => {
    setLoading(true);
    let response = await APILA.getOdooConnectionsByAccount({
      account: account,
    });
    setErpData([...response.data.connections]);
    setLoading(false);
  };

  useEffect(() => {
    if (props.currentAccount != "") {
      getERP(props.currentAccount);
    }
  }, [props.currentAccount]);

  const DetailCol = ({ text, row }) => (
    <div className="content-firstColumn">
      <div className={classnames("iconBand")}>
        <Tooltip color="#002448" placement="top" title="Editar">
          <i
            className="fas fa-edit"
            onClick={() => {
              setEditData(row);
              setShowModal(true);
            }}
          />
        </Tooltip>
        <Tooltip color="#002448" placement="top" title="Eliminar">
          <i className="fas fa-trash" onClick={() => deleteItem(row.id)} />
        </Tooltip>
      </div>
      <p className="name-fc">{text}</p>
    </div>
  );

  const deleteItem = async (id) => {
    setLoading(true);
    let response = await APILA.deleteOdooConnection({ id: id });
    getERP(props.currentAccount);
  };
  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        fontSize: "0.9rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "160px",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  let filteredData = erpData.filter((item) => {
    if (item?.erp?.toLowerCase().includes(filterText.toLocaleLowerCase())) {
      return item?.erp?.toLowerCase().includes(filterText.toLocaleLowerCase());
    }
    if (
      item?.username?.toLowerCase().includes(filterText.toLocaleLowerCase())
    ) {
      return item?.username
        ?.toLowerCase()
        .includes(filterText.toLocaleLowerCase());
    }
    if (item?.url?.toLowerCase().includes(filterText.toLocaleLowerCase())) {
      return item?.url?.toLowerCase().includes(filterText.toLocaleLowerCase());
    }
    if (item?.db?.toLowerCase().includes(filterText.toLocaleLowerCase())) {
      return item?.db?.toLowerCase().includes(filterText.toLocaleLowerCase());
    }
  });

  const handleFilterTextChange = (value) => {
    setFilterText(value);
    if (value !== "") {
      history.push({
        search: `search_query=${value}`
      })
    } else {
      history.push({
        search: ""
      })
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page)
    changeUrl({
      pagination: page
    })
  }

  return (
    <main className="main__IntegracionOdoo">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="barraSuperior">
            <select
              defaultValue={"1"}
              value={props.value}
              onChange={(e) => props.setValue(e.currentTarget.value)}
            >
              <option value={"1"}>Conexión ERP</option>
            </select>
            <DataTableUtils
              data={[]}
              action={() => {
                setEditData({});
                setShowModal(true);
              }}
              actionIcon={
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Crear nueva conexión"
                >
                  <i className="fas fa-plus" />
                </Tooltip>
              }
              onChangeFilterText={handleFilterTextChange}
              valueFilter={filterText}
            >
              <Tooltip
                color="#002448"
                placement="top"
                title="Exportar información"
              >
                <div className="ExportBtn">
                  <Share />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Personalizar columnas"
              >
                <div id="shareBtn">
                  <ShowColumn2 columns={list} />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Filtrar y agrupar"
              >
                <div className="Filtro2 filtro3">
                  <FilterColumn2 columns={list} />
                </div>
              </Tooltip>
            </DataTableUtils>
          </div>

          <section className="main_emails_sectionTable">
            <ReactDataTable
              columns={list}
              data={filteredData}
              persistTableHead
              customStyles={customStyles}
              pagination
              // paginationPerPage={2}
              paginationComponentOptions={{
                rowsPerPageText: "Elementos por página",
                rangeSeparatorText: "de",
              }}
              noDataComponent={"No hay datos para mostrar"}
              currentPage={currentPage}
              onChangePage={handleChangePage}
              paginationDefaultPage={currentPage}
            //paginationComponentOptions={esp}
            //noHeader
            />
          </section>
          {showModal && (
            <ModalConectOdoo
              edit={editData}
              showModal={showModal}
              refresh={(account) => {
                getERP(account);
              }}
              setShowModal={(data) => {
                setShowModal(data);
              }}
            />
          )}
        </>
      )}
    </main>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

export default connect(mapStateToProps)(withUtils(ConectionOdoo));
