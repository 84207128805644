import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MultiSelect from 'libs/multiselect'
import { getAccounts } from 'redux/actions/accounts.actions'
import { getClients } from 'redux/actions/clients.actions'
import { createAccount } from 'redux/actions/accounts.actions'
import { pwaUpsertAccount } from 'redux/actions/pwa.actions'

import { getClient_Mysql } from 'redux/actions/clients_mysql.actions'

const UpsertAccount = (props) => {
  const [canRender, setCanRender] = useState(0)
  const [username, setUsername] = useState('')
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    address: false,
  })
  const [state, setState] = useState({
    id: '',
    name: '',
    phone: '',
    email: '',
    credit: 0,
    client: '',
    address: '',
    fanpage: '',
    members: [],
    createAt: 0,
    edit: false,
    eAdvertising:0,
    clienteddl:{id_cli:0,name:''}
    
  })

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((userData) => {
      let { username } = userData
      setUsername(username)
      props._getClients()
      props._getClient_Mysql()
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      let newState = {
        edit: true,
        id: props.edit.id,
        name: props.edit.name,
        credit: props.edit.credit,
        client: props.edit.client,
        phone: props.edit.phone || '',
        email: props.edit.email || '',
        address: props.edit.address || '',
        fanpage: props.edit.fanpage || '',
        createAt: props.edit.createAt,
        members: props.edit.members || '',
        eAdvertising:props.edit.eAdvertising,
        clienteddl:{id_cli:props.edit.id,name:props.edit.name}
      }
      setCanRender(1)
      setState(prev => ({
        ...prev,
        ...newState,
      }))
    } else {
      setCanRender(0)
      setState({
        id: '',
        name: '',
        phone: '',
        email: '',
        credit: 0,
        client: '',
        address: '',
        fanpage: '',
        members: [],
        createAt: 0,
        edit: false,
        eAdvertising:0,
        clienteddl:{id_cli:0,name:''}
      })
    }
  }, [props.edit])

  const handleInputChange = (e) => {
    let name = e.target.name
    let value = e.target.value

    setState(prev => ({
      ...prev,
      [name]: value
    }))

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }))
  }

  const handleUsersChange = (value) => {
    if(value){
      setState((prev) => ({
        ...prev,
        members: value,
      }))
    }
    
  }

  const areValidInputs = () => {
    let found = props.accounts.find(element => element.name == state.name);      
    let error=false;
    if(found && !state.edit){
      error=true;
    }
    
    const mErrors = {
      name: error,
      email: state.email === '',
      phone: state.phone === '',
      address: state.address === ''
    }

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }))


    return !_.some(mErrors, (e) => e)
  }

  const handleUpsert = (e) => {
    e.preventDefault()

    if (!areValidInputs()) return

    let accountId = state.id

    if (accountId === '') {
      accountId = v4()
    }

    if (props.currentUser !== '') {
      props._createAccount(
        accountId,
        state.name,
        username, // state.client,
        state.phone,
        state.email,
        state.address,
        state.credit,
        state.members,
        state.edit,
        state.eAdvertising
      )
    } else {
      props._pwaUpsertAccount(
        accountId,
        state.name,
        state.phone,
        state.email,
        state.address,
        state.edit,
        state.eAdvertising
      )
    }

    window.$.fancybox.close()
  }

  const handleNameList= (event, value) => {
    // props.utils.log('handleInputChange')
    // let name = e.target.name
    // let value = e.target.value
    if (value) {
      
      setState(prev => ({
        ...prev,
        
        // id: value.id_client,
        phone:value.phone,
        email:value.email,
        name: value.name,
        address:value.address,
        eAdvertising:value.id_client,
        clienteddl: {
          id_cli: value.id_client,
          name: value.name,
        } 
       
      }))



    }  
  }

  const handleName = (event, value) => {
    props.utils.log('handleInputChange')
    props.utils.log('value name client', value)

    if (value) {
      let bod = {
        id_region: value.id_region,
        bandera: 1
      }
      setState(prev => ({
        ...prev,
        country: {
          id_region: value.id_region,
          name: value.name,
        } 
      }))

      setState(prev => ({
        ...prev,
        medio: {
          id_medium: '',
          site: '',
        } 
      }))

      setState(prev => ({
        ...prev,
        product:{id_ad_product: '', banner: ''}
      }))
      props._getMediumBanner(bod)
    }  
  }

  const handleUsersName = (x,y) => {
    let array=[];


  //  y.map((val) => {
  //    console.log("handleUsersName", val.value)
  //     if(val.value==x[0]){
  //       console.log("found val.value",val.value)
  //       array.push(val.value)
  //     }
  // })
    // console.log("handleUsersName    x.edit.members",x.edit.members)
    // console.log("handleUsersName state",state)
    // if(x.edit.members){
    //   if(x.edit.members.lenght>0){
    //     return x.edit.members.join(',')
    //   }else{
    //     return x.edit.members
    //   }
      
    // }else{
    //   return ""
    // }
    // console.log("handleUsersName result ", result)
    return ''
    
  }
  let users = []

  if (props.currentUser !== '') {
    props.clients.map((client) => {
      if (client.id !== username && (client.name !== '' || client.email !=='')) {
        users = [
          ...users,
          {
            value: client.id,
            name: `${client.name} (${client.email})`,            
          },
        ]
      }
    })
  }


  if(canRender==1 || props.edit.status!=true){
    return (
      <div
        id='new-account-modal'
        style={{ display: 'none' }}
        className={classnames('upsert-modal')}
      >
        <div className='foot-mnc'>
          <div className='formOneStep'>
            <div className='titleOneStep'>
              <p className='h3'>
                {state.edit
                  ? 'Actualizemos tu cuenta'
                  : 'Creemos una nueva cuenta'}
              </p>
            </div>
  
            <div className='bodyOneStep'>
              {/* <div className='oneColBody'>
                <FormGroup>
                  <Label
                    style={{
                      color: '#05252d',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    Ponle un nombre a tu cuenta
                  </Label>
                  <Input
                    type='text'
                    name='name'
                    className='input'
                    value={state.name}
                    onChange={handleInputChange}
                    placeholder='P. ejemplo madres solteras'
                    invalid={errors.name}
                  />
                  <FormFeedback>Ingresa un nombre</FormFeedback>
                </FormGroup>
              </div> */}
  
              <div className='oneColBody'>
            <FormGroup>
              <Label
                style={{
                  color: '#05252d',
                  fontWeight: 500,
                  marginBottom: '5px',
                }}
              >
                Ponle un nombre a tu cuenta 
              </Label>
              <Autocomplete
                disabled={state.edit}                
                value={state.clienteddl}
                options={props.clients_mysql}
                onChange={handleNameList}
                getOptionLabel={(options) => options.name||""}
                style={{ width: 300, height: 35 }}
                className={classnames({
                  'is-invalid': errors.name,
                })}
                renderInput={(params) => (
                  <TextField {...params} label='Cuenta' variant='outlined' />
                )}
              />
              <FormFeedback>La cuenta ya existe o el dato se encuentra vacio</FormFeedback>
            </FormGroup>
           </div>
           <div className='oneColBody'></div>
  
              {/* <div className='oneColBody'>
                <FormGroup>
                  <Label
                    style={{
                      color: '#05252d',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    Asocia una fanpage
                  </Label>
                  <Input
                    type='text'
                    name='fanpage'
                    className='input'
                    value={state.fanpage}
                    onChange={handleInputChange}
                    placeholder='P. ejemplo /madres_solteras'
                  />
                </FormGroup>
              </div> */}
  
              <div className='oneColBody'>
                <FormGroup>
                  <Label
                    style={{
                      color: '#05252d',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    N&uacute;mero de tel&eacute;fono
                  </Label>
                  <Input
                    type='text'
                    name='phone'
                    className='input'
                    value={state.phone}
                    onChange={handleInputChange}
                    placeholder='P. ejemplo 5555 5555'
                    invalid={errors.phone}
                  />
                  <FormFeedback>
                    Ingresa un n&uacute;mero de tel&eacute;fono
                  </FormFeedback>
                </FormGroup>
              </div>
  
              <div className='oneColBody'>
                <FormGroup>
                  <Label
                    style={{
                      color: '#05252d',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    Correo electr&oacute;nicoo
                  </Label>
                  <Input
                    type='text'
                    name='email'
                    className='input'
                    value={state.email}
                    onChange={handleInputChange}
                    placeholder='P. ejemplo cuenta@gmail.com'
                    invalid={errors.email}
                  />
                  <FormFeedback>
                    Ingresa un correo electr&oacute;nico
                  </FormFeedback>
                </FormGroup>
              </div>
  
              <div className='twoColBody'>
                <FormGroup>
                  <Label
                    style={{
                      color: '#05252d',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    Direcci&oacute;n
                  </Label>
                  <Input
                    type='text'
                    name='address'
                    className='input'
                    value={state.address}
                    onChange={handleInputChange}
                    placeholder='P. ejemplo 7 avenida 4-12'
                    invalid={errors.address}
                  />
                  <FormFeedback>Ingresa una direcci&oacute;n</FormFeedback>
                </FormGroup>
              </div>
  
              {/* {props.currentUser !== '' && state.client === username && ( */}
                <div className='twoColBody'>
                  <FormGroup>
                    <Label
                      style={{
                        color: '#05252d',
                        fontWeight: 500,
                        marginBottom: '5px',
                      }}
                    >
                      Asocia usuarios a esta cuenta
                    </Label>
                    
                    <MultiSelect
                      options={users}
                      value={state.members}                  
                      labelKey='name'
                      onChange={handleUsersChange}
                      onSelect={state.members}
                    />
                    <FormFeedback>Ingresa un nombre</FormFeedback>
                  </FormGroup>
                </div>
               
            </div>
  
            <div className='footOneStep mt-3'>
              <a
                href='#'
                data-fancybox-close
                className='btnCancelOS'
                onClick={(e) => e.preventDefault()}
              >
                Cancelar
              </a>
  
              <a href='#' className='btnConfirmOS' onClick={handleUpsert}>
                Confirmar
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return (
      <div
        id='new-account-modal'
        style={{ display: 'none' }}
        className={classnames('upsert-modal')}
      >
        <div className='foot-mnc'>
          <div className='formOneStep'>
            <div className='titleOneStep'>
              <p className='h3'>
                {state.edit
                  ? 'Actualizemos tu cuenta'
                  : 'Creemos una nueva cuenta'}
              </p>
            </div>
  
           
            <div className='footOneStep mt-3'>
              <a
                href='#'
                data-fancybox-close
                className='btnCancelOS'
                onClick={(e) => e.preventDefault()}
              >
                Cancelar
              </a>
  
              <a href='#' className='btnConfirmOS' onClick={handleUpsert}>
                Confirmar
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  clients: state.clients,
  edit: state.accounts.edit,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  clients_mysql:state.clients_mysql,
  accounts: state.accounts.list,
  selected:state.accounts.edit.selected

})

const mapDispatchToProps = (dispatch) => ({
  _getAccounts: client => dispatch(getAccounts(client)),
  _getClients: () => dispatch(getClients()),
  _getClient_Mysql: () => dispatch(getClient_Mysql()),
  _pwaUpsertAccount: (id, name, phone, email, address, edit) =>
    dispatch(pwaUpsertAccount(id, name, phone, email, address, edit)),
  _createAccount: (id, name, username, phone, email, address, credit, members, edit,eAdvertising) =>
    dispatch(
      createAccount(id, name, username, phone, email, address, credit, members, edit,eAdvertising)
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpsertAccount)