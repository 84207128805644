/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { saveCopy, saveObservation } from "redux/actions/medioCamp.actions";
import { saveSteps } from "redux/actions/nameCamp.actions";

const ObservationCamp = (props) => {
  const [observation, setObservation] = useState(
    props?.campaign?.observations || ""
  );
  const [copy, setCopy] = useState(props.campaign?.copy || "");

  const backName = (e) => {
    e.preventDefault();
    props._saveSteps(6);
  };

  const handleCopyChange = (e, editor) => {
    console.log();

    const inputcopy = document.getElementById("txtcopy");

    if (!inputcopy.value) {
      inputcopy.style.borderBottom = "1px solid red";
      inputcopy.style.color = "red";
      inputcopy.placeholder = "Campo requerido";
    } else {
      inputcopy.style.borderBottom = "1px solid #05252d";
      inputcopy.style.color = "#05252d";
      inputcopy.placeholder = "Ingresa un texto que acompañe la publicación";
    }

    setCopy(e.value);
  };

  const handleObservationChange = (e, editor) => {
    const inputobs = document.getElementById("txtobs");

    if (!inputobs.value) {
      inputobs.style.borderBottom = "1px solid red";
      inputobs.style.color = "red";
      inputobs.placeholder = "Campo requerido";
    } else {
      inputobs.style.borderBottom = "1px solid #05252d";
      inputobs.style.color = "#05252d";
      inputobs.placeholder = "Agrega indicaciones adicionales para la campaña";
    }
    setObservation(e.value);
  };

  const saveMetodo = (e) => {
    e.preventDefault();
    const inputcopy = document.getElementById("txtcopy");
    const inputobs = document.getElementById("txtobs");

    if (!inputcopy.value) {
      inputcopy.style.borderBottom = "1px solid red";
      inputcopy.style.color = "red";
      inputcopy.placeholder = "Campo requerido";
    } else {
      inputcopy.style.borderBottom = "1px solid #05252d";
      inputcopy.style.color = "#05252d";
      inputcopy.placeholder = "Ingresa un texto que acompañe la publicación";
    }
    if (!inputobs.value) {
      inputobs.style.borderBottom = "1px solid red";
      inputobs.style.color = "red";
      inputobs.placeholder = "Campo requerido";
    } else {
      inputobs.style.borderBottom = "1px solid #05252d";
      inputobs.style.color = "#05252d";
      inputobs.placeholder = "Agrega indicaciones adicionales para la campaña";
    }

    if (inputcopy.value !== "" && inputobs.value !== "") {
      console.log("saving copy", inputcopy.value);
      console.log("saving obs", inputobs.value);
      props._saveCopy(inputcopy.value);
      props._saveObservation(inputobs.value);
      props._saveSteps(7);
    }
  };

  return (
    <div className={props.clase}>
      <h1 className="title"> Ingresa los detalles de tu publicación</h1>
      <br></br>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Observaciones
          </Label>
          <Input
            id="txtobs"
            type="textarea"
            placeholder="Agrega indicaciones adicionales para la campaña"
            rows={3}
            onChange={handleObservationChange}
            value={observation}
            name="observation"
          />
        </FormGroup>
      </div>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Copy
          </Label>
          <Input
            id="txtcopy"
            type="textarea"
            rows={3}
            placeholder="Ingresa un texto que acompañe la publicación"
            onChange={handleCopyChange}
            value={copy}
            name="copy"
          />
        </FormGroup>
      </div>
      <br></br>
      <div className="space-btns">
        <a className="btn-camp gray" onClick={backName} href="#">
          Volver
        </a>
        <a className="btn-camp" onClick={saveMetodo} href="#" id="nextMedio">
          Siguiente
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
  mediumbanners: state.mediumbanners,
  mediumbannerstype: state.mediumbannerstype,
  countries: state.countryReducer,
  mediumbanners: state.mediumbanners,
});

const mapDispatchToProps = (dispatch) => ({
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _saveObservation: (obs) => dispatch(saveObservation(obs)),
  _saveCopy: (copy) => dispatch(saveCopy(copy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObservationCamp);
