export default {
  CREADO: {
    id: 1,
    color: "#ff3d57",
    label: "Creado",
    class: "status_creado",
  },
  INACTIVO: {
    id: 2,
    color: "#ff3d57",
    label: "Inactivo",
    class: "status_creado",
  },
  ELIMINADO: {
    id: 3,
    color: "#DB3522",
    label: "Eliminado",
    class: "status_cancelado",
  },
  MIX_APROBADO: {
    id: 5,
    color: "#794fcf",
    label: "Aprobado",
    class: "status_mix_aprobado",
  },
  EN_REVISION: {
    id: 6,
    color: "#fdab3d",
    label: "En Revisión",
    class: "status_revision",
  },
  APROBADO: {
    id: 7,
    color: "#DB3522",
    label: "Desaprobado",
    class: "status_cancelado",
  },
  MODIFICADO: {
    id: 8,
    color: "#ff3d57",
    label: "Modificado",
    class: "status_creado",
  },
  EN_IMPLEMENTACION: {
    id: 9,
    color: "#6c9c99",
    label: "En Implementación",
    class: "status_implementacion",
  },
  ACTIVO: {
    id: 10,
    color: "#00ca72",
    label: "Activo",
    class: "status_corriendo",
  },
  PENDIENTE: {
    id: 11,
    color: "#fdab3d",
    label: "Pendiente",
    class: "status_revision",
  },
  FINALIZADO: {
    id: 12,
    color: "#009aff",
    label: "Finalizado",
    class: "status_completado",
  },
  FINALIZADO_SIN_META: {
    id: 13,
    color: "#009aff",
    label: "Finalizado sin meta",
    class: "status_completado",
  },
  PAUSADO: {
    id: 14,
    color: "#ff3d57",
    label: "Pausado por Lets",
    class: "status_detenido",
  },
  RECHAZADO: {
    id: 15,
    color: "#fdab3d",
    label: "Rechazado",
    class: "status_revision",
  },
  NO_IMPLEMENTADO: {
    id: 16,
    color: "#DB3522",
    label: "No implementado",
    class: "status_cancelado",
  },
  FINALIZADO_SIN_REPORTE: {
    id: 17,
    color: "#009aff",
    label: "Finalizado sin reporte",
    class: "status_completado",
  },
  PAUSADO2: {
    id: 18,
    color: "#ff3d57",
    label: "Pausado",
    class: "status_detenido",
  },
};

export const arrayStatus = [
  {
    id: 1,
    label: "Creado",
  },
  {
    id: 2,
    label: "Inactivo",
  },
  {
    id: 3,
    label: "Eliminado",
  },
  {
    id: 5,
    label: "Mix Aprobado",
  },
  {
    id: 6,
    label: "En revisión",
  },
  {
    id: 7,
    label: "Desaprobado",
  },
  {
    id: 8,
    label: "Modificado",
  },
  {
    id: 9,
    label: "En Implementación",
  },
  {
    id: 10,
    label: "Activo",
  },
  {
    id: 11,
    label: "Pendiente",
  },
  {
    id: 12,
    label: "Finalizado",
  },
  {
    id: 13,
    label: "Finalizado sin Meta",
  },
  {
    id: 14,
    label: "Pausado",
  },
  {
    id: 15,
    label: "Rechazado",
  },
  {
    id: 16,
    label: "No implementado",
  },
  {
    id: 17,
    label: "Finalizado sin reporte",
  },
];

export const arrayPriority = [
  {
    id: 0,
    name: "Normal",
    show: false,
    nameAccount: "normal",
  },
  {
    id: 1,
    name: "Crítico",
    show: true,
    nameAccount: "critical",
  },
  {
    id: 2,
    name: "Urgente",
    show: true,
    nameAccount: "urgent",
  },
  {
    id: 3,
    name: "Normal",
    show: true,
    nameAccount: "normal",
  },
];
