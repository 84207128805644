import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { saveSteps } from 'redux/actions/nameCamp.actions'
import { saveObjets } from 'redux/actions/objetivoCamp.actions'
import { getObjectCampain } from 'redux/actions/objectCampain.actions'

const ObjetivoCamp = (props) => {
  useEffect(() => {
    if (props.campaign.objetivo == null) {
      props._getObjectCampain()
    }
  }, [])

  const cambio = () => {
    let select = document.getElementById('btnObjetoCamp')
    select.style.borderBottom = '1px solid grey'
    select.style.color = '#292f4c'
  }

  const backFecha = (e) => {
    e.preventDefault()
    props._saveSteps(3)
  }

  const saveObjetivo = (e) => {
    e.preventDefault()

    let objTxt = document.getElementById('btnObjetoCamp')

    if (objTxt.value != '') {
      props._saveObjets(objTxt.options[objTxt.selectedIndex].text)
      props._saveSteps(5)
    } else {
      objTxt.style.borderBottom = '1px solid red'
      objTxt.style.color = 'red'
    }
  }

  var sel = document.getElementById('btnObjetoCamp')

  if (props.campaignupd != undefined && props.campaignupd.length > 0) {
    if (sel != undefined && sel != null) {
      if (props.campaign.objetivo != null) {
        props.campaignupd[0].objetivo = props.campaign.objetivo
      }

      for (var i = 0; i < sel.length; i++) {
        var opt = sel[i]
        if (opt.value == props.campaignupd[0].objetivo) {
          sel.selectedIndex = i
        }
      }
    }
  }

  const pautaList = props.objectcampain

  return (
    <div clase='component'>
      <h1 className='title'>Selecciona un objetivo para la pauta</h1>
      <select
        className='input'
        id='btnObjetoCamp'
        onChange={cambio}
        placeholder='hola mundo'
        defaultValue={props.campaign.objetivo}
      >
        <option disabled selected hidden value=''>
          P. ejemplo Objetivo
        </option>
        {pautaList.map((pauta) => (
          <option key={pauta.name} value={pauta.name}>
            {pauta.name}
          </option>
        ))}
      </select>
      <div className='space-btns'>
        <a className='btn-camp gray' onClick={backFecha} href='#'>
          volver
        </a>
        <a className='btn-camp' onClick={saveObjetivo} href='#' id='nextMedio'>
          siguiente
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
  objectcampain: state.objcampain,
})

const mapDispatchToProps = (dispatch) => ({
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _getObjectCampain: () => dispatch(getObjectCampain()),
  _saveObjets: (objetivo) => dispatch(saveObjets(objetivo)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjetivoCamp)