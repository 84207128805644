import { useState, useEffect, useRef, isValidElement } from "react";
import { withUtils } from "utils";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import * as APILA from "helpers/apiLA";
import PageLoader from "components/page_loader";
import Swal from "sweetalert2";
import { descifrar, cifrar } from "helpers/encrypt";
import Tooltip from "antd/es/tooltip";

const ModalConectOdoo = (props) => {
  const { showModal, setShowModal, refresh, edit } = props;
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    id: "",
    url: "",
    username: "",
    db: "",
    erp: "Odoo",
    port: 8069,
    password: "",
    idCliente: "",
    idProducto: "",
    idCompany: "0",
    idUser: "0"
  });

  useEffect(() => {
    if (edit.id) {
      getData(edit);
    }
  }, [edit]);

  useEffect(() => {
    console.log("Estado del state", state)

  }, []);

  const getData = async (row) => {
    console.log("Edit", row);
    let password = await props._descrifrar(row.password, "LetsAdvertise");
    setState({
      id: "",
      url: row.url,
      username: row.username,
      db: row.db,
      port: row.port,
      erp: row.erp,
      password: password,
      idCliente: row.idCliente,
      idProducto: row.idProducto,
      idCompany: row.idCompany,
      idUser: row.idUser
    });
  };

  function onNumber(datos) {
    let cadenaSinComa = datos.replace(",", "");
    let valores = cadenaSinComa.split(" ");
    let numeros = valores.map(function (valor) {
      return parseInt(valor);
    });
    return numeros
  }

  const onClick = async () => {
    if (
      state.url != "" &&
      state.db != "" &&
      state.erp != "" &&
      state.username != "" &&
      state.password != "" &&
      state.idCliente != "" &&
      state.idProducto != "" &&
      state.idCompany !== "0" &&
      state.idUser !== "0"
    ) {
      console.log('VALORES RECIBIDOS', state);
      setLoading(true);
      console.log("State", state);
      console.log("CurrentAccount", props.currentAccount, props.currentUser);
      const body = {
        id: edit.id ? edit.id : "",
        url: state.url,
        port: 8069,
        db: state.db,
        erp: state.erp,
        username: state.username,
        password: state.password,
        account: props.currentAccount,
        userId: props.currentUser,
        idCliente: state.idCliente,
        idProducto: state.idProducto,
        idCompany: Number(state.idCompany),
        idUser: Number(state.idUser)
      };
      console.log("Este es el body desde el modal", body)

      let response = await APILA.connectionOdoo(body);
      if (response.data.status !== 'Success') return Swal.fire({
        title: "Error",
        text: "Credenciales inválidas",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then(() => {
        setLoading(false);
      });
      let name = props.accounts.find(
        (ele) => ele.id === props.currentAccount,
      );
      let responseSinc = await APILA.initialSync({
        "idClient": onNumber(state.idCliente),
        "idProducts": onNumber(state.idProducto),
        "db": state.db,
        "idConnection": response.data.data.id,
        "url": state.url,
        "username": state.username,
        "idClientFilter": state.idCliente,
        "idProductsFIlter": state.idProducto,
        "password": state.password,
        "idCompany": Number(state.idCompany),
        "idUser": Number(state.idUser),
        "account": props.currentAccount,
        "name": name.name
      });
      if (responseSinc.data.success === 'post call succeed!') {
        Swal.fire({
          title: "Confirmación",
          text: "Vinculado con éxito!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(() => {
          setShowModal(false);
          refresh(props.currentAccount);
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Error en sincronización",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(() => {
          setLoading(false);
        });
      }

      setLoading(false);
    } else {
      Swal.fire({
        title: "Advertencia",
        text: "Por favor ingresa todos los campos.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };

  const onInputChange = (e) => {
    e.persist();
    setState(() => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="blur-div">
      <div className="content-modal">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <div className="header-modal">
              <h3 className="titleModal">Conéctate a tu ERP</h3>
            </div>

            <div className="body-modal">
              <section className="body-part">
                <label className="label-container">
                  ERP
                  <select name="erp" onChange={onInputChange}>
                    <option value={"Odoo"}>Odoo</option>
                  </select>
                </label>
                <label className="label-container">
                  Url
                  <input
                    type="text"
                    value={state.url}
                    name="url"
                    onChange={onInputChange}
                  />
                </label>
                <label className="label-container">
                  Usuario base de datos
                  <input
                    type="text"
                    value={state.username}
                    name="username"
                    onChange={onInputChange}
                  />
                </label>
                <label className="label-container">
                  Nombre base de datos
                  <input
                    type="text"
                    value={state.db}
                    name="db"
                    onChange={onInputChange}
                  />
                </label>
                {/* <label className="label-container">
                  Puerto
                  <input
                    type="text"
                    value={state.port}
                    name="port"
                    onChange={onInputChange}
                  />
                </label> */}
                {/* <label className="label-container">
                  Contraseña usuario base de datos
                  <input
                    type="text"
                    value={state.password}
                    name="password"
                    onChange={onInputChange}
                  />
                </label> */}
                {view ? (
                  <label className="label-container">
                    Contraseña usuario base de datos
                    <input
                      type="text"
                      value={state.password}
                      name="password"
                      onChange={onInputChange}
                    />
                    <i
                      class="fas fa-eye-slash"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView(false)}
                    ></i>
                  </label>
                ) : (
                  <label className="label-container">
                    Contraseña usuario base de datos
                    <input
                      type="password"
                      value={state.password}
                      name="password"
                      onChange={onInputChange}
                    />
                    <i
                      class="fas fa-eye"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView(true)}
                    ></i>
                  </label>
                )}

                <label className="label-container">
                  Id clientes
                  <input
                    type="text"
                    value={state.idCliente}
                    name="idCliente"
                    onChange={onInputChange}
                  />
                </label>
                <label className="label-container">
                  Id productos
                  <input
                    type="text"
                    value={state.idProducto}
                    name="idProducto"
                    onChange={onInputChange}
                  />
                </label>
                <label className="label-container">
                  Id usuario
                  <input
                    type="number"
                    value={state.idUser}
                    name="idUser"
                    onChange={onInputChange}
                  />
                </label>
                <label className="label-container">
                  Id compañía
                  <input
                    type="number"
                    value={state.idCompany}
                    name="idCompany"
                    onChange={onInputChange}
                  />
                </label>
              </section>
            </div>

            <div className="footer-Modal">
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button
                  className="btn btn_cancel btnCancelOS"
                  onClick={(e) => setShowModal(false)}
                >
                  Cancelar
                </button>
              </Tooltip>
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button className="btn btn_save btnConfirmOS" onClick={onClick}>
                  Guardar
                </button>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  accounts: state.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _descrifrar: (texto, password) => dispatch(descifrar(texto, password)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ModalConectOdoo));
