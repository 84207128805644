import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ReactCalendar from '@easygosanet/react-calendar'

import status from 'config/status'
import { connect } from 'react-redux'
import classnames from "classnames";
import { withUtils } from 'utils'
import { updateCampaingStatus, funcampaignupd } from 'redux/actions/dashboard.actions'
import ModalPauta from "components/modalPauta/ModalPauta";
const Calendar = ({ campaigns, utils, _updateCampaingStatus, _funcampaignupd, dataEnviada, audiences, refresh, refreshData, clearCampaignupd }) => {
  const [events, setEvents] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [campEdit, setCampEdit] = useState([]);
  useEffect(() => {

    let mCampaings = []
    console.log('CalendarCampaigns',campaigns)
    campaigns.map((campaign) => {
      if (campaign.fechaIni && campaign.fechafin) {
        let fi = campaign.fechaIni.split('.')
        let ff = campaign.fechafin.split('.')
        let campName= campaign.id + '-$' + campaign.budget + '-' + campaign.medio + '-' + campaign.name + '-' + campaign.formato
        mCampaings.push({
          id: campaign.id,
          title: campName,
          start: new Date(`${fi[2]}-${fi[1]}-${fi[0]} 00:00:00`),
          end: new Date(`${ff[2]}-${ff[1]}-${ff[0]} 24:00:00`),
          color: getEventColor(campaign.status)
        })
      }
    })

    if (!_.isEmpty(mCampaings)) setEvents(mCampaings)
  }, [campaigns])

  const getEventColor = mStatus => {
    switch (mStatus) {
      // Corriendo
      case status.ACTIVO.id:
        return status.ACTIVO.color
      // En cola
      case status.CREADO.id:
        return status.CREADO.color
      // En implementacion
      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.color
      // Finalizado
      case status.FINALIZADO.id:
      case status.FINALIZADO_SIN_META.id:
      case status.FINALIZADO_SIN_REPORTE.id:
        return status.FINALIZADO.color
      // Aprobado
      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.color
      // En revision
      case status.PENDIENTE.id:
      case status.RECHAZADO.id:
      case status.EN_REVISION.id:
        return status.EN_REVISION.color
      // Cancelado
      case status.ELIMINADO.id:
      case status.NO_IMPLEMENTADO.id:
        return status.ELIMINADO.color
      default:
        return status.PAUSADO.color
    }
  }
  const onEventClick = (e, f) => {
    console.log('event',e)
    console.log('EventDetail', e.event._def.publicId)
    console.log('Campaigns to search',campaigns)
    const found = campaigns.filter(ele => ele.id.toString() === e.event._def.publicId.toString())
    console.log('found', found)
    if (found.length > 0) {
      if (found[0].status != 3 &&
        found[0].status != 6) {
        console.log('Se puede editar');
        /* props._funcampaignupd(campaignupd); */
        _funcampaignupd(found[0])
        let fechaIni = found[0].fechaIni.replace(/\./g, "/")
        fechaIni = fechaIni.split('/')
        console.log('LA fecha', fechaIni);
        fechaIni = fechaIni[2] + "/" + fechaIni[1] + "/" + fechaIni[0]
        console.log('LA FECHA', fechaIni);
        setCampEdit(found[0]);
        setShowModal(true);
      }

    }
  }
  return (
    <div className='form-tar'>
      <ReactCalendar
        eventClick={onEventClick}
        events={events}
        displayEventTime={true}
        dayMaxEvents={false}
        selectable={true}
        headerToolbar={{ start: 'prev', center: 'title', end: 'next' }}
      />
      {showModal && (
        <div className="blur-div blurNewPauta">
          <div
            className={classnames("upsert-modal w-75 h-75 modalapauta")}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <ModalPauta
              dataEnviada={dataEnviada}
              setShowModal={setShowModal}
              audiences={audiences}
              campaignupd={campEdit}
              setRefresh={() => refreshData()}
              refresh={refresh}
              _clearCampaignupd={clearCampaignupd}
            />
          </div>
        </div>
      )}
    </div>
  )
}
const mapDispatchToProps = (dispatch) => ({
  _updateCampaingStatus: (campaingId, status, prevStatus) => dispatch(updateCampaingStatus(campaingId, status, prevStatus)),
  _funcampaignupd: (camp) => dispatch(funcampaignupd(camp)),
})

export default connect(undefined, mapDispatchToProps)(withUtils(Calendar))