/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import * as Modal from "redux/actions/modal.actions";
import { updateInformationCurrentAccount } from "redux/actions/accountInformation.actions";
import "./style.scss";

const ModaAccountInformation = (props) => {
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    address: false,
    phone: false,
  });

  const initialState = {
    name: "",
    email: "",
    address: "",
    phone: "",
    edit: false,
  };
  const [state, setState] = useState(initialState);
  const [messageError, setMessageError] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });

  useEffect(() => {
    setState({});
    if (!_.isEmpty(props.editData)) {
      let newState = {
        name: props.editData.name,
        email: props.editData.email,
        address: props.editData.address,
        phone: props.editData.phone,
        edit: true,
      };
      setState((prev) => ({
        ...prev,
        ...newState,
      }));
    } else {
      setState(initialState);
    }
  }, [props.editData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const closeModal = (e) => {
    if (e) e.preventDefault();
    props.setShowModal(false);
    setState(initialState);
  };

  const validateData = () => {
    let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    let validateEmail = regexEmail.test(state.email.trim());
    if (!validateEmail) {
      setMessageError({
        ...messageError,
        email: "Ingresa un correo electronico valido",
      });
      setErrors({ ...errors, email: true });
      return false;
    }
    let validateData = {
      name: state.name.trim() !== "",
      email: validateEmail,
      phone: state.phone.trim() !== "",
      address: state.address.trim() !== "",
    };
    setErrors({ ...errors, email: "" });
    setMessageError({ ...messageError, email: "" });
    return _.every(validateData, Boolean);
  };

  const handleEditInfo = async (e) => {
    e.preventDefault();
    if (!validateData()) {
      props._showAlertModal(
        "Campos Vacíos",
        "Por favor ingresa todos los campos",
        Modal.MODAL_TYPE_ERROR,
        () => {}
      );
      return;
    }
    if (!props.currentUser) return;
    try {
      await props._updateInformationCurrentAccount({
        name: state.name,
        phone: state.phone,
        address: state.address,
        email: state.email,
        account: props.currentAccount,
      });
    } catch (error) {
      console.log("ERROR ACTUALIZANDO ACTIVOS", { error });
    }
    closeModal();
    props.info();
  };

  const handleCreateInfo = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="blur-div">
      <div
        id="new-audience-modal"
        style={{ display: props.showModal ? "block" : "none" }}
        className={classnames("upsert-modal")}
      >
        <div className="foot-mnc">
          <div className="formOneStep">
            <div className="titleOneStep">
              <p className="h3">
                {state.edit
                  ? "Actualicemos tu cuenta"
                  : "Agreguemos tus activos digitales"}
              </p>
            </div>
            <div className="bodyOneStep bodyOneStepPublico ">
              <div className="twoColBody">
                <FormGroup>
                  <h5
                    style={{
                      color: "#05252d",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Nombre
                  </h5>
                  <Input
                    required
                    type="text"
                    name="name"
                    className="input"
                    value={state.name}
                    onChange={handleInputChange}
                    placeholder="P. ejemplo: Scentia"
                    invalid={errors.name}
                  />
                  <FormFeedback>Ingresa un nombre</FormFeedback>
                </FormGroup>
              </div>
              <div className="twoColBody">
                <FormGroup>
                  <h5
                    style={{
                      color: "#05252d",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Dirección
                  </h5>
                  <Input
                    required
                    type="text"
                    name="address"
                    className="input"
                    value={state.address}
                    onChange={handleInputChange}
                    placeholder="Zona 1, Ciudad de Guatemala, Guatemala"
                    invalid={errors.address}
                  />
                  <FormFeedback>Ingresa una dirección</FormFeedback>
                </FormGroup>
              </div>
              <div className="twoColBody">
                <FormGroup>
                  <h5
                    style={{
                      color: "#05252d",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Numero de telefono
                  </h5>
                  <Input
                    required
                    type="number"
                    name="phone"
                    className="input"
                    value={state.phone}
                    onChange={handleInputChange}
                    placeholder="P. ejemplo: 12345678"
                    invalid={errors.phone}
                  />
                  <FormFeedback>Ingresa un numero de telefono </FormFeedback>
                </FormGroup>
              </div>
              <div className="twoColBody">
                <FormGroup>
                  <h5
                    style={{
                      color: "#05252d",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Correo electronico
                  </h5>
                  <Input
                    required
                    type="text"
                    name="email"
                    className="input"
                    value={state.email}
                    onChange={handleInputChange}
                    placeholder="P. ejemplo: minombre@example.com"
                    invalid={errors.email}
                  />
                  <FormFeedback>
                    {!messageError.email
                      ? "Ingresa un correo electronico"
                      : messageError.email}
                  </FormFeedback>
                </FormGroup>
              </div>
            </div>
            <div className="footOneStep w-100 footOneStepModal3">
              <a href="#" className="btnCancelOS firstBtn" onClick={closeModal}>
                Cancelar
              </a>
              <a
                href="#"
                className="btnConfirmOS secondBtn"
                onClick={state.edit ? handleEditInfo : handleCreateInfo}
              >
                Confirmar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.audiences.edit,
  accounts: state.accounts.list,
  audiences: state.audiences.list,
  currentUser: state.app.current_user,
  pwaAudiences: state.pwa.audiences.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _updateInformationCurrentAccount: ({
    name,
    address,
    phone,
    email,
    account,
  }) =>
    dispatch(
      updateInformationCurrentAccount({ name, address, phone, email, account })
    ),
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModaAccountInformation);
